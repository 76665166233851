import { AuthorizationsExtModule } from './../ext/authorizations-card/authorizations-ext.module';
import { NgModule } from '@angular/core';
import { IssueExtComponent } from '../life-issue-card-ext/issue/issue-ext.component';
import { CommonModule } from '@angular/common';
import { LifeCardModule, LicRequiredModule } from '@rgi/life-issue-card';
import { DigitalSignDocumentsComponent } from '../digital-sign-documents/digital-sign-documents.component';
import {
  LifeDetailExtComponent
} from '../life-postsales-card-ext/life-detail/life-detail-ext.component';
import {
    LifeDetailCardModule,
    LoaderMobileModule,
    TooltipModule,
    LifePostsalesModule, DataValueFormatterModule,
    SurveyEvaluateStateLifeOperations
} from '@rgi/life-postsales-card';
import { RgiCountryLayerModule } from '@rgi/country-layer';
import { RgiRxDatePickerModule, RgiRxExpansionModule, RgiRxFormElementsModule, RgiRxModalModule, RgiRxPanelModule, RgiRxSnackbarModule, RgiRxTableModule } from '@rgi/rx/ui';
import {
  AjsConfigurationLifePostsalesComplete
} from '../life-postsales-card-ext/models/ajs-configuration-life-postsales-ext-complete';
import {
  AngularCommunicationPostsalesComplete
} from '../life-postsales-card-ext/models/angular-communication-postsales-ext-complete';
import { TranslateModule } from '@ngx-translate/core';
import { SummaryExtComponent } from '../life-issue-card-ext/summary/summary-ext.component';
import { ProposalOtpModule } from '../proposal-otp/proposal-otp.module';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { IssuePrivacyEditComponent } from '../issue-privacy-edit/issue-privacy-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MotorIssueCardModule } from '@rgi/motor-issue-card';
import { PartyOtpComponent } from '../proposal-otp/party-otp-component/party-otp.component';
import {RgiRxI18nModule } from '@rgi/rx/i18n';
import { IIAB_TRANSLATIONS, LPC_TRANSLATIONS_PPEVO_QUEST_LIFE } from './i18n/translations';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OtpService } from '../services/otp-service.service';
import { DatiAmmExtComponent } from '../life-issue-card-ext/dati-amm/dati-amm-ext.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SurveyEvaluateStateOperationsExt } from '../passpro-pro-survey-ext/state/survey-evaluate-state-operations-ext.service';
import { TotalSummaryCardModule } from './total-summary-card/total-summary-card.module';
import { RgiAnagPortalModule } from '@rgi/anag/portal';
import { LicInvestmentsExtModule } from '../life-issue-card-ext/life-card/invest-standalone-session/lic-investments-ext.module';
import {AnagPrivacyComponentExt} from "../ext/anag-cards/privacy/anag-privacy.component-ext";
import { AuthorizationsDetailExtComponent } from '../ext/authorizations-card/authorizations-detail/authorizations-detail-ext.component';
import { AuthorizationsListExtComponent } from '../ext/authorizations-card/authorizations-list/authorizations-list-ext.component';
import { LifeCardExtComponent } from '../life-issue-card-ext/life-card-ext.component';
import { AjsConfigurationLifeExt } from '../life-issue-card-ext/portal-integration/ajs-configuration-life-issue';
import { AnagUtilsService } from '@rgi/anag';
import { AnagUtilsServiceExt } from '../ext/anag-cards/anag-resources/anag-utils.service-ext';


@NgModule({
  declarations: [
    LifeCardExtComponent,
    DatiAmmExtComponent,
    IssueExtComponent,
    LifeDetailExtComponent,
    DigitalSignDocumentsComponent,
    SummaryExtComponent,
    IssuePrivacyEditComponent,
    PartyOtpComponent,
    AnagPrivacyComponentExt
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    NgbModule,
    LifeCardModule,
    LoaderMobileModule,
    RgiCountryLayerModule,
    LifeDetailCardModule,
    TooltipModule,
    RgiRxExpansionModule,
    LifePostsalesModule,
    TranslateModule,
    ProposalOtpModule,
    PortalNgCoreModule,
    RgiRxFormElementsModule,
    ReactiveFormsModule,
    RgiRxDatePickerModule,
    RgiRxSnackbarModule,
    MotorIssueCardModule,
    RgiRxI18nModule,
    RgiRxI18nModule.forRoot(IIAB_TRANSLATIONS),
    RgiRxI18nModule.forRoot(LPC_TRANSLATIONS_PPEVO_QUEST_LIFE),
    RgiRxModalModule,
    RgiRxPanelModule,
    NgSelectModule,
    LicRequiredModule,
    RgiRxTableModule,
    TotalSummaryCardModule,
    RgiAnagPortalModule,
    LicInvestmentsExtModule,
    DataValueFormatterModule,
    AuthorizationsExtModule
  ],
  exports: [
    LifeCardExtComponent,
    DatiAmmExtComponent,
    IssueExtComponent,
    LifeDetailExtComponent,
    DigitalSignDocumentsComponent,
    SummaryExtComponent,
    IssuePrivacyEditComponent,
    PartyOtpComponent,
    AnagPrivacyComponentExt
  ],
  providers: [
    { provide: 'datiAmmComponent', useValue: DatiAmmExtComponent },
    { provide: 'issueComponent', useValue: IssueExtComponent },
    { provide: 'summaryComponent', useValue: SummaryExtComponent },
    { provide: 'otpService', useValue: OtpService },
    { provide: SurveyEvaluateStateLifeOperations, useClass: SurveyEvaluateStateOperationsExt },
    { provide: 'authorizationsDetailComponent', useValue: AuthorizationsDetailExtComponent },
    { provide: 'authorizationsListComponent', useValue: AuthorizationsListExtComponent },
    { provide: AnagUtilsService, useClass: AnagUtilsServiceExt }
  ]
})
export class IiabPortalExtModule {
  constructor() {
    AngularCommunicationPostsalesComplete();
    AjsConfigurationLifePostsalesComplete();
    AjsConfigurationLifeExt();
  }
}

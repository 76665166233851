import {Inject, Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscription} from 'rxjs';
import {DocumentEntity} from './dto/document-entity';
import {DocumentCategoryEntity, DocumentSubcategoryEntity} from './dto/document-category-entity';
// @ts-ignore
import {ApiClaim, ApiParty} from '@rgi/digital-claims-common-angular';
import {FormGroup} from '@angular/forms';
import {RgiRxTranslationService} from "@rgi/rx/i18n";

@Injectable({
  providedIn: 'root'
})
export class ClaimDocumentsService {

  // upload
  private isUploadPotential: boolean;
  private uploadParty: ApiParty;
  private uploadCategory: DocumentCategoryEntity;
  private uploadSubcategory: DocumentSubcategoryEntity;
  // edit
  private editDocument: DocumentEntity;
  private editParty: ApiParty;
  private editCategory: DocumentCategoryEntity;
  private editSubcategory: DocumentSubcategoryEntity;
  private isEditClaimDocument: boolean;
  // get pufx
  public authSrv: any;

  // requests
  private baseApiUrl: string;
  private baseApiUrlRs: string;
  private baseApiUrlOld: string;
  private urlservice = '/claims';

  private exercise: string;
  private agencyCode: string;
  private claimNumber: string;
  private reportNumber: string;
  private companyCode: string;
  private jsonClaim: any;
  private isInsidePotentialClaim: boolean;
  // is in a process and possibly has task NotifyTakeInCharge (presa in carico)
  private integrationWF: any;
  private areDocumentsViewed: boolean;
  private formViewDocuments: FormGroup;

  // claim documents
  private documents: Array<DocumentEntity>;
  private documentCategories: Array<DocumentCategoryEntity>;

  // potclaim documents
  private potClaimDocuments: Array<DocumentEntity>;
  private potClaimDocumentCategories: Array<DocumentCategoryEntity>;
  // number because the only information required on the other component is the length, no need to set the whole thing
  private potClaimDocumentsLength: number;

  // all documents if only one condensed view (claim + potlciam)
  private allDocuments: Array<DocumentEntity>;
  // parties
  private parties: Array<ApiParty>;
  private notInvolvedParties: Array<ApiParty>;

  // pisystemproperty determines whether PARTIES are shown
  private sysPropShowParties: boolean = false;

  // pufx, can download policy document SXDCDT
  private canDownloadPolicyDocument: boolean = false;

  messageKoUploadDocument: string = '';

  constructor(
    @Inject('containerCacheService') private cache,
    private httpClient: HttpClient,
    @Inject('authService') authService,
    @Inject('getSystemProperty') private getSystemProperty: any,
    @Inject('environment') environment: any,
    private rxTranslationsService: RgiRxTranslationService) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
    this.baseApiUrlOld = environment.api.portal.host + environment.api.portal.path;
    this.baseApiUrlRs = environment.api.portal.host + environment.api.portal.path;

    this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._KO_UPLOAD_DOC').subscribe(res => {
      this.messageKoUploadDocument = res;
    });

    this.authSrv = authService;
    const listFunz = this.authSrv.getOperator().enabledFeatures;
    if (listFunz.indexOf('SXDCDT') >= 0) {
      this.canDownloadPolicyDocument = true;
    }
    // extract system property to enable or disable parties
    this.getSystemProperty.findProperty('ClaimsHideClaimDocuments').then((result) => {
      if (result && result.systemProperties && result.systemProperties.length) {
        for (const variable of result.systemProperties) {
          if (variable.key === 'ClaimsHideClaimDocuments') {
            const enableParty = variable.value;
            if (enableParty === 'Enable') {
              this.sysPropShowParties = true;
            }
          } else {
            this.sysPropShowParties = false;
            // this.sysPropShowParties = false;
          }
        }
      }
    });

  }

  setIsEditClaimDocument(val: boolean) {
    this.isEditClaimDocument = val;
  }

  getIsEditClaimDocument() {
    return this.isEditClaimDocument;
  }

  getIsInsidePotentialClaim() {
    return this.isInsidePotentialClaim;
  }

  setIsInsidePotentialClaim(val: boolean) {
    this.isInsidePotentialClaim = val;
  }

  getFormViewDocuments() {
    return this.formViewDocuments;
  }

  setFormViewDocuments(form: FormGroup) {
    this.formViewDocuments = form;
  }

  getAreDocumentsViewed() {
    return this.areDocumentsViewed;
  }

  setAreDocumentsViewed(viewed: boolean) {
    this.areDocumentsViewed = viewed;
  }

  setIntegrationWF(workflow: any) {
    this.integrationWF = workflow;
  }

  getIntegrationWF(): any {
    return this.integrationWF;
  }

  setJsonClaim(claim: any) {
    this.jsonClaim = claim;
  }

  getJsonClaim(): any {
    return this.jsonClaim;
  }

  getIsUploadPotential(): boolean {
    return this.isUploadPotential;
  }

  setIsUploadPotential(val: boolean) {
    this.isUploadPotential = val;
  }

  getClaimDocuments(): Observable<any> {
    if (this.claimNumber != null && this.claimNumber.trim().length) {
      return this.httpClient.get(this.baseApiUrl + this.urlservice + '/getClaimDocuments' +
        '?claimNumber=' + this.claimNumber);
    }
  }

  getPotClaimDocuments(): Observable<any> {
    if (this.jsonClaim.idPotencialClaim) {
      return this.httpClient.get(this.baseApiUrl + this.urlservice +
        '/getPotClaimDocuments' + '?potClaimId=' + this.jsonClaim.idPotencialClaim);
    }
  }

  setPotClaimDocuments(docs: Array<DocumentEntity>) {
    this.potClaimDocuments = docs;
  }

  setAllDocuments(docs: Array<DocumentEntity>) {
    this.allDocuments = docs;
  }

  getAllDocuments(): Array<DocumentEntity> {
    return this.allDocuments;
  }

  getPotClaimDocs() {
    return this.potClaimDocuments;
  }

  setPotClaimDocumentCategories(cats: Array<DocumentCategoryEntity>) {
    this.potClaimDocumentCategories = cats;
  }

  getPotClaimDocumentCategories(): Array<DocumentCategoryEntity> {
    return this.potClaimDocumentCategories;
  }

  setPotClaimDocumentsLength(num: number) {
    this.potClaimDocumentsLength = num;
  }

  getPotClaimDocumentsLength(): number {
    return this.potClaimDocumentsLength;
  }

  updateViewedDocuments(filter): Observable<any> {
    return this.httpClient.post(this.baseApiUrlOld + this.urlservice + '/updateClaimDocInfo', {docInfo: filter});
  }

  getClaimDocumentCategories(isClaim: boolean): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(this.baseApiUrl + this.urlservice + '/getClaimDocumentCategories?isUpload=false&isClaim=' + (isClaim ? 'true' : 'false'));
  }

  getSysPropShowParties(): boolean {
     return true;
    // return this.sysPropShowParties;
  }

  setSysPropShowParties(value: boolean) {
    this.sysPropShowParties = value;
  }


  validateInvalidateDocument(doc: DocumentEntity, isValidated: boolean) {
    const metaObj = {
      CATEGORIA: undefined,
      ID_DOCUMENTO: undefined,
      MICRO_CATEGORIA: undefined,
      VALIDAZIONE: undefined
    };
    metaObj.CATEGORIA = [doc.category];
    // only fill relevant metadata
    metaObj.ID_DOCUMENTO = [doc.id];

    if (doc.microCategory === null || doc.microCategory === '') {
      metaObj.MICRO_CATEGORIA = ['?'];
    } else {
      metaObj.MICRO_CATEGORIA = [doc.microCategory];
    }

    metaObj.VALIDAZIONE = [isValidated];

    const body = {
      documentUpdate: {
        documentId: doc.id,
        uploadNote: doc.uploadNote,
        toSendEBDS: doc.toSendEBDS,
        metaInfo: metaObj,
        externalVisibility: doc.externalVisibility,
        assetType: doc.assetType,
        subTitle: doc.subtitle
      }
    };
    // consider only controls that have a value

    return this.httpClient.post(this.baseApiUrl + this.urlservice + '/documents/update', body);
  }


  downloadDocumentZip(documentids: string[], companyCode: string) {
    const body = {
      downloadDocumentZIPInput: {
        companyCode,
        documentFilter: documentids.map((v, i, arr) => ({documentId: v})),
      }
    };

    return this.httpClient.post(this.baseApiUrl + this.urlservice + '/documents/downloadZip', body);
  }

  downloadDocument(documentId: string, companyCode: string): Observable<any> {
    /* return this.httpClient.get(this.baseApiUrl + this.urlservice + '/downloadDocument?documentId='
             + documentId + '&companyCode=' + companyCode);*/
    const body = {
      downloadDocumentInput: {
        companyCode,
        documentId,
        documentIdPass: '',
        convertToPdf: false
      }
    };
    return this.httpClient.post(this.baseApiUrl + this.urlservice + '/documents/download', body);
  }

  modifyDocument(form: FormGroup, id: string) {

    const metaObj = {
      CATEGORIA: undefined,
      ID_DOCUMENTO: undefined,
      MICRO_CATEGORIA: undefined,
      COD_ANAGRAFE: undefined,
      dataRicezione: undefined,
    };
    metaObj.CATEGORIA = [form.controls.category.value];
    // only fill relevant metadata
    metaObj.ID_DOCUMENTO = [id];

    if (form.controls.category.value === form.controls.subcategory.value) {
      metaObj.MICRO_CATEGORIA = ['?'];
    } else {
      metaObj.MICRO_CATEGORIA = [form.controls.subcategory.value];
    }
    if (this.getSysPropShowParties()) {
      metaObj.COD_ANAGRAFE = [form.controls.party.value];

    }
    if (form.controls.receiptDateFormatted && form.controls.receiptDateFormatted.value) {
      metaObj.dataRicezione = [form.controls.receiptDateFormatted.value];
    }

    const body = {
      documentUpdate: {
        documentId: id,
        uploadNote: form.controls.uploadNote.value,
        assetType: form.controls.assetType.value,
        subTitle: form.controls.subtitle.value,
        toSendEBDS: form.controls.toSendEBDS.value,
        externalVisibility: form.controls.externalVisibility.value,
        metaInfo: metaObj
      }
    };
    // consider only controls that have a value

    return this.httpClient.post(this.baseApiUrl + this.urlservice + '/documents/update', body);
  }

  uploadDocuments(documents: DocumentEntity[], form: FormGroup, companyCode: string, user: string, documentViewed: boolean
  ) {
    // format documentsArray as requested by rest service
    const documentsArr = documents.map((value, index, array) => {
      const metaData = [];
      this.fillMetadata(metaData, value, companyCode, user, form);
      const assetTypeTmp = form.controls.assetType.value ? ({
          codice: form.controls.assetType.value,
          descrizione: ''
        })
        : null;

      return ({
        documentName: value.fileName,
        metaInfo: metaData,
        uploadNote: value.uploadNote,
        subTitle: value.subtitle,
        file: {
          fileName: value.fileName,
          mimeType: value.mimetype,
          fileSize: value.file.fileSize,
          binary: value.file.binary
        },
        toSendEBDS: form.controls.toSendEBDS.value,
        assetType: assetTypeTmp,

      });


    });
    // rest service /documents/upload object has documentsArray as property for multiple docs
    const body = {
      storeDocumentInput: {
        companyCode: this.companyCode,
        documentsArray: documentsArr,
        // isDocumentViewed: Boolean(documentViewed), // presa visione documenti non presente su uca
      }
    };
    if (this.isUploadPotential) {
      return this.httpClient.post(this.baseApiUrl + this.urlservice + '/documents/potclaim/upload', body);
    } else {
      return this.httpClient.post(this.baseApiUrl + this.urlservice + '/documents/upload', body);

    }

  }

  fillMetadata(metaData: any[], value: DocumentEntity, companyCode: string, user: string, form: FormGroup) {
    metaData.push({
      key: 'COD_COMPAGNIA',
      values: [{value: this.companyCode}]
    });
    metaData.push({
       key: 'NUM_PRATICA',
      // key: 'numDenuncia',
      values: [{value: this.reportNumber}]
    });
    if (value.category && !value.microCategory){
      metaData.push({
        key: 'CATEGORIA',
        values: [{value: value.category}]
      });
      metaData.push({
        key: 'MICRO_CATEGORIA',
        values: [{value: '?'}]
      });
    }else if (value.category && value.microCategory && value.microCategory !== '?'){
      metaData.push({
        key: 'CATEGORIA',
        values: [{value: value.category}]
      });

      metaData.push({
        key: 'MICRO_CATEGORIA',
        values: [{value: value.microCategory}]
      });
    }

    metaData.push({
      key: 'COD_ANAGRAFE',
      values: [{value: value.codAnagrafe}]
    });
    metaData.push({
      key: 'NOME_UTENTE',
      values: [{value: user}]
    });
    metaData.push({
      key: 'AGENZIA',
      values: [{value: this.agencyCode}]
    });
    metaData.push({
      key: 'ESERCIZIO',
      // tslint:disable-next-line:radix
      values: [{value: parseInt(this.exercise)}]
    });
    metaData.push({
      key: 'ALERT_NEW_DOC',
      values: [{value: form.controls.alertNewDoc.value}]
    });
    metaData.push({
      key: 'CANALE_RICEZIONE',
      values: [{value: 'CLS'}]
    });
    metaData.push({
      key: 'ACCESSO',
      values: [{value: 'P'}]
    });
    metaData.push({
      key: 'note',
      values: [{}]
    });
    // if is claim document
    if (!this.isUploadPotential) {
      metaData.push({
         key: 'NUM_SINISTRO',
        values: [{value: this.claimNumber}]
      });
    }
    /*    metaData.push({
          key: 'MICRO_CATEGORIA',
          values: [{value: value.microCategory}]
        });*/
    metaData.push({
      key: 'ALERT_NEW_DOC',
      values: [{value: form.controls.alertNewDoc.value}]
    });
    metaData.push({
      key: 'NUM_PAGINE',
      values: [{value: form.controls.numPages.value}]
    });
    metaData.push({
      key: 'DATA_CARICAMENTO',
      values: [{value: new Date()}]
    });

    if (form.contains('receiptDateFormatted')) {
      metaData.push({
        key: 'dataRicezione',
        values: [{value: form.controls.receiptDateFormatted.value}]
      });
    }

    if (form.contains('linkedDamage') && form.controls.linkedDamage.value !== '') {
      metaData.push({
        key: 'ID_DAMAGE_REQUEST',
        values: [{value: form.controls.linkedDamage.value}]
      });
    }

  }

  getAgencyCode(): string {
    return this.agencyCode;
  }

  setAgencyCode(code: string) {
    this.agencyCode = code;
  }

  getCompanyCode(): string {
    return this.companyCode;
  }

  setCompanyCode(code: string) {
    this.companyCode = code;
  }

  getClaimExercise(): string {
    return this.exercise;
  }

  setClaimExercise(ex: string) {
    this.exercise = ex;
  }

  getClaimNumber(): string {
    return this.claimNumber;
  }


  setClaimNumber(id: string) {
    this.claimNumber = id;
  }

  getDocuments(): Array<DocumentEntity> {
    return this.documents;
  }

  setDocuments(docs: Array<DocumentEntity>) {
    this.documents = docs;
  }

  getDocumentCategories(): Array<DocumentCategoryEntity> {
    return this.documentCategories;
  }

  setDocumentCategories(cats: Array<DocumentCategoryEntity>) {
    this.documentCategories = cats;
  }

  getNotInvolvedParties(): ApiParty[] {
    return this.notInvolvedParties;
  }

  setNotInvolvedParties(parties: ApiParty[]) {
    this.notInvolvedParties = parties;
  }

  getParties(): ApiParty[] {
    return this.parties;
  }

  setParties(parties: ApiParty[]) {
    this.parties = parties;
  }

  setReportNumber(reportNumber: string) {
    this.reportNumber = reportNumber;
  }

  getReportNumber(): string {
    return this.reportNumber;
  }

  setUploadParty(party: ApiParty) {
    this.uploadParty = party;
  }

  getUploadParty(): ApiParty {
    return this.uploadParty;
  }

  setUploadCategory(category: DocumentCategoryEntity) {
    this.uploadCategory = category;
  }

  getUploadCategory(): DocumentCategoryEntity {
    return this.uploadCategory;
  }

  setUploadSubcategory(subcat: DocumentSubcategoryEntity) {
    this.uploadSubcategory = subcat;
  }

  getUploadSubcategory(): DocumentSubcategoryEntity {
    return this.uploadSubcategory;
  }

  setEditParty(party: ApiParty) {
    this.editParty = party;
  }

  getEditParty(): ApiParty {
    return this.editParty;
  }

  setEditCategory(category: DocumentCategoryEntity) {
    this.editCategory = category;
  }

  getEditCategory(): DocumentCategoryEntity {
    return this.editCategory;
  }

  setEditSubcategory(subcat: DocumentSubcategoryEntity) {
    this.editSubcategory = subcat;
  }

  getEditSubcategory(): DocumentSubcategoryEntity {
    return this.editSubcategory;
  }

  setEditDocument(doc: DocumentEntity) {
    this.editDocument = doc;
  }

  getEditDocument(): DocumentEntity {
    return this.editDocument;
  }

  setCanDownloadPolicyDocuments(val: boolean) {
    this.canDownloadPolicyDocument = val;
  }

  getCanDownloadPolicyDocuments(): boolean {
    return this.canDownloadPolicyDocument;
  }

  openDocumentViewer(claimNum: string, claimId: string, companyCode: string, username: string): Observable<any> {
    const body = {
      claimId,
      companyCode,
      username,
      claimNum
    };


    // https://int-uca.rgi.it/api/rest/v2/claims/2019_TRM_0516/document/viewer/path?companyCode=471&username=ADMIN
    if (this.isUploadPotential) {
      // return this.httpClient.post(this.baseApiUrl + this.urlservice + '/' + claimNum + '/document/viewer/path', body);
    } else if (username) {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.get(this.baseApiUrl + this.urlservice + '/' + claimNum + '/document/viewer/path?companyCode=' + companyCode + '&username=' + username);

    }
  }

}

import {Injectable} from '@angular/core';
import {UserService} from '@rgi/rx/auth';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {combineLatest, forkJoin, Observable, of} from 'rxjs';
import {catchError, concatMap, map, mergeMap} from 'rxjs/operators';
import {GroupPolicyDateAdapter} from '../../adapters/ngbDateCustomParserFormatter ';
import {OPERATIONSCODE, PRODUCTSCATEGORY, SALEPOINTTYPE} from '../../group-policy-constants/general';
import {ROUTES_GP_POLDATA} from '../../group-policy-constants/routes-constants';
import {
  DataForSteps,
  DemandAndNeeds,
  NewFlowIssueRequest,
  NewFlowIssueResponse,
  ProductsReq
} from '../../group-policy-models/group-policy-issue-home';
import {GroupPolicyResourceService} from '../../group-policy-resources/group-policy-resource.service';
import {GroupPolicyApiRestErrorService} from '../../group-policy-services/group-policy-api-rest-error.service';
import {GroupPolicyIntegrationService} from '../../group-policy-services/group-policy-integration.service';
import {OperatorLight} from '../../services/external-service';
import {GroupPolicyStateHome} from '../group-policy-state';
import {BaseEntity} from '../../group-policy-models/group-policy-issue-policy-data';

@Injectable({
  providedIn: 'root'
})
export class GroupPolicyStatelessOpHomeService {

  constructor(protected groupPolicyService: GroupPolicyResourceService,
              protected userService: UserService,
              protected dateAdapter: GroupPolicyDateAdapter,
              protected routingService: RoutingService,
              protected integrationService: GroupPolicyIntegrationService,
              protected gpErrorService: GroupPolicyApiRestErrorService) {
  }

  initHome$(stateHome$: Observable<GroupPolicyStateHome>): Observable<GroupPolicyStateHome> {
    return stateHome$
      .pipe(
        map((firstState) => {
            if (this.userService.isLoggedIn()) {
              if (this.userService.getUser<OperatorLight>().salePoint &&
                this.userService.getUser<OperatorLight>().salePoint.salePointType.codice !== SALEPOINTTYPE.ROOT) {
                firstState.selectedNode = this.userService.getUser<OperatorLight>().salePoint.code;
              }
            }
            firstState.issueDate = new Date().toISOString().slice(0, 10);
            firstState.effectiveDate = new Date().toISOString().slice(0, 10);
            return firstState;
          }
        ),
        mergeMap((stateHome) => {
            return forkJoin(of(stateHome),
              this.groupPolicyService.getProducts$(this.createProdReq(stateHome)),
              this.groupPolicyService.getNodes$(),
              this.groupPolicyService.getCompanies$());
          }
        ),
        map(
          ([st, products, nodes, companies]) => {
            st.nodes = nodes;
            st.companies = companies;
            st.products = products;
            return st;
          }
        ),
      );
  }

  protected createProdReq(stateHome: GroupPolicyStateHome): ProductsReq {
    return {
      codeSP: stateHome.selectedNode,
      filterProductsCategory: PRODUCTSCATEGORY.COLLECTIVE.toString(),
      filterSalableProduct: true
    };
  }

  protected getNewIssueReq(stateHome: GroupPolicyStateHome): NewFlowIssueRequest {
    const startIssueReq: NewFlowIssueRequest = {} as NewFlowIssueRequest;
    startIssueReq.effectDate = this.dateAdapter.toApi(stateHome.effectiveDate);
    startIssueReq.posCode = stateHome.selectedNode;
    startIssueReq.productCode = stateHome.selectedProduct;
    startIssueReq.issueDate = stateHome.issueDate;
    startIssueReq.companyCode = stateHome.selectedCompany;
    return startIssueReq;
  }

  protected createObjForNextStep(respNewFlowIssue: NewFlowIssueResponse): DataForSteps {
    return {
      resId: respNewFlowIssue.uuid,
      product: respNewFlowIssue.product,
      operationCode: OPERATIONSCODE.ISSUE,
      node: respNewFlowIssue.node
    } as DataForSteps;
  }

  searchAvailableProducts$(groupPolicyStateHome$: Observable<GroupPolicyStateHome>): Observable<GroupPolicyStateHome> {
    return groupPolicyStateHome$
      .pipe(
        mergeMap(
          (st) => {
            return combineLatest(of(st), this.groupPolicyService.getProducts$(this.createProdReq(st)));
          }
        ),
        map(
          ([st, productsResp]) => {
            if (!productsResp || !productsResp.find(prod => prod.code === st.selectedProduct)) {
              st.selectedProduct = undefined;
            }
            st.products = productsResp;
            return st;
          }
        )
      );
  }

  startNewFlow$(groupPolicyStateHome$: Observable<GroupPolicyStateHome>, demandAndNeeds: DemandAndNeeds,
                activeRoute: ActiveRoute): Observable<GroupPolicyStateHome> {
    return groupPolicyStateHome$.pipe(
      concatMap((st: GroupPolicyStateHome) => {
        return this.groupPolicyService.newFlowIssueGroupPolicy(this.getNewIssueReq(st), demandAndNeeds).pipe(
          map(resp => {
            const productInfo = st.products.find(p => p.code === st.selectedProduct);
            resp.product = new BaseEntity(productInfo.code, productInfo.description);
            resp.node = st.selectedNode;
            this.integrationService.navigate(this.routingService, ROUTES_GP_POLDATA, this.createObjForNextStep(resp), activeRoute);
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateHome) => st)
    );
  }

  showCompanyField() {
    return true;
  }
}

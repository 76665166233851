import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxUserService, UserService} from './user.service';
import {RgiRxUserAuthorizationService, UserAuthorizationService} from './user-authorization.service';
import {AuthorizedDirective} from './authorized.directive';


@NgModule({
  declarations: [AuthorizedDirective],
  imports: [
    CommonModule
  ],
  exports: [AuthorizedDirective],
  providers: [
    {
      provide: UserService, useExisting: RgiRxUserService
    },
    {
      provide: UserAuthorizationService, useExisting: RgiRxUserAuthorizationService
    }
  ]
})
export class UserModule {
}

import {ActiveRoute, Routes, RoutingService} from '@rgi/rx/router';
import {StateStoreService} from '@rgi/rx/state';
import {DashboardManagerService} from './dashboard/state-manager/dashboard-manager.service';
import {DashboardResourceService} from './dashboard/resources/dashboard-resources.service';
import {DashboardSearchComponent} from './dashboard/dashboard-search/dashboard-search.component';
import {DashboardSummaryComponent} from './dashboard/dashboard-summary/dashboard-summary.component';
import {DashboardDetailComponent} from './dashboard/dashboard-detail/dashboard-detail.component';

export const ICD_ROUTES: Routes = [
    {
        route: 'dashboard-search',
        component: DashboardSearchComponent,
        providers: [
            {
                provide: DashboardManagerService,
                useClass: DashboardManagerService,
                deps: [ActiveRoute, StateStoreService, RoutingService, DashboardResourceService],
            }
        ]
    },
    {
        route: 'dashboard-summary',
        component: DashboardSummaryComponent,
        providers: [
            {
                provide: DashboardManagerService,
                useClass: DashboardManagerService,
                deps: [ActiveRoute, StateStoreService, RoutingService, DashboardResourceService],
            }
        ]
    },
    {
        route: 'dashboard-detail',
        component: DashboardDetailComponent,
        providers: [
            {
                provide: DashboardManagerService,
                useClass: DashboardManagerService,
                deps: [ActiveRoute, StateStoreService, RoutingService, DashboardResourceService],
            }
        ]
    }
];

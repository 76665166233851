<div [formGroup]="formGroup" id="claim-detail-page">

    <div data-qa="claim-detail" *ngIf="claimDetailVisible">

        <div class="row">
            <div class="col-md-12 life-detail-margin-box life-detail-box" id="consulting-detail">

                <lpc-insured-data [insured]="claimDetail?.insured"></lpc-insured-data>

                <div class="col-md-12 life-detail-first-info">
                    <div class="core-header-icon">
                        <span class="header-icon rgifont rgi-folder"></span>
                        <span class="header-text" translate>lpc_claim_data</span>
                        <span class="pull-right header-text"></span></div>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <pnc-datepicker [customFormGroup]="formGroup.get('dates')" customFormControlName="claimDate"
                            label="{{'lpc_Data_sinistro'}}">
                        </pnc-datepicker>
                    </div>
                    <div class="col-lg-6">
                        <pnc-datepicker [customFormGroup]="formGroup.get('dates')" customFormControlName="notificationDate"
                            label="{{'lpc_Data_Pervenimento_Denuncia'}}"></pnc-datepicker>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <pnc-datepicker [customFormGroup]="formGroup.get('dates')"
                            customFormControlName="reportDate" label="{{'lpc_Data_Denuncia_Sinistro'}}">
                        </pnc-datepicker>
                    </div>
                    <div class="col-lg-6">
                        <label class="lbl" translate>lpc_Tipo_sinistro</label><br>
                        <input class="form-control" [value]="claimDetail?.type.descrizione" disabled>
                    </div>
                </div>
                <div formGroupName="factors" class="row">
                    <lpc-factor-adapter #factorAdapter [form]="formGroup.get('factors')"
                        [factors]="claimDetail?.factors" [summary]="false">
                    </lpc-factor-adapter>
                </div>

                <lpc-claim-policies [claimType]="claimDetail?.type.codice" [policies]="claimDetail?.polices" [sessionIdFrom]="data?.sessionId"></lpc-claim-policies>

            </div>
        </div>

        <div class="btn-group btn-group-justified">
            <div class="btn-group">
                <button type="button" (click)="back()" class="btn btn-warning btn-secondary" translate>lpc_BACK</button>
            </div>
            <div *ngIf="cancel" class="btn-group">
                <button type="button" (click)="changeStatus('3')" class="btn btn-warning" translate>lpc_annulla_denuncia</button>
            </div>
            <div *ngIf="update" class="btn-group">
                <button type="button" (click)="updateClaim()" class="btn btn-warning" translate>lpc_modifica_denuncia</button>
            </div>
            <div *ngIf="confirm" class="btn-group">
                <button type="button" (click)="changeStatus('2')" class="btn btn-warning" translate>lpc_conferma_denuncia</button>
            </div>

        </div>
    </div>

</div>

import { ChangeDetectorRef, Component, Inject, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { questionnaireCacheServiceInjectionToken } from '@rgi/questionnaires-manager';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';
import {
  BeneficiaryDefinition,
  BeneficiaryRole,
  PaymentTypeDefinition,
  PostsalesError,
  Role
} from '../../models/postsales-operations-response.model';
import { DistributionConfigDefinition } from '../../modules/distribution-toggle/distribution-toggle.model';
import { LpcClaimBeneficiariesComponent } from '../../modules/lpc-beneficiary/lpc-claim-beneficiaries/lpc-claim-beneficiaries.component';
import { LpcBeneficiaryUtils } from '../../modules/lpc-beneficiary/util/lpc-beneficiary-utils';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { LpcBeneficiaryService } from '../../services/beneficiary.service';

export interface Liquidation {
  isSelected: boolean;
  movementDescription: string;
  effectiveDate: string;
  issueDate: string;
  grossAmount: string;
  settlementKey: string;
}

@Component({
  selector: 'lpc-change-settlement-benef',
  templateUrl: './change-settlement-benef.component.html',
  styleUrls: ['./change-settlement-benef.component.css'],
  providers: [
    // TODO: check if there is a better way
    // @ts-ignore
    questionnaireCacheServiceInjectionToken
  ]
})
export class ChangeSettlementBenefComponent extends AbsOperationComponent {

  protected operationDataKey = 'change-settlement-benef';
  protected anagSubj = null;

  public liquidationDefinition: Liquidation[];
  public beneficiaryDefault: boolean;

  @ViewChild('beneficiaries') beneficiaries: LpcClaimBeneficiariesComponent;

  private _beneficiaryRoleDefinitions: BeneficiaryRole[] = [];
  distributionConfiguration: DistributionConfigDefinition = null;


  get beneficiaryRoleDefinitions(): BeneficiaryRole[] {
    return this._beneficiaryRoleDefinitions;
  }

  private _beneficiaryDefinitions: BeneficiaryDefinition[] = [];
  get beneficiaryDefinitions(): BeneficiaryDefinition[] {
    return this._beneficiaryDefinitions;
  }

  get beneficiaryType(): string {
    if (!!this.beneficiaryDefinitions.length) {
      return this.beneficiaryDefinitions[0].code;
    }
  }

  private _beneficiaryData: any[] = [];

  get idAssicurato(): string {
    if (this._beneficiaryDefinitions.length) {
      return this._beneficiaryDefinitions[0].idAssicurato;
    } else if (!!this._beneficiaryData && this._beneficiaryData.length) {
      return this._beneficiaryData[0].beneficiary && this._beneficiaryData[0].beneficiary.idAssicurato;
    } else {
      return EMPTY_STR;
    }
  }

  private _paymentTypes: PaymentTypeDefinition[] = [];
  public get paymentTypes(): PaymentTypeDefinition[] {
    return this._paymentTypes;
  }

  public get feErrors(): PostsalesError[] {
    return LpcBeneficiaryUtils.getSettlementBeneficiariesErrors(this.formGroup, this.translate);
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService,
    protected beneficiaryService: LpcBeneficiaryService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe(response => {
        this.beneficiaryDefault = response.data.operationData.data.beneficiariesDefault;
        LpcBeneficiaryUtils.setBeneficiaryRoles(response.definitions.BenefiacyRoles as BeneficiaryRole[]);
        if (response.definitions.beneficiaries != null) {
          this._beneficiaryDefinitions = (response.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
        }
        this._paymentTypes = response.definitions.paymentTypes as PaymentTypeDefinition[];

        if (!!response.data.operationData.data) {
          this.liquidationDefinition = response.data.operationData.data.selectableSettlements;
          this._beneficiaryData = response.data.operationData.data.beneficiaries as any[];
          if (!!this._beneficiaryData) {
            this.formGroup.get('beneficiaries').setValue(
              (this._beneficiaryData as any[]).map(beneficiary => {
                return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
              }),
              { emitEvent: false }
            );
          }
        }
      }),
    );
  }

  protected getFormGroup() {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      selectsettlement: new UntypedFormControl(null, [Validators.required]),
      beneficiaries: new UntypedFormControl(),
      notes: new UntypedFormControl() // Text-area note
    });
  }

  protected getTransformedOperationData(): any {
    const beneficiaries = !!this.formGroup.getRawValue().beneficiaries ? this.formGroup.getRawValue().beneficiaries : [];
    return {
      beneficiaries: beneficiaries
        .map(claimBeneficiary => {
          return LpcBeneficiaryUtils.convertToBeneficiaryRequest(
            claimBeneficiary,
            this.distributionConfiguration && this.distributionConfiguration.totalAmount
          );
        }),
      selectableSettlements: this.getSettlements(),
      beneficiariesDefault: this.beneficiaryDefault
    };
  }

  onNext(step: string = null, publish: boolean = false, context = step, isConfirmAndAccept = false) {
    if (step === 'selectsettlement') {
      if (!!this.formGroup.get('selectsettlement').value) {
        super.onNext(step, publish, context, isConfirmAndAccept);
      } else {
        this.setCustomFeErrors(step, 'You must select the settlement to perform the variation', 'error');
      }
    } else if (step === 'beneficiaries') {
      if (this.formGroup.get('beneficiaries').valid &&
      this.beneficiaryService.checkAllQuestionnaires(this.stepper.activeStep.id, s => this.setQuestionaryError(s))) {
        this.$subscriptions.push(
          this.beneficiaryService.persistQuestionnaires(this.stepper.activeStep.id).pipe(
              switchMap(_ => {
                return this.plcQuestService.prevalQuest(this.operationDataKey, step, this);
              }),
              switchMap(result => {
                this.questFactorsArray = result;
                this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, step, this);
                if (!!this.avcQuestionnaire) {
                  this.avcQuestionnaire.questionnaireManager.questList = this.plcQuestService.checkQuestionnariesOrder(
                    this.avcQuestionnaire
                  );
                }
                return this.updateDraft(step);
              })
            ).subscribe(result => {
              this.detectChanges();
              const bErrors = result.errors && result.errors.length > 0;
              const toBePublished = (isConfirmAndAccept && !bErrors) || this.stepper.isAtLastStep;
              if (!this.hasBlockingErrorsOnSteps(context)) {
                this.doPublishOrNext(toBePublished);
              }
            })
        );
      }/*  else {
        this.setFeErrors(step);
      } */
    } else {
      super.onNext(step, publish, context);
    }
  }

  updateDraft(step: string, reload?: boolean, opDataType?: string): Observable<any> {
    return super.updateDraft(step, reload, opDataType).pipe(
      tap(result => {
        this.beneficiaryDefault = result.data.operationData.data.beneficiariesDefault;
        if (result.definitions.beneficiaries != null) {
          this._beneficiaryDefinitions = (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
        }

        if (result.definitions.distributionDefinition && step !== 'beneficiaries') {
          this.distributionConfiguration = this.getDistributionConfiguration(result.definitions);
        }

        this.formGroup.get('beneficiaries').setValue(
          this.getClaimBeneficiaries(result),
          { emitEvent: false }
        );
        if (!!result.data.operationData && !!result.data.operationData.data) {
          this.liquidationDefinition = result.data.operationData.data.selectableSettlements;
        }
        this._paymentTypes = result.definitions.paymentTypes;
      })
    );
  }

  getDistributionConfiguration(definition: any): DistributionConfigDefinition {
    const settlement = this.getSettlements().find(liq => liq.isSelected);
    return {
      totalAmount: settlement && Number(settlement.grossAmount),
      showDistributionSwitch: definition.distributionDefinition.showSwitch,
      distributionType: definition.distributionDefinition.type
    };
  }

  getClaimBeneficiaries(result: any) {
    if (result.data.operationData.data.beneficiaries) {
      return (result.data.operationData.data.beneficiaries as any[]).map(beneficiary => {
        return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
      });
    }
    return [];
  }

  getSettlements(): Liquidation[] {
    const selectedSettlement = this.formGroup.get('selectsettlement').value;
    if (!!this.liquidationDefinition) {
      this.liquidationDefinition.map((set) => {
        if (set.settlementKey === selectedSettlement) {
          set.isSelected = true;
        } else {
          set.isSelected = false;
        }
      });
    }

    return this.liquidationDefinition;
  }

  public onAddedSubject($event: Role) {
    if ($event === null) {
      this.openModal('lpc_found_duplicate', 'lpc_duplicate_subject_message', true);
    }
    this.$subscriptions.push(
      this.plcQuestService.prevalQuest(this.operationDataKey, EMPTY_STR, this).subscribe(result => {
        this.questFactorsArray = result;
        this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, EMPTY_STR, this);
      })
    );
  }

  onTriggerQuestPreval($event: any) {
    if (!!$event) {
      this.anagSubj = $event;
      this.$subscriptions.push(
        this.plcQuestService.prevalQuest(this.operationDataKey, EMPTY_STR, this).subscribe(result => {
          this.questFactorsArray = result;
          this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, EMPTY_STR, this);
        })
      );
    }
  }

}

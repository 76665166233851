import {QuestionnaireFlatI} from '../../../../models/domain-models/questionnaire-flatI';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Message} from '../../../../models/message';
import {RgiRxTranslationService} from '@rgi/rx/i18n';

@Component({
  selector: 'lib-re-issue-questionnaire-modal',
  templateUrl: './re-issue-questionnaire-modal.component.html'
})
export class ReIssueQuestionnaireModalComponent implements OnInit {


  @Output() modalClose = new EventEmitter();

  @Input() set questionnaireFlat(questionnaire: QuestionnaireFlatI) {
    // this.questionnaire = this.setNullAnswers(questionnaire);
    this.questionnaire = questionnaire;
  }

  // @ViewChild(QuestionnaireComponent, {static: false}) questionnaireComp: QuestionnaireComponent;

  questionnaire: QuestionnaireFlatI;
  questionnaireComplete = false;
  questionnaireSubmitted = false;
  isNextDisabled = false;

  errorMessage: string;
  validationMessages: Message[] = [];

  constructor(
    protected translate: RgiRxTranslationService
  ) {

  }

  // setNullAnswers(questionnaireFlat: QuestionnaireFlatI) {
  //   if (questionnaireFlat) {
  //     questionnaireFlat.questions.forEach(question => {
  //       question.answers.forEach(answer => {
  //         if (answer.value === '-1') {
  //           answer.value = null;
  //         }
  //       });
  //     });
  //   }
  //   return questionnaireFlat;
  // }

  ngOnInit() {
  }

  closeModal() {
    this.questionnaireSubmitted = false;
    this.modalClose.emit();
  }

  save() {
    // this.questionnaireSubmitted = true;
    // // this.questionnaireComplete = this.questionnaireComp.validate();
    // this.questionnaireComp.validate();
    //
    // if (this.isQuestionnaireComplete(this.questionnaireComp.questionnaireFlat)) {
    //   this.modalClose.emit();
    // } else {
    //   this.setErrorMessages();
    // }
  }

  isQuestionnaireComplete(questionnaire: QuestionnaireFlatI) {
    let questionnaireComplete = true;

    questionnaire.questions.forEach(question => {
      question.answers.forEach(answer => {
        if (!answer.value || answer.value === '-1') {
          questionnaireComplete = false;
        }
      });
    });

    // this.questionnaireComplete = questionnaireComplete;
    return questionnaireComplete;
  }

  questionnaireNotCompiled() {
    // if (this.questionnaireComp && this.questionnaireSubmitted) {
    //   if (this.questionnaireComp.validate()) {
    //     this.validationMessages = [];
    //     this.isNextDisabled = false;
    //   } else {
    //     this.isNextDisabled = true;
    //   }
    // }
    // return this.isNextDisabled;
  }

  setErrorMessages() {
    this.validationMessages = [];
    this.validationMessages.push(new Message('', this.errorMessage));
  }
}

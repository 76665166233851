import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { Asset } from '../../dto/policy/asset';
import { DamageFactor } from '../../dto/policy/damage-factor';
import { Risk } from '../../dto/policy/risk';
import { Policy } from '../../dto/policy/policy';
import { formatNumber, formatDate } from '@angular/common';
import { ApiPolicy } from '../../model/api/policy/api-policy';
import { ApiAsset } from '../../model/api/policy/api-asset';
import { ApiDamageFactor } from '../../model/api/policy/api-damage-factor';
import { ApiRisk } from '../../model/api/policy/api-risk';
import { ApiDamageSubject } from '../../model/api/policy/api-damage-subject';
import { DamageSubject } from '../../dto/policy/subject';
import { ApiDamageLocation } from '../../model/api/policy/api-damage-location';
import { Factor } from '../../dto/policy/factor';
import { DamageLocation } from '../../dto/policy/damage-location';
import { ApiActivity } from '../../model/api/policy/api-activity';
import { Activity } from '../../dto/policy/activity';
import { ApiVehicle } from '../../model/api/policy/api-vehicle';
import { Vehicle } from '../../dto/policy/vehicle';
import { ApiFactor } from '../../model/api/policy/api-factor';
import { ApiTranslatorService } from '../api-translator.service';
import { Formats } from '../../constants/policy/formats';
import { ApiHolderResidence } from '../../model/api/policy/ApiHolderResidence';
import { Toponym } from '../../dto/policy/toponym';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Injectable({
  providedIn: 'root'
})
export class ApiTranslatorPolicyService extends ApiTranslatorService {
  private tempFormatFix = true;

  apiPolicyToDtoPolicy(contractRes: ApiPolicy): Policy {
    const policyDetail = new Policy();

    policyDetail.number = contractRes.policyNumber;
    policyDetail.description = contractRes.description;
    policyDetail.effectDate = contractRes.effectDate;
    policyDetail.expirationDate = contractRes.expirationDate;
    policyDetail.holder = contractRes.contractingSubject;
    if (contractRes.contractor && contractRes.contractor.subject && contractRes.contractor.subject.residence) {
      policyDetail.residenceHolder = this.apiHolderResidenceToDtoLocations(contractRes.contractor.subject.residence);
    }
    policyDetail.assets = this.apiAssetsToDtoAssets(contractRes.assets);
    policyDetail.nodeCode = contractRes.nodeCode;


    return policyDetail;
  }


  apiHolderResidenceToDtoLocations(apiHolderResidence: ApiHolderResidence): DamageLocation {
    const damageLocation = new DamageLocation();
    damageLocation.address = apiHolderResidence.placeAddress;
    damageLocation.adminLevel2 = apiHolderResidence.adminLevel2;
    damageLocation.adminLevel3 = apiHolderResidence.adminLevel3;
    damageLocation.city = apiHolderResidence.city;
    damageLocation.formatAddress = apiHolderResidence.formatAddress;
    damageLocation.houseNumber = apiHolderResidence.number;
    damageLocation.nationAbbreviation = apiHolderResidence.countryCode;
    damageLocation.province = apiHolderResidence.province ? apiHolderResidence.province.codice : null;
    damageLocation.zipCode = apiHolderResidence.cap;
    if (apiHolderResidence.toponym) {
      damageLocation.idToponym = new Toponym();
      damageLocation.idToponym.description = apiHolderResidence.toponym.descrizione;
    }
    return damageLocation;

  }

  apiAssetsToDtoAssets(apiAssets: ApiAsset[]): Asset[] {
    const result = [];
    for (const assset of apiAssets) {
      result.push(this.apiAssetToDtoAsset(assset));
    }

    return result;
  }

  apiAssetToDtoAsset(apiAsset: ApiAsset): Asset {
    const asset = new Asset();

    if (apiAsset.asset) {
      asset.code = apiAsset.asset.code;
    }
    asset.description = apiAsset.description;

    if (apiAsset.risks) {
      asset.risks = this.apiRisksToDtoRisks(apiAsset.risks);
    }

    if (apiAsset.factors) {
      asset.factors = this.apiDamageFactorsToDtoDamageFactors(apiAsset.factors);
    }

    if (apiAsset.subjects) {
      asset.subjects = this.apiDamageSubjectsToDtoDamageSubjects(apiAsset.subjects);
    }

    if (apiAsset.archiveSubjects) {
      asset.archiveSubjects = this.apiDamageSubjectsToDtoDamageSubjects(apiAsset.archiveSubjects);
    }

    if (apiAsset.location) {
      asset.locations = this.apiLocationsToDtoLocations(apiAsset.location);
    }

    if (apiAsset.activity) {
      asset.activities = this.apiActivitesToDtoActivities(apiAsset.activity);
    }

    if (apiAsset.factors) {
      asset.factors = this.apiDamageFactorsToDtoDamageFactors(apiAsset.factors);
    }

    if (apiAsset.clauses) {
      asset.clauses = apiAsset.clauses;
    }

    if (apiAsset.vehicle) {
      asset.vehicles = this.apiVehiclesToDtoVehicles(apiAsset.vehicle);
    }

    return asset;
  }

  apiActivitesToDtoActivities(apiActivities: ApiActivity[]): Activity[] {
    const activities = [];
    for (const apiActivity of apiActivities) {
      activities.push(this.apiActivityToDtoActivitie(apiActivity));
    }
    return activities;
  }

  apiActivityToDtoActivitie(apiActivity: ApiActivity): Activity {
    const activity = new Activity();
    activity.code = apiActivity.code;
    activity.description = apiActivity.description;
    activity.principal = apiActivity.principal;
    return activity;
  }

  apiVehiclesToDtoVehicles(apiVehicles: ApiVehicle[]): Vehicle[] {
    const vehicles = [];
    for (const apiActivity of apiVehicles) {
      vehicles.push(this.apiVehicleToDtoVehicle(apiActivity));
    }
    return vehicles;
  }

  apiVehicleToDtoVehicle(apiActivity: ApiVehicle): Vehicle {
    const vehicle = new Vehicle();

    vehicle.classAsset = apiActivity.classAsset;
    vehicle.extensionAsset = apiActivity.extensionAsset;
    vehicle.licencePlateNumber = apiActivity.licencePlateNumber;
    vehicle.licencePlateType = apiActivity.licencePlateType;
    vehicle.specialAbbreviation = apiActivity.specialAbbreviation;
    vehicle.useAsset = apiActivity.useAsset;

    return vehicle;
  }

  apiDamageSubjectsToDtoDamageSubjects(apiSubjects: ApiDamageSubject[]): DamageSubject[] {
    const subjects = [];
    for (const apiSubject of apiSubjects) {
      subjects.push(this.apiDamageSubjectToDtoDamageSubject(apiSubject));
    }
    return subjects;
  }

  apiDamageSubjectToDtoDamageSubject(apiSubject: ApiDamageSubject): DamageSubject {
    const damageSubject = new DamageSubject();
    damageSubject.denomination = apiSubject.denomination;
    damageSubject.idSubjectLock = apiSubject.idSubjectLock;
    damageSubject.name = apiSubject.name;
    damageSubject.personType = apiSubject.personType;
    damageSubject.roleType = apiSubject.roleType;
    damageSubject.subject = apiSubject.subject;
    damageSubject.surname = apiSubject.surname;
    return damageSubject;
  }

  apiDamageFactorsToDtoDamageFactors(apiFactors: ApiDamageFactor[]): DamageFactor[] {
    const dfs = [];
    if (apiFactors) {
      for (const apiFactor of apiFactors) {
        dfs.push(this.apiDamageFactorToDamageFactor(apiFactor));
      }
    }
    return dfs;
  }

  apiLocationsToDtoLocations(apiLocations: ApiDamageLocation[]): DamageLocation[] {
    const damageLocations = [];
    for (const damageLocation of apiLocations) {
      damageLocations.push(this.apiLocationToDtoLocation(damageLocation));
    }
    return damageLocations;
  }

  apiLocationToDtoLocation(apiDamageLocation: ApiDamageLocation): DamageLocation {
    const damageLocation = new DamageLocation();
    damageLocation.address = apiDamageLocation.address;
    damageLocation.adminLevel2 = apiDamageLocation.adminLevel2;
    damageLocation.adminLevel3 = apiDamageLocation.adminLevel3;
    damageLocation.city = apiDamageLocation.city;
    damageLocation.formatAddress = apiDamageLocation.formatAddress;
    damageLocation.houseNumber = apiDamageLocation.houseNumber;
    damageLocation.nationAbbreviation = apiDamageLocation.nationAbbreviation;
    damageLocation.principal = apiDamageLocation.principal;
    damageLocation.province = apiDamageLocation.province;
    damageLocation.zipCode = apiDamageLocation.zipCode;
    damageLocation.idToponym = apiDamageLocation.idToponym;
    return damageLocation;
  }

  apiAddressToDtoLocation(apiDamageLocation: ApiDamageLocation): DamageLocation {
    const damageLocation = new DamageLocation();
    damageLocation.address = apiDamageLocation.address;
    damageLocation.adminLevel2 = apiDamageLocation.adminLevel2;
    damageLocation.adminLevel3 = apiDamageLocation.adminLevel3;
    damageLocation.city = apiDamageLocation.city;
    damageLocation.formatAddress = apiDamageLocation.formatAddress;
    damageLocation.houseNumber = apiDamageLocation.houseNumber;
    damageLocation.nationAbbreviation = apiDamageLocation.nationAbbreviation;
    damageLocation.principal = apiDamageLocation.principal;
    damageLocation.province = apiDamageLocation.province;
    damageLocation.zipCode = apiDamageLocation.zipCode;
    damageLocation.idToponym = apiDamageLocation.idToponym;
    return damageLocation;
  }

  apiDamageFactorToDamageFactor(rsFactor: ApiDamageFactor): DamageFactor {
    const df = new DamageFactor();
    df.dateValue = rsFactor.dateValue;
    df.factor = this.apiFactorToDtoFactor(rsFactor.factor);
    df.numberValue = rsFactor.numberValue;
    df.stringValue = rsFactor.stringValue;
    df.type = rsFactor.type;
    df.factorType = rsFactor.factorType;
    df.descriptionListValue = rsFactor.descriptionListValue;
    df.format = rsFactor.format;
    df.value = this.formatFactorValue(df);
    return df;
  }

  apiFactorToDtoFactor(apiFactor: ApiFactor): Factor {
    const factor = new Factor();
    factor.description = apiFactor.description;
    factor.value = apiFactor.value;
    return factor;
  }

  apiRisksToDtoRisks(rsRisks: ApiRisk[]): Risk[] {
    const dfs = [];
    for (const rsRisk of rsRisks) {
      dfs.push(this.apiRiskToDtoRisk(rsRisk));
    }
    return dfs;
  }

  apiRiskToDtoRisk(apiRisk: ApiRisk): Risk {
    const risk = new Risk();

    risk.description = apiRisk.description;
    risk.premium = apiRisk.premium;
    risk.factors = this.apiDamageFactorsToDtoDamageFactors(apiRisk.factors);

    return risk;
  }

  private formatFactorValue(factor: DamageFactor): string {
    const separatore = Formats.NUMBER_FORMATS.GROUP_SEP;
    let fmtValue = factor.stringValue;

    const type = parseInt(factor.type.codice, 10);

    if (type === 2) {
      fmtValue = formatDate(factor.dateValue, 'short', this.locale);
    } else if (type === 1) {
      fmtValue = factor.numberValue.toString();

      const factorType = parseInt(factor.factorType, 10);
      switch (factorType) {
        case 3:
          this.translateService.translate('_CLAIMS_COMMON_._NO').subscribe((text: string) => { fmtValue = text; });
          if (factor.numberValue > 0) {
            this.translateService.translate('_CLAIMS_COMMON_._YES').subscribe((text: string) => { fmtValue = text; });
          } else if (factor.numberValue < 0) {
            fmtValue = factor.numberValue.toString();
          }
          break;
        case 0:
        case 2:
          if (factor.descriptionListValue && factor.descriptionListValue !== 'FACTOR NOT SET OR VALUE NOT IN LIST.') {
            fmtValue = factor.descriptionListValue;
          }
          break;
        default:
          if (factor.numberValue > -1) {
            if (factor.format === '%1[.2g]') {
              fmtValue = formatNumber(factor.numberValue, this.locale, '1.2-2');
            } else if (factor.format === '%1[.2]') {
              fmtValue = formatNumber(factor.numberValue, this.locale, '1.2-2');
              fmtValue = fmtValue.split(separatore).join('');
            } else if (factor.format === '%1[4]') {
              fmtValue = fmtValue.split(separatore)[0];
            } else {
              fmtValue = formatNumber(factor.numberValue, this.locale, '1.0-0');
              fmtValue = fmtValue.split(separatore).join('');
            }
          }
      }
    }

    if (parseFloat(fmtValue) === -1) {
      fmtValue = '';
    }

    if (this.tempFormatFix && fmtValue) {
      const re = /([0-9])\/([0-9])/gi;
      const toRepl = '$1 / $2';
      fmtValue = fmtValue.replace(re, toRepl);
    }

    return fmtValue;
  }



  constructor(
    public translateService: RgiRxTranslationService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super();
  }

}

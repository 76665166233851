import {Injectable} from '@angular/core';
import {RgiRxHttpClientFactory, RgiRxHttpClientWrapper} from '@rgi/rx/http';
import {LifeRgiRxHttpErrorAdapter} from '../adapters/life-rgi-rx-http-error-adapter';

@Injectable({
  providedIn: 'root'
})
export class LifeRgiRxHttpClientWrapperService {

  private readonly client: RgiRxHttpClientWrapper;

  constructor(public rgiRxHttpClientFactory: RgiRxHttpClientFactory) {
    this.client = this.rgiRxHttpClientFactory.buildWrapper({
      withErrorAdapter: LifeRgiRxHttpErrorAdapter
    });
  }

  public getClient(): RgiRxHttpClientWrapper {
    return this.client;
  }

}



export const caiModuleEndPoint = {
  urlUnitsProductBin: '/v2/claims/unitsProductBin',
  urlSimilarClaims: '/claims/similarClaims',
  // to be define
  newClaim: '/claims/newClaim',
  damagedparts: '/claim/damagedparts',
  urlSimilarpotential: '/v2/claims/similarClaims',
  urlGenerateClaim: '/claims/claim',
  urlClaimSave: '/claims/claimSave',
  urlGetQuestionnaire: '/claims/claim/rcaquestionnaire/get',
  urlSetQuestionnaire: '/claims/claim/rcaquestionnaire/set',
  urlGetContract: '/ptfdanni/contract',
  urlGetClaimPes: '/claims/pes/',
  urlGetAllCategories: '/v2/claims/damage-categories',
  urlGetCovertureBranches: '/claims/coverturebranches',
  urlGetDetailNodeCompany: '/admin/pointofsale',
  urlListPolicies: '/ptfall/policies',
};

// function ClaimSaveFactory($resource, apiConf) {
//     var endpoint = apiConf.server + apiConf.base_url + '/claims/claimSave';
//     var outcome = $resource(endpoint, {}, { save: { method: 'POST' } });
//     return outcome;
// }

import { EMPTY_STR, StepperConfiguration } from '../../../models/consts/lpc-consts';

export interface TransferDataStepperConfig extends StepperConfiguration {
  /**
   * @stepField id `'roles'`
   * @stepField label `'lpc_variation_step_label'`
   * @stepField fieldId `'roles'`
   * @stepField formName `'roles'`
   */
  DATA_TRANSFER: {
    id: string;
    label: string;
    fieldId: string;
    formName: string;
  };
}

export const TRANSFER_RESERVE: TransferDataStepperConfig = {
  DATE: {
    id: 'dates',
    label: 'lpc_date_variation_step',
    fieldId: 'dates',
    formName: 'dates'
  },
  POLICY_FACTORS: {
    id: 'factors',
    label: 'lpc_PolicyData',
    fieldId: 'factors',
    formName: 'factors'
  },
  QUESTS_FIN: {
    id: 'questsFin',
    label: 'lpc_financial_questionnaire_step',
    fieldId: 'questsFin',
    formName: EMPTY_STR
  },
  ASSET_FACTORS: {
    id: 'assetFactors',
    label: 'lpc_asset_factors',
    fieldId: 'assetFactors',
    formName: 'assetFactors'
  },
  PAYMENT: {
    id: 'payment',
    label: 'lpc_payment_data',
    fieldId: 'payment',
    formName: 'payment'
  },
  QUOTATION: {
    id: 'quotation',
    label: 'lpc_quotation_step',
    fieldId: 'quotation',
    formName: 'quotation'
  },
  RISKS: {
    id: EMPTY_STR,
    label: EMPTY_STR,
    fieldId: EMPTY_STR,
    formName: 'risks'
  },
  QUESTS: {
    id: 'quests',
    label: 'lpc_avc_questionnaire',
    fieldId: 'quests',
    formName: EMPTY_STR
  },
  QUESTS_HLT: {
    id: 'questsHlt',
    label: 'lpc_health_questionnaire',
    fieldId: 'questsHlt',
    formName: EMPTY_STR
  },
  QUESTS_UNIT: {
    id: 'questsUnt',
    label: 'lpc_unit_questionnaire',
    fieldId: 'questsUnt',
    formName: EMPTY_STR
  },
  WARNING: {
    id: 'warnings',
    label: 'lpc_warnings',
    fieldId: 'warnings',
    formName: EMPTY_STR
  },
  DOCUMENT: {
    id: 'documents',
    label: 'lpc_confirm_variation_step',
    fieldId: 'documents',
    formName: EMPTY_STR
  },
  DATA_TRANSFER: {
    id: 'dataTransfer',
    label: 'lpc_transfer_data',
    fieldId: 'dataTransfer',
    formName: 'dataTransfer'
  },
};

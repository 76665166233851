import { EMPTY_STR, StepperConfiguration } from '../../../models/consts/lpc-consts';

export interface BackPackDataStepperConfig extends StepperConfiguration {
  /**
   * @stepField id `'roles'`
   * @stepField label `'lpc_variation_step_label'`
   * @stepField fieldId `'roles'`
   * @stepField formName `'roles'`
   */
  SELECT_TRANSFER: {
    id: string;
    label: string;
    fieldId: string;
    formName: string;
  };
  FISCAL_DATA_HEADER: {
    id: string;
    label: string;
    fieldId: string;
    formName: string;
  };
  FISCAL_FOLDER_DATA_2001_2006: {
    id: string;
    label: string;
    fieldId: string;
    formName: string;
  };
  FISCAL_FOLDER_DATA_POST_2006: {
    id: string;
    label: string;
    fieldId: string;
    formName: string;
  };
  FISCAL_FOLDER_DATA_BEFORE_2001: {
    id: string;
    label: string;
    fieldId: string;
    formName: string;
  };
}

export const BACKPACK_TRANSFER: BackPackDataStepperConfig = {
  DATE: {
    id: 'dates',
    label: 'lpc_date_variation_step',
    fieldId: 'dates',
    formName: 'dates'
  },
  SELECT_TRANSFER: {
    id: 'reserveTransferSelect',
    label: 'lpc_select_transfer',
    fieldId: 'reserveTransferSelect',
    formName: 'reserveTransferSelect'
  },
  FISCAL_DATA_HEADER: {
    id: 'fiscalDataFolder',
    label: 'lpc_fiscal_data',
    fieldId: 'fiscalDataFolder',
    formName: 'fiscalDataFolder'
  },
  FISCAL_FOLDER_DATA_2001_2006: {
    id: EMPTY_STR,
    label: EMPTY_STR,
    fieldId: EMPTY_STR,
    formName: 'fiscalFolderData20012006'
  },
  FISCAL_FOLDER_DATA_POST_2006: {
    id: EMPTY_STR,
    label: EMPTY_STR,
    fieldId: EMPTY_STR,
    formName: 'fiscalFolderDataPost2006'
  },
  FISCAL_FOLDER_DATA_BEFORE_2001: {
    id: EMPTY_STR,
    label: EMPTY_STR,
    fieldId: EMPTY_STR,
    formName: 'fiscalFolderDataBefore2001'
  },
  WARNING: {
    id: 'warnings',
    label: 'lpc_warnings',
    fieldId: 'warnings',
    formName: EMPTY_STR
  },
  DOCUMENT: {
    id: 'documents',
    label: 'lpc_confirm_variation_step',
    fieldId: 'documents',
    formName: EMPTY_STR
  },
};

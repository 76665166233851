import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild
} from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { JS_EVENT, PV_TOKEN } from '../../models/consts/lpc-consts';
import { AngularCommunicationService } from '../../services/angular-communication.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { PlcOperationComponentsUtils } from '../../utils/plc-operations-components-utils';
import { LifePostSalesHostDirective } from './directives/life-postsales-host.directive';
import { EventNotificator } from './models/event-notificator';
import { AnagService } from '../../services/anag.service';
import { AnagSubject } from '../../models/subject.model';

@Component({
  selector: 'lpc-life-postsales-session',
  templateUrl: './life-postsales-session.component.html',
  styleUrls: ['./life-postsales-session.component.scss']
})
export class LifePostsalesSessionComponent implements OnInit, OnDestroy {

  @ViewChild(LifePostSalesHostDirective, {static: true}) micHost: LifePostSalesHostDirective;
  componentRef: ComponentRef<Component>;

  protected subscriptions: Subscription[] = [];

  @Input() set selectedSubject(subject: any) {
    if (!!subject) {
      if (subject === 'noSubj') {
        this.anagService.receiveSubjectFromModalX(null);
      } else if (subject.objectId != null) {
        this.anagService.receiveSubjectFromModalX(subject);
      }
    }
  }

  @Input() set openedAnagSession(obj: { opened: boolean, subject: AnagSubject, role: string}) {
    if (!!obj) {
      this.anagService.openedAnagModifySession(obj);
    }
  }

  @Input() data: {data: any, idParentSession: string};

  @Output() eventPropagation: EventEmitter<string | { [ key: string ]: any}> = new EventEmitter<string | { [ key: string ]: any}>();

  operationComponents = null;
  jumpComponents = null;

  constructor(
    protected componentFactoryResolver: ComponentFactoryResolver,
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected angularCommunication: AngularCommunicationService,
    protected anagService: AnagService
  ) {
    // per estendere i componenti sarà necessario estendere questo service
    // nel costruttore richiamare la super e in seguito riassegnare le operazioni che devono essere estese
    // Esempio: this.operationComponents.CODICEOPERAZIONE = OperazioneExtComponent;

    this.operationComponents = PlcOperationComponentsUtils.getOperationComponents();
    this.jumpComponents = {};
  }

  ngOnInit() {
    this.eventPropagation.emit(JS_EVENT.LOADER.START);
    this.subscriptions.push(
      timer(1000).pipe(
        take(2),
        tap(_ => {
          if (this.isFromAuthModify()) {
            this.operations.setSession({
              authorizationId: this.data.data.authorizationId,
              policyNumber: this.data.data.contractId,
              operation: this.data.data.causeCode,
              operationDescription: this.data.data.operationDescription,
              sessionId: this.data.data.sessionId,
              idParentSession: this.data.data.idParentSession
            });
            this.operations.setPolicyNumber(this.data.data.contractId);
          }
          this.operations.setParentSessionId(this.data.idParentSession);
          this.loadComponent(this.operations.session.operation);
        })
      ).subscribe(_ => this.eventPropagation.emit(JS_EVENT.LOADER.STOP))
    );
  }

  isFromAuthModify(): boolean {
    return this.data != null && this.data.data.authorizationId != null;
  }

  loadComponent(operation: string) {
    if (this.micHost) {
      let operationsVector = this.operationComponents;
      if (this.operations.session.isFinancial) {
        operationsVector = Object.assign({}, this.operationComponents, this.jumpComponents);
      }

      const component: Type<Component> = operationsVector[operation];
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

      const viewContainerRef = this.micHost.viewContainerRef;
      viewContainerRef.clear();
      this.componentRef = viewContainerRef.createComponent(componentFactory);
      this.subscriptions.push(
        this.angularCommunication.communication.subscribe(val => {
          this.eventPropagation.emit(val);
        }),
        this.anagService.modalOpening.subscribe(val => {
          if (!!val.event) {
           this.eventPropagation.emit(val.event);
         } else {
           this.eventPropagation.emit(val);
         }
       })
      );
      if ((this.componentRef.instance as EventNotificator).eventPropagation !== undefined) {
        (this.componentRef.instance as EventNotificator).eventPropagation.subscribe((event) => {
          this.eventPropagation.emit(event);
        });
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
    this.anagService.resetModalOpen();
  }

}

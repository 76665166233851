import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortReport'
})
export class ReportSortPipe implements PipeTransform {
  transform(array: any[], field1: string, field2: string): any[] {
    if (array) {
      return array.sort((a, b) => {
        if (a[field1] < b[field1]) {
          return -1;
        } else if (a[field1] > b[field1]) {
          return 1;
        } else {
          const valueA = (a[field2] || '').toLowerCase();
          const valueB = (b[field2] || '').toLowerCase();
          if (valueA < valueB) {
            return -1;
          } else if (valueA > valueB) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    }
    return array;
  }
}

export const OPERATION_MOCK =
    [{id: 4, description: 'Conferma Provvigioni Banca'},
     {id: 5, description: 'Conferma Provvigioni Compagnia'},
     {id: 6, description: 'Provvigioni manuali'}];

export const ACTIONS_MOCK = [{id: 3, description: 'Segnalazione a IT'}, {id: 5, description: 'Segnalazione a Business'}, {
    id: 4,
    description: 'Segnalazione a Compagnia'
}];
export const NOTES_MOCK = [{id: 1, description: 'da approfondire con Business'}, {id: 2, description: 'errore Compagnia'}, {
    id: 3,
    description: 'errore Banca'
}, {id: 4, description: 'errore data'}, {id: 5, description: 'errore Banca convenzione'}, {
    id: 6,
    description: 'errore Compagnia convenzione'
}, {id: 7, description: 'errore Banca e Compagnia'}];

export const COMMISSIONS_SCHEMA = {
    rows: [

        {
            name: 'checkbox', title: '',
        },
        {
            name: 'policyNumber', title: 'ICD.POLICY_NUMBER',
        },
        {
            name: 'extPolicyNumber', title: 'ICD.EXT_POLICY_NUMBER',
        },
        {
            name: 'extMatchKey', title: 'ICD.MATCH_KEY',
        },
        {
            name: 'competenceDate', title: 'ICD.COMPETENCE_DATE',
        },
        {
            name: 'statusDescr', title: 'ICD.STATUS',
        },
        {
            name: 'commDeltaAmt', title: 'ICD.DELTA_COMMISSIONS',
            styleClass: 'td-right',
        },
        {
            name: 'extGrossPremiumAmt', title: 'ICD.EXT_PREMIUM',
            styleClass: 'td-right',
        },
        {
            name: 'subStatusDescr', title: 'ICD.SUBSTATUS',
        }
    ],
    header: ['checkbox', 'policyNumber', 'extPolicyNumber', 'extMatchKey', 'competenceDate', 'statusDescr', 'commDeltaAmt', 'extGrossPremiumAmt', 'subStatusDescr']
};

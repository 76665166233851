import {Inject, Injectable} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Clause} from '../models/domain-models/clause';
import {HttpClient} from '@angular/common/http';
import {Section} from '../models/domain-models/section';
import {ProposalService} from '../proposal.service';
import {MicHttpService} from '../http/mic-http.service';

@Injectable({
  providedIn: 'root'
})
export class ClauseService {

  protected baseApiUrlV2;
  protected contractEndpoint;
  protected clause: Clause;
  protected httpClient: HttpClient;

  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any,
    protected proposalService: ProposalService
  ) {
    this.httpClient = this.micHttpClient.getHttpClient();
    const newMotorFlowServicesPath = environment.api.portal.nmf;
    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path + '/v2';

    this.contractEndpoint = this.baseApiUrlV2 + '/portfolio/contract';
  }

  toFormGroup(clauseList: Clause[]): UntypedFormGroup {
    const group: any = {};

    clauseList.forEach(clause => {
      group[clause.code] = new UntypedFormControl(clause.selected, clause.compulsory ? Validators.required : null);
    });
    return new UntypedFormGroup(group);
  }

  getPreQuotationClauses(contractId: string) {
    return this.httpClient.get<Array<Clause>>(this.contractEndpoint + '/' + contractId + '/clauses');
  }

  updateAssetClauses(unitClauses: Array<Clause>) {
    const contractId = this.proposalService.getContractId();
    return this.httpClient.put<Array<Section>>(this.contractEndpoint + '/' + contractId + '/clauses', unitClauses);
  }

  updateRiskClause(clause: Clause, riskCode: string) {
    const contractId = this.proposalService.getContractId();
    return this.httpClient.put<Array<Clause>>(this.contractEndpoint + '/' + contractId + '/units/' + riskCode + '/clause', clause);
}

  setClause(clause: Clause) {
    this.clause = clause;
  }

  getClause() {
    return this.clause;
  }
}

<div [formGroup]="formGroup" class="row">
  <div style="margin-top:5px" class="input-group col-lg-12" formGroupName="value">
    <input
      [ngClass]="{'with-percentage' : showPercentage, 'without-percentage' : !showPercentage}"
      type="text"
      class="form-control"
      readonly=""
      formControlName="name"
      [attr.data-qa]="'beneficiary_' + formGroup.get('value').get('role').value"
    >
    <ng-container *ngIf="_distributionType === _AMOUNT; else percentageInput">
      <input
        *ngIf="showPercentage"
        type="text"
        step="0.01"
        formControlName="amount"
        class="form-control with-amount"
        [placeholder]="'lpc_assignment_amount'"
        [attr.data-qa]="getValueForDataQA('amount')"
      >
    </ng-container>
    <ng-template #percentageInput>
      <input
        *ngIf="showPercentage"
        type="text"
        step="0.01"
        formControlName="percentage"
        class="form-control with-percentage"
        [placeholder]="'Percentage'"
        [attr.data-qa]="getValueForDataQA('percent')"
      >
    </ng-template>

    <div class="input-group-btn">
      <!--  <button class="btn btn-outline-secondary" type="button" (click)="onEdit(role)"> -->
      <!--   <span class="rgifont rgi-pencil"></span> -->
      <!-- </button> -->
      <button *ngIf="!blockBeneficiaries" [disabled]="!editable" (click)="deleteBen(value)" class="btn btn-outline-secondary" type="button" [attr.data-qa]="getValueForDataQA('trash')">
        <span class="rgifont rgi-trash"></span>
      </button>
    </div>
  </div>
</div>

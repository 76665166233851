
    <div class="modal-header">
      <div class="modal-title" translate>
          lpc_quotation_details
          <!-- <button type="button" class="close basic-close" (click)="showDetailModal=false">
            <span aria-hidden="true">&times;</span>
          </button> -->
        </div>
    </div>
    <div class="modal-body">
      <div class="row">
        <div *ngIf="showFirstInstallment && !!firstInstalment" class="col-lg-4">
          <lpc-premium-table [premium]="firstInstalment"></lpc-premium-table>
        </div>
        <lpc-quotation-recess *ngIf="!!quotationData" [quotationData]="quotationData"></lpc-quotation-recess>
        <div [ngClass]="{'col-lg-4': !!firstInstalment, 'col-lg-6': !firstInstalment }">
          <lpc-premium-table *ngIf="!!nextInstalment" [premium]="nextInstalment"></lpc-premium-table>
        </div>
        <div [ngClass]="{'col-lg-4': !!firstInstalment, 'col-lg-6': !firstInstalment }">
          <lpc-premium-table *ngIf="!!annualPremium" [premium]="annualPremium"></lpc-premium-table>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="btn-group btn-group-justified"
        style="margin-top: 0px !important; border: 0px !important; border-right: 0px !important;">
        <div class="btn-group">
          <button class="btn btn-warning" data-qa="close-button" (click)="closeModal()" translate>lpc_close_button</button>
        </div>
      </div>
    </div>


import {ConditionFlatIResponse} from './condition-flatI-response';
import {SectionFlatI} from './section-flatI';
import {QuestionStatuses} from './question-statuses';
import {AnswerFlatIResponse} from './answer-flatI-response';

export class QuestionFlatIResponse {
  code: string;
  visible: boolean;
  answerList: AnswerFlatIResponse[];
  description: string;
  section: SectionFlatI;
  compulsory: boolean;
  hasNotes: boolean;
  id: string;
  text: string;
  conditionList: ConditionFlatIResponse[];
  order: number;
  status: QuestionStatuses;

  constructor() {
  }
}

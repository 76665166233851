import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalLoadingIndicatorComponent} from './portal-loading-indicator/portal-loading-indicator.component';


@NgModule({
    declarations: [PortalLoadingIndicatorComponent],
    imports: [
        CommonModule
    ]
})
export class PortalUIModule {
}

// import { registerLocaleData } from '@angular/common';
// import localeDe from '@angular/common/locales/de';
// import localeEn from '@angular/common/locales/en';
// import localeEs from '@angular/common/locales/es';
// import localeFr from '@angular/common/locales/fr';
// import localeIt from '@angular/common/locales/it';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private lang;

  constructor() {
    // this.registerLocales();
  }

  getLocale() {
    return this.lang;
  }

  setLang(l: string) {
    this.lang = l;
  }

  getLand() {
    return this.lang;
  }

  // private registerLocales() {
  //   registerLocaleData(localeIt, 'it');
  //   registerLocaleData(localeEn, 'en');
  //   registerLocaleData(localeEs, 'es');
  //   registerLocaleData(localeDe, 'de');
  //   registerLocaleData(localeFr, 'fr');
  // }
}

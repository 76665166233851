import { ChangeDetectorRef, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ComponentWithAnagModal } from '../../interfaces/component-with-anag-modal';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';
import { RequestFactor } from '../../models/factor.model';
import { BeneficiaryDefinition, BeneficiaryRole, FactorDefinition, FormFieldsDefinition, Role } from '../../models/postsales-operations-response.model';
import { AnagSubject } from '../../models/subject.model';
import { LpcClaimBeneficiariesComponent } from '../../modules/lpc-beneficiary/lpc-claim-beneficiaries/lpc-claim-beneficiaries.component';
import { LpcBeneficiaryUtils } from '../../modules/lpc-beneficiary/util/lpc-beneficiary-utils';
import { LpcFactorAdapterComponent } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { RoleType } from '../../models/enum/lpc-subjects.enum';
import { LpcBeneficiaryService } from '../../services/beneficiary.service';

@Component({
  selector: 'lpc-expiry-choice',
  templateUrl: './expiry-choice.component.html',
  styleUrls: ['./expiry-choice.component.css']
})
export class ExpiryChoiceComponent extends AbsOperationComponent implements OnInit, ComponentWithAnagModal {

  @ViewChild('factorAdapter') child: LpcFactorAdapterComponent;
  @ViewChild('beneficiaries') beneficiaries: LpcClaimBeneficiariesComponent;

  private _beneficiaryData: any[];

  protected operationDataKey = 'expiryChoice';
  protected requestProductFactor: RequestFactor[] = [];

  public listProductFactor: FactorDefinition[] = [];
  public roleCodeToAdd = '300000' as RoleType;
  public beneficiariesDefinitions: BeneficiaryDefinition[] = [];
  public reversionary: FormFieldsDefinition[];
  // public paymentTypes: PaymentTypeDefinition[] = [];

  get idAssicurato(): string {
    if (this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].idAssicurato;
    } else if (!!this._beneficiaryData && this._beneficiaryData.length) {
      return this._beneficiaryData[0].beneficiary && this._beneficiaryData[0].beneficiary.idAssicurato;
    } else {
      return EMPTY_STR;
    }
  }

  get reversionaryForm() {
    return this.formGroup.get('reversionary');
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    /* protected operations: MockExpiryChoiceService, */
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected anagService: AnagService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService,
    protected beneficiaryService: LpcBeneficiaryService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  ngOnInit(): void {
    this.initializeSession();
    this.$subscriptions.push(this.createDraft().subscribe());
    this.formGroup.get('reversionary').valueChanges.subscribe(el => {
      this.$feErrors = [];
      if (!!this.reversionary) {
        if (this.reversionaryForm.value == null) {
          this.setCustomFeErrors('factors', 'Inserire soggetto reversionario', 'error');
        } else {
          if (this.reversionaryForm.value.value.percentage == null) {
            this.setCustomFeErrors('factors', 'Inserire percentuale del soggetto reversionario', 'error');
          }
        }
      }
    });
  }

  public updateProductFactors(factors: RequestFactor[]) {
    this.requestProductFactor = factors;
    this.$subscriptions.push(this.onReload('factors').subscribe((result) => {
      this.updateFormValue(result.definitions.productFactors, 'factors');
      this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
      this.reversionary = result.definitions.reversionary;
      if (!this.reversionary) {
        this.reversionaryForm.setValue(null);
        this.reversionaryForm.clearValidators();
        this.reversionaryForm.updateValueAndValidity();
      }
    }));
  }

  /**
   * Add missing factors
   */
  public updateFormValue(factors: any, formName: string) {
    const codes: string[] = [];
    factors.map((element => {
        codes.push(element.code);
    }));
    this.removeOldControls(this.formGroup.get(formName) as UntypedFormGroup, codes);
  }

  /**
   * Remove old checks
   */
  public removeOldControls(form: UntypedFormGroup, codes: string[]) {
      Object.keys(form.controls).forEach((key) => {
          if (!codes.includes(key) && key !== 'reversionary') {
              form.removeControl(key);
          }
      });
  }

  public onTriggerQuestPreval($event: any) {
    if (!!$event) {
      this.$subscriptions.push(
        this.plcQuestService.prevalQuest(this.operationDataKey, EMPTY_STR, this).subscribe(result => {
          this.questFactorsArray = result;
          this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, EMPTY_STR, this);
        })
      );
    }
  }

  public onAddedSubject($event: Role) {
    if ($event === null) {
      this.openModal('lpc_found_duplicate', 'lpc_duplicate_subject_message', true);
    }
  }

  public openAnagSubjectModal() {
    this.anagService.openSubjectModal(this);
  }

  public receiveAnagSubjectFromModal(subject: AnagSubject) {
    const role: Role = AnagService.subjectToRole(subject, this.roleCodeToAdd as RoleType);
    this.reversionaryForm.setValue(
      {
        value: role
      }
    );
  }

  public deleteRevers(event) {
    this.reversionaryForm.setValue(null);
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      factors: new UntypedFormGroup({
      }),
      reversionary: new UntypedFormControl(),
      beneficiaries: new UntypedFormControl([])
    });
  }

  protected updateDraft(step: string, reload: boolean = false, opDataType?: string): Observable<any> {
    if (this.beneficiaryService.checkAllQuestionnaires(this.stepper.activeStep.id, s => this.setQuestionaryError(s))) {
      return this.beneficiaryService.persistQuestionnaires(this.stepper.activeStep.id)
        .pipe(
          switchMap(() => {
            return super.updateDraft(step, reload, opDataType);
          }),
          tap(result => {
            if (!!result.definitions.beneficiaryRoles) {
              LpcBeneficiaryUtils.setBeneficiaryRoles(result.definitions.beneficiaryRoles as BeneficiaryRole[]);
            }
            if (result.definitions.beneficiaries != null) {
              this.beneficiariesDefinitions = (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
            }
            this._beneficiaryData = result.data.operationData.data.beneficiaries;
            if (!!this._beneficiaryData) {
              this.formGroup.get('beneficiaries').setValue(
                (this._beneficiaryData as any[]).map(beneficiary => {
                  return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
                }),
                { emitEvent: false }
              );
            }
            this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
            // this.paymentTypes = result.definitions.paymentTypes;
            this.reversionary = result.definitions.reversionary;
            this.detectChanges();
          })
      );
    }
  }

  protected getTransformedOperationData(): any {
    return {
      listProductFactor: this.requestProductFactor,
      reversionary: !!this.reversionaryForm ? !!this.reversionaryForm.value ? this.reversionaryForm.value.value : null : null
    };
  }

}

import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {mergeMap} from 'rxjs/operators';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Unit} from '../../../models/domain-models/unit';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Message} from '../../../models/message';
import {UnitService} from '../re-issue-quotation-services/unit.service';
import {UnitSection} from '../../../models/domain-models/unit-section';
import {
  ReIssueQuotationStateManagerService
} from '../../re-issue-state-manager/re-issue-quotation-state-manager.service';
import {ReIssueRefactorService} from '../../re-issue-resources/re-issue-portafolio-resources/re-issue-refactor.service';
import {Package} from '../../../models/pc-portfolio-models/assets-models/pc-insured-asset';
import {Factor} from '../../../models/pc-portfolio-models/factor-models/factor';
import {of, Subscription} from 'rxjs';
import {AssetSection} from '../../../models/domain-models/asset-section';
import {Beneficiaries} from '../../../models/domain-models/beneficiaries';
import {Currency} from '../../../models/domain-models/parameters/currency';
import {InputPremium} from '../../../models/domain-models/inputPremium';
import {RgiRxUserAuthorizationService, RgiRxUserService} from '@rgi/rx/auth';
import {ClauseOutput} from '../../../models/domain-models/clause';
import {ReIssueQuotationState} from '../../re-issue-state-manager/re-issue-quotation.state';

@Component({
  selector: 're-issue-asset-unit',
  templateUrl: './re-issue-asset-unit.component.html',
  styleUrls: ['./re-issue-asset-unit.component.css']
})
export class ReIssueAssetUnitComponent implements OnInit, OnChanges, OnDestroy {

  @Output() recalculateAvailable: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() validationMessagesAsset: EventEmitter<Message[]> = new EventEmitter<Message[]>();
  @Output() checkPackets: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() unitSelected: EventEmitter<string> = new EventEmitter<string>();

  @Input() sectionsPremium: Map<string, UnitSection>;
  @Input() viewUnitsBox: boolean;
  @Input() assetInstanceKey: string;
  @Input() quotePackets: Package[];
  @Input() packetContract: boolean;
  @Input() sync: boolean;
  @Input() sections: Array<AssetSection>;
  @Input() currency: Currency;

  @Input() guaranteesForm: UntypedFormGroup;

  validationMessages: Array<Message> = [];
  areaCode = '';
  formReady = true;
  msgMandatory: string;
  sub: Subscription = new Subscription();
  isDirectionalUser: boolean;

  constructor(
    public stateManager: ReIssueQuotationStateManagerService<ReIssueQuotationState>,
    protected formBuilder: UntypedFormBuilder,
    protected refactorService: ReIssueRefactorService,
    private translateService: RgiRxTranslationService,
    private userService: RgiRxUserService,
    protected unitService: UnitService,
    private userAuthorizationService: RgiRxUserAuthorizationService
  ) {
    const isHeadOfOffice = this.userAuthorizationService.isAuthorizedFor('admin.config.operatortype.headoffice');
    this.isDirectionalUser = this.userService.getUser<any>().isDirectionalUser || isHeadOfOffice;
  }

  ngOnInit() {
    // Emit Asset Key To Initialize Clauses
    this.unitSelected.emit(this.assetInstanceKey);
  }

  getSumGrossSection(section: any): string {
    let result = 0;
    this.sectionsPremium.get(section.code).unitList.forEach(unit => {
      if (unit.selected) {
        result += +unit.annual.gross;
      }
    });
    return result.toString();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sections) {
      if (changes.sections.previousValue) {
        this.sub.unsubscribe();
        this.sub = new Subscription();
        this.guaranteesForm = this.unitService.updateGuaranteesForm(changes.sections.currentValue, this.guaranteesForm);
        this.subscribeControls(changes.sections.currentValue);
      } else {
        this.guaranteesForm = this.unitService.toFormGroup(changes.sections.currentValue);
        this.subscribeControls(changes.sections.currentValue);
      }
      if (changes.quotePackets && changes.quotePackets.currentValue) {
        changes.quotePackets.currentValue.forEach(pack => {
          if (pack.selected) this.packetUnitsManagement(pack.selected, [pack], changes.sections.currentValue);
        })
      }
    }
  }

  subscribeControls(sections: any) {
    sections.forEach(section => {
      section.unitList.forEach(unit => {
        const formControlSub = this.guaranteesForm.controls[section.code + '_' + unit.code].valueChanges.subscribe(selected => {
          unit.selected = selected;
          if (selected) {
            this.enableFactorFormControl(unit, section.code);
            if (unit.beneficiaries && unit.beneficiaries.length > 0) {
              this.enableBeneficiariesUnit(this.assetInstanceKey, unit, section.description);
            }
          } else {
            this.disableFactorFormControl(unit, section.code);
            if (unit.beneficiaries && unit.beneficiaries.length > 0) {
              this.disableBeneficiariesUnit(this.assetInstanceKey, unit, section.description);
            }
          }
          this.putSelectedUnit(unit, section);
          this.updateErrorMessageVariable();
        });
        this.sub.add(formControlSub);
      });
    });
  }

  updateErrorMessageVariable() {
    this.validationMessages.length = 0;
    if (this.guaranteesForm.invalid) {
      this.recalculateAvailable.emit(false);
      const controls = this.guaranteesForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          this.pushMessage(name);
        }
      }
    } else {
      this.recalculateAvailable.emit(true);
    }
    this.validationMessagesAsset.emit(this.validationMessages);
  }

  pushMessage(formControlName: string) {
    const array = formControlName.split('_');
    const sectionCode = array[0];
    const unitCode = array[1];
    const factorCode = array[2];
    const section = this.sections.find(s => s.code === sectionCode);
    const unit = section.unitList.find(u => u.code === unitCode);
    const factor = unit.factors.find(f => f.code === factorCode);
    this.validationMessages.push(new Message(this.areaCode, section.description + ' '
      + unit.description + ' ' + factor.description + ' ' + this.setMandatoryMessage()));
  }

  validateVariables(unitSelected: Unit, section: AssetSection, controlName) {
    let factorValue = this.guaranteesForm.get(controlName).value;
    if(factorValue === null || factorValue === ''){
      this.guaranteesForm.get(controlName).setValue(null);
      if(!this.isDirectionalUser) {
        this.updateUnitPremium(new InputPremium(), section.code, unitSelected.code);
      }
    }
    this.updateErrorMessageVariable();
    if (this.isUnitFormControlValid(unitSelected, section.code)) {
      this.putSelectedUnit(unitSelected, section);
    }
  }

  setMandatoryMessage(): string {
    const msg = of(['RE_ISSUE.MANDATORY']);
    msg.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(next => {
      this.msgMandatory = next [0];
    }).unsubscribe();
    return this.msgMandatory;

  }

  isUnitFormControlValid(unit: Unit, section: string): boolean {
    const factorCodes = unit.factors.map(factor => factor.code);
    let valid = true;
    factorCodes.forEach(fKey => {
      if (this.guaranteesForm.controls[section + '_' + unit.code + '_' + fKey].invalid) {
        valid = false;
      }
    });
    return valid;
  }

  disableFactorFormControl(unit: Unit, section: string) {
    unit.factors.forEach(factor=>{
      factor.value = null;
      this.guaranteesForm.controls[section + '_' + unit.code + '_' + factor.code].setValue(factor.value);
      this.guaranteesForm.controls[section + '_' + unit.code + '_' + factor.code].disable();
    });
    if(!this.isDirectionalUser){
      this.updateUnitPremium(new InputPremium(),section, unit.code);
    }
  }

  enableFactorFormControl(unit: Unit, section: string) {
    const factorCodes = unit.factors.map(factor => factor.code);
    factorCodes.forEach(fKey => {
      const factors: Array<Factor> = this.refactorService.variableToSection(unit.factors);
      if (factors.find(factor => factor.code === fKey).modifiable) {
        this.guaranteesForm.controls[section + '_' + unit.code + '_' + fKey].enable();
      } else {
        this.guaranteesForm.controls[section + '_' + unit.code + '_' + fKey].disable();
      }
    });
  }

  enableBeneficiariesUnit(assetInstanceKey: string, unit: Unit, sectionDescription: string) {
    const state = this.stateManager.getCurrentState();
    const beneficiaries = new Beneficiaries();
    beneficiaries.assetKey = assetInstanceKey;
    beneficiaries.section = sectionDescription;
    beneficiaries.unit = unit.description;
    beneficiaries.unitCode = unit.code;
    beneficiaries.beneficiary = unit.beneficiaries;
    state.listBeneficiariesUnit.push(beneficiaries);
    state.listBeneficiariesUnit = [...state.listBeneficiariesUnit];
    state.isBeneficiaryUnitVisibility = true;
    const instanceKeyUnitCode = assetInstanceKey + '_' + unit.code;
    state.isBeneficiaryUnitVisibilityArray.push(instanceKeyUnitCode);
    this.stateManager.updateState$(of(state));
  }

  private disableBeneficiariesUnit(assetInstanceKey: string, unit: Unit, sectionDescription: string) {
    const state = this.stateManager.getCurrentState();
    this.stateManager.deleteBeneficiariesByAssetAndByUnit(
      this.stateManager.getCurrentState().resourceId, assetInstanceKey, unit.code).subscribe(val => {
      const index = state.isBeneficiaryUnitVisibilityArray.indexOf(assetInstanceKey + '_' + unit.code);
      if (index > -1) {
        state.isBeneficiaryUnitVisibilityArray.splice(index, 1);
      }
      state.listBeneficiariesUnit.forEach((beneficiary, i) => {
        if (beneficiary.assetKey === assetInstanceKey && beneficiary.unitCode === unit.code && beneficiary.section === sectionDescription) {
          state.listBeneficiariesUnit.splice(i, 1);
          state.listBeneficiariesUnit = [...state.listBeneficiariesUnit];
        }
      });
      state.beneficiariesSelected.forEach(b => {
        if (b.split('_')[0] === assetInstanceKey && b.split('_')[1] === unit.code) {
          state.beneficiariesSelected.splice(state.beneficiariesSelected.indexOf(b), 1);
        }
      });
    });
    this.stateManager.updateState$(of(state));
  }

  enableBeneficiariesUnitPacket(assetInstanceKey: string, unit: Unit, sectionDescription: string, packetCode: string) {
    const state = this.stateManager.getCurrentState();
    const beneficiaries = new Beneficiaries();
    beneficiaries.assetKey = assetInstanceKey;
    beneficiaries.section = sectionDescription;
    beneficiaries.unit = unit.description;
    beneficiaries.unitCode = unit.code;
    beneficiaries.packetCode = packetCode;
    beneficiaries.beneficiary = unit.beneficiaries;
    state.listBeneficiariesUnit.push(beneficiaries);
    state.listBeneficiariesUnit = [...state.listBeneficiariesUnit];
    state.isBeneficiaryUnitVisibility = true;
    const instanceKeyUnitCodePacketCode = assetInstanceKey + '_' + unit.code + '_' + packetCode;
    state.isBeneficiaryUnitVisibilityArray.push(instanceKeyUnitCodePacketCode);
    this.stateManager.updateState$(of(state));
  }

  private disableBeneficiariesUnitPacket(assetInstanceKey: string, unit: Unit, sectionDescription: string, packetCode: string) {
    const state = this.stateManager.getCurrentState();
    this.stateManager.deleteBeneficiariesByAssetAndByUnit(
      this.stateManager.getCurrentState().resourceId, assetInstanceKey, unit.code).subscribe(val => {
      const index = state.isBeneficiaryUnitVisibilityArray.indexOf(assetInstanceKey + '_' + unit.code + '_' + packetCode);
      if (index > -1) {
        state.isBeneficiaryUnitVisibilityArray.splice(index, 1);
      }
      state.listBeneficiariesUnit.forEach((beneficiary, i) => {
        if (beneficiary.assetKey === assetInstanceKey && beneficiary.unitCode === unit.code &&
          beneficiary.section === sectionDescription && beneficiary.packetCode === packetCode) {
          state.listBeneficiariesUnit.splice(i, 1);
          state.listBeneficiariesUnit = [...state.listBeneficiariesUnit];
        }
      });
      state.beneficiariesSelected.forEach(b => {
        if (b.split('_')[0] === assetInstanceKey && b.split('_')[1] === unit.code) {
          state.beneficiariesSelected.splice(state.beneficiariesSelected.indexOf(b), 1);
        }
      });
      this.stateManager.updateState$(of(state));
    });
  }

  putSelectedUnit(unitSelected: Unit, section: AssetSection) {
    const state = this.stateManager.getCurrentState();
    return this.stateManager.putUnitSelected(state.resourceId
      , this.assetInstanceKey, section.code
      , this.refactorService.unitToPcUnit(unitSelected)).pipe(
    ).subscribe(() => this.unitSelected.emit(this.assetInstanceKey));
  }

  selectPacket(packageSelected: Package) {
    const state = this.stateManager.getCurrentState();
    // Update unit form
    this.updatePacketUnitForm(state, packageSelected);
    // Reset packages in state
    const asset = state.assetInstances.find(inst => inst.asset.key === this.assetInstanceKey).asset;
    asset.package.forEach(packet => {
      if (packet.code !== packageSelected.code) {
        packet.selected = false;
      } else {
        packet.selected = packageSelected.selected;
      }
    });
    asset.package.forEach(packet => {
      if (packet.selected) {
        packet.units.forEach(unit => {
          if (unit.beneficiaries.length > 0) {
            this.enableBeneficiariesUnitPacket(this.assetInstanceKey, unit, unit.section.description, packet.code);
          }
        });
      } else {
        packet.units.forEach(unit => {
          this.disableBeneficiariesUnitPacket(this.assetInstanceKey, unit, unit.section. description, packet.code);
        });
      }
    });

    // Set package on instance
    state.assetInstances.find(inst => inst.asset.key === this.assetInstanceKey).asset.selectedPackage = packageSelected;
    this.stateManager.updateState$(of(state));
    // Verify errors
    this.checkPackets.emit(packageSelected.selected);
  }

  updatePacketUnitForm(state: any, packageSelected: Package) {
    const assetInstance = state.assetInstances.find(inst => inst.asset.key === this.assetInstanceKey).asset;
    const sections = assetInstance.factors;
    const packages = assetInstance.package;
    // Manage not selected packet units => units form
    this.packetUnitsManagement(false, packages, sections);
    // Manage selected packet units => units form
    const selectedPackets = [];
    selectedPackets.push(packageSelected);
    this.packetUnitsManagement(packageSelected.selected, selectedPackets, sections);
    // Trigger validation
    const controls = this.guaranteesForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        this.guaranteesForm.setErrors({errors: true});
      } else {
        this.guaranteesForm.setErrors(null);
      }
    }
    this.guaranteesForm.markAllAsTouched();
    this.updateErrorMessageVariable();
  }

  updateUnitPremium(val: InputPremium, sectionCode: string, code: string) {
    val.unitCode = code;
    val.assetInstanceKey = this.assetInstanceKey;
    const state = this.stateManager.getCurrentState();

    this.stateManager.putInputPremium(state, this.assetInstanceKey, sectionCode, val).subscribe(st => {
      this.stateManager.updateState$(of(st));
      this.updateErrorMessageVariable();
    });
  }

  packetUnitsManagement(selected: boolean, packs: Package[], sections: any) {
    packs.forEach(packet => {
      packet.units.forEach(packUnit => {
        // Get section unit
        const section = sections.find(s => s.code === packUnit.section.code);
        const unit = section.unitList.find(u => u.code === packUnit.code);
        // Copy factors in unit package
        packUnit.factors = unit.factors;
        // Update form unit && factors
        this.guaranteesForm.controls[section.code + '_' + unit.code].setValue(selected, {
          onlySelf: true,
          emitEvent: false
        });
        packUnit.selected = selected;
        // OPERATIONS ON PACKET UNITS
        if (selected && !packUnit.enabled) {
          this.guaranteesForm.controls[section.code + '_' + unit.code].disable();
        } else if (!selected) {
          this.guaranteesForm.controls[section.code + '_' + unit.code].enable();
        }

        // Beneficiaries
        // const hasBeneficiaries = unit.beneficiaries && unit.beneficiaries.length > 0;
        // if (hasBeneficiaries) {
        //   unit.selected = selected;
        //   if (selected) {
        //     this.enableBeneficiariesUnit(this.assetInstanceKey, unit, section.description);
        //   } else {
        //     this.disableBeneficiariesUnit(this.assetInstanceKey, unit, section.description);
        //   }
        // }
        // unit.selected = selected;
        // if (selected && unit.beneficiaries.length > 0) {
        //   this.enableBeneficiariesUnitPacket(this.assetInstanceKey, unit, section.description, packet.code);
        // } else {
        //   this.disableBeneficiariesUnitPacket(this.assetInstanceKey, unit, section.description, packet.code);
        // }
        this.guaranteesForm = this.unitService.updateFactorsFormControl(packUnit, section.code, this.guaranteesForm);
      });
    });
  }

  updateUnitClause(output: ClauseOutput, sectionCode: string, unitCode: string) {
    const selectedClause = output.clause;
    const validFormClauses = output.formValid;
    if (selectedClause) {
      const state = this.stateManager.getCurrentState();
      const section = this.sections.find(s => s.code === sectionCode);
      const unit = section.unitList.find(u => u.code === unitCode);
      const clauses = unit.clauseUnit;
      clauses.find(clause => clause.code === selectedClause.code).selected = selectedClause.selected;
      this.stateManager.putUnitClauses(state, this.assetInstanceKey, sectionCode, unitCode, clauses).subscribe(st => {
        if (validFormClauses) {
          this.updateErrorMessageVariable();
        }
        this.stateManager.updateState$(of(st));
      });
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  canEnableInputPremium(sectionCode:string, unit: any){
    let result = true;
    unit.factors.forEach(factor => {
      let factiorValue = this.guaranteesForm.get(sectionCode +'_' + unit.code + '_' + factor.code).value;
      result = result && (!factor.mandatory || (factiorValue && factiorValue !== ''));
    });
    return result;
  }
}



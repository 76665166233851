<table class="rgi-ui-datepicker-calendar-table rgi-ui-datepicker-calendar-year-table">
  <thead class="rgi-ui-datepicker-calendar-header">
  <tr>
    <th class="rgi-ui-datepicker-calendar-table-divider" aria-hidden="true" colspan="3"></th>
  </tr>
  </thead>
  <tbody rgi-rx-calendar-body role="grid"
         [rows]="months" [numCols]="3" [cellRatio]="3 / 7"
         [activeCell]="activeCell"
         [todayValue]="todayMonth"
         [selectedValues]="selectedMonths"
         [selectMode]="selectMode"
         (keydown)="handleCalendarKeydown($event)"
         (select)="selectCalendarCell($event)">
  </tbody>
</table>

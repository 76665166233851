import { CurrencyCacheService, LpcCurrencyCache } from './../../../../../services/currency-cache.service';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { TranslationWrapperService } from '../../../../../i18n/translation-wrapper.service';
import { Subscription } from 'rxjs';
import {
  FinancialElementToggleEvent,
  KarmaFundDefinition } from '../../../../../../lib/modules/lpc-karma-funds/model/karma-fund-definition';
import { KarmaFundError } from '../../../../../../lib/modules/lpc-karma-funds/model/karma-fund-error';
import { LpcKarmaInvestmentFundComponent } from '../lpc-karma-investment-fund/lpc-karma-investment-fund.component';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';

@Component({
  selector: 'lpc-karma-investment-funds',
  templateUrl: './lpc-karma-investment-funds.component.html',
  styleUrls: ['./lpc-karma-investment-funds.component.scss'],
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class LpcKarmaInvestmentFundsComponent implements OnInit, OnDestroy {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };
  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  /**
   * CASO GESTIONE FONDI
   * Nel caso questo componente contiene i fondi all'interno di un profilo è necessario fornire profileIdForFunds
   * viene creato un FG per il profilo con al suo interno tutti i FC per i fondi
   * usare sempre il metodo getFormGroup quando si lavora sul form, restituisce quello corretto
   */

  // ****************************** PAGINAZIONE ******************************
  public pageSize = 5;
  public pageSizes = [5, 10, 20, 30, this.translate.getImmediate('lpc_Mostra_tutti')];
  public page = 1;
  public totalItems = 5;
  public count = 0;

  // ****************************** PAGINAZIONE ******************************

  @Input() public investmentMode: any;
  @Input() public definition: KarmaFundDefinition[];
  @Input() public profileIdForFunds: string; // da fornire se dobbiamo gestire dei fondi
  @Input() public totalAmount: number;

  @Input() public showSliderInput: boolean;
  @Input() public showPercentageInput: boolean;
  @Input() public percentageSumEqual100: boolean;
  @Input() public enableSingleElementSelection: boolean;
  @Input() public prevalIfLengthEqual1: boolean;

  @Input() public formGroup: UntypedFormGroup;
  get getFormGroup(): UntypedFormGroup {
    if (!!this.profileIdForFunds) {
      return this.formGroup.get(this.profileIdForFunds) as UntypedFormGroup;
    } else {
      return this.formGroup;
    }
  }

  @Output() changeValueEvent = new EventEmitter<any>();

  @ViewChildren('singleFinancialElement') singleFinancialElement: QueryList<LpcKarmaInvestmentFundComponent>;

  public internalFormGroup = new UntypedFormGroup({
    search: new UntypedFormControl(),
    count: new UntypedFormControl(this.pageSize),
  });

  /** variabile che contiene i profili/fondi -> filtro con il campo search */
  private $funds: KarmaFundDefinition[];
  public get funds(): KarmaFundDefinition[] {
    if (!this.internalFormGroup.get('search').value) {
      return this.$funds;
    } else {
      return this.$funds.filter(fund => fund.description.match(new RegExp(this.internalFormGroup.get('search').value, 'i')));
    }
  }

  /** restituisce i fondi selezionati da passare alla summary */
  get selectedElementsForSummary(): KarmaFundDefinition[] {
    const elementsFromForm = this.getFormGroup.getRawValue();
    const selectedFundsInfo = this.definition.filter(f => {
      const fund = f;
      fund.percentage = elementsFromForm[f.id];
      fund.percent = elementsFromForm[f.id];
      if (!!elementsFromForm[f.id] && +elementsFromForm[f.id] > 0) {
        return fund;
      }
    });
    return selectedFundsInfo;
  }

  // TODO: A COSA SERVE?
  get checksOnOverallAlloc(): boolean {
    return this.definition.every(profile => profile.maxPercentAllocation == null && profile.minPercentAllocation == null);
  }

  private $subscriptions: Subscription[] = [];
  public errors: KarmaFundError[] = [];

  constructor(
    protected translate: TranslationWrapperService,
    protected rgiNumberformatter: RgiCountryLayerNumberFormatPipe,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService
    ) {
      this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit() {
    this.initForm();
    this.$subscriptions.push(
      this.getFormGroup.valueChanges.subscribe(result => {
        this.errors = this.getErrorMessages(this.extractFormGroupErrors(this.getFormGroup));
      })
    );
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  /**
   * @description Inizializza il form tenendo conto delle carie casistiche (prevalIfLengthEqual1 e percentageSumEqual100).
   * Setta anche le validazioni e aggiorna gli errori.
   */
  initForm() {
    this.$funds = this.definition;

    // CASO FONDI
    // se si tratta dei fondi aggiungo al formGroup il contenitore che rappresenta il profilo a cui i fondi appartengono
    if (!!this.profileIdForFunds) {
      if (!this.formGroup.get(this.profileIdForFunds)) {
        this.formGroup.addControl(this.profileIdForFunds, new UntypedFormGroup({}));
      }
    }
    // !!! DA QUA IN POI UTILIZZO GETFORMGROUP PER PRENDERE IL FG CORRETTO (DIFFERENZA DI PROFONDITA' TRA PROFILO/FONDO) !!!

    // caso di un solo profilo/fondo -> prevalorizzo a 100% (solo con il flag prevalIfLengthEqual1 attivo)
    if (!!this.prevalIfLengthEqual1 && this.definition.length === 1) {
      const id = this.definition[0].id;
      if (!this.getFormGroup.get(id)) {
        this.getFormGroup.addControl(id, new UntypedFormControl(1));
      } else if (this.getFormGroup.get(id).value !== 1) {
        this.getFormGroup.get(id).setValue(1, { emitEvent: false });
      }
    } else {
      // caso normale -> se ci sono profili/fondi bloccati li prevalorizzo
      this.definition.forEach(fp => {
        const blockedValue = fp.minPercentAllocation === fp.maxPercentAllocation ? fp.minPercentAllocation : null;
        const value = !!blockedValue ? blockedValue : fp.percent ?
        fp.percent : fp.percentage ? fp.percentage : null;

        if (!this.getFormGroup.get(fp.id)) {
          this.getFormGroup.addControl(fp.id, new UntypedFormControl(value));
        } else if (value !== this.getFormGroup.get(fp.id).value) {
          this.getFormGroup.get(fp.id).setValue(value, { emitEvent: false });
        }
        /* if (!!blockedValue && !this.getFormGroup.get(fp.id).disabled) {
          this.getFormGroup.get(fp.id).disable()
        } */
      });
    }

    // aggiorno le validazioni e setto gli errori (se presenti)
    this.errors = this.getErrorMessages(this.getFormGroup.errors);
  }

  extractFormGroupErrors(fg) {
    const errors = {};
    if (!!fg.errors) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      errors['root'] = fg.errors; // errori del FG
    } else {
      Object.keys(fg.controls).forEach(k => {
        errors[k] = fg.get(k).errors; // errori dei FC
      });
    }
    return errors;
  }

  private getErrorMessages(errors: ValidationErrors): KarmaFundError[] {
    const array: KarmaFundError[] = [];
    if (errors) {
      Object.keys(errors).forEach(k => {
        const err = errors[k];
        if (!!err) {
          if (!!err.percentageSum) {

            const amount =  this.rgiNumberformatter.transform(this.getAmount(), '', this.currencyFormatterOptions);

            array.push({
              description: this.translate.getImmediate('lpc_investment_sum_must_be100') + ' ('
                + amount + '): ' + (this.rgiNumberformatter.transform(err.percentageSum.value, '', this.percentFormatterOptions)) + '%',
              amount: null,
              percent: null,
              limit: null,
              type: null
            });
          } else if (err.max || err.min) {
            const e = !!err.max ? err.max : err.min;
            array.push({
              description: this.getFundById(k).description,
              amount: null,
              percent: e.value,
              limit: e.limit,
              type: !!err.max ? 'max' : 'min'
            });
          } else if (err.noPerc) {
            array.push({
              description: (this.getFundById(k).description) + ': ' + this.translate.getImmediate('lpc_percentage_max_zero'),
              amount: null,
              percent: null,
              limit: null,
              type: null
            });
          }
        }
      });
    }
    return array;
  }

  private getAmount(): string {
    return typeof this.totalAmount === 'number'
      ? this.totalAmount.toFixed(2)
      : Number(this.totalAmount).toFixed(2);
  }

  private getFundById(id: string): KarmaFundDefinition {
    return this.$funds.find(fund => fund.id === id);
  }

  /** @description Metodo invocato ad ogni cambio di valore */
  valueChangeHandling(event) {
    // aggiorno le validazioni del control appena cambiato
    this.getFormGroup.get(event.id).updateValueAndValidity();

    this.changeValueEvent.emit(event);

    // CASO SINGLE ELEMENT SELECTION -> solo un elemento selezionabile con il 100%
    if (!!this.enableSingleElementSelection && !!event.value && event.value === 1) {
      // se posso selezionare un solo fondo azzero gli altri
      Object.keys(this.getFormGroup.controls).forEach(elementId => {
        const val = elementId === event.id ? event.value : null;
        this.getFormGroup.get(elementId).setValue(val);
      });
    }
  }


  onToggleClick(obj: FinancialElementToggleEvent) {
    this.singleFinancialElement.filter(el => el.definition.id !== obj.id).forEach(elem => {
      elem.formGroup.get('isPercent').setValue(obj.isPercent);
    });
  }



  // ****************************** PAGINAZIONE ******************************
  public handlePageChange(event) {
    this.page = event;
  }
  handlePageSizeChange(event) {
    this.pageSize = event;
    this.page = 1;
    if (event === 'Mostra tutti') {
      this.pageSize = this.funds.length;
    }
  }
  // ****************************** PAGINAZIONE ******************************

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentPrint} from '../../models/api-models/document-print';

@Component({
  selector: 'lib-re-issue-card-document-preview',
  templateUrl: './re-issue-card-document-preview.component.html',
  styleUrls: ['./re-issue-card-document-preview.component.css']
})
export class ReIssueCardDocumentPreviewComponent implements OnInit {

  @Output()
  modalClose = new EventEmitter();

  @Input()
  document: DocumentPrint;

  constructor() {
  }

  ngOnInit() {
  }

  close() {
    this.modalClose.emit();
  }

}

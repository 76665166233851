import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { RoutableComponent, RoutingService } from '@rgi/portal-ng-core';
import { ApiEnumType, CardComponent, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { ElaborationRequestService } from '../../elaboration-request.service';
import { ElaborationRequestDetailComponent } from '../elaboration-request-detail/elaboration-request-detail.component';
import { ApiNewRequestFilter } from '../../api/api-new-request-filter';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ApiClaimsParameters } from '../../api/api-claims-parameters';
import { ApiActionList } from '../../api/api-action-list';
import { ElaborationRequestDetails } from '../../dto/elaboration-request-details';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { ClaimConnectorActionsResponse } from '../../dto/claims-connector-actions-response';


enum RequestType {
  RESERVATION = '1',
  ONDEMAND = '2',
}

@Component({
  selector: "claims-new-elaboration-request",
  templateUrl: "./new-elaboration-request.component.html",
  styleUrls: ["./new-elaboration-request.component.scss"],
})
export class NewElaborationRequestComponent
  extends CardComponent
  implements OnInit, RoutableComponent
{
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild("modalE", { static: true }) modal: any;

  routes: { detail: any };

  public newRequestForm: UntypedFormGroup;
  public actionsList: ApiActionList[];
  public requestTypeList: TypeEnum[] = [];
  public jsonData: ApiClaimsParameters[] = [];
  public RESERVATION: string;
  errorMessage: string;
  public today: NgbDate;
  public outcomeRequest: boolean = false;
  emitter: any;
  public detailId: string;
  public outcome: string;
  public outcomeSuc;
  public outcomeFail;
  public showDetailButton: boolean;
  public maxJson: number = 0;
  public selectedRequestType: string;
  public creationError: string;

  selectedAction: any = null;
  formattedActionEntity: string = "";
  reviewData: any;
  reviewModal: any;
  isActionSelected: boolean = false; 

  r = RequestType;

  constructor(
    // private letterTemplatesService: LetterTemplatesService,
    @Inject("sessionService") private sessionServiceInject: any,
    @Inject("eventService") private eventService: any,
    @Inject("angularGridInstance") private gridInstance: any,
    @Inject("enumService") private enumService: any,
    private formBuilder: UntypedFormBuilder,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    private elaborationRequestService: ElaborationRequestService,
    private rxTranslationsService: RgiRxTranslationService,
    @Inject("elaborationRequestDetailComponent")
    elaborationRequestDetailComponent,
    private routingService: RoutingService
  ) {
    super(cardComponentService, sessionService);
    this.sessionServiceInject = sessionServiceInject;
    this.emitter = eventService;
    this.routes = {
      detail: elaborationRequestDetailComponent,
    };
    this.rxTranslationsService
      .translate("_CLAIMS_._RESERVATION")
      .subscribe((res) => (this.RESERVATION = res));
    this.rxTranslationsService
      .translate("_CLAIMS_._REQUEST_CREATED")
      .subscribe((res) => (this.outcomeSuc = res));
    this.rxTranslationsService
      .translate("_CLAIMS_._CREATION_FAILED")
      .subscribe((res) => (this.outcomeFail = res));
  }

  ngOnInit() {    
    this.getRequestType();
    this.getActionsList();
    this.initValidators();
    const today = new Date();
    this.today = new NgbDate(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate()
    );
  }

  initValidators() {
    this.newRequestForm = this.formBuilder.group({
      action: new UntypedFormControl("", [Validators.required]),
      requestType: new UntypedFormControl("", [Validators.required]),
      codeChain: new UntypedFormControl("", [Validators.required]),
      elaborationRequestDate: [],
      externalKey: new UntypedFormControl("", [Validators.required]),
      jsonTextArea: [],
      jsonExternalKey: [],
    });
  }

  getRequestType() {
    this.requestTypeList = [];

    const enumEl: ApiEnumType[] =
      this.enumService.getEnumList("claims.queueType");
    if (enumEl) {
      enumEl.forEach((respEnum: ApiEnumType) => {
        const list = {
          eTypeCode: respEnum.codice,
          eTypeDescription: respEnum.descrizione,
        };
        this.requestTypeList.push(list);
      });
    }
  }

  // getCurrentSession() {
  //   return this.sessionServiceInject.list().find(el => el.isActive);
  // }

  getActionsList() {
    this.elaborationRequestService.getActionsList().subscribe((res: any) => {
      this.actionsList = res.response;
    });
  }

  saveRequest() {
    this.outcomeRequest = false;
    const filter = new ApiNewRequestFilter();

    if (this.newRequestForm.controls.action.value) {
      filter.codeAction = this.getAction();
    }
    if (this.newRequestForm.controls.requestType.value) {
      filter.requestType =
        this.requestTypeList[
          Number(this.newRequestForm.controls.requestType.value)
        ].eTypeCode;
    }
    if (this.newRequestForm.controls.codeChain.value) {
      filter.codeChain = this.newRequestForm.controls.codeChain.value;
    }
    if (filter.requestType === RequestType.RESERVATION) {
      if (this.newRequestForm.controls.elaborationRequestDate.value) {
        filter.elaborationRequestDate =
          this.newRequestForm.controls.elaborationRequestDate.value.getTime();
      }
    }
    if (this.newRequestForm.controls.externalKey.value) {
      filter.externalKey = this.newRequestForm.controls.externalKey.value;
    }

    if (this.jsonData.length <= this.maxJson) {
      this.jsonData.forEach((elem) => {
        console.log(elem.externalKey);
        if (!elem.externalKey) {
          elem.externalKey = "NP";
        }
      });
      filter.claimsConnectorActionsParameterEntity = this.jsonData;
      console.log(filter.claimsConnectorActionsParameterEntity);
    } else {
      this.rxTranslationsService
        .translate("_CLAIMS_._TOO_MANY_JSON_WERE_UPLOADED")
        .subscribe((res) => (this.errorMessage = res));
    }

    this.startLoader();
    this.elaborationRequestService.newRequest(filter).subscribe(
      (res: any) => {
        this.detailId = res.elaborationId;
        this.modalOpen(this.getPopupMessage(res));
        this.outcomeRequest = true;
        // this.showDetailButton = filter.requestType === RequestType.ONDEMAND;

         // Mostra il pulsante solo se la richiesta è stata salvata correttamente
        this.showDetailButton = true;  // Mostra il pulsante

        // Verifica il tipo di richiesta
        if (filter.requestType === RequestType.ONDEMAND) {
          // Logica specifica per ONDEMAND
        } else if (filter.requestType === RequestType.RESERVATION) {
          // Logica specifica per PRENOTAZIONE
        }

        this.newRequestForm.reset();
        this.deleteJson();
        this.emitter.broadcastEvent("stop-loader");
      },
      (error: Error) => {
        console.log(error);
        this.rxTranslationsService
          .translate("_CLAIMS_._CREATION_ERROR")
          .subscribe((res) => (this.errorMessage = res));
        this.emitter.broadcastEvent("stop-loader");
        this.showDetailButton = false; // Nascondi il pulsante in caso di errore
      }
    );
  }

  getPopupMessage(resp: ClaimConnectorActionsResponse): string {
    let message = "";
    if (resp.response && resp.response.queueOutcomeState) {
      message += resp.response.queueOutcomeState.description;
      if (
        resp.response.jsonDiscardedBean &&
        resp.response.jsonDiscardedBean.length > 0
      ) {
        for (const bean of resp.response.jsonDiscardedBean) {
          message += " - " + bean.discardError.description;
        }
        message += " - ElaborationId: " + resp.elaborationId;
      }
    } else if (resp.requestOutcome && resp.requestOutcome.description) {
      message +=
        resp.requestOutcome.code + " " + resp.requestOutcome.description;
    }
    return message;
  }

  modalOpen(outcome) {
    this.creationError = "";
    this.outcome = outcome;
    this.modal.open();
  }

  modalClose() {
    this.modal.close();
    this.creationError = "";
  }

  private startLoader() {
    this.errorMessage = null;
    this.gridInstance.cards.refresh();
    this.emitter.broadcastEvent("start-loader");
  }

  addJson() {
    if (
      this.newRequestForm.controls.jsonTextArea.value &&
      this.jsonData.length < this.maxJson &&
      this.maxJson !== 0
    ) {
      const jsonEntity = {
        externalKey: this.newRequestForm.controls.jsonExternalKey.value,
        parameter: this.newRequestForm.controls.jsonTextArea.value,
      };
      this.jsonData.push(jsonEntity);
      this.newRequestForm.controls.jsonTextArea.reset();
      this.newRequestForm.controls.jsonExternalKey.reset();     
    }   
  }

  deleteJson(i?: number) {
    if (i != null) {
      this.jsonData.splice(i, 1);
    } else {
      this.jsonData = [];
    }
  }

  checkMaxJson() {
    const ctrl = this.newRequestForm.controls;
    if (!ctrl.requestType.value || !ctrl.action.value) {
      return;
    }

    const rType: TypeEnum =
      this.requestTypeList[
        Number(this.newRequestForm.controls.requestType.value)
      ];
    const acType: ApiActionList =
      this.actionsList[Number(this.newRequestForm.controls.action.value)];
    this.selectedRequestType = rType.eTypeCode;
    if (rType.eTypeCode === RequestType.RESERVATION) {
      this.maxJson = acType.multiJMaxRecSked;
    } else if (rType.eTypeCode === RequestType.ONDEMAND) {
      this.maxJson = acType.multiJMaxRecOnline;
    } else {
      this.maxJson = 0;
    }
    console.log(acType);
  }

  onActionChange(event: any) {
    const selectedIndex = event.target.value;
    this.selectedAction = this.actionsList[selectedIndex];       
    if (this.selectedAction && this.selectedAction.actionEntity) {
      try {
        // Converte la stringa JSON in un oggetto JavaScript
        const jsonObject = JSON.parse(this.selectedAction.actionEntity);
        // Formatta l'oggetto JSON con indentazione di 4 spazi
        this.formattedActionEntity = JSON.stringify(jsonObject, null, 4);        
      } catch (error) {
        console.error("Errore durante il parsing del JSON: ", error);
        this.formattedActionEntity = this.selectedAction.actionEntity;
      }
    } else {
      this.formattedActionEntity = "";
    }
  }

  
  

  getAction(): string {
    const action: ApiActionList =
      this.actionsList[Number(this.newRequestForm.controls.action.value)];
    return action.actionCode;
  }

  validateDate(controlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const min: Date = new Date(1990, 1, 1);

      if (control.value) {
        if (control.value instanceof Date) {
          const inputDate = control.value;
          inputDate.setHours(0, 0, 0, 0);
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
          if (inputDate > currentDate) {
            control.setErrors({ futureDate: true });
          } else if (inputDate < min) {
            control.setErrors({ tooOld: true });
          } else {
            control.setErrors(null);
          }
        } else {
          control.setErrors({ invalidDate: true });
        }
      } else {
        const validator: any =
          control.validator && control.validator(new UntypedFormControl());

        if (validator && validator.required) {
          control.setErrors({ required: true });
        } else {
          control.setErrors(null);
        }
      }
    };
  }

  // handleButtonClick() {
  //   if (this.newRequestForm.controls.requestType.value === RequestType.RESERVATION) {
  //     // Mostra i dati del form nella modale
  //     this.reviewData = this.getFormData();  // Recupera i dati del form
  //     this.reviewModal.open();  // Apre la modale di revisione
  //   } else {
  //     // Esecuzione immediata: mostra i dettagli dell'ultima esecuzione
  //     this.goToDetail();
  //   }
  // }

  goToDetail() {
    if (this.newRequestForm.controls.requestType.value === RequestType.RESERVATION) {
      // Mostra i dettagli della prenotazione nella modale nel caso della prenotazione
      // this.reviewData = this.getFormData();  // Recupera i dati del form
      this.reviewModal.open();  // Apre la modale
    } else{
      // Esecuzione onDemand
    // perform search
    const id = Number(this.detailId);
    this.eventService.broadcastEvent("start-loader");
    this.elaborationRequestService.getElaborationRequestOutcome(id).subscribe(
      (res: any) => {
        // build from parent class
        const details = new ElaborationRequestDetails(res.response[0]);
        this.elaborationRequestService.setElaborationRequestDetailList(details);
        this.eventService.broadcastEvent("stop-loader");
        this.routingService.loadComponent(this.routes.detail);
        (
          this.routingService.componentRef
            .instance as ElaborationRequestDetailComponent
        ).card = this.card;
        // this.session.open('claimsExperts', 'home', '', true);
        this.eventService.broadcastEvent("stop-loader");
      },
      (error) => {
        this.eventService.broadcastEvent("stop-loader");
      }
    );
  }}


  // getFormData() {
  //   return {
  //     action: this.newRequestForm.controls.action.value,
  //     requestType: this.newRequestForm.controls.requestType.value,
  //     codeChain: this.newRequestForm.controls.codeChain.value,
  //     elaborationRequestDate: this.newRequestForm.controls.elaborationRequestDate.value,
  //     externalKey: this.newRequestForm.controls.externalKey.value,
  //     jsonData: this.jsonData
  //   };
  // }
  


}

interface TypeEnum {
  eTypeCode: string;
  eTypeDescription: string;
}

import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AnagEntityIta, AnagUbicationComponent, CORE_ADDRESS_FUNCTION} from '@rgi/anag';
import {AddressApiContacts} from '../../models/domain-models/address-api-contacts';
import {ReIssuePolicyAddressUtilityService} from '../re-issue-utility/re-issue-policy-address-utility.service';
import {ModalService} from '@rgi/rx/ui';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {ApiPcAddress} from '../../models/api-models/apiPcAddress';
import {ReIssueCommunicationTypeEnum} from '../../enums/re-issue-communication-type-enum';

class AnagAddressData {
  modalLabel: string;
  addressType: string;
  hideNormalize: boolean;
  address: any;
}

@Component({
  selector: 're-issue-contact',
  templateUrl: './re-issue-contact.component.html',
  styleUrls: ['./re-issue-contact.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ReIssueContactComponent implements OnInit {
  policyContactForm: UntypedFormGroup;
  selectedIndex = ReIssueCommunicationTypeEnum.POSTAL_MAIL;
  msg: string;
  lastIndex = 0;
  errormsg: string;
  radioButtonSelected: boolean[] = [true, false];
  @Input() addressData;
  @Input() subject;
  @Input() contact;
  @Output() updatePolicyAddress: EventEmitter<any> = new EventEmitter<any>();
  @Output() policyContactErrorMessage: EventEmitter<string> = new EventEmitter<string>();

  readonly ReIssueCommunicationTypeEnum: typeof ReIssueCommunicationTypeEnum = ReIssueCommunicationTypeEnum;
  private mainAddress: AddressApiContacts;

  constructor(protected formBuilder: UntypedFormBuilder,
              private portfolioPolicyAddressUtilityService: ReIssuePolicyAddressUtilityService,
              protected customModalService: ModalService,
              protected translate: RgiRxTranslationService) {
    this.policyContactForm = this.formBuilder.group({
      mainAddress: [undefined, ],
      policyEmail: [undefined, ]
    });
  }

  ngOnInit() {
    this.addressData = [];
    const address: AddressApiContacts = this.addressFromSubject(this.subject);
    this.addressData.push(address);

    switch (this.contact?.channelType) {
      case undefined:
      case null:
        if (!this.policyContactForm.controls.policyEmail.value) {
          this.policyContactForm.patchValue({
            policyEmail: this.subject.emails[0]
          });
        }
        if (this.contact?.address != null) {
          this.policyContactForm.controls.mainAddress.setValue(this.contact.address);
          if (!(this.contact.address.formattedAddress === address.formatAddress)) {
            this.contact.address = this.portfolioPolicyAddressUtilityService.pcAddressToAddressData(this.contact.address);
            this.contact.address = this.portfolioPolicyAddressUtilityService.addressApiToPcAddress(this.contact.address);
            this.addressData.push(this.contact.address);
            this.mainAddress = this.contact.address;
            this.radioButtonSelected[0] = false;
            this.radioButtonSelected[1] = true;
          }
        } else {
          if (address.formatAddress && address.formatAddress.length !== 0) {
            this.mainAddress = address;
            this.policyContactForm.controls.mainAddress.setValue(this.mainAddress);
            // tslint:disable-next-line:max-line-length
            const addressApi = this.portfolioPolicyAddressUtilityService.addressApiToPcAddress(this.policyContactForm.controls.mainAddress.value);
            const emitted = {mainAddress: addressApi, mail: null};
            this.updatePolicyAddress.emit(emitted);
          }
        }
        break;
      case ReIssueCommunicationTypeEnum.EMAIL.toString():
        this.policyContactForm.controls.policyEmail.setValue(this.contact.email);
        this.selectedIndex = ReIssueCommunicationTypeEnum.EMAIL;
        this.updateLastIndex(this.selectedIndex);
        break;
      case ReIssueCommunicationTypeEnum.RESERVED_AREA.toString():
        if (!this.policyContactForm.controls.policyEmail.value) {
          this.policyContactForm.patchValue({
            policyEmail: this.subject.emails[0]
          });
        }
        this.selectedIndex = ReIssueCommunicationTypeEnum.RESERVED_AREA;
        this.updateLastIndex(this.selectedIndex);
        break;
      default:
        break;
    }
    this.updateErrorMessages();
  }

  selectedAddress(address: AddressApiContacts, i) {
    this.radioButtonSelected.forEach((radioselect, index) => {
      if (index === i) {
        this.radioButtonSelected[index] = true;
      } else {
        this.radioButtonSelected[index] = false;
      }
    });
    this.mainAddress = address;
    this.policyContactForm.controls.mainAddress.setValue(this.mainAddress);
    if (address) {
      this.selectedIndex = ReIssueCommunicationTypeEnum.POSTAL_MAIL;
      let addressApi: any;
      if (i === 0) {
        addressApi = this.portfolioPolicyAddressUtilityService.addressApiToPcAddress(this.policyContactForm.controls.mainAddress.value);
      } else {
        addressApi = this.mainAddress;
      }
      const emitted = {mainAddress: addressApi, mail: null};
      this.updatePolicyAddress.emit(emitted);
    }
    this.updateErrorMessages();
  }

  selectEmail() {
    if (this.policyContactForm.controls.policyEmail.value != null && this.policyContactForm.controls.policyEmail.value !== '') {
      const emitted = {
        mainAddress: null,
        mail: this.policyContactForm.controls.policyEmail.value,
        channelType: ReIssueCommunicationTypeEnum.EMAIL
      };
      this.updatePolicyAddress.emit(emitted);
    }
    this.updateErrorMessages();
  }

  setData(event: any) {
    switch (event.index) {
      case ReIssueCommunicationTypeEnum.POSTAL_MAIL:
        this.byPostaOrdinaria();
        break;
      case ReIssueCommunicationTypeEnum.EMAIL:
        this.byEmail();
        break;
      case ReIssueCommunicationTypeEnum.RESERVED_AREA:
        this.byReservedArea();
        break;
      default:
        break;
    }
    this.updateErrorMessages();
  }

  byPostaOrdinaria() {
    this.selectedIndex = ReIssueCommunicationTypeEnum.POSTAL_MAIL;
    if (this.checkIndex(this.selectedIndex)) {
      this.updateLastIndex(this.selectedIndex);
      this.policyContactForm.controls.policyEmail.setValidators(null);
      let addressApi: any;
      if (this.radioButtonSelected[0] === true) {
        addressApi = this.portfolioPolicyAddressUtilityService.addressApiToPcAddress(this.addressData[0]);
      } else if (this.policyContactForm.controls.mainAddress.value && this.policyContactForm.controls.mainAddress.value !== '') {
        addressApi = this.policyContactForm.controls.mainAddress.value;
        addressApi = this.portfolioPolicyAddressUtilityService.pcAddressToAddressData(addressApi);
        addressApi = this.portfolioPolicyAddressUtilityService.addressApiToPcAddress(addressApi);
      }
      if (addressApi) {
        this.policyContactForm.controls.mainAddress.setValue(addressApi);
        const emitted = {mainAddress: addressApi, mail: null, channelType: null};
        this.updatePolicyAddress.emit(emitted);
      }
    }
  }

  byEmail() {
    this.selectedIndex = ReIssueCommunicationTypeEnum.EMAIL;
    if (this.checkIndex(this.selectedIndex)) {
      this.updateLastIndex(this.selectedIndex);
      this.policyContactForm.controls.policyEmail.setValidators(Validators.required);
      if (this.policyContactForm.controls.policyEmail.value != null && this.policyContactForm.controls.policyEmail.value !== '') {
        const emitted = {mainAddress: null, mail: this.policyContactForm.controls.policyEmail.value, channelType: 1};
        this.updatePolicyAddress.emit(emitted);
      } else if (this.subject.emails[0]) {
        this.policyContactForm.patchValue({
          policyEmail: this.subject.emails[0]
        });
        const emitted = {
          mainAddress: null,
          mail: this.policyContactForm.controls.policyEmail.value,
          channelType: ReIssueCommunicationTypeEnum.EMAIL
        };
        this.updatePolicyAddress.emit(emitted);
      }
    }
  }

  byReservedArea() {
    this.selectedIndex = ReIssueCommunicationTypeEnum.RESERVED_AREA;
    if (this.checkIndex(this.selectedIndex)) {
      this.updateLastIndex(this.selectedIndex);
      this.policyContactForm.controls.policyEmail.setValidators(null);
      const emitted = {
        mainAddress: null,
        mail: this.policyContactForm.controls.policyEmail.value,
        channelType: ReIssueCommunicationTypeEnum.RESERVED_AREA
      };
      this.updatePolicyAddress.emit(emitted);
    }
  }

  addAddress() {
    const ubicationData = new AnagAddressData();
    ubicationData.modalLabel = 'Indirizzo'; // can be whatever you want, please use translation keys
    ubicationData.addressType = CORE_ADDRESS_FUNCTION.portfolioSubject;
    // default value, you can change it to rietreve different address configuration (check exported CORE_ADDRESS_FUNCTION)
    ubicationData.hideNormalize = false; // optional, hides the normalize button when set to true. default false
    const ubicationModal = this.customModalService.openComponent(AnagUbicationComponent, ubicationData);
    ubicationModal.modal.enableClickBackground = false;
    ubicationModal.modal.onClose.subscribe(address => { // addr is an AnagApiAddress object
      if (address) {
        address = this.portfolioPolicyAddressUtilityService.addressApiToPcAddress(address);
        this.policyContactForm.controls.mainAddress.setValue(address);
        this.addressData.push(address);
        if (this.addressData.length === 1) {
          this.radioButtonSelected[0] = true;
          this.mainAddress = this.addressData[0];
        } else if (this.addressData.length === 2) {
          this.radioButtonSelected[1] = true;
          this.radioButtonSelected[0] = false;
          this.mainAddress = this.addressData[1];
        }
        const emitted = {mainAddress: this.policyContactForm.controls.mainAddress.value, mail: null};
        this.updatePolicyAddress.emit(emitted);
        this.updateErrorMessages();
      }
    });
  }

  changeAddress(addressIn: ApiPcAddress) {
    const ubicationData = new AnagAddressData();
    ubicationData.modalLabel = 'Indirizzo'; // can be whatever you want, please use translation keys
    ubicationData.address = this.portfolioPolicyAddressUtilityService.pcAddressToAddressData(addressIn);
    ubicationData.addressType = CORE_ADDRESS_FUNCTION.portfolioSubject;
    // default value, you can change it to rietreve different address configuration (check exported CORE_ADDRESS_FUNCTION)
    ubicationData.hideNormalize = false; // optional, hides the normalize button when set to true. default false
    const ubicationModal = this.customModalService.openComponent(AnagUbicationComponent, ubicationData);
    ubicationModal.modal.enableClickBackground = false;
    ubicationModal.modal.onClose.subscribe(address => { // addr is an AnagApiAddress object
      if (address) {
        address = this.portfolioPolicyAddressUtilityService.addressApiToPcAddress(address);
        this.policyContactForm.controls.mainAddress.setValue(address);
        this.addressData[1] = address;
        if (this.addressData.length === 1) {
          this.radioButtonSelected[0] = true;
          this.mainAddress = this.addressData[0];
        } else if (this.addressData.length === 2) {
          this.radioButtonSelected[1] = true;
          this.radioButtonSelected[0] = false;
          this.mainAddress = this.addressData[1];
        }
        const emitted = {mainAddress: this.policyContactForm.controls.mainAddress.value, mail: null};
        this.updatePolicyAddress.emit(emitted);
        this.updateErrorMessages();
      }
    });
  }

  private updateErrorMessages() {
    let errorMessage: any;
    switch (this.selectedIndex) {
      case ReIssueCommunicationTypeEnum.POSTAL_MAIL:
        if (!this.policyContactForm.controls.mainAddress.value || this.policyContactForm.controls.mainAddress.value === '') {
          errorMessage = 'RE_ISSUE.ALTERNATIVE_POLICY_ADRESS';
        }
        break;
      case ReIssueCommunicationTypeEnum.EMAIL:
        if (!this.policyContactForm.controls.policyEmail.value || this.policyContactForm.controls.policyEmail.value === '') {
          errorMessage = 'RE_ISSUE.VALID_POLICY_EMAIL';
        }
        break;
      case ReIssueCommunicationTypeEnum.RESERVED_AREA:
        break;
      default:
        break;
    }
    this.policyContactErrorMessage.emit(errorMessage);
  }

  private addressFromSubject(subj) {
    const address: AddressApiContacts = new AddressApiContacts();
    // address.country = subj.residence.country ?
    //   new GenericEntity(null, subj.residence.country.codice, subj.residence.country.descrizione) : null;
    address.countryCode = subj.residence.country ? subj.residence.country.codice : null;
    address.adminLevel1 = subj.residence.adminLevel1;
    address.adminLevel2 = subj.residence.adminLevel2;
    address.adminLevel3 = subj.residence.adminLevel3;
    address.adminLevel2Short = subj.residence.adminLevel2Short;
    address.cap = subj.residence.cap;
    address.toponym = subj.residence.toponym ?
      new AnagEntityIta(subj.residence.toponym.codice, subj.residence.toponym.descrizione) : null;
    address.placeAddress = subj.residence.placeAddress;
    address.number = subj.residence.number;
    address.formatAddress = subj.residence.formatAddress;
    return address;
  }

  private checkIndex(selectedIndex: number) {
    if (this.lastIndex === selectedIndex) {
      return false;
    } else {
      return true;
    }
  }

  private updateLastIndex(selectedIndex: number) {
    this.lastIndex = selectedIndex;
  }
}

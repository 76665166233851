<div class="rgi-ui-text-1 rgi-ui-info rgi-ui-d-flex rgi-ui-g-3">
  {{(titleParams && titleParams.policyNumber) ? ('RGI_SHARED_COMPONENTS.OUTBOUND_DOC_TITLE' | translate:titleParams) : ('RGI_SHARED_COMPONENTS.OUTBOUND_DOC_PROPOSAL_TITLE' | translate:titleParams)}}
  <div class="rgi-ui-tooltip">
    <i class="rgi-ui-text-1 rgi-comp-doc-action rgi-ui-info rgi-ui-icon-change" (click)="getOutboundDocuments()"></i>
    <span class="rgi-ui-tooltip-text" translate>{{'RGI_SHARED_COMPONENTS.REFRESH' | translate}}</span>
  </div>
</div>
<rgi-rx-datatable [sortable]="true" *ngIf="schema"
                  [data]="dataSource" [schema]="schema"
                  (onAction)="onActionClick($event)"
                  [pageOptions]="[5,10,20,50]"
                  [expansionRow]="rowDetail">
  <ng-template rgiRxDataTableCell="statusDescription" let-ctx>
    <div class="rgi-ui-badge-rounded-pill {{ctx.row.statusColor}}" translate>
      {{ctx.row.statusDescription}}
    </div>
  </ng-template>

  <ng-template rgiRxDataTableCell="sign" let-ctx>
    <div class="rgi-ui-tooltip">
      <button class="rgi-ui-btn rgi-ui-text-3 rgi-comp-doc-action rgi-ui-info {{ctx.row.signIcon}}"
              (click)="manageSignature(ctx.row)" [disabled]="!ctx.row.isSignable">
      </button>
      <span class="rgi-ui-tooltip-text" translate>{{ctx.row.signTooltipDescr}}</span>
    </div>
  </ng-template>

</rgi-rx-datatable>
<ng-template #rowDetail let-ctx>
  <rgi-generic-detail [details]="ctx.details" [showSeparatorLine]="false" [colNum]="2">
  </rgi-generic-detail>
</ng-template>

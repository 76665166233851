import {AnagEntityIta} from '../anag-model/anag-api/anag-subject-api';

export const PERSON_TYPE_ENUM = 'sinistri.TipoSoggetto';
export const PROFESSION_TYPE_ENUM = 'anag.Tipo professione';
export const CORPORATE_FORM_ENUM = 'anag.Forma Societaria';
export const BUSINESS_SECTOR_ENUM = 'anag.Settore Attivita';
export const TOPONYMS_ENUM = 'anag.Toponimi';
export const PRIVACY_CONSENSUS_ENUM = 'anag.ConsensoPrivacy';
export const PRIVACY_ENUM = 'anag.Privacy';
export const MAIN_CONTACT_ENUM = 'anag.Contatto Principale';
export const QUALIFICATION_ENUM = 'anag.Titolo Studio';
export const SUBJECT_TYPE_ENUM = 'anag.Tipo Soggetto';
export const MARITAL_STATUS_ENUM = 'anag.Stato Civile';
export const DOCUMENT_TYPE_ENUM = 'anag.Tipo documento';
export const PROFESSION_ENUM = 'anag.Professione';
export const RISK_CLASSIFICATION_ENUM = 'anag.RiskClassification';
export const REASON_CLASSIFICATION_ENUM = 'anag.ReasonClassification';
export const BASIS_CLASSIFICATION_ENUM = 'anag.BasisClassification';
export const BOND_TYPE_ENUM = 'anag.Tipo Legame';
export const NATION_ENUM = 'anag.Nazione';
export const PROVINCE_ENUM = 'anag.Provincia';
export const DOCUMENT_ISSUING_AUTHORITY_ENUM = 'anag.Autorita Rilascio Documenti';
export const LANGUAGE_ENUM = 'anag.Lingua';
export const ECONOMIC_ACTIVITY_GROUP_ENUM = 'anag.AtecoCodes';
export const ECONOMIC_ACTIVITY_SUBGROUP_ENUM = 'anag.EconomicActivitySubgroup';
export const ATECO_CODES_ENUM = 'anag.AtecoCodes';
export const GENDER_IDENTITY_ENUM = 'anag.genderIdentity';
export const TYPE_RESIDENCE = 'anag.Tipo Indirizzo';
export const TYPE_COUNTER_TERRORISM = 'anag.Antiterrorismo';
export const HONORIFIC_TITLE = 'anag.Titolo Onorifico';
export const RATING_ORIGIN = 'anag.ratingOrigin';

export const ENUMCODES = [
  PERSON_TYPE_ENUM, PROFESSION_TYPE_ENUM, CORPORATE_FORM_ENUM, BUSINESS_SECTOR_ENUM, TOPONYMS_ENUM, PRIVACY_CONSENSUS_ENUM,
  PRIVACY_ENUM, MAIN_CONTACT_ENUM, QUALIFICATION_ENUM, MARITAL_STATUS_ENUM, DOCUMENT_TYPE_ENUM, PROFESSION_ENUM, BOND_TYPE_ENUM,
  NATION_ENUM, PROVINCE_ENUM, DOCUMENT_ISSUING_AUTHORITY_ENUM, LANGUAGE_ENUM, ECONOMIC_ACTIVITY_SUBGROUP_ENUM,
  ECONOMIC_ACTIVITY_GROUP_ENUM, ATECO_CODES_ENUM, GENDER_IDENTITY_ENUM, SUBJECT_TYPE_ENUM, TYPE_RESIDENCE, TYPE_COUNTER_TERRORISM,
  HONORIFIC_TITLE, RATING_ORIGIN
];

const sex_female = new AnagEntityIta('2', '_ANAG_._LABEL_._FEMALE_');
const sex_male = new AnagEntityIta('1', '_ANAG_._LABEL_._MALE_');

export const ANAG_SEX = [
  sex_female,
  sex_male
];

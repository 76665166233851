export class ExtProperty {
    chiave: string;
    valore: string;
    constructor (chiave:string, valore:string) {
        this.chiave = chiave;
        this.valore = valore;
    }
}

export class Extension {
    properties: ExtProperty[];
}
<rgi-rx-panel style="display: inline-block; margin-top:10%">
    <rgi-rx-panel-header [closeable]="true"
                         (onClose)="onCloseModal(); this.expertLinkedSearchList = []"></rgi-rx-panel-header>

    <form [formGroup]="searchForm" style="margin-bottom: 2vh;">
      <div class="form-group">
        <!-- COUNTRY -->
        <!--   entire country row is hidden if uma disabled-->
        <div class="col-xs-3" *ngIf="isUmaUniqueKeyManagementEnabled">
          <label ng-bind="label">{{ '_CLAIMS_._NATION' | translate }}</label>
          <select class="core-select form-control" id="expert-search-country" name="experts-search-country"
                  formControlName="country"
                  attr.data-qa="experts-search-input">
            <option value=""></option>
            <option *ngFor="let country of this.formConfig.countries"
                    [value]="country.code">{{country.description}}</option>
          </select>
        </div>
        <!-- SUBJECT TYPE-->
        <div class="col-xs-3">
          <label ng-bind="label">{{ '_CLAIMS_._SUBJECT_TYPE' | translate }}</label>
          <select class="core-select form-control" id="expert-search-subjectType" name="experts-search-subjectType"
                  formControlName="subjectType"
                  attr.data-qa="experts-search-input">
            <option value=""></option>
            <option *ngFor="let type of this.formConfig.subjectTypes"
                    [value]="type.idObject">{{type.description}}</option>
          </select>
        </div>
        <!--      EXPERT CODE-->
        <div class="col-xs-3">
          <label ng-bind="label">{{ '_CLAIMS_._CODE' | translate }} </label>

          <input type="text" class="form-control" name="code" formControlName="expertCode"
                 attr.data-qa="expertsearch-code-input">
        </div>

        <!--      BUSINESS NAME (RAGIONE SOCIALE) -->
        <div class="col-xs-3">
          <label ng-bind="label">{{ '_CLAIMS_._BUSINESS_NAME' | translate }} </label>

          <input [attr.disabled]="!isLegalSubject ? true : null" type="text" class="form-control"
                 name="businessName"
                 formControlName="businessName"
                 attr.data-qa="expertsearch-businessName-input">
        </div>

        <!--   NAME   -->
        <div class="col-xs-3">
          <label ng-bind="label">{{ '_CLAIMS_._NAME' | translate }} </label>

          <input [attr.disabled]="isLegalSubject ? true : null" type="text" class="form-control" name="name"
                 formControlName="name"
                 attr.data-qa="expertsearch-name-input">
        </div>
        <!--   SURNAME-->
        <div class="col-xs-3">
          <label ng-bind="label">{{ '_CLAIMS_._SURNAME' | translate }} </label>

          <input [attr.disabled]="isLegalSubject ? true : null" type="text" class="form-control" name="surname"
                 formControlName="surname"
                 attr.data-qa="expertsearch-surname-input">
        </div>


        <!--      a priori show CF and P.Iva fields if uma is disabled-->
        <!--      CODICE FISCALE-->
        <div *ngIf="!isUmaUniqueKeyManagementEnabled" class="col-xs-3">
          <label ng-bind="label">{{ '_CLAIMS_._FISCAL_CODE' | translate }} </label>

          <input type="text" class="form-control" name="fiscalCode" formControlName="fiscalCode"
                 attr.data-qa="expertsearch-fiscalCode-input">
        </div>
        <!--      PARTITA IVA -->
        <div *ngIf="!isUmaUniqueKeyManagementEnabled" class="col-xs-3">
          <label ng-bind="label">{{ '_CLAIMS_._VAT_NUMBER' | translate }} </label>

          <input type="text" class="form-control" name="vatNumber" formControlName="vatNumber"
                 attr.data-qa="expertsearch-vatNumber-input">
        </div>

        <!--      SHOW UMA FIELDS-->
        <!--  UMA fields shown only if enabled-->
        <div *ngIf="isUmaUniqueKeyManagementEnabled" style="all:unset;">
          <!--        loop through countries. only display currently selected country -->
          <div *ngFor="let country of formConfig.countries; let countryIndex = index;">
            <div style="all:unset;" *ngIf="searchForm.value.country===country.code">
              <!--            display the 3 optional labels, only if they are defined -->
              <!--          ngIf : row is shown only if the label has a value  -->
              <!--            formControlName is a combination of COUNTRYCODE + G (legal or physical subject) + CKEY_NUMBER -->
              <!--            CKEY 1-->
              <div class="col-xs-3"
                   *ngIf="umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey1 !==''">
                <label
                  ng-bind="label">{{ umaConfigKeys[country.code][isLegalSubject ? '2' : '1']['labelCkey1']}} </label>
                <input type="text" class="form-control"
                       name="{{country.code + isLegalSubject ? '-G' : ''  +  '-Ckey1' }}"
                       formControlName="{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey1' }}"
                       attr.data-qa="expertsearch-{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey1' }}-input">
              </div>
              <!--            CKEY 2-->
              <div class="col-xs-3"
                   *ngIf="umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey2 !==''">
                <label
                  ng-bind="label">{{ umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey2}} </label>
                <input type="text" class="form-control"
                       name="{{country.code + isLegalSubject ? '-G' : ''  +  '-Ckey2' }}"
                       formControlName="{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey2' }}"
                       attr.data-qa="expertsearch-{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey2' }}-input">
              </div>
              <!--            CKEY 3-->
              <div class="col-xs-3"
                   *ngIf="umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey3 !==''">
                <label
                  ng-bind="label">{{ umaConfigKeys[country.code][isLegalSubject ? '2' : '1'].labelCkey3}} </label>
                <input type="text" class="form-control"
                       name="{{country.code + isLegalSubject ? '-G' : ''  +  '-Ckey3' }}"
                       formControlName="{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey3' }}"
                       attr.data-qa="expertsearch-{{country.code + (isLegalSubject ? '-G' : '')  +  '-Ckey3' }}-input">
              </div>
            </div>
          </div>
        </div>
        <!--      EXPERT TYPE (TIPO FIDUCIARIO) -->
        <div class="col-xs-3">
          <label ng-bind="label">{{ '_CLAIMS_._EXPERT_TYPE' | translate }}</label>
          <select rgiRxNativeSelect class="core-select form-control" id="expert-search-expertType"
                  name="experts-search-expertType" formControlName="expertType"
                  attr.data-qa="experts-search-expertType-input">
            <option value=""></option>
            <option *ngFor="let type of this.formConfig.expertTypes"
                    [value]="type.idObject">{{type.description}}</option>
          </select>
        </div>
        <div class="col-xs-12">
          <span *ngIf="showError" class="col-sm-12 content-error validate-error" attr.data-qa="expert-search-error">
          <span class="rgifont rgi-exclamation-triangle"></span>
          <span>{{ '_CLAIMS_._MESSAGE_._VALUE_AT_LEAST_ONE_FIELD_BETWEEN_NAME_SURNAME_BUSINESS_NAME_CONTRACTOR_FISCAL_CODE_VAT_CODE_CODE_INDEPENDENT_EXPERT' | translate }}</span>
      </span>
          <span *ngIf="showNoExpertsFound" class="col-sm-12 content-error validate-error"
                attr.data-qa="expert-search-not-found">
          <span class="rgifont rgi-exclamation-triangle"></span>
          <span>{{ '_CLAIMS_._MESSAGE_._NO_RESULT' | translate }}</span>
      </span>
          <span *ngIf="!showError && !showNoExpertsFound"><br/>&nbsp;</span>
        </div>
        <div class="col-xs-12"
             style="margin-top:1em; margin-bottom: 2vh; display:flex; justify-content: space-between">
          <button class="rgi-ui-btn rgi-ui-btn-primary" style="width: 49.2%; justify-self: center"
                  (click)="onEmpty()">{{ '_CLAIMS_._BUTTONS_._EMPTY' | translate }}</button>
          <button class="rgi-ui-btn rgi-ui-btn-primary" style="width: 49.2%; justify-self: center"
                  (click)="onFind()">{{ '_CLAIMS_._BUTTONS_._FIND' | translate }}</button>
        </div>
      </div>
    </form>

    <!-- search results displayed here  (onAction)="onActionClick($event)"-->
    <rgi-rx-datatable *ngIf="expertLinkedSearchList && expertLinkedSearchList.length>0" rgi-rx-qa="my-table"
                      qa-ctx="my-app-context" style="display: inline-block"
                      [data]="this.expertLinkedSearchList" [schema]="schema"
                      (select)="onSelectChange($event)"
                      [selectable]="true" [pageOptions]="[5]">
    </rgi-rx-datatable>

    <!--SAVE BUTTON-->
    <div class="col-xs-12" *ngIf="selections.size>0" style="margin-top: 2vh; margin-bottom: 2vh;">

      <button style="width: 100%" rgi-rx-button (click)="onSaveNewLinkedExpert()" color="primary"
              [disabled]="!selections.size">{{ '_CLAIMS_._BUTTONS_._SAVE' | translate }}</button>

    </div>
  </rgi-rx-panel>
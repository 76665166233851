import {Inject, Injectable} from '@angular/core';
import {Province} from './models/domain-models/province';
import {BehaviorSubject, of} from 'rxjs';
import {City} from './models/domain-models/city';
import {HttpClient, HttpParams} from '@angular/common/http';
import {MicHttpService} from './http/mic-http.service';

@Injectable({
  providedIn: 'root'
})
export class GISService {

  private baseApiUrl;
  private baseApiUrlV2;
  private commonEndpoint;
  protected httpClient: HttpClient;
  private provinces = new Array<Province>();
  private cities = new BehaviorSubject<Array<City>>(new Array<City>());

  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any
  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.baseApiUrlV2 = this.baseApiUrl + '/v2';
    this.commonEndpoint = this.baseApiUrlV2 + '/common';
    this.httpClient = this.micHttpClient.getHttpClient();
  }

  getProvinces() {
    return this.provinces;
  }

  setProvinces(provinces) {
    this.provinces = provinces;
  }

  getProvinceName(idProvince) {
    if (this.provinces) {
      const prov = this.provinces.find((province) => {
          return Number(province.id) === Number(idProvince);
        }
      );
      if (prov) {
        return prov.description;
      }
    }
    return null;
  }

  getCities() {
    return this.cities.asObservable();
  }

  setCities(cities) {
    this.cities.next(cities);
  }

  getCityName(cityIstatCode) {
    const city = this.getMostRecentCities().find(cityElement => {
        return Number(cityElement.istatCode) === Number(cityIstatCode);
      }
    );
    if (city) {
      return city.cityName;
    }
    return null;
  }

  getMostRecentCities() {
    return this.cities.getValue();
  }

  retrieveCities(province: string, allZipCodes?: boolean) {
    if (province) {
      let httpParams: HttpParams = new HttpParams();
      httpParams = httpParams.set('prov', province);
      httpParams = httpParams.set('allZipCodes', allZipCodes ? 'yes' : 'no');
      return this.httpClient.get<Array<City>>(this.commonEndpoint + '/gis' + '/cities', {params: httpParams});
    } else {
      return of(new Array<City>());
    }
  }

  retrieveProvinces() {
    return this.httpClient.get<Array<Province>>(this.commonEndpoint + '/gis' + '/provinces');
  }

}

import {AdditionalLabel} from '../proposal-models/additional-label';
import {PcSection} from './pc-section';

export class PcInsuredAsset {
  additionalLabels?: AdditionalLabel[];
  key?: string;
  asset?: Asset;
  name?: string;
  extra?: string;
  readonly?: boolean;
  sections?: PcSection[];
  messages?: Message[];
  extentions?: Extention[];
  packages: Package[];
  sync: boolean;

  constructor() {
  }
}

interface Asset {
  additionalLabels?: AdditionalLabel[];
  code?: string;
  description?: string;
}

interface Property {
  additionalLabels?: AdditionalLabel[];
  key?: string;
  value?: string;
}

interface Message {
  additionalLabels?: AdditionalLabel[];
  code?: string;
  description?: string;
  title?: string;
  type?: string;
  codetype?: string;
}

export interface Extention {
  additionalLabels?: AdditionalLabel[];
  key?: string;
  value?: string;
}

export class Package {
  code ?: string;
  shortDescription?: string;
  description?: string;
  extendedDescription?: string;
  enabled?: boolean;
  selected?: boolean;
  premium?: any;
  totalPremium?: any;
  units?: any;
}

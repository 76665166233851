import { AfterViewChecked, Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewEncapsulation } from '@angular/core';
import { JS_EVENT, PV_TOKEN } from '../../../models/consts/lpc-consts';
import { CurrencyCacheService, LpcCurrencyCache } from '../../../services/currency-cache.service';

@Component({
  selector: 'lpc-loan-data-detail',
  templateUrl: './loan-data-detail.component.html',
  styleUrls: ['../life-movement-detail/life-movement-detail.component.scss',
  './loan-data-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoanDataDetailComponent implements OnInit, AfterViewChecked {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  @Input() id: string;
  @Input() data: any;
  @Output() eventPropagation = new EventEmitter<any>();

  constructor(
    @Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
      this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit() {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

  back() {
    this.eventPropagation.emit(JS_EVENT.BACK_TO_LIFE_DETAIL);
  }

  ngAfterViewChecked() {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

}

import { PV_ERROR_SEVERITY_VALUE } from './../../models/postsales-operations-response.model';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import {AnagService} from '../../services/anag.service';
import {ChangeDetectorRef, Component, Inject, Optional, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {InputFieldDefinition, PaymentTypeDefinition, Role, RoleDefinition} from '../../models/postsales-operations-response.model';
import {PostsalesOperationsService} from '../../services/postsales-operations.service';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import {ComponentWithAnagModal} from '../../interfaces/component-with-anag-modal';
import {AnagSubject, PARTY_COMPLETE_KO} from '../../models/subject.model';
import {AbsOperationComponent} from '../abs-operation-component/abs-operation.component';
import {questionnaireCacheServiceInjectionToken} from '@rgi/questionnaires-manager';
import {QuestionnaireCacheService} from '@rgi/questionnaires-manager';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotifierService} from '@rgi/portal-ng-core';
import {tap} from 'rxjs/operators';
import {EMPTY, Observable} from 'rxjs';
import { PlcQuestService } from '../../services/plc-quest.service';
import { AuthService } from '../../services/auth.service';
import { SystemPropertiesService } from '../../services/system-properties.service';
import { PersonType, RoleType, Roles } from '../../models/enum/lpc-subjects.enum';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';
import { LpcRolesUtils } from '../../modules/lpc-roles-step/lpc-roles-utils';
import { ROLES, RolesStepperConfig, _ROLES } from './config/change-roles-configuration';
import { _OPERATION_CODES } from '../../modules/lpc-operations-list/model/lpc-operations-constants';
import { LpcRolesControlComponent } from '../../modules/lpc-roles-control/lpc-roles-control/lpc-roles-control.component';


@Component({
  selector: 'lpc-change-roles',
  templateUrl: './change-roles.component.html',
  styleUrls: ['./change-roles.component.css'],
  providers: [
    // TODO: check if there is a better way
    // @ts-ignore
    questionnaireCacheServiceInjectionToken
  ]
})
export class ChangeRolesComponent extends AbsOperationComponent implements ComponentWithAnagModal {
  @ViewChild('policyRoles') policyRoles: LpcRolesControlComponent;

  public readonly ROLES_STEP: RolesStepperConfig = ROLES;

  protected operationDataKey = ROLES.ROLES.id;

  public roleCodeToAdd: RoleType;
  public subRoleCodeToAdd: RoleType;
  public subjectToAdd: string;

  public admittedRoles: RoleDefinition[] = [];
  public roles: Role[] = [];
  public changeReasons: InputFieldDefinition[];
  public initialHolderId: string;

  public paymentTypes: PaymentTypeDefinition[] = [];

  /**
   * @description
   * ASMC-9140 - indica che nel flusso è stato cambiato il contraente
   * in modifica autorizzazione il contraente è diverso da quello iniziale
   * ma è stato cambiato prima di essere mandato in autorizzazione
   * holderIsChanged = true & isHolderChangedInThisFlow = false
   */
  public isHolderChangedInThisFlow = false;


  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected anag: AnagService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected systemPropertiesService: SystemPropertiesService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  public getRoleCode(role): string {
    if (role === _ROLES._HOLDER) {
      return Roles.CONTRACTOR;
    } else if (role === _ROLES._EFFECTIVE_HOLDER) {
      return Roles.EFFECTIVEHOLDER;
    } else {
      return null;
    }
  }

  enableRoleCheckbox(roleCode: string): boolean {
    const holder = this.holderRole;
    return !!holder && holder.adult && holder.personType === PersonType.PHYSICAL &&
          LpcRolesUtils.enableCheckbox(this.operations, LpcRolesUtils.getRoleCode(roleCode));
  }

  get holderRole(): Role {
    return !!this.roles ? this.roles.find(role => role.role === Roles.CONTRACTOR) : null;
  }

  get effectiveHolderName(): string {
    if (this.roles.length > 0) {
      const role: Role = this.roles.find(element => Roles.EFFECTIVEHOLDER === element.role);
      return role ? role.name : EMPTY_STR;
    }
  }

  get isViewEffectiveHolder(): boolean {
    const role: Role[] = this.roles.filter(element => Roles.EFFECTIVEHOLDER === element.role);
    return role.length > 0 ? true : false;
  }

  get thirdPayerName(): string {
    if (this.roles.length > 0) {
      const role: Role = this.roles.find(element => Roles.THIRDPAYER === element.role);
      return role ? role.name : EMPTY_STR;
    }
  }
  get isViewThirdPayer(): boolean {
    // il contraente è cambiato rispetto a quello iniziale?
    const role: Role[] = this.roles.filter(element => Roles.THIRDPAYER === element.role);
    return role.length > 0 ? true : false;
  }

  get isInitialHolderChanged() {
    // il contraente è cambiato rispetto a quello iniziale?
    const holder = this.getRolesDataByCodes(this.getRoleCode(_ROLES._HOLDER));
    return (holder.length === 1 && holder[0].id !== this.initialHolderId);
  }

  get disableContractor(): boolean {
    // disabilito se sono in una variazione di figure anagrafiche
    return this.session.operation === _OPERATION_CODES.CHANGEROLES;
  }

  get disableAllRoles(): boolean {
    // disabilito se sono in cambio contraenza e non ho ancora cambiato il contraente
    // al momento sempre a false, in seguito verrà gestita con un proprietà
    return false;
  }

  get isHolderPresent(): boolean {
    return this.getRolesDataByCodes(this.getRoleCode(_ROLES._HOLDER)).length === 1;
  }

  get selectedRoles(): string {
    if (this.roles.length > 0) {
      const role: Role = this.roles.find(element => Roles.CONTRACTOR === element.role);
      return role ? role.name : EMPTY_STR;
    }
  }

  get VariationLabel(): string {
    if (!!this.session.subCauseCode) {
      return this.changeReasons.find(element => this.session.subCauseCode === element.code).label;
    }
  }

  get viewStepPayment(): boolean {
    return this.isInitialHolderChanged && this.paymentTypes != null && this.paymentTypes.length > 0;
  }


  ngOnInit() {
    this.initializeSession();

    this.$subscriptions.push(
      this.createDraft().subscribe(result => {
        this.admittedRoles = (result.definitions.admittedRoles as RoleDefinition[])
          .sort((a, b) => {
            return Number(a.position) - Number(b.position);
          });
        this.roles = result.data.operationData.data.roles;
        this.changeReasons = (result.definitions.changeReason as InputFieldDefinition[]);
        this.initialHolderId = result.data.contractorId;
        this.formGroup
          .get(ROLES.ROLES.formName)
          .setValue(
              this.roles,
              {emitEvent: false}
            );
        this.setPaymentFromResult(result);
        if (!!result.data.subCauseCode) {
          this.session.subCauseCode = result.data.subCauseCode;
        }
      })
    );
  }

  updateDraft(step: string, reload?: boolean, opDataType?: string): Observable<any> {
    return super.updateDraft(step, reload, opDataType).pipe(
      tap(result => {
        if (!!result.definitions.paymentTypes) {
          this.paymentTypes = (result.definitions.paymentTypes as PaymentTypeDefinition[]).reverse();
        } else {
          this.formGroup.get(ROLES.PAYMENT.formName).setValue(null, { emitEvent: false });
        }
      })
    );
  }

  private setPaymentFromResult(result) {
    if (!!result.data.operationData.data) {
      this.formGroup.get(ROLES.PAYMENT.formName).setValue(result.data.operationData.data.payment, { emitEvent: false });
    } else {
      this.formGroup.get(ROLES.PAYMENT.formName).setValue(null, { emitEvent: false });
    }
  }

  getRolesDataByCodes(...codes: string[]): Role[] {
    return this.roles.filter(el => codes.includes(el.role));
  }

  getExcludedRolesDataByCodes(...codes: string[]): Role[] {
    return this.roles.filter(el => !codes.includes(el.role));
  }

  getDefinitionsByCodes(...codes: string[]): RoleDefinition[] {
    return this.admittedRoles.filter(el => codes.includes(el.code));
  }

  getExcludedDefinitionsByCodes(...codes: string[]): RoleDefinition[] {
    return this.admittedRoles.filter(el => !codes.includes(el.code));
  }

  protected getFormGroup() {
    return new UntypedFormGroup({
      [ROLES.DATE.formName]: new UntypedFormControl(),
      [ROLES.ROLES.formName]: new UntypedFormControl(),
      [ROLES.PAYMENT.formName]: new UntypedFormControl(),
      [ROLES.NOTES.formName]: new UntypedFormControl(), // Text-area note
      [ROLES.ROLES.formChildren.presetValueDelegato]: new UntypedFormControl(),
      [ROLES.ROLES.formChildren.presetValueTutoreLegale]: new UntypedFormControl(),
      [ROLES.ROLES.formChildren.presetValueTerzoPagatore]: new UntypedFormControl()
    });
  }

  addRole(roleType: RoleType) {
    this.roleCodeToAdd = roleType;
    this.subRoleCodeToAdd = null;
    this.subjectToAdd = null;
    this.openAnagSubjectModal();
  }

  addSubRole(event: { subRoleCode: RoleType, roleCode: RoleType, subjId: string }) {
    this.roleCodeToAdd = event.roleCode;
    this.subRoleCodeToAdd = event.subRoleCode;
    this.subjectToAdd = event.subjId;
    this.openAnagSubjectModal();
  }

  openAnagSubjectModal() {
    this.anag.openSubjectModal(this);
  }

  // se sono valorizzati subRoleCodeToAdd e subjectToAdd allora si tratta di un iserimento di un sottoRuolo
  // subRoleCodeToAdd -> codice del sottoRuolo da aggiungere
  // roleCodeToAdd -> codice del ruolo padre
  // subjectToAdd -> soggetto a cui aggiungere il nuovo subj
  receiveAnagSubjectFromModal(subject: AnagSubject) {
    const subjRole = this.subRoleCodeToAdd != null && this.subjectToAdd != null ? this.subRoleCodeToAdd : this.roleCodeToAdd;

    this.$subscriptions.push(
      this.anag.checkPartyCompleted(
        Number(subject.objectId),
        Number(subject.idLatestPhotos),
        subjRole,
        Number(this.session.managementNode)
      ).pipe(
        switchMap(res => {
          if (res.result === PARTY_COMPLETE_KO) {
            const roleDescr = this.admittedRoles.find(ar => ar.code === subjRole).label;
            this.$feErrors = this.$feErrors.filter(m => m.errorId === subjRole);
            this.setCustomFeErrorsVector(
              this.ROLES_STEP.ROLES.id,
              res.outcome.map(m => `${roleDescr} ${subject.nominative}: ${m}`),
              PV_ERROR_SEVERITY_VALUE.ERROR,
              subjRole
            );
            return EMPTY;
          }
          this.pushNewSubjToRolesArray(subject);
          return this.updateDraft(this.ROLES_STEP.ROLES.id, true);
        })
      ).subscribe(el => {
        this.admittedRoles = el.definitions.admittedRoles as RoleDefinition[];
        this.roles = el.data.operationData.data.roles;
        this.formGroup.get(this.ROLES_STEP.ROLES.formName).setValue(this.roles, {emitEvent: false});
        if (this.roleCodeToAdd === Roles.CONTRACTOR && !this.subRoleCodeToAdd) {
          this.holderIsChanged();
          this.setPaymentFromResult(el);
        }
    }));
  }

  protected pushNewSubjToRolesArray(subject: AnagSubject) {
    let subjRole: Role = null;
    if (this.subRoleCodeToAdd != null && this.subjectToAdd != null) {
      // SUBROLE
      subjRole = AnagService.subjectToRole(subject, this.subRoleCodeToAdd);
      this.roles.forEach((r) => {
        if (r.id === this.subjectToAdd || r.role === this.roleCodeToAdd) {
          if (!r.linkedSubjectsRoles) {
            r.linkedSubjectsRoles = [];
          }
          r.linkedSubjectsRoles.push(subjRole);
        }
      });
    } else {
      subjRole = AnagService.subjectToRole(subject, this.roleCodeToAdd);
      this.roles.push(subjRole);
    }
  }

  changeSubCause(event) {
    this.session.subCauseCode = event.target.value;
    this.$subscriptions.push(this.onReload(this.ROLES_STEP.ROLES.id).subscribe());
  }

  deleteRole(event: Role) {
    this.removeFirstRoleFromTheArray(event);
    this.formGroup.get(this.ROLES_STEP.ROLES.formName).setValue(this.roles, {emitEvent: false});
    this.$subscriptions.push(
      this.updateDraft(this.ROLES_STEP.ROLES.formName, true).subscribe(
        el => {
          this.admittedRoles = el.definitions.admittedRoles as RoleDefinition[];
          if (!!this.roles.length && this.roles.map(role => role.role !== Roles.CONTRACTOR)
            .reduce((accumulator, currentValue) => accumulator && currentValue)) {
            this.roles = [];
          } else {
            this.roles = el.data.operationData.data.roles;
          }
          if (event.role === Roles.CONTRACTOR) {
            this.formGroup.get(this.ROLES_STEP.PAYMENT.formName).setValue(null);
          }
          this.formGroup
            .get(this.ROLES_STEP.ROLES.formName)
            .setValue(
              this.roles,
              {emitEvent: false}
            );
        },
        err => console.error(err)
      )
    );
  }

  private removeFirstRoleFromTheArray(event: Role) {
    // Trova l'indice del primo elemento che corrisponde ai valori di event
    const indexToRemove = this.roles
    .findIndex(item => item.role === event.role && item.id === event.id && item.percentage === event.percentage);

    // Rimuovi l'elemento se trovato
    if (indexToRemove !== -1) {
      this.roles.splice(indexToRemove, 1);
    }
  }

  deleteSubRole(event: { subRoleCode: string, subSubjId: string, roleCode: string, subjId: string }) {
    this.roles.forEach((role) => {
      if (role.role === event.roleCode) {
        if (role.linkedSubjectsRoles != null && role.linkedSubjectsRoles.length > 0) {
          role.linkedSubjectsRoles = role.linkedSubjectsRoles.filter(sr => !(sr.role === event.subRoleCode && sr.id === event.subSubjId));
        }
      }
    });
    this.$subscriptions.push(
      this.updateDraft(this.ROLES_STEP.ROLES.formName, true).subscribe(
        el => {
          this.admittedRoles = el.definitions.admittedRoles as RoleDefinition[];
          this.roles = el.data.operationData.data.roles;
          this.formGroup.get(this.ROLES_STEP.ROLES.formName).setValue(this.roles, {emitEvent: false});
        },
        err => console.error(err)
      )
    );
  }

  protected getTransformedOperationData(): any {
    if (this.viewStepPayment) {
      return {
        roles: this.formGroup.get(this.ROLES_STEP.ROLES.formName).value,
        payment: !!this.formGroup.get(this.ROLES_STEP.PAYMENT.formName) ? this.formGroup.get(this.ROLES_STEP.PAYMENT.formName).value : null
      };
    } else {
      return {
        roles: this.formGroup.get(this.ROLES_STEP.ROLES.formName).value
      };
    }
  }

  onNext(step: string = null, publish: boolean = false, context = step, isConfirmAndAccept: boolean = false) {
    if (step === this.ROLES_STEP.ROLES.formName) {
      if (this.isHolderPresent) {
        if (this.checkStepRoles()) {
          super.onNext(step, publish, context);
        } else {
          this.setFeErrors(step);
        }
      }
    } else {
      super.onNext(step, publish, context, isConfirmAndAccept);
    }
  }

  public checkStepRoles(): boolean {
    this.validateMandatoryRolesWithCheckboxEnabled();

    if (!this.isInitialHolderChanged) {
      return true;
    }

    if (!this.session.subCauseCode) {
      return false;
    }

    if (this.viewStepPayment) {
      return !!this.formGroup.get(this.ROLES_STEP.PAYMENT.formName) &&
             this.formGroup.get(this.ROLES_STEP.PAYMENT.formName).value != null &&
             this.formGroup.valid;
    }

    return true;

  }

  private validateMandatoryRolesWithCheckboxEnabled(): void {
    [Roles.DELEGATE, Roles.THIRDPAYER, Roles.LEGAL_GUARDIAN].forEach(role => {
      const childControlName = this.policyRoles.checkboxControlsName.get(role);
      const rolesForm: Role[] = this.formGroup.get(this.ROLES_STEP.ROLES.formName).value;
      const isCheckBoxEnable = LpcRolesUtils.enableCheckbox(this.operations, role);
      const roleUndefined = !rolesForm.filter(r => r.role === role).some(v => v);
      const roleDescr = this.admittedRoles.find(r => r.code === role)?.label;
      if (isCheckBoxEnable && !!childControlName && roleUndefined && !!roleDescr) {
        this.formGroup.get(this.ROLES_STEP.ROLES.formName).setErrors({
          error: this.translate.getImmediate('lpc_role_is_mandatory', { role: roleDescr })
        });
      }
    });
  }

  public holderIsChanged() {
    this.session.subCauseCode = null;
    this.isHolderChangedInThisFlow = true;
  }


  // se la checkbox del ruolo è a "SI" il soggetto inserito è uguale al contraente
  selectRoleEqualHolder(role: Role) {
    this.roles.push(role);
    this.updateDraft(this.ROLES_STEP.ROLES.formName, true).subscribe(el => {
      this.admittedRoles = el.definitions.admittedRoles as RoleDefinition[];
      this.roles = el.data.operationData.data.roles;
      this.formGroup.get(this.ROLES_STEP.ROLES.formName).setValue(this.roles, {emitEvent: false});
    });
  }

  replaceRoleWithPerc(role: Role) {
  }


}

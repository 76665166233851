import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors} from '@angular/forms';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {
  ReIssueProposalManagementState,
  ReIssueProposalManagementStateManagerService
} from '../re-issue-state-manager/re-issue-proposal-management-state-manager.service';
import {ModalService} from '@rgi/rx/ui';
import {Message} from '../../models/message';
import {of, Subject} from 'rxjs';
import {mergeMap, share, take} from 'rxjs/operators';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {AddressApiContacts} from '../../models/domain-models/address-api-contacts';
import {
  DataMeansOfPayment,
  DebMeanOfPayment, MeansOfPaymentResponse
} from '../../models/means-of-payment-response';
import {Ie11UtilityService} from '../re-issue-utility/ie-11-utility.service';
import {PaymentFrequencyContainer} from '../../models/domain-models/parameters/payment-frequency-container';

import {
  ReIssueProposalQuestionairesModalComponent
} from './re-issue-proposal-questionaires-modal/re-issue-proposal-questionaires-modal.component';
import {ReIssueQuestionnaireUtilityService} from '../re-issue-utility/re-issue-questionnaire-utility.service';
import {AnagEntityIta} from '@rgi/anag';
import {PushMessage, PushMessageHandlerService} from '@rgi/rx';
import {CreditCard} from '../../models/pc-portfolio-models/proposal-models/PaymentDataRequest';
import {AnagIssueSubject} from "../re-issue-anag/anag-issue";
import {StepperSection} from '../../models/stepper-section';
import {AnagPartyPayment} from "@rgi/anag/lib/anag-model/anag-domain/anag-party-payment";
import {
  ReIssuePortfolioPaymentFrequencyResourceService
} from "../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-payment-frequency-resource.service";
import {PcPaymentFrequency} from "../../models/pc-portfolio-models/payment-frequency-models/pc-payment-frequency";
import {
  ReIssuePortfolioContactResourceService
} from "../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-contact-resource.service";
import {PolicyContact} from "../../models/domain-models/policy-contact";



@Component({
  selector: 're-issue-proposal-management',
  templateUrl: './re-issue-proposal-management.component.html',
  styleUrls: ['./re-issue-proposal-management.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReIssueProposalManagementComponent implements OnInit {
  proposalManagementForm: UntypedFormGroup;
  paymentDebCredForm = new UntypedFormGroup({});
  state: ReIssueProposalManagementState;
  policyValidationMessages: Message[] = [];
  addressData: AddressApiContacts[] = [];
  radioButtonSelected: boolean[] = [];
  paymentOptions = [];
  creditOptions = [];
  meanOfPaymentSelected: DataMeansOfPayment;
  paymentForm = new UntypedFormGroup({});
  refundForm = new UntypedFormGroup({});
  paymentSelect = false;
  paymentRefundSelect = false;
  mainAddress: AddressApiContacts;
  arrayControl: string[] = [];
  arrayCreditControl: string[] = [];
  selectedDeb: DebMeanOfPayment;
  selectedCred: DebMeanOfPayment;
  selectedCreditDefault = false;
  closeSnackBar: string;
  operationCode: string;
  formComplete: boolean;
  promise = new Subject<any>();
  policyContactMessages: string;
  errorMessage: string;
  paymentFrequency: string;
  TAG_ERRORS = 'errors';
  TAG_FORM_ERRORS = 'formErrors';
  currentStep = StepperSection.issue;
  stepperLabels = ['RE_ISSUE.POLICY_DATA', 'RE_ISSUE.ASSETS_DATA', 'RE_ISSUE.QUOTATION', 'RE_ISSUE.SUMMARY', 'RE_ISSUE.ISSUE_STEPPER'];
  isFromExternalCard = false;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    public stateManager: ReIssueProposalManagementStateManagerService<ReIssueProposalManagementState>,
    protected customModalService: ModalService,
    private translateService: RgiRxTranslationService,
    protected ie11Utility: Ie11UtilityService,
    protected questionnaireUtilityService: ReIssueQuestionnaireUtilityService,
    public pushMessageHandler: PushMessageHandlerService,
    protected paymentFrequencyResourceService: ReIssuePortfolioPaymentFrequencyResourceService,
    protected contactService: ReIssuePortfolioContactResourceService,
    protected contractSevice: ReIssuePortfolioContactResourceService,
  ) {

    this.translateService.translate('RE_ISSUE.CLOSE').subscribe(next => {
      this.closeSnackBar = next;
    }).unsubscribe();

    this.proposalManagementForm = this.formBuilder.group(
      {
        policyNumber: [{value: '', disabled: true}],
        issueDate: [, Validators.required],
        effectiveDate: [, Validators.required]
      }
    );
    this.paymentDebCredForm = this.formBuilder.group(
      {
        meanOfPaymentDeb: [,],
        meanOfPaymentCred: [,]
      }
    );
    this.disableFormValues();
    this.updateFormValues();
  }

  disableFormValues() {
    this.proposalManagementForm.controls.effectiveDate.disable();
    this.proposalManagementForm.controls.issueDate.disable();
  }

  private updateFormValues() {
    this.promise.subscribe(() => {
      this.proposalManagementForm.controls.effectiveDate.valueChanges.subscribe(val => {
        this.stateManager.putTechnicalData(val).subscribe(st => {
          this.validateWarningMessages();

          if (val instanceof Date && (st.warnings && st.warnings.length === 0)) {
            this.proposalManagementForm.controls.effectiveDate.setErrors(null);
          } else {
            this.proposalManagementForm.controls.effectiveDate.setErrors({dateNotValid: 'Date is not Valid'});
          }

          this.stateManager.updateState$(of(st));
        });
      });
    });

  }

  ngOnInit() {
    this.stateManager.getState$().pipe(
      take(1),
      mergeMap(st => {
        if(st.isFromExternalCard){
          st.policyTechnicalData.effectiveDate = st.policyTechnicalData.effectiveDate;
          st.policyTechnicalData.issueDate = st.policyTechnicalData.issueDate;
        }
        if(st.policyTechnicalData.paymentFrequencyContainer == null){
          this.paymentFrequencyResourceService.getPaymentFrequency(st.resourceId).subscribe(frequency =>{
            this.updatePaymentFrequencyState(st, frequency);
            this.updateContactState(st, st.contact);
          });
        }else {
            this.setPaymentFrequency(st.policyTechnicalData.paymentFrequencyContainer);
        }
        const payments = this.stateManager.postMeansOfPayment(st);
        payments.subscribe(data => {
          if (data &&
            data.meansOfPayment &&
            data.meansOfPayment.meansOfPayment &&
            data.meansOfPayment.meansOfPayment.credMeansOfPayment &&
            data.meansOfPayment.meansOfPayment.credMeansOfPayment.length === 1) {
            const fieldValue = {value: data.meansOfPayment.meansOfPayment.credMeansOfPayment[0].meanOfPayment};
            this.generateCredPaymentField(fieldValue);
            this.selectedCreditDefault = true;
          }
        });
        return payments;
      }),
      share(),
      mergeMap(st => {
        this.proposalManagementForm.controls.issueDate.setValue(
          new Date(st.policyTechnicalData.issueDate.date));
        this.proposalManagementForm.controls.effectiveDate.setValue(
          new Date(st.policyTechnicalData.effectiveDate.date));
        if (st.proposal?.contractNumber.policy) {
          this.proposalManagementForm.controls.policyNumber.setValue(st.proposal.contractNumber.policy);
        }
        this.isFromExternalCard = st.isFromExternalCard;
        this.stateManager.updateState$(of(st));
        this.promise.next();
        return of(st);
      })
    ).subscribe(
      state1 => {
        this.actionsPostInit();
        if (state1.isBuyProposal) {
          this.stateManager.getQuestionaires$(state1).subscribe(quest => {
            const {
              modal,
              component
            } = this.customModalService.openComponent(ReIssueProposalQuestionairesModalComponent);
            component.questionnaireFlat = this.questionnaireUtilityService.convertPcQuestionnaireToQuestionnaireFlatI(quest[0]);
            component.modalClose.subscribe(val => {
            });
            modal.enableClickBackground = false;
          });
        }
      }
    );
  }
  updateContactState(state: ReIssueProposalManagementState, contact: PolicyContact){
    state.contact = contact;
    this.stateManager.updateState$(of(state));
  }

  updatePaymentFrequencyState(state: ReIssueProposalManagementState, paymentFrequency: PcPaymentFrequency){
    state.policyTechnicalData.paymentFrequencyContainer.paymentFrequencyList.forEach(element => {
      element.selected = false;
      if (element.code === paymentFrequency.code) {
        element.selected = true;
      }
    });
  }

  actionsPostInit() {
  }


  setPaymentFrequency(paymentFrequencyContainer: PaymentFrequencyContainer) {
    if (paymentFrequencyContainer != null) {
      paymentFrequencyContainer.paymentFrequencyList.forEach(val => {
        if (val.selected) {
          this.paymentFrequency = val.code;
        }
      });
    }
  }

  private addressFromSubject(subj) {
    const address: AddressApiContacts = new AddressApiContacts();
    // address.country = subj.residence.country ?
    //   new GenericEntity(null, subj.residence.country.codice, subj.residence.country.descrizione) : null;
    address.countryCode = subj.residence.country ? subj.residence.country.codice : null;
    address.adminLevel1 = subj.residence.adminLevel1;
    address.adminLevel2 = subj.residence.adminLevel2;
    address.adminLevel3 = subj.residence.adminLevel3;
    address.adminLevel2Short = subj.residence.adminLevel2Short;
    address.cap = subj.residence.cap;
    address.toponym = subj.residence.toponym ?
      new AnagEntityIta(subj.residence.toponym.codice, subj.residence.toponym.descrizione) : null;
    address.placeAddress = subj.residence.placeAddress;
    address.number = subj.residence.number;
    address.formatAddress = subj.residence.formatAddress;
    return address;
  }

  validateWarningMessages() {
    this.pushMessageHandler.clearTag(this.TAG_ERRORS);
    const st = this.stateManager.getCurrentState();
    if (st.warnings && st.warnings.length) {
      st.warnings.forEach(war => {
        const errorMsg = new PushMessage();
        errorMsg.tag = this.TAG_ERRORS;
        errorMsg.status = 'danger';
        errorMsg.dismissible = false;
        errorMsg.options = {icon: 'rgi-ui-icon-alert'};
        errorMsg.content = war.description;
        this.pushMessageHandler.notify(errorMsg);
      });
    }
  }

  selectedPayment() {
    console.log('Payment selected');

    const st = this.stateManager.getCurrentState();
    this.paymentOptions = [];
    this.selectedDeb = this.paymentDebCredForm.controls.meanOfPaymentDeb.value;
    if (this.selectedDeb.code !== 'DDO') {
      if (this.paymentOptions) {
        this.creditOptions.forEach(el => {
          console.log('elemento::', el);
          this.refundForm.controls[el.name].enable();
        });
      }
    } else {
      this.paymentOptions.forEach(el => {
        console.log('elemento::', el);
        if (el.readOnly) {
          this.refundForm.get(el.name).disable();
          this.refundForm.markAllAsTouched();
        } else {
          this.refundForm.controls[el.name].enable();
        }
      });
    }
    if (st.meansOfPayment && st.meansOfPayment.meansOfPayment &&
      st.meansOfPayment.meansOfPayment.debMeansOfPayment) {
      st.meansOfPayment.meansOfPayment.debMeansOfPayment.forEach(data => {
        if (data.meanOfPayment.code === this.selectedDeb.code) {
          this.meanOfPaymentSelected = data;
          if (data.paymentFields) {
            this.paymentOptions = data.paymentFields;
            if (this.arrayControl.length !== 0) {
              this.arrayControl.forEach(s => {
                this.paymentForm.removeControl(s);
              });
            }

            const partyPaymentData = this.findPaymentMethod(st.subject.payments, data.meanOfPayment.identification);

            this.arrayControl = [];

            this.paymentOptions.forEach(paymentField => {
              this.arrayControl.push(paymentField.name.toUpperCase());
              this.paymentForm.addControl(paymentField.name.toUpperCase(),
                new UntypedFormControl('', paymentField.mandatory ? Validators.required : null));
              if (paymentField.name.toUpperCase() === 'CABI') {
                this.setDefaultABI();
                // this.paymentForm.controls.CABI.disable();
              }
              if (paymentField.name.toUpperCase() === 'CCAB') {
                this.paymentForm.controls.CCAB.setValidators([Validators.pattern(/^\S*$/),
                  Validators.maxLength(5), Validators.required]);
              }
              if (paymentField.name.toUpperCase() === 'CACCOUNT') {
                this.paymentForm.controls.CACCOUNT.setValidators([Validators.pattern(/^\S*$/),
                  Validators.maxLength(12), Validators.required]);
              }

              if (paymentField.name.toUpperCase() === 'CIBAN') {
                // this.paymentForm.controls.CIBAN.disable();
                if (partyPaymentData?.ccIBAN) {
                  this.paymentForm.controls.CIBAN.setValue(partyPaymentData.ccIBAN);
                }
                this.paymentForm.controls.CIBAN.setValidators([Validators.pattern(/^\S*$/), Validators.minLength(26),
                  Validators.maxLength(28), Validators.required]);
              }
              if (paymentField.name.toUpperCase() === 'IDCREDITCARD') {
                if (partyPaymentData?.cartaDiCreditoTipo) {
                  this.paymentForm.controls.IDCREDITCARD.setValue(this.findCreditCardOption(data.creditCards, partyPaymentData.cartaDiCreditoTipo.codice));
                }
              }
              if (paymentField.name.toUpperCase() === 'CHOLDER') {
                // this.paymentForm.controls.CHOLDER.disable();
                if (partyPaymentData?.holder) {
                  this.paymentForm.controls.CHOLDER.setValue(partyPaymentData.holder);
                }
                this.paymentForm.controls.CHOLDER.setValidators([Validators.pattern(/^[a-zA-Z ]*$/)]);
              }
              if (paymentField.name.toUpperCase() === 'DCARDEXPIRE') {
                this.paymentForm.controls.DCARDEXPIRE.setValidators([Validators.pattern(/^(0[1-9]|1[0-2])\/[0-9]{4}$/)]);
                if (partyPaymentData?.cartaDiCreditoScadenza) {
                  this.paymentForm.controls.DCARDEXPIRE.setValue(this.formatDate(partyPaymentData.cartaDiCreditoScadenza));
                }
                this.paymentForm.controls.DCARDEXPIRE.setValidators([Validators.pattern(/^(0[1-9]|1[0-2])\/[0-9]{4}$/)]);
              }
              if (paymentField.name.toUpperCase() === 'CRUM') {
                if (paymentField.readOnly) {
                  this.paymentForm.controls.CRUM.disable();
                }
              }
            });
          }
          this.setPaymentSelect();
        }
      });
    }
    if (!this.selectedDeb) {
      this.arrayControl.forEach(s => {
        this.paymentForm.removeControl(s);
      });
    }
  }

  setDefaultABI() {
    this.paymentForm.controls.CABI.setValue('02008');
  }

  setPaymentSelect() {
    if (this.paymentForm.controls) {
      this.paymentSelect = true;
    } else {
      this.paymentSelect = false;
    }

  }

  updatePolicyAddress(event) {
    this.stateManager.updateContact(event);
  }

  formsValid() {
    this.pushMessageHandler.clearTag(this.TAG_FORM_ERRORS);
    this.formComplete = !this.paymentForm.invalid &&
      !this.refundForm.invalid &&
      !this.proposalManagementForm.invalid;
    if (!this.formComplete) {
      const errorMsg = new PushMessage();
      errorMsg.tag = this.TAG_FORM_ERRORS;
      errorMsg.status = 'danger';
      errorMsg.dismissible = false;
      errorMsg.options = {icon: 'rgi-ui-icon-alert'};
      errorMsg.content = 'RE_ISSUE.FIELDS_MARKED_IN_RED';
      this.pushMessageHandler.notify(errorMsg);
    }
  }

  checkPolicyContactValidity(policyContactErrorMessage: string) {
    const tag = 'policyContactErrors';
    this.pushMessageHandler.clearTag(tag);
    this.policyContactMessages = policyContactErrorMessage;
    if (policyContactErrorMessage) {
      const msg: PushMessage = new PushMessage();
      const opts = {icon: 'rgi-ui-icon-alert'};
      msg.tag = tag;
      msg.status = 'danger';
      msg.content = policyContactErrorMessage;
      msg.dismissible = false;
      msg.options = opts;
      this.pushMessageHandler.notify(msg);
    }
  }

  verifyBankAccountDetails() {
    this.operationCode = '04';
    this.validateBankAccountDetails().subscribe();
  }

  validateBankAccountDetails() {
    let caccount = '';
    let abi = '';
    let cab = '';
    let iban = '';
    switch (this.selectedDeb.code) {
      case 'DDO': {
        caccount = this.paymentForm.controls.CACCOUNT.value;
        abi = this.paymentForm.controls.CABI.value;
        cab = this.paymentForm.controls.CCAB.value;
        break;
      }
      case 'ADAUT': {
        // TODO: implement call
        break;
      }
      case 'VOUCH': {
        caccount = this.paymentForm.controls.CCHECKNUMBER.value;
        break;
      }
      case 'CFLEX': {
        caccount = this.paymentForm.controls.CCHECKNUMBER.value;
        break;
      }
      case 'GCARD': {
        caccount = this.paymentForm.controls.CCHECKNUMBER.value;
        break;
      }
    }
    if (this.operationCode === '02') {
      iban = this.refundForm.controls.CIBAN.value;
    }

    return null;
  }


  selectedCredPayment() {
    this.generateCredPaymentField(null);
  }

  generateCredPaymentField(fieldValue) {
    const st = this.stateManager.getCurrentState();
    this.creditOptions = [];

    this.selectedCred = fieldValue ? fieldValue.value : this.paymentDebCredForm.controls.meanOfPaymentCred.value;
    if (st.meansOfPayment && st.meansOfPayment.meansOfPayment
      && st.meansOfPayment.meansOfPayment.credMeansOfPayment) {
      st.meansOfPayment.meansOfPayment.credMeansOfPayment.forEach(data => {

        if (data.meanOfPayment.code === this.selectedCred.code) {
          if (data.paymentFields) {
            this.creditOptions = data.paymentFields;
            if (this.arrayCreditControl.length !== 0) {
              this.arrayCreditControl.forEach(s => {
                this.refundForm.removeControl(s);
              });
            }
            this.arrayCreditControl = [];

            this.creditOptions.forEach(paymentField => {

              this.arrayCreditControl.push(paymentField.name.toUpperCase());
              this.refundForm.addControl(paymentField.name.toUpperCase(),
                new UntypedFormControl('', paymentField.mandatory ? Validators.required : null));
              this.refundForm.controls[paymentField.name.toUpperCase()].setValue(paymentField.value);
              if (this.selectedCred && this.selectedCred.code !== 'DDO') {
                // paymentField.readOnly = false;
                this.refundForm.controls[paymentField.name.toUpperCase()].enable();
              }
              if (paymentField.name.toUpperCase() === 'DCARDEXPIRE') {
                this.refundForm.controls.DCARDEXPIRE.setValidators([Validators.pattern(/^(0[1-9]|1[0-2])\/[0-9]{4}$/)]);
              }
            });
          }
          this.paymentRefundSelect = true;
        }
      });
    }
    if (!this.selectedCred) {
      this.arrayCreditControl.forEach(s => {
        this.refundForm.removeControl(s);
      });

    }
  }

  private findCreditCardOption(creditCards: DebMeanOfPayment[], creditCardCode: string) {
    return creditCards.find(debMeanOfPayment =>
      creditCardCode === debMeanOfPayment.identification
    );
  }

  back() {
    this.stateManager.back();
  }

  goToIthStep(i){
    this.stateManager.goToIthStep(i);
  }

  issue() {
    if (this.selectedDeb) {
    this.verificaData();
    } else if (this.selectedCred) {
      this.verificaDataCred();
    }
    this.formsValid();
    if (this.formComplete) {
      return this.stateManager.issuePolicy(
        this.addressData,
        this.mainAddress,
        null,
        this.paymentForm,
        this.refundForm,
        this.selectedDeb,
        this.selectedCred,
        null
      ).pipe(take(1)).subscribe(st => {
        if (st && st.pcPolicy) {
          if (!(st.warnings && st.warnings.length)) {
            this.stateManager.goToNextPage();
          }
        }
      });
    }
  }

  getOptName(name: string) {
    return name.toUpperCase();
  }
  verificaData() {
      const currentYear = this.proposalManagementForm.controls.effectiveDate.value.getFullYear();
      const inputYear = parseInt(this.paymentForm.controls.DCARDEXPIRE.value.split('/')[1], 10);
      const currentMonth = this.proposalManagementForm.controls.effectiveDate.value.getMonth() + 1 ;
      const inputMonth = parseInt(this.paymentForm.controls.DCARDEXPIRE.value.split('/')[0], 10);
      if (inputYear < currentYear || inputMonth < currentMonth) {
        const errorMsg = new PushMessage();
        errorMsg.tag = this.TAG_FORM_ERRORS;
        errorMsg.status = 'danger';
        errorMsg.dismissible = false;
        errorMsg.options = {icon: 'rgi-ui-icon-alert'};
        errorMsg.content = 'RE_ISSUE.DATE_CREDIT_CARD';
        this.pushMessageHandler.notify(errorMsg);
        throw new Error('RE_ISSUE.DATE_CREDIT_CARD');
      } else {
        this.pushMessageHandler.clear();
      }
  }
  verificaDataCred() {
    const currentYear = this.proposalManagementForm.controls.effectiveDate.value.getFullYear();
    const inputYearCred = parseInt(this.refundForm.controls.DCARDEXPIRE.value.split('/')[1], 10);
    const currentMonth = this.proposalManagementForm.controls.effectiveDate.value.getMonth() + 1 ;
    const inputMonthCred = parseInt(this.refundForm.controls.DCARDEXPIRE.value.split('/')[0], 10);
    if (inputYearCred < currentYear || inputMonthCred < currentMonth) {
      const errorMsg = new PushMessage();
      errorMsg.tag = this.TAG_FORM_ERRORS;
      errorMsg.status = 'danger';
      errorMsg.dismissible = false;
      errorMsg.options = {icon: 'rgi-ui-icon-alert'};
      errorMsg.content = 'RE_ISSUE.DATE_CREDIT_CARD';
      this.pushMessageHandler.notify(errorMsg);
      throw new Error('RE_ISSUE.DATE_CREDIT_CARD');
    }
  }

  private findPaymentMethod(paymentMethods: AnagPartyPayment[], paymentMethodId: string) {
    return paymentMethods?.find(value =>
      value.paymentTypeID === paymentMethodId
    );
  }

  private formatDate(date: string): string {
    if (date) {
      const rawDate = new Date(date);
      return `${(rawDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${rawDate.getUTCFullYear()}`;
    }
  }

}

import {Injectable} from '@angular/core';
import {AbstractStateManager, State, StateStoreService} from '@rgi/rx/state';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {ReIssuePortfolioBusinessService} from '../re-issue-business-services/re-issue-portfolio-business.service';
import {Observable, of} from 'rxjs';
import {RgiRxLogger} from '@rgi/rx';
import {PortfolioMovementResponse} from '../../models/api-models/portfolio-movement-response';
import {PortfolioMovement} from '../../models/api-models/portfolio-movement';
import {map} from 'rxjs/operators';
import {ReIssueReprintDocumentsNavigation} from './re-issue-reprint-documents-state-manager.service';
import {Policy} from '../../models/policyList';
import {Contract} from '../../models/api-models/ptfdanni-resources';

export enum ReprintDocumentsStepperSection {
  selection = 1,
  documents = 2
}

export const reprintDocumentStepperLabels = ['Movements selection', 'Documents to print list'];

export class ReIssueReprintMovementsNavigation {
  policy: Policy;
  titles: any[];
  contract: Contract;
  bancaParameters: any;
}

export class ReIssueReprintMovementsState extends State {
  movimentList: PortfolioMovement[];
  policyNumber: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReIssueReprintMovementsStateManagerService<T extends ReIssueReprintMovementsState> extends AbstractStateManager<T> {
  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected routingService: RoutingService,
    protected logger: RgiRxLogger,
    protected portfolioBusinessService: ReIssuePortfolioBusinessService
  ) {
    super(activeRoute, stateStoreService);
  }

  getPolicyMovementList$(): Observable<PortfolioMovementResponse> {
    const data: ReIssueReprintMovementsNavigation = this.activeRoute.getRouteData();
    if (data.policy.policyNumber) {
      return this.portfolioBusinessService.getPolicyMovementList$(data.policy.policyNumber).pipe(map(resp => {
        let st: T = this.getCurrentState();
        if (!st) {
          st = (new ReIssueReprintMovementsState(this.activeRoute.key)) as T;
        }
        this.logger.debug('getPolicyMovementList$ - state', st);
        this.logger.debug('getPolicyMovementList$ - resp', resp);
        st.policyNumber = data.policy.policyNumber;
        if (resp && resp.movimentoList) {
          st.movimentList = resp.movimentoList;
        }
        this.updateState$(of(st));
        return resp;
      }));
    } else {
      of(null);
    }
  }

  reprintMovement(movement: PortfolioMovement) {
    const nav: ReIssueReprintDocumentsNavigation = {
      policyNumber: this.getCurrentState().policyNumber,
      movement
    };

    this.logger.debug('reprintMovement() - ', movement);

    this.routingService.navigate('re-issue-reprint-documents', nav, this.activeRoute.id);
  }

}

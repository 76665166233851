import {TableRowSchema, TableSchema, TableSchemaFormatSingle, TableTextRowSchema} from '@rgi/rx/ui';

export const AUTHORIZATIONS_LIST_TABLE_SCHEMA: TableSchema = {
    rows: [{
            name: 'authCode',
            title: '_AUTH_._CODE'
        }, {
            name: 'authRequestType',
            title: '_AUTH_._REQUEST_TYPE'
        }, {
            name: 'authContractType',
            title: '_AUTH_._CONTRACT_TYPE'
        }, {
            name: 'authOperation',
            title: '_AUTH_._OPERATION'
        }, {
            name: 'authContractNumber',
            title: '_AUTH_._CONTRACT_NUMBER',
        }, {
            name: 'authDescription',
            title: '_AUTH_._DESCRIPTION',
        }, {
            name: 'authReference',
            title: '_AUTH_._REFERENCE',
        }, {
            name: 'authLastEvent',
            title: '_AUTH_._LAST_EVENT',
        }, {
            name: 'authRequestDate',
            title: '_AUTH_._REQUEST_DATE',
            format: {
                pipe: 'date',
                args: ['shortDate']
            }
        }, {
            name: 'authNodeCode',
            title: '_AUTH_._NODE_CODE',
        }, {
            name: 'action',
            title: '',
            actions: [{
                name: 'OPEN',
                styleClass: 'rgi-ui-btn-outline rgi-ui-icon-right-arrow'
            }]
        }
    ],
    header: [
        'authCode',
        'authRequestType',
        'authContractType',
        'authOperation',
        'authContractNumber',
        'authDescription',
        'authReference',
        'authLastEvent',
        'authRequestDate',
        'authNodeCode',
        'action']
};

export const AUTHORIZATIONS_LIST_TABLE_SCHEMA_SUB: TableSchema = {
    rows: [{
        name: 'authRequestType',
        title: '_AUTH_._REQUEST_TYPE',
    }, {
        name: 'authContractNumber',
        title: '_AUTH_._CONTRACT_NUMBER',
        styleClass: 'contract-num-sub'
    }, {
        name: 'authDescription',
        title: '_AUTH_._DESCRIPTION',
    }, {
        name: 'authStatus',
        title: '_AUTH_._REQUEST_STATUS',
    }, {
        name: 'action',
        title: '',
        actions: [{
            name: 'OPEN',
            styleClass: 'subcard rgi-ui-btn-outline rgi-ui-icon-right-arrow'
        }]
    }
    ],
    header: [
        'authRequestType',
        'authContractNumber',
        'authDescription',
        'authStatus',
        'action']
};


<div *ngIf="isBeneficiaryUnitVisibility">
  <div class="core-header-icon re-issue-card-styles">
    <span class="rgi-re-header-icon rgifont rgi-value nfm_font_size"></span>
    <span class="header-text" translate>RE_ISSUE.BENEFICIARIES</span>&nbsp;
    <span class="pull-right header-text"> </span>
  </div>

  <form [formGroup]="beneficiaryForm">
    <form formArrayName="beneficiary" class="rgi-ui-form-group row block">
      <div class="col-sm-6 col-xs-12" *ngFor="let beneficiariesUnit of listBeneficiariesUnit; let i = index">
        <form [formGroupName]="i">
          <div class="container-fluid" attr.data-qa="{{beneficiariesUnit.section + ' ' + beneficiariesUnit.unit}}">
            <div class="row">
              <div class="core-header-icon col-sm-12 col-xs-12 beneficiaryTitle">
                <span class="header-text beneficiaryTitle"
                      translate>{{beneficiariesUnit.section + ' ' + beneficiariesUnit.unit}}</span>&nbsp;
                <span class="pull-right header-text"> </span>
              </div>
            </div>
            <div class="row">
              <rgi-rx-form-field>
                <label rgiRxLabel><span translate>RE_ISSUE.BENEFICIARY_TYPE</span>
                </label>
                <select rgiRxNativeSelect (change)="selectedBeneficiaryType(i)" formControlName="beneficiaryType"
                        data-qa="beneficiaryType">
                  <option value=""></option>
                  <option *ngFor="let beneficiary of beneficiariesUnit.beneficiary; let h = index"
                          [value]="beneficiary.code">{{beneficiary.description}}</option>
                </select>
              </rgi-rx-form-field>
              <form formArrayName="beneficiaryParties" class="rgi-ui-form-group row"
                    *ngIf="beneficiaryForm.controls.beneficiary.value[i].beneficiaryVisibility">
                <div class="col-sm-12 col-xs-12 beneficiary"
                     *ngFor="let party of getBeneficiaryParties(i).controls; let j = index">
                  <form [formGroupName]="j">
                    <div class="containerBeneficiary" attr.data-qa="beneficiaryParty_{{j}}">
                      <rgi-rx-drop-container style="width: 100%;" class="beneficiary"
                                             label="{{'RE_ISSUE.BENEFICIARY' | translate}}"
                                             formControlName="beneficiaryParty" viewField="nominative"
                                             data-qa="beneficiary" [select]="'searchOrCreateSubject'"
                                             (onValueChange)="selectedBeneficiarySubject($event, i, j)"
                                             (onRemove)="removeBeneficiarySubject(i, j)">
                      </rgi-rx-drop-container>
                      <div style="width: 100%;" class="beneficiary" *ngIf="party.value.percentageVisibility">
                        <rgi-rx-form-field>
                          <input rgiRxInput formControlName="percentageBeneficiary" data-qa="beneficiaryPerc"
                                 maxlength="3" placeholder="{{'RE_ISSUE.PERCENTAGE' | translate}}">
                          <label rgiRxLabel>&nbsp;</label>
                        </rgi-rx-form-field>
                      </div>
                    </div>
                  </form>
                </div>
              </form>
            </div>
            <div class="rgi-ui-grid">
              <div class="rgi-ui-col">
                <rgi-rx-snackbar tag="{{beneficiariesUnit.unitCode}}"></rgi-rx-snackbar>
              </div>
            </div>
          </div>
        </form>
      </div>
    </form>
  </form>
</div>

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AddressType, ZipCode } from '../../dsc-shared-model/dsc-model-address';

@Injectable({
  providedIn: 'root'
})
export class DscAddressDataService {

  private baseApiUrl: string;
  private baseApiUrlV2: string;
  private urlservice = '/claims';
  private urlServiceOccurrenceData = '/claims/occurrencedata';
  private urlServiceLoadCatastropheEvents = '/claims/catastropheEvents';
  private urlServiceLoadOpeningModes = '/claims/openingModes';
  private urlServiceNormalize = '/anag/normalize';

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.baseApiUrlV2 = this.baseApiUrl + '/v2';
  }

  saveOccurrenceDataInCache(input: any) {
    return this.httpClient.post(this.baseApiUrlV2 + this.urlServiceOccurrenceData, input);
  }

  catastropheEvents(rami: Array<string>, lossDate: string) {
    const queryString = '?lossDate=' + lossDate;
    return this.httpClient.post(this.baseApiUrlV2 + this.urlServiceLoadCatastropheEvents + queryString, rami);
  }

  openingModes() {
    return this.httpClient.get(this.baseApiUrlV2 + this.urlServiceLoadOpeningModes);
  }

  normalize(address: AddressType) {
    return this.httpClient.post(this.baseApiUrl + this.urlServiceNormalize, address);
  }

  getCitiesFromZipCode(zipCode: string): Observable<ZipCode[]> {
    if (zipCode.length === 5 &&  !isNaN(parseInt(zipCode, 10))) {
      return this.httpClient.get<ZipCode[]>(this.baseApiUrlV2 + this.urlservice + '/extractFromZipCode?zipCode=' + zipCode);
    }
  }

}

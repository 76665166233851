import {IT} from './it';
import {EN} from './en';
import { RgiRxTranslations, RgiRxi18nModuleLoadType, Serialized18n } from '@rgi/rx/i18n';

export function portalNgcoreI18nLoaderIT(): RgiRxi18nModuleLoadType {
  return new Promise<Serialized18n>((resolve) => resolve(IT));
}

export function portalNgCoreI18nLoaderEN(): RgiRxi18nModuleLoadType {
  return new Promise<Serialized18n>((resolve) => resolve(EN));
}

export const PORTAL_NG_CORE_TRANSLATIONS: RgiRxTranslations = [
  {
    load: portalNgcoreI18nLoaderIT,
    locale: 'it'
  },
  {
    load: portalNgCoreI18nLoaderEN,
    locale: 'en'
  }
];

export class ExpertEntity {
  id: bigint;
  code: string;
  name: string;
  surname: string;
  fiscalCode: string;
  vatNumber: string;
  expertType: number;
  expertTypeProvider: number;
  expertTypeDescription: string;
  percCash: number;
  percVAT: number;
  percWithHolding: number;
  abi: string;
  cabi: string;
  bankAccount: string;
  maxNumAssignments: number;
  isPublicDepartment: boolean;
  isAvailableReplacementCar: boolean;
  isAvailableCarCollection: boolean;
  carBrandDescription: string;
  subjectBusinessName: string;
  countryCode: string;
  subjectKey1: string;
  subjectKey2: string;
  subjectKey3: string;
  taxRegimeId: string;
  taxRegimeDescription: string;
  expertTypeProviderDescription: string;
  subjectKeyWithDescription: string;
  nameBySubjectType: string;
}

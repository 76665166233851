<rgi-rx-expansion-panel *ngFor="let asset of assets" [expanded]="false" color="default" >
  <rgi-rx-expansion-panel-header>
    <span rgi-rx-expansion-panel-label translate>{{ asset.description }}</span>
  </rgi-rx-expansion-panel-header>
  <div *ngIf="asset" class="rgi-ui-col rgi-ui-grid-3">
    
      <div *ngFor="let subject of asset.subjects" class="rgi-ui-col">
          <consultation-row [type]="'TEXT'" [label]="subject.type.description" [value]="subject.subject.nominative"></consultation-row>
      </div>
    

    
  </div>
</rgi-rx-expansion-panel>

import {InjectionToken} from '@angular/core';
import {RGI_RX_LOCALE} from '@rgi/rx/i18n';
import {RgiRxDateLocaleFormats} from './native-date-time-adapter.class';

export const RGI_RX_DEFAULT_DATETIME_CONFIG: RgiRxDateTimeConfig = {
  locale: 'i18n'
};

export interface RgiRxDateTimeConfig {
  locale: 'browser' | 'LOCALE_ID' | 'i18n';
}

export type RgiRxLocaleDateParseFormats = {[key in RGI_RX_LOCALE]: string};
export type RgiRxLocaleDateTimeParseFormats = {[key in RGI_RX_LOCALE]: string};

export const RGI_RX_DEFAULT_LOCALE_DATE_PARSE_FORMATS: RgiRxDateLocaleFormats = {
  it: 'dd/MM/yyyy',
  es: 'dd/MM/yyyy',
  en: 'M/dd/yyyy',
  de: 'dd.MM.yyyy',
  fr: 'dd/MM/yyyy'
};

export const RGI_RX_DEFAULT_LOCALE_DATETIME_PARSE_FORMATS: RgiRxLocaleDateTimeParseFormats = {
  it: `${RGI_RX_DEFAULT_LOCALE_DATE_PARSE_FORMATS.it}, HH:mm`,
  es: `${RGI_RX_DEFAULT_LOCALE_DATE_PARSE_FORMATS.es}, H:mm`,
  en: `${RGI_RX_DEFAULT_LOCALE_DATE_PARSE_FORMATS.en} h:mm a`,
  de: `${RGI_RX_DEFAULT_LOCALE_DATE_PARSE_FORMATS.de}, HH:mm`,
  fr: `${RGI_RX_DEFAULT_LOCALE_DATE_PARSE_FORMATS.fr} HH:mm`
};

export const RGI_RX_LOCALE_DATE_PARSE_FORMATS = new InjectionToken<RgiRxDateLocaleFormats>('RGI_RX_LOCALE_DATE_PARSE_FORMATS');
export const RGI_RX_LOCALE_DATETIME_PARSE_FORMATS = new InjectionToken<RgiRxDateLocaleFormats>('RGI_RX_LOCALE_DATETIME_PARSE_FORMATS');

export const RGI_RX_DATETIME_CONFIG = new InjectionToken<RgiRxDateTimeConfig>('RGI_RX_DATETIME_CONFIG');




<form [formGroup]="reportForm" xmlns="http://www.w3.org/1999/html" class="rgi-ui-form-container analytics-form">
  <h1 class="analytics-report-title">
    <span class="rgi-ui-icon-document"></span>
    {{ '_NEW_REPORT_' | translate | uppercase }}
  </h1>

  <div class="analytics-new-report-input">
    <rgi-rx-form-field class="analytics-flex-col">
      <label rgiRxLabel class="rgi-ui-form-control-label">
        {{ '_NEW_REPORT_NAME_' | translate }}
        <span class="analytics-required-fields"> *{{ '_MANDATORY_LBL_' | translate }}</span>
      </label>
      <input rgiRxInput formControlName="name" #content name="reportName" type="text">
    </rgi-rx-form-field>
    <rgi-rx-form-field class="analytics-flex-col">
      <label rgiRxLabel class="rgi-ui-form-control-label">{{ '_SELECT_STREAM_' | translate }}
        <span class="analytics-required-fields"> *{{ '_MANDATORY_LBL_' | translate }}</span>
      </label>
      <select rgiRxNativeSelect formControlName="stream" (change)="selectStream()" [rgiRxAnimatedLoading]="streamLoader()" [delay]="2000"
              loaderClass="analytics-loader">
        <option *ngFor="let stream of streamList" value="{{stream.qId}}">{{ stream.qName }}</option>
      </select>
    </rgi-rx-form-field>
  </div>

  <div class="analytics-new-report-input">
    <rgi-rx-form-field class="analytics-flex-col" *ngIf="showApp">
      <label rgiRxLabel class="rgi-ui-form-control-label">{{ '_SELECT_APP_' | translate }}
        <span class="analytics-required-fields"> *{{ '_MANDATORY_LBL_' | translate }}</span>
      </label>
      <select rgiRxNativeSelect formControlName="app"
              (change)="selectApp($event)" [rgiRxAnimatedLoading]="appLoader()" [delay]="2000"
              loaderClass="analytics-loader">
        <option value="0"></option>
        <option *ngFor="let app of filterDocumentsByStream" [value]="app.qDocId">{{ app.qDocName }}</option>
      </select>
    </rgi-rx-form-field>
    <rgi-rx-form-field class="analytics-flex-col" *ngIf="showSheet">
      <label rgiRxLabel>{{ '_SELECT_SHEET_' | translate }}
        <span class="analytics-required-fields"> *{{ '_MANDATORY_LBL_' | translate }}</span>
      </label>
      <rgi-rx-multiselect placeholder="'_SELECT_SHEET_' | translate"
                          formControlName="sheet" (selectionChange)="showCharts()"
                          [rgiRxAnimatedLoading]="getSheetsLoading()"
                          [delay]="2000"
                          loaderClass="analytics-loader">
        <rgi-rx-option *ngFor="let option of sheetSelect" [value]="option.id" [label]="option.title">
          {{ option.title }}
        </rgi-rx-option>
      </rgi-rx-multiselect>
    </rgi-rx-form-field>
  </div>


  <!-- QLIK LINK TO OPENED APP  -->
  <div class="rgi-ui-form-group" *ngIf="showSheet" style="margin-top:10px;">
    <button class="analytics-open-app-btn" rgi-rx-button color="primary" (click)="openQlikSense()">
      {{ '_OPEN_APP_QLIK_' | translate }}
    </button>
  </div>

  <div class="analytics-new-report-input" *ngIf="showSheet">
    <rgi-rx-form-field class="analytics-flex-col">
      <label rgiRxLabel class="rgi-ui-form-control-label">{{ '_SELECT_THEME_' | translate }}
      </label>
      <select rgiRxNativeSelect formControlName="theme">
        <option *ngFor="let theme of themes" value="{{theme.name}}">{{ theme.name }}</option>
      </select>
    </rgi-rx-form-field>
  </div>

  <hr style="width: 100%;">


  <div class="analytics-report-container">
    <!-- SETTINGS -->
    <div class="analytics-settings-box">
      <h3 class="analytics-report-title">
        <span class="rgi-ui-icon-settings"></span>
        {{'_REPORT_SETTINGS_' | translate}}
      </h3>
      <div class="analytics-settings">
        <div>
        <rgi-rx-form-field class="analytics-settings-field">
          <input formControlName="enableSelections" rgiRxInput type="checkbox">
          <label rgiRxLabel>{{ '_ENABLE_SELECTIONS_' | translate }}</label>
        </rgi-rx-form-field>
          <rgi-rx-form-field class="analytics-settings-field analytics-settings-input">
            <label rgiRxLabel>{{ '_MAX_ROWS_' | translate }}</label>
            <input rgiRxInput formControlName="maxRows" type="number">
          </rgi-rx-form-field>
        </div>
        <div>
          <rgi-rx-form-field class="analytics-settings-field">
            <input formControlName="enableInteractions" rgiRxInput type="checkbox">
            <label rgiRxLabel>{{ '_ENABLE_INTERACTIONS_' | translate }}</label>
          </rgi-rx-form-field>
          <rgi-rx-form-field class="analytics-settings-field analytics-settings-input">
            <label rgiRxLabel>{{ '_MAX_COLS_' | translate }}</label>
            <input rgiRxInput formControlName="maxCols" type="number">
          </rgi-rx-form-field>
        </div>
      </div>
    </div>

    <!-- LINK REPORT -->
    <div>
      <h3 class="analytics-report-title">
        <span class="rgi-ui-icon-export"></span>
        {{ '_LINK_REPORT_' | translate }}
      </h3>
      <div class="analytics-link-report">
        <rgi-rx-form-field *ngFor="let report of reports" class="analytics-settings-field">
            <input rgiRxInput formControlName="area" type="radio" (change)="onReportChange($event)" [value]="report.id">
            <label rgiRxLabel>{{ report.description }}</label>
        </rgi-rx-form-field>
      </div>
    </div>
  </div>

  <hr style="width: 100%;">

  <div *ngIf="areChartsVisible" class="mt-5">
    <h3 class="analytics-report-title">
      <span class="rgi-ui-icon-edit2"></span>
      {{ '_SHOW_CHARTS_TITLE_' | translate }}
    </h3>
    <div class="listbox"></div>

    <rgi-rx-form-field>
      <label rgiRxLabel>{{ '_SELECT_FILTER_' | translate }}</label>
      <rgi-rx-multiselect formControlName="filters">
        <rgi-rx-option *ngFor="let option of filterSelect" [value]="option" [label]="option.title">
          {{ option.title }}
        </rgi-rx-option>
      </rgi-rx-multiselect>
    </rgi-rx-form-field>

    <div *ngIf="submitted && formValue.elements.errors" class="invalid-feedback" style="margin-top: 10px;">
      <div *ngIf="formValue.elements.errors['required']" style="color:red;font-weight: bold;">
        {{ '_SELECT_CHARTS_ERROR_' | translate }}
      </div>
    </div>
    <div class="analytics-flex">
      <div class="analytics-charts" id="chart{{item.name}}" *ngFor="let item of objects;let i=index">
        <input rgiRxInput #checkboxes (change)="onCheckChange($event, item)"
               class="rgi-ui-form-control" type="checkbox"> {{ '_SELECT_CHART_' | translate }}
        <div class="chart{{item.name}}" style="height: 300px; width:100%; "
             [rgiRxAnimatedLoading]="getLoading$(item.name)" loaderClass="analytics-loader" [delay]="2000"></div>
      </div>
    </div>

    <div *ngIf="objects.length == 0">
      {{ '_NO_CHART_' | translate }}
    </div>
  </div>

  <rgi-rx-panel-footer class="analytics-btn-footer">
    <button rgi-rx-button color="secondary" (click)="closeSession()" style="margin: 0.1em;">
      {{ '_BACK_BTN_' | translate }}
    </button>
    <button rgi-rx-button color="primary" (click)="uncheckAll()" *ngIf="areChartsVisible" style="margin: 0.1em;">
      {{ '_CANCEL_BTN_' | translate }}
    </button>
    <button rgi-rx-button color="primary" (click)="saveReport(savereport)" *ngIf="areChartsVisible"
            style="margin: 0.1em;">
      {{ '_SAVE_BTN_' | translate }}
    </button>
  </rgi-rx-panel-footer>

</form>

<ng-template #savereport>
  <rgi-rx-panel>
    <rgi-rx-panel-header [closeable]="true" (onClose)="closeTemplate()">MESSAGGIO DI SISTEMA</rgi-rx-panel-header>
    <rgi-rx-panel-footer>
      <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="closeTemplate()">chiudi</button>
    </rgi-rx-panel-footer>
    <p>Report salvato con successo!</p>
  </rgi-rx-panel>
</ng-template>

import {FormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContactsEvent} from '../../../models/domain-models/contacts-event';
import {ModalService, RgiRxOnContainerValueChange, RgiRxOnRemove} from '@rgi/rx/ui';
import {ActiveRoute} from '@rgi/rx/router';
import {AnagFlowData} from '@rgi/anag';
import {ReIssuePolicyDataConstants} from '../../../re-issue-constants/re-issue-policy-data-constants';
import {AnagIssueSubject} from '../../re-issue-anag/anag-issue';
import {AnagIssueSubjectBusinessService} from '../../re-issue-anag/anag-issue-service';
import {ReIssueGeneralDataResourceService} from '../../re-issue-resources/re-issue-general-data-resource.service';
import {CheckPartyComplete} from '../../../models/domain-models/check-party-complete';
import {ReIssueMsgData} from '../../re-issue-msg-modal/re-issue-msg-model/re-issue-msg-data';
import {ReIssueMsgModalComponent} from '../../re-issue-msg-modal/re-issue-msg-modal.component';
import {Observable, of} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {RgiRxTranslationService} from "@rgi/rx/i18n";
import {State} from '@rgi/rx/state';

@Component({
  selector: 're-issue-general-data',
  templateUrl: './re-issue-general-data.component.html',
  styleUrls: ['./re-issue-general-data.component.css'],
})
export class ReIssueGeneralDataComponent implements OnInit {
  @Input() set formSubmitted(isFormSubmitted: boolean) {
    if (isFormSubmitted) {
      this.isFormSubmitted = isFormSubmitted;
      this.markFormAsDirtyAndTouched();
    }
  }

  @Input() stateObs: Observable<State>;
  state: State;

  @Input() productId: number;

  @Input() thirdPersonContact: any;

  @Input() set productName(name: string) {
    this.generalDataForm.patchValue({
      product: name
    });
  }

  @Input() set nodeName(name: string) {
    this.generalDataForm.patchValue({
      node: name
    });
  }

  @Input() set anagSubject(s: AnagIssueSubject) {
    this.anagSubjectRe = s;
    this.subjectSelected = !!s;
    this.patchSubjectDataAndValidateForm(s);
  }

  get anagSubject(): AnagIssueSubject {
    return this.anagSubjectRe;
  }

  @Input() set anagCoOwners(s: Array<AnagIssueSubject>) {
    this.anagCoOwnersRe = s ? s : [];
    this.generalDataForm.patchValue({
      coOwners: this.anagCoOwnersRe
    });
  }
  get anagCoOwners(): Array<AnagIssueSubject> {
    return this.anagCoOwnersRe;
  }

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected subjectBusinessService: AnagIssueSubjectBusinessService,
    public activateRoute: ActiveRoute,
    protected generalDataResourceService: ReIssueGeneralDataResourceService,
    public modalService: ModalService,
    private translateService: RgiRxTranslationService,
  ) {
    this.initializeForm();
    this.roleKeys = ReIssuePolicyDataConstants;
  }

  generalDataForm: UntypedFormGroup;
  roleKeys: any;
  anagSubjectRe: any;
  anagCoOwnersRe: Array<AnagIssueSubject> = [];

  generalDataValid = false;
  subjectSelected = false;
  isFormSubmitted = false;
  partyRolePolicyHolder: string;
  partyRolecoOwner: string;
  @Input() nodeId: string;
  @Input() nodeObject: any;
  @Input() coOwnerEnabled: boolean = true;

  @Output() subjectSelectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() coOwnerSelectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() coOwnerDeletedEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output() formValidEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output() emailEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() mobilePhoneEmitter: EventEmitter<string> = new EventEmitter<string>();

  @Output() contactsDataEmitter: EventEmitter<ContactsEvent> = new EventEmitter<ContactsEvent>();

  msgData: string;

  markFormAsDirtyAndTouched() {
    this.generalDataForm.markAsTouched();
    this.generalDataForm.markAllAsTouched();
  }

  initializeForm() {
    this.generalDataForm = this.formBuilder.group(
      {
        product: [{value: '', disabled: true}, Validators.required],
        policyholder: [, Validators.required],
        coOwners: [this.anagCoOwnersRe],
        node: [{value: 'node', disabled: true}],
        fiscalCode: [{value: '', disabled: true}, Validators.required],
        residence: [{value: '', disabled: true}],
        email: [{value: '', disabled: true}, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')],
        mobilePhone: [{value: '', disabled: true},
          [Validators.pattern('[- +()0-9]+')]
        ]
      }
    );
  }

  disableSubjectFormFields() {
    this.generalDataForm.controls.email.disable();
    this.generalDataForm.controls.mobilePhone.disable();
  }

  enableSubjectFormFields() {
    // TODO: UNCOMMENT WHEN FUNCTIONAL
    // this.generalDataForm.controls.email.enable();
    // this.generalDataForm.controls.mobilePhone.enable();
  }

  ngOnInit() {
    this.stateObs.subscribe(st => {
      this.state = st;
    });
  }

  getSubjectDropContainerData(role, isPolicyHolder) {
    const data = new AnagFlowData();
    data.partyRole = '' + role;
    if (isPolicyHolder) {
      this.partyRolePolicyHolder = data.partyRole;
    } else {
      this.partyRolecoOwner = data.partyRole;
    }
    data.idParentSession = this.activateRoute.id;
    data.nodeCode = this.nodeObject?.code;
    return data;
  }

  onEmailChange() {
    this.validateForm();
    if (this.generalDataForm.controls.email.valid) {
      this.contactsDataEmitter.emit(
        new ContactsEvent(
          'email',
          this.generalDataForm.controls.email.value.toUpperCase()
        )
      );
    }
  }

  onMobilePhoneChange() {
    this.validateForm();
    if (this.generalDataForm.controls.mobilePhone.valid) {
      this.contactsDataEmitter.emit(
        new ContactsEvent(
          'mobilePhone',
          this.generalDataForm.controls.mobilePhone.value
        )
      );
    }
  }

  validateForm() {
    this.generalDataValid = this.generalDataForm.valid;
    this.formValidEmitter.emit(this.generalDataValid);
  }

  patchSubjectDataAndValidateForm(subject: AnagIssueSubject) {
    subject ? this.addSubjectFormData(subject) : this.removeSubjectFormData();
    this.generalDataForm.updateValueAndValidity();
    this.validateForm();
  }

  addSubjectFormData(subject: AnagIssueSubject) {
    this.generalDataForm.patchValue({
      policyholder: subject,
      fiscalCode: this.getFiscalCode(subject),
      email: subject.emails && subject.emails[0] ? subject.emails[0].toLowerCase() : null,
      residence: subject.residence ? subject.residence.formatAddress : '',
      mobilePhone: subject.mobilePhone && subject.mobilePhone[0] ? subject.mobilePhone[0].localPrefix + subject.mobilePhone[0].number : ''
    });
    this.enableSubjectFormFields();
  }

  getFiscalCode(subject: AnagIssueSubject) {
    let fiscalCode = subject.vat;
    if (!fiscalCode) {
      if (subject.partyKey && subject.partyKey[0]) {
        if (subject.partyKey[0].key1 && subject.partyKey[0].key1.value && subject.partyKey[0].key1.value !== '') {
          fiscalCode = subject.partyKey[0].key1.value;
        } else if (subject.partyKey[0].key2 && subject.partyKey[0].key2.value && subject.partyKey[0].key2.value !== '') {
          fiscalCode = subject.partyKey[0].key2.value;
        }
      } else if (!subject.partyKey && !fiscalCode && subject.fiscalCode) {
        fiscalCode = subject.fiscalCode;
      }
    }

    return fiscalCode;
  }

  removeSubjectFormData() {
    this.generalDataForm.patchValue({
      fiscalCode: '',
      email: '',
      residence: '',
      mobilePhone: ''
    });
    this.disableSubjectFormFields();
    this.generalDataValid = false;
    this.formValidEmitter.emit(this.generalDataValid);
    this.anagSubjectSelected(null);
  }

  anagSubjectSelected(val: RgiRxOnContainerValueChange) {
    if (val !== null) {
      const state: any = this.state;
      if (state && state.thirdPersonContact && state.thirdPersonContact.objectId === val.changed.objectId) {
        this.displayError('RE_ISSUE.SUBSCRIBER_THIRD_PERSON_ERROR');
      } else if (state && state.isSubstitution && state.previousContractor.objectId === val.changed.objectId) {
        this.displayError('RE_ISSUE.SUBSTITUTION_ERROR');
      } else {
        this.subjectBusinessService.getSubject(val.changed.objectId, val.changed.node.identification)
          .subscribe(subj => {
            const c: CheckPartyComplete = new CheckPartyComplete(
              subj.idLatestPhotos,
              this.partyRolePolicyHolder,
              this.productId.toString(),
              subj.node.identification
            );
            this.generalDataResourceService.checkPartyComplete(c)
              .subscribe(
                (val2) => {
                  if (val2.outcome) {
                    const msgData = new ReIssueMsgData();
                    msgData.msg = val2.outcome[0];
                    msgData.showButtonContinue = false;
                    const confirmationModal = this.modalService.openComponent(ReIssueMsgModalComponent, msgData);

                    confirmationModal.modal.onClose.subscribe(() => {
                      this.subjectSelected = false;
                      this.generalDataForm.controls.policyholder.reset();
                    });
                  } else {
                    this.subjectSelected = true;
                    this.patchSubjectDataAndValidateForm(subj);
                    this.subjectSelectedEmitter.emit(subj);
                  }
                },
                error => {
                  this.showErrorModal(error, subj);
                }
              );
          });
      }
    } else {
      this.subjectSelected = false;
      this.subjectSelectedEmitter.emit(val);
    }
  }

  private displayError(error: string) {
    const idMsg: string = error;
    this.showGenericErrorModal(idMsg);
    this.subjectSelected = false;
    this.generalDataForm.controls.policyholder.reset();
  }

  anagCoOwnerSelected(val: RgiRxOnContainerValueChange) {
    if (val !== null) {
      const objectId:string = val.changed.objectId;
      const isPolicyHolder:boolean = this.isPolicyHolder(objectId);
      const isCoOwner:boolean = this.isCoOwner(objectId);
      if (!isPolicyHolder && !isCoOwner) {
        let anagIssueSubject = new AnagIssueSubject();
        anagIssueSubject.nominative = val.changed.nominative;
        this.subjectBusinessService.getSubject(val.changed.objectId, val.changed.node.identification)
          .subscribe(subj => {
            this.coOwnerSelectedEmitter.emit(subj);
          });
      } else {
        const idMsg:string = isPolicyHolder ? 'RE_ISSUE.CO_POLICYHOLDER_SUBSCRIBER_ERROR' : 'RE_ISSUE.CO_POLICYHOLDER_JOINT_SUBSCRIBER_ERROR';
        this.showGenericErrorModal(idMsg);
        this.deleteCoOwner(objectId);
      }
    } else{
        this.coOwnerSelectedEmitter.emit(val);
    }
  }

  isPolicyHolder(subjectId:string){
    let policyHolder = this.generalDataForm.controls.policyholder.value;
    return policyHolder !== null && policyHolder !== undefined && policyHolder.objectId === subjectId; // was using codelizer. this may be only !policyHolder || policyHolder.objectId === subjectId
  }

  isCoOwner(subjectId:string){
    let  coOwners:any = this.getCoOwnersValue();
    const result = coOwners.filter(coPolicyHolder => (subjectId === coPolicyHolder.objectId || subjectId === coPolicyHolder.id));
    return result.length > 1;
  }

  deleteCoOwner(subjectId:string){
    let  coOwners:any = this.getCoOwnersValue();
    let i:number = coOwners.length-1;
    while(i >= 0){
      if(coOwners[i].objectId === subjectId || coOwners[i].id === subjectId) {
        coOwners.splice(i, 1);
        this.anagCoOwnersRe = coOwners;
        this.generalDataForm.get('coOwners').setValue(this.anagCoOwnersRe);
        i=-1;
      }
      i--;
    }
  }

  showErrorModal(error, subj) {
    const msgData = new ReIssueMsgData();
    msgData.msg = error.violations[0].message;
    const violationLevel = error.violations[0].level;
    if (violationLevel === 'WARNING') {
      msgData.showButtonConfirm = true;
    } else if (violationLevel === 'BLOCKING') {
      msgData.showButtonConfirm = false;
      this.resetPolicyholderValues();
    }
    const confirmationModal = this.modalService.openComponent(ReIssueMsgModalComponent, msgData);
    confirmationModal.modal.onClose.subscribe((confirm) => {
      if (!confirm && violationLevel !== 'BLOCKING') {
        this.resetPolicyholderValues();
      }
      if (confirm && violationLevel !== 'BLOCKING') {
        this.subjectSelected = true;
        this.patchSubjectDataAndValidateForm(subj);
        this.subjectSelectedEmitter.emit(subj);
      }
    });
  }

  showGenericErrorModal(idMsg:string) {
    const msgData = new ReIssueMsgData();
    const msg = of([idMsg]);
    msg.pipe(mergeMap(r => {
      return this.translateService.translateAll(...r);
    })).subscribe(next => {
      this.msgData = next [0];
    }).unsubscribe();
    msgData.msg = this.msgData;
    msgData.showButtonConfirm = false;
    this.modalService.openComponent(ReIssueMsgModalComponent, msgData);
  }

  private resetPolicyholderValues() {
    this.removeSubjectFormData();
    this.subjectSelected = false;
    this.generalDataForm.controls.policyholder.reset();
  }

  private getCoOwnersValue(){
    return this.generalDataForm.get('coOwners').value;
  }

  onRemove($event: RgiRxOnRemove) {
    const idSubject = $event.data.idLatestPhotos ? $event.data.idLatestPhotos : $event.data.snapshotId;
    this.coOwnerDeletedEmitter.emit(idSubject);
    this.anagCoOwnersRe.forEach( (item, index) => {
      if(item.idLatestPhotos === idSubject) this.anagCoOwnersRe.splice(index,1);
    });
  }
}

<div (click)="onContainerClicked($event)" class="modal" tabindex="-1" [ngClass]="{'in': visibleAnimate}"
     [ngStyle]="{'display': visible ? 'block' : 'none', 'opacity': visibleAnimate ? 1 : 0}">
  <div class="modal-dialog" [ngClass]="dialogClass">
    <div class="modal-content" style="font-family: DINProMedium">
      <div *ngIf="!hideHeader" class="modal-header">
        <ng-content select=".app-modal-header"></ng-content>
      </div>
      <div [id]="modalBodyId" class="modal-body" style="padding-top: 5px">
        <ng-content select=".app-modal-body"></ng-content>
      </div>
      <div *ngIf="!hideFooter" class="modal-footer">
        <ng-content select=".app-modal-footer"></ng-content>
      </div>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {ReIssuePtfdanniResourceService} from '../re-issue-resources/re-issue-ptfdanni-resource.service';
import {PtfDanniPostContract} from '../../models/api-models/ptf-danni-post-contract';
import {PtfDanniPostContractResponse} from '../../models/api-models/ptf-danni-post-contract-response';
import {Observable} from 'rxjs';
import {ReIssuePolicyDataNavigationData} from '../re-issue-state-manager/re-issue-policy-data-state';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {
  ReIssuePortfolioProposalService
} from '../re-issue-resources/re-issue-portafolio-resources/re-issue-portfolio-proposal.service';
import {ProposalDataRequest} from '../../models/pc-portfolio-models/proposal-models/proposal-data-request';

export interface ReIssueOpenContractRequestI {
  proposalNumber: string;
  policyNumber: string;
  quoteNumber: string;
  createCopy: boolean;
  productCode: string;
  productName: string;
  companyId: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReIssueProposalLoadingBusinessService {

  constructor(
    private ptfdanniResourceService: ReIssuePtfdanniResourceService,
    private portfolioProposalService: ReIssuePortfolioProposalService,
    private routingService: RoutingService
  ) {
  }

  openContract$(
    postContractReq: ReIssueOpenContractRequestI,
    node: any,
    activeRoute: ActiveRoute
  ) {
    this.postContract$(
      postContractReq
    ).subscribe((val: PtfDanniPostContractResponse) => {
      const reIssuePolicyDataNavigationData: ReIssuePolicyDataNavigationData = {
        contractorSubject: null, // TODO: CHECK IF CORRECT
        previousContractor:null,
        resourceId: val.id,
        node,
        productCode: postContractReq.productCode,
        productName: postContractReq.productName,
        policyTechnicalData: null,
        companyId: postContractReq.companyId,
        editMode: true,
        editFunctionality: true,
        backFromStep: false,
        isSubstitution: false,
        isToDisableFraz: null,
        authorize: false,
        productId: null,
        thirdPersonContact: null
      };

      this.routingService.navigate(
        're-issue-policy-data',
        reIssuePolicyDataNavigationData,
        activeRoute.id
      );
    });
  }

  postContract$(
    postContractReq: ReIssueOpenContractRequestI
  ): Observable<PtfDanniPostContractResponse> {
    const request = new PtfDanniPostContract();
    request.proposalNumber = postContractReq.proposalNumber;
    request.policyNumber = postContractReq.policyNumber;
    request.quoteNumber = postContractReq.quoteNumber;
    request.createCopy = postContractReq.createCopy;

    return this.ptfdanniResourceService.postContract$(
      request
    );
  }

  initProposal(contract: any) {
    return this.portfolioProposalService.initProposal(
      this.getProposalRequestFromContract(contract)
    );
  }

  getProposalRequestFromContract(contract: any): ProposalDataRequest {
    return {
      additionalLabels: null,
      productCode: contract.product.code,
      companyCode: contract.company.code,
      managementNodeCode: contract.managementNode.code,
      issuingDate: contract.issueDate,
      effectDate: contract.policyEffectiveDate,
      from: {
        copy: null,
        resume: contract.proposalNumber,
        load: null
      }
    };
  }
}

import {Injectable} from '@angular/core';
import {ReIssueEmissionResourceService} from '../re-issue-resources/re-issue-emission-resource.service';
import {ReIssueEmissionState} from '../re-issue-state-manager/re-issue-emission-state-manager.service';
import {
  ReIssueDocumentsResourceServiceService
} from "../re-issue-resources/re-issue-documents-resource-service.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ReIssueMovementCodesEnum} from "../../enums/re-issue-movement-codes-enum";

@Injectable({
  providedIn: 'root'
})
export class ReIssueEmissionBusinessService<T extends ReIssueEmissionState> {

  constructor(
    private emissionResourceService: ReIssueEmissionResourceService,
    private documentsResourceService: ReIssueDocumentsResourceServiceService,
  ) {
  }

  getDocuments(state: T): Observable<T> {
    let policyNumber = state.policy?.contractNumber?.policy || state.resourceId;
    return this.documentsResourceService.getDocuments(policyNumber, ReIssueMovementCodesEnum.ISSUE_MOVEMENT).pipe(
      map(documents => {
        state.documents = documents;
        return state;
      })
    );
  }

  // checkPin(state: T, pinValue: string): Observable<T> {
  //   return this.emissionResourceService.postCheckPin(state.policyNumber.contractNumber.policyNumber, pinValue).pipe(
  //     map(res => {
  //       console.log('PinCheckResponse::', res);
  //       state.pinResponse = res;
  //       if (res.includes('OK')) {
  //         state.checkPinResponse = true;
  //       } else {
  //         state.checkPinResponse = false;
  //       }
  //       return state;
  //     })
  //   );
  // }

  // confirmOrder$(state: T): Observable<T> {
  //   return this.emissionResourceService.confirmOrder$(state.policyNumber.contractNumber.policyNumber).pipe(
  //     map(res => {
  //       console.log('ConfirmOrderResponse::', res);
  //       state.confirmOrderResponse = res;
  //       return state;
  //     })
  //   );
  // }

  downloadDocuments$(policyNumber: string, documentCode: string): Observable<Blob> {
    return this.documentsResourceService.downloadDocuments$(policyNumber, documentCode);
  }
}

import {Injectable} from '@angular/core';
import {ActiveRoute, RgiRxRouteData, RoutingService} from '@rgi/rx/router';
import {Observable} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {OPERATIONSCODE} from '../../group-policy-constants/general';
import {ROUTES_GP_SUMMARY} from '../../group-policy-constants/routes-constants';
import {DataForSteps, IdentificationKey} from '../../group-policy-models/group-policy-issue-home';
import {GroupPolicyProposalissueRouteData} from '../../group-policy-models/group-policy-proposalissue';
import {GroupPolicyResourceService} from '../../group-policy-resources/group-policy-resource.service';
import {GroupPolicyApiRestErrorService} from '../../group-policy-services/group-policy-api-rest-error.service';
import {GroupPolicyIntegrationService} from '../../group-policy-services/group-policy-integration.service';
import {GroupPolicyStateProposalIssue} from '../group-policy-state';

@Injectable({
  providedIn: 'root'
})
export class GroupPolicyStatelessOpProposalissueService {
  constructor(protected groupPolicyService: GroupPolicyResourceService,
              protected routingService: RoutingService,
              protected integrationService: GroupPolicyIntegrationService,
              protected gpErrorService: GroupPolicyApiRestErrorService) {
  }

  initProposalIssue$(stateHome$: Observable<GroupPolicyStateProposalIssue>,
                     activeRoute: ActiveRoute): Observable<GroupPolicyStateProposalIssue> {
    const proposalIssueRouteData = activeRoute.getRouteData<GroupPolicyProposalissueRouteData>();
    return stateHome$.pipe(
      mergeMap((st) => {
        st.errors = this.gpErrorService.cleanErrorsForCode(st.errors, 'FOOTER');
        return this.groupPolicyService.proposalIssue(proposalIssueRouteData.proposalNumber).pipe(
          map((resp: IdentificationKey) => {
            this.integrationService.navigate(this.routingService, ROUTES_GP_SUMMARY,
              this.createObjForNextStep(resp, proposalIssueRouteData), activeRoute);
            return st;
          }),
          catchError(this.gpErrorService.catchApiErrorFn(st, 'FOOTER'))
        );
      }),
      catchError(this.gpErrorService.manageStreamErrFn()),
      map((st: GroupPolicyStateProposalIssue) => st)
    );
  }

  protected createObjForNextStep(respNewFlowIssue: IdentificationKey,
                                 editRouteData: RgiRxRouteData<GroupPolicyProposalissueRouteData>): DataForSteps {
    return {
      resId: respNewFlowIssue.uuid,
      operationCode: OPERATIONSCODE.ISSUE_PROPOSAL,
      idParentSession: editRouteData.idParentSession,
      inquiryProposalNumber: editRouteData.proposalNumber,
      isFromInquiry: editRouteData.isFromInquiry
    } as DataForSteps;
  }

}

import { PolicyService } from './../../../services/policy-service';
import { EventNotificator } from '../../../event-notificator';
import { LifeRoleService } from './../../../services/life-role.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SelectSubjectComponent } from '../select-subject/select-subject.component';
import { TranslationWrapperService  } from '../../../../i18n/translation-wrapper.service';
import { AnagEditPartyResolver } from '@rgi/anag';



@Component({
  selector: 'lic-select-second-insured',
  // TODO l'html mi pare uguale a quello dell'assicurato forse posso evitare di farlo
  templateUrl: './select-second-insured.component.html',
  styleUrls: ['./select-second-insured.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SelectSecondInsuredComponent extends SelectSubjectComponent implements OnInit, EventNotificator  {

  @Input() parentForm: UntypedFormGroup;
  disabled: boolean;
  @Output() eventPropagation = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Input() role: string;

  label: string;
  select;

  constructor(
    lifeRoleService: LifeRoleService,
    policyService: PolicyService,
    translateService: TranslationWrapperService,
    protected anagEditResolver: AnagEditPartyResolver) {
    super(lifeRoleService, policyService, translateService, anagEditResolver);
    this.select = this.translateService.getImmediate('lic_select');
  }

  ngOnInit() {
    this.label = this.translateService.getImmediate('lic_SecondInsured');
    this.disabled = false;
    this.parentForm.controls.secondInsured.setValue(this.val);
  }


}

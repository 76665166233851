import { AttachmentType } from "./parameters/attachment-type";
import { Party } from "./parameters/party";

export class Bond {
    constructor(
        public party: Party,
        public description: string,
        public effectDate: Date,
        public expirationDate: Date,
        public attachmentType: AttachmentType,
      ) {
      }
}
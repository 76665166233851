<div class="step-header">
  <span class="rgifont rgi-check icon-sel-card" id="bordered-icon-sel" *ngIf="open" ></span>
  <span class="rgifont rgi-chevron-down center" style="color:white" *ngIf="!disabled && !open"></span>
  <span class="glyphicon glyphicon-chevron-right step" *ngIf="!active && disabled"></span>
  <span class="header-step" translate>{{ label }}</span>
  <span
    *ngIf="open"
    id="step-pencil"
    class="rgifont rgi-pencil icon-sel-card"
    [ngClass]="{'disable-pencil': open && disabled, 'enabled-pencil': !(open && disabled)}"
    (click)="onHeaderClick()">
  </span>
</div>
<div class="step-body">
  <ng-content></ng-content>
</div>


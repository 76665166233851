import {Directive, TemplateRef} from '@angular/core';
import {RgiRxTemplateContentDirective} from '../rgi-ui-api';

@Directive({
  selector: 'ng-template[rgiRxDragLabel]'
})
export class RgiRxDragLabelDirective extends RgiRxTemplateContentDirective {


  constructor(template: TemplateRef<any>) {
    super(template);
  }
}

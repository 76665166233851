import {ROUTES_EXTENSION_LIST} from './routes';
import {PncPsalesPageStep, PncPsalesRequiredDataComponent, PncPsalesRequiredDataStateManager} from '@rgi/pnc-postsales';


export const RGI_MOTOR_POSTSALES_STEPS: { [key: string]: PncPsalesPageStep } = {
  EXTENSION_LIST: {
    routeName: ROUTES_EXTENSION_LIST,
    component: PncPsalesRequiredDataComponent,
    manager: PncPsalesRequiredDataStateManager,
    deps: []
  },
};

import { GenericEntity } from '../generic-entity';

export class ConfMetadata {

  public id: string;
  public code: string;
  public description: string;

  public value: string;
  public valueDescription: string;
  public propertyType: GenericEntity;
  public listValue: Array<string>;
  public visible: boolean;
  public usedAsIndex: boolean;
  public usedForValidation: boolean;
  public mandatory: boolean;
  public modifiable: boolean;
  public systemMetadato: boolean;
  public finderMetadato: boolean;
  public complianceStatus: GenericEntity;

  constructor() { }

}

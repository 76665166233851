<ng-container *ngIf="stateSummary">
  <rgi-gp-stepper (targetRoute)="callActionBack($event)"
                  [disabled]="!!stateSummary.proposalNumber"></rgi-gp-stepper>
  <ng-container *ngIf="stateSummary.summary">

    <div class="rgi-gp-section-title">
      <span class="rgi-gp-icon rgi-ui-icon-document"></span>
      <b translate>_GP_._TITLE_._POLICY_GENERAL_DATA_</b>
    </div>

    <rgi-gp-group-policy-summary-adm-data-component
      [summaryAdministrativeData]="stateSummary.summary.summaryAdministrativeData">
    </rgi-gp-group-policy-summary-adm-data-component>

    <div class="rgi-gp-section-title">
      <span class="rgi-gp-icon rgi-ui-icon-users"></span>
      <b translate>_GP_._TITLE_._SUBJECTS_</b>
    </div>

    <rgi-gp-group-policy-summary-parties-component
      [summaryParties]="stateSummary.summary.summaryParties">
    </rgi-gp-group-policy-summary-parties-component>

    <div class="rgi-gp-section-title">
      <span class="rgi-gp-icon rgi-ui-icon-rule"></span>
      <b translate>_GP_._TITLE_._ASSET_GUARANTEES_DATA_</b>
    </div>

    <rgi-gp-group-policy-summary-assets-component [stateManagerCluster]="stateManagerSummary"
                                                  [summaryAssets]="stateSummary.summary.summaryAssets">
    </rgi-gp-group-policy-summary-assets-component>

    <div *ngIf="stateSummary.quotations && stateSummary.quotations.quotationDetail">
      <div class="rgi-gp-section-title">
        <span class="rgi-gp-icon rgi-ui-icon-users"></span>
        <b translate>_GP_._QUOTATION_._DETAIL_</b>
      </div>
      <rgi-gp-group-policy-summary-quotation-detail
        [quotationDetail]="stateSummary.quotations.quotationDetail"
      >
      </rgi-gp-group-policy-summary-quotation-detail>
    </div>

    <div *ngIf="isApplicationsListVisible">
      <div class="rgi-gp-section-title">
        <span class="rgi-gp-icon rgi-ui-icon-cube"></span>
        <b translate>_GP_._TITLE_._APPLICATIONS_LIST_</b>
      </div>
      <rgi-gp-group-policy-applications-list
        [proposalNumber]="stateSummary.summary.summaryAdministrativeData.proposalNumber"
        [onlyValidPolicy]="true">
      </rgi-gp-group-policy-applications-list>
    </div>
  </ng-container>

  <ng-container *ngIf="stateSummary.documents && stateSummary.documents.length > 0">
    <div class="rgi-gp-section-title">
      <span class="rgi-gp-icon rgi-ui-icon-document"></span>
      <b translate>_GP_._TITLE_._DOCUMENTS_</b>
    </div>
    <div *ngFor="let document of stateSummary.documents">
      <span class="rgi-ui-icon-note" style="font-size: 20px; margin-right: 5px;"></span>
      <span class="rgi-ui-title-3">{{document.description}}</span>
    </div>
  </ng-container>

  <rgi-gp-payments-section *ngIf="stateSummary.editablePayments"
                           [paymentConfig]="stateSummary.editablePayments"
                           [selectablePayments]="stateSummary.selectablePayments"
                           [paymentSectionValidCheck]="paymentValidationChecker"
                           (setPaymentMethod)="setPaymentMethod($event)"
                           (isSectionValid)="isPaymentOk = $event"
                           [isSectionDisabled]="!!stateSummary.proposalNumber">
  </rgi-gp-payments-section>

  <div class="rgi-ui-grid-1 rgi-gp-w-100">
    <div class="rgi-ui-col">
      <rgi-rx-snackbar [tag]="'ISSUE_SUMMARY'"></rgi-rx-snackbar>
    </div>
  </div>

  <div *ngIf="stateSummary.errors && stateSummary.errors.length" data-qa="errorMessage">
    <rgi-gp-group-policy-errors-component
      [errors]="stateSummary.errors"
      [type]="stateSummary.type"
      [areaCode]="'FOOTER'">
    </rgi-gp-group-policy-errors-component>
  </div>

<!--  Footer is hided only when is saved a proposal with subsequent call-->
<!--  to documents. The other cases navigate to next page of issue-confirm-->
  <div class="rgi-ui-panel-footer" *ngIf="!(stateSummary.documents && stateSummary.documents.length >= 0)">
    <button rgi-rx-button
            color="secondary"
            (click)="callActionBack()"
            [disabled]="summaryForm.invalid"
            data-qa="buttonsBack" translate>{{'_GP_._BTN_._BACK_'}}
    </button>
    <ng-container *ngFor="let summaryAction of stateSummary.summaryActions">
      <button
        rgi-rx-button
        color="primary"
        (click)="callActionEmit(summaryAction.code)"
        [disabled]="summaryForm.invalid || !isPaymentOk"
        data-qa="buttonsIssue">{{summaryAction.description}}
      </button>
    </ng-container>
  </div>
</ng-container>

import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { DscCarBodyworkModalComponent } from './dsc-car-bodywork-modal/dsc-car-bodywork-modal.component';
import { ModalService } from '@rgi/rx/ui';
import { DscCarBodyworkService } from './dsc-car-bodywork.service';
import { OpenTypeSharedComponent } from '../dsc-shared-model/dsc-model-enums';
import { CarBodywork } from '../dsc-shared-model/dsc-model-bodywork';

@Component({
  selector: 'claims-dsc-car-bodywork',
  templateUrl: './dsc-car-bodywork.component.html',
  styleUrls: ['./dsc-car-bodywork.component.scss']
})
export class DscCarBodyworkComponent implements OnInit {

  isAccordion: boolean = false;
  expanded: boolean = true;
  @Input() carBodywork: CarBodywork = new CarBodywork();
  @Input() typeOpenComponent: OpenTypeSharedComponent = OpenTypeSharedComponent.isAccordion;
  @Output() outputpolicyData = new EventEmitter<CarBodywork>();

  constructor(
    private modalServiceRgi: ModalService,
    private carBodyworkService: DscCarBodyworkService
    ) {}


  ngOnInit(): void {
    this.isAccordion = this.typeOpenComponent === OpenTypeSharedComponent.isAccordion ? true : false;
  }
  deleteBodywork() {
    this.carBodywork = new CarBodywork();
    this.outputpolicyData.emit(this.carBodywork);
  }
  selectBodywork(carBodywork) {
    this.outputpolicyData.emit(carBodywork);
  }

  openModalNewCarbodywork() {
    this.modalServiceRgi.open(DscCarBodyworkModalComponent)
      .onClose.subscribe(res => {
        if (res === 'newBodywork' ) {
          this.goToNewGarageSession();
        } else {
          const driver = res.driver;
          this.carBodywork.objectId = res.objectId;
          this.carBodywork.code = res.code;
          if (driver) {
            this.carBodywork.designation = driver.designation;
            this.carBodywork.givenName = driver.givenName;
            this.carBodywork.surname = driver.surname;
            this.carBodywork.fiscalCode = driver.fiscalCode;
            this.carBodywork.vatCode = driver.vatCode;
            this.carBodywork.claimsAddress = { ...driver.claimsAddress };
          }
          this.selectBodywork(this.carBodywork);
        }
    });
  }

  goToNewGarageSession() {
    this.carBodyworkService.goToNewGarageSession$('newClaimsHome');
  }

  setAccordion() {
    this.expanded = !this.expanded;
}
}

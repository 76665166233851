import { state } from '@angular/animations';
import { NewDialogueState } from './../dialogue-handler-configuration/dialogue-handler.model';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { DialogueHandlerNewDialogueStateManager } from '../dialogue-handler-service/dialogue-handler-new-dialogue.state.manager';
import { ActiveRoute } from '@rgi/rx/router';
import { RgiRxDatatableSelectionChange, TableSchema } from '@rgi/rx/ui';
import { LoggerFactory } from '@rgi/rx';
import { ParamConstants, ParamPlaceholder, paramObj } from '../constants/paramCostants';
import { schemaActor, schemaFlow, schemaFlux } from './schema-table/schema-table-new-dialogue';
import { ActCausOutcome, CausaliBySinistroList, DialogueFunction, DialogueObject, DocList, Etype, FunParamList, FunctionDocData, NewDialogueRequest, PossibleAnswers, WrapperTableResponses } from '../dialogue-handler-configuration/new-dialogue.model';
import { Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ParameterFieldRecord } from '../dialogue-handler-configuration/dh-summary.model';


export enum NewDialogueViewConfiguration {
  PARTIAL_VIEW_CONF = 'PNV',
  COMPLETE_VIEW_CONF = 'CNV',
  RESPONSE_VIEW_CONF = 'RNV'
}

enum FunctionList {
  CICOS = 'CICOS',
  CICOS_AF = 'CCOSAF'
}

export enum RoleCompany {
  CREDITOR = '8',
  DEBTOR = '9'
}

@Component({
  selector: 'claims-new-dialogue',
  templateUrl: './new-dialogue.component.html',
  styleUrls: ['./new-dialogue.component.scss'],
})


export class NewDialogueComponent implements OnInit {

  checkClearParams: boolean = false;
  errorMessage: string;
  newDialogueViewConfiguration = NewDialogueViewConfiguration;
  roleCompany = RoleCompany;

  @Input() newRequestForm: NewDialogueRequest;

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('modalOutcome', { static: false }) modal: any;

  subscriptions: Subscription[] = [];

  modalError: string = 'PLACEHOLDER';
  newDialogueForm: UntypedFormGroup;
  dynamicParameters: FunParamList[][];
  paramConst = ParamConstants;
  localParam: ParameterFieldRecord[] = [];
  outcome: string;
  modalType: string;
  controlsConfig: any;
  lastCheckedIndex: number;
  selectedDocuments = new Map();
  viewConfig;
  msgId: number;
  onbjectIndex: number = 0;
  descrObject : string = '';
  role : Etype ;
  selectedResponse: PossibleAnswers = null;
  viewTable : boolean = false;
  localResponseLength: number;
  paramForm: UntypedFormGroup;

  filteredObject: Etype[] = [];
  localObject: NewDialogueState;
  today: Date;

  wrapperTableResponses: WrapperTableResponses = new WrapperTableResponses();

  schemaActor: TableSchema = schemaActor;
  schemaFlow: TableSchema = schemaFlow;
  schemaFlux: TableSchema = schemaFlux;

  viewCond = {
    functionCond: false,
    companyCond: false,
    causalCond: false,
    responseCond: false,
    documentationCond: false
  }

  private readonly logger = LoggerFactory();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public newDialogueState: DialogueHandlerNewDialogueStateManager,
    public activeRoute: ActiveRoute,
    @Inject('eventService') private emitter: any,
    // private cdRef: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    this.checkState();
    this.viewConfiguration();
    this.initValidators();
    this.updateStateOnChangeForm();
    this.localObject = this.newDialogueState.getCurrentState();

    if(this.localObject?.claimNumberPass) {
        this.newDialogueForm.controls.claimNumberPass.setValue(this.localObject.claimNumberPass);
    }

    this.today = new Date();

  }

  stampa(elem) {
    console.log(elem)
  }

  onFunctionSelected() {
    this.descrObject  = null;
    this.role = null;
    if (this.newDialogueForm.controls.idFunStatusSelected.value != '' && this.newDialogueForm.controls.idFunStatusSelected.value != null) {
      const selectedFunctionCode = this.newDialogueForm.controls.idFunStatusSelected.value;
      const selectedFunction = this.localObject.searchWrapperObject.dialogueFunctionResponse.find(func => func.functionCode === selectedFunctionCode);
      this.onbjectIndex = this.localObject.searchWrapperObject.dialogueObjectResponse.findIndex(elem => elem.functionCode == selectedFunctionCode)
      if (selectedFunction) {
        const firstDialogueMsgData = selectedFunction.dialogueMsgData[0];
        this.msgId = Number(selectedFunction.dialogueMsgData[0].dialogueMsgId);
        if (firstDialogueMsgData) {
          this.newDialogueForm.patchValue({
            dialogueMsgCode: selectedFunction.functionStatusCode,
            dialogueMsgDescription: selectedFunction.statusDescription
          });
        }

        // Pulisce la select degli oggetti di dialogo quando viene selezionata una nuova function
        this.newDialogueForm.controls.idDialogueSubject.setValue(null);
        // this.filteredObject = this.localObject.searchWrapperObject.dialogueObjectResponse.filter(element => element.functionCode === selectedFunctionCode);
        // this.filteredObject = this.localObject.searchWrapperObject.dialogueObjectResponse[0].dialogueSubject;

        if (this.viewConfig == NewDialogueViewConfiguration.PARTIAL_VIEW_CONF) {
          this.newDialogueState.partialPopolateCascadeSections$(this.msgId);
        }
        if(this.localObject.searchWrapperObject.dialogueObjectResponse[this.onbjectIndex].dialogueSubject.length == 1){
            this.newDialogueForm.controls.idDialogueSubject.setValue(this.localObject.searchWrapperObject.dialogueObjectResponse[this.onbjectIndex].dialogueSubject[0].code);
            this.descrObject = this.localObject.searchWrapperObject.dialogueObjectResponse[this.onbjectIndex].dialogueSubject[0].description;
        }

        if(selectedFunctionCode == FunctionList.CICOS || selectedFunctionCode == FunctionList.CICOS_AF){
          this.role = this.localObject.newWrapperObject.responseRoleList.data.find( elem => elem.code == '8');
          this.newDialogueForm.controls.roleCompanyUs.setValue(this.role.code);
          this.onObjectSelected();
        }
      }
    }
    else
    {
      this.dynamicParameters = [];
      this.onbjectIndex = 0;
      this.newDialogueForm.controls.idFunStatusSelected.setValue(null);
      this.newDialogueForm.controls.dialogueMsgCode.setValue(null);
      this.newDialogueForm.controls.dialogueMsgDescription.setValue(null);
      this.newDialogueForm.controls.idDialogueSubject.setValue(null);
    }
  }

  onObjectSelected() {
    // Controlla se entrambe le select sono popolate e se la select degli oggetti contiene un valore
    this.viewTable = false;
    const functionValue = this.newDialogueForm.controls.idFunStatusSelected.value;
    const objectValue = this.newDialogueForm.controls.idDialogueSubject.value;
    const compValue = this.newDialogueForm.controls.roleCompanyUs.value;
    console.error(compValue)
    if (functionValue != null && functionValue.trim() !== '' && objectValue != null && objectValue.trim() !== '' && compValue) {
      if (this.viewConfig != NewDialogueViewConfiguration.PARTIAL_VIEW_CONF) {
        this.retrieveCascadeData();
      }
    }


  }

  setDescStatusValue() {
     this.selectedResponse = this.localObject.responseObject.possibleAnswers.find((elem) => elem.idFunctionStatus == this.newDialogueForm.controls.idFunStatusSelected.value);
     console.log("status", this.selectedResponse)
  }


  checkState() {
    this.subscriptions.push(this.newDialogueState.getState$().subscribe((state) => {
      // if (state && state.newWrapperObject && state.newWrapperObject.responseActCausOutcome) {
      //   this.wrapperTableResponses.attoreCausale = state.newWrapperObject.responseActCausOutcome.response;
      // } else {
      // }
      console.log(state)
      if (state && state?.outcomeService?.requestOutcome) {
        this.emitter.broadcastEvent('stop-loader');
        if (state?.outcomeService?.requestOutcome?.code == '1' || state?.outcomeService?.requestOutcome?.code == '2') {
          this.modalOpen(state?.outcomeService?.requestOutcome?.description, state?.outcomeService?.requestOutcome?.code)
        }
        state.outcomeService = null;
      }

    if (state && state?.newWrapperObject?.responseFunParam?.response?.length > 0) {
        this.setParamForm(state.newWrapperObject.responseFunParam.response)
        state.newWrapperObject.responseFunParam = null;
        this.checkClearParams = false;
      } else if (state && state?.newWrapperObject?.responseFunParam?.response?.length == 0 && this.checkClearParams == false) {
        this.dynamicParameters = null;
        this.checkClearParams = true;
      }


      if(state?.newWrapperObject?.responseActCausOutcome?.response?.length > 0 && !this.viewTable){
        setTimeout(() => {
          this.viewTable = true
        }, 100)
      }



      if(state?.newWrapperObject?.responseActCausOutcome?.response?.length == 1){
        state.newWrapperObject.responseActCausOutcome.response[0].selected = true;
       }
      return of(state);

    }
    )
    );
  }

  onSelectChange($event: RgiRxDatatableSelectionChange<any>) {
    if ($event.removed.length > 0) {
      $event.removed.forEach((doc) => this.selectedDocuments.delete(doc.docId))
    }
    if ($event.added.length > 0) {
      $event.added.forEach((doc) => this.selectedDocuments.set(doc.docId, doc))
    }
  }

  viewConfiguration() {
    this.viewConfig = this.newDialogueState.getCurrentState().viewConfig;
    // const viewConfig = NewDialogueViewConfiguration.COMPLETE_VIEW_CONF;
    switch (this.viewConfig) {
      case NewDialogueViewConfiguration.COMPLETE_VIEW_CONF:
        this.viewCond.functionCond = true;
        this.viewCond.causalCond = true;
        this.viewCond.documentationCond = true;
        break;
      case NewDialogueViewConfiguration.PARTIAL_VIEW_CONF:
        this.viewCond.functionCond = true;
        this.viewCond.companyCond = true;
        break;
      case NewDialogueViewConfiguration.RESPONSE_VIEW_CONF:
        this.viewCond.responseCond = true;
        this.viewCond.documentationCond = true;
        break;
    }
  }

  initValidators() {
    this.newDialogueForm = this.formBuilder.group(
      this.createFormControlsConfig(),
    )
    // this.onChangeForm();
  }

  setParamForm(param: FunParamList[]) {
    this.paramForm = this.formBuilder.group(
      this.createDynamicParam(param),
    );
    this.updateStateOnParamChangeForm();
  }

  modalOpen(type, outcome) {
    this.outcome = outcome;
    this.modalType = type;
    console.error(this.outcome, this.modalType)
    this.modal.open();
  }

  modalClose() {
    this.outcome = null;
    this.modalType = null;
    this.modal.close();
    // this.modalError='';
  }

  createDynamicParam(param: FunParamList[]) {
    if (this.subscriptions[2]) {
      this.subscriptions[2].unsubscribe();
      this.subscriptions[2] = null;
    }
    let controls = {};
    let parameters: FunParamList[] = param.map(
      x => {
        if (x.listCode) {
          x.listCode = x.listCode.split(";");
        }

        if (x.listValue) {
          x.listValue = x.listValue.split(";");
        }
        return { ...x, label: '' }
      });

    this.dynamicParameters = [];

    let index = 0;

    for (let i = 1; i <= parameters.length; i++) {
      let validators = [];
      index = i - 1;
      if (parameters[index].required) validators.push(Validators.required);
      if (parameters[index].maxLength) validators.push(Validators.maxLength(parameters[index].maxLength));

      (controls as any)['parameter' + i] = new UntypedFormControl('', validators);
      parameters[index].label = 'parameter' + i;

    }

    while (parameters.length) this.dynamicParameters.push(parameters.splice(0, 3));
    return controls;
  }

  createFormControlsConfig() {
    //TODO
    //chiamata che valorizza l'array parameters paramObj
    // let array: string[] = stringa.split(';');
    let controlsConfig = {
      idClnotifcard: [],
      idHistoryStatus: [],
      idFunStatusSelected: [],
      note: [],
      claimNumberPass: [],
      newDialogue: [],
      externalIdentifyDamageParty: [],
      idTypeLoss: [],
      licensePlateUs: [],
      insuranceCodeUs: [],
      licensePlateOther: [],
      insuranceCodeOther: [],
      lossDate: [],
      tinVatDamageParty: [],
      roleCompanyUs: [],
      idDialogueSubject: [],
      dialogueMsgCode: [],
      dialogueMsgDescription: [],
      response: [],

      dExpCompany: []

      // documents: Document[];
    }

    this.controlsConfig = controlsConfig;
    return controlsConfig;
  }

  createLocalParams() {
    let localParam: ParameterFieldRecord[] = [];

    if(!!this.dynamicParameters) {
      this.dynamicParameters.forEach((paramRow) => {
        let row = [];
        paramRow.forEach(par => {
          if (par.defualtValue) {
            row.push({
              parameterCode: par.paramCode,
              parameter: par.paramLabel,
              value: par.defualtValue,
              type: par.typeValue
            });
          }
        });

        localParam = [...localParam, ...row];
      })
    }

    this.newDialogueForm.controls.response.setValue(localParam);
  }

  pushDocuments() {
    let documents = [];

    if(!!this.selectedDocuments) {
      this.selectedDocuments.forEach((docu) => {
        documents.push({
          idDocInfo: docu.docCategory,
          idDocument: docu.docId,
          fileName: docu.actorName
        });
      })
    }

    return documents;
  }

  save() {
    // this.unsubscribeState();
    // if (this.paramForm.valid) {
      let documents = this.pushDocuments();
      let param = this.createLocalParams();
      this.emitter.broadcastEvent('start-loader');
      if(this.viewConfig !=  NewDialogueViewConfiguration.RESPONSE_VIEW_CONF){
        this.newDialogueForm.controls.newDialogue.setValue(true);
      }
      this.newDialogueState.saveDialogue$(documents,param,this.msgId);
    // } else {
    //   this.errorMessage = this.translate.instant('Invalid parameter form');
    // }
  }

  updateStateOnChangeForm() {
    this.subscriptions.push(this.newDialogueForm.valueChanges.subscribe((res) => {
      this.newDialogueState.updateFormSearch$(res);
    })
    )
  }

  updateStateOnParamChangeForm() {
    this.subscriptions.push(this.paramForm.valueChanges.subscribe((res) => {
      this.newDialogueState.updateFormSearch$(res);
    }))
  }

  unsubscribeState() {
    this.subscriptions.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
        sub = null;
      }
    })
  }

  onCheckboxChange(rowData: any, state: CausaliBySinistroList[]) {
    if (this.lastCheckedIndex != undefined) {
      state[this.lastCheckedIndex].selected = false;
    }
    this.lastCheckedIndex = rowData.index;

    this.newDialogueForm.controls.idTypeLoss.setValue(rowData.row.idTypeLoss);
    this.newDialogueForm.controls.licensePlateUs.setValue(rowData.row.licensePlateUs);
    this.newDialogueForm.controls.tinVatDamageParty.setValue(rowData.row.tinVatDamageParty)

    this.retrieveLastTableData(rowData.index);
  }

  retrieveLastTableData(index: number) {
    this.newDialogueState.popolateLastTableData$(index);
  }


  retrieveCascadeData() {
    if(this.localObject.viewConfig == NewDialogueViewConfiguration.RESPONSE_VIEW_CONF){
      if(this.newDialogueForm.controls.idFunStatusSelected.value){
        this.setDescStatusValue();
        this.newDialogueState.responsePopolateCascadeSections$(this.msgId);
      } else {
        this.dynamicParameters = null;
      }
    } else {
      this.newDialogueState.popolateCascadeSections$(this.msgId);
    }
  }

  goBack() {
    if(this.localObject.viewConfig == NewDialogueViewConfiguration.RESPONSE_VIEW_CONF){
      this.newDialogueState.goBackToSummary$();
    } else {
      this.newDialogueState.goBack();
    }
  }

}




import { NgModule } from '@angular/core';
import { CardAnalyticsHomeComponent } from './card-analytics-home/card-analytics-home.component';
import { CardAnalyticsDashboardComponent } from './card-analytics-dashboard/card-analytics-dashboard.component';
import {RgiRxRoutingModule} from '@rgi/rx/router';
import {CARD_ANALYTICS_ROUTES} from './card-analytics.routes';
import {
    RgiRxDragDropModule,
    RgiRxFormElementsModule,
    RgiRxLoadingModule,
    RgiRxMenuModule,
    RgiRxModalModule,
    RgiRxMultiselectModule,
    RgiRxPanelModule,
    RgiRxSnackbarModule,
    RgiRxSwitchModule
} from '@rgi/rx/ui';
import {CommonModule} from '@angular/common';
import {RgiRxAutoCompleteModule} from '@rgi/rx/ui';
import {RgiRxExpansionModule} from '@rgi/rx/ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CardAnalyticsNewReportComponent } from './card-analytics-new-report/card-analytics-new-report.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { TRANSLATIONS } from './localization/translations';
import { CardAnalyticsEditReportComponent } from './card-analytics-edit-report/card-analytics-edit-report.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AnalyticsInterceptor } from './interceptors/analytics.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ChartFilterPipe } from './pipes/report.elements.pipeline';
import {GridsterModule} from 'angular-gridster2';
import { CopyReportModalComponent } from './modals/copy-report-modal/copy-report-modal.component';
import { CopyReportModalConfirmComponent } from './modals/copy-report-modal-confirm/copy-report-modal-confirm.component';
import { DeleteReportModalComponent } from './modals/delete-report-modal/delete-report-modal.component';
import { DeleteReportModalConfirmComponent } from './modals/delete-report-modal-confirm/delete-report-modal-confirm.component';
import { UpdateReportModalComponent } from './modals/update-report-modal/update-report-modal.component';
import {ReportSortPipe} from './pipes/report.sort.pipe';
import { AnalyticsReportItemComponent } from './components/analytics-report-item/analytics-report-item.component';
import {RgiRxHttpModule} from '@rgi/rx/http';

@NgModule({
    declarations: [CardAnalyticsHomeComponent, CardAnalyticsDashboardComponent, CardAnalyticsNewReportComponent,
        CardAnalyticsEditReportComponent, ChartFilterPipe, ReportSortPipe, CopyReportModalComponent, CopyReportModalConfirmComponent,
        DeleteReportModalComponent, DeleteReportModalConfirmComponent, UpdateReportModalComponent, AnalyticsReportItemComponent],
    imports: [
        RgiRxAutoCompleteModule,
        RgiRxExpansionModule,
        RgiRxRoutingModule.forRoot(CARD_ANALYTICS_ROUTES),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RgiRxHttpModule,
        RgiRxI18nModule.forRoot(TRANSLATIONS),
        RgiRxLoadingModule,
        GridsterModule,
        RgiRxDragDropModule,
        RgiRxSnackbarModule,
        RgiRxPanelModule,
        DragDropModule,
        RgiRxModalModule,
        RgiRxFormElementsModule,
        RgiRxAutoCompleteModule,
        RgiRxSwitchModule,
        RgiRxMenuModule,
        RgiRxMultiselectModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AnalyticsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ]
})
export class AnalyticsModule { }

import {Component, Inject, OnInit} from '@angular/core';
import {
  RePostSalesCancellationConfirmationState,
  RePostsalesStateManagerCancellationConfirmationService
} from '../../re-postsale-state-manager/re-postsales-state-manager-cancellation-confirmation.service';
import {ActiveRoute} from '@rgi/rx/router';

@Component({
  selector: 're-cancellation-confirmation',
  templateUrl: './re-cancellation-confirmation.component.html',
  styleUrls: ['./re-cancellation-confirmation.component.css']
})
export class ReCancellationConfirmationComponent implements OnInit {
  constructor(public stateManager: RePostsalesStateManagerCancellationConfirmationService<RePostSalesCancellationConfirmationState>,
              private activeRoute: ActiveRoute,
              @Inject('sessionService') private portalSession: any) {
  }

  ngOnInit() {
  }

  close() {
    this.portalSession.remove(this.activeRoute.id);
  }

}

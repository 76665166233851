import {AnagApiResponse} from './anag-api-response';
import {AnagApiParty} from '../anag-domain/anag-api-party';

export class AnagApiSubjects extends AnagApiResponse {
  public output: AnagApiSubjectsOutput;
}

export class AnagApiSubjectsOutput {
  public totalCounter: string;
  public outcome: any;
  public subjects: Array<AnagApiParty>;
}

import { Directive, ElementRef, HostListener, Renderer2, OnInit } from '@angular/core';

import { EMPTY_STR } from '../../../models/consts/lpc-consts';

@Directive({
  selector: '[lpcCustomNumberFormatDirective]'
})
export class CustomNumberFormatDirective implements OnInit {
  element: ElementRef;
  decimalNumber = 2;

  constructor(el: ElementRef, protected renderer: Renderer2) {
    this.element = el;
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    if (this.element.nativeElement.value == null || this.element.nativeElement.value === EMPTY_STR ) {
      this.element.nativeElement.value = '0.0';
    } else if ((this.element.nativeElement.value as string ).startsWith('.')) {
      this.element.nativeElement.value = '0.0';
    } else {
      this.formattingInputNumber();
    }
  }

  @HostListener('keyup', ['$event.key'])
  onkeyup(value) {
    this.element.nativeElement.value = this.element.nativeElement.value.replace(',', '.');
    return true;
  }

  @HostListener('keypress', ['$event'])
  onkeydown(event) {
    const value = event.key;
    const fieldVal = this.getNewString(event);

    const regexForKeyPress = /[\d\.,]/;
    if (regexForKeyPress.test(value)) {
      const regexForValue = /^\d+([\.,]\d{0,2})?$/;
      if (regexForValue.test(fieldVal)) {
        return true;
      }
    }

    return false;
  }

  getNewString(event) {
    const valoreAttualeInput = event.srcElement.value;
    const key = event.key;
    const splitValoreAttualeInput = valoreAttualeInput.split(EMPTY_STR);
    splitValoreAttualeInput.splice(event.target.selectionStart, (event.target.selectionEnd - event.target.selectionStart), key);
    const nuovoValore = splitValoreAttualeInput.join(EMPTY_STR);
    return nuovoValore;
  }

  ngOnInit() {
    /*
      TODO ho inserito il timer perchè la modale si apriva sovrascrivendo i valori formattati
    */
    setTimeout(() => {
      if (this.element.nativeElement.value !== null && this.element.nativeElement.value !== '-1' &&
      this.element.nativeElement.value !== EMPTY_STR) {
        this.formattingInputNumber();
      } else if (this.element.nativeElement.value === 0) {
        this.element.nativeElement.value = '0.0';
      }
    }, 10);
  }

  formattingInputNumber() {
    this.element.nativeElement.value = this.element.nativeElement.value.replace(',', '.');
    if (this.element.nativeElement.value === 0) {
      this.element.nativeElement.value = '0.0';
    }
    console.log(this.element.nativeElement.value , 'value');
  }


}

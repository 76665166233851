import { ApiActivityDetailsInput } from './api-activity-details-input';

export class ApiActivityDetailsRequest {
    activityDetailsInput: ApiActivityDetailsInput;

    constructor() {
        this.activityDetailsInput = new ApiActivityDetailsInput();
    }
}

/**
 * export duplicate declaration that was publicly exported
 */
export class ActivityDetailsRequest extends ApiActivityDetailsRequest {}

import { Directive, Input, HostListener, ElementRef } from '@angular/core';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';

@Directive({
  selector: '[appFormatDisplayValue]'
})
export class FormatDisplayValueDirective {
  @Input('appFormatDisplayValue') formatOptions: Intl.NumberFormatOptions;

  constructor(private el: ElementRef, protected rgiNumberFormatter: RgiCountryLayerNumberFormatPipe) {}

  @HostListener('blur', ['$event.target.value'])
  onInput(value: string) {
    const normalizedValue = this.normalizeDecimalSeparator(value);
    const formattedValue = this.formatDisplayValue(normalizedValue, this.formatOptions);
    this.el.nativeElement.value = formattedValue;
  }

  formatDisplayValue(value: string, options: Intl.NumberFormatOptions): string {
    const num = Number(value.trim());
    if (isNaN(num)) return value; // Return the original value if it's not a number
    const formattedValue = this.rgiNumberFormatter.transform(num, '', options);
    return formattedValue;
  }

  normalizeDecimalSeparator(value: string): string {
    // Remove all separators except the last one, which we assume to be the decimal
    let separator = ''; // This will hold the last separator found
    let parts = value.split('').reverse(); // Reverse to start checking from the end
  
    for (let i = 0; i < parts.length; i++) {
      if (parts[i] === ',' || parts[i] === '.') {
        if (!separator) {
          // The first separator we find (from the end) is assumed to be the decimal
          separator = parts[i];
          parts[i] = '.'; // Standardize on dot for decimal
        } else {
          // Remove all other separators that are not the assumed decimal
          parts.splice(i, 1);
          i--; // Adjust index to continue correctly after splice
        }
      }
    }
  
    return parts.reverse().join(''); // Reverse back to original order and join to form the normalized number
  }

}
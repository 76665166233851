import {Metadata} from './metadata';
import {GenericEntity} from "../../../../../resources/models/generic-entity";

export class DocumentFile {

  id: string;
  name: string;
  userIns: GenericEntity;
  signed: boolean;
  downloaded: boolean;
  signatureDate: Date;
  validate: boolean;
  note: string;
  verifyDate: Date;
  tokenSign: string;
  url: string;
  idDocDMS: string;
  metadataList: Array<Metadata>;
  body: any;
  deliveryTime: string;// o data a seconda di come arriva dal be
  uploadTime: string;// o data a seconda di come arriva dal be

}

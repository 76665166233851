import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[claimsDragAndDrop]'
})
export class DragAndDropDirective {
  @Output() dndDrop = new EventEmitter<DragEvent>();
  @Output() dndDragover = new EventEmitter<DragEvent>();
  @Output() dndDragleave = new EventEmitter<DragEvent>();
  @Output() dndDragend = new EventEmitter<DragEvent>();
  @Output() dndDrag = new EventEmitter<DragEvent>();
  @Output() dndDragenter = new EventEmitter<DragEvent>();
  @Output() dndDragexit = new EventEmitter<Event>();
  @Output() dndDragstart = new EventEmitter<Event>();

  constructor() {
  }

  @HostListener('dragexit', ['$event']) onDragExit(evt: Event) {
    this.dndDragexit.emit(evt);
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(evt: DragEvent) {
    this.dndDragenter.emit(evt);
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragstart', ['$event']) onDragStart(evt: DragEvent) {
    this.dndDragstart.emit(evt);
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragend', ['$event']) onDragEnd(evt: DragEvent) {
    this.dndDragend.emit(evt);
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drag', ['$event']) onDrag(evt: DragEvent) {
    this.dndDragover.emit(evt);
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    this.dndDragover.emit(evt);
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt: DragEvent) {
    this.dndDragleave.emit(evt);
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event']) onDrop(evt: DragEvent) {
    this.dndDrop.emit(evt);
    evt.preventDefault();
    evt.stopPropagation();
  }

}

export class EquipmentType {

  constructor(
    public equipmentCode: string,
    public glossaryCode: string,
    public updatingDate: Date,
    public creationDate: Date,
    public description: string
  ) {
  }
}

<div style="font-family: 'DINProRegular' !important">
  <rgi-rx-panel
    style="margin-top:6%;display: inline-block; padding-bottom: 4vh; font-family:'DINProRegular' !important">
    <rgi-rx-panel-header [closeable]="true" (onClose)="onCloseModalUploadDocument(); ">
      <!-- span per icona -->
      <span style="font-family: DINProRegular, Arial, sans-serif !important">
        {{ '_CLAIMS_._NEW_UPLOAD' | translate }} {{this.utilityService.getSysPropShowParties() ?
        this.party.subject.surname ? this.party.subject.givenName + ' ' +
        this.party.subject.surname : this.party.subject.designation : ''}}, {{this.category.elementDescription}} </span>
    </rgi-rx-panel-header>
    <form [formGroup]="uploadForm" style="margin-bottom: 2vh;  font-family: 'DINProRegular' !important">

      <!--file upload-->
      <div class="col-xs-12" (drop)="drop($event)" (dragover)="allowDrop($event)">

        <input type="file" id="file" name="file" class="inputfile"
          style="width: 0.1px; height: 0.1px; position: absolute; z-index: -1; overflow: hidden; opacity: 0"
          (change)="onFileUploaded($event)">
        <!--            <label for="file" style="width: 100%; height:16vh; background-color: #f5f9fd">-->
        <label for="file"
          style="width: 100%; height:16vh; font-size:16px; background-color: #f5f9fd;
                 border:1px dotted black; display: flex; flex-direction: column; justify-content: center; align-items: center">
          <div class="col-xs-5 clearfix" style="display: flex; flex-direction: column; margin:auto">
            <div class="col-xs-1" style="margin: auto">
              <span class="box-upload-icon glyphicon glyphicon-cloud-upload" style="scale:1.4"></span>
            </div>
            <div class="col-xs-12" style="text-align: center">
              {{ '_CLAIMS_._MESSAGE_._DRAG_A_FILE_TO_UPLOAD' | translate }}
            </div>
            <div class="col-xs-12-" style="text-align: center">
              <span style="font-weight: 700">{{ '_CLAIMS_._CLICK_HERE' | translate }}</span> {{ '_CLAIMS_._TO_SELECT' |
              translate }}
            </div>
          </div>

        </label>
      </div>

      <!-- 1st row : subcat + numpages-->
      <div class="col-xs-6" *ngIf="category.subcategories && category.subcategories.length > 0">

        <rgi-rx-form-field>
          <label rgiRxLabel>{{ '_CLAIMS_._SELECT_SUBCATEGORY' | translate }}</label>
          <select rgiRxNativeSelect name="subcategory" formControlName="subcategory" required>
            <option
              *ngIf="this.category && this.subcategory &&  this.category?.elementId == this.subcategory?.elementId"
              [value]="this.subcategory?.elementId">{{this.subcategory.elementDescription}}</option>
            <option *ngIf="this.category && this.subcategory && this.subcategory.elementId != this.category.elementId"
              [value]="this.subcategory?.elementId">{{this.subcategory.elementDescription}}
            </option>
            <option *ngFor="let subcat of getValidSubcategories(this.category)" [value]="subcat.elementId">
              {{subcat.elementDescription}}</option>
          </select>
        </rgi-rx-form-field>
      </div>

      <div class="col-xs-6">
        <label rgiRxLabel style="font-family: DINProRegular, Arial, sans-serif !important">{{
          '_CLAIMS_._NUMBER_OF_PAGES' | translate }}</label>
        <input type="number" class="form-control" name="numPages" formControlName="numPages"
          attr.data-qa="documentupload-numpages-input">
      </div>
      <!-- 2nd row : notes + subtitle-->
      <div class="col-xs-6">
        <label rgiRxLabel style="font-family: DINProRegular, Arial, sans-serif !important">{{ '_CLAIMS_._NOTES' |
          translate }} </label>
        <input type="text" class="form-control" name="uploadNote" formControlName="uploadNote"
          attr.data-qa="documentupload-upload-note-input">

      </div>
      <div class="col-xs-6">
        <label rgiRxLabel style="font-family: DINProRegular, Arial, sans-serif !important">{{ '_CLAIMS_._SUBTITLE' |
          translate }} </label>
        <input type="text" class="form-control" name="code" formControlName="subtitle"
          attr.data-qa="documentupload-subtitle-input">

      </div>
      <!-- 3rd row : asset type-->
      <div class="col-xs-6">
        <rgi-rx-form-field>
          <label rgiRxLabel style="font-family: DINProRegular, Arial, sans-serif !important">{{ '_CLAIMS_._ASSET_TYPE' |
            translate }}</label>
          <select rgiRxNativeSelect name="assetType" style="font-family: DINProRegular, Arial, sans-serif !important"
            formControlName="assetType" required="{{this.uploadForm.controls.toSendEBDS.value}}">

            <option value=""></option>
            <option value="1">{{ '_CLAIMS_._VEICHLE' | translate }}</option>
            <option value="2">{{ '_CLAIMS_._PEOPLE' | translate }}</option>
            <option value="3">{{ '_CLAIMS_._THINGS' | translate }}</option>
          </select>
        </rgi-rx-form-field>
      </div>
      <!-- <div class="col-xs-6">
           <rgi-rx-form-field>
             <label rgiRxLabel>{{ 'Reception date' | translate}}</label>
             <rgi-rx-date-picker [openOnClick]="false">
               <input [rgiRxDateTimeInput]="receptionDate" formControlName="dateReceipt"
                      data-qa="rgi-update-reserve-reopen-date" placeholder="{{datepickerPlaceholder}}"
                      [max]="today">
             </rgi-rx-date-picker>
             <rgi-rx-date-time #receptionDate></rgi-rx-date-time>
           </rgi-rx-form-field>
         </div>-->
      <!--CHECKBOXES , EBDS, NEW ALERT-->

      <!--          <div class="col-xs-12" style="margin-top: 0.5vh; margin-bottom: 0.5vh">
                    <rgi-rx-form-field>
                      <input type="checkbox" rgiRxInput formControlName="toSendEBDS">
                      <label rgiRxLabel>{{ 'Send to EBDS' | translate}}</label>
                    </rgi-rx-form-field>
                  </div>-->
      <div class="col-xs-12" style="margin-top: 0.5vh; margin-bottom: 0.5vh">
        <rgi-rx-form-field>
          <input type="checkbox" rgiRxInput formControlName="alertNewDoc">
          <label style="font-family: DINProRegular, Arial, sans-serif !important" rgiRxLabel>{{
            '_CLAIMS_._MESSAGE_._ALERT_NEW_DOC' | translate }}</label>
        </rgi-rx-form-field>
      </div>
      <!--if the category requires it, show reception date required-->
      <div class="col-xs-6" style="margin-bottom: 1vh"
        *ngIf="this.isReceptionDateRequired || this.isReceptionDateForLegal">
        <form [formGroup]="receiptDateForm">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_CLAIMS_._MESSAGE_._RECEIIPT_DATE' | translate }}</label>
            <rgi-rx-date-picker [openOnClick]="true" [showRemoveButton]="true">
              <input [rgiRxDateTimeInput]="picker2" (keydown)="handleKeyDown($event)"
                formControlName="receiptDatePickerFormField" [max]="getMaxDay()" [selectMode]="'single'"
                (dateTimeChange)="onDateTimeChange($event)" (dateTimeInput)="onDateTimeChange($event)">
            </rgi-rx-date-picker>
            <rgi-rx-date-time #picker2 [startView]="'month'" [pickerType]="'calendar'"></rgi-rx-date-time>
          </rgi-rx-form-field>
        </form>
      </div>
      <!--if there are associated damage requests-->
      <!-- && filteredDamageRequests.length > 0-->

      <div class="col-xs-12" style="margin-bottom: 2vh;"
        *ngIf="this.isDamageRequestCategory && this.filteredDamageRequests.length > 0">
        <!--title-->
        <div class="col-xs-12 linked-damages-title " style="display: flex; justify-content: center; align-items: center;
                font-family: DINPro-Bold; background-color: #abafb1; margin-right: 1vh; color: white; height: 40px;">
          {{ '_CLAIMS_._MESSAGE_._EXISTING_REQUEST_FOR_DAMAGES_TO_MATCH' | translate }}
        </div>
        <!--nessuna richiesta danni-->
        <div class="col-xs-12 linked-damages-element"
          style="display: flex; background-color: #dadada; font-family: DINPro-Medium; margin-right: 1vh; height: 65px;">
          <span style="display: flex; align-items: center"><rgi-rx-form-field style="display: inline-flex">
              <input type="radio" [value]="''" rgiRxInput formControlName="linkedDamage">
            </rgi-rx-form-field>
            {{ '_CLAIMS_._MESSAGE_._NO_REQUEST_DAMAGES' | translate }}
            </span>
        </div>

        <div class="col-xs-12 linked-damages-element" style="display: flex;
          background-color: #ebeff3; color: #686868; font-family: DINPro-Bold; height: 65px; "
          *ngFor="let damage of this.filteredDamageRequests">
          <span style="display: flex; align-items: center">
            <rgi-rx-form-field style="display: inline-flex">
              <input [value]="damage.idClaimOfDamages" type="radio" rgiRxInput formControlName="linkedDamage">
            </rgi-rx-form-field>
            {{ '_CLAIMS_._MESSAGE_._REQUEST_FOR_DAMAGES_RECEIPT_DATE' | translate }}
            : {{damage.receiptDate}}
          </span>
        </div>
      </div>

      <!--show uploaded files-->
      <!-- <span style="padding:1vh" class="rgifont rgi-doc-file-format"></span>-->
      <div class="col-xs-12" *ngFor="let doc of this.uploadedFiles" style="height:8vh; margin-top:5px; margin-bottom: 5px; display: flex; align-items: center;
             border: 1px solid grey; border-radius: 0.35vh">
        <div class="col-xs-1 rgifont rgi-doc-file-format"
          style="color:#209ef6; scale:1.5; display: flex; justify-content: center"></div>
        <div class="col-xs-10" style="font-size: 17px; font-family: DINPro-Bold">{{doc.name}}</div>
        <div class="col-xs-1">
          <button (click)="onFileRemoved(doc)"><span class="rgifont rgi-trash upload-doc-font"></span></button>
        </div>
      </div>
    </form>
    <rgi-rx-panel-footer style="display: inline;">
      <!--!this.uploadForm.controls.subcategory.value)
        (!this.uploadForm.controls.receiptDateFormatted || !this.uploadForm.controls.receiptDateFormatted.value ||
                this.uploadForm.controls.receiptDateFormatted.value === ''))
                -->
      <button rgi-rx-button color="primary" [disabled]="(!this.uploadedFiles  ||
                 !this.uploadedFiles.length ||
                (
                (this.isReceptionDateRequired || this.isReceptionDateForLegal)
                 && !this.isReceptionDateValid)
                  ||
                (this.uploadForm.controls.toSendEBDS.value == true && (!this.uploadForm.controls.assetType.value
                || this.uploadForm.controls.assetType.value === '')))" (click)="onConfirmUploadFiles()">
                {{'_CLAIMS_._BUTTONS_._UPLOAD' | translate }}</button>

    </rgi-rx-panel-footer>
    <!--DELETE BUTTON-->
  </rgi-rx-panel>
</div>
<div>
    <!-- <div *ngIf="!pes || pesLoaded"> -->
    <claims-stepper [currentStep]="currentStep" [labels]="stepperLabels"></claims-stepper>

    <div class="details-container">

        <br>
        <claims-policy-detail-panel-content [policy]=policy [card]="card" [damageCategory]="damageCategory"
            *ngIf="!policy.isDummyPolicy">
        </claims-policy-detail-panel-content>
        <br>

        <pnc-accordion [closeOthers]="false" [destroyOnHide]="false" #pncAccordion="AccordionComponent">
            <pnc-panel id="occurence-data">
                <ng-template pncPanelTitle class="panel-header">
                    <claims-editable-panel-title enabled="true" title="{{'_CLAIMS_._OCCURRENCE_DATA' | translate}}" panelId="occurence-data"
                        hideModifyBeforeComplete="true" hideModifyOnDisable="true" [pncAccordion]="pncAccordion">
                    </claims-editable-panel-title>
                </ng-template>
                <ng-template pncPanelContent>
                    <claims-occurence-data-panel-content panelId="occurence-data" [card]="card" nextPanelId="party-data"
                        acceptSignals="true" (stepperEventEmitter)="progress($event)"
                        [occurrenceData]="occurrenceData" [damageCategory]="damageCategory"
                        (editDone)="onEditDone($event)" [pes]="pes">
                    </claims-occurence-data-panel-content>
                </ng-template>
            </pnc-panel>

            <pnc-panel id="party-data" disabled="true">
                <ng-template pncPanelTitle class="panel-header">
                    <claims-editable-panel-title title= "{{'_CLAIMS_._DAMAGED__INVOLVED_ITEM_DATA' | translate}}" panelId="party-data"
                        hideModifyOnDisable="true" [pncAccordion]="pncAccordion">
                    </claims-editable-panel-title>
                </ng-template>
                <ng-template pncPanelContent>
                    <claims-party-data-panel-content panelId="party-data" [card]="card" nextPanelId="damage-category" *ngIf="!enableMultiDamage"
                        (stepperEventEmitter)="progress($event)" [bodywork]="bodywork" [driver]="driver"
                        [bodyworkIntegrationObj]="bodyworkIntegrationObj" acceptSignals="true"
                        [partyData]="partyData" [damageCategory]="damageCategory"
                        (selectedAssetEmitter)="assetSelected($event)" (editDone)="onEditDone($event)" [pes]="pes"
                        [third]="third" [enableMultiDamage]="enableMultiDamage" (selectedThirdPartyEmitter)="onSelectedThirdParty($event)">
                    </claims-party-data-panel-content>
                    <claims-party-data-panel-content panelId="party-data" [card]="card" nextPanelId="document-management" *ngIf="enableMultiDamage"
                        (stepperEventEmitter)="progress($event)" [bodywork]="bodywork" [driver]="driver"
                        [damageCategoriesData]="damageCategoriesData"
                        [bodyworkIntegrationObj]="bodyworkIntegrationObj" acceptSignals="true"
                        [partyData]="partyData" [damageCategory]="damageCategory"
                        (selectedAssetEmitter)="assetSelected($event)" (editDone)="onEditDone($event)" [pes]="pes"
                        [third]="third" [enableMultiDamage]="enableMultiDamage" (selectedThirdPartyEmitter)="onSelectedThirdParty($event)">
                    </claims-party-data-panel-content>
                </ng-template>
            </pnc-panel>

            <pnc-panel disabled="true" id="damage-category">
                <ng-template pncPanelTitle class="panel-header">
                    <claims-editable-panel-title title="{{'_CLAIMS_._TYPE_OF_LOSS' | translate}}" panelId="damage-category"
                        hideModifyOnDisable="true" [pncAccordion]="pncAccordion">
                    </claims-editable-panel-title>
                </ng-template>
                <ng-template pncPanelContent>
                    <claims-damage-category-panel-content panelId="damage-category" [card]="card"
                        nextPanelId="document-management" (stepperEventEmitter)="progress($event)" acceptSignals="true"
                        [damageCategoriesData]="damageCategoriesData"
                        [selectedAsset]="selectedAsset" (editDone)="onEditDone($event)" [pes]="pes"
                        [isSelectedThirdParty]="isSelectedThirdParty"
                        [abilitaAperturaSenzaCausale]="abilitaAperturaSenzaCausale" [policy]="policy">
                    </claims-damage-category-panel-content>
                </ng-template>
            </pnc-panel>

            <pnc-panel disabled="true" id="document-management">
                <ng-template pncPanelTitle class="panel-header">
                    <claims-editable-panel-title title="{{'_CLAIMS_._DOCUMENT_MANAGEMENT' | translate}}" panelId="document-management"
                        hideModifyOnDisable="true" [pncAccordion]="pncAccordion">
                    </claims-editable-panel-title>
                </ng-template>
                <ng-template pncPanelContent>
                    <claims-document-management-panel-content panelId="document-management" [card]="card"
                        (panelCompleted)="onPanelCompleted($event)" acceptSignals="true"
                        [policy]="policy" [documentsData]="documentsData" [pes]="pes" (editDone)="onEditDone($event)">
                    </claims-document-management-panel-content>
                </ng-template>
            </pnc-panel>
        </pnc-accordion>
    </div>

    <div class="btn-group btn-group-justified">
        <div class="btn-group" *ngIf="isEditPes()">
            <button (click)="cancel()" class="btn btn-warning text-uppercase">{{ '_CLAIMS_._BUTTONS_._CANCEL' | translate }}</button>
        </div>
        <div class="btn-group">
            <button (click)="save()" class="btn btn-warning text-uppercase"
                [disabled]="saveDisabled || (isEditPes() && !editDone)">{{ labelSave }}</button>
        </div>
    </div>
</div>

import {StartCardProduct} from "../domain-models/start-card-product";

export class GenericEntityPcPortfolio {
  additionalLabels: any;
  code = '';
  description: string;
}

export class ProductsObject {
  public products: Array<StartCardProduct> = [];
  public productsType: Array<StartCardProduct> = [];
}

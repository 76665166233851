<div class="col-12 life-detail-margin-box life-detail-box" [attr.id]="dataQa">
  <table class="table-responsive table coreDT life-detail-margin-top">
    <thead>
      <tr class="head-result thDataTable life-detail-tbld_row_header">
        <th *ngFor="let header of headers" translate>{{ 'lpc_' + header }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="table-row table-background" *ngFor="let item of data">
        <td class="table-border" [attr.data-qa]="dataQa + '-cell-' + item.key" *ngFor="let value of item | keyvalue; let i = index">
         {{ item[headers[i]] | dataValueFormatterPipe }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

import {Component, ComponentRef, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ClaimDetail} from '../../models/domain-models/claim-detail';
import {GenericEntity} from '../../models/domain-models/generic-entity';
import {ProposalService} from '../../proposal.service';
import {InsuranceBackgroundService} from '../../insurance-background/insurance-background.service';
import {Modal} from '../../modal';
import {ClaimsData} from '../claims-data';
import {RiskCertificateService} from '../risk-certificate-service';
import {Subscription} from 'rxjs';
import {Message} from '../../models/message';

@Component({
  selector: 'mic-claim-modal',
  templateUrl: './claim-modal.component.html',
  styleUrls: ['claim-modal.component.scss']
})
export class ClaimModalComponent implements Modal, OnInit, OnDestroy {

  claimsData: ClaimsData;

  @ViewChild('claimModal', {read: ViewContainerRef, static: true}) entry: ViewContainerRef;

  validationMessages: Message[] = new Array<Message>();
  claimModalForm: any;
  componentRef: ComponentRef<any>;
  messagesSubscription: Subscription;

  areaCode = 'CLAIMS';

  constructor(
    protected proposalService: ProposalService,
    protected insuranceBackgroundService: InsuranceBackgroundService,
    protected formBuilder: UntypedFormBuilder,
    protected riskCertificateService: RiskCertificateService) {
  }

  ngOnInit() {
    this.claimsData = this.riskCertificateService.getClaimsData();

    this.claimModalForm = this.formBuilder.group(
      {
        year: [this.claimsData.isNewClaim ? this.claimsData.yearList[0] : this.claimsData.selectedClaimDetails.year, Validators.required],
        claimNumber: [this.claimsData.isNewClaim ? '' : this.claimsData.selectedClaimDetails.claimNumber],
        responsabilityPortion: [this.claimsData.isNewClaim ? '' :
          this.claimsData.selectedClaimDetails.responsabilityPortion,
          [Validators.required, Validators.max(50), Validators.min(0)]],
        malus: [this.claimsData.isNewClaim ? false : this.claimsData.selectedClaimDetails.malus],
        damageType: [this.claimsData.isNewClaim ? '' : (this.claimsData.selectedClaimDetails.damageType
          ? this.claimsData.selectedClaimDetails.damageType.id : ''),
          this.claimsData.isNewClaim ? (this.claimModalForm && this.claimModalForm.year && this.claimModalForm.year.value >= 2015
            ? Validators.required : null) : this.claimsData.insuranceStatus.claimDetails.find(
            x => x.id === this.claimsData.selectedClaimDetails.id).year >= 2015 ? Validators.required : null],
        description: [this.claimsData.isNewClaim ? '' : this.claimsData.selectedClaimDetails.description]
      }
    );
    this.funzioneOnChange(this.claimModalForm.year);
  }

  onSubmit() {
    Object.keys(this.claimModalForm.controls).forEach(field => {
      this.claimModalForm.get(field).markAsDirty();
    });

    if (this.claimsData.selectedClaimDetails.year) {
      this.funzioneOnChange(String(this.claimsData.selectedClaimDetails.year));
    } else {
      this.funzioneOnChange(String(this.claimModalForm.controls.year.value));
    }
    this.claimModalForm.controls.damageType.updateValueAndValidity();

    this.validateForm();
    if (this.validationMessages.length === 0) {
      let select: ClaimDetail = this.claimsData.selectedClaimDetails;

      if (!this.claimsData.isNewClaim) {
        this.claimsData.insuranceStatus.claimDetails.forEach(claim => {
          select = this.claimsData.insuranceStatus.claimDetails.find(
            x => x.id === this.claimsData.selectedClaimDetails.id);
        });
      }

      this.mapValue(select);

      if (this.claimsData.isNewClaim) {
        select.year = this.claimModalForm.controls.year.value;
        select.id = -1;
        for (const claimDetails of this.claimsData.insuranceStatus.claimDetails) {
          if (select.id > claimDetails.id) {
            select.id = claimDetails.id;
          }
        }
        select.id = select.id - 1;
        select.claimNumber = null;
        this.claimsData.insuranceStatus.claimDetails.push(select);
      }
      const dateOfBirth = this.proposalService.getDateOfBirth();
      this.insuranceBackgroundService.updateInsuranceStatus(this.proposalService.getContractId(),
        this.claimsData.insuranceStatus, dateOfBirth).subscribe(
        (data) => {
          this.insuranceBackgroundService.setCorrectIdOnCompany(data);
          this.insuranceBackgroundService.setInsuranceStatus(data);
          this.cancel();
        }
      );
    }
  }

  funzioneOnChange(valore: string) {
    if (valore && Number(valore) >= 2015) {
      this.claimModalForm.controls.damageType.setValidators([Validators.required]);
    } else {
      this.claimModalForm.controls.damageType.clearValidators();
    }
  }

  mapValue(select: ClaimDetail) {
    if (this.claimModalForm.controls.description) {
      select.description = this.claimModalForm.controls.description.value;
    }
    select.responsabilityPortion = this.claimModalForm.controls.responsabilityPortion.value;
    select.malus = this.claimModalForm.controls.malus.value;
    select.damageType = this.claimsData.damageTypeList[this.claimModalForm.controls.damageType.value - 1];
  }

  cancel() {
    this.componentRef.destroy();
  }

  validateForm() {

    const controls = this.claimModalForm.controls;

    this.validationMessages.length = 0;

    if (controls.responsabilityPortion.errors && controls.responsabilityPortion.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Responsability Portion is mandatory'));
    }
    if (controls.responsabilityPortion.errors && controls.responsabilityPortion.errors.max) {
      this.validationMessages.push(new Message(this.areaCode, 'Responsability Portion cannot be greater than 50%'));
    }
    if (controls.responsabilityPortion.errors && controls.responsabilityPortion.errors.min) {
      this.validationMessages.push(new Message(this.areaCode, 'Responsability Portion cannot be equal to or lower than 0%'));
    }
    if (controls.damageType.errors && controls.damageType.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Damage Type is mandatory'));
    }
  }

  genericEntitiesTrackByFn(index, genericEntity: GenericEntity) {
    return genericEntity.code;
  }

  onChangeMalus() {
    if (this.claimModalForm.controls.malus &&
      this.claimModalForm.controls.malus.value === 'true') {
      this.claimModalForm.controls.malus.value = 'false';
    } else {
      this.claimModalForm.controls.malus.value = 'true';
    }
  }

  ngOnDestroy() {
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
  }

}

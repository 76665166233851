import {Component, Input, OnInit} from '@angular/core';
import {RgiRxDatasource, RgiRxDatatableRowAction, TableSchema} from '@rgi/rx/ui';
import {OutboundDocumentsService} from '../services/outbound-documents.service';
import {OutboundDocument, OutboundDocumentDataSource} from '../resources/model/common/outbound-document';
import {RGI_DOCUMENTS_ACTIONS} from '../resources/constants/actions';

@Component({
  selector: 'rgi-outbound-documents',
  templateUrl: './outbound-documents.component.html'
})
export class OutboundDocumentsComponent implements OnInit {

  @Input() policyNumber: string;
  @Input() proposalNumber: string;
  @Input() movementId: string;
  @Input() movementDesc: string;

  dataSource: RgiRxDatasource<OutboundDocument> = new OutboundDocumentDataSource();
  schema: TableSchema;
  titleParams: any;

  constructor(
    protected documentsService: OutboundDocumentsService,
  ) {
    this.schema = undefined;
  }

  ngOnInit() {
    this.titleParams = {policyNumber: this.policyNumber, proposalNumber: this.proposalNumber, movementDesc: this.movementDesc};
    this.getOutboundDocuments();
  }

  onActionClick($event: RgiRxDatatableRowAction<OutboundDocument>) {
    this.documentsService.onDocumentAction$($event.name, this.proposalNumber, this.movementId, $event.row).subscribe(() => {
      this.getOutboundDocuments();
    });
  }

  getOutboundDocuments() {
    this.documentsService.getOutboundDocumentsDataSource$(this.proposalNumber, this.movementId)
      .subscribe((documents: OutboundDocument[]) => {
        this.dataSource.update(documents);
        this.schema = this.documentsService.getOutboundDocumentsSchema(documents);
    });
  }

  manageSignature(row: OutboundDocument) {
    this.onActionClick({
      name: RGI_DOCUMENTS_ACTIONS.SIGN,
      row: row,
      event: null
    });
  }
}

import { ChangeDetectorRef, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable } from 'rxjs/internal/Observable';
import { concatMap, tap } from 'rxjs/operators';
import { OperationWithFinancialStepComponent } from '../../interfaces/impl/operation-with-financial-step.component';
import { InvestmentAdapterConfig, OperationWithInvestment } from '../../interfaces/operation-with-financial-step';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { RequestFactor } from '../../models/factor.model';
import { InvestmentMode, InvestmentProfileMode, OperationPropertyCode } from '../../models/operation-property-code.enum';
import { Definition, FactorDefinition, OperationProperty } from '../../models/postsales-operations-response.model';
import { LpcFactorAdapterComponent } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import { KarmaFundDefinition } from '../../modules/lpc-karma-funds/model/karma-fund-definition';
import { KarmaProfileDefinition } from '../../modules/lpc-karma-funds/model/karma-profile-definition';
import { VolatilityResponse, VolatilityType } from '../../modules/lpc-karma-funds/model/karma-volatility';
import { KarmaFundService } from '../../modules/lpc-karma-funds/service/karma-fund.service';
import { LpcKarmaFundUtils } from '../../modules/lpc-karma-funds/utils/lpc-karma-fund-utils';
import { AnagService } from '../../services/anag.service';
import { AngularCommunicationService } from '../../services/angular-communication.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { PlcDateUtils } from '../../utils/plc-date-utils';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import {KarmaProfile} from '../../modules/lpc-karma-funds/model/karma-profile';
import {KarmaFund} from '../../modules/lpc-karma-funds/model/karma-fund';
import { LpcCurrencyCache, CurrencyCacheService } from '../../services/currency-cache.service';

const _VMODI = '_VMODI';
// @ts-ignore
const _VRSPA = '_VRSPA';

@Component({
  selector: 'lpc-switch-free-component',
  templateUrl: './switch-free-component.component.html',
  styleUrls: ['./switch-free-component.component.scss']
})
export class SwitchFreeComponentComponent extends OperationWithFinancialStepComponent implements OnInit, OperationWithInvestment {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };
  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumIntegerDigits: 1,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  @ViewChild('factorAdapter') factorAdapter: LpcFactorAdapterComponent;

  // mappa degli amount dei profili che si aggiorna ad ogni chiamata
  public disinvestmentProfileAmountMap = new Map<string, number>();
  public disinvestmentProfileAmountWithPercentageMap = new Map<string, number>();
  public disinvestmentProfiles: KarmaProfileDefinition[] = [];
  public totalInvestedAmount: number;

  private _investmentModeForRequest: number;
  private $investedProfiles: KarmaProfileDefinition[] = [];
  get investedProfiles(): KarmaProfileDefinition[] {
    return this.$investedProfiles;
  }

  protected operationDataKey = 'switch';

  public requestFactor: RequestFactor[] = [];
  public listProductFactor: FactorDefinition[] = [];
  public insuredSettlements: any[] = [];
  public tableDefinitions: Definition[] = [];
  public isFreeManagement: boolean;

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    // protected operations: MockSwitchFreeService,
    protected changeDetector: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authorizationService: AuthService,
    protected angularCommunicationService: AngularCommunicationService,
    protected karmaService: KarmaFundService,
    protected anag: AnagService,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService
  ) {
    super(operations, changeDetector, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authorizationService, anag, karmaService, angularCommunicationService);
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  get hasProductFactors(): boolean {
    return !!this.listProductFactor.length;
  }

  get operationCantBePublish(): boolean {
    return this.errors.some(error => error.severity === 'error');
  }

  public isAFreeManagement() {
    const managementFactor = this.requestFactor.find(x => x.code === '_VMODS');
    this.isFreeManagement = managementFactor && managementFactor.value === '1';
  }

  ngOnInit(): void {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe(result => {
        // RDDL-3548
        if (!!result.definitions.productFactors && !!(result.definitions.productFactors as FactorDefinition[]).length) {
          this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
        }

        this.populateInvestmentForms(result);
      })
    );
  }

  public updateFactors(factors: RequestFactor[]) {
    this.requestFactor = factors;
    this.$subscriptions.push(this.onReload('factors').subscribe((result) => {
      this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
    }));
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      factors: new UntypedFormGroup({}),
      disinvestmentProfiles: new UntypedFormControl(),
      disinvestmentFunds: new UntypedFormGroup({}),
      investmentProfiles: new UntypedFormGroup({}),
      investmentFunds: new UntypedFormGroup({})
    });
  }

  protected getTransformedOperationData(): any {
    let disinvestments = [];
    this.isAFreeManagement();
    if (!!this.formGroup.getRawValue().disinvestmentProfiles && !!this.formGroup.getRawValue().disinvestmentFunds &&
    !!this.disinvestmentProfiles) {
      disinvestments = LpcKarmaFundUtils.getBackendStructureOfProfiles(
        this.disinvestmentProfiles,
        this.formGroup.getRawValue().disinvestmentProfiles,
        this.formGroup.getRawValue().disinvestmentFunds
      );
    }

    let investments = [];
    if (!!this.formGroup.getRawValue().investmentProfiles && !!this.formGroup.getRawValue().investmentFunds &&
    !!this.investmentProfiles) {
      investments = LpcKarmaFundUtils.getBackendStructureOfProfiles(
        this.investmentProfiles,
        this.formGroup.getRawValue().investmentProfiles,
        this.formGroup.getRawValue().investmentFunds
      );
    }
    return {
      disinvestments,
      investments,
      listProductFactor: this.requestFactor,
      volatility: this.volatility
    };
  }

  protected updateDraft(step: string, reload?: boolean, opDataType?: string): Observable<any> {
    if (step === 'factors') {
      this.requestFactor = this.factorAdapter.getRequestFactor();
    }
    return super.updateDraft(step, reload, opDataType).pipe(concatMap(result => {
      this.insuredSettlements = result.data.operationData.data.insuredSettlements;
      this.tableDefinitions = result.definitions.settlement as Definition[];
      this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
      const disinvestmentProfiles: any = result.definitions.disinvestmentProfiles;
      const investedProfiles: any = result.definitions.investedProfiles;
      const investmentFoundsFg = this.formGroup.get('disinvestmentFunds') as UntypedFormGroup;
      if (!reload && !!disinvestmentProfiles && !!disinvestmentProfiles.length) {
        /* const modifiable: any = result.definitions.modifiableFunds;*/
        this.updateProfilesAndFunds(step, result.definitions);
        if (result.definitions) {
          this.recalculateTotalAmount(result.definitions);
        }
        if (investedProfiles && investmentFoundsFg.value) {
          this.calculateInvestedAmount(investedProfiles, investmentFoundsFg.value);
        }
        if (this.canCalculateVolatility(step)) {
          this.onCalculateVolatility();
        }
      }
      return Promise.resolve(result);
    }));
  }



  // ---------------------------------------- INVESTIMENTI ----------------------------------------

  // PROFILI SELEZIONATI
  get selectedDisinvestmentProfiles(): KarmaProfileDefinition[] {
    const profiles: any = this.formGroup.getRawValue().disinvestmentProfiles ? this.formGroup.getRawValue().disinvestmentProfiles : {};
    const profileKeys = Object.keys(profiles).filter(key => !!profiles[key]);
    return this.disinvestmentProfiles.filter(pr => profileKeys.includes(pr.id));
  }

  // FLAG PER SALTARE GLI STEP DI INVESTIMENTI
  public get skipDisinvestment(): boolean {
    return this.disinvestmentProfiles && this.disinvestmentProfiles.length > 0 &&
      this.disinvestmentProfiles.every(
        p => p.maxPercentAllocation === p.minPercentAllocation && p.maxPercentAllocation > 0
      );
  }
  public get skipInvestmentProfiles(): boolean {
    return this.getInvestmentProfilesMode() === InvestmentProfileMode.FIXED || this.profileFixedPercentage;
  }
  public get skipInvestmentFunds(): boolean {
    return this.fundFixedPercentage;
  }

  // configurazioni da passare al componente degli investimenti per gestire le varie customizzazioni
  get getInvestmentProfileConfigurations(): InvestmentAdapterConfig {
    return {
      profileFormGroupName: 'investmentProfiles',
      investmentMode: this.getInvestmentMode(),
      enableVolatility: false,
      prevalIfLengthEqual1: true,
      percentageSumEqual100: true,
      enableSingleElementSelection: false,
      showSliderInput: true,
      showPercentageInput: true,
      showAmountColumnOnViewOnly: true,
      enableOpenListDefault: true
    };
  }

  get getInvestmentFundConfigurations(): InvestmentAdapterConfig {
    return {
      profileFormGroupName: 'investmentProfiles',
      fundFormGroupName: 'investmentFunds',
      investmentMode: this.getInvestmentMode(),
      enableVolatility: this.isWithVolatility(),
      prevalIfLengthEqual1: true,
      percentageSumEqual100: true,
      enableSingleElementSelection: false,
      showSliderInput: true,
      showPercentageInput: true,
      showAmountColumnOnViewOnly: true,
      enableOpenListDefault: true
    };
  }

  public updateProfilesAndFunds(step, definitions) {
    // TODO: allineare agli investimenti
    if (this.showDisinvestment) {
      this.initDisinvestmentProfilesAndFunds(step, definitions);
    }

    // INVESTIMENTI - VISIBILITà STEP & GESTIONE UPDATE
    super.updateProfilesAndFunds(step, definitions);
  }

  public isWithVolatility(): boolean {
    if (this.hasOperationPropertyByCode(OperationPropertyCode.VOLATILITY_CALCULATION)) {
      return  PlcObjectUtils.getBooleanString(
        this.getOperationPropertyByCode(OperationPropertyCode.VOLATILITY_CALCULATION).booleanValue
      );
    }
    return false;
  }

  public calculateVolatility(): Observable<VolatilityResponse> {
    const disinvProfilesValueFromForm = this.getProfilesValueFromForm(this.formGroup, 'disinvestmentProfiles', 'disinvestmentFunds', true);
    const switchAmount = this.isFreeManagement ? this.totalInvestedAmount : this.totalAmountToInvest;
    return this.karmaService.calculateVolatility(
      this.getProfilesValueFromForm(this.formGroup, 'investmentProfiles', 'investmentFunds', true),
      this.mapToProfilesRelativeToDisinvestedAmount(disinvProfilesValueFromForm, switchAmount),
      PlcDateUtils.isoDateTimeToIsoDate(this.formGroup.get('dates').value._1OEFF),
      this.operations.session.productId,
      this.operations.getPolicyNumber(),
      switchAmount,
      this.session.contractor,
      this.operations.session.managementNodeCode,
      this.$session.operation,
      this.getInvestmentModeForRequest(),
      VolatilityType.switchDemand,
      disinvProfilesValueFromForm
    ).pipe(
      tap(r => {
        this.volatility = r.volatility;
        this.extractVolatilityErrors(r);
      })
    );
  }

  private mapToProfilesRelativeToDisinvestedAmount(profilesValueFromForm: KarmaProfile[], switchAmount: number) {
    return profilesValueFromForm.map(profile => this.createProfileRelativeToDisinvestedAmount(profile, switchAmount));
  }

  private createProfileRelativeToDisinvestedAmount(profile: KarmaProfile, amountToDisinvest: number): KarmaProfile {
    const totalDisinvestedForProfile = this.disinvestmentProfileAmountWithPercentageMap.get(profile.id);
    const percentDisinvestedFromProfile = totalDisinvestedForProfile / amountToDisinvest;
    let funds: KarmaFund[];
    if (this.disinvestmentProfiles.every(p =>
      p.funds.every(fund =>
        (fund.minPercentAllocation === fund.maxPercentAllocation) && (fund.maxPercentAllocation === 100)))) {
      funds = this.createTotalDisinvestmentFunds(profile, totalDisinvestedForProfile);
    } else {
      funds = profile.funds.map(fund =>
        this.createFundRelativeToDisinvestedAmount(fund, totalDisinvestedForProfile));
    }
    return {
      description: profile.description,
      funds,
      id: profile.id,
      percentage: percentDisinvestedFromProfile,
      amount: profile.amount
    };
  }

  private createFundRelativeToDisinvestedAmount(fund: KarmaFund, amountDisinvestedFromProfile: number): KarmaFund {
    const amountDisinvestedFromFund = amountDisinvestedFromProfile * (fund.percentage / 100);
    const percentDisinvestedOfProfile = amountDisinvestedFromFund / amountDisinvestedFromProfile;
    return {
      id: fund.id,
      investmentType: fund.investmentType,
      percentage: percentDisinvestedOfProfile * 100,
      fundTypeId: fund.fundTypeId,
      amount: fund.amount
    };
  }

  public volatilityType() {
    return VolatilityType.switchDemand;
  }

  handleVolatilityEvent(event) {
    if (event.action === 'calculate') {
      this.onCalculateVolatility();
    } else if (event.action === 'reset') {
      this.volatility = 0;
    }
  }

  // ----------------- INVESTMENT MODE -----------------
  public getDisinvestmentMode(): InvestmentMode | undefined {
    const prop: OperationProperty = this.getOperationPropertyByCode(OperationPropertyCode.DISINVESTMENT_MODE);
    return !!prop ? (prop.stringValue as InvestmentMode) : undefined;
  }
  public getInvestmentMode(): InvestmentMode | undefined {
    const prop: OperationProperty = this.getOperationPropertyByCode(OperationPropertyCode.INVESTMENT_MODE);
    return !!prop ? (prop.stringValue as InvestmentMode) : undefined;
  }

  // ALTRA PROPRIETà PER INDICARE I FONDI BLOCCATI (???)
  public getInvestmentProfilesMode() {
    const prop: OperationProperty = this.getOperationPropertyByCode(OperationPropertyCode.INV_PROFILES_MODE);
    if (!!prop) {
      return prop.stringValue as InvestmentProfileMode;
    }
    return undefined;
  }

  // recupera l'investment mode da passare in request al calcolo volatilità
  public getInvestmentModeForRequest(): number {
    const vmodi = this.listProductFactor.find(factor => factor.code === _VMODI);
    if (!!vmodi && !!vmodi.defaultValue) {
      this._investmentModeForRequest = Number(vmodi.defaultValue);
      return this._investmentModeForRequest;
    }
  }
  // ----------------- INVESTMENT MODE -----------------


  // ---------------------------------------- INVESTIMENTI ----------------------------------------

  // --------------------------------------- DISINVESTIMENTI ---------------------------------------

  // calcolato all'avanti dei profili di disinvestimento
  // restituisce l'amount disinvestito del profilo desiderato
  // (calcolato con amount del profilo / percentuale di disinvestimento)
  public getDisinvestmentAmountWithProfileId(profileId: string): number {
    return this.disinvestmentProfileAmountWithPercentageMap.get(profileId);
  }

  public getActivePanelIds(fieldId: string): string[] {
    const panels = [];
    if (!!fieldId) {
      if (fieldId === 'disinvestmentFunds') {
        this.selectedDisinvestmentProfiles.forEach(el => {
          panels.push('panel' + el.id);
        });
      }
    }
    return panels;
  }


  // restituisce i fondi investiti di un profilo, ovvero quellli da cui si può disivestire
  public getInvestedFundsByProfileId(id: number): KarmaFundDefinition[] {
    const profileFound = this.investedProfiles.find(el => el.id.toString() === id.toString());
    if (!!profileFound) {
      return profileFound.funds;
    }
  }

  // ricalcola il totale disinvestito e lo setta nelle mappe
  // disinvestmentProfileAmountMap -> contiene l'amount della definitions
  // disinvestmentProfileAmountWithPercentageMap -> contiene l'amount di ogni fondo diviso per la perc disinvestita
  recalculateTotalAmount(definitions: any) {
    let totalDisinvestmentAmount = 0;
    const disinvestmentFg = this.formGroup.get('disinvestmentProfiles') as UntypedFormGroup;
    definitions.disinvestmentProfiles.forEach(p => {
      const disinvestedPercentage = !!disinvestmentFg.value ? +disinvestmentFg.value[p.id] : null;
      const disinvestedAmount =  (p.amount * (!!disinvestedPercentage ? +disinvestedPercentage : 0));
      totalDisinvestmentAmount += ( disinvestedAmount === 0 ? p.amount : disinvestedAmount );
      // Aggiorno le mappe con il totale per profilo e con il totale pesato con la perc
      this.disinvestmentProfileAmountMap.set(p.id, p.amount);
      this.disinvestmentProfileAmountWithPercentageMap.set(p.id, disinvestedAmount);
      });
    this.totalAmountToInvest = totalDisinvestmentAmount;
  }

calculateInvestedAmount(data1: any[], data2: any) {
    let total = 0;

    for (const entry of data1) {
      const profileKey = entry.id;
      const fundsData = data2[profileKey];

      if (fundsData) {
        for (const fund of entry.funds) {
          const fundKey = fund.id.toString();
          const percentage = fundsData[fundKey];

          if (percentage !== null && percentage !== undefined) {
            total += percentage * fund.counterValue;
          }
        }
      }
    }

    this.totalInvestedAmount = total;
  }

  private initDisinvestmentProfilesAndFunds(step, definitions): void {
    if (!this.$investedProfiles || this.$investedProfiles.length === 0) {
      this.$investedProfiles = definitions.investedProfiles;
    }

    // INIZIALIZZO I PROFILES SOLO LA PRIMA VOLTA, POI NON DEVO PIù CONSIDERARE LE DEFINITIONS
    if (this.getNextStep(step) === this.firstDisinvestmentStep) {
      const disinvestmentProfilesDefinition = definitions.disinvestmentProfiles;
      if (!!disinvestmentProfilesDefinition) {
        this.disinvestmentProfiles.forEach(profile => {
          (this.formGroup.get('disinvestmentFunds') as UntypedFormGroup).removeControl(profile.id.toString());
        });
        this.disinvestmentProfiles = disinvestmentProfilesDefinition; // CASO PREVALORIZZAZIONE (IN TENDENZA O DA ULTIMO MOVIMENTO)
        const profilesFormValue: any = {};
        this.disinvestmentProfiles.forEach(profile => {
          const fundsFormValue: any = {};
          const profileValue = LpcKarmaFundUtils.getElementDefaultValue(profile);
          profilesFormValue[profile.id] = profileValue === 100 ? 1 : profileValue;
          profile.funds.forEach(fund => {
            const fundValue = LpcKarmaFundUtils.getElementDefaultValue(fund);
            fundsFormValue[fund.id] = fundValue === 100 ? 1 : fundValue;
          });
          this.formGroup.get('disinvestmentProfiles').setValue(profilesFormValue, {emitEvent: false});
          (this.formGroup.get('disinvestmentFunds') as UntypedFormGroup).addControl(profile.id.toString(), new UntypedFormControl());
          this.formGroup.get('disinvestmentFunds').get(profile.id.toString()).setValue(fundsFormValue, {emitEvent: false});
        });
      }
    }
  }

  public getProfilePercent(id: string): number {
    return this.formGroup.get('disinvestmentProfiles').value[id];
  }

  public getProfileAmount(id: string): number {
    const percentage = this.formGroup.get('disinvestmentProfiles').value[id];
    const foundProfile = this.investedProfiles.find(profile => profile.id.toString() === id.toString());
    const amount = this.totalAmountToInvest;
    if (!!foundProfile) {
      return foundProfile.counterValue * percentage;
    }
    if (!!amount) {
      return amount * percentage;
    }
    return 0;
  }

  // --------------------------------------- DISINVESTIMENTI ---------------------------------------

  private createTotalDisinvestmentFunds(profile: KarmaProfile, totalDisinvestedForProfile: number): KarmaFund[] {
    return profile.funds.map(fund => this.createTotalDisinvFund(fund, totalDisinvestedForProfile));
  }

  private createTotalDisinvFund(fund: KarmaFund, totalDisinvestedForProfile: number) {
    // if total switch, the value to disinvest from each fund is equal to how much is currently invested
    const disinvestFromFund = this.investedProfiles
      .map(p => p.funds)
      .reduce((f1, f2) => f1.concat(f2))
      .find(f => f.id.toString() === fund.id.toString());
    const valueToDisinvestFromFund = !!disinvestFromFund ? disinvestFromFund.counterValue : 0;
    const disinvestmentPercent = valueToDisinvestFromFund / totalDisinvestedForProfile;
    return {
      id: fund.id,
      investmentType: fund.investmentType,
      percentage: disinvestmentPercent * 100,
      fundTypeId: fund.fundTypeId,
      amount: fund.amount
    };
  }
}

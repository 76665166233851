import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Party } from '@rgi/digital-claims-common-angular';


@Component({
  selector: 'claims-party-checkboxes',
  templateUrl: './party-checkboxes.component.html',
  styleUrls: ['./party-checkboxes.component.scss']
})
export class PartyCheckboxesComponent implements OnInit, OnChanges {
  @Input() policyHolder: Party;
  @Input() driver: Party;
  @Output() driverChange = new EventEmitter<Party>();
  @Input() disabled: boolean;
  formGroup: UntypedFormGroup;
  holderDriverInternal: boolean;


  set holderDriver(value: boolean) {
    this.holderDriverInternal = value;
    if (value) {
      if (!this.driver || this.policyHolder && this.driver.id !== this.policyHolder.id) {
        this.driver = this.policyHolder;
        this.driverChange.emit(this.driver);
      }
    } else {
      if (this.policyHolder && this.driver && this.driver.id === this.policyHolder.id) {
        this.driver = null;
        this.driverChange.emit(null);
      }
    }
  }

  get holderDriver() {
    return this.holderDriverInternal;
  }

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.driver) {
      if (changes.driver.currentValue && changes.driver.currentValue.id === this.policyHolder.id) {
        this.holderDriverInternal = true;
      } else if (!changes.driver.currentValue || changes.driver.currentValue.id !== this.policyHolder.id) {
        this.holderDriverInternal = false;
      }
    }
  }

  ngOnInit() {
  }

}

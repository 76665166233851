import {
  PncPsalesRequiredDataStateManager
} from '../../../state-managers/required-data/required-data-state-manager.service';
import {PncPostSalesOrchestratorService} from '../../../orchestrator/pnc-postsales-orchestrator.service';
import {PncPostSalesIntegrationService} from '../../../services/pnc-postsales-integration.service';
import {PncPsalesHttpErrorService} from '../../../services/pnc-postsales-http-error.service';
import {PncDomainService} from '../../../resources/http/pnc-domain.service';
import {ActiveRoute} from '@rgi/rx/router';
import {StateStoreService} from '@rgi/rx/state';
import {RgiRxPushMessageHandlerService, RgiRxPushOptions} from '@rgi/rx';
import {Observable, of} from 'rxjs';
import {PncPsalesRequiredDataState} from '../../../resources/states/required-data-state';
import {PncPostSalesCreateDraftResponse} from '../../../resources/model/api/contract-operations';
import {dateDtoToFormField} from '../../../resources/model/api/dates';
import {PncPsalesForm} from '../../../resources/model/common/form';
import {catchError, concatMap, map} from 'rxjs/operators';
import {PolicyTransferData} from '../../../resources/model/api/policy-transfer-data';
import {RGI_FIELD_TYPE, RgiFormField} from '../../../resources/model/common/rgi-form-field';
import {RgiRxUserService} from '@rgi/rx/auth';
import {OperatorLight} from '../../../resources/model/api/operator-light';
import {ApiAgencies, ApiNode} from '../../../resources/model/api/nodes';
import {PNC_ACTIONS} from '../../../resources/constants/actions';
import {formatISO} from 'date-fns';
import {GenericEntity, LabeledEntity} from '../../../resources/model/common/entities';
import {SUMMARY_SECTION_DETAIL_TYPE} from '../../../resources/model/common/summary-step-detail';

export class PncPsalesPolicyTransferState {
  nodes: ApiNode[];
  transferData: PolicyTransferData;
}
export class PncPsalesRequiredDataPolicyTransferStateManager extends PncPsalesRequiredDataStateManager {

  routePrefix: string;
  policyTransferDataForm = 'policyTransferDataForm';
  effectDate = 'EFFECT_DATE';
  nodeFrom = 'NODE_FROM';
  branchFrom = 'BRANCH_FROM';
  nodeTo = 'NODE_TO';
  branchTo = 'BRANCH_TO';

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    orchestrator: PncPostSalesOrchestratorService,
    integrationService: PncPostSalesIntegrationService,
    pushMessageHandler: RgiRxPushMessageHandlerService,
    errorService: PncPsalesHttpErrorService,
    context: any,
    private resourceService: PncDomainService,
    private userService: RgiRxUserService<OperatorLight>,
  ) {
    super(activeRoute, stateStoreService, orchestrator, integrationService, pushMessageHandler, errorService, context);
    this.routePrefix = context.apiPrefix;
    if (!this.routePrefix) {
      throw Error('Missing apiPrefix in context');
    }
    this.registerOnFormFieldChange(
      this.policyTransferDataForm,
      [this.effectDate, this.nodeTo, this.branchTo],
      (state, field, value) => this.updatePolicyTransferDataForm$(of(state), field, value)
    );
  }


  initState$(state: Observable<PncPsalesRequiredDataState>): Observable<PncPsalesRequiredDataState> {
    return state.pipe(
      concatMap((st: PncPsalesRequiredDataState) => {
        this.pushMessageHandler.clearTag(this.activeRoute.route);
        return this.resourceService.createPostSalesDraft$(st.policyNumber, st.operationType, st.currentOperation.code, this.routePrefix).pipe(
          map((resp: PncPostSalesCreateDraftResponse) => {
            st.draftId = resp.resourceId;
            return st;
          }),
          catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
        );
      }),
      concatMap((st: PncPsalesRequiredDataState) => {
        return this.resourceService.getNodes$(this.userService.getUser().salePoint.objectId).pipe(
          map((resp: ApiAgencies) => {
            st.requiredData = new PncPsalesPolicyTransferState();
            st.requiredData.nodes = resp.agencies;
            return st;
          }),
          catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
        );
      }),
      concatMap((st: PncPsalesRequiredDataState) => {
        return this.getTransferData$(st);
      }),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesRequiredDataState) => st)
    );
  }

  private getTransferData$(st: PncPsalesRequiredDataState) {
    return this.resourceService.getPolicyTransferData$(this.routePrefix, st.policyNumber, st.operationType, st.currentOperation.code, st.draftId).pipe(
      map((resp: PolicyTransferData) => {
        st.requiredData.transferData = resp;
        let fields = [];
        fields.push(dateDtoToFormField(this.effectDate, '_PCPSALES_._LABEL_._EFFECT_DATE_', resp.effectDate));
        const nodeFromField: RgiFormField = {
          code: this.nodeFrom,
          label: '_RGIPNC_._LABEL_._NODE_FROM_',
          type: RGI_FIELD_TYPE.STRING,
          mandatory: false,
          visible: true,
          editable: false,
          value: resp?.nodeFrom?.description
        };
        const branchFromField: RgiFormField = {
          code: this.branchFrom,
          label: '_RGIPNC_._LABEL_._BRANCH_FROM_',
          type: RGI_FIELD_TYPE.STRING,
          mandatory: false,
          visible: true,
          editable: false,
          value: resp?.branchFrom?.description
        };
        const nodeToField: RgiFormField = {
          code: this.nodeTo,
          label: '_RGIPNC_._LABEL_._NODE_TO_',
          type: RGI_FIELD_TYPE.DROPCONTAINER,
          mandatory: true,
          visible: true,
          editable: !!resp?.nodeFrom?.code,
          value: resp?.nodeTo,
          dropContainerAction: PNC_ACTIONS.NODE_SELECTION,
          dropContainerLabel: '_RGIPNC_._LABEL_._NODE_TO_',
          dropContainerViewField: 'description',
          dropContainerInputData: st.requiredData.nodes
        };
        const branchToFIeld: RgiFormField = {
          code: this.branchTo,
          label: '_RGIPNC_._LABEL_._BRANCH_TO_',
          type: RGI_FIELD_TYPE.LIST,
          mandatory: resp.nodeFrom?.code === resp.nodeTo?.code && resp?.branchFrom === null && resp?.selectableBranches?.length > 0,
          visible: true,
          editable: !!resp?.selectableBranches?.length,
          allowedValues: resp?.selectableBranches,
          value: resp?.selectableBranches?.find(branch => branch.code === resp?.branchTo)?.code
        };
        fields = fields.concat([nodeFromField, branchFromField, nodeToField, branchToFIeld]);
        st.forms[this.policyTransferDataForm] = new PncPsalesForm(fields);
        return st;
      }),
      catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
    );
  }

  private updatePolicyTransferDataForm$(state$: Observable<PncPsalesRequiredDataState>, field: string, updatedValue: any): Observable<PncPsalesRequiredDataState> {
    this.pushMessageHandler.clearTag(this.activeRoute.route);
    return state$.pipe(
      concatMap((st: PncPsalesRequiredDataState) => {
        const transferData: PolicyTransferData = st.requiredData.transferData;
        let selectedBranchTo: GenericEntity;
        this.getField(this.policyTransferDataForm, field, st).value = updatedValue;
        switch (field) {
          case this.effectDate:
            transferData.effectDate.value = formatISO(updatedValue, {representation: 'date'});
            transferData.nodeTo = null;
            transferData.branchTo = null;
            this.getField(this.policyTransferDataForm, this.nodeFrom, st).value = null;
            this.getField(this.policyTransferDataForm, this.branchFrom, st).value = null;
            const nodeToField = this.getField(this.policyTransferDataForm, this.nodeTo, st);
            nodeToField.value = null;
            nodeToField.editable = false;
            const branchToField = this.getField(this.policyTransferDataForm, this.branchTo, st);
            branchToField.value = null;
            branchToField.editable = false;
            break;
          case this.nodeTo:
            transferData.nodeTo = !!updatedValue && !(Object.keys(updatedValue).length === 0 && updatedValue.constructor === Object) ? {
              code: updatedValue.code,
              description: updatedValue.description
            } : null;
            transferData.branchTo = null;
            this.getField(this.policyTransferDataForm, this.branchTo, st).value = null;
            break;
          case this.branchTo:
            if (updatedValue && updatedValue.length > 0) {
              selectedBranchTo = transferData.selectableBranches.find(val => val.code = updatedValue);
              transferData.branchTo = selectedBranchTo.code;
            } else {
              transferData.branchTo = null;
            }
            break;
        }
        return this.resourceService.setPolicyTransferData$(this.routePrefix, st.policyNumber, st.operationType, st.currentOperation.code, st.draftId, transferData).pipe(
          map(() => st),
          catchError(this.errorService.catchApiErrorFn(st, this.activeRoute.route))
        );
      }),
      concatMap((st: PncPsalesRequiredDataState) => this.getTransferData$(st)),
      catchError(this.errorService.manageStreamErrFn()),
      map((st: PncPsalesRequiredDataState) => st)
    );
  }

  actionContinue() {
    this.pushMessageHandler.clearTag(this.activeRoute.route);
    const st = this.getCurrentState();
    const isFormValid = this.validateForm(st);
    if (isFormValid) {
      this.setSummaryData(st);
      this.updateState$(this.orchestrator.goToNextStep(st, this.activeRoute));
    }
  }

  setSummaryData(st: PncPsalesRequiredDataState) {
    const transferData: PolicyTransferData = st.requiredData.transferData;
    const effDate: string = transferData.effectDate.value;
    const nodeFrom: GenericEntity = transferData.nodeFrom;
    const branchFrom: GenericEntity = transferData.branchFrom;
    const nodeTo: GenericEntity = transferData.nodeTo;
    const branchToCode: string = transferData.branchTo;
    const branchToDescr = branchToCode ? transferData.selectableBranches.find(branch => branch.code === branchToCode).description : null;
    const effDateEntity = [new LabeledEntity('_PCPSALES_._LABEL_._EFFECT_DATE_', effDate)];
    const nodeFromEntity = [new LabeledEntity('_RGIPNC_._LABEL_._NODE_FROM_', nodeFrom.description)];
    const branchFromEntity = !!branchFrom ? [new LabeledEntity('_RGIPNC_._LABEL_._BRANCH_FROM_', branchFrom?.description)] : [];
    const nodeToEntity = [new LabeledEntity('_RGIPNC_._LABEL_._NODE_TO_', nodeTo.description)];
    const branchToEntity = !!branchToDescr ? [new LabeledEntity('_RGIPNC_._LABEL_._BRANCH_TO_', branchToDescr)] : [];
    st.summaryStepDetail = {
      stepLabel: '_PCPSALES_._STEPS_._POLICY_TRANSFER_DATA_',
      sections: [
        {
          detail: {
            type: SUMMARY_SECTION_DETAIL_TYPE.KEYVALUES,
            data: [].concat(effDateEntity).concat(nodeFromEntity).concat(branchFromEntity).concat(nodeToEntity).concat(branchToEntity)
          }
        }
      ]
    };
  }

  private validateForm(st: PncPsalesRequiredDataState): boolean {
    let valid = true;
    const transferData: PolicyTransferData = st.requiredData.transferData;
    const msgOpts = {tag: this.activeRoute.route} as RgiRxPushOptions;
    if (transferData.nodeTo?.code === null) {
      valid = false;
      this.pushMessageHandler.danger('_PCPSALES_._MSG_._NODE_TO_REQUIRED_', msgOpts);
      return valid;
    }
    if (transferData.nodeFrom?.code === transferData.nodeTo?.code) {
      if (!!transferData.branchFrom && transferData.branchFrom.code === transferData.branchTo) {
        valid = false;
        this.pushMessageHandler.danger('_PCPSALES_._MSG_._SAME_BRANCHES_', msgOpts);
        return valid;
      }
      if (!transferData.branchFrom && !transferData.branchTo) {
        valid = false;
        this.pushMessageHandler.danger('_PCPSALES_._MSG_._SAME_NODE_', msgOpts);
        return valid;
      }
    }

    return valid;
  }
}

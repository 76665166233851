export enum Action {
  ALTERNATIVE_PLATE_WORKFLOW_STARTED,
  WITHOUT_PLATE_WORKFLOW_STARTED,
  MULTIPLE_PRODUCTS_ASSETS_FOUND_STANDARD_PLATE_WORKFLOW,
  DATA_IN_ANIA_NOT_FOUND_STANDARD_PLATE_WORKFLOW,
  SINGLE_PRODUCT_ASSET_FOUND_STANDARD_PLATE_WORKFLOW,
  CONFIRM_BUTTON_PRESSED,
  CANCEL_BUTTON_PRESSED,
  NOT_MY_VEHICLE_BUTTON_PRESSED,
  EDIT_ASSET_DATA_BUTTON_PRESSED,
  EDIT_QUOTATION_BUTTON_PRESSED,
  NEXT_BUTTON_PRESSED,
  ISSUE_POLICY,
  NON_STANDARD_VEHICLE_ORIGIN_IN_START_CARD_SELECTED,
  STANDARD_VEHICLE_ORIGIN_IN_START_CARD_SELECTED,
  BACK_BUTTON_PRESSED,
  VISUALIZZA_RIPRENDI,
  EDIT_PROPOSAL,
  SUBSTITUTION_NEXT_BUTTON_PRESSED,
  SUBSTITUTION_EDIT_ASSET,
  SUBMIT_ADEGUATEZZA_SURVEY,
  AMENDMENT_SAVE_BUTTON_PRESSED
}

import {AnagApiResponse} from './anag-api-response';


export class AnagApiPartyKeyConfigRequest {
  partyConfigurationFilter: AnagPartyKeyFilter;

  constructor() {
    this.partyConfigurationFilter = new AnagPartyKeyFilter();
  }
}

export class AnagPartyKeyFilter {
  country: string;
  legalForm: number;
  partyRole: number;
  partyType: string;
}

export class AnagPartyUniqueKeyResponse extends AnagApiResponse {
  outcome: any;
  partyUniqueKeyOutput: string;
}

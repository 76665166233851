export class ApiOctoInstallersFilter {

  filterInstallersOcto: {
    zipCode: string,
    nation: string
  };

  constructor() {
  }
}

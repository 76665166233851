import {Inject, Injectable} from '@angular/core';
import {Variable} from '../../models/domain-models/variable';
import {Section} from '../../models/domain-models/section';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ProposalService} from '../../proposal.service';
import {VariablesService} from '../../variables/variables-service';
import {MicHttpService} from '../../http/mic-http.service';
import {HttpClient} from '@angular/common/http';
import {format} from 'date-fns';


@Injectable({
  providedIn: 'root'
})
export class UnitService {

  private baseApiUrlV2;
  private contractEndpoint;
  private unitSections: Array<Section>;
  protected httpClient: HttpClient;

  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any,
    protected proposalService: ProposalService,
    protected variableService: VariablesService
  ) {
    const newMotorFlowServicesPath = environment.api.portal.nmf;

    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path + '/v2';

    this.contractEndpoint = this.baseApiUrlV2 + '/portfolio/contract';

    this.httpClient = this.micHttpClient.getHttpClient();
  }

  toFormGroup(sections: Section[]): UntypedFormGroup {
    const group = new UntypedFormGroup({});
    sections.forEach(section => {
      section.unitList.forEach(unit => {

        group.setControl(section.code + '_' + unit.code, new UntypedFormControl(unit));
        if (unit && unit.variables) {
          const variablesUnit = unit.variables;
          if (variablesUnit.length === 0) {
            return;
          }

          variablesUnit.sort(
            (a: Variable, b: Variable) => {
              if (a.order > b.order) {
                return 1;
              } else if (a.order < b.order) {
                return -1;
              } else {
                return 0;
              }
            }
          );

          variablesUnit.forEach(variable => {
            const name = section.code + '_' + unit.code + '_' + unit.warrantyCode + '_' + variable.code;
            const variableFormControl = this.variableService.getVariableFormControl(variable, unit.selected);
            if (!unit.selected || !variable.editable) {
              variableFormControl.disable({emitEvent: false});
            }
            group.setControl(name, variableFormControl);
          });
        }
      });
    });
    return group;
  }

  updateUnitValues(sections: Section[], form: UntypedFormGroup): Section[] {
    sections.forEach(section => {
      section.unitList.forEach(unit => {
        unit = form.controls[section.code + '_' + unit.code].value;

        const variablesUnit = unit.variables;
        if (variablesUnit && variablesUnit.length !== 0) {

          variablesUnit.sort(
            (a: Variable, b: Variable) => {
              if (a.order > b.order) {
                return 1;
              } else if (a.order < b.order) {
                return -1;
              } else {
                return 0;
              }
            }
          );

          variablesUnit.forEach(variable => {
            const key = section.code + '_' + unit.code + '_' + unit.warrantyCode + '_' + variable.code;

            const value = form.controls[key].value;
            variable.value = value != null ? '' + value : '';
            if (variable.type === 4 && value && value !== '') {
              variable.value = format(new Date(variable.value), 'dd-MM-yyyy');
            }
          });
        }
      });
    });
    return sections;
  }

  updateUnitValuesValidators(sections: Section[], form: UntypedFormGroup): Section[] {
    sections.forEach(section => {
      section.unitList.forEach(unit => {
        const variablesUnit = unit.variables;

        const keyUnit = section.code + '_' + unit.code;

        if (!form.controls[keyUnit]) {
          form.addControl(keyUnit, new UntypedFormControl(unit));
        }

        if (variablesUnit && variablesUnit.length !== 0) {

          variablesUnit.sort(
            (a: Variable, b: Variable) => {
              if (a.order > b.order) {
                return 1;
              } else if (a.order < b.order) {
                return -1;
              } else {
                return 0;
              }
            }
          );

          variablesUnit.forEach(variable => {

            const key = section.code + '_' + unit.code + '_' + unit.warrantyCode + '_' + variable.code;

            if (!form.controls[key]) {
              form.addControl(key, new UntypedFormControl(variable.value, variable.compulsory ? Validators.required : null));
            }

            const controlVariable = form.controls[key];
            controlVariable.setValue(variable.value);

            if (variable.compulsory && unit.selected) {
              controlVariable.setValidators([Validators.required]);
              controlVariable.updateValueAndValidity();
            } else {
              controlVariable.clearValidators();
              controlVariable.updateValueAndValidity();
            }
            controlVariable.markAsTouched();
          });
        }
      });
    });
    return sections;
  }

  setUnitsAsset(unitSections: Array<Section>) {
    this.unitSections = unitSections;
  }

  getUnitsAsset(): Array<Section> {
    return this.unitSections;
  }

  updateUnitAsset(unitList: Array<Section>) {
    const contractId = this.proposalService.getContractId();
    return this.httpClient.put<Array<Section>>(this.contractEndpoint + '/' + contractId + '/unit', unitList);
  }

}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PanelService } from '../panel.service';
import { PanelStatus, PanelStatusState } from '../dto/panel-status';
import { EditablePanelElement } from '../editable-panel-element/editable-panel-element';
import { PanelSignal } from '../dto/panel-signal';
import { registerLocaleData } from '@angular/common';

@Component({
  selector: 'claims-editable-panel-title',
  templateUrl: './editable-panel-title.component.html',
  styleUrls: ['./editable-panel-title.component.scss']
})
export class EditablePanelTitleComponent extends EditablePanelElement implements OnInit {
  @Input() title: string;
  @Input() hideModifyOnDisable = false;
  @Input() hideModifyBeforeComplete = false;
  @Input() contentEnabled: boolean;

  contentComplete = false;
  modifyEnabled: boolean;
  modifyVisible: boolean;
  panelOpened: boolean;

  private enabledValue: boolean;

  constructor(panelService: PanelService) {
    super(panelService);
  }

  ngOnInit() {
    this.subscribeToPncUpdates();
    if (this.contentEnabled) {
      this.enablePanelAndContent();
    }
  }

  protected panelStatusRecieved(panelStatus: PanelStatus) {
    if (!panelStatus.panelEnabled) {
      if (this.hideModifyOnDisable) {
        this.hideModify();
      }
      this.disableModify();
    }

    if (panelStatus.contentEnabled && panelStatus.panelEnabled) {
      this.modifyEnabled = true;
      this.modifyVisible = true;
    } else {
      if (this.hideModifyOnDisable) {
        this.hideModify();
      }
    }

    if (panelStatus.state === PanelStatusState.incomplete) {
      this.contentComplete = false;
      if (this.hideModifyBeforeComplete) {
        this.hideModify();
      }
    } else {
      this.contentComplete = true;
    }

    // Check if the enable functions are to alter
    if (!panelStatus.panelOpened) {
      this.closePanel();
    } else if (panelStatus.panelOpened) {
      this.openPanel();
    }

    if (panelStatus.contentEnabled) {
      if (this.hideModifyOnDisable) {
        this.hideModify();
      }
      this.modifyEnabled = false;
    } else {
      if (panelStatus.panelEnabled) {
        if (this.hideModifyOnDisable) {
          this.showModify();
        }
        this.modifyEnabled = true;
      }
    }

    this.status = panelStatus;
  }

  protected panelSignalRecieved(panelSignal: PanelSignal) {
    if (this.acceptSignals) {
      if (PanelSignal.open === panelSignal) {
        this.openPanel();
      }
    }
  }

  disableModify() {
    this.modifyEnabled = false;
  }

  enableModify() {
    this.modifyEnabled = true;
  }

  hideModify() {
    this.modifyVisible = false;
  }

  showModify() {
    this.modifyVisible = true;
  }

  modify(event) {

    this.enablePanelContent();

    event.stopPropagation();
    return false;
  }

}

import { UntypedFormGroup } from '@angular/forms';

export function DateComparisonValidator(controlNameDate1: string, controlNameDate2: string) {

  return (formGroup: UntypedFormGroup) => {
    const control1 = formGroup.controls[controlNameDate1];
    const control2 = formGroup.controls[controlNameDate2];

    if (control1.value && control2.value) {
      if (control1.value instanceof Date && control2.value instanceof Date) {
        if (control1.value > control2.value) {
          control1.setErrors({ date1AfterDate2: true });
          control2.setErrors({ date1AfterDate2: true });
        } else {
          control1.setErrors(null);
          control2.setErrors(null);
        }
      } else {
        control1.setErrors({ invalidDate: true });
        control2.setErrors({ invalidDate: true });
      }
    } else if (control1.value && control1.value instanceof Date) {
      control1.setErrors(null);
    } else if (control2.value && control2.value instanceof Date) {
      control2.setErrors(null);
    }
  };

}

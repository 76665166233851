import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TabsetComponent, TabDirective, TabContentDirective, TabTitleDirective} from './tabset';

export {TabsetComponent, TabDirective, TabContentDirective, TabTitleDirective, TabChangeEvent} from './tabset';
export {TabsetConfig} from './tabset-config';

const TABSET_DIRECTIVES = [TabsetComponent, TabDirective, TabContentDirective, TabTitleDirective];

@NgModule({declarations: TABSET_DIRECTIVES, exports: TABSET_DIRECTIVES, imports: [CommonModule]})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class TabsetModule {
  /**
   * Importing with '.forRoot()' is no longer necessary, you can simply import the module.
   * Will be removed in 4.0.0.
   *
   * @deprecated 3.0.0
   */
  static forRoot(): ModuleWithProviders<TabsetModule> {
    return {ngModule: TabsetModule};
  }
}

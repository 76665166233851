export class Questionnaire {
    questionnaireCode: string;
    questionnaireCodeType: string;
    questionnaireCategory: number;
    rcaQuestionnaire: boolean;
    rcaQuestionnaireEdit: any;
    furtherQuestions: any;
    runChecks: boolean;
    questionsDisabled: any;
    questionsRcaType: any;
    responseConditioning: any;
    questions: any;
    answers: QuestionnaireAnswers[] = [];
    items: QuestionnaireItems[] = [];
    outcome: any;
    requestedArrivalDate: boolean;
    arrivalDate: any;
    requestedConfirm: boolean;
    messages: any;
    potentialClaimNumber: string;
    claimNumber: string;
}

export interface QuestionnaireAnswers {
    questionCode: string;
    codiceQuestionario: string;
    answerCode: string;
    idRispostaGestore: string;
    valueResponse: number;
    typeResponse: string;
    valuesResponse: ValuesResponse[];
}

export interface ValuesResponse {
    id: number;
    description: string;
}

export interface QuestionnaireItems {
    question: QuestionnaireItemsQuestion;
    answers: QuestionnaireItemsAnswers[];
    responseConditioning: any;
}

export interface QuestionnaireItemsQuestion {
    questionCode: string;
    questionValue: string;
    questionState: string;
    questionDescription: string;
}
export interface QuestionnaireItemsAnswers {
    questionCode: string;
    codiceQuestionario: string;
    answerCode: string;
    idRispostaGestore: string;
    valueResponse: number;
    typeResponse: string;
    valuesResponse: ValuesResponse[];
}

export interface ClaimNumberQuestionnair {
    claimNumber: string;
    potentialClaimNumber: string;
}

import {Observable, Subject, Subscription} from 'rxjs';
import {
  AfterContentInit,
  ContentChild,
  Directive,
  EventEmitter,
  HostListener, Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Directive({
  selector: '[rgiRxSDKExpandableNode]',
  exportAs: 'rgiRxSDKExpandableNode'
})
/**
 * A single expandable node that can be expanded on click
 */
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class RgiRxSDKExpandableNode {
  private _onToggle = new Subject<Event | any>();

  toggle(event: Event | any) {
    this._onToggle.next();
  }

  get onToggle(): Observable<void> {
    return this._onToggle.asObservable();
  }
  @HostListener('click', ['$event'])
  _handleHostClick(event: MouseEvent) {
    this.toggle(event);
  }
}

@Directive({
  selector: '[rgiRxSDKExpandableHost]',
  exportAs: 'rgiRxSDKExpandableHost'
})
/**
 * A host directive for a single RgiRxSDKExpandableNode that reflect the toggle event.
 * Maintain the state of expansion of the underling node
 */
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class RgiRxSDKExpandableHost implements AfterContentInit, OnDestroy {
  @ContentChild(RgiRxSDKExpandableNode, {static: false}) expandableNode: RgiRxSDKExpandableNode;
  @Output() toggle = new EventEmitter<boolean>();
  private _expanded: boolean;
  private expandSubscription =  Subscription.EMPTY;

  ngAfterContentInit(): void {
    this.expandSubscription.unsubscribe();
    this.expandSubscription = this.expandableNode.onToggle
      .subscribe(
      next => {
         this.expanded = !this.expanded;
         this.toggle.emit(this.expanded);
      }
    );
  }
  @Input() get expanded(): boolean {
    return this._expanded;
  }

  set expanded(value: boolean) {
    this._expanded = coerceBooleanProperty(value);
  }

  ngOnDestroy(): void {
    this.expandSubscription.unsubscribe();
  }
}


<template #nodeSearchModalOverlay></template>
<div id="start-form-wrapper" class="ac-start-form">
    <form [formGroup]="startForm" (ngSubmit)="onSubmit($event)" class="rgi-ui-form-container">

        <div *ngIf="advancedSearch || isFieldVisible(FIELD_AUTH_REQUEST_CODE)">
            <rgi-rx-form-field>
                <label rgiRxLabel translate>_AUTH_._AUTHORIZATION_REQUEST_CODE</label>
                <input rgiRxInput type="text" formControlName="requestCode" rgi-rx-qa="requestCode">
            </rgi-rx-form-field>
        </div>

        <div *ngIf="!isAgency || (availableAgencies && availableAgencies?.length >= maxNode)">
            <label rgiRxLabel class="label-core">
                <span translate>_AUTH_._NODE_CODE</span>
            </label>
            <div>
                <rgi-rx-drop-container [required]="true" formControlName="node" viewField="description"
                                       select="nodeSelection" [selectData]="availableAgencies">
                    <ng-template rgiRxDropLabel let-ctx>
                        <span translate>Seleziona nodo</span>
                    </ng-template>
                </rgi-rx-drop-container>
            </div>
        </div>

        <div *ngIf="isFieldVisible(FIELD_AUTH_REQUEST_TYPE)">
            <rgi-rx-form-field>
                <label translate>_AUTH_._REQUEST_TYPE</label>
                <select rgiRxNativeSelect formControlName="requestType" name="requestType" rgi-rx-qa="select-node">
                    <option></option>
                    <option *ngFor="let requestType of requestTypes; index as i; trackBy: genericEntitiesTrackByFn"
                            [ngValue]="requestType">
                        {{ requestType?.description }}
                    </option>
                </select>
            </rgi-rx-form-field>
        </div>

        <div *ngIf="isFieldVisible(FIELD_AUTH_PRIORITY)">
            <rgi-rx-form-field>
                <label translate>_AUTH_._PRIORITY</label>
                    <select rgiRxNativeSelect formControlName="priority" name="priority">
                    <option></option>
                    <option *ngFor="let priority of priorities; index as i; trackBy: genericEntitiesTrackByFn"
                            [ngValue]="priority">
                        {{ priority?.description }}
                    </option>
                </select>
            </rgi-rx-form-field>
        </div>

        <div *ngIf="isFieldVisible(FIELD_AUTH_POLICY_TYPE)">
            <rgi-rx-form-field>
                <label translate>_AUTH_._POLICY_TYPE</label>
                <select rgiRxNativeSelect formControlName="policyType" name="policyType" (change)="showPMFields()">
                    <option></option>
                    <option *ngFor="let policyType of policyTypeList; index as i; trackBy: genericEntitiesTrackByFn"
                            [ngValue]="policyType">
                        {{ policyType?.description }}
                    </option>
                </select>
            </rgi-rx-form-field>
        </div>
        <div *ngIf="isFieldVisible(FIELD_AUTH_USER_TYPE)">
            <rgi-rx-form-field>
                <label translate>_AUTH_._USER_TYPE</label>
                <select rgiRxNativeSelect formControlName="userType" name="userType"
                        [compareWith]="compareGenericEntities">
                    <option></option>
                    <option *ngFor="let type of userTypes; index as i; trackBy: genericEntitiesTrackByFn"
                            [ngValue]="type">
                        {{ type?.description }}
                    </option>
                </select>
            </rgi-rx-form-field>
        </div>
        <div *ngIf="advancedSearch || isFieldVisible(FIELD_AUTH_COMPETENT_USER)">
            <rgi-rx-form-field>
                <label translate>_AUTH_._COMPETENT_USER</label>
                <input rgiRxInput type="text" formControlName="competentUser"
                       class="form-control ac-input ac-competentUser" rgi-rx-qa="competentUser">
            </rgi-rx-form-field>
        </div>
        <div *ngIf="isFieldVisible(FIELD_AUTH_REQUEST_STATUS)">
            <rgi-rx-form-field>
                <label rgiRxLabel translate>_AUTH_._REQUEST_STATUS</label>
                <select rgiRxNativeSelect formControlName="requestStatus" name="requestStatus"
                        [compareWith]="compareGenericEntities">
                    <option></option>
                    <option *ngFor="let requestStatus of requestStatusList; index as i; trackBy: genericEntitiesTrackByFn"
                            [ngValue]="requestStatus">
                        {{ requestStatus?.description }}
                    </option>
                </select>
            </rgi-rx-form-field>
        </div>
        <div *ngIf="advancedSearch || isFieldVisible(FIELD_AUTH_WORKFLOW_STATUS)">
            <rgi-rx-form-field>
                <label translate>_AUTH_._WORKFLOW_STATUS</label>
                <select rgiRxNativeSelect formControlName="workflowStatus" name="workflowStatus">
                    <option></option>
                    <option *ngFor="let workflowStatus of workflowStatusList; index as i; trackBy: genericEntitiesTrackByFn"
                            [ngValue]="workflowStatus">
                        {{ workflowStatus?.description }}
                    </option>
                </select>
            </rgi-rx-form-field>
        </div>
        <div>
            <rgi-rx-form-field>
                <label rgiRxLabel for="proposalNumber" *ngIf="!showPmFields" translate>_AUTH_._PROPOSAL_NUMBER</label>
                <label rgiRxLabel for="proposalNumber" *ngIf="showPmFields" translate>_AUTH_._PROPOSAL_NUMBER_PM</label>
                <input rgiRxInput id="proposalNumber" type="text" formControlName="proposalNumber" rgi-rx-qa="proposalNumber">
            </rgi-rx-form-field>
        </div>

        <div>
            <rgi-rx-form-field>
                <label rgiRxLabel for="policyNumber" *ngIf="!showPmFields" translate>_AUTH_._POLICY_NUMBER</label>
                <label rgiRxLabel for="policyNumber" *ngIf="showPmFields" translate>_AUTH_._POLICY_NUMBER_PM</label>
                <input rgiRxInput id="policyNumber" type="text" formControlName="policyNumber"
                       class="form-control ac-input ac-policyNumber" rgi-rx-qa="policyNumber">
            </rgi-rx-form-field>
        </div>
        <div *ngIf="showPmFields">
            <rgi-rx-drop-container formControlName="subscriber"
                                   label="{{'_AUTH_._SUBSCRIBER' | translate}}"
                                   [select]="'searchOrCreateSubject'" viewField="nominative"
                                   (onValueChange)="onPartySelected($event)">
            </rgi-rx-drop-container>
        </div>
        <div class="form-group col-sm-6" *ngIf="advancedSearch || isFieldVisible(FIELD_AUTH_REQUEST_DATA_FROM)">
            <rgi-rx-form-field>
                <label rgiRxLabel>{{ '_AUTH_._REQUEST_DATE_FROM' | translate }}</label>
                <rgi-rx-date-picker [openOnClick]="false">
                    <input [rgiRxDateTimeInput]="picker1"
                           rgi-rx-qa="requestDateFrom"
                           formControlName="requestDateFrom">
                </rgi-rx-date-picker>
                <rgi-rx-date-time #picker1></rgi-rx-date-time>
            </rgi-rx-form-field>
        </div>
        <div class="form-group col-sm-6" *ngIf="advancedSearch || isFieldVisible(FIELD_AUTH_REQUEST_DATA_TO)">
            <rgi-rx-form-field>
                <label rgiRxLabel>{{ '_AUTH_._REQUEST_DATE_TO' | translate }}</label>
                <rgi-rx-date-picker [openOnClick]="false">
                    <input [rgiRxDateTimeInput]="picker1"
                           rgi-rx-qa="requestDateTo"
                           formControlName="requestDateTo">
                </rgi-rx-date-picker>
                <rgi-rx-date-time #picker1></rgi-rx-date-time>
            </rgi-rx-form-field>
        </div>
        <div *ngIf="isFieldVisible(FIELD_AUTH_PRODUCT_TYPE)">
            <rgi-rx-form-field>
                <label rgiRxLabel translate>_AUTH_._PRODUCT_TYPE</label>
                <select rgiRxNativeSelect formControlName="productType" name="productType">
                    <option></option>
                    <option *ngFor="let productType of productTypeList; index as i; trackBy: genericEntitiesTrackByFn"
                            [ngValue]="productType">
                        {{ productType?.description }}
                    </option>
                </select>
            </rgi-rx-form-field>
        </div>
        <div class="form-group" *ngIf="advancedSearch || isFieldVisible(FIELD_AUTH_POSTSELL_CODE)">
            <rgi-rx-form-field>
                <label rgiRxLabel translate>_AUTH_._POSTSELL_CODE</label>
                <input rgiRxInput type="text" formControlName="postSellCode" rgi-rx-qa="postSel lCode">
            </rgi-rx-form-field>
        </div>
        <div *ngIf="advancedSearch || isFieldVisible(FIELD_AUTH_DEROGATION_LEVEL)">
            <rgi-rx-form-field>
                <label rgiRxLabel translate>_AUTH_._POSSIBLE_DEROGATION_LEVEL</label>
                <input (click)="valuesDerogationLevel()" type="checkbox" rgiRxInput name="checkboxField" required>
            </rgi-rx-form-field>
        </div>
        <div *ngIf="subsystemList.length > 1">
            <rgi-rx-form-field>
                <label rgiRxLabel translate>_AUTH_._SUBSYSTEM</label>
                <select rgiRxNativeSelect formControlName="subsystem" name="subsystem">
                    <option></option>
                    <option *ngFor="let subsystem of subsystemList; index as i; trackBy: genericEntitiesTrackByFn"
                            [ngValue]="subsystem">
                        {{ subsystem?.description }}
                    </option>
                </select>
            </rgi-rx-form-field>
        </div>
        <div *ngIf="advancedSearch || isFieldVisible(FIELD_AUTH_DEROGATION_LEVEL_NUMBER)">
            <rgi-rx-form-field>
                <label rgiRxLabel translate>_AUTH_._DEROGATION_LEVEL</label>
                <select rgiRxNativeSelect formControlName="derogationLevelNumber" name="derogationLevelNumber">
                    <option></option>
                    <option *ngFor="let derogationLevelNumber of derogationLevelList; index as i; trackBy: genericEntitiesTrackByFn"
                            [ngValue]="derogationLevelNumber">
                        {{ derogationLevelNumber?.description }}
                    </option>
                </select>
            </rgi-rx-form-field>
        </div>
        <div class="form-group row" *ngIf="advancedSearch || isFieldVisible(FIELD_AUTH_LAST_ACTION)">
            <rgi-rx-form-field>
                <label rgiRxLabel translate>_AUTH_._LAST_EXECUTED_ACTION</label>
                <select rgiRxNativeSelect formControlName="lastAction" name="lastAction">
                    <option></option>
                    <option *ngFor="let lastAction of lastActionList; index as i; trackBy: genericEntitiesTrackByFn"
                            [ngValue]="lastAction">
                        {{ lastAction?.description }}
                    </option>
                </select>
            </rgi-rx-form-field>
        </div>
        <div id="error-container">
            <ac-authorization-error-message *ngIf="(startForm?.invalid && submitted && validationMessages?.length > 0)" type="error" [messages]="validationMessages"></ac-authorization-error-message>
            <ac-authorization-error-message *ngIf="!hasResults" type="warning" [messages]="['No results']"></ac-authorization-error-message>
        </div>

        <div class="rgi-ui-panel-footer">
            <!-- Buttons -->
            <button rgi-rx-button (click)="onClear()" color="secondary" data-qa="empty-btn">
                {{ '_AUTH_._EMPTY' | translate | uppercase }}
            </button>
            <button rgi-rx-button type="submit" color="primary" data-qa="find-actions-btn"
                    [disabled]="submitted && !startForm?.valid">
                {{ '_AUTH_._FIND' | translate | uppercase }}
            </button>
        </div>
    </form>
</div>

<br>
<form [formGroup]="addAssetForm">
<div class="container-fluid">
    <div class="form-group row" >
        <div class="col-sm-4 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._TYPE' | translate }} </label>
            <select class="core-select form-control" formControlName="vehicleType" id="vehicleType" name="vehicleType"
            attr.data-qa="vehicle-asset-detail-vehicleType-input" (change)="chargePlateType()">
            <option></option>
            <option *ngFor="let el of vehicleTypeList; " value={{el.code}}>
                {{el.description | translate}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>
        <div class="col-sm-4 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._PLATE_TYPE' | translate }} </label>
            <select class="core-select form-control" formControlName="licensePlateType" id="licensePlateType" name="licensePlateType"
            attr.data-qa="vehicle-asset-detail-licencePlateType-input" (change)="checkLicensePlate()" (blur)="checkLicensePlate()">
            <option></option>
            <option *ngFor="let el of licensePlateTypeList; " value={{el.code}}>
                {{el.description | translate}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>
        <div class="col-sm-4 col-xs-12" >
            <label ng-bind="label">{{ '_CLAIMS_._PLATE_NUMBER' | translate }}</label>
            <input class="form-control" formControlName="licensePlate" id="licensePlate" type="text"
            name="licensePlate" attr.data-qa="vehicle-asset-detail-licensePlate-input" (change)="checkLicensePlate()" 
            (blur)="checkLicensePlate()">
        </div>
    </div>
    <div *ngIf="errorMessages.length > 0" class="error-container">
        <pnc-form-message type="error" detail="validationError" [messages]="errorMessages"
            data-qa="new-asset-error-messages"></pnc-form-message>
    </div>
    <div class="form-group row" >
        <div class="col-sm-12 col-xs-12" >
            <button class="btn btn-warning col-sm-12 " (click)="ibd($event)"
                        attr.data-qa="injury-asset-detail-doc-btn">{{ ''_CLAIMS_._BUTTONS_._IBD' | translate | uppercase}}</button>
        </div>
    </div>

    <div class="form-group row" >
        <div class="col-sm-4 col-xs-12" >
            <label ng-bind="label">{{ '_CLAIMS_._CHASSIS_NUMBER' | translate }}</label>
            <input class="form-control" formControlName="chassisNumber" id="chassisNumber" type="text"
            name="chassisNumber" attr.data-qa="vehicle-asset-detail-chassisNumber-input" (change)="checkLicensePlate()" (blur)="checkLicensePlate()" >
        </div>
        
        <div class="col-sm-4 col-xs-12" >
            <label ng-bind="label">{{ '_CLAIMS_._MANUFACTURER' | translate }}</label>
            <input class="form-control" formControlName="brand" id="brand" type="text"
            name="brand" attr.data-qa="vehicle-asset-detail-brand-input" >
        </div>
        <div class="col-sm-4 col-xs-12" >
            <label ng-bind="label">{{ '_CLAIMS_._MODEL' | translate }}</label>
            <input class="form-control" formControlName="model" id="model" type="text"
            name="model" attr.data-qa="vehicle-asset-detail-model-input" >
        </div>
    </div>


    <div class="form-group row" >
        <div class="col-sm-4 col-xs-12">
            <pnc-datepicker label="{{ '_CLAIMS_._FIRST_REGISTRATION_DATE' | translate }}" customFormControlName="regDate"
            [customFormGroup]="addAssetForm" attr.data-qa="vehicle-asset-detail-regDate-input"
            id="regDate">  
            </pnc-datepicker> 
        </div>
        <div class="col-sm-4 col-xs-12">
            <label ng-bind="label">{{ '_CLAIMS_._NATION' | translate }} </label>
            <select class="core-select form-control" formControlName="selectedCountry" id="selectedCountry" name="selectedCountry"
            attr.data-qa="vehicle-asset-detail-selectedCountry-input" >
            <option></option>
            <option *ngFor="let el of countryList; " value={{el.id}}>
                {{el.value | translate}}</option>
            </select>
            <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
        </div>

        <div class="col-sm-4 col-xs-12" style="text-align: center;">
            <div class="col-sm-12col-xs-12" style="text-align: center;">
            <label ng-bind="label">{{ '_CLAIMS_._PARKED_VEHICLE' | translate }}</label>
            </div>
            <div class="col-sm-12 col-xs-12" style="text-align: center;">
                <input class="form-control" formControlName="parked" id="parked" type="checkbox"
                name="parked" class="form-control btn-checkbox checkbox-unit" attr.data-qa="vehicle-asset-detail-parked-input" >
            </div>
        </div>
        
    </div>
    
</div>
</form>

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pnc-modal-overlay',
  templateUrl: './modal-overlay.component.html',
  styleUrls: ['./modal-overlay.component.css']
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class ModalOverlayComponent implements OnInit {

  ref: any;

  @Input() title: string;
  @Input() messages: Array<string> = [] = [];
  @Input() onClose: () => void;
  @Input() onConfirm: () => void;

  constructor() { }

  ngOnInit() {
    this.title = this.title === undefined || this.title === null ? 'SYSTEM MESSAGE' : this.title;
  }

  close() {
    if (!!this.onClose) {
      this.onClose();
    }
    if (!!this.ref) {
      this.ref.destroy();
    }
  }

  confirm() {
    if (!!this.onConfirm) {
      this.onConfirm();
    }
    if (!!this.ref) {
      this.ref.destroy();
    }
  }

}

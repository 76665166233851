import { TableSchema } from '@rgi/rx/ui';

export interface ISimilarClaims {
  branchesCodes: string[];
  occurrenceDate: string;
  policyNumber: string;
  typePtf: string;
}

export interface Stato {
  codice: string;
  descrizione: string;
}

export interface Controparte{
  compagnia: string;
  targa: string;
}

// Define the interface for the main object in the array
export interface PotentialClaim {
  objectId: any;
  franchigia: any;
  idDenuncia: string;
  contraente: any;
  dataEffettoOriginalePolizza: any;
  responsabilitaDichiarata: any;
  stato: Stato;
  // Add other properties here...
  ispettorato: string;
  pesconSinistro: any;
}

// Define the interface for the entire JSON structure
export interface OutputSimilarClaims {
  listPotentialClaims: PotentialClaim[];
  existClMadePostuma: boolean;
}

export const CLAIMS_SIMILAR_LEGAL: TableSchema = {
  rows: [
    {
      name: 'numeroDenuncia',
      title: '_CLAIMS_._COMPLAINT_NUMBER_STATUS'
    },
    {
      name: 'numberClaim',
      title: '_CLAIMS_._CLAIM_NUMBER_STATUS'
    },
    {
      name: 'dataAccadimento',
      title: '_CLAIMS_._OCCURRENCE_DATE'
    },
    {
      name: 'descrTipoCopertura',
      title: '_CLAIMS_._COVERTURE_TYPE'
    },
    {
      name: 'dataDenuncia',
      title: '_CLAIMS_._COMPLAINT_DATE'
    },
    {
      name: 'numeroPolizza',
      title: '_CLAIMS_._POLICY_NUMBER'
    },
    {
      name: 'nominativoAssicurato',
      title: '_CLAIMS_._INSURED'
    },
    {
      name: 'nominativoControparte',
      title: '_CLAIMS_._COUNTERPART'
    },
    {
      name: 'numeroProcedimento',
      title: 'CLAIMS_._PROCEDURE_NUMBER'
    },
    {
      name: 'actions',
      title: '_CLAIMS_._BUTTONS_._ACTIONS',
      styleClass: 'rgi-gp-action-cell',
      actions: [
        {
          name: 'SUMMARY',
          styleClass: 'rgi-ui-icon-action',
        }
      ]
    }
  ],
  header: ['numeroDenuncia', 'numberClaim', 'dataAccadimento', 'descrTipoCopertura',
    'dataDenuncia', 'numeroPolizza', 'nominativoAssicurato',
    'nominativoControparte', 'numeroProcedimento', 'actions']
};

export const CLAIMS_SIMILAR_NO_LEGAL: TableSchema = {
  rows: [
    {
      name: 'numeroDenuncia',
      title: '_CLAIMS_._POTENTIAL_CLAIM_NUMBER'
    },
    {
      name: 'numberClaim',
      title: '_CLAIMS_._CLAIM_NUMBER_STATUS'
    },
    {
      name: 'dataAccadimento',
      title: '_CLAIMS_._OCCURRENCE_DATE'
    },
    {
      name: 'claimBranch',
      title: '_CLAIMS_._CLAIM_BRANCH'
    },
    {
      name: 'claimType',
      title: '_CLAIMS_._CLAIM_TYPE'
    },
    {
      name: 'numeroPolizza',
      title: '_CLAIMS_._POLICY_NUMBER'
    },
    {
      name: 'liquidatore',
      title: '_CLAIMS_._CDL'
    },
    {
      name: 'ispettorato',
      title: '_CLAIMS_._ADJUSTER'
    },
    {
      name: 'actions',
      title: '_CLAIMS_._ACTIONS',
      styleClass: 'rgi-gp-action-cell',
      actions: [
        {
          name: 'VIEW',
          styleClass: 'rgifont dt-btn rgi-chevron-right cus',
          hidden: { // make it hidden when actionPes === false
            assert: {
              path: 'actionPes',
              value: false,
              condition: '='
            }
          }
        }
      ]
    }
  ],
  header: ['numeroDenuncia', 'numberClaim', 'dataAccadimento',
    'claimBranch', 'claimType', 'numeroPolizza', 'ispettorato',
    'liquidatore', 'actions']
};


export interface DataTableSimilarClaims {
  numeroDenuncia: string;
  numberClaim: string;
  dataAccadimento: string;
  descrTipoCopertura: string;
  dataDenuncia: string;
  numeroPolizza: string;
  nominativoAssicurato: string;
  nominativoControparte: string;
  contraente: string;
  numeroProcedimento: string;
  companyCode: string;
  liquidatore: string;
  ispettorato: string;
  claimBranch: string;
  claimType: string;
  targaTelaio: string;
  descrizioneAccadimento: string;
  prodotto: string;
  lawsuitInfo: string;
  nodoPolizza: string;
  numeroPolizzaMadre: any;
  numeroApplicazione: string;
  controparti: string;
  stato: Stato;
}

export interface OutputShowDetail {
  numeroDenuncia: string;
  companyCode: string;
  claimSelected: PotentialClaim;
}

import {Component, OnInit} from '@angular/core';
import {ConsultationContractDetailState} from "../../state-managers/states/consultation-contract-details-state";
import {
  ConsultationContractDetailsStateManagerService
} from '../../state-managers/consultation-contract-details-state-manager.service';
import {
  ConsultationStartCardActionModalComponent
} from "../consultation-start-card/consultation-start-card-action-modal/consultation-start-card-action-modal.component";
import {PushMessageHandlerService, RgiRxPushMessage} from "@rgi/rx";
import {PolicyOperationPayload} from "../../models/policy-operations";
import {ModalService} from "@rgi/rx/ui";

@Component({
  selector: 'contract-details',
  templateUrl: './consultation-contract-details.component.html',
  styleUrls: ['./consultation-contract-details.css']
})
export class ConsultationContractDetailsComponent  implements OnInit {

  operationTypes: Array<string> = [];
  constructor(
    public stateManager: ConsultationContractDetailsStateManagerService<ConsultationContractDetailState>,
    protected modalService: ModalService,
    private errorMsgService: PushMessageHandlerService,
  ) {

  }
  ngOnInit() {
    this.stateManager.getContractDetails(this.stateManager.getCurrentState().contractNumber).subscribe(res =>{
      let currentState:ConsultationContractDetailState = this.stateManager.getCurrentState();
      currentState.contract = res.contract;
      currentState.signInstalment = res.signInstalment;
      currentState.nextInstalment = res.nextInstalment;
    });
  }
  getPolicyOperations(policyDataOperationInput: PolicyOperationPayload){
    policyDataOperationInput.userCode = this.stateManager.getCurrentState().userCode;
    this.stateManager.getPolicyOperations(policyDataOperationInput).subscribe(res =>{
      res.operations.forEach(operation => {
        if (!this.operationTypes.find(type => {
          return type === operation.type;
        })) {
          this.operationTypes.push(operation.type);
        }
        console.log(res)
      })
      if (this.operationTypes.length) {
        const {modal, component} = this.modalService.openComponent(ConsultationStartCardActionModalComponent);
        component.operations = res.operations;
        component.operationTypes = this.operationTypes;
      }else {
        this.notifyError('CONTRACT_CONSULTATION.NO_ACTIONS', 'default');
      }
    });
  }
  notifyError(content: string, status: string) {
    const msg: RgiRxPushMessage = new RgiRxPushMessage();
    const opts = {icon: 'rgi-ui-icon-alert'};
    msg.tag = 'consultationErrorMessage';
    msg.status = status;
    msg.content = content;
    msg.dismissible = false;
    msg.options = opts;
    this.errorMsgService.notify(msg);
  }

  back() {
    this.stateManager.back();
  }
}

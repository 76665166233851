<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

  <lpc-step label="lpc_date_variation_step" [errors]="errors" id="dates" fieldId="dates" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
                    (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step label="lpc_SelectSettlement" [errors]="errors" fieldId="selectsettlement" id="selectsettlement" (next)="onNext($event, true)">
    <lpc-liquidation
      [liquidationDefinition]="liquidationDefinition"
      [formGroup]="formGroup">
    </lpc-liquidation>
  </lpc-step>

  <lpc-step id="beneficiaries" label="lpc_life_detail_beneficiaries" [errors]="errors" fieldId="beneficiaries"
            (next)="onNext($event, true)" [feErrors]="feErrors" feFieldId="beneficiaries"
            (confirmAccept)="onNext($event)" [isConfirmAndAccept]="isConfirmAndAccept">
    <lpc-claim-beneficiaries
      formControlName="beneficiaries" [paymentTypes]="paymentTypes"
      [beneficiaryType]="beneficiaryType"
      [idAssicurato]="idAssicurato" [summary]="!isActiveStep('beneficiaries')"
      [validateMandatoryBeneficiary]="false"
      [questionnairesCode]="getUnfilteredQuestMap('IST')"
      [questionnairesDefinitions]="questionnaireDefinitions"
      (addedSubject)="onAddedSubject($event)"
      (triggerQuestPreval)="onTriggerQuestPreval($event)"
      [questFactorsArray]="questFactorsArray"
      [disabledQuestionArray]="disabledQuestionArray"
      [distributionConfiguration]="distributionConfiguration"
      #beneficiaries >
    </lpc-claim-beneficiaries>
  </lpc-step>

  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      (documentEvent)="handleDocumentEvent($event)">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
  <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>

export class PremiumDetail {

  constructor(
    public sectionTitle: string,
    public grossAmount: number,
    public netAmount: number,
    public accessories: number,
    public instalmentInterest: number,
    public taxableAmount: number,
    public taxes: number,
    public SSN: number
  ) {
  }
}

<div [formGroup]="formGroup">
    <div [ngClass]="{'col-xs-12 col-sm-6 col-md-7': showPercentageInput, 'col-xs-12 col-sm-12 col-md-12 lpc-radio-style': !showPercentageInput}" class="top-margin padding-top-life-15 padding-left-life-10">
      <pnc-checkbox formControlName="active" [attr.data-qa]="'investment_checkbox_' + definition.description"></pnc-checkbox> <span data-qa="fundItemLabel"> {{definition.description}} </span>
    </div>
    <div [hidden]="!showPercentageInput" class="col-xs-12 col-sm-6 col-md-5 padding-top-life-15">
      <div [ngClass]="{'col-xs-6 col-sm-4': showSliderInput, 'col-xs-6 col-sm-6': !showSliderInput}" class="switch-right">
        <label class="switch">
          <input style="position: fixed;" type="checkbox" formControlName="isPercent" class="form-control" (change)="onToggleClick()">
          <span class="slider" true="%" [attr.false]="currencyCode" ></span>
        </label>
      </div>
      <div [ngClass]="{'col-xs-6 col-sm-4': showSliderInput, 'col-xs-6 col-sm-6': !showSliderInput}">
        <label class="switch-value">
          <input #percent formControlName="percent"  [attr.data-qa]="'investment_percentage_' + definition.description" [value]="formGroup.get('percent').value" *ngIf="isPercent" class="form-control" placeholder="%" (blur)="roundValue('percent')"/>
          <input #amount formControlName="amount" [attr.data-qa]="'investment_amount_' + definition.description"  [value]="formGroup.get('amount').value" *ngIf="!isPercent" class="form-control" placeholder="currencyCode" (blur)="roundValue('amount')"/>
        </label>
      </div>
      <div *ngIf="showSliderInput" class="col-sm-4">
        <input class="slider-top" *ngIf="isPercent" formControlName="percent" type="range" min="0" max="100">
        <input class="slider-top" *ngIf="!isPercent" formControlName="amount" step="0.01" type="range" min="0" max="{{totalAmount}}">
        <div class="col-md-6 col-xs-6">0</div>
        <div class="col-md-6 col-xs-6" style="text-align: right;">{{isPercent? '100' : totalAmount}}</div>
      </div>
    </div>
  </div>
  <div class="col-sm-12">
    <hr>
  </div>

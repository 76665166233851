import {RgiRxRouteDef, RgiRxRouter} from '../routing.service';
import {Observable} from 'rxjs';
import {ActiveRoute, RgiRxQueryData, RgiRxRouteData, RgiRxRouteOptions, RgiRxURIDef} from '../router.api';

/**
 * RgiRx router public api Facade with History and url navigation
 * @see RgiRxRouterLocationModule
 * @since 0.17
 */
export abstract class RgiRxHistoryRouter extends RgiRxRouter {

  abstract activeRouteChange$(): Observable<ActiveRoute>;

  abstract navigateByURL<T>(url: string, queryParams?: RgiRxQueryData, options?: RgiRxRouteOptions);

  abstract forward(): void;

  abstract back(): void;

  abstract findRouteByURL<T>(url: string, routeData?: RgiRxRouteData<T>, options?: RgiRxRouteOptions): { uriDef: RgiRxURIDef; routeDef: RgiRxRouteDef };
}

import { LpcClaimBeneficiaryControlComponent } from './../lpc-claim-beneficiary-control/lpc-claim-beneficiary-control.component';
import {
  DISTRIBUTION_PERC, DISTRIBUTION_AMOUNT,
  DistributionConfigDefinition } from './../../distribution-toggle/distribution-toggle.model';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { questionnaireCacheServiceInjectionToken, QuestionnairesManagerComponent } from '@rgi/questionnaires-manager';
import { Questionnaire, QuestionnaireCacheService, QuestionnairesManagerService, Survey } from '@rgi/questionnaires-manager';
import { of, Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { switchMap } from 'rxjs/operators';
import { ComponentWithAnagModal } from '../../../interfaces/component-with-anag-modal';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';
import { BeneficiaryRole, PaymentTypeDefinition, QuestionnaireDefinition, Role } from '../../../models/postsales-operations-response.model';
import { AnagSubject, PARTY_COMPLETE_KO } from '../../../models/subject.model';
import { AnagService } from '../../../services/anag.service';
import { LoaderService } from '../../../services/loader.service';
import { PlcObjectUtils } from '../../../utils/plc-object-utils';
import { Beneficiary } from '../model/beneficiary';
import { ClaimBeneficiary } from '../model/claim-beneficiary';
import { LpcBeneficiaryUtils } from '../util/lpc-beneficiary-utils';
import { RoleType } from '../../../models/enum/lpc-subjects.enum';


@Component({
  selector: 'lpc-claim-beneficiary',
  templateUrl: './lpc-claim-beneficiary.component.html',
  styleUrls: ['./lpc-claim-beneficiary.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcClaimBeneficiaryComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcClaimBeneficiaryComponent),
      multi: true
    },
    questionnaireCacheServiceInjectionToken
  ]
})
export class LpcClaimBeneficiaryComponent
  implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor, Validator, ComponentWithAnagModal {

  @Input() paymentTypes: PaymentTypeDefinition[] = [];
  // eslint-disable-next-line max-len
  @Output() deleteSubRole: EventEmitter<{benef?: string, linkedRole: ClaimBeneficiary}> = new EventEmitter<{benef?: string, linkedRole: ClaimBeneficiary}>();
  @Output() delete: EventEmitter<ClaimBeneficiary> = new EventEmitter<ClaimBeneficiary>();
  @Output() addedSubject: EventEmitter<Role> = new EventEmitter<Role>();
  @Output() triggerQuestPreval: EventEmitter<any> = new EventEmitter<any>();
  @Output() loaderQuest = new EventEmitter<string>();
  public beneficiaryRolesDefinition: BeneficiaryRole[];
  public subBeneficiaryCount: Map<number, number> = new Map<number, number>();

  @Input() questionnairesCode: Map<string, boolean>;
  @Input() public questFactorsArray: any[] = [];
  @Input() public disabledQuestionArray: any[] = [];
  @Input() public defaultAnswersArray: any[] = [];
  @Input() questionnairesDefinitions: QuestionnaireDefinition[];
  @Input() public blockBeneficiaries = false;
  @Input() distributionConfig: DistributionConfigDefinition = null;

  @ViewChild('avcQuestionnaire') avcQuestionnaire: QuestionnairesManagerComponent;
  @ViewChild('mainBenef') mainBenef: LpcClaimBeneficiaryControlComponent;
  public questionnaireKey;
  public roleCodeToAdd: RoleType;
  public linkedRoles: BeneficiaryRole[] = [];
  public subRolesDefinition: Map<string, BeneficiaryRole[]> = new Map();
  // Updated Survey
  surveyUpdated: any = null;
  /**
   * @description a boolean value that will be set if you are compiling a new questionnaire
   */
  isNewQuest = false;

  private $subscriptions: Subscription[] = [];
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    subject: new UntypedFormControl(),
    linkedRoles: new UntypedFormArray([]),
    paymentMethod: new UntypedFormControl(),
    questionnaireData: new UntypedFormControl(),
    editable: new UntypedFormControl(),
    idBenefSettlement: new UntypedFormControl()
  });
  savedQuestionnaireIds: string[] = [];
  partyCompleteError: {subjIdPlusrole: string, messages: string[]}[] = [];

  get filteredQuestionnairesCode() {
    const s = this.formGroup.getRawValue();
    const newMap = new Map();
    if (s && s.subject && s.subject.value) {
      const subjectType = s.subject.value.personType === '1' ? 'PF' : 'PG';
      if (this.questionnairesCode) {
        this.questionnairesCode.forEach((val, key) => {
          const quest = this.questionnairesDefinitions.find(q => q.code === key);
          if (!!quest && (!quest.subjectType || quest.subjectType === subjectType)) {
            newMap.set(key, val);
          } else {
            val = !val; // rimuovo l'obbligatorietà del questionario
          }
        });
      }
    }
    return newMap;
  }


  constructor(
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected anagService: AnagService,
    protected questManager: QuestionnairesManagerService,
    protected loaderService: LoaderService
  ) {
    const uuid = Date.now().toString(36) + Math.random().toString(36).substring(2);
    this.questionnaireKey = uuid + '-AVC';
  }

  ngOnInit() {
    this.$subscriptions.push(
      this.formGroup.valueChanges.subscribe(() => {
        this.writeValue(this.formGroup.getRawValue());
        this.onChange(this.formGroup.getRawValue());
        this.onTouch();
      })
    );
  }

  openAnagSubjectModal(code: RoleType) {
    this.roleCodeToAdd = code;
    this.anagService.openSubjectModal(this);
  }

  receiveAnagSubjectFromModal(subject: AnagSubject) {
    const roleConverted: Role = AnagService.subjectToRole(subject, this.roleCodeToAdd);
    this.$subscriptions.push(
      this.anagService.checkPartyCompleted(
        Number(subject.objectId),
        Number(subject.idLatestPhotos),
        roleConverted.role,
        Number(this.anagService.managementNode)
      ).subscribe(res => {
        if (res.result === PARTY_COMPLETE_KO) {
          const roleDescr = this.beneficiaryRolesDefinition.find(r => r.code === roleConverted.role).label;
          this.partyCompleteError.push({
              subjIdPlusrole: `${subject.objectId}-${roleConverted.role}`,
              messages: res.outcome.map(m => `${roleDescr} ${subject.nominative}: ${m}`)
          });
        } else {
          this.removeMessagesByRole(subject.objectId, roleConverted.role);
        }
        this.handleSubjectValidated(roleConverted, subject);
        (this.formGroup.get('linkedRoles') as UntypedFormArray).updateValueAndValidity();
      })
    );
  }

  private removeMessagesByRole(subjId: string, role: RoleType) {
    this.partyCompleteError = this.partyCompleteError.filter(m => m.subjIdPlusrole !== `${subjId}-${role}`);
  }

  private handleSubjectValidated(roleConverted: Role, subject: AnagSubject) {
    this.addSubRolesDefinition(roleConverted.role,
      LpcBeneficiaryUtils.filterLinkedRolesByParentRoleAndSbj(this.roleCodeToAdd, subject));
    const linkedRoles = this.formGroup.get('linkedRoles') as UntypedFormArray;
    Object.keys(linkedRoles.controls).forEach(role => {
      const linkedRole = linkedRoles.get(role);
      if (linkedRole.get('code').value === roleConverted.role) {
        this.addSubjectToRole(linkedRole, roleConverted, subject);
      }
    });
    this.notifySubjectJustAdded(roleConverted, subject, this.roleCodeToAdd);
    this.setSubBeneficiariesVisibility();
  }

  notifySubjectJustAdded(role, anagSubj, roleCodeToAdd) {
    this.addedSubject.emit(role);
    this.triggerQuestPreval.emit({
      anagSubj,
      roleCodeToAdd
    });
  }

  private addSubRolesDefinition(roleCode: string, beneficiaryRoles: BeneficiaryRole[]) {
    const subRolesDefinition: BeneficiaryRole[] = this.subRolesDefinition.get(roleCode) || [];
    beneficiaryRoles.forEach(role => {
      const isIncluded = subRolesDefinition.findIndex(x =>
        x.availForRole === role.availForRole && x.code === role.code) !== -1;
      if (!isIncluded) {
        subRolesDefinition.push(role);
      }
    });
    this.subRolesDefinition.set(roleCode, subRolesDefinition);
  }

  private removeSubLinkedRoles(roleCode: string) {
    this.subRolesDefinition.delete(roleCode);
  }

  private addSubjectToRole(linkedRole, roleConverted: Role, subject: AnagSubject) {
    const subjectType = subject.subjectType.codice;
    linkedRole.get('subjects').push(new UntypedFormControl({
      subject: {
        value: roleConverted,
        type: subjectType,
        code: '_ALTRO',
        idAssicurato: EMPTY_STR,
        irrevocable: true
      },
      paymentMethod: null,
      questionnaireData: null
    }));
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    const rawSubject = this.formGroup.getRawValue().subject;
    if (!!rawSubject) {
      const rawBeneficiary: Role = rawSubject.value;
      this.beneficiaryRolesDefinition = LpcBeneficiaryUtils
        .filterLinkedRolesByParentRoleAndSbj('100', rawBeneficiary);
      this.beneficiaryRolesDefinition.forEach(roleDefinition => {
        const allRolesForBeneficiary = rawBeneficiary.linkedSubjectsRoles;
        this.initRolesForCode(roleDefinition, allRolesForBeneficiary);
      });

      this.formGroup.updateValueAndValidity();
      this.setSubBeneficiariesVisibility();

      this.avcQuestionnaire.questionnaireManagerService.questUpdated.pipe(
        switchMap(update => {
          if (!!this.formGroup.get('questionnaireData').value && !!this.formGroup.get('questionnaireData').value.id) {
            return this.loaderService.registerBeCall(
              this.questManager.getSurveyVersion(this.formGroup.get('questionnaireData').value.id)
            );
          } else {
            this.isNewQuest = true;
          }
          return of();
        })
      ).subscribe(survey => {
        this.surveyUpdated = survey.lastVersion;
      });
    }
  }

  private initRolesForCode(roleDefinition: BeneficiaryRole, allRolesForBeneficiary: Role[]): void {
    const linkedRoles: UntypedFormArray = this.formGroup.get('linkedRoles') as UntypedFormArray;
    const subjectsForRole = PlcObjectUtils.asValidArray(allRolesForBeneficiary)
      .filter(value => value.role === roleDefinition.code);
    const roles = this.createRolesForCode(subjectsForRole, allRolesForBeneficiary);

    linkedRoles.push(new UntypedFormGroup({
        code: new UntypedFormControl(roleDefinition.code),
        subjects: new UntypedFormArray(roles)
      })
    );
  }

  private createRolesForCode(subjectsForRole: Role[], allRolesForBeneficiary: Role[]): UntypedFormControl[] {
    if (!subjectsForRole) {
      return [];
    }
    return subjectsForRole.map(roleSubject => {
      const subRoles = this.createSubRoles(allRolesForBeneficiary, roleSubject);
      return new UntypedFormControl({
        subject: {
          value: roleSubject,
          type: roleSubject.personType,
          code: '_ALTRO',
          idAssicurato: EMPTY_STR,
          irrevocable: true
        },
        linkedRoles: subRoles,
        paymentMethod: null,
        questionnaireData: null
      });
    });
  }

  private createSubRoles(linkedSubjectsRoles: Role[], parentRole: Role): Beneficiary[] {
    const subRolesDefinition = LpcBeneficiaryUtils
      .filterLinkedRolesByParentRoleAndSbj(parentRole.role, parentRole);
    this.subRolesDefinition.set(parentRole.role, subRolesDefinition);
    const subRoles = PlcObjectUtils.asValidArray(linkedSubjectsRoles)
      .filter(x => subRolesDefinition.findIndex(y => x.role === y.code) !== -1);
    parentRole.linkedSubjectsRoles = subRoles;
    return subRoles.map(x => ({
          value: x,
          type: x.personType,
          code: '_ALTRO',
          idAssicurato: EMPTY_STR,
          irrevocable: true
      }));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: ClaimBeneficiary): void {
    if (!!obj.subject && !!obj.subject.creditPayment) {
      delete obj.subject.creditPayment;
    }
    if (obj.questionnaireData) {
      this.savedQuestionnaireIds = obj.questionnaireData.id ? [obj.questionnaireData.id] : [];
    } else {
      this.savedQuestionnaireIds = [];
    }
    this.formGroup.patchValue(obj, {emitEvent: false});
  }

  onChange(obj: ClaimBeneficiary) {
  }

  onTouch() {
  }

  onDelete() {
    this.delete.emit(this.formGroup.getRawValue());
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {};
    const beneficiaryForm = this.formGroup.get('subject');
    const linkedRoles = this.formGroup.get('linkedRoles') as UntypedFormArray;
    const beneficiary: Beneficiary = beneficiaryForm.value as Beneficiary;


    // VALIDAZIONE MIN E MAX PERCENT
    if (!this.distributionConfig ||
      this.distributionConfig.distributionType === DISTRIBUTION_PERC ||
      this.distributionConfig.distributionType === null
    ) {
      this.validateBeneficiaryPercentage(beneficiary, errors, beneficiaryForm);
    } else if (!!this.distributionConfig && this.distributionConfig.distributionType === DISTRIBUTION_AMOUNT && beneficiaryForm.errors) {
      PlcObjectUtils.merge(errors, {subRole: beneficiaryForm.errors});
    }

    // VALIDAZIONE RUOLI BENEFICIARI
    this.validateBeneficiaryRoles(linkedRoles, errors);

    // VALIDAZIONE METODI DI PAGAMENTO
    if (!!this.formGroup.get('paymentMethod').errors) {
      PlcObjectUtils.merge(errors, {paymentMethod: this.formGroup.get('paymentMethod').errors});
    }

    // VALIDAZIONE RUOLI OBBLIGATORI
    this.validateMandatoryRoles(linkedRoles, errors);

    if (!!this.partyCompleteError.length) {
      Object.assign(errors, {partycomplete: this.partyCompleteError});
    }

    return !!Object.keys(errors).length ? errors : null;
  }

  protected validateMandatoryRoles(linkedRoles: UntypedFormArray, errors: ValidationErrors) {
    linkedRoles.controls.forEach(ctr => {
      const role = ctr.value;
      const code = role.code;
      const subjectsForRole = role.subjects;
      const roleDefinition: BeneficiaryRole | undefined = this.getSubLinkedRoleByCode(code);
      const mandatory: boolean = !!roleDefinition ? roleDefinition.mandatory : false;
      if (mandatory && (!subjectsForRole || subjectsForRole.length === 0)) {
        errors.roles = true;
      }
    });
  }

  protected validateBeneficiaryPercentage(beneficiary: Beneficiary, errors: ValidationErrors, beneficiaryForm: AbstractControl) {
    if (!!beneficiary) {
      const subject = beneficiary.value as Role;
      const percent: number = Number(subject.percentage);
      if (percent <= 0) {
        PlcObjectUtils.merge(errors, {percentage: {value: 0, subject: subject.name, type: 'min'}});
      } else if (percent > 100) {
        PlcObjectUtils.merge(errors, {percentage: {value: 100, subject: subject.name, type: 'max'}});
      }
    }
    if (beneficiaryForm.errors) {
      PlcObjectUtils.merge(errors, {subRole: beneficiaryForm.errors});
    }
  }

  protected validateBeneficiaryRoles(linkedRoles: UntypedFormArray, errors: ValidationErrors) {
    Object.keys(linkedRoles.controls).forEach(role => {
      const code: string = linkedRoles.get(role).get('code').value;
      const subjectsForRole: UntypedFormGroup = linkedRoles.get(role).get('subjects') as UntypedFormGroup;
      const roleSubjects = subjectsForRole.value;

      const subRoleErrors: any[] = Object.keys(subjectsForRole.controls)
        .map(key =>  subjectsForRole.get(key).errors).filter(error => !!error);
      if (!!subRoleErrors.length) {
        PlcObjectUtils.merge(errors, { personType: true });
      }

      if (roleSubjects != null && roleSubjects.length > 0) {
        const subjects: Role[] = roleSubjects.map(x => x.subject.value);
        // controllo fisico/giuridico
        subjects.forEach(sbj => {
          const beneficiaryRoleDefinition: BeneficiaryRole = LpcBeneficiaryUtils.getBeneficiaryRoles().find(linkedRole =>
            (linkedRole.availForType === 3 || linkedRole.availForType.toString() === sbj.personType) && linkedRole.code === code
          );
          if (!!beneficiaryRoleDefinition && beneficiaryRoleDefinition.admittedType !== 3
            && beneficiaryRoleDefinition.admittedType.toString() !== sbj.personType) {
            if (beneficiaryRoleDefinition.admittedType === 1) {
              PlcObjectUtils.merge(errors, {code, physical: 1});
            } else {
              PlcObjectUtils.merge(errors, {code, physical: 2});
            }
          }
        });
        // controllo duplicati
        const hasDuplicateSubjects = subjects.some(sbj =>
          subjects.filter(x => x.id === sbj.id).length > 1);
        if (hasDuplicateSubjects) {
          PlcObjectUtils.merge(errors, {duplicates: true});
        }
      }
      if (!!subjectsForRole.errors) {
        PlcObjectUtils.merge(errors, {code, sub: subjectsForRole.errors});
      }
    });
  }

  persist(): Observable<Map<string, Survey[]>> {
    return this.questCacheService.persistAll();
  }

  isQuestionnaireValid(): boolean {
    if (this.avcQuestionnaire.getQuest().length === 0) {
      return true;
    }
    return this.questCacheService.checkCompileByKey(this.avcQuestionnaire.key);
  }

  getQuestionnaires(): Questionnaire[] {
    if (!!this.avcQuestionnaire) {
      return this.avcQuestionnaire.getQuest();
    }
  }

  fillQuestionnaireDataForm(surveys: Survey[], modified: boolean) {
      const survey = surveys[0];
      this.formGroup.get('questionnaireData').setValue({
        id: survey.uuid,
        code: survey.questionnaire.questionnaireType.code,
        descr: survey.questionnaire.questionnaireType.name,
        modified
      });
  }

  /**
   * @description
   * is a method called on the persist of the quests chained to each Beneficiary.
   * it returns an Observable to handle and sync the flow and the service call
   * @param surveys type Survey[]
   * @calls getSurveyVersion to get the survey and check if its been modified
   */
  fillForm(surveys: Survey[]): Observable<any> {
    if (1 === surveys.length) {
      if (!!this.surveyUpdated) {
        return this.loaderService.registerBeCall(
          this.questManager.getSurveyVersion(surveys[0].uuid)).pipe(
            switchMap((survey) => {
              const questModified = !PlcObjectUtils.equal(
                this.surveyUpdated.questionnaire.questions,
                survey.lastVersion.questionnaire.questions);
              this.fillQuestionnaireDataForm(surveys, questModified);
              return of(true);
            })
        );
      } else if (this.isNewQuest) { // aggiunto un nuovo questionario imposto modified a true
        this.fillQuestionnaireDataForm(surveys, true);
        return of(true);
      } else {
        this.fillQuestionnaireDataForm(surveys, false);
        return of(true);
      }
    }
    return of();
  }

  public deleteRole({id, roleCode}) {
    const linkedRoles = this.formGroup.get('linkedRoles') as UntypedFormArray;
    Object.keys(linkedRoles.controls).forEach(role => {
      const beneficiaryRole = linkedRoles.get(role) as UntypedFormGroup;
      if (beneficiaryRole.get('code').value === roleCode) {
        this.removeMessagesByRole(id, roleCode);
        const subjects = beneficiaryRole.get('subjects') as UntypedFormArray;
        const indexToRemove = subjects.value.findIndex(x => x.subject.value.id === id);
        this.deleteSubRole.emit({
          benef: this.formGroup.get('subject').value.value.id,
          linkedRole: subjects.value.find(x => x.subject.value.id === id )
        });
        subjects.removeAt(indexToRemove);
        this.setSubBeneficiariesVisibility();
      }
    });
    this.removeSubLinkedRoles(roleCode);
  }

  private getSubLinkedRoleByCode(code: string): BeneficiaryRole | undefined {
    return this.beneficiaryRolesDefinition.find(role => role.code === code);
  }

  private setSubBeneficiariesVisibility() {
    this.beneficiaryRolesDefinition.forEach((role, idx) => {
      this.subBeneficiaryCount.set(idx, this.countSubjectForRole(role, idx));
    });
  }

  private countSubjectForRole(role: BeneficiaryRole, currentRoleIndex: number): number {
    if (!!(this.formGroup.get('linkedRoles') as UntypedFormArray).controls[currentRoleIndex]) {
      const subBeneficiaries = (this.formGroup.get('linkedRoles') as UntypedFormArray).controls;
      const currentSubBeneficiaries = subBeneficiaries[currentRoleIndex].get('subjects').value;
      if (currentSubBeneficiaries.length === 0) {
        return 0;
      }

      return this.getSubjectsForRole(role, subBeneficiaries).length;
    }
  }

  private getSubjectsForRole(role: BeneficiaryRole, subBeneficiaries: AbstractControl[]): UntypedFormArray {
    const isSameRole = (subjectRole: AbstractControl): boolean => subjectRole.value.code === role.code;
    const subjectsForRole: AbstractControl[] = subBeneficiaries.filter(isSameRole).map(x => x.get('subjects'));
    return (subjectsForRole ? subjectsForRole[0] : new UntypedFormArray([])) as UntypedFormArray;
  }

  public getSubjectRoles(roleIndex: number): AbstractControl[] {
    const role: BeneficiaryRole = this.beneficiaryRolesDefinition[roleIndex];
    const subBeneficiaries: AbstractControl[] = (this.formGroup.get('linkedRoles') as UntypedFormArray).controls;
    const subjectsForRole: UntypedFormArray = this.getSubjectsForRole(role, subBeneficiaries);
    return subjectsForRole.controls;
  }

  public canAddSubBeneficiaries(roleIndex: number): boolean {
    const currentRole: BeneficiaryRole = this.beneficiaryRolesDefinition[roleIndex];
    const subjectsForRole: number = this.subBeneficiaryCount.get(roleIndex);
    const maxCardinality = currentRole.maxCardinality || 1;
    return (subjectsForRole < maxCardinality) && this.editability();
  }

  onTriggerQuestPreval($event: any) {
    this.triggerQuestPreval.emit($event);
  }

  public loaderQuestManager(event: string) {
    this.loaderQuest.emit(event);
  }

  public editability(): boolean {
    return this.formGroup.get('editable').value == null || this.formGroup.get('editable').value;
  }

  public titleVisibility(): boolean {
    const hasSubBenef = [...this.subBeneficiaryCount.values()].some(hasvalues => !!hasvalues);
    return this.editability() && hasSubBenef || (!this.editability() &&
    this.formGroup.get('linkedRoles').value.some(el => el.subjects.length !== 0)) && hasSubBenef;
  }

  public labelVisibility(code: string) {
    return this.editability() || !this.editability() &&
    this.formGroup.get('linkedRoles').value.find(role => role.code === code).subjects.length !== 0;
  }

  onChangePayment(payment) {
    // cambio pagamenti
  }

}

import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ClaimDocument } from '../model/claim-document';
import { DocumentService } from '../document.service';
import { DocumentPreviewComponent } from '../document-preview/document-preview.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {
  @Input() document: ClaimDocument;
  @Output() documentDelete = new EventEmitter<ClaimDocument>();
  pdfType: boolean = false;

  constructor(
    private documentService: DocumentService,
    private modalService: NgbModal,
    @Inject('eventService') private eventService: any,
    private rxTranslationsService: RgiRxTranslationService) { }

  ngOnInit() {
    if (this.document && this.document.documentName) {
      const extension = this.document.documentName.split('.').pop();
      if (extension && extension.toUpperCase() === 'PDF') {
        this.pdfType = true;
      }
    }
  }

  previewDocument(event: any) {
    event.stopPropagation();
    if (this.document.externalServiceDocumentId) {
      this.eventService.broadcastEvent('start-loader');
      this.documentService.downloadDocument(this.document.externalServiceDocumentId).subscribe(
        (response: any) => {
          this.eventService.broadcastEvent('stop-loader');
          this.addMimeTypeToDocument(response.document);
          this.document.file = response.document.file.binary;
          this.openPreviewModal();
        },
        (error: Error) => {
          this.eventService.broadcastEvent('stop-loader');
          console.log(error);
        }
      );
    } else {
      this.openPreviewModal();
    }
    return false;
  }

  private openPreviewModal() {
    const modalRef = this.modalService.open(DocumentPreviewComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.document = this.document;
  }

  private addMimeTypeToDocument(documentFromService: any) {
    const fileType = documentFromService.file.mimeType;
    if (fileType.toUpperCase() === 'pdf'.toUpperCase() || fileType.toUpperCase() === 'docx'.toUpperCase()) {
      documentFromService.file.binary = 'data:application/pdf;base64,' + documentFromService.file.binary;
    } else if (fileType.toUpperCase() === 'txt'.toUpperCase()) {
      documentFromService.file.binary = 'data:text/plain;base64,' + documentFromService.file.binary;
    } else if (fileType.toUpperCase() === 'jpeg'.toUpperCase()) {
      documentFromService.file.binary = 'data:image/jpeg;base64,' + documentFromService.file.binary;
    } else if (fileType.toUpperCase() === 'png'.toUpperCase()) {
      documentFromService.file.binary = 'data:image/png;base64,' + documentFromService.file.binary;
    }
  }

  deleteDocument(event: any) {
    event.stopPropagation();
    const that = this;
    this.rxTranslationsService.translate('_CLAIMS_._MESSAGE_._THE_DOCUMENT_WILL_BE_PERMANENTLY_DELETED_DO_YOU_WITH_TO_CONTINUE').subscribe(
      (res) => {
        if (res && confirm(res))
          this.documentService.deleteDocument(this.document).subscribe(() => {
            that.documentDelete.emit(that.document);
          },
            (error: Error) => console.log(error)
          );
      }
    )
    return false;
  }

}

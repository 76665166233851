import {Question} from './question';
import {GenericEntity} from '../models/domain-models/generic-entity';

export class Questionnaire extends GenericEntity {
  constructor(
    public id: string,
    public code: string,
    public description: string,
    public questionList: Array<Question>,
    public complete?: boolean,
    public mandatory?: boolean,
    public differentFromParty?: boolean,
    public adequate?: boolean,
    public categoryAdeguatezza?: boolean
  ) {
    super(id, code, description);
  }
}

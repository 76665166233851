import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {path} from '../conf/conf';
import {Report} from '../model/report';
import {ReportArea} from '../model/report-area';
import {ReportDetail} from '../model/report-detail';
import {CREATE_REPORT, DELETE_REPORT, REPORT_AREAS, REPORT_DETAIL, REPORTS, UPDATE_REPORT} from '../constants/analytics-api';

@Injectable({
  providedIn: 'root'
})

export class ReportService {

  protected API_URL: string;
  protected REPORT_URL: string;
  protected reports: BehaviorSubject<Report[]> = new BehaviorSubject([]);

  constructor(private http: HttpClient, @Inject('environment') environment: any) {
    this.API_URL = environment.api.portal.host + environment.api.portal.path + environment.api.gateway.path;
    this.REPORT_URL = this.API_URL + path.REPORT_URL + '/';
  }


  getReport(id): Observable<ReportDetail> {
    return this.http.get<ReportDetail>(this.REPORT_URL + REPORT_DETAIL + '/' + id);
  }

  getAllReports(): Observable<Report[]> {
    return this.http.get<Report[]>(this.REPORT_URL + REPORTS).pipe(
      tap(res => {
        this.reports.next(res);
      })
    );
  }

  getReportsSubject(): Observable<Report[]> {
    return this.reports.asObservable();
  }

  getReportAreas(): Observable<ReportArea[]> {
    return this.http.get<ReportArea[]>(this.REPORT_URL + REPORT_AREAS);
  }

  deleteReport(id) {
    return this.http.delete(this.REPORT_URL + DELETE_REPORT +'/' + id);
  }

  createReport(body) {
    return this.http.post(this.REPORT_URL + CREATE_REPORT, body);
  }

  updateReport(body) {
    return this.http.put(this.REPORT_URL +  UPDATE_REPORT + '/' + body.id, body);
  }

}

import {ComponentFactory, ComponentFactoryResolver, Inject, Injectable, Optional} from '@angular/core';
import {NG_PASSPRO_DYNAMIC_ANSWER, NgPassProAnswer, NgPassproDynamicAnswer} from "../questionnaire.api";
import {AnswerContainerI} from "../models";
import {CustomAnswerDirective} from "../custom-answer.directive";

@Injectable()
export class DynamicAnswerRenderService {

  private _customAnswers: CustomAnswerDirective[] = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    @Optional() @Inject(NG_PASSPRO_DYNAMIC_ANSWER) private dynamicAnswers?: NgPassproDynamicAnswer[]
  ) {
    if (!!this.dynamicAnswers && !Array.isArray(this.dynamicAnswers)) {
        throw Error("NG_PASSPRO_DYNAMIC_ANSWER providers must be multi!")
    }
  }

  getFactory(answerContainer: NgPassProAnswer): ComponentFactory<NgPassProAnswer> {

    if (!!this.dynamicAnswers) {

      let resolved = this.dynamicAnswers.filter(value =>
        value.when(answerContainer.answer)
      );

      if (resolved.length === 0) {
        return undefined;
      }

      if (resolved.length > 1) {
        throw new Error("Ambiguous NgPassproDynamicAnswer definition, more than one dynamic answer configuration resolved from the when condition");
      }

      let ngPassproDynamicAnswer = resolved[0];
      return this.componentFactoryResolver.resolveComponentFactory(ngPassproDynamicAnswer.component);
    }
  }

  set customAnswers(value: CustomAnswerDirective[]) {
    this._customAnswers = value;
  }

  getMatchingAnswer(answerContainer: AnswerContainerI): CustomAnswerDirective | undefined {
    return this._customAnswers.find(c => c.when(answerContainer));
  }
}

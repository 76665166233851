<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="close()">
  </rgi-rx-panel-header>

  <rgi-rx-datatable rgi-rx-qa="add-contract-policy-list"
                    [data]="policies" [schema]="tableSchema"
                    [pageOptions]="pageOptions" [selectable]="true"
                    (select)="selectEvent($event)" >
    <ng-template rgiRxDataTableCell="description"  let-ctx>
      <button rgi-rx-button variant="dashed" color="secondary" readonly="true">{{ctx.row.description}}</button>
    </ng-template>
    <ng-template rgiRxDataTableCell="policyNumber" let-ctx>
      <div>
        {{ctx.row.policyNumber !== null ? ctx.row.policyNumber : ctx.row.proposalNumber}}
      </div>
    </ng-template>
    <ng-template rgiRxDataTableCell="state" let-ctx>
      <div>
        {{ctx.row.state.description}}
      </div>
    </ng-template>
  </rgi-rx-datatable>

  <!-- FOOTER -->
  <rgi-rx-panel-footer class="rgi-ui-grid-2 rgi-ui-grid-no-gutter">
      <div class="rgi-ui-col">
        <button rgi-rx-button (click)="close()" color="secondary" class="rgi-re-button"
                data-qa="confirm" translate>RE_ISSUE.BACK</button>
      </div>
      <div class="rgi-ui-col">
        <button rgi-rx-button (click)="insertPolicies()" color="primary" type="submit" class="rgi-re-button"
                data-qa="confirm" translate>RE_ISSUE.NEXT</button>
      </div>
  </rgi-rx-panel-footer>
</rgi-rx-panel>

import {HttpClient} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {EvaluatorProductI, EvaluatorObjectI, EvaluatorSectionI} from "./models";
import {SurveyVersionI} from "../models/survey-version";
import {Observable} from "rxjs";
import {EnvironmentService} from "@rgi/ng-passpropro-core";

@Injectable({
  providedIn: 'root'
})
export class EvaluatorService {
  private BASE_PATH: string;

  constructor(private httpClient: HttpClient, private environmentService: EnvironmentService) {
    this.BASE_PATH = this.environmentService.environmentUrl + "/questionnaires/evaluator";
  }


  addSelection(evaluatorProduct :EvaluatorProductI, surveyVersion: SurveyVersionI):Observable<any>{
    const path = this.BASE_PATH + `/${surveyVersion.uuid}`;
    return this.httpClient.put(path, {
      evaluationProduct: evaluatorProduct
    })
  }

}

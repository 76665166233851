import {Component, Input} from '@angular/core';
import { Subject } from '../../../../../models/details/contract-details';

@Component({
  selector: 'lib-consultation-warranties-assets-subjects',
  templateUrl: './consultation-subjects.component.html',
  styleUrls: ['./consultation-subjects.component.css']
})
export class ConsultationWarrantiesAssetsSubjectsComponent {
  @Input() subjects:Subject[];
}

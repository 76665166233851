import {EventInstance, Events, EventType} from '@rgi/rx';
import {AnagApiAddress, AnagApiParty} from '../anag-model/anag-domain/anag-api-party';
import {AnagRouteData} from '../anag-model/anag-domain/anag-route-data';
import {AnagConfiguration} from '../anag-model/anag-domain/anag-configuration';

export const SUBJECT_SELECTED: EventType = {eventName: 'SUBJECT_SELECTED'};
export const NEW_PARTY: EventType = {eventName: 'NEW_PARTY'};
export const CLOSE_ANAG_MODAL: EventType = {eventName: 'CLOSE_ANAG_MODAL'};
export const UMA_ADDRESS_UPDATE: EventType = {eventName: 'UMA_ADDRESS_UPDATE'};
export const KEY_DATA_UPDATE: EventType = {eventName: 'KEY_DATA_UPDATE'};
export const PARTY_CREATED: EventType = {eventName: 'PARTY_CREATED'};


export const ANAG_EVENTS: Events = [
  SUBJECT_SELECTED,
  NEW_PARTY,
  CLOSE_ANAG_MODAL,
  UMA_ADDRESS_UPDATE,
  KEY_DATA_UPDATE,
  PARTY_CREATED
];

export interface SubjectSelectedEvent extends EventType {
  routeData?: AnagRouteData;
}

export class SubjectSelectedEventInstance implements EventInstance<SubjectSelectedEvent> {
  event: SubjectSelectedEvent;

  constructor(party: AnagApiParty) {
    this.event = {
      eventName: SUBJECT_SELECTED.eventName,
      routeData: {party, isModalFlow: true}
    };
  }
}

export interface NewPartyEvent extends EventType {
  routeData?: AnagRouteData;
}

export class NewPartyEventInstance implements EventInstance<NewPartyEvent> {
  event: NewPartyEvent;

  constructor(routeData?: AnagRouteData) {
    this.event = {
      eventName: NEW_PARTY.eventName,
      routeData
    };
  }
}


export interface CloseAnagModalEvent extends EventType {

}

export class CloseAnagModalEventInstance implements EventInstance<CloseAnagModalEvent> {
  event: CloseAnagModalEvent;

  constructor() {
    this.event = {
      eventName: CLOSE_ANAG_MODAL.eventName
    };
  }
}

export interface UmaAddressUpdateEvent extends EventType {
  address: AnagApiAddress;
  isAddressValid: boolean;
}

export class UmaAddressUpdateEventInstance implements EventInstance<UmaAddressUpdateEvent> {
  event: UmaAddressUpdateEvent;

  constructor(address: AnagApiAddress, isAddressValid: boolean) {
    this.event = {
      eventName: UMA_ADDRESS_UPDATE.eventName,
      address,
      isAddressValid
    };
  }
}

export interface KeyDataUpdateEvent extends EventType {
  party: AnagApiParty;
  configuration: AnagConfiguration;
}

export class KeyDataUpdateEventInstance implements EventInstance<KeyDataUpdateEvent> {
  event: KeyDataUpdateEvent;

  constructor(party: AnagApiParty, configuration) {
    this.event = {
      eventName: KEY_DATA_UPDATE.eventName,
      party,
      configuration
    };
  }
}

export interface PartyCreatedEvent extends EventType {
  party: AnagApiParty;
  idSession: string;
}

export class PartyCreatedEventInstance implements EventInstance<PartyCreatedEvent> {
  event: PartyCreatedEvent;

  constructor(party: AnagApiParty, idSession: string) {
    this.event = {
      eventName: PARTY_CREATED.eventName,
      party,
      idSession
    };
  }
}

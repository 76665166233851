import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { distinctUntilChanged } from 'rxjs/operators';
import { ReserveData } from '../../../models/movement.model';

@Component({
  selector: 'lpc-reserve-formula-detail',
  templateUrl: './reserve-formula-detail.component.html',
  styleUrls: ['./reserve-formula-detail.component.css']
})
export class ReserveFormulaDetailComponent implements OnInit {

  @Input() reserveData: ReserveData;


  public riskNames: string [] = [];
  public formulasGrouped: {[key: string]: {relatedRisk: string, value: string}[]};

  constructor() { }

  ngOnInit() {
    this.riskNames = this.reserveData.reserveRiskData.map(f => f.riskName);
    this.formulasGrouped = this.getFormulasSctructure(this.reserveData.reserveRiskData);
  }


  /**
   * @description
   * creating a structure with the formula name as a category name
   * and inside each obj we will have the relatedRisk name and the value of the formula
   * @returns
   * es: {
   *       Bilancio: [
   *                  {
   *                    relatedRisk: 'MF PUR - Unit Linked',
   *                    value: '1000.95'
   *                  }
   *       ]
   *     }
   */
  getFormulasSctructure(reserveRiskData): {[key: string]: {relatedRisk: string, value: string}[]} {
    return reserveRiskData
            .map(f => f.reserveFormule)
            .reduce((pRisk, cRisk) => {
              return pRisk.concat(cRisk); // unisco i due array di formule per ogni rischio
            }).reduce((group, formula) => {
              const category = formula.description;
              const relatedRisk = this.reserveData.reserveRiskData
              .find(r => r.reserveFormule.find(f => f.description === category && f.value === formula.value))
              .riskName;

              group[category] = !!group[category] ? group[category] : [];
              group[category].push({relatedRisk, value: formula.value});
              return group;
            }, {});
  }

  /**
   * @returns
   * the formulaName as a string to make and iteration on the html
   */
  getformulaNames(formulasGrouped: {[key: string]: {relatedRisk: string, value: string}[]}): Observable<string[]> {
    return of(Object.keys(formulasGrouped)).pipe(
      distinctUntilChanged()
    );
  }

  getFormulaByRiskNameAndFormula(formula: string, riskName: string): { relatedRisk: string, value: string } {
    return this.formulasGrouped[formula].find(risk => risk.relatedRisk === riskName);
  }

}

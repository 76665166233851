import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DanniContract} from '../../models/api-models/ptfdanni-resources';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {PtfDanniPostContract} from '../../models/api-models/ptf-danni-post-contract';
import {PtfDanniPostContractResponse} from '../../models/api-models/ptf-danni-post-contract-response';
import {PtfDanniPostActionResponse} from '../../models/api-models/ptf-danni-post-response';
import {REST_API_BANCA_PTF_DANNI_CONF, REST_API_TECHDATA_CONF} from './re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePtfdanniResourceService {
  private apiBancaPtfAllConf: any;
  private qqContractConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any,
  ) {
    this.apiBancaPtfAllConf = REST_API_BANCA_PTF_DANNI_CONF;
    this.qqContractConf = REST_API_TECHDATA_CONF;
  }

  getContract(contractId: string): Observable<DanniContract> {
    return this.http.get<DanniContract>(this.getContractUrl(contractId)).pipe(share());
  }

  getContractUrl(contractId: string): string {
    return this.apiConf.api.portal.path + this.apiBancaPtfAllConf.path + this.apiBancaPtfAllConf.contractPath + '/' + contractId;
  }

  postContract$(body: PtfDanniPostContract): Observable<PtfDanniPostContractResponse> {
    return this.http.post<any>(this.postContractUrl(), body).pipe(share());
  }

  postContractUrl(): string {
    const text = this.apiConf.api.portal.path + this.qqContractConf.path;
    return text.slice(0, -1);
  }


  postAction$(req: any): Observable<PtfDanniPostActionResponse> {
    return this.http.post<PtfDanniPostActionResponse>(this.getActionUri(), req);

  }

  getActionUri(): string {
    return this.apiConf.api.portal.path + this.apiBancaPtfAllConf.path + 'policyoperations';
  }

  getContractByNum(contractNum: string, status: string): Observable<any> {
    return this.http.get(this.apiConf.api.portal.path + this.apiBancaPtfAllConf.path + 'contracts/' + contractNum + '/' + status);
  }

  getProposalByNum(proposalNum: string): Observable<any> {
    return this.http.get(this.apiConf.api.portal.path + this.apiBancaPtfAllConf.path + 'contractinstalment/' + proposalNum);
  }
}

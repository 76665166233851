import {Injectable} from '@angular/core';

@Injectable()
export class MicIntegrationService {

  constructor() { }

  closeApp() { }

  getSessionDataFromContainerCache(sessionName: string): any { return null; }

}

import {Component, OnInit} from '@angular/core';
import {ModalService} from '@rgi/rx/ui';
import {StepperSection} from '../../models/stepper-section';
import {ReIssuePdfQuoteModalComponent} from '../re-issue-pdf-quote-modal/re-issue-pdf-quote-modal.component';
import {
  ReIssueSaveQuoteManager,
  ReIssueSaveQuoteStateManagerService
} from '../re-issue-state-manager/re-issue-save-quote-state-manager.service';

@Component({
  selector: 're-issue-save-quote',
  templateUrl: './re-issue-save-quote.component.html',
  styleUrls: ['./re-issue-save-quote.component.css']
})
export class ReIssueSaveQuoteComponent implements OnInit {
  currentStep = StepperSection.saveQuote;
  stepperLabels = ['RE_ISSUE.POLICY_DATA', 'RE_ISSUE.ASSETS_DATA', 'RE_ISSUE.QUOTATION', 'RE_ISSUE.SUMMARY', 'RE_ISSUE.SAVING_QUOTE', 'RE_ISSUE.ISSUE_STEPPER'];

  constructor(
    public stateManager: ReIssueSaveQuoteStateManagerService<ReIssueSaveQuoteManager>,
    protected customModalService: ModalService
  ) {
  }

  ngOnInit() {
  }

  openStampModal() {
    const {modal, component} = this.customModalService.openComponent(ReIssuePdfQuoteModalComponent);
    // TODO: verifica cos'è il valore di encrypted
    // component.proposalNumber = this.stateManager.getCurrentState().contractNumber.proposalNumberEncrypted;
    component.proposalNumber = this.stateManager.getCurrentState().proposal.contractNumber.proposal;
    component.isProposal = true;

    modal.onClose.subscribe(() => {
    });
  }

  checkout() {
    this.stateManager.goToProposalManagement();
  }

  goToIthStep(i){
    this.stateManager.goToIthStep(i);
  }
  // back() {
  //   this.stateManager.back();
  // }
}

<form [formGroup]="questionnaireForm">

  <ng-container *ngIf="questionnaireElement?.visible">
    <div [ngSwitch]="questionnaireElement?.questionType">

      <div *ngSwitchCase="'-1'">
        <label [className]="'questionnaire-question-label'"><b>{{ questionnaireElement?.question }}</b></label>
      </div>

      <div *ngSwitchCase="'0'">
        <label [className]="'questionnaire-question-label'">{{ questionnaireElement?.question }}</label>
        <pnc-required *ngIf="questionnaireElement?.required" required="true"></pnc-required>
        <select (change)="onAnswerChange()" [attr.data-qa]="'question_'+questionnaireElement?.id" [formControlName]="questionnaireElement?.id"
                class="select-nativa">
          <option [value]="-1"></option>
          <option *ngFor="let answer of questionnaireElement.answers" [value]="answer[0]">
            {{ answer[1] }}</option>
        </select>
        <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
      </div>

      <div *ngSwitchCase="'1'">
        <label [className]="'questionnaire-question-label'">{{ questionnaireElement?.question }}</label>
        <pnc-required *ngIf="questionnaireElement?.required" required="true"></pnc-required>
        <input (change)="onAnswerChange()" [attr.data-qa]="'question_'+questionnaireElement?.id" [disabled]="questionnaireElement.disabled"
               [formControlName]="questionnaireElement?.id"
               class="form-control mic-input" type="number">
      </div>

      <div *ngSwitchCase="'2'">
        <label [className]="'questionnaire-question-label'">{{ questionnaireElement?.question }}</label>
        <pnc-required *ngIf="questionnaireElement?.required" required="true"></pnc-required>
        <select (change)="onAnswerChange()" [attr.data-qa]="'question_'+questionnaireElement?.id" [formControlName]="questionnaireElement?.id"
                class="select-nativa">
          <option [value]="-1"></option>
          <option *ngFor="let answer of questionnaireElement.answers" [value]="answer[0]">
            {{ answer[1] }}</option>
        </select>
        <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
      </div>

      <div *ngSwitchCase="'3'">
        <label [className]="'questionnaire-question-label'">{{ questionnaireElement?.question }}</label>
        <pnc-required *ngIf="questionnaireElement?.required" required="true"></pnc-required>
        <div [attr.data-qa]="'question_'+questionnaireElement?.id" [ngClass]="{'ng-invalid ng-dirty': questionnaireElement?.selectedAnswer !== 1
                    && questionnaireElement?.selectedAnswer !== 0
                && questionnaireElement?.required
                && formSubmitted}" class="content-rgi-radio">
          <label (click)="onAnswerChange(1)" [attr.data-qa]="'question_'+questionnaireElement?.id+'-YES'"
                 [ngClass]="{'active':questionnaireElement?.selectedAnswer === 1}"
                 class="btn rgi-radio-btn btn-primary" translate>YES</label>
          <label (click)="onAnswerChange(0)" [attr.data-qa]="'question_'+questionnaireElement?.id+'-NO'"
                 [ngClass]="{'active':questionnaireElement?.selectedAnswer === 0}"
                 class="btn rgi-radio-btn btn-primary" translate>NO</label>
        </div>
      </div>

      <div *ngSwitchCase="'4'">
        <pnc-datepicker [attr.data-qa]="'question_'+questionnaireElement?.id" [customFormControlName]="questionnaireElement?.id"
                        [customFormGroup]="questionnaireForm" [disabled]="questionnaireElement.disabled"
                        [id]="questionnaireElement?.id" [label]="questionnaireElement?.question"
                        [value]="questionnaireElement?.selectedAnswer">
          <pnc-required *ngIf="questionnaireElement?.required" required="true"></pnc-required>
        </pnc-datepicker>
      </div>

      <div *ngSwitchCase="'5'">
        <label [className]="'questionnaire-question-label'">{{ questionnaireElement?.question }}</label>
        <pnc-required *ngIf="questionnaireElement?.required" required="true"></pnc-required>
        <input (change)="onAnswerChange()" [attr.data-qa]="'question_'+questionnaireElement?.id" [disabled]="questionnaireElement.disabled"
               [formControlName]="questionnaireElement?.id" [value]="questionnaireElement?.selectedAnswer"
               class="form-control mic-input" type="text">
      </div>

    </div>
  </ng-container>
</form>

<div class="container-fluid table-white party-table" attr.data-qa="party-data-panel-{{partyType}}-{{componentId}}">
  <div class="container-fluid table-white">
    <div class="row">
      <div class="col-md-1 col-sm-1 col-xs-12 text-padd-right">
        <span attr.data-qa="party-data-label">{{ partyTypeLabel() }}</span>
        <pnc-required *ngIf="mandatory" required="true"></pnc-required>
      </div>
      &nbsp;&nbsp;&nbsp;
      <!-- <button type="button" class="btn btn-flat btn-sm" (click)="partyDetails($event)" *ngIf="party"
        [disabled]="disabled">
        {{ '_CLAIMS_._DETAILS' | translate }}
        &nbsp;
        <span class="rgifont rgi-search2"></span>
      </button>    Rimosso per RDC-1287--> 
      <span attr.data-qa="status-data-label" *ngIf="party && party.statusDescription"
        style="background-color: #fcd9bc;float: right;">{{ '_CLAIMS_._ASSET_INSURED' | translate }}: {{
        party.statusDescription |
        translate }}</span>
      <br>
    </div>
    <div class="container-fluid table-white">
      <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-12 text-padd-right" attr.data-qa="party-data-panel-name-{{componentId}}">
          <button type="button" class="btn btn-flat btn-sm width-100-perc" (click)="addParty($event)"
            [disabled]="disabled">
            <span *ngIf="!party">{{ '_CLAIMS_._SELECT' | translate }}</span>
            <span *ngIf="party">{{ getNameFromType(party) || '-'}} </span>
            <button *ngIf="party && editable" type="button" class="drag-del-item pull-right icon"
              (click)="deleteParty($event)" [disabled]="disabled">
              <span class="glyphicon glyphicon-trash"></span>
            </button>
          </button>
        </div>
        <div class="col-md-2 col-sm-1 col-xs-12 text-padd-right">
          <button type="button" class="btn btn-flat btn-sm" (click)="partyDetails($event)" *ngIf="party && fullDetails"
            [disabled]="disabled">
            {{ '_CLAIMS_._DETAILS' | translate }}
            &nbsp;
            <span class="rgifont rgi-search2"></span>
          </button>
          <span attr.data-qa="status-data-label" *ngIf="statusDescription"
            style="background-color: #fcd9bc;float: right;">{{
            statusDescription | translate }}</span>
        </div>
        <!-- <div *ngIf="party!=null && isAntiFinancialCrimes(party)" class="col-md-1 col-sm-1 col-xs-12 text-padd-right rgifont rgi-lock">
            </div> -->
        <div class="col-md-2 col-sm-1 col-xs-12 text-padd-right">
          <label *ngIf="party!=null && isAntiFinancialCrimes(party)" ng-bind="label"
            style="color: #ffff; background-color: #ff0000;">{{party?.statusAntiFinancialCrimesDescr |
            translate}}</label>
        </div>
      </div>
    </div>
    <div *ngIf="fullDetails" class="row">
      <div class="col-md-3 col-sm-6 col-xs-12 text-padd-right"
        attr.data-qa="party-data-panel-fiscalcode-{{componentId}}">{{ '_CLAIMS_._FISCAL_CODE__VAT_CODE' | translate }}:
        <span>{{fiscalCodeOrVat() || '-'}}</span>
      </div>
      <div class="col-md-4 col-sm-6 col-xs-12 text-padd-right" attr.data-qa="party-data-panel-address-{{componentId}}">
        {{ '_CLAIMS_._ADDRESS' | translate }}:
        <span>{{party?.address || '-'}}</span>
      </div>
      <div class="col-md-3 col-sm-6 col-xs-12 text-padd-right" attr.data-qa="party-data-panel-mail-{{componentId}}">
        {{ '_CLAIMS_._EMAIL' | translate }}:
        <span>{{party?.mail || '-'}}</span>
      </div>
      <div class="col-md-2 col-sm-6 col-xs-12 text-padd-right"
        attr.data-qa="party-data-panel-phonenumber-{{componentId}}">{{ '_CLAIMS_._TELEPHONE' | translate }}:
        <span>{{party?.phonenumber || '-'}}</span>
      </div>
    </div>
  </div>
</div>
import {Directive, ElementRef, Input} from '@angular/core';
import {CdkCell, CdkCellDef, CdkColumnDef, CdkFooterCell, CdkFooterCellDef, CdkHeaderCell, CdkHeaderCellDef} from '@angular/cdk/table';
import {RgiRxTableSortOrder} from './table-api';

@Directive({
  selector: '[rgiRxCellDef]',
  providers: [{provide: CdkCellDef, useExisting: RgiRxCellDef}]
})
export class RgiRxCellDef extends CdkCellDef {
}

@Directive({
  selector: '[rgiRxHeaderCellDef]',
  providers: [{provide: CdkHeaderCellDef, useExisting: RgiRxHeaderCellDef}]
})
export class RgiRxHeaderCellDef extends CdkHeaderCellDef {
}

@Directive({
  selector: '[rgiRxFooterCellDef]',
  providers: [{provide: CdkFooterCellDef, useExisting: RgiRxFooterCellDef}]
})
export class RgiRxFooterCellDef extends CdkFooterCellDef {
}

@Directive({
  selector: '[rgiRxColumnDef]',
  providers: [
    {provide: CdkColumnDef, useExisting: RgiRxColumnDef}
  ],
})
export class RgiRxColumnDef extends CdkColumnDef {
  @Input('rgiRxColumnDef')
  get name(): string{
    return this._name;
  }
  set name(name: string) {
    this._setNameInput(name);
  }
}

@Directive({
  selector: 'rgi-rx-header-cell, td[rgi-rx-header-cell]',
  host: {
    class: 'rgi-ui-header-cell',
    role: 'columnheader',
    '[attr.aria-sort]': "ariaSortValue"
  },
})
export class RgiRxHeaderCell extends CdkHeaderCell {
  constructor(columnDef: CdkColumnDef,
              elementRef: ElementRef<HTMLElement>) {
    super(columnDef, elementRef);
    elementRef.nativeElement.classList.add(`rgi-ui-column-${columnDef.cssClassFriendlyName}`);
  }

  @Input() ariaSort?: RgiRxTableSortOrder;

  protected get ariaSortValue(): 'none' | 'ascending' | 'descending' {
    if (!this.ariaSort) {
      return 'none';
    }
    return `${this.ariaSort}ending` as 'ascending' | 'descending';
  }
}

@Directive({
  selector: 'rgi-rx-footer-cell, td[rgi-rx-footer-cell]',
  host: {
    class: 'rgi-ui-footer-cell',
    role: 'gridcell',
  },
})
export class RgiRxFooterCell extends CdkFooterCell {
  constructor(columnDef: CdkColumnDef,
              elementRef: ElementRef) {
    super(columnDef, elementRef);
    elementRef.nativeElement.classList.add(`rgi-rx-column-${columnDef.cssClassFriendlyName}`);
  }
}

@Directive({
  selector: 'rgi-rx-cell, td[rgi-rx-cell]',
  host: {
    class: 'rgi-ui-cell',
    role: 'gridcell',
  },
})
export class RgiRxCell extends CdkCell {
  constructor(columnDef: CdkColumnDef,
              elementRef: ElementRef<HTMLElement>) {
    super(columnDef, elementRef);
    elementRef.nativeElement.classList.add(`rgi-rx-column-${columnDef.cssClassFriendlyName}`);
  }
}

import { LicKarmaFundElementComponent } from './../lic-karma-fund-element/lic-karma-fund-element.component';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { LicObjectUtils } from '../../../utils/lic-object-utils';
import { KarmaFundDefinition } from '../../model/karma-fund-definition';
import { KarmaFundError } from '../../model/karma-fund-error';
import { FundToggleEvent } from '../../model/karma-fund';


@Component({
  selector: 'lic-karma-fund-elements',
  templateUrl: './lic-karma-fund-elements.component.html',
  styleUrls: ['./lic-karma-fund-elements.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicKarmaFundElementsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LicKarmaFundElementsComponent),
      multi: true
    }
  ]
})
export class LicKarmaFundElementsComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  public formGroup: UntypedFormGroup = this.getFormGroup();

  @Input() public definition: KarmaFundDefinition[];
  @Input() public totalAmount: number;
  @Input() public showSliderInput = true;
  @Output() public totalPercent: EventEmitter<number> = new EventEmitter<number>();
  @Input() public sliderProperty: string;

  // input iniziale dove vengono prevalorizzati i fondi selezionati (avanti-indietro)
  @Input() selectedFundsSummary: { name: string, selected: boolean, value: string }[];
  @ViewChildren('singleFinancialElement') singleFinancialElement: QueryList<LicKarmaFundElementComponent>;

  public pageSize = 5;
  public pageSizes = [5, 10, 20, 30, 'Mostra tutti'];
  public page = 1;
  public totalItems = 5;
  public count = 0;

  public fundsSelected: { name: string, selected: boolean, value: string }[] = [];

  private $funds: KarmaFundDefinition[];
  public filteredFunds: KarmaFundDefinition[];

  private $subscriptions: Subscription[] = [];

  private $defaultValueStructure: any;

  private $totalPercent = 0;

  public get totalAllocatedPercent(): number {
    return LicObjectUtils.roundToDecimal(this.$totalPercent, 6);
  }

  private $errors: KarmaFundError[];

  public get errors(): KarmaFundError[] {
    return this.$errors;
  }

  get areProfiles(): boolean {
    return this.definition.every(profile => !!profile.profileId);
  }

  constructor() {
  }

  ngOnInit() {
    this.formGroup.get('count').setValue(5);

    if (this.definition) {
      this.$funds = this.definition;
      if (!!this.$funds) {
        this.count = this.$funds.length;
      }
      this.$defaultValueStructure = this.getDefaultStructure();
      this.$funds.forEach(fund => {
        (this.formGroup.get('funds') as UntypedFormGroup).addControl(fund.id.toString(), new UntypedFormControl());
      });
      this.filteredFunds = this.$funds;
    }


    this.$subscriptions.push(
      this.formGroup.get('funds').valueChanges.subscribe(result => {
        const obj = {};
        Object.keys(result).forEach(key => {
          obj[key] = result[key] ? result[key] : null;
        });
        this.$totalPercent = this.getTotalPercent();
        this.totalPercent.emit(this.$totalPercent);
        this.onChange(obj);
        this.onTouch();
      })
    );

    if (this.selectedFundsSummary && this.selectedFundsSummary.length > 0) {
      this.fundsSelected = this.selectedFundsSummary;
    }

    this.formGroup.get('search').valueChanges.subscribe(result => {
      this.filteredFunds = !!result ? this.$funds.filter(
        fund => fund.description.match(new RegExp(this.formGroup.get('search').value, 'i'))
      ) : this.$funds;
    });
  }

  public handlePageChange(event) {
    this.page = event;
  }

  handlePageSizeChange(event) {
    this.pageSize = event;
    this.page = 1;
    if (event === 'Mostra tutti') {
      this.pageSize = this.$funds.length;
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    if (!!obj) {
      const control = LicObjectUtils.merge({}, this.$defaultValueStructure, obj);
      this.formGroup.get('funds').patchValue(
        control, { emitEvent: false }
      );
    } else {
      const control = LicObjectUtils.merge({}, this.$defaultValueStructure);
      this.formGroup.get('funds').setValue(
        control, { emitEvent: false }
      );
    }
    this.$totalPercent = this.getTotalPercent();
    this.totalPercent.emit(this.$totalPercent);
  }

  onChange(obj: any) {
  }

  onTouch() {
  }

  isAllocationCompleted(): boolean {
    return this.totalAllocatedPercent === 1;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const obj: ValidationErrors = {};
    const funds: any = {};
    if (this.totalAllocatedPercent > 1) {
      obj.max = { value: this.totalAllocatedPercent };
    } else {
      if (this.totalAllocatedPercent !== 1) {
        obj.min = { value: this.totalAllocatedPercent };
      }
    }
    Object.keys((this.formGroup.get('funds') as UntypedFormGroup).controls).forEach(key => {
      const cont: UntypedFormControl = this.formGroup.get('funds').get(key) as UntypedFormControl;
      if (cont.errors) {
        funds[key] = cont.errors;
      }
    });
    if (!!Object.keys(funds).length) {
      obj.funds = funds;
    }
    const errors = !!Object.keys(obj).length ? obj : null;
    this.$errors = this.getErrorMessages(errors);
    return errors;
  }

  private getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      search: new UntypedFormControl(),
      count: new UntypedFormControl(this.pageSize),
      funds: new UntypedFormGroup({})
    });
  }


  private getDefaultStructure(): any {
    const obj = {};
    this.$funds.forEach(fund => {
      obj[fund.id] = null;
    });
    return obj;
  }

  private getTotalPercent(): number {
    let total = 0;
    const funds = this.formGroup.getRawValue().funds;
    Object.keys(funds).forEach(key => {
      if (!!funds[key] && !!funds[key].percentage) {
        total += parseFloat(funds[key].percentage) / 100;
      } else if (!!funds[key]) {
        total += parseFloat(funds[key]);
      } else if (!funds[key]) {
        total += 0;
      }
    });
    return total;
  }

  private getErrorMessages(errors: ValidationErrors): KarmaFundError[] {
    const array: KarmaFundError[] = [];
    if (errors) {
      if (errors.min || errors.max) {
        const type: 'min' | 'max' = errors.min ? 'min' : 'max';
        const difference: number = 1 - errors[type].value;
        array.push({
          description: 'TOTAL_FUNDS',
          amount: difference * this.totalAmount,
          percent: difference,
          limit: 1,
          type
        });
      }
      if (errors.funds) {
        Object.keys(errors.funds).forEach(key => {
          const fundError: any = errors.funds[key];
          const type: 'min' | 'max' = fundError.min ? 'min' : 'max';
          const difference: number = fundError[type].limit - fundError[type].value;
          array.push({
            description: this.getFundById(key).description,
            amount: difference * this.totalAmount,
            percent: difference,
            limit: fundError[type].limit,
            type
          });
        });
      }
    }
    return array;
  }

  public getOkMessage(): KarmaFundError {
    return {
      description: 'TOTAL_FUNDS',
      amount: 0,
      percent: 0,
      limit: 1,
      type: 'ok'
    };
  }

  private getFundById(id: string): KarmaFundDefinition {
    return this.$funds.find(fund => fund.id === id);
  }

  public addToFunds(event) {
    if (event.selected) {
      const presentFund = this.fundsSelected.find(fund => fund.name === event.name);
      if (!!presentFund) {
        const index = this.fundsSelected.map(fund => fund.name).indexOf(presentFund.name);
        if (index !== -1) {
          this.fundsSelected.splice(index, 1);
        }
      }
      this.fundsSelected.push(event);
    } else {
      const index = this.fundsSelected.map(fund => fund.name).indexOf(event.name);
      if (index !== -1) {
        this.fundsSelected.splice(index, 1);
      }
    }
  }

  onToggleClick(obj: FundToggleEvent) {
    this.singleFinancialElement.filter(el => el.definition.id !== obj.id).forEach(elem => {
      elem.formGroup.get('isPercent').setValue(obj.isPercent);
    });
  }


}

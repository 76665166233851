import {Component, Input} from '@angular/core';
import {Factor} from "../../../../models/details/contract-details";

@Component({
  selector: 'lib-consultation-policy-details-factors',
  templateUrl: './consultation-policy-details-factors.component.html',
  styleUrls: ['./consultation-policy-details-factors.component.css']
})
export class ConsulationPolicyDetailsFactorsComponent {
  @Input() factors:Factor[];
  dateFormat:string ='dd/MM/yyyy';
}

import {Inject, Injectable} from '@angular/core';
import {AnagApiParty, AnagIntegrationService, ROUTES_HOME} from '@rgi/anag';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {ANAG_CARD} from '../rgi-anag.card.configurations';


@Injectable()
export class AnagPortalIntegrationService extends AnagIntegrationService {

  constructor(protected _routingService: RoutingService,
              protected translationService: RgiRxTranslationService,
              @Inject('sessionService') private portalSession: any,
              @Inject('coreResult') private portalCoreResult: any,
              @Inject('parallelRouting') private portalParallelRouting: any,
              @Inject('containerCacheService') private portalContainerCacheService: any) {
    super(_routingService);
  }

  openPartyMerger(_idCard: string, _criteria: number, _status: number) {
    let title = '';
    this.translationService.translate('_ANAG_._LABEL_._PARTY_MERGER_').subscribe(text => title = text);

    const paramsIntegrationPass = {
      RGIRedirectOk: '/partyMerger.navi',
      NAVI_PAR_CRITERIA: _criteria,
      NAVI_PAR_STATUS: _status
    };
    const objNavigation: any = {
      newSession: true,
    };
    const idCardParent = this.portalSession.list().find(session => session.idSession === _idCard);
    if (idCardParent) {
      objNavigation.idCardParent = idCardParent.id;
    }

    const integrationPass = {
      paramsIntegrationPass,
      objNavigation
    };
    this.portalSession.open('anagFinder', 'merger', title, true, integrationPass, null, _idCard);
  }

  openEditSubject(routeData: any, idSession?: string) {
    const idNavigation = this.portalSession.open(ANAG_CARD.card.name, 'edit', 'edit', true, null, null, idSession);
    this.portalCoreResult.setResult(idNavigation, 'edit', routeData);
  }

  openDetailSubject(routeData: any, idSession?: string) {
    const idNavigation = this.portalSession.open(ANAG_CARD.card.name, 'detail', 'detail', true, null, null, idSession);
    this.portalCoreResult.setResult(idNavigation, 'detail', routeData);
  }

  openLimitationManagement(_idCard: string, _subjectId: string, _nodeId: string) {
    let title = '';
    this.translationService.translate('_ANAG_._BTN_._LIMITATION_MANAGEMENT_').subscribe(text => title = text);

    const paramsIntegrationPass = {
      RGIRedirectOk: '/subjectLimitationManagement.navi',
      NAVI_PAR_SUBJECTID: _subjectId,
      NAVI_PAR_NODEID: _nodeId
    };
    const objNavigation = {
      navReturnOk: 'home',
      functionReloadCtrlParent: 'returnLimitationManagement()',
      idCardParent: this.portalSession.list().find(session => session.idSession === _idCard).id,
      newSession: true
    };
    const integrationPass = {
      paramsIntegrationPass,
      objNavigation
    };
    this.portalSession.open('anagCardZoom', 'limitationManagement', title, true, integrationPass, null, _idCard);
  }

  openHistory(_idCard: string, _subjectId: string, _nodeId: string) {
    let title = '';
    this.translationService.translate('_ANAG_._BTN_._HISTORY_').subscribe(text => title = text);

    const paramsIntegrationPass = {
      RGIRedirectOk: '/subjectHistory.navi',
      NAVI_PAR_SUBJECTID: _subjectId,
      NAVI_PAR_NODEID: _nodeId
    };
    const objNavigation = {
      navReturnOk: 'home',
      idCardParent: this.portalSession.list().find(session => session.idSession === _idCard).id,
      newSession: true
    };

    const integrationPass = {
      paramsIntegrationPass,
      objNavigation
    };

    this.portalSession.open('anagCardZoom', 'history', title, true, integrationPass, null, _idCard);
  }

  changeSession(idSession, idSessionOld) {
    this.portalSession.setActive(idSessionOld);
    this.portalSession.remove(idSession);
  }

  backToHome(activeRoute: ActiveRoute) {
    const anagSession = this.portalSession.list().find(session => session.idSession === activeRoute.id);
    this.portalSession.remove(anagSession.idSession);
  }

  navigate(routingService: RoutingService, destinationRoute: string, routeData: any, activeRoute: ActiveRoute) {
    if (activeRoute.route === ROUTES_HOME || routeData.isModalFlow) {
      super.navigate(routingService, destinationRoute, routeData, activeRoute);
    } else {
      const portalRoute = ANAG_CARD.routes.find(route => route.destination === destinationRoute).route;
      const anagSession = this.portalSession.list().find(session => session.idSession === activeRoute.id);
      this.portalCoreResult.setResult(anagSession.id, portalRoute, routeData);
      this.portalParallelRouting.go(anagSession.id, portalRoute);
    }
  }

  setPartyInContainerCache(party: AnagApiParty, id: string) {
    this.portalContainerCacheService.getCache().set(id, {
      type: 'subject',
      data: party
    });
  }

  changeTitle(id: string, title: string) {
    const anagSession = this.portalSession.list().find(session => session.idSession === id);
    if (anagSession) {
      anagSession.title = title;
    }
  }
}

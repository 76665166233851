import {Component, Input} from '@angular/core';
import {ContractDetails} from '../../../models/details/contract-details';

@Component({
  selector: 'lib-consultation-policy-details',
  templateUrl: './consultation-policy-details.component.html',
  styleUrls: ['./consultation-policy-details.component.css']
})
export class ConsultationPolicyDetailsComponent {
  @Input() contract:ContractDetails;
}

<div>
    <form [formGroup]="searchPolicyForm" data-qa="policy-search-form">

        <div class="col-sm-12 container-policy-search">
            <div class="col-sm-12">
                <pnc-datepicker label="{{ '_CLAIMS_._OCCURRENCE_DATE' | translate }}" customFormControlName="occurrenceDate"
                    [customFormGroup]="searchPolicyForm" [maxDate]="today"
                    attr.data-qa="policy-search-occurrence-date-input">
                </pnc-datepicker>
            </div>
            <div class="col-sm-12">
                <label class="label-core"
                    attr.data-qa="policy-search-occurrence-time-label">{{ '_CLAIMS_._OCCURRENCE_HOUR' | translate }}</label>
                <ngb-timepicker attr.data-qa="policy-search-occurrence-time-input" formControlName="occurrenceTime"
                    id="occurrenceTime" name="time">
                </ngb-timepicker>
            </div>

            <div class="col-sm-12">
                <label class="label-core" attr.data-qa="policy-search-plate-label">{{ '_CLAIMS_._PLATE_NUMBER' | translate }}
                </label>
                <pnc-required required="true" *ngIf="!isPolicyNumberFieldEnabled"></pnc-required>
            </div>
            <input class="col-sm-12 form-control" formControlName="plate"
                [ngClass]="{'submitted-with-error': isMissingPlateAndPolicyNumber()}" id="plate" type="text"
                name="plate" attr.data-qa="policy-search-plate-input">

            <div class="col-sm-12" *ngIf="isPolicyNumberFieldEnabled">
                <label class="label-core"
                    attr.data-qa="policy-search-policynumber-label">{{ '_CLAIMS_._POLICY_NUMBER' | translate }}
                </label>
            </div>
            <input class="col-sm-12 form-control" formControlName="policynumber" *ngIf="isPolicyNumberFieldEnabled"
                [ngClass]="{'submitted-with-error': isMissingPlateAndPolicyNumber()}" id="policynumber" type="text"
                name="policynumber" attr.data-qa="policy-search-policynumber-input">

            <div class="col-sm-12">
                <label class="label-core"
                    attr.data-qa="policy-search-category-list-label">{{ '_CLAIMS_._DAMAGE_CATEGORY' | translate }}</label>
                <pnc-required required="true"></pnc-required>
            </div>
            <div>
                <div *ngFor="let category of damageCategories; let i = index">
                    <input *ngIf="category.ministerialBranchCodes?.length > 0" required
                        attr.data.qa="damage-category-radio-{{i}}" name="cat" formControlName="cat" type="radio"
                        id="radio{{i}}" [value]="category.idCategory" (change)="onSelectionChange(category)"
                        [ngClass]="{submitted: submitted}">
                    <label *ngIf="category.ministerialBranchCodes?.length > 0"
                        attr.data.qa="damage-category-label-{{i}}" for="radio{{i}}"><span
                            class="{{category.cssClass}} icon"></span>{{category.description}}</label>
                </div>
            </div>
        </div>
        <span *ngIf="errorMessage" class="col-sm-12 content-error validate-error" attr.data-qa="policy-search-error">
            <span class="rgifont rgi-exclamation-triangle"></span>
            <span>{{errorMessage | translate}}</span>
        </span>
        <div class="btn-group btn-group-justified">
            <div class="btn-group">
                <button type="button" class="btn btn-warning pull-right" (click)="emptyFields()"
                    attr.data-qa="policy-search-empty-btn">{{ '_CLAIMS_._BUTTONS_._EMPTY' | translate }}</button>
            </div>
            <div class="btn-group">
                <button type="submit" class="btn btn-warning pull-right" attr.data-qa="policy-search-find-btn"
                    id="policy-search-submit" (click)="goToSearchResults($event)">{{ '_CLAIMS_._BUTTONS_._FIND' | translate }}</button>
            </div>
        </div>
    </form>

</div>
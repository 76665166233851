import {AbstractControl, ValidationErrors} from '@angular/forms';
import * as IBAN from 'iban';

export class RgiCountryLayerValidators {
  static iban(c: AbstractControl): ValidationErrors {
    if (c.value && hasValidChars(c.value) && IBAN.isValid(c.value)) {
      return null;
    } else {
      return {
        rgiCountryLayerIban: {
          actualValue: c.value
        }
      };
    }
  }

}

function hasValidChars(iban: string) {
  return /^[A-Za-z0-9]+$/.test(iban);
}

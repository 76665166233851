<div *ngIf="!!state">
    <ng-container *ngFor="let form of formKeys">
        <ng-container *ngIf="state.forms[form].fields && state.forms[form].fields.length">
            <rgi-pnc-psales-section-title [title]="state.forms[form].title">
            </rgi-pnc-psales-section-title>
            <rgi-pnc-psales-form-fields [formFields]="state.forms[form].fields"
                                        [fieldsFormGroup]="getFG(form)">
            </rgi-pnc-psales-form-fields>
        </ng-container>
    </ng-container>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { Event} from '../../resources/models/event';
import {TableSchema} from "@rgi/rx/ui";
import {Observable, of} from "rxjs";

@Component({
  selector: 'ac-auth-history',
  templateUrl: './auth-history.component.html',
  host: {
    class: 'rgi-authorizations-style'
  }
})
export class AuthHistoryComponent implements OnInit {

  @Input()  historyList = Array<Event>();

  public historyListTableData: Observable<any[]>;

  constructor() {
  }

  ngOnInit() {
    this.historyListTableData = of(this.historyList.map(event => {

      return {
        id: event.id,
        description: event.description,
        user: event.competentUser ? event.competentUser.description : (event.user ? event.user.description : null),
        date: event.date
      };
    }));
  }

  MESSAGES_LIST_TABLE_SCHEMA: TableSchema = {
    rows: [
      {
        name: 'description',
        title: '_AUTH_._EVENT'
      },
      {
        name: 'user',
        title: '_AUTH_._USER'
      },
      {
        name: 'date',
        title: '_AUTH_._DATE'
      }
    ],
    header: ['description', 'user', 'date']
  };

  getDetailModel(applicationRow: any) {
    return this.historyList.find(event => event.id === applicationRow.id);
  }
}

<form [formGroup]="group">
  <div class="pull-left">
    <lic-help-icon [helpFile]="config.helpFile" [title]="label"></lic-help-icon>
  </div>
  <pnc-datepicker
    [label]="label"
    [id]="config.code"
    [customFormControlName]="config.code"
    [customFormGroup]="group"
    [required]="config.compulsory"
    (change)="onChange()"
    (dateSelect)="onChange()"
    [attr.data-qa]="'factor' + config.code"
    [attr.data-mod] = "'factor' + config.code + modifiable" [attr.data-obb] = "'factor' + config.code + config.compulsory"
  >
  </pnc-datepicker>
</form>

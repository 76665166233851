import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {RgiRxDatatableComponent, TableSchema} from '@rgi/rx/ui';
import {Policy} from "../../../../models/policyList";
import {POLICY_LIST_SCHEMA} from "./add-contract-policy-list-schema";


@Component({
  selector: 'add-contract-policy-list',
  templateUrl: './add-contract-policy-list.component.html',
  styleUrls: ['./add-contract-policy-list.component.css']
})
export class AddContractPolicyListComponent implements OnInit {

  @Input() policies :any;
  @Output() modalClose = new EventEmitter();
  @ViewChild(RgiRxDatatableComponent) table: RgiRxDatatableComponent<any>;

  tableSchema: TableSchema = POLICY_LIST_SCHEMA;
  pageOptions: number[] = [10, 20, 50];
  selectedPolicies: Array<Policy> = [];

  constructor() {
  }
  ngOnInit() {
  }
  selectEvent(event:any){
    console.log(this.table.selections.selected)
  }
  insertPolicies(){
    this.modalClose.emit(this.table.selections.selected);
  }
  close(){
    this.modalClose.emit();
  }
}

<form [formGroup]="group">
  <div class="grid">
    <pnc-datepicker
      [label]="label"
      [customFormControlName]="config.code"
      [customFormGroup]="group"
      [required]="config.required"
      (change)="onChange()"
      [attr.data-qa]="'mic-datepicker-' + config.code">
    </pnc-datepicker>
  </div>
</form>

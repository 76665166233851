import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserAssociatedLetterEntity} from './dto/user-associated-letter-entity.entity';
import {LetterTemplateEntity} from './dto/letter-template.entity';
import {LetterResponseEntity} from './dto/letter-response.entity';
import {DerogationLevelEntity} from './dto/derogation-level.entity';
import {ManagePhaseEntity} from './dto/manage-phase.entity';

@Injectable({
  providedIn: 'root'
})

export class LetterTemplatesService {
  private baseApiUrlV2: string;

  private urlLetterTemplatesInit = '/claims/letterTemplate/initLetterTemplates';
  private urlLetterTemplatesList = '/claims/letterTemplate/letterTemplatesList';
  private urlLetterTemplatesSave = '/claims/savelettertemplate';
  private urlLetterTemplatesDelete = '/claims/letterTemplate/deleteLetter';

  private mainClass: LetterTemplateMainClass;

  private userList: UserAssociatedLetterEntity[];
  private userListForCurrentEdit: UserAssociatedLetterEntity[];
  private livelliDeroga: DerogationLevelEntity[];
  private fasiGestione: ManagePhaseEntity[];
  private letterList: LetterTemplateEntity[];

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any,
  ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  setRawValues(raw: LetterResponseEntity) {
    this.userList = raw.letterTemplate.listUsers;

    this.livelliDeroga = [];
    this.fasiGestione = [];

    Object.keys(raw.mapLivelliDeroga).forEach(ind => this.livelliDeroga.push(new DerogationLevelEntity(ind, raw.mapLivelliDeroga[ind])));
    // mapFasiGestione contiene filtri che potrebbero essere gestiti in futuro (CLAIMS, PORTAFOGLIO, ALL)
    Object.keys(raw.mapFasiGestione).forEach(ind =>
      this.fasiGestione.push(new ManagePhaseEntity(ind, raw.mapFasiGestione[ind][0], raw.mapFasiGestione[ind][1]))
    );
  }

  public setUserListForCurrentEdit(userListForCurrentEdit: UserAssociatedLetterEntity[]) {
    this.userListForCurrentEdit = userListForCurrentEdit;
  }

  public clenUserListForCurrentEdit() {
    this.userListForCurrentEdit = [];
  }

  public getUserLetterDerogationLevelForCurrentEditByUserId(id: number): string {
    const user: UserAssociatedLetterEntity = this.userListForCurrentEdit.find(value => value.userId === id);
    return user ? user.livelloDerogaLettera : null;
  }

  public setMainClass(mainClass: LetterTemplateMainClass) {
    this.mainClass = mainClass;
  }

  public getMainClass(): LetterTemplateMainClass {
    return this.mainClass;
  }

  public setLetterList(letterList: LetterTemplateEntity[]) {
    this.letterList = letterList;
  }

  public getLetterList(): LetterTemplateEntity[] {
    return this.letterList;
  }

  public setFasiGestione(fasiGestione: ManagePhaseEntity[]) {
    this.fasiGestione = fasiGestione;
  }

  public getFasiGestione(): ManagePhaseEntity[] {
    return this.fasiGestione;
  }

  public setLivelliDeroga(livelliDeroga: DerogationLevelEntity[]) {
    this.livelliDeroga = livelliDeroga;
  }

  public getLivelliDeroga(): DerogationLevelEntity[] {
    return this.livelliDeroga;
  }

  public setUserList(userList: UserAssociatedLetterEntity[]) {
    this.userList = userList;
  }

  public getUserList(): UserAssociatedLetterEntity[] {
    return this.userList;
  }

  getLetterTemplatesList() {
    return this.httpClient.get(this.baseApiUrlV2 + this.urlLetterTemplatesList);
  }

  saveLetterTemplate(letterModel: LetterTemplateEntity) {
    return this.httpClient.post(this.baseApiUrlV2 + this.urlLetterTemplatesSave, letterModel);
  }

  getModelInit(letterId?: string) {
    const data: any = {};

    if (letterId) {
      data.idLetter = letterId;
    }

    return this.httpClient.get(this.baseApiUrlV2 + this.urlLetterTemplatesInit, {params: data});
  }

  deleteLetterTemplate(letterId?: string) {
    const data: any = {};

    if (letterId) {
      data.idLetter = letterId;
    }

    return this.httpClient.get(this.baseApiUrlV2 + this.urlLetterTemplatesDelete, {params: data});
  }
}

export enum LetterTemplateMainClass {
  NEW_LETTER_TEMPLATE,
  LETTER_TEMPLATE_LIST
}

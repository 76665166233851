/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { LangInterceptor } from './lang.interceptor';

/** Http interceptor providers in outside-in order
 * @deprecated interceptors are already provided by @rgi/rx/portal and should be removed
 * They are still listed here and exported to public api in order to still allow to register them in the app.
 * Please remove them from the app and from here.
 */
export const CLAIMS_COMMON_HTTP_INTERCEPTORS = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
];

import {ApiContract} from '../api-models/api-contract';
import {GenericEntity} from './generic-entity';
import {ApiAssetType} from '../api-models/api-asset-type';
import {ApiProduct} from '../api-models/api-product';
import {LicensePlate} from './license-plate';
import {VehicleProperties} from './vehicle-properties';
import {PolicyContacts} from '../../proposal/addresses/policy-contacts';

export class MotorProposal {
  constructor(
    public companyId: string,
    public nodeId: string,
    public apiContract: ApiContract,
    public product: GenericEntity,
    public assetType: ApiAssetType,
    public contractId: string,
    public multipleProductsAssetTypes: Array<ApiProduct>,
    public licensePlate: LicensePlate,
    public vehicleProperties: VehicleProperties,
    public vehicleType: string,
    public policyContacts: PolicyContacts
  ) {
  }

  static createEmptyProposal() {
    return new MotorProposal(null, null, null, null, null, null, null, null, null, null, null);
  }
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Country} from '../../../models/domain-models/country';
import {LpsData, PolicyLpsData} from '../../../models/domain-models/parameters/policy-lps-data';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
  selector: 're-issue-lps',
  templateUrl: './re-issue-lps.component.html',
  styleUrls: ['./re-issue-lps.component.css']
})
export class ReIssueLpsComponent implements OnInit, OnDestroy {

  @Input() euCountriesList: Country[];
  @Input() lpsData: LpsData;
  @Input() isDirectionalUser: boolean;

  @Output() lpsDataOutput: EventEmitter<LpsData> = new EventEmitter<LpsData>();

  private formChangesSubscription: Subscription;
  lpsForm: UntypedFormGroup;
  selectedEuCountry: Country;

  COUNTRIES_EXTRA_EUROPEAN_ECONOMIC_AREA_ID = '31';

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) {
  }

  ngOnInit() {
    this.createForm();
    this.initializeForm(this.lpsData);
    this.subscribeToFormChanges();
  }

  private createForm() {
    this.lpsForm = this.formBuilder.group({
      euCountry: [null],
      extraEuCountry: [null],
      currency: [null],
      changeDate: [null],
      exchangeRate: [null]
    });
  }

  private initializeForm(lpsData: LpsData) {
    this.initializeEuCountry(lpsData.policyLpsData.lpsPolicyIdentification);
    this.initializeExtraEuCountry(lpsData.policyLpsData.extraEUCountry);
    this.initializeCurrency(lpsData.policyLpsData.currency);
    this.initializeChangeDate(lpsData.policyLpsData.exchangeDate);
    this.initializeExchangeRate(lpsData.policyLpsData.exchangeRate);
  }

  private initializeEuCountry(lpsPolicyIdentification: Country) {
    if (lpsPolicyIdentification) {
      this.selectedEuCountry = this.getCountryById(lpsPolicyIdentification.identification);
      this.lpsForm.controls.euCountry.setValue(this.selectedEuCountry.identification);
    }
  }

  private initializeExtraEuCountry(extraEuCountry: string) {
    if (extraEuCountry) {
      this.lpsForm.controls.extraEuCountry.setValue(extraEuCountry);
    } else {
      this.lpsForm.controls.extraEuCountry.disable();
    }
  }

  private initializeCurrency(currency: string) {
    if (currency) {
      this.lpsForm.controls.currency.setValue(currency);
    }
  }

  private initializeChangeDate(exchangeDate: string) {
    if (exchangeDate) {
      const dateParts = exchangeDate.split('/');
      if (dateParts.length === 3) {
        const day = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10) - 1; // Months are zero-based (0-11)
        const year = parseInt(dateParts[2], 10);
        const formattedDate = new Date(year, month, day);
        this.lpsForm.controls.changeDate.setValue(formattedDate);
      }
    }
  }

  private initializeExchangeRate(exchangeRate: string) {
    if (exchangeRate) {
      this.lpsForm.controls.exchangeRate.setValue(exchangeRate);
    }
  }

  private subscribeToFormChanges() {
    this.formChangesSubscription = this.lpsForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe((formValues) => {
        this.updateLpsDataOutput(formValues);
        this.updateExtraEuCountryState(formValues.euCountry);
      });
  }

  private updateExtraEuCountryState(euCountryId: string) {
    this.formChangesSubscription.unsubscribe();
    if (euCountryId === this.COUNTRIES_EXTRA_EUROPEAN_ECONOMIC_AREA_ID) {
      this.lpsForm.controls.extraEuCountry.enable();
    } else {
      this.lpsForm.controls.extraEuCountry.setValue(null);
      this.lpsForm.controls.extraEuCountry.disable();
    }
    this.subscribeToFormChanges();
  }

  private updateLpsDataOutput(formValues) {
    this.lpsDataOutput.emit(
      new LpsData(
        this.lpsData.lps,
        new PolicyLpsData(
          this.lpsData.policyLpsData.bManuallySet,
          formValues.currency,
          formValues.changeDate,
          formValues.exchangeRate,
          formValues.extraEuCountry,
          this.lpsData.policyLpsData.lpsAmount,
          this.getCountryById(formValues.euCountry),
          this.lpsData.policyLpsData.lpsType,
          this.lpsData.policyLpsData.lpsUse,
          this.lpsData.policyLpsData.rsAmount
        )
      )
    );
  }

  private getCountryById(id: string): Country {
    const foundCountry = this.euCountriesList.find(country => country.identification === id);
    return foundCountry || (id === '' ? this.euCountriesList.find(country => country.identification === '1') : null);
  }

  ngOnDestroy() {
    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }
  }

}

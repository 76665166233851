<rgi-rx-panel [ngClass]="{'rgi-gp-modal-s': !showComm1stYear && !showCommNextYears}">
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()">
    <div class="rgi-ui-card-title" translate>
      {{ this.data.title }}
    </div>
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button rgi-rx-button color="secondary" data-qa="rgi-gp-cancel-btn" translate (click)="cancel()">
      _GP_._BTN_._UNDO_
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary uppercase" (click)="saveCommissions()" data-qa="rgi-gp-save-btn"
            [disabled]="commissionsForm.invalid" translate>{{'_GP_._BTN_._SAVE_'}}
    </button>
  </rgi-rx-panel-footer>

  <ng-container [formGroup]="assignValuesForm">
    <table rgi-rx-table [dataSource]="[{}]">
      <ng-container rgiRxColumnDef="blank">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef class="rgi-gp-riskCellW"></td>
        <td rgi-rx-cell *rgiRxCellDef="let row"></td>
      </ng-container>

      <ng-container rgiRxColumnDef="{{percPurchaseNet}}">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._TITLE_._COMMISSION_PERCENTAGE_FIRST_YEAR_
        </td>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          <input rgiRxInput formControlName="assignFirstYear" type="number">
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="{{percCollNet}}">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._TITLE_._COMMISSION_PERCENTAGE_NEXT_YEAR_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          <input rgiRxInput formControlName="assignNextYear" type="number">
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="{{percFlex}}">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._TITLE_._COMMISSION_DISCOUNT_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          <input rgiRxInput formControlName="assignDiscount" type="number">
        </td>
      </ng-container>
      <ng-container rgiRxColumnDef="assign">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef class="rgi-gp-assignCellW"></td>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          <button rgi-rx-button color="info" type="submit"
                  (click)="assignFormValues()" translate>_GP_._BTN_._ASSIGN_
          </button>
        </td>
      </ng-container>

      <tr rgi-rx-header-row *rgiRxHeaderRowDef="commissionColumns"></tr>
      <tr rgi-rx-row *rgiRxRowDef="let row; columns: commissionColumns;"></tr>
    </table>
  </ng-container>

  <ng-container [formGroup]="commissionsForm">
    <table rgi-rx-table [dataSource]="dataSource">
      <ng-container rgiRxColumnDef="blank">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef class="rgi-gp-riskCellW"></td>
        <td rgi-rx-cell *rgiRxCellDef="let row">{{row.description}}</td>
      </ng-container>
      <ng-container rgiRxColumnDef="{{percPurchaseNet}}">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._TITLE_._COMMISSION_PERCENTAGE_FIRST_YEAR_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          <input *ngIf="row.percPurchaseNet" rgiRxInput type="number"
                 [formControlName]="'risk_'+row.id+'_comm_1st_year'">
        </td>
      </ng-container>
      <ng-container rgiRxColumnDef="{{percCollNet}}">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._TITLE_._COMMISSION_PERCENTAGE_NEXT_YEAR_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          <input *ngIf="row.percCollNet" rgiRxInput type="number" [formControlName]="'risk_'+row.id+'_comm_next_year'">
        </td>
      </ng-container>
      <ng-container rgiRxColumnDef="{{percFlex}}">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._TITLE_._COMMISSION_DISCOUNT_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          <input *ngIf="row.percFlex" rgiRxInput type="number" [formControlName]="'risk_'+row.id+'_comm_discount'">
        </td>
      </ng-container>
      <ng-container rgiRxColumnDef="assign">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef class="rgi-gp-assignCellW"></td>
        <td rgi-rx-cell *rgiRxCellDef="let row"></td>
      </ng-container>
      <tr rgi-rx-header-row *rgiRxHeaderRowDef="commissionColumns"></tr>
      <tr rgi-rx-row *rgiRxRowDef="let row; columns: commissionColumns;"></tr>
    </table>
  </ng-container>
</rgi-rx-panel>

import {DisplayGrid, GridType} from 'angular-gridster2';

export const options = {
  gridType: GridType.Fit,
  displayGrid: DisplayGrid.OnDragAndResize,
  draggable: { enabled: true },
  resizable: { enabled: true },
  disableWindowResize: true,
  scrollToNewItems: false,
  disableWarnings: false,
  ignoreMarginInRow: false,
  setGridSize: false,
  scale: 1
};

/**
 * native-date-time-format.class
 */
import {RgiRxDateTimeFormats} from './date-time-format.class';

export const RGI_RX_NATIVE_DATE_TIME_FORMATS: RgiRxDateTimeFormats = {
  parseInput: null,
  fullPickerInput: {year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric'},
  datePickerInput: {year: 'numeric', month: '2-digit', day: '2-digit'},
  timePickerInput: {hour: 'numeric', minute: 'numeric'},
  monthYearLabel: {year: 'numeric', month: 'short'},
  dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
  monthYearA11yLabel: {year: 'numeric', month: 'long'},
};

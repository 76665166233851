<div *ngIf="!isPolicyModifyData">
    <h4 class="no-document-header" *ngIf="!showDocumentList">
        {{ '_CLAIMS_._MESSAGE_._NO_DOCUMENTS_TO_UPLOAD' | translate }}</h4>

    <div *ngIf="showDocumentList">

        <h3 class=" title-header">{{ '_CLAIMS_._MANDATORY_DOCUMENTS' | translate }}</h3><br>

        <div class="container-fluid">
            <pnc-accordion [closeOthers]="false" [destroyOnHide]="false" #pncAccordionObbligatory="AccordionComponent">
                <pnc-panel id="document-category-{{documentCategory.categoryId}}"
                    *ngFor="let documentCategory of obbligatoryDocuments">
                    <ng-template pncPanelTitle>
                        <claims-document-category-panel-title contentEnabled="true" enabled="true"
                            panelId="document-category-{{documentCategory.categoryId}}"
                            [documentCategory]="documentCategory" [pncAccordion]="pncAccordionObbligatory"
                            (categoryChange)="onCategoryChange()" [policy]="policy" [hideUpload]="hideUpload">
                        </claims-document-category-panel-title>
                    </ng-template>
                    <ng-template pncPanelContent>
                        <div class="document-list">
                            <claims-document *ngFor="let document of documentCategory.documents" [document]="document"
                                (documentDelete)="onDocumentDelete($event, documentCategory)">
                            </claims-document>
                        </div>
                    </ng-template>
                </pnc-panel>
            </pnc-accordion>
        </div>


        <h3 class="title-header">{{ '_CLAIMS_._OTHER_DOCUMENTS' | translate }}</h3><br>

        <div class="container-fluid">
            <pnc-accordion [closeOthers]="false" [destroyOnHide]="false"
                #pncAccordionNonObbligatory="AccordionComponent">
                <pnc-panel id="document-category-{{documentCategory.categoryId}}"
                    *ngFor="let documentCategory of notObbligatoryDocuments">
                    <ng-template pncPanelTitle>
                        <claims-document-category-panel-title enabled="true"
                            panelId="document-category-{{documentCategory.categoryId}}"
                            [documentCategory]="documentCategory" [pncAccordion]="pncAccordionNonObbligatory"
                            [policy]="policy" (categoryChange)="onCategoryChange()" [hideUpload]="hideUpload">
                        </claims-document-category-panel-title>
                    </ng-template>
                    <ng-template pncPanelContent>
                        <div class="document-list">
                            <claims-document *ngFor="let document of documentCategory.documents" [document]="document"
                                (documentDelete)="onDocumentDelete($event, documentCategory)">
                            </claims-document>
                        </div>
                    </ng-template>
                </pnc-panel>
            </pnc-accordion>
        </div>
    </div>
</div>
import {GenericEntityId} from '../common/entities';
import {TaxDetailAPI} from './tax-detail';
import {ConfiguredAmount} from '../common/supplementary-tech-acc';

export class TechnicalAccounting {
  amount: number;
  appendix: string;
  commissionsAmount: number;
  currency: GenericEntityId;
  effect: string;
  node: GenericEntityId;
  status: GenericEntityId;
  type: GenericEntityId;
}

export type TechnicalAccountingList = TechnicalAccounting[];

export class SupplementaryTechnicalAccounting {
  amounts: SupplementaryTechnicalAccountingAmount[];
  visibleAmounts: ConfiguredAmount[];
  operationPremium: number;
  annualPremium: number;
}

export class SupplementaryTechnicalAccountingAmount {
  riskCode: string;
  riskDescription: string;
  presentInContract: boolean;
  net: number;
  netInterests: number;
  charges: number;
  chargesInterests: number;
  taxable: number;
  taxes: TaxDetailAPI[];
  totalTaxes: number;
  gross: number;
  collectedCommissions: number;
  purchasedCommissions: number;
}


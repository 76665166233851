import {Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {RgiRxSemanticStatus} from '@rgi/rx';

@Directive({
  selector: 'rgi-rx-tab-group[color]'
})
export class RgiRxTabGroupColorDirective implements OnChanges {

  @Output() changes = new EventEmitter<RgiRxSemanticStatus | "link">();

  constructor() {}

  @Input() color: RgiRxSemanticStatus | "link";

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.changes.next(this.color)
    }
  }
}


export interface RgiRxTabGroupLayoutChanges {
  tabsRow?: number;
  minWidthTab?: number;
  layout?: "grid" | "flex-grid" | "flex-auto" | "auto";
  minWidthTabUnits: 'px' | 'em' | 'rem';
  wordBreak?: boolean;
}

@Directive({
  selector: 'rgi-rx-tab-group[color][layout]'
})
export class RgiRxTabGroupLayoutDirective implements OnChanges {

  @Output() changes = new EventEmitter<RgiRxTabGroupLayoutChanges>();
  @Input() layout?:
    | "grid"
    | "flex-grid"
    | "flex-auto"
    | "auto";
  @Input() tabsRow?: number;
  @Input() minWidthTab?: number;
  @Input() minWidthTabUnits: 'px' | 'em' | 'rem' = 'px';
  @Input() wordBreak?: boolean

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.changes.next({
        tabsRow: this.tabsRow,
        minWidthTab: this.minWidthTab,
        layout: this.layout,
        wordBreak: this.wordBreak,
        minWidthTabUnits: 'px'
      });
    }
  }
}

import {Injectable} from '@angular/core';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {ROUTES_DETAIL, ROUTES_FULL_DETAIL, ROUTES_HOME, ROUTES_PARTY_EDITOR} from '../anag-constants/routes-constants';
import {AnagRouteData} from '../anag-model/anag-domain/anag-route-data';
import {AnagApiParty} from '../anag-model/anag-domain/anag-api-party';

@Injectable()
export class AnagIntegrationService {

  constructor(
    protected _routingService: RoutingService
  ) {
  }

  openPartyMerger(_idCard: string, _criteria: number, _status: number) {

  }

  openEditSubject(routeData: any, idSession?: string) {
    this._routingService.navigate(ROUTES_PARTY_EDITOR, routeData);
  }

  openDetailSubject(routeData: any, _id?: string) {
    this._routingService.navigate(ROUTES_DETAIL, routeData, _id);
  }

  openFullDetailSubject(routeData: any, _id: string) {
    this._routingService.navigate(ROUTES_FULL_DETAIL, routeData);
  }

  openLimitationManagement(_idCard: string, _subjectId: string, _nodeId: string) {

  }

  openHistory(_idCard: string, _subjectId: string, _nodeId: string) {

  }

  backToDetailPage(activeRoute: ActiveRoute, _idParentSession: string, _idSession: string) {
    this._routingService.navigate(ROUTES_DETAIL, activeRoute.getRouteData<AnagRouteData>(), activeRoute.id);
  }

  changeSession(idSession, idSessionOld) { }

  backToHome(activeRoute: ActiveRoute) {
    this._routingService.navigate(ROUTES_HOME, {}, activeRoute.id);
  }

  navigate(routingService: RoutingService, destinationRoute: string, routeData: any, activeRoute: ActiveRoute) {
    routingService.navigate(destinationRoute, routeData, activeRoute.id);
  }

  setPartyInContainerCache(party: AnagApiParty, id: string) {

  }

  changeTitle(id: string, keyTitle: string) {
  }
}

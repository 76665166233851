<ng-container [formGroup]="group">
<div [ngClass]="{'rgi-gp-flex-field-container': factor.editableCheckVisible}">
  <rgi-rx-form-field [ngClass]="{'rgi-gp-flex-70': factor.editableCheckVisible}">
    <label rgiRxLabel>
      <span>{{factor.description}}</span>
    </label>
    <input rgiRxInput
           rgiGpFocusElement
           [attr.data-qa]="'factor' + factor.code"
           formControlName="{{factor.code}}">
  </rgi-rx-form-field>
  <rgi-rx-form-field *ngIf="factor.editableCheckVisible">
    <input type="checkbox" rgiRxInput [attr.data-qa]="'factor' + factor.code + '_CHECK'"
           formControlName="{{factor.code + '_CHECK'}}" (click)="onCheckUpdate()">
    <label translate>_GP_._LABEL_._NOT_EDITABLE_</label>
  </rgi-rx-form-field>
</div>
</ng-container>

import {LicKarmaFundUtils} from '../../lic-karma-funds/utils/lic-karma-fund-utils';
import {LicObjectUtils} from '../../utils/lic-object-utils';
import {Fund} from '../../lic-fund/model/profile';
import {ToolDefinition} from '../../lic-investment-contract/model/tool-definition';
import {ToolConfDefinition} from '../../lic-investment-contract/model/tool-conf-definition';
import {PassProfileDefinition} from '../../lic-fund/model/PassProfileDefinition';
import { FACTOR_TOOL_CODE, FactorToolCode, Roles, ToolCode } from '../../enum/life-issue.enum';
import { PolicyModel } from '../../models/policy.model';
import { FactorCode } from '../../invest-standalone-session/utils/factor-code.enum';
import { PassUtils } from '../../invest-standalone-session/utils/PassUtils';
import { LifeRoleService } from '../../services/life-role.service';

export class LicToolUtils {

  public static isToolScheduledPremium(toolCode: string): boolean {
    return toolCode === ToolCode.SCHEDULED_PREMIUM;
  }

  public static isToolRiskReduction(toolCode: string): boolean {
    return toolCode === ToolCode.RISK_REDUCTION;
  }

  public static isToolPeriodicCoupon(toolCode: string): boolean {
    return toolCode === ToolCode.PERIODIC_COUPON;
  }

  public static isToolScheduledPartialWithdrawal(toolCode: string): boolean {
    return toolCode === ToolCode.SCHEDULED_PARTIAL_WITHDRAWAL;
  }

  public static isToolLockIn(toolCode: string): boolean {
    return toolCode === ToolCode.LOCK_IN;
  }

  public static isToolStopLoss(toolCode: string): boolean {
    return toolCode === ToolCode.STOP_LOSS;
  }

  public static isToolProgressiveSwitch(toolCode: string): boolean {
    return toolCode === ToolCode.PROGRESSIVE_SWITCH;
  }

  private static fractionConverter(rate: number): number {
    /*
      Frazionamento | NumRate Pass | IDkelia da associare
      Annuale	      |       1	     |           12
      Semestrale	  |       2      |          	6
      Trimestrale	  |       4	     |            7
      Bimestrale	  |       6	     |            9
      Mensile	      |       12	   |            8
     */
    const frequencyConversion = new Map<number, number>()
      .set(1, 12)
      .set(2, 6)
      .set(4, 7)
      .set(6, 9)
      .set(12, 8);
    return frequencyConversion.get(rate);
  }

  public static getDefaultFormValueOf(
    toolDefinition: ToolDefinition,
    valorized: boolean,
    defaultFunds: boolean,
    selectedProfilesFunds: Map<string, Fund[]>
  ): any {
    const obj: any = {
      factors: {},
      investmentProfiles: {},
      investmentFunds: {},
      disinvestmentProfiles: {},
      defaultFunds
    };
    toolDefinition.factors.forEach(factor => {
      obj.factors[factor.code] = valorized && !!factor.value ? factor.value : null;
    });
    obj.investmentProfiles = LicKarmaFundUtils.getProfileFormValueOf(
      LicObjectUtils.asValidArray(toolDefinition.investmentProfiles)
    );
    obj.investmentFunds = LicKarmaFundUtils.getFundFormValueOf(
      LicObjectUtils.asValidArray(toolDefinition.investmentProfiles)
    );
    obj.disinvestmentProfiles = LicKarmaFundUtils.getProfileFormValueOf(
      LicObjectUtils.asValidArray(toolDefinition.disinvestmentProfiles)
        .filter(profile => {
          selectedProfilesFunds.has(profile.id);
        })
        .map(profile => {
          const prfl: PassProfileDefinition = Object.assign({}, profile);
          prfl.funds = profile.funds.filter(fund => {
            const funds: Fund[] = selectedProfilesFunds.get(profile.id);
            return !!funds.find(fnd => fnd.id === fund.id);
          });
          return prfl;
        })
    );
    return obj;
  }

  public static getFactorValueByCode(toolDefinition: ToolDefinition, code: FactorToolCode): string | number | null {
    const factor: ToolConfDefinition = toolDefinition.factors.find(fct => fct.code === code);
    if (!!factor) {
      return factor.value;
    }
    return null;
  }

  public static getFactorStrutture(toolDefinition: ToolDefinition, mainProposal: PolicyModel, roleService: LifeRoleService) {
    const mappingFactor: ToolConfDefinition[] = LicObjectUtils.cloneObject(toolDefinition.factors);
    const mappedToolFactor = mappingFactor.reduce((gr, fac) => {
      if (fac.type === 'ENUM') {
        if (fac.values.length === 1 && !fac.value) {
          gr[fac.code] = fac.values[0].id;
          fac.value = fac.values[0].id;
        }
      }
      switch (fac.code) {
        case FACTOR_TOOL_CODE.EFFECTDATE_CODE:
          gr[fac.code] = PassUtils.getIsoDateValueFromFactorByCode(mainProposal.quote.product.factors, FactorCode.EFFECT_DATE);
          break;

        case FACTOR_TOOL_CODE.FREQUENCY_CODE:
          if (fac.readOnly && fac.values.length === 1) {
            gr[fac.code] = fac.values[0].id;
            break;
          }
          const policyFrequency = LicToolUtils.fractionConverter(Number(mainProposal.proposal.policyVersion.fractionation.numRate));
          const foundFreqOnValues = fac.values.find(f => f.id === fac.value);
          const foundPolFreqOnValues = fac.values.find(f => f.id === policyFrequency.toString());
          const frequency = !!foundFreqOnValues ? fac.value : foundPolFreqOnValues && foundPolFreqOnValues.id;
          gr[fac.code] = frequency;
          break;

        case FACTOR_TOOL_CODE.BENEFICIARY_ID_CODE:
          const benefId = LicToolUtils.getFactorValueByCode(toolDefinition, FACTOR_TOOL_CODE.BENEFICIARY_ID_CODE);
          let beneficiary;
          if (!!benefId) {
            beneficiary = roleService.fill(roleService.getStoredSubject(benefId.toString()));
          }
          beneficiary = !beneficiary ? mainProposal.proposal.lifeParty.find(lp => lp.party.partyRole === Roles.CONTRACTOR).party.extraInformations : beneficiary;
          gr[fac.code] = beneficiary;
          break;

        case FACTOR_TOOL_CODE.AMOUNT_CODE:
          let amount = fac.value;
          if (LicToolUtils.isToolScheduledPremium(toolDefinition.operationCodeId)) {
            amount = amount !== null && Number(amount) >= 0 ?
            amount :
            PassUtils.getNumberValueFromFactorByCode(mainProposal.quote.product.factors, FactorCode.PRRFI);
          }
          gr[fac.code] = amount;
          break;

        default:
          gr[fac.code] = fac.value;
          break;
      }
      return gr;
    }, {}) as {[key in FactorToolCode]: string | number | null};
    return mappedToolFactor;
  }
}

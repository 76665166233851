import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LicFundButtonComponent} from './component/lic-fund-button/lic-fund-button.component';
import {LicFundsGroupComponent} from './component/lic-funds-group/lic-funds-group.component';
import {LicFundsMessageComponent} from './component/lic-funds-message/lic-funds-message.component';
import {LicFundsPercentageMessageComponent} from './component/lic-funds-percentage-message/lic-funds-percentage-message.component';
import {LicFundsStepComponent} from './component/lic-funds-step/lic-funds-step.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {LicProfileStepComponent} from './component/lic-profile-step/lic-profile-step.component';
import {LicFinantialParamCalculatorComponent} from './component/lic-finantial-param-calculator/lic-finantial-param-calculator.component';
import {FundService} from './service/fund.service';
import {ToPercentage} from '../utils/pipes/percentage.pipe';
import { RgiRxExpansionModule, RgiRxTableModule } from '@rgi/rx/ui';
import { CustomNumberModule } from '../custom-number/custom-number.module';
import { RgiCountryLayerModule } from '@rgi/country-layer';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
  declarations: [
    LicFundButtonComponent,
    LicFundsGroupComponent,
    LicFundsMessageComponent,
    LicFundsPercentageMessageComponent,
    LicFundsStepComponent,
    LicProfileStepComponent,
    LicFinantialParamCalculatorComponent,
    ToPercentage
  ],
  exports: [
    LicFundsStepComponent,
    LicFundsMessageComponent,
    LicFundButtonComponent,
    LicFundsGroupComponent,
    LicProfileStepComponent,
    LicFinantialParamCalculatorComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PortalNgCoreModule,
    RgiRxExpansionModule,
    RgiRxTableModule,
    CustomNumberModule,
    RgiCountryLayerModule,
    RgiRxI18nModule
  ],
  providers: [
    FundService
  ]
})
export class LicFundModule { }

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { PV_TOKEN } from '../../../../models/consts/lpc-consts';

@Injectable({
  providedIn: 'root'
})
export class AddContactService {

    baseApiUrl: string;
    addressReceived = false;

    constructor(
        public httpClient: HttpClient,
        @Inject(PV_TOKEN.ENV) protected environment: any
    ) {
        this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    }

    getCountries(): Observable<any> {
        return this.httpClient.get(this.baseApiUrl + '/subapp/countries');
    }

    getProvinces(): Observable<any> {
        return this.httpClient.get(this.baseApiUrl + '/subapp/provinces');
    }

    getAddressConfiguration(country): Observable<any> {
        return this.httpClient.get(this.baseApiUrl + '/subapp/addressconfig/' + country + '?function=PortfolioArchiveSubject');
    }

    getCitiesByProvince(province): Observable<any> {
        return this.httpClient.get(this.baseApiUrl + '/subapp/cities/' + province);
    }

    getToponyms(): Observable<any> {
        return this.httpClient.get(this.baseApiUrl + '/subapp/toponyms');
    }

    getZipByCityAndProvince(city, province): Observable<any> {
        return this.httpClient.get(this.baseApiUrl + '/subapp/zips/' + city + '/' + province);
    }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { RgiCountryLayerModule } from '@rgi/country-layer';
import { CustomNumberFormatModule } from '../lpc-formule-modal/utils/lpc-custom-number-format.module';
import { LpcLoanRefundTableComponent } from './lpc-loan-refund-table.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';

@NgModule({
    declarations: [LpcLoanRefundTableComponent],
    exports: [LpcLoanRefundTableComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RgiCountryLayerModule,
        FormsModule,
        NgSelectModule,
        CustomNumberFormatModule,
        RgiRxI18nModule
    ]
})
export class LpcLoanRefundTableModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProposalOtpComponent } from './proposal-otp-component/proposal-otp.component';
import { ProposalOtpService } from './proposal-otp-service/proposal-otp.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import {RgiRxSnackbarModule} from '@rgi/rx/ui';

@NgModule({

  declarations: [
    ProposalOtpComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RgiRxI18nModule,
    TranslateModule.forRoot(),
    PortalNgCoreModule,
    RgiRxSnackbarModule,
  ],
  providers: [
    ProposalOtpService
  ],
  exports: [
    ProposalOtpComponent
  ]

})

export class ProposalOtpModule {}

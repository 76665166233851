<template #modalOverlay></template>
<template #productAssetModalOverlay></template>
<div *ngIf="contractAvailable">
  <div *ngIf="!issueCompleted">
    <div *ngIf="entitledParty && !isEntitledBeforeQuotation()" class="row nmf_informative_message">
      <span class="rgifont message-icon rgi-exclamation-circle">&nbsp;&nbsp;</span>
      <span translate>ENTITLED:</span><span> {{ entitledParty.nominative }}</span><span> - </span>
      <span translate>Fiscal Code:</span><span> {{ entitledParty.fiscalCode }}</span><span> - </span>
      <span translate>Birth date:</span><span> {{ entitledParty.dateOfBirth | date: 'dd/MM/yyyy' }}</span>
      <span *ngIf="entitledParty.residence?.formatAddress"> - </span>
      <span *ngIf="entitledParty.residence?.formatAddress" translate>Address:</span>
      <span *ngIf="entitledParty.residence?.formatAddress"> {{ entitledParty.residence?.formatAddress }}</span>
    </div>
    <br clear="all">

    <!-- PARTIES ----------------------------------------------------------------------------------->
    <div class="nmf_cont_page" *ngIf="!isEntitledBeforeQuotation()">
      <div class="nmf_cont_sez">
        <div class="nmf_cont_titsez nmf_cont_titsez_color">
          <span class="header-icon rgifont rgi-riparti_coass nfm_font_size"></span>
          <h1 translate>Parties</h1>
        </div>
        <div class="nmf_cont_dati">
          <mic-parties (editQuotation)="editQuotation()" (eventPropagation)="eventPropagation.emit($event)"
                       (nonBlockingMessagesRoles)="addPartyRolesNonBlockingMessaging($event)" (subscriberChanged)="onSubscriberChanged()"
                       (subscriberRemoved)="onSubscriberRemoved()"
                       (validationMessagesRoles)="addPartyRolesMessaging($event)"
                       (valuesChanged)="valuesChanged($event)"
                       [substConfListener]="substConfigUpdateEmitter.asObservable()"
                       [changeAssetListener]="changeAssetEmitter.asObservable()">
          </mic-parties>
        </div>
      </div>
    </div>

    <!-- QUESTIONNAIRES ----------------------------------------------------------------------------->
    <mic-questionnaires (eventPropagation)="eventPropagation.emit($event)" (valuesChanged)="valuesChanged($event)"
                        (warningsEventEmitter)="getWarningMessages('WARN')"
                        *ngIf="showQuestionnaires && questionnaires && questionnaires.length > 0"
                        [questionnaires]="questionnaires">
    </mic-questionnaires>

    <!-- BOND -------------------------------------------------------------------------------------->
    <div *ngIf="constraint?.constraint" class="nmf_cont_page">
      <div class="nmf_cont_sez">
        <div class="nmf_cont_titsez nmf_cont_titsez_color">
          <span class="header-icon rgifont rgi-traffic-cone nfm_font_size"></span>
          <h1 translate>Liens</h1>
        </div>
        <mic-liens (eventPropagation)="eventPropagation.emit($event)"
                   (validationMessagesBond)="addPartyLiensMessaging($event)" (valuesChanged)="valuesChanged($event)">
        </mic-liens>
        <br>
        <br>
      </div>
    </div>
  </div>

  <!-- ACCORDION POLICY ADDRESS ----------------------------------------------------------------------------->
  <mic-addresses (valuesChanged)="valuesChanged($event)" [refreshPolicyContacts]="refreshPolicyContacts.asObservable()"
                 [retrieveNewContacts]="retrieveNewContacts.asObservable()"
                 [viewEmailAddress]="viewEmailAddress"
                 [viewPostalAddresses]="viewPostalAddresses"
                 [isFormEnabled]="isSectionEditable('policy_address')">
  </mic-addresses>

  <div *ngIf="documentManagerEnabled && !isSubstitution" class="nmf_cont_page">
    <div class="nmf_cont_sez">
      <rgi-documents [contractId]="contractId"
                     [uploadDownloadEnabled]="proposalNumber!==undefined"
                     (startDocument)="startDocuments($event)"
                     (eventModifiedDocument)="verifyDocuments($event)">

      </rgi-documents>
    </div>
  </div>

  <div *ngIf="proposalStaus" class="nmf_cont_dati">
    <span  translate>Proposal status: </span>
    <span translate>{{this.translate.instant(proposalStaus?.description)}}</span>
  </div>

  <!-- Error messaging -->
  <div *ngIf="validationMessages?.length > 0
      && enableWarningIcon && !showFloatingMessages" class="errors-warnings-floating-wrapper ng-scope"
       id="errors-warnings-floating-wrapper">
    <div (click)="onWarningIconClick()" class="errors-warnings-floating-icon minimized">
      <span class="rgifont rgi-exclamation-triangle"></span>
    </div>
  </div>

  <div #errorsElement class="errors-warnings-floating-wrapper fix-to-bottom">
    <div *ngIf="(validationMessages?.length > 0
        || nonBlockingMessages?.length > 0) && showFloatingMessages" class="errors-warnings-floating-messages"
         id="errors-warnings-floating-messages">
      <div class="content-errors nmf_content-errors">
        <pnc-form-message [messages]="nonBlockingMessages" data-qa="action-error-messages" detail="validationError"
                          objectType="complex" type="warning">
        </pnc-form-message>
        <pnc-form-message [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
                          objectType="complex" type="error">
        </pnc-form-message>
        <div (click)="onCloseErrorsClick()" *ngIf="(validationMessages?.length > 0
                || nonBlockingMessages?.length > 0) && showFloatingMessages" class="errors-warnings-floating-icon expanded">
          <span class="rgifont rgi-close"></span>
        </div>
        <div class="nmf_triangle"></div>
      </div>
    </div>
  </div>

  <div *ngIf="!showFloatingMessages" id="error-container">
    <pnc-form-message *ngIf="nonBlockingMessages?.length > 0" [messages]="nonBlockingMessages" data-qa="action-error-messages"
                      detail="validationError" objectType="complex" type="warning">
    </pnc-form-message>
    <pnc-form-message *ngIf="validationMessages?.length > 0" [messages]="validationMessages" data-qa="action-error-messages"
                      detail="validationError" objectType="complex" type="error">
    </pnc-form-message>
  </div>

  <br>

  <!-- Button bar -->
  <div class="btn-group btn-group-justified">
    <div *ngIf="!isSubstitution" class="btn-group">
      <button (click)="editQuotation()" class="btn btn-warning btn-secondary pull-right text-uppercase" data-qa="edit-quot-actions-btn"
              type="button">
        <span translate>EDIT QUOTATION</span>
      </button>
    </div>
    <div *ngIf="isEditQuote" class="btn-group">
      <button (click)="saveQuote()" [disabled]="disableSaveQuote" class="btn btn-warning btn-secondary pull-right text-uppercase"
              data-qa="btn-quotation-savequote" type="button">
        <span translate>SAVE QUOTE</span>
      </button>
    </div>
    <div *ngIf="showDocuments && !isSubstitution && !isEditQuote && !isAmendment" class="btn-group">
      <button [disabled]="true" class="btn btn-warning btn-secondary pull-right text-uppercase"
              data-qa="documents-actions-btn" type="button">
        <span><span translate>DOCUMENTS</span></span>
      </button>
    </div>
    <div *ngIf="showDocuments && !isSubstitution && !isEditQuote && !isAmendment" class="btn-group">
      <button (click)="proposalPrint()" class="btn btn-warning btn-secondary pull-right text-uppercase" data-qa="documents-actions-btn"
              type="button">
        <span><span translate>PROPOSAL PRINT</span></span>
      </button>
    </div>
    <div *ngIf="!isSubstitution && !isEditQuote && !isAmendment" class="btn-group">
      <button (click)="saveProposal()" [disabled]="!enableSaveProposal || !questionnairesCompleted || !surveyCompleted"
              class="btn btn-warning btn-secondary pull-right text-uppercase" data-qa="save-prop-actions-btn"
              type="button">
        <span translate>SAVE PROPOSAL</span>
      </button>
    </div>
    <div *ngIf="!isSubstitution && !isEditQuote && !isAmendment" class="btn-group">
      <button (click)="sendPrecontractualDocuments()" [disabled]="validationMessages?.length > 0 || !questionnairesCompleted || !surveyCompleted"
              class="btn btn-warning btn-secondary pull-right text-uppercase" data-qa="send-precontractual-documents-actions-btn"
              type="button">
        <span translate>SEND PRE-CONTRACTUAL DOCUMENTS</span>
      </button>
    </div>
    <div *ngIf="plate && proposalStaus && proposalStaus.code === proposalStatusTypes.STATE_VALID_PROPOSAL && !isSubstitution  && !isEditQuote"
         class="btn-group">
      <button (click)="goToMandatoryData()" [disabled]="validationMessages.length > 0 || !isPurchasableProposal || (!documentsCompleted && isBindingProposal)" class="btn btn-warning pull-right text-uppercase"
              data-qa="next-actions-btn"
              type="button">
        <span translate>NEXT</span>
      </button>
    </div>
    <div *ngIf="proposalStaus && proposalStaus.code === proposalStatusTypes.STATE_VALID_POLICY && isAmendment"
         class="btn-group">
      <button (click)="goToMandatoryData()" [disabled]="validationMessages.length > 0" class="btn btn-warning pull-right text-uppercase"
              data-qa="next-actions-btn"
              type="button">
        <span translate>NEXT</span>
      </button>
    </div>
    <div *ngIf="isSubstitution && !isEditQuote" class="btn-group">
      <button (click)="substitutionNext()" [disabled]="validationMessages.length > 0" class="btn btn-warning pull-right text-uppercase"
              data-qa="next-actions-btn" type="button">
        <span translate>NEXT</span>
      </button>
    </div>
    <div *ngIf="plate && proposalStaus && proposalStaus.code === proposalStatusTypes.STATE_PROPOSED_TO_BE_AUTHORIZED && !isSubstitution && !isEditQuote && !isAmendment"
         class="btn-group">
      <button *ngIf="isBindingProposal" (click)="onAuthorize()" [disabled]="!enableAuthorization || validationMessages.length > 0 || (!documentsCompleted && isBindingProposal)" class="btn btn-warning pull-right text-uppercase"
              data-qa="issue-actions-btn"
              type="button">
        <span translate>AUTHORIZATION REQUEST</span>
      </button>
      <button *ngIf="!isBindingProposal" (click)="goToMandatoryData()" [disabled]="validationMessages.length > 0 || !isPurchasableProposal || (!documentsCompleted && isBindingProposal)" class="btn btn-warning pull-right text-uppercase"
              data-qa="next-actions-btn"
              type="button">
        <span translate>NEXT</span>
      </button>
    </div>
  </div>

  <div *ngIf="issueCompleted">
    <mic-policy-summary></mic-policy-summary>
  </div>
</div>

import {PremiumDetailAPI} from './premium-api';
import {TaxDetailAPI} from '../tax-detail';

export class InstalmentDetailAPI {
  premium: PremiumDetailAPI;
  amountByCover: InstalmentPremiumDetail[];
  amountByRisk: InstalmentPremiumDetail[];
  fees: FeesDetail;
}

export class InstalmentPremiumDetail {
  accessories?: string;
  additionalLabels?: any[];
  antiracket?: string;
  description?: string;
  gross?: string;
  net?: string;
  rate?: string;
  taxable?: string;
  taxes?: string;
  taxesDetails: TaxDetailAPI[];
}

export class FeesDetail {
  commissions: string;
  premium: PremiumDetailAPI;
}

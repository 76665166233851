import { Component, forwardRef, Inject, Input, OnDestroy, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TypeRefund } from '../../models/enums/vita.enum';
import { LoanRefundOperationData, LoanRefundRequestData, LoanTableDefinitions } from '../../models/loan.model';
import { FormFieldsDefinition, Values } from '../../models/postsales-operations-response.model';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import { LpcCurrencyCache, CurrencyCacheService } from '../../services/currency-cache.service';

@Component({
  selector: 'lpc-loan-refund-table',
  templateUrl: './lpc-loan-refund-table.component.html',
  styleUrls: ['./lpc-loan-refund-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcLoanRefundTableComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcLoanRefundTableComponent),
      multi: true
    }
  ]
})
export class LpcLoanRefundTableComponent implements OnInit, OnDestroy, ControlValueAccessor {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  @Input() public loanDefinitions: LoanTableDefinitions[] = [];
  @Input() protected prevalorizedLoan: LoanRefundOperationData = null;

  private subscriptions: Subscription[] = [];

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    loans: new UntypedFormArray([])
  });

  constructor(@Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit(): void {
    console.log('loan', this.loanDefinitions);
    this.populateFormArray();
    this.subscriptions.push(
      this.formGroup.valueChanges.pipe()
      .subscribe(value => {
        this.writeValue(value);
        this.onChange(value);
      })
    );
  }

  private populateFormArray() {
    this.loanDefinitions.forEach((loan, index) => {
      let selectedLoanId = null;
      const selectedTypeOfRefund: UntypedFormControl = new UntypedFormControl({value: loan.typeOfRefund.value, disabled: true});
      const refundAmount: UntypedFormControl = new UntypedFormControl(null);
      if (!!this.prevalorizedLoan && this.prevalorizedLoan.selectedLoanId === loan.id) {
        selectedLoanId = this.prevalorizedLoan.selectedLoanId;
        selectedTypeOfRefund.setValue(this.prevalorizedLoan.selectedTypeOfRefund);
        selectedTypeOfRefund.enable();
        refundAmount.setValue(this.prevalorizedLoan.refundAmount);
      }
      const group = new UntypedFormGroup({
        selectedLoanId: new UntypedFormControl(selectedLoanId, Validators.required),
        selectedTypeOfRefund,
      });
      if (!!refundAmount.value) {
        group.addControl('refundAmount', refundAmount);
      }
      (this.formGroup.get('loans') as UntypedFormArray).push(group);
    });
  }

  changeTypeOfRefund(typeOfRefund: Values, groupId: string, loan: LoanTableDefinitions) {
    if (TypeRefund.PRES_TIPO_RIMBORSO_PARZIALE === typeOfRefund.id) {
      const maxValueImportable = loan.residualAmount;
      const validators: ValidatorFn[] = [Validators.required, Validators.max(Number(maxValueImportable)), Validators.min(0.01)];
      ((this.formGroup.get('loans') as UntypedFormArray)
      .get(groupId) as UntypedFormGroup)
      .addControl('refundAmount', new UntypedFormControl(null, validators));
    } else {
      this.deleteRefundAmountControl(groupId);
    }
  }

  private deleteRefundAmountControl(groupId: string) {
    if ((this.formGroup.get('loans') as UntypedFormArray).get(groupId).get('refundAmount')) {
      (this.formGroup.get('loans') as UntypedFormArray).get(groupId).get('refundAmount').reset();
      ((this.formGroup.get('loans') as UntypedFormArray).get(groupId) as UntypedFormGroup).removeControl('refundAmount');
    }
  }

  selectedLoan(groupId: string, loan: LoanTableDefinitions) {
    const formArray = (this.formGroup.get('loans') as UntypedFormArray);
    Object.keys(formArray.controls)
    .filter(id => id !== groupId)
    .forEach(id => {
      formArray.get(id).get('selectedLoanId').clearValidators();
      formArray.get(id).get('selectedTypeOfRefund').disable();
      this.deleteRefundAmountControl(id);
      formArray.get(id).reset();
    });
    formArray.get(groupId).get('selectedTypeOfRefund').enable();
    formArray.get(groupId).get('selectedTypeOfRefund').setValidators(this.getValidator(loan.typeOfRefund));
  }

  getValidator(typeOfRefund: FormFieldsDefinition): ValidatorFn[] {
    const validator: ValidatorFn[] = [];
    if (typeOfRefund.mandatory) {
      validator.push(Validators.required);
    }
    return validator;
  }

  writeValue(obj: any[]): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onTouch() {
  }

  onChange(obj: any) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  validate(control: any) {
    const errors: { [key: string]: string } = {};
    if (!!control.value && !!control.value.loans) {
      Object.keys(control.value.loans).forEach(key => {
        const group = (this.formGroup.get('loans') as UntypedFormArray).get(key);
        if (!!group) {
          if (group.invalid && group.get('selectedTypeOfRefund').hasError('required')) {
            Object.assign(errors, {error: 'lpc_error_mandatory_selected_type_of_refund'});
          }
          if (group.invalid && !!group.get('refundAmount') && group.get('refundAmount').hasError('max')) {
            Object.assign(errors, {error: 'lpc_negative_amount'});
          }
          if (group.invalid && !!group.get('refundAmount') && group.get('refundAmount').hasError('required')) {
            Object.assign(errors, {error: 'lpc_error_mandatory_fields'});
          }
        }
      });
    }
    return !!Object.keys(errors).length ? errors : null;
  }

  getSelectedLoanSummary(): LoanTableDefinitions {
    const rawValue = (!!this.formGroup.controls && !!this.formGroup.controls.loans) ?
    (this.formGroup.controls.loans as UntypedFormArray).getRawValue() : [];

    if (!!rawValue.length) {
      const filteredLoan: LoanRefundRequestData[] = rawValue.filter(selectedLoan => !!selectedLoan.selectedLoanId);
      const reduced: LoanRefundRequestData = !!filteredLoan.length ? filteredLoan.reduce(val => val) : null;
      if (!!reduced && !!reduced.selectedTypeOfRefund) {
        // eslint-disable-next-line max-len
        const returnObj: LoanTableDefinitions = PlcObjectUtils.clone(this.loanDefinitions.find(val => val.id === reduced.selectedLoanId));
        if (TypeRefund.PRES_TIPO_RIMBORSO_PARZIALE === reduced.selectedTypeOfRefund) {
          returnObj.amountRefund = !!reduced.refundAmount ? reduced.refundAmount.toString() : returnObj.amountRefund;
        } else {
          returnObj.amountRefund = returnObj.residualAmount;
        }
        returnObj.typeOfRefund.value = returnObj.typeOfRefund.values.find(val => val.id === reduced.selectedTypeOfRefund).description;
        return returnObj;
      }
    }
    return ;
  }
}

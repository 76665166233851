import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {Factor} from '../../models/factor.model';
import { EMPTY_STR } from '../../models/consts/lpc-consts';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';
import { FACTOR_TYPE } from '../../models/enums/vita.enum';

@Directive({
  selector: '[lpcFormatFactor]',
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class FormatFactorDirective implements OnInit {
  @Input() factorInput: Factor;
  element: ElementRef;

  constructor(
    el: ElementRef,
    public decPipe: RgiCountryLayerNumberFormatPipe
  ) {
    this.element = el;
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    if ((this.element.nativeElement.value != null && this.element.nativeElement.value !== '-1' &&
      this.element.nativeElement.value !== EMPTY_STR) && this.factorInput.formatter != null && this.factorInput.type === '1') {
      this.formattingInputNumber();
    }
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    if ((this.element.nativeElement.value != null && this.element.nativeElement.value !== '-1' &&
      this.element.nativeElement.value !== EMPTY_STR) && this.factorInput.formatter != null && this.factorInput.type === '1') {
      this.resetFormat();
    }
  }

  @HostListener('keypress', ['$event'])
  onkeydown(event) {
    const value = event.key;
    const fieldVal = this.getNewString(event);

    if (this.factorInput.type && this.factorInput.type.toString() === FACTOR_TYPE.NUMERIC) {
      const regexForKeyPress = /[\d\.,]/;
      if (regexForKeyPress.test(value)) {
        const regexForValue = /^\d+([\.,]\d{0,2})?$/;
        if (regexForValue.test(fieldVal)) {
          return true;
        }
      }
    } else {
      return true;
    }
    return false;
  }

  getNewString(event) {
    const valoreAttualeInput = event.target.value;
    const key = event.key;
    const splitValoreAttualeInput = valoreAttualeInput.split(EMPTY_STR);
    splitValoreAttualeInput.splice(event.target.selectionStart, (event.target.selectionEnd - event.target.selectionStart), key);
    const nuovoValore = splitValoreAttualeInput.join(EMPTY_STR);
    return nuovoValore;
  }

  @HostListener('keyup', ['$event.key'])
  onkeyup(value) {
    if (this.factorInput.type !== '5') {
      this.element.nativeElement.value = this.element.nativeElement.value.replace('.', ',');
    }
    return true;
  }

  ngOnInit() {
    if (this.factorInput.formatter != null && this.factorInput.type === '1') {
      if (this.factorInput.formatter.maxLen !== -1 && this.factorInput.formatter.maxLen !== 0) {
        this.element.nativeElement.maxLength = this.factorInput.formatter.maxLen;
      }
      if (this.element.nativeElement.value != null && this.element.nativeElement.value !== '-1' &&
        this.element.nativeElement.value !== EMPTY_STR) {
        this.formattingInputNumber();
      }
    }
  }

  resetFormat() {
    const regex = /[.,\s]/g;
    this.element.nativeElement.value = this.element.nativeElement.value.replace(regex, EMPTY_STR);

    const decimal = (this.element.nativeElement.value.substring(
      this.element.nativeElement.value.length - (+this.factorInput.formatter.precision),
      this.element.nativeElement.value.length));

    const integer = (this.element.nativeElement.value.substring(
      0,
      this.element.nativeElement.value.length - (+this.factorInput.formatter.precision)));

    if (+decimal === 0) {
      this.element.nativeElement.value = integer;
    } else {
      this.element.nativeElement.value = integer + '.' + decimal;
    }

  }

  formattingInputNumber() {
    this.element.nativeElement.value = this.element.nativeElement.value.replace(',', '.');

    const decimal = +this.factorInput.formatter.precision;
    if (decimal > 0) {
      const decimalNumberFormatterOptions: Intl.NumberFormatOptions = {
        style: 'decimal',
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal
      };
      if (this.factorInput.formatter.grouping && +this.element.nativeElement.value != null) {
        this.element.nativeElement.value = this.decPipe
          .transform(+this.element.nativeElement.value, '' , decimalNumberFormatterOptions);
      } else {
        const regex = /[.\s]/g;
        this.element.nativeElement.value = this.decPipe
          .transform(+this.element.nativeElement.value, '', decimalNumberFormatterOptions).replace(regex, EMPTY_STR);
      }
    } else {
      if (this.factorInput.formatter.grouping && +this.element.nativeElement.value != null) {
        this.element.nativeElement.value = this.decPipe
          .transform(+this.element.nativeElement.value);
      }
    }
  }

}

export enum PlateType {

  NEW = 'NEW',
  OLD = 'OLD',
  TEST = 'TEST',
  FOREIGN = 'FOREIGN',
  TEMPORARY = 'TEMPORARY',
  SPECIAL = 'SPECIAL',
  CHASSIS = 'CHASSIS',
  TROLLY_BUS = 'TROLLY_BUS',
  TECH_SUITABILITY_CERTIFICATES = 'TECH_SUITABILITY_CERTIFICATES'

}

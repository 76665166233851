<button class="rgi-ui-datepicker-control-button rgi-ui-datepicker-control-arrow-button"
        [disabled]="!previousEnabled()" [attr.aria-label]="'RGI_RX.DATEPICKER.PREV_MULTI_YEAR_LABEL' | translate"
        type="button" tabindex="0" (click)="prevYearList($event)">
    <span class="rgi-ui-datepicker-control-button-content rgi-ui-icon-left-arrow" tabindex="-1">
    </span>
</button>
<table class="rgi-ui-datepicker-calendar-table rgi-ui-datepicker-calendar-multi-year-table">
  <thead class="rgi-ui-datepicker-calendar-header">
  <tr>
    <th colspan="3">{{tableHeader}}</th>
  </tr>
  </thead>
  <tbody rgi-rx-calendar-body role="grid"
         [rows]="years" [numCols]="3" [cellRatio]="3 / 7"
         [activeCell]="activeCell"
         [todayValue]="todayYear"
         [selectedValues]="selectedYears"
         [selectMode]="selectMode"
         (keydown)="handleCalendarKeydown($event)"
         (select)="selectCalendarCell($event)"></tbody>
</table>
<button class="rgi-ui-datepicker-control-button rgi-ui-datepicker-control-arrow-button"
        [disabled]="!nextEnabled()" [attr.aria-label]="'RGI_RX.DATEPICKER.NEXT_MULTI_YEAR_LABEL' | translate"
        type="button" tabindex="0" (click)="nextYearList($event)">
    <span class="rgi-ui-datepicker-control-button-content rgi-ui-icon-right-arrow" tabindex="-1">
    </span>
</button>

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AvailableOperation } from '../models/availableOperation';
import { APP_SETTING, EMPTY_STR, PV_TOKEN } from '../models/consts/lpc-consts';
import { PoliciesDetail, Policy } from '../models/life-detail.model';
import {
  DataInputField,
  PostsalesError,
  PostsalesOperationObject,
  PostsalesOperationsResponse,
  PostsalesOperationsResponseData,
  Role
} from '../models/postsales-operations-response.model';
import { PostsalesSession } from '../models/postsales-session';
import { AbsOperationComponent } from '../operation-components/abs-operation-component/abs-operation.component';
import { PlcResponseUtils } from '../utils/plc-response-utils';
import { PlcOperationUtils } from './../utils/plc-operations-utils';
import { LoaderService } from './loader.service';
import { PostsalesOperations } from './postsales-operations.interface';
import { SystemPropertiesService } from './system-properties.service';
import {DocumentService} from './document.service';
import { CurrencyCacheService, LpcCurrencyCache } from './currency-cache.service';

export const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

@Injectable({
  providedIn: 'root'
})
export class PostsalesOperationsService implements PostsalesOperations {

  protected $session: PostsalesSession;
  protected readonly baseApiUrl: string;
  private policyNumber: string;
  private draftResponse;
  private operation: string;
  private operationDescription: string;
  protected draftId: string;
  private sessionId: string;
  private restBaseContractsUrl = 'life/contracts/';
  private _envPath: string;
  private _baseUrl: string;

  private $errors: PostsalesError[] = [];
  // mappa per convertire gli step degli errori che devono essere reindirizzati verso un'altro step
  // esempio => stepDaBE : 'stepFinaleFE'
  private errorMapping = {
    recalculateQuote: 'quotation'
  };

  public documentsConfig = null;


  public get errors(): PostsalesError[] {
    return this.$errors;
  }

  public set errors(errors: PostsalesError[]) {
    this.$errors = errors ? errors : [];
  }

  get lastInitializedSession(): PostsalesSession {
    return this.$session;
  }

  public operationUrls = PlcOperationUtils.getOperationUrls();

  public get envPath(): string {
    return this._envPath;
  }

  public set envPath(envPath: string) {
    this._envPath = envPath;
  }

  public get baseUrl(): string {
    return this._baseUrl;
  }

  public set baseUrl(baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  protected getOperationUrl() {
    if (!!this.session && this.session.isFinancial) {
      return Object.assign({}, this.operationUrls);
    } else {
      return this.operationUrls;
    }
  }

  public opCodesAndSessionNames(): Map<string, string> {
    return PlcOperationUtils.getOperationConstants();
  }

  public hasOPCode(code: string): boolean {
    return this.opCodesAndSessionNames().has(code);
  }

  constructor(
    protected httpClient: HttpClient,
    @Inject(PV_TOKEN.ENV) protected environment: any,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Inject(PV_TOKEN.CORE_AUTH_SERVICE) protected authService: any,
    protected loaderService: LoaderService,
    protected systemPropertiesService: SystemPropertiesService,
    protected documentService: DocumentService,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService
  ) {
    this.baseApiUrl = environment.api.portal.host;
    this.envPath = this.environment.api.portal.host + this.environment.api.portal.path;
    this.baseUrl = this.environment.api.portal.host + this.environment.api.portal.path + APP_SETTING.REST_BASE_URL
      + this.restBaseContractsUrl;
    // this is temporary, we should be able soon to get this info from request header
    if (!!this.currencyService && !this.currencyService.locale) {
      this.currencyService.locale = 'IT';
    }  
  }

  public getNodeCode(): string {
    return this.authService.getSalePointCode();
  }

  public getDocumentService(): DocumentService {
    return this.documentService;
  }

  // TODO: remove this from service, AbsOperationComponent already has it (should be on component)
  public hasBlockingErrorsOnStep(...steps: string[]): boolean {
    return !!this.errors.filter(
      error => error.severity === 'error' && (error.context === 'flow' || !!steps.includes(error.context))
    ).length;
  }

  public createDraft(session: PostsalesSession): Observable<PostsalesOperationObject> {
    let createDraftResponse = null;
    return this.loaderService.registerBeCall(this.httpClient
      .post<PostsalesOperationsResponse>(
        this.baseUrl + session.policyNumber + '/operations/' + this.getOperationUrl()[session.operation] + '/drafts',
        {
          contractId: session.policyNumber,
          username: this.authService.getOperator().username,
          causeCode: session.operation,
          loginNodeCode: this.authService.getSalePointCode(),
          authorizationId: session.authorizationId
        }
      ).pipe(
        tap(response => {
          this.$session.productId = response.data.productId;
          this.$session.managementNodeCode = response.data.managementNodeCode;
          this.errors = response.errors;
          const blockingError = !!this.errors && this.errors.find(err => err.severity === 'error');
          if (!!blockingError) {
            throw new Error(blockingError.errorMessage);
          }
          createDraftResponse = response;
        }),
        switchMap(() => {
          return this.systemPropertiesService.loadAllProperties();
        }),
        map(response => PlcResponseUtils.responseToObject(createDraftResponse))
      ));
  }

  public getStoredSystemProp(code: string): any {
    return this.systemPropertiesService.getStoredSystemProp(code);
  }

  public updateDraft(
    session: PostsalesSession,
    dates: { [key: string]: Date },
    operationRoles: {[key: string]: Role },
    data: { [key: string]: any },
    modified: { [key: string]: boolean },
    step?: string,
    reload?: boolean,
    opDataType?: string,
    questionnaires ?: {}
  ): Observable<PostsalesOperationObject> {
    const d: DataInputField[] = Object.keys(dates).map(key => {
      return {
        code: key,
        label: key,
        value: (dates[key] && dates[key] instanceof Date) ? dates[key].toISOString() : null,
        userInput: modified[key]
      };
    });
    // Costruisco la request
    const requestData = this.createRequestData(
      session, d, questionnaires, step, reload, operationRoles, opDataType, data
    );
    return this.loaderService.registerBeCall(
      this.httpClient
      .post<PostsalesOperationsResponse>(
        this.baseUrl + session.policyNumber + '/operations/' + this.getOperationUrl()[session.operation] + '/drafts/' + session.draft,
        requestData,
        {headers}
      )
      .pipe(
        tap(response => {
          response.errors = this.mapErrorsStep(response.errors);
          if (!!step) {
              this.errors = response.errors
                .filter(err => (err.context === 'flow' || err.context === step) || err.severity !== 'error');
            } else {
              this.errors = response.errors;
            }
        }),
          map(PlcResponseUtils.responseToObject)
        ));
  }

  createRequestData(session, dates, questionnaires, step, reload, operationRoles, opDataType, data): { [key: string]: any } {
    return {
      id: session.draft,
      contractId: session.policyNumber,
      username: this.authService.getOperator().username,
      causeCode: session.operation,
      subCauseCode: session.subCauseCode,
      contractorId: session.contractor,
      managementNodeId: session.managementNode,
      dates,
      operationData: {
        operationDataType: (!!opDataType) ? opDataType : session.operation,
        data
      },
      questionnaires,
      step,
      reload,
      operationRoles
    };
  }

  publish(session: PostsalesSession, note: string, publishAndAccept: boolean): Observable<PostsalesOperationsResponse> {
    return this.loaderService.registerBeCall(this.httpClient
      .post<PostsalesOperationsResponse>(
        this.baseUrl + session.policyNumber + '/operations/' + this.getOperationUrl()[session.operation] + '/published',
        {
          draftId: session.draft,
          note: !!note ? note : EMPTY_STR
        },
        { headers }
      )
      .pipe(
        tap(response => {
          this.errors = response.errors;
        })
      ));
  }

  // metodo che permette di eseguire dei comandi dopo aver terminato la publish
  // sia flusso normale che quello tramite salva accetta emetti
  public onEndPublish(response, publishAndAccept: boolean): Observable<PostsalesOperationsResponse> {
    return of(response);
  }

  public mapErrorsStep(errors: PostsalesError[]): PostsalesError[] {
    if (errors) {
      return errors.map(e => {
        e.context = !!this.errorMapping[e.context] ? this.errorMapping[e.context] : e.context;
        return e;
      });
    } else {
      return [];
    }
  }

  public getAvailableOperations(): Observable<AvailableOperation[]> {
    const username = this.authService.getOperator().username;
    const salePointId = this.authService.getSalePointId();
    const params = new HttpParams()
      .set('username', username)
      .set('nodeId', salePointId);
    return this.httpClient.get<AvailableOperation[]>(
      this.baseUrl + this.policyNumber + '/operations',
      { params }
    );
  }

  public getSummary<S>(session: PostsalesSession): Observable<S> {
    return this.httpClient.get<S>(
      this.baseUrl +  session.policyNumber + '/operations/' + this.getOperationUrl()[session.operation] +
      '/drafts/' + session.draft + '/summary'
    );
  }

  setPolicyNumber(policyNumber: string) {
    this.policyNumber = policyNumber;
  }

  getPolicyNumber(): string {
    return this.policyNumber;
  }

  setDraftId(draftId: string) {
    this.draftId = draftId;
  }

  saveDraft(response) {
    this.draftResponse = response;
  }

  getDraft(): PostsalesOperationsResponseData {
    return this.draftResponse;
  }

  setOperation(operation: string) {
    this.operation = operation;
  }

  getOperationDescription(): string {
    return this.operationDescription;
  }

  setOperationDescription(operationDescription: string) {
    this.operationDescription = operationDescription;
  }

  getOperation(): string {
    return this.operation;
  }

  setSessionId(id: string) {
    this.sessionId = id;
  }

  getSessionId(): string {
    return this.sessionId;
  }

  setParentSessionId(id: string) {
    this.$session.idParentSession = id;
  }

  getParentSessionId(): string {
    return this.$session.idParentSession;
  }

  clear() {
    this.operation = null;
    this.operationDescription = null;
    this.draftResponse = null;
    this.draftId = null;
    this.policyNumber = null;
  }

  public setSession(session: PostsalesSession) {
    this.$session = session;
  }

  public get session(): PostsalesSession {
    return this.$session;
  }

  // TODO: return Policy not any
  public getPolicyDetail(polNum: string, salePointId: string): Observable<PoliciesDetail> {
    const url = this.envPath + '/ptfall/policies';
    const body = {
      filterPolicies: {
        policyNumber: polNum,
        salePointId
      }
    };
    const newHeader = headers.append('RGI_idPv', salePointId);
    const options = {
      headers: newHeader
    };
    return this.loaderService.registerBeCall(
      this.httpClient.post(url, body, options)
    );
  }

  public getPolicy(polNum: string, salePointId: string): Observable<Policy> {
    return this.getPolicyDetail(polNum, salePointId).pipe(
      map(result => result.policies[0])
    );
  }


  public passFlowPublishedByOPCode(policyNumber: any, idDraft: any, operationCode: string): Observable<any> {
    const url = this.baseUrl + policyNumber + '/operations/' + operationCode + '/published';
    const body = {
      draftId: idDraft
    };
    return this.httpClient.post(url, body);
  }

  public passFlowDraftByOPCode(policyNumber: string, operationDate: string,
                               operationCode: string, operation: string,
                               amountSegragatedFunds?: number, amountNotSegragatedFunds?: number,
                               payMode?: any,
                               ticketId?: string,
                               ticketIdCrypt?: string,
                               questionnairesToSave?: any,
                               amountValue?: number,
                               issuedateCode = '_VOEMI',
                               effectdateCode = '_1OEFF'
                               ): Observable<any> {
    let paymentMode;
    if (payMode) {
      if (payMode.mode) {
        paymentMode = { mode: 'CASH' };
      } else if (payMode.bankAccount) {
        paymentMode = { mode: 'DDO', iban: payMode.bankAccount.iban };
      }
    }
    const url = this.baseUrl + policyNumber + '/operations/' + operation + '/drafts';
    const questionnaires = new Array();
    if (questionnairesToSave) {
      let i = 0;
      questionnairesToSave.forEach(element => {
        if (element[0]) {
          questionnaires[i] = {
            id: element[0].id,
            code: element[0].questionnaire.code,
            descr: element[0].questionnaire.name
          };
          i++;
        }
      });
    }
    const body = {
      contractId: policyNumber,
      username: this.authService.getOperator().username,
      causeCode: operationCode,
      loginNodeCode: this.authService.getSalePointCode(),
      ticketId: ticketId.toString(),
      ticketIdCrypt: ticketIdCrypt.toString(),
      operationData: {
        operationDataType: operationCode,
        data: {
          amountSegragatedFunds,
          amountNotSegragatedFunds,
          paymentMode,
          amountValue
        }
      },
      questionnaires,
      dates: [
        {
          code: issuedateCode,
          label: issuedateCode,
          value: new Date(), // "2020-04-24T23:00:00.000+0000",
          userInput: true,
          persistent: true
        },
        {
          code: effectdateCode,
          label: effectdateCode,
          value: operationDate, // "2020-04-24T23:00:00.000+0000",
          userInput: true,
          persistent: false
        }
      ]
    };
    if (!amountSegragatedFunds && !amountNotSegragatedFunds && !amountValue && !payMode) {
      delete body.operationData.data;
    } else {
      if (amountSegragatedFunds !== 0 && !amountSegragatedFunds) {
        delete body.operationData.data.amountSegragatedFunds;
      }
      if (amountNotSegragatedFunds !== 0 && !amountNotSegragatedFunds) {
        delete body.operationData.data.amountNotSegragatedFunds;
      }
      if (amountValue !== 0 && !amountValue) {
        delete body.operationData.data.amountValue;
      }
      if (!payMode) {
        delete body.operationData.data.paymentMode;
      }
    }
    return this.httpClient.post(url, body);
  }

  checkOperationRolesOnNext(subjects: Role[]): Observable<any[]> {
    return of([]);
  }

  handleOperationRolesCreate(subjects: Role[]) {
    // metodo utilizzato in EXT per caricare le informazioni dei ruoli
  }

  checkQuestionnaireOnNext(caller: AbsOperationComponent, quest: any, step: string): Observable<PostsalesError[]> {
    return of([]);
  }

}

import { ApiPager, EnumType } from '@rgi/digital-claims-common-angular';

export class InvoiceClaimsListFilter {

    public claimNumber: string;
    public pager: ApiPager;
    public technicalState: number;
    public claimOpeningDateFrom: Date;
    public claimOpeningDateTo: Date;
    public subjectSurnameOrDenomination: string;
    public subjectName: string;
    public codHandler: string;
}

import { AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { RoutableComponent, RoutingService } from '@rgi/portal-ng-core';
import { CardComponent, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { NewLetterTemplatesComponent } from '../new-letter-templates/new-letter-templates.component';
import { LetterTemplateEntity } from '../../dto/letter-template.entity';
import { RgiRxDatatableRowAction, TableSchema } from '@rgi/rx/ui';
import { LetterTemplatesService } from '../../letter-templates.service';
import { LetterResponseEntity } from '../../dto/letter-response.entity';
import { AlertService, Outcome } from '@rgi/digital-claims-common-angular';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'claims-letter-templates-list',
  templateUrl: './letter-templates-list.component.html',
  styleUrls: ['./letter-templates-list.component.scss']
})
export class LetterTemplatesListComponent extends CardComponent implements OnInit, AfterViewInit, RoutableComponent {
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();

  routes: { newLetterTemplates: any; };
  letterList: LetterTemplateEntity[];
  @ViewChild('tableEl') dt: any; // RgiRxDatatableComponent

  errorMessage: string;

  schema: TableSchema;

  constructor(
    @Inject('sessionService') private sessionServiceInject: any,
    @Inject('angularGridInstance') private gridInstance: any,
    @Inject('eventService') private emitter: any,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    @Inject('newLetterTemplates') newLetterTemplates,
    private routingService: RoutingService,
    private letterTemplatesService: LetterTemplatesService,
    private alertService: AlertService,
    public translateService: RgiRxTranslationService
  ) {
    super(cardComponentService, sessionService);
    this.routes = {
      newLetterTemplates,
    };


    const obsTitle$ = this.translateService.translate('_CLAIMS_._TITLE');
    const obsManagePhase$ = this.translateService.translate('_CLAIMS_._MANAGE_PHASE');
    const obsDerogationLevel$ = this.translateService.translate('_CLAIMS_._DEROGATION_LEVEL');
    const obsDateOfInsertion$ = this.translateService.translate('_CLAIMS_._DATE_OF_INSERTION');
    combineLatest([
      obsTitle$,
      obsManagePhase$,
      obsDerogationLevel$,
      obsDateOfInsertion$,
    ]).subscribe(
      ([title, managePhase, derogationLevel, dateOfInsertion]) => {
        this.schema = {
          rows: [
            {
              name: 'titolo',
              title: title,
            },
            {
              name: 'descrFaseGestione',
              title: managePhase,
            },
            {
              name: 'livelloDeroga',
              title: derogationLevel,
            },
            {
              name: 'dins',
              title: dateOfInsertion,
              format: {
                pipe: 'date',
                args: ['shortDate']
              }
            },
            {
              name: 'actions',
              title: '',
              actions: [
                {
                  name: 'modify',
                  label: '',
                  styleClass: 'glyphicon glyphicon-pencil'
                },
                {
                  name: 'delete',
                  label: '',
                  styleClass: 'glyphicon glyphicon-trash'
                },
              ]
            }
          ],
          header: ['titolo', 'descrFaseGestione', 'livelloDeroga', 'dins', 'actions']
        };
      })
  }

  ngOnInit() {
    this.translateService.translate('_CLAIMS_._MODELS_LIST')
      .subscribe(res => this.card.title = res);
    this.letterList = this.letterTemplatesService.getLetterList();
    this.letterList.sort((a, b) => a.idFaseGestione - b.idFaseGestione);
  }

  getCurrentSession() {
    return this.sessionServiceInject.list().find(el => el.isActive);
  }

  back() {
    const session = this.getCurrentSession();
    this.sessionServiceInject.remove(session.idSession);
  }

  ngAfterViewInit(): void {
    // this.routingService.routes = this.routes;
  }

  openNewModel(letterTemplateEntity?: LetterTemplateEntity) {
    this.routingService.loadComponent(this.routes.newLetterTemplates);
    (this.routingService.componentRef.instance as NewLetterTemplatesComponent).card = this.card;
    if (letterTemplateEntity) {
      (this.routingService.componentRef.instance as NewLetterTemplatesComponent).letterTemplateEntity = letterTemplateEntity;
    }
  }

  onActionClick(event: RgiRxDatatableRowAction<LetterTemplateEntity>) {
    this.errorMessage = null;
    this.gridInstance.cards.refresh();
    this.emitter.broadcastEvent('start-loader');

    if (event.name === 'modify') {
      this.letterTemplatesService.getModelInit(event.row.id.toString()).subscribe((response: LetterResponseEntity) => {
        this.emitter.broadcastEvent('stop-loader');
        this.openNewModel(response.letterTemplate);
      });
    }
    if (event.name === 'delete') {
      this.letterTemplatesService.deleteLetterTemplate(event.row.id.toString()).subscribe((response: Outcome) => {
        if (response.code === Outcome.OK) {
          this.letterList = this.letterList.filter(d => d.id !== event.row.id);
          this.dt.dataSource.update(this.letterList);
          this.translateService.translate('_CLAIMS_._DELETED')
            .subscribe(res => this.alertService.translatedMessage(res));
        } else {
          this.translateService.translate('_CLAIMS_._IN_ERROR')
            .subscribe(res => this.alertService.translatedMessage(res));
        }
        this.emitter.broadcastEvent('stop-loader');
      });
    }
  }
}

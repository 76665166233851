import {Component} from '@angular/core';
import {RgiRxSnackbarNotification} from '../rgi-rx-snackbar-types';

@Component({
  selector: 'rgi-rx-snackbar-body',
  templateUrl: './rgi-rx-snackbar-body.component.html',
  host: {
    class: 'rgi-ui-snackbar-body'
  }
})
export class RgiRxSnackbarBodyComponent {

  constructor(public snackbarNotification: RgiRxSnackbarNotification) {
  }
}

import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {RgiRxDataPaginator} from '../paginator/paginator-api';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {RgiRxDSLAssertion} from '@rgi/rx';
import {RgiRxDataTablePipeType} from './data-table-api';

export type RgiRxTableDataSourceInput<T> = Observable<ReadonlyArray<T> | T[]> | ReadonlyArray<T> | T[];
export type RgiRxTableSource<T> = Observable<T[] | ReadonlyArray<T>> | T[] | RgiRxDatasource<T>;

export type RgiRxTableSortOrder = 'asc' | 'desc';

export interface RgiRxTableColumnSorted {
  order: RgiRxTableSortOrder | undefined;
  schema: TableRowTypeSchema;
}

export type RgiRxSortComparatorFn<T> = (arg: T) => number;

export type RgiRxTableSortComparatorDef<T> = string | RgiRxSortComparatorFn<T>;

export function isActionRowSchema(row: TableRowTypeSchema): row is TableActionRowSchema {
  return (row as TableActionRowSchema).actions !== undefined;
}


/**
 * A Cell row definition for textual data
 */
export interface TableTextRowSchema extends TableRowSchema {
  /**
   * Any formatting to be applied
   */
  format?: TableSchemaFormat;

  /**
   * Alternative path of the object, when set, it will be used as node path resolver instead of name
   */
  path?: string;
}

export type TableSchemaFormat = TableSchemaFormatSingle | TableSchemaFormatChain;

/**
 * The field formatting definition
 */
export interface TableSchemaFormatSingle {
  /**
   * The transformation function to be used
   */
  pipe: RgiRxDataTablePipeType | string;
  /**
   * Any argument required to the transformation pipe
   */
  args?: any[];
}

/**
 * The field formatting definition
 */
export interface TableSchemaFormatChain {
  pipes: TableSchemaFormatSingle[];
}

export interface TableSchemaWithStyles extends TableSchemaWithStyleClass {
  styles?: { [styleClass: string]: RgiRxDSLAssertion };

}

export interface TableSchemaWithStyleClass {
  /**
   * A styleClass to be applied to the row cell
   */
  styleClass?: string;
}

/**
 * Representation of a row
 */
export interface TableRowSchema extends TableSchemaWithStyles {
  /**
   * The name of the field to bind
   */
  name: string;
  /**
   * The title to display
   */
  title: string;
  /**
   * A description of the value
   */
  description?: string;

}

export interface TableActionSchemaType {
  /**
   * Name of the action
   */
  name: string;
  /**
   * Label of the action for the ui
   * The ui control should show this label as text
   */
  label?: string;

  /**
   * A description label for the action
   * the ui control can show it as a side element (ex. overlays, tooltips etc)
   */
  description?: string;

  /**
   * A label of the action that is not displayed, but it's used for accessibility purposes
   */
  ariaLabel?: string;
}

/**
 * An action cell-value to allow user interactions
 */
export interface TableActionSchema extends TableActionSchemaType, TableSchemaWithStyles {
  /**
   * An assertion to determine if the actions should be hidden
   */
  hidden?: RgiRxDSLAssertion;
  /**
   * An assertion to determine if the actions should be disabled
   */
  disabled?: RgiRxDSLAssertion;
}


/**
 * A Row definition for an action cell
 */
export interface TableActionRowSchema extends TableRowSchema {
  actions: TableActionSchema[];
}

export type TableRowTypeSchema = TableRowSchema | TableTextRowSchema | TableActionRowSchema;

export interface TableHeaderActionSchema extends TableActionSchemaType, TableSchemaWithStyleClass {
  /**
   * When the action should be visible
   * multi: when multi-selection is in place
   * single: when there's no multi-selection
   */
  when: 'multi' | 'single' | 'always';
  disabled?: boolean;
}

/**
 * Schema Representation of a table.
 * The schema defines rows and headers to be displayed on a table allowing a data-table component ui to draw
 * the table accordingly.
 */
export interface TableSchema {
  /**
   * rows of the schema
   */
  rows: TableRowTypeSchema[];
  /**
   * Headers to show off the table
   */
  header: string[];

  headerActions?: TableHeaderActionSchema[];
}


export type RgiRxDataSourceTypeObjectProps<T> = {
  readonly [P in keyof T]: T[P]
};

export function getDataSourceTypeObjectPropsFromSchema<T>(tableSchema: TableSchema): RgiRxDataSourceTypeObjectProps<T> {
  return tableSchema.rows.map(value => value.name).reduce((acc, curr) => (acc[curr] = '', acc), {} as T);
}


export type RgiRxDataSourceChange = 'sorted' | 'updated';

/**
 * Abstract class for building RgiRxDatasource implementations
 */
export abstract class RgiRxDatasource<T> extends DataSource<T> {
  /**
   * A subject that hold the fetched data
   */
  protected readonly _data: BehaviorSubject<T[]>;
  /**
   * An observable of the filter token to search for object properties values
   */
  protected readonly _filter = new BehaviorSubject<string>('');
  /**
   * object properties of the <T> type to be used when filtering the objects
   */

  /**
   * An observable that notifies any changed happened within the datasource
   */
  private readonly _changed = new Subject<RgiRxDataSourceChange>();

  private _filterObjectProps?: RgiRxDataSourceTypeObjectProps<T>;
  protected _paginator?: RgiRxDataPaginator;


  /**
   * Create the RgiRxDatasource
   * @param data the initial data (default is []
   * @param paginator optional RgiRxDataPaginator instance to allow pagination
   * @param sourceObjectProps properties of the objects that should be used while filtering
   */
  protected constructor(data: T[] = [], paginator?: RgiRxDataPaginator, sourceObjectProps?: RgiRxDataSourceTypeObjectProps<T>) {
    super();
    this._data = new BehaviorSubject<T[]>(data);
    this._paginator = paginator;
    this._filterObjectProps = sourceObjectProps;
  }

  get data$(): Observable<T[]> {
    return this._data.asObservable();
  }

  /**
   * Get the current dataset
   */
  get data(): T[] {
    return this._data.getValue();
  }

  /**
   * Get the current filter value
   */
  get filter(): string {
    return this._filter.getValue();
  }

  /**
   * Set the filter value
   * @param filter the filter value to apply
   */
  set filter(filter: string) {
    this._filter.next(filter);
  }


  /**
   * Get the RgiRxDataSourceTypeObjectProps to be used for filtering the objects.
   */
  get filterObjectProps(): RgiRxDataSourceTypeObjectProps<T> | undefined {
    return this._filterObjectProps;
  }


  set filterObjectProps(value: RgiRxDataSourceTypeObjectProps<T> | undefined) {
    this._filterObjectProps = value;
  }

  /**
   * Get the paginatior instance
   * @return RgiRxDataPaginator
   */
  get paginator(): RgiRxDataPaginator | undefined {
    return this._paginator;
  }

  /**
   * Add the RgiRxDataPaginator
   * @param dataPaginator an instance of RgiRxDataPaginator
   */
  set paginator(dataPaginator: RgiRxDataPaginator | undefined) {
    this._paginator = dataPaginator;
  }


  /**
   * Weather the datasouce have a paginator instance
   */
  hasPaginator(): boolean {
    return !!this.paginator;
  }

  /**
   * Update the dataset with new values
   * @param data
   */
  update(data: T[]) {
    this._data.next(data);
    if (this.hasPaginator()) {
      this.paginator.elementCount = data.length;
    }
    this._changed.next('updated');
  }

  connect(collectionViewer: CollectionViewer): Observable<T[] | ReadonlyArray<T>> {
    return this._data.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }

  /**
   * Move an element from an index to another
   * @param startIndex
   * @param targetIndex
   */
  moveElement(startIndex: number, targetIndex: number) {
    const elements = this._data.getValue();
    moveItemInArray(elements, startIndex, targetIndex);
    this.update(elements);
    this._changed.next('sorted');
    if (this.hasPaginator()) {
      this.paginator.pageIndex = this.paginator.getElementPage(targetIndex);
    }
  }

  /**
   * Slice the data in a page set give the current paginator
   * @param data
   */
  protected _page(data: T[]) {
    if (!this.hasPaginator() || !this._paginator.elementCount) {
      return data;
    }
    const pageSize = this._paginator.pageSize;
    const startIndex = this._paginator.pageIndex;
    return data.slice(startIndex, startIndex + pageSize);
  }

  /**
   * Get the current page rows from the dataset
   */
  getCurrentPageRows(): T[] {
    return this._page(this._data.getValue());
  }


  /**
   * Get the change event notifier
   */
  get changed(): Observable<RgiRxDataSourceChange> {
    return this._changed.asObservable().pipe();
  }
}



/**
 * Table schema builder of building TableSchema definition programmatically.
 * @see TableSchema
 */
export class TableSchemaBuilder<T> {

  private constructor(instance: T) {
    this.instance = instance;
    this.init();
  }

  private schema: TableSchema;
  private instance: T;

  /**
   * Get a builder instance
   * @param instance if a object definition is passed the builder can check the presence of the fields when building the
   * schema.
   */
  static get<T>(instance?: T): TableSchemaBuilder<T> {
    return new TableSchemaBuilder(instance);
  }

  private init() {
    this.schema = {
      header: [],
      rows: []
    };
  }

  /**
   * Add an header name to show
   * @param name
   */
  addHeader(name: string): TableSchemaBuilder<T> {
    this.schema.header.push(name);
    return this;
  }

  /**
   * Add a text row cell definition
   * @param name the name of the cell
   * @param title the title of the cell
   * @param format the format if any
   * @param styleClass the styleClass if any
   * @param description the description if any
   * @param path the alternate path if any
   * @see TableTextRowSchema
   */
  addTextRow(name: string, title: string, format?: TableSchemaFormat, styleClass?: string, description?: string, path?: string): TableSchemaBuilder<T> {

    if (!this.hasProperty(name)) {
      throw new Error(`The current object has no property named ${name}`);
    }
    this.schema.rows.push({
      name,
      title,
      format,
      styleClass,
      description,
      path
    });
    return this;
  }

  /**
   * Create an action row cell definition
   * @param name
   * @param title
   * @param actions
   * @param styleClass
   * @param description
   * @see TableActionRowSchema
   */
  addActionRow(name: string,
               title: string,
               actions: TableActionSchema[],
               styleClass?: string,
               description?: string): TableSchemaBuilder<T> {
    this.schema.rows.push({
      name,
      styleClass,
      title,
      actions,
      description
    });
    return this;
  }

  private hasProperty(name: string) {
    return this.instance ? this.instance.hasOwnProperty(name) : true;
  }

  /**
   * Build the schema and clear the builder
   */
  build(): TableSchema {
    const serialize = {...this.schema};
    this.init();
    delete this.instance;
    return serialize;
  }

}



import { Injectable } from '@angular/core';
import {
  GpRecoverApplicationsModalComponent
} from '../group-policy-components/group-policy-modals/group-policy-modal-recover-applications/recover-applications-modal.component';
import {catchError, concatMap, map} from 'rxjs/operators';
import {GPRecoverAppsPayload, GPRecoverAppsSubstitutionType} from '../group-policy-models/gp-move-application';
import {Observable, of} from 'rxjs';
import {
  GpConfirmationEvent
} from '../group-policy-components/group-policy-modals/group-policy-modal-confirmation/gp-confirmation-modal.component';
import {ResultMessage} from '../group-policy-models/group-policy-issue-policy-data';
import {ModalService} from '@rgi/rx/ui';
import {GroupPolicyResourceService} from '../group-policy-resources/group-policy-resource.service';
import {GroupPolicyCrossService} from './group-policy-cross.service';
import {GroupPolicyIntegrationService} from './group-policy-integration.service';
import {DataForSteps} from '../group-policy-models/group-policy-issue-home';

@Injectable({
  providedIn: 'root'
})
export class GpRecoverApplicationsService {

  constructor(
    protected modalService: ModalService,
    protected resourceService: GroupPolicyResourceService,
    protected crossService: GroupPolicyCrossService,
    protected integrationService: GroupPolicyIntegrationService,
  ) { }


  manageRecoverApplications$(proposalNumber: string, sessionToClose?: string) {
    const recoverAppsModal = this.modalService.open(GpRecoverApplicationsModalComponent);
    recoverAppsModal.enableClickBackground = false;
    return recoverAppsModal.onClose.pipe(
      concatMap((payload: GPRecoverAppsPayload | null) => {
        return this.manageRecoverAppsConfirmationModal$(payload);
      }),
      concatMap((payload: GPRecoverAppsPayload | null) => {
        return this.recoverApplications$(payload, proposalNumber, sessionToClose);
      })
    );
  }

  protected manageRecoverAppsConfirmationModal$(payload: GPRecoverAppsPayload): Observable<GPRecoverAppsPayload | null> {
    if (payload) {
      let disclaimerMsg;
      switch (payload.substitutionType) {
        case GPRecoverAppsSubstitutionType.FULL:
          disclaimerMsg = '_GP_._MSG_._SUBST_FULL_DISCLAIMER_';
          break;
        case GPRecoverAppsSubstitutionType.INCREMENTAL:
          disclaimerMsg = '_GP_._MSG_._SUBST_INCREMENTAL_DISCLAIMER_';
          break;
        default:
          throw Error('substitution type not managed');
      }
      return this.crossService.openConfirmationModal(disclaimerMsg).pipe(
        map((disclaimerChoice: GpConfirmationEvent) => {
          if (disclaimerChoice === GpConfirmationEvent.CONFIRM) {
            return payload;
          }
          return null;
        })
      );
    }
    return of(null);
  }

  protected recoverApplications$(payload: GPRecoverAppsPayload, proposalNumber: string, sessionToClose: string): Observable<null> {
    if (payload) {
      return this.resourceService.recoverApplications$(proposalNumber, payload).pipe(
        catchError(() => {
          console.error('Error while scheduling recover application');
          this.crossService.openBaseModal('_GP_._MSG_._BATCH_SCHEDULED_KO_').subscribe();
          return null;
        }),
        map((result: ResultMessage) => {
          this.crossService.openBaseModal(result.message).subscribe(() => {
            if (!!sessionToClose) {
              const inquiryData = {
                proposalNumber
              } as DataForSteps;
              this.integrationService.goToInquiry(inquiryData, sessionToClose);
            }
          });
          return null;
        })
      );
    }
    return of(null);
  }
}

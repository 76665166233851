   	
   		<div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 text-align-right-claim">
			{{label | translate}}
		</div>

   		<div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
        	<div class="amounts-bar-container">
	        	<div *ngIf="insideAmount>0 || insideAmount==0" class="amounts-bar-inside" ngStyle="styleAmountsBarInside()"><span class="bold horizontal-padding-claim amounts-bar-claim-text">{{(insideAmount | currency:'EUR':true) || '-'}}</span></div>
				<div *ngIf="insideAmount<0" class="amounts-bar-inside" ngStyle="styleAmountsBarInside()"><span class="bold horizontal-padding-claim amounts-bar-claim-text">{{(0 | currency:'EUR':true) || '-'}}</span></div>
			</div>
		</div>
		
   		<div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
        	<span class="bold amounts-bar-claim-text amounts-bar-claim-text-container">{{(containerAmount | currency:'EUR':true) || '-'}}</span>
		</div>
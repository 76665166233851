import { ApiEnumType } from '@rgi/digital-claims-common-angular';
import { ExposureEntity } from '../../domain/exposure-entity';
import { ExcessDamageDto } from './excess-damage-dto';
import { LimitDamageDto } from './limit-damage-dto';

export class TypeOfLossDamageDto {

    advanceAggregateExcess: ApiEnumType;
    advanceClaimExcess: ApiEnumType;
    advanceSir: ApiEnumType;
    advanceDeductible: ApiEnumType;
    amountTolInput: number;
    amountTolOutput: number;
    amountTolOmniaCompresiDomiciliatari: number;
    enableManualExcess: boolean;
    enumReasonUpdate: string;
    excludeAggregateExcess: boolean;
    excludeClaimExcess: boolean;
    excludeDeductible: boolean;
    excludeLimit: boolean;
    excludeFirstSir: boolean;
    excludeSecondSir: boolean;
    idTol: number;
    tolDescription: string;
    subtypeCodes: ApiEnumType[];

    historicalErosionExcess: ExcessDamageDto;
    limits: LimitDamageDto[];

    constructor(damageCardPayment = new ExposureEntity()) {

        this.advanceClaimExcess = new ApiEnumType('', '');
        this.amountTolInput = damageCardPayment.amount;
        this.idTol = +damageCardPayment.idCausaleDanno;
        this.amountTolOmniaCompresiDomiciliatari = 0;
    }
}

import { Component, Inject, Input, OnInit, Output, EventEmitter, Optional } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DetailReceiptReturn, IComboListReceipt, INIT_DETAIL_RECEIPT, ReasonRejected } from './receipt-return.interface';
import { NewPaymentService } from '../new-payment.service';
import { DatePipe } from '@angular/common';
import { DIALOG_DATA, OnModalClose } from '@rgi/rx/ui';

@Component({
  selector: 'claims-receipt-return-modal',
  templateUrl: './receipt-return-modal.component.html',
  styleUrls: ['./receipt-return-modal.component.scss']
})
export class ReceiptReturnModalComponent implements OnInit, OnModalClose {

  @Input() testInputDetailReceipt: any;

  valueCheckAccept = 'accept';
  valueCheckReject = 'reject';
  acceptedCheckbox = false;
  rejectedCheckbox = false;
  reasonRejectedList: ReasonRejected[] = [];
  reasonRejectedSelected: ReasonRejected;
  receiptReturnFormModal: UntypedFormGroup;
  detailReceiptReturn: DetailReceiptReturn = INIT_DETAIL_RECEIPT;
  claimNumber: string = '';
  idClaim: string = '';
  today = new Date();
  datepickerPlaceholder: string = '';
  isDisabledForm: boolean = false;
  detailReceiptReturnForm: UntypedFormGroup;
  modalClose = new EventEmitter<any>();
  data: any;
  invalidConfirmButton = true; // Serve per disabilitare (true) il bottone di conferma, altrimenti false

  constructor(
    private paymentService: NewPaymentService,
    public activeModal: NgbActiveModal,
    private datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    @Optional() @Inject(DIALOG_DATA) data: any) {
    this.testInputDetailReceipt = data.detailObject;
    this.testInputDetailReceipt.selectedClaim = data.claimCurrent;
  }

  ngOnInit() {
    this.initForm();
    this.populateForm();
    if (this.testInputDetailReceipt) {
      this.getReasonRejected();
    }
  }

  checkboxCheckUncheck(value: string) {
    if (value === 'accept') {
      this.detailReceiptReturn.rejectedCheckbox = false;
    } else if (value === 'reject') {
      this.detailReceiptReturn.acceptedCheckbox = false;
    }
    this.checkValidatorsForm();

  }

  populateForm() {
    // tslint:disable-next-line:max-line-length
    this.claimNumber = this.testInputDetailReceipt.selectedClaim ? this.testInputDetailReceipt.selectedClaim.claimNumber
      ? this.testInputDetailReceipt.selectedClaim.claimNumber : '' : '';
    // tslint:disable-next-line:max-line-length
    this.idClaim = this.testInputDetailReceipt.selectedClaim ? this.testInputDetailReceipt.selectedClaim.idClaim
      ? this.testInputDetailReceipt.selectedClaim.idClaim : '' : '';
  }

  initForm() {
    this.detailReceiptReturnForm = this.formBuilder.group({
      note: [''],
      reasonRejected: [''],
      dateReceipt: [new Date(), Validators.required]
    });
    this.detailReceiptReturn.rejectedCheckbox = false;
    this.detailReceiptReturn.acceptedCheckbox = false;
    this.invalidConfirmButton = true;
  }

  checkValidatorsForm() {
    if (this.detailReceiptReturn.rejectedCheckbox === true) {
      if (this.reasonRejectedList.length > 0 && this.reasonRejectedList[0] !== undefined) {
        this.detailReceiptReturnForm.get('reasonRejected').setValidators(Validators.required);
      } else {
        this.detailReceiptReturnForm.get('reasonRejected').clearValidators();
      }
      this.detailReceiptReturnForm.get('note').setValidators(Validators.required);
      this.detailReceiptReturnForm.get('dateReceipt').setValidators(Validators.required);
      this.invalidConfirmButton = false;
    } else if (this.detailReceiptReturn.acceptedCheckbox === true) {
      this.detailReceiptReturnForm.get('note').clearValidators();
      this.detailReceiptReturnForm.get('reasonRejected').clearValidators();
      this.detailReceiptReturnForm.get('dateReceipt').clearValidators();
      this.detailReceiptReturnForm.get('dateReceipt').setValidators(Validators.required);
      this.invalidConfirmButton = false;
    } else {
      this.detailReceiptReturnForm.get('note').setValidators(Validators.required);
      this.detailReceiptReturnForm.get('dateReceipt').setValidators(Validators.required);
      this.detailReceiptReturnForm.get('reasonRejected').setValidators(Validators.required);
      this.invalidConfirmButton = true;
    }
    this.detailReceiptReturnForm.get('note').updateValueAndValidity();
    this.detailReceiptReturnForm.get('reasonRejected').updateValueAndValidity();
    this.detailReceiptReturnForm.get('dateReceipt').updateValueAndValidity();
  }

  closeModal(responseBody: any) {
    this.detailReceiptReturn = INIT_DETAIL_RECEIPT;
    this.modalClose.emit(responseBody);
  }

  confirmReceiptReturn() {
    this.detailReceiptReturn.dateReceipt = this.detailReceiptReturnForm.get('dateReceipt').value;
    this.detailReceiptReturn.note = this.detailReceiptReturnForm.get('note').value;
    this.detailReceiptReturn.codeReasonRejected = this.detailReceiptReturnForm.get('reasonRejected').value;
    this.detailReceiptReturn.claimNumber = this.claimNumber;
    this.detailReceiptReturn.receiptId = this.testInputDetailReceipt.paramsIntegrationPass.NAVI_PAR_SETTLEMENTID;
    this.closeModal(this.detailReceiptReturn);
  }

  getReasonRejected() {
    const body: IComboListReceipt = {
      tipoCausale: '11',
      dataSinistro: this.formatDateTimezone(this.testInputDetailReceipt.selectedClaim.reportDate),
      ramoMinisteriale: this.testInputDetailReceipt.selectedClaim.ministerialBranchCodes
    };
    this.paymentService.getReasonRejectedList(body).subscribe((res) => {
      this.reasonRejectedList.push(...this.initComboReasonRejectedList(res));
    });
  }

  initComboReasonRejectedList(lista: any): ReasonRejected[] {
    const reasonRejectedComboList: ReasonRejected[] = [];

    if (lista && lista !== undefined && lista.length > 0 && lista[0] && lista[0] !== undefined && lista[0].length > 0) {
      this.initReasonRejectList();

      lista[0].forEach((res, index) => {
        const combo: ReasonRejected = {
          code: res.toString(),
          description: lista[1][index]
        };
        reasonRejectedComboList.push(combo);
      });
    }
    return reasonRejectedComboList;
  }

  formatDateTimezone(date: string): string {
    const miaData = new Date(date);
    const timezoneOffset = Math.abs(new Date().getTimezoneOffset());
    miaData.setTime(miaData.getTime() + timezoneOffset * 60000);
    const dataOutput = this.datePipe.transform(miaData, 'dd-MM-yyyy');
    return dataOutput;
  }

  /**
   * Inizializzazione della lista delle motivazioni di annullamento
   */
  initReasonRejectList() {
    this.reasonRejectedSelected = {
      code: '',
      description: ''
    };
  }

}

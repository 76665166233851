<rgi-rx-datatable rgi-rx-qa="multiple-substitution"
                  [data]="policies" [schema]="tableSchema"
                  [pageOptions]="pageOptions">
  <ng-template rgiRxDataTableCell="description"  let-ctx>
    <button rgi-rx-button variant="dashed" color="secondary" readonly="true">{{ctx.row.description}}</button>
  </ng-template>
  <ng-template rgiRxDataTableCell="main" let-ctx>
    <input rgiRxInput [checked]="ctx.row === selectedRow" [disabled]="isOnePolicy" (click)="checkboxSelected(ctx,$event)" type="checkbox">
  </ng-template>
  <ng-template rgiRxDataTableCell="policyNumber" let-ctx>
    <div>
      {{ctx.row.policyNumber !== null ? ctx.row.policyNumber : ctx.row.proposalNumber}}
    </div>
  </ng-template>
  <ng-template rgiRxDataTableCell="state" let-ctx>
    <div>
      {{ctx.row.state.description}}
    </div>
  </ng-template>
  <ng-template rgiRxDataTableCell="actions"  let-ctx>
      <button [disabled]="isOnePolicy" rgi-rx-button color="primary"
              type="submit" class="rgi-re-button" (click)="delete(ctx.row)"
              attr.data-qa="delete_{{ctx.row.policyNumber}}" translate>RE_ISSUE.DELETE</button>
  </ng-template>
</rgi-rx-datatable>

export const RGI_RX_TABLE_i18n = {
  it: {
    RGI_RX: {
      DATATABLE: {
        SEARCH_LABEL: 'Cerca',
        EMPTY: 'Nessun elemento da visualizzare',
        SORT_ROW_LABEL: 'Ordina riga {{index}}',
        SELECT_ROW_LABEL: 'Seleziona riga {{index}}',
        SELECT_ALL_ROW_LABEL: 'Seleziona tutte le righe',
        SORT_HEADER_LABEL: 'Ordina colonna {{name}}',
        EXPAND_ROW_LABEL: 'Espandi riga {{index}}',
        COLLAPSE_ROW_LABEL: 'Comprimi riga {{index}}'
      }
    }
  },
  en: {
    RGI_RX: {
      DATATABLE: {
        SEARCH_LABEL: 'Search',
        EMPTY: 'Nothing to display',
        SORT_ROW_LABEL: 'Order row {{index}}',
        SELECT_ROW_LABEL: 'Select row {{index}}',
        SELECT_ALL_ROW_LABEL: 'Select all rows',
        SORT_HEADER_LABEL: 'Sort column {{name}}',
        EXPAND_ROW_LABEL: 'Expand row {{index}}',
        COLLAPSE_ROW_LABEL: 'Collapse row {{index}}'
      }
    }
  },
  es: {
    RGI_RX: {
      DATATABLE: {
        SEARCH_LABEL: 'Búsqueda',
        EMPTY: 'Nada que mostrar',
        SORT_ROW_LABEL: 'Ordenar fila {{index}}',
        SELECT_ROW_LABEL: 'Seleccionar fila {{index}}',
        SELECT_ALL_ROW_LABEL: 'Seleccionar todas las filas',
        SORT_HEADER_LABEL: 'Ordenar columna {{name}}',
        EXPAND_ROW_LABEL: 'Expandir fila {{index}}',
        COLLAPSE_ROW_LABEL: 'Colapsar fila {{index}}'
      }
    }
  },
  fr: {
    RGI_RX: {
      DATATABLE: {
        SEARCH_LABEL: 'Rechercher',
        EMPTY: 'Rien à afficher',
        SORT_ROW_LABEL: 'Ordre de la ligne {{index}}',
        SELECT_ROW_LABEL: 'Sélectionner la ligne {{index}}',
        SELECT_ALL_ROW_LABEL: 'Sélectionner toutes les lignes',
        SORT_HEADER_LABEL: 'Trier la colonne {{name}}',
        EXPAND_ROW_LABEL: 'Développer la ligne {{index}}',
        COLLAPSE_ROW_LABEL: 'Réduire la ligne {{index}}'
      }
    }
  },
  de: {
    RGI_RX: {
      DATATABLE: {
        SEARCH_LABEL: 'Suche',
        EMPTY: 'Nichts zu zeigen',
        SORT_ROW_LABEL: 'Reihenfolge Zeile {{index}}',
        SELECT_ROW_LABEL: 'Wählen Sie Zeile {{index}}',
        SELECT_ALL_ROW_LABEL: 'Wählen Sie alle Zeilen',
        SORT_HEADER_LABEL: 'Spalte {{name}} sortieren',
        EXPAND_ROW_LABEL: 'Zeile {{index}} erweitern',
        COLLAPSE_ROW_LABEL: 'Zeile {{index}} reduzieren'
      }
    }
  }
};

import {InjectionToken, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ServicesInteropModule} from "./services-interop/services-interop.module";

export type AjsIntegrationConfig = { passportal: boolean };
export const AJS_NG_PASSPROPRO_CONFIG = new InjectionToken<AjsIntegrationConfig>("AJS_NG_PASSPROPRO_CONFIG");

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ServicesInteropModule
  ]
})
export class FacadeInteropModule { }

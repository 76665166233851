<div class="rgi-nmf-mb-1">
  <div class="rgi-nmf-section">
    <div class="nmf_cont_titsez nmf_cont_titsez_color">
      <span class="header-icon rgifont rgi-ui-icon-payment nfm_font_size"></span>
      <h3 class="header-text" translate>Payment means</h3>
      <button class="btn btn-default btn-quotation-detail" *ngIf="canAddPayment()"
              data-qa="rgi-add-payment-btn" (click)="addPaymentMethod()" [disabled]="isSectionDisabled" type="button">
        {{'Add payment mean' | translate}}
        <span class="rgi-ui-icon-plus"></span>
      </button>
    </div>
  </div>
  <form [formGroup]="paymentsForm">
    <div class="rgi-ui-text-3" data-qa="rgi-debit-payments" *ngIf="debitMeans.length > 0">
      <b translate>Debit payment</b>
    </div>
    <rgi-rx-form-field *ngFor="let debit of debitMeans; index as i">
      <input type="radio" value="{{i}}" rgiRxInput formControlName="debitRadio">
      <span [attr.data-qa]="'deb_'+i+'_description'">{{getLabel(debit)}}</span>
      <button *ngIf="debit.editable" class="rgi-ui-btn" [disabled]="isSectionDisabled"
              data-qa="rgi-edit-debit-method" (click)="editPaymentMethod(debit)" color="info">
        <span class="rgi-ui-icon rgi-ui-icon-edit"></span>
      </button>
    </rgi-rx-form-field>
    <div class="rgi-ui-text-3" data-qa="rgi-credit-payments" *ngIf="creditMeans.length > 0">
      <b translate>Credit payment</b>
    </div>
    <rgi-rx-form-field *ngFor="let credit of creditMeans; index as i">
      <input type="radio" value="{{i}}" rgiRxInput formControlName="creditRadio">
      <span [attr.data-qa]="'cred_'+i+'_description'">{{getLabel(credit)}}</span>
      <button *ngIf="credit.editable" class="rgi-ui-btn" [disabled]="isSectionDisabled"
              data-qa="rgi-edit-credit-method" (click)="editPaymentMethod(credit)" color="info">
        <span class="rgi-ui-icon rgi-ui-icon-edit"></span>
      </button>
    </rgi-rx-form-field>
  </form>
</div>



import { Etype } from "./dialogue-handler-search.model";

  export class DialogueSummaryObject {
    requestOutcome: Etype;
    messageList: MessageList[] = [];
    codFunction: string = '';
    descFunction: string = '';
    dialogueSubject: Etype;
    startDate: string = '';
    cancelDate: string='';
    lastMsgDate: string = '';
    roleCompany: Etype;
    idCl: string = '';
    numCl: string = '';
    idPotClaim: string = '';
    numPotClaim: string = '';
    codCompCtnpAnia: string = '';
    plateCtnParty: string = '';
    idNominative: number;
    externalKey: string = '';
    externalIdentifyDamageParty: string = '';
    internalKey: string = '';
    internalIdentifyDamageParty: string = '';
    insurPlate: string = '';
    codCompanyAnia: string = '';
    occurrenceDate: string = '';
    typeInsPlate: string = '';
    typePlateCtnp: string = '';
    identifierUniqueCode: string = '';
  }

  export class MessageList {
    idHistoryStatus: number
    // requestCode: string = '';
    // requestDesc: string = '';
    requestDate: string = '';
    dIns: Date = new Date();
    expiration: string = '';
    expirationLeg: string = '';
    // respCode: string = '';
    // respDesc: string = '';
    update: string = '';
    sendRequest: string = '';
    userRequest: string = '';
    activity: Etype;
    referenceAnia: string = '';
    note: string = '';
    notesThirdParty: string = '';
    codeClassification: string = '';
    statusElabRequest: Etype;
    statusForward: string = '';
    notifyDate: string = '';
    greenDot: boolean = false;

    inputRequestCode: string = '';
    inputRequestDescription: string = '';
    outputResponseCode: string = '';
    outputResponseDescription: string = '';
    inputIdMessage: string = '';
    outputIdMessage: string = '';


    documentList: DocumentList[] = [];
    wasteFieldRecord: WasteFieldRecord[] = [];
    parameterFieldRecord: ParameterFieldRecord[] = [];
    paramRequest: ParameterFieldRecord[] = [];
    paramResponse: ParameterFieldRecord[] = [];
    docuRequest: DocumentList[] = [];
    docuResponse: DocumentList[] = [];
  }

  export class DocumentList {
    fileName: string = '';
    subtitle: string = '';
    typeDoc: number ;
    codDocAnia: string = '';
    nominativo: string = '';
    origin: number;
    idDocInfo: number
    idDoc: string = '';
  }

  export class WasteFieldRecord {
    codError: string = '';
    descError: string = '';
    typeError: string = '';
    typeFlow: string = '';
  }

  export class ParameterFieldRecord {
    parameterCode: string = '';
    parameter: string = '';
    value: string = '';
    type: number;
    nature: number;
    order: number;
  }


  export class OutcomeService {
     code: string = '';
     descr: string = '';
     idpotentialclaim: string = '';
     potentialclaimnumber: string = '';
     companycode: string = '';
     detail: string = '';
     messages: Map<string, string>;
     savedCodeList: string[] = [];
     objects: Map<string, object[]>;
  }

  export class CausalResponse {
    requestOutcome : Etype;
    response: CausalData[] = [];
  }

  export class CausalData {
    causaleId: number;
    causaleDescr: string = '';
    actorId: string = '';
    actorExternalIdentifyDamageParty: string = '';
    actorName: string = '';
    licensePlateUs: string = '';
    agreementType: string = '';
    ruolo: Etype;
    //selected: boolean = false;
  }

  export class SummaryForm {
    claimNumberPass : string = '';
    idTypeOfLoss: number;
  }

  export class DettaglioSinistroDenuncia{
    requestOutcome: Etype;
    response: DettaglioSinistroDenunciaData[] = [];
  }

  export class DettaglioSinistroDenunciaData{
    titolo: string = '';
    valore: string = '';
    tipo: string = '';
  }

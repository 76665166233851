import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClaimByActor } from '../party-data-panel-content/dto/claim-by-actor';

@Component({
  selector: 'claims-similar-claims-by-actor-modal',
  templateUrl: './similar-claims-by-actor-modal.component.html',
  styleUrls: ['./similar-claims-by-actor-modal.component.scss']
})
export class SimilarClaimsByActorModalComponent implements OnInit {

  claimList: ClaimByActor[];
  selectedSubjectId: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.sortClaims();
  }

  closeModal(event: any) {
    this.activeModal.close();
    event.stopPropagation();
    return false;
  }

  sortClaims() {
    const selectedSubjectArray: ClaimByActor[] = [];
    const notMatched: ClaimByActor[] = [];
    for (const element of this.claimList) {
      if (element.subjectId.toString() === this.selectedSubjectId) {
        selectedSubjectArray.push(element);
      } else {
        notMatched.push(element);
      }
    }
    this.claimList = [...selectedSubjectArray, ...notMatched];
  }

}

import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CardComponentService} from '@rgi/digital-claims-common-angular';
import {ExpertUtilityService} from '../expert-utility-service.service';
import {RoutableComponent, RoutingService} from '@rgi/portal-ng-core';
import {ExpertEntity} from '../dto/expert-entity';
import {ExpertDetailsComponent} from '../expert-details/expert-details.component';
import {ExpertDetailEntity} from '../dto/expert-detail-entity';

@Component({
  selector: 'claims-experts-list',
  templateUrl: './experts-list.component.html',
  styleUrls: ['./experts-list.component.scss']
})
export class ExpertsListComponent implements OnInit, OnDestroy, RoutableComponent {

  public routes: any = null;
  constructor(
    cardComponentService: CardComponentService,
    @Inject('sessionService') private session: any,
    @Inject('coreResult') private coreResult,
    @Inject('authService') private authService,
    @Inject('eventService') private eventService: any,
    @Inject('enumService')  private enumService: any,
    @Inject('expertDetailsComponent') expertDetailsComponent,
    private routingService: RoutingService,
    public formBuilder: UntypedFormBuilder,
    private utilityServ: ExpertUtilityService,
  ) {
    this.routes = {
      detail : expertDetailsComponent,
    };
    console.log('EXPERTS LIST COMPONENT CONSTRUCTED');
  }

  @Input() card: any;
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();

  public searchForm: UntypedFormGroup;
  public formConfig: any = {};
  public showError: boolean = false; // boolean to show hidden div in case user performs search without valued fields
  // triggered when button FIND is clicked on search formt
  public showNoExpertsFound: boolean = false;
  public isLegalSubject: any = false;
  public isUmaUniqueKeyManagementEnabled;
  public umaConfigKeys: any; // array of uma configs (PSSINCONFKEY)
  public expertList: ExpertEntity[];

  ngOnInit() {
    const tmpForm: any = this.utilityServ.getExpertSearchFormGroup().getRawValue();
    console.log(tmpForm);
    this.searchForm = this.formBuilder.group(tmpForm);

    this.expertList = this.utilityServ.getExperts();

    if (!this.expertList || this.expertList.length === 0) {
      this.onFind();
    }

  //   replicate behaviour of experts-search component
    this.eventService.broadcastEvent('start-loader');
    this.utilityServ.getConfigExpertsFilter().subscribe(
      (res: any) => {
        console.log('getConfigExpertsFilter --> ', res);
        this.isUmaUniqueKeyManagementEnabled = res.umaUniqueKeyManagementEnabled;
        this.formConfig.subjectTypes = res.subjectTypes;
        this.formConfig.expertTypes = res.expertTypes;
        this.eventService.broadcastEvent('stop-loader');
        //  depending on uma enabled or not
        if (this.isUmaUniqueKeyManagementEnabled) {
          // countries are handled only if uma enabled
          // add form fields
          for (const cn of res.countries) {
            const countryCode: string = cn.code;
            // giuridico key 1
            this.searchForm.addControl(countryCode + '-G-Ckey1',
              new UntypedFormControl([], Validators.nullValidator));
            // fisico key1
            this.searchForm.addControl(countryCode + '-Ckey1',
              new UntypedFormControl([], Validators.nullValidator));
            // giuridico key 2
            this.searchForm.addControl(countryCode + '-G-Ckey2',
              new UntypedFormControl([], Validators.nullValidator));
            // fisico key2
            this.searchForm.addControl(countryCode + '-Ckey2',
              new UntypedFormControl([], Validators.nullValidator));
            // giuridico key3
            this.searchForm.addControl(countryCode + '-G-Ckey3',
              new UntypedFormControl([], Validators.nullValidator));
            // fisico key3
            this.searchForm.addControl(countryCode + '-Ckey3',
              new UntypedFormControl([], Validators.nullValidator));
          }
          this.formConfig.countries = res.countries;
          this.umaConfigKeys = res.umaConfigKeysMap.umaConfigKeysByCountry;

        }

      },
      (error) => {
        this.eventService.broadcastEvent('stop-loader');
      }
    );



    // subscribe to subjectType changes, determines disabled behaviour of input name/surname -- businessName
    this.searchForm.controls.subjectType.valueChanges.subscribe(value => {
      if (value === '1') {
        this.isLegalSubject = false;
        this.searchForm.controls.businessName.setValue(null);
      } else if (value === '2') {
        this.isLegalSubject = true;
        this.searchForm.controls.name.setValue(null);
        this.searchForm.controls.surname.setValue(null);
      }
    });
  }

  ngOnDestroy(): void {
    this.utilityServ.setExperts([]);
  }
  // triggered when button EMPTY is clicked on search form
  onEmpty() {
    this.searchForm.reset();
    this.utilityServ.setExperts([]);
    this.expertList = [];
    this.showError = false;
    this.isLegalSubject = false;
    this.expertList = [];
  }
  onFind() {
    this.showNoExpertsFound = false;
    // check if one of Codice,Nome, Cognome, CF, Partita iva has value
    if ( ((this.searchForm.controls.subjectType.value === '1' &&
          (!this.searchForm.value.name || !this.searchForm.value.name.trim().length ) &&
          (!this.searchForm.value.surname || !this.searchForm.value.surname.trim().length)) ||
         (this.searchForm.controls.subjectType.value === '2' &&
          (! this.searchForm.controls.businessName || !this.searchForm.value.businessName.trim().length )))
      && (!this.searchForm.value.expertCode || !this.searchForm.value.expertCode.trim().length)
      && (!this.searchForm.value.vatNumber || !this.searchForm.value.vatNumber.trim().length)
      && (!this.searchForm.value.fiscalCode || !this.searchForm.value.fiscalCode.trim().length)
      // TODO : consider UMA cases where fields may be required
      // && (this.isUmaUniqueKeyManagementEnabled && (this.searchForm.value.))
    ) {
      this.showError = true;
    } else {
      this.showError = false;
      // perform search
      this.searchForm.addControl('isUmaUniqueKeyEnabled',
        new UntypedFormControl(this.isUmaUniqueKeyManagementEnabled, Validators.nullValidator));
      this.eventService.broadcastEvent('start-loader');
      this.utilityServ.setExpertsSearchFormGroup(this.searchForm);
      this.expertList = [];
      // determine which of the form values are actually relevant
      // this.session.open('claimsExperts', 'home', '', true);
      this.utilityServ.getExpertSearchResult().subscribe((res: any[]) => {
        this.utilityServ.setExperts(new Array<ExpertEntity>());
        if (res.length > 0) {
          for (const x of res) {
            const tmp: ExpertEntity = new ExpertEntity();
            tmp.abi = x.abi;
            tmp.isAvailableCarCollection = x.availableCarCollection;
            tmp.isAvailableReplacementCar = x.availableReplacementCar;
            tmp.bankAccount = x.bankAccount;
            tmp.cabi = x.cabi;
            tmp.carBrandDescription = x.carBrandDescription;
            tmp.code = x.code;
            tmp.countryCode = x.countryCode;
            tmp.expertType = x.expertType;
            tmp.expertTypeProvider = x.expertTypeProvider;
            tmp.fiscalCode = x.fiscalCode;
            tmp.id = x.id;
            tmp.maxNumAssignments = x.maxNumAssignments;
            tmp.name = x.name;
            tmp.percCash = x.percCash;
            tmp.percVAT = x.percVAT;
            tmp.percWithHolding = x.percWithHolding;
            tmp.isPublicDepartment = x.publicDepartment;
            tmp.subjectBusinessName = x.subjectBusinessName;
            tmp.subjectKey1 = x.subjectKey1;
            tmp.subjectKey2 = x.subjectKey2;
            tmp.subjectKey3 = x.subjectKey3;
            tmp.surname = x.surname;
            tmp.taxRegimeDescription = x.taxRegimeDescription;
            tmp.taxRegimeId = x.tagRegimeId;
            tmp.vatNumber = x.vatNumber;
            tmp.expertTypeDescription = x.expertTypeDescription;
            tmp.expertTypeProviderDescription = x.expertTypeProviderDescription;
            tmp.subjectKeyWithDescription = x.subjectKeyWithDescription;
            tmp.nameBySubjectType = x.nameBySubjectType;
            this.utilityServ.getExperts().push(tmp);
          }
          if (this.utilityServ.getExperts().length > 0) {
           this.expertList = this.utilityServ.getExperts();
          }
        } else {
          this.showNoExpertsFound = true;
        }
        this.eventService.broadcastEvent('stop-loader');
      },
      (error) => {
        this.eventService.broadcastEvent('stop-loader');
        this.showNoExpertsFound = true;
      });



    }
  }

  // receives expert code to perform detail search
  onHandleModalExpertDetail(code: bigint, aRow: ExpertEntity) {
    // perform search
    this.eventService.broadcastEvent('start-loader');
    this.utilityServ.getExpertDetails(code).subscribe((res: any) => {
        console.log('EXPERT DETAILS : ', res);
        // build from parent class
        const tmp = new ExpertDetailEntity(aRow);
        tmp.roleEnrollmentCode = res.roleEnrollmentCode;
        tmp.roleEnrollmentDate = res.roleEnrollmentDate;
        tmp.fiscalAddress  = res.fiscalAddress;
        tmp.associatedExperts = res.associatedExperts;
        tmp.country = res.country;
        tmp.region = res.region;
        tmp.city = res.city;
        tmp.zipCode = res.zipCode;
        tmp.toponym = res.toponym;
        tmp.houseNumber = res.houseNumber;
        tmp.subjectDenomination = res.subjectDenomination;
        tmp.expertTypeDescription = res.expertTypeDescription;
        tmp.officeAddress = res.officeAddress;
        this.utilityServ.setExpertDetail(tmp);
        this.eventService.broadcastEvent('stop-loader');
        this.routingService.loadComponent(this.routes.detail);
        (this.routingService.componentRef.instance as ExpertDetailsComponent).card = this.card;
        // this.session.open('claimsExperts', 'home', '', true);
        this.eventService.broadcastEvent('stop-loader');
      } ,
      (error) => {
        this.eventService.broadcastEvent('stop-loader');
      }
    );
  }

}

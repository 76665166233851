import {Inject, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {manageNewCardInPassPortal, RE_ISSUE_CARD} from './re-issue-card.card';
import {PortalConfig, RGI_RX_PORTAL_CONFIG, RgiRxPortalModule} from '@rgi/rx/portal';
import {ReIssueCardModule} from '@rgi/re-issue-card';




@NgModule({
  imports: [
    ReIssueCardModule,
    CommonModule,
    RgiRxPortalModule.forRoot(RE_ISSUE_CARD)
  ]
})
export class ReIssueCardPortalModule {
  constructor(@Inject(RGI_RX_PORTAL_CONFIG) config: PortalConfig) {
    manageNewCardInPassPortal(config);
  }
}

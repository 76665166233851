export class Action {

  messageDescription: string;
  derogationLevel: number;
  messageLevel: number;
  unitCode: string;
  unitDescription: string;
  sectionCode: string;
  sectionDescription: string;
  assetCode: string;
  assetDescription: string;
  instanceName: string;
  codLivelloAzioni: string;
  isAlert: boolean;
}

export class ActionServiceResponse {
  errorMessages: ActionServiceErrorMessage[];
}

export class ActionServiceErrorMessage {
  actionLeveCode?: string;
  assetCode?: string;
  assetDescription?: string;
  codeMessageType?: string;
  derogationLevel: string;
  description?: string;
  instanceName?: string;
  isAlert?: boolean;
  messageCode?: string;
  messageDescription: string;
  messageLevel?: string;
  objectId?: string;
  sectionCode?: string;
  sectionDescription?: string;
  unitCode?: string;
  unitDescription?: string;

  constructor(messageDescription, derogationLevel, codeMessageType) {
    this.messageDescription = messageDescription;
    this.derogationLevel = derogationLevel;
    this.codeMessageType = codeMessageType;
  }
}

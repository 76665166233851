<div class="ppevo-tree-item" (click)="toggleContent()">
    <span [ngClass]="productCfg.expandContent?'ppevo-tree-close':'ppevo-tree-open'"></span>
    <span [ngClass]="{'ppevo-non-selectable':!isProductSelectable()}">
        <input type="checkbox" [(ngModel)]="product.selected" (click)="onClickSelect($event)" [disabled]="!isEdit"/>
    </span>
    <span class="name">{{product.name}}</span>
</div>
<div class="ppevo-info" title="{{'NG_PASSPROPRO_SURVEY.information'|translate}}" *ngIf="productCfg.canShowInfo" (click)="onClickInfoProduct()"></div>

<!-- Icona documento per download-->
<span class="ppevo-info-ui rgi-ui-icon-book" *ngIf="(documents$|async) as documents" (click)="downloadDocument(documents)"></span>


<div *ngIf="false" class="ppevo-score-box" survey-eval-score [scoreObj]="product" [scoreVisible]="productCfg.canShowScore" [scoreStyleClass]="productCfg.scoreStyleClass"></div>

<div *ngIf="productCfg.cluster" [ngClass]="productCfg.cluster.styleClass" title="{{productCfg.cluster.title}}"></div>

<ng-container *ngIf="validation">

    <div *ngFor="let valMsg of validation.messages"
            class="ppevo-validation-message-box"
            [ngClass]="valMsg.styleClass"
    >
        <div class="ppevo-validation-message">{{valMsg.msg}}</div>
    </div>

    <div *ngIf="validation.hasInvalidCoverages" class="ppevo-validation-product-invalid-covs" translate>NG_PASSPROPRO_SURVEY.validation-product-invalid-covs</div>
    <div *ngIf="validation.hasInvalidPackages" class="ppevo-validation-product-invalid-pkgs" translate>NG_PASSPROPRO_SURVEY.validation-product-invalid-pkgs</div>
</ng-container>

<div *ngIf="!isProductSelectable() && isEdit" class="ppevo-product-selection-toggle-all-box">
    <button *ngIf="productCfg.showDeselectAll" class="ppevo-btn-deselect-all" (click)="toggleSelectAll(false)" translate>NG_PASSPROPRO_SURVEY.product-deselect-all</button>
    <button *ngIf="productCfg.showSelectAll" class="ppevo-btn-select-all" (click)="toggleSelectAll(true)" translate>NG_PASSPROPRO_SURVEY.product-select-all</button>
</div>

<div class="anag-w-100 anag-section-card">
  <div class="anag-section-card-header">
    <span class="anag-section-card-icon rgi-ui-icon-ellipsis-menu"></span>
    <span class="anag-section-title rgi-ui-text-3">{{'_ANAG_._LABEL_._PAYMENT_' | translate}}</span>
    <button class="anag-section-edit-button" data-qa="edit-payments" (click)="editPayments()"
            *ngIf="stateMgr.getCurrentState().editorModeEnable" [disabled]="stateMgr.getCurrentState().blockedFlow">
      <span class="rgi-ui-icon-edit2 anag-edit-icon"></span>
    </button>
  </div>
  <div class="anag-section-content">
    <div rgi-rx-accordion *ngIf="!!inputParty?.payments?.length; else messageNothingToShow">
      <rgi-rx-expansion-panel color="default" *ngFor="let paymentMethod of inputParty.payments; index as i"
                              [attr.data-qa]="'anag-payment-method_'+i">
        <rgi-rx-expansion-panel-header>
          <div rgi-rx-expansion-panel-label>{{paymentMethod.paymentTypeDescription}} - {{PAYMENT_TYPES.get(paymentMethod.paymentType) | translate}}</div>
        </rgi-rx-expansion-panel-header>
        <div rgiRxExpansionPanelContent *ngIf="getPayments(paymentMethod) as payment">
          <div class="rgi-ui-grid-2" *ngFor="let field of payment.paymentFields">
              <div class="rgi-ui-col" *ngIf="getFieldValueFromPartyForSection(paymentMethod, field)">
                <span>{{field.label}}:</span>
              </div>
              <div class="rgi-ui-col" *ngIf="getFieldValueFromPartyForSection(paymentMethod, field)">
                <b>{{getFieldValueFromPartyForSection(paymentMethod, field)}}</b>
              </div>
          </div>
        </div>
      </rgi-rx-expansion-panel>
    </div>
    <ng-template class="rgi-ui-snackbar rgi-ui-default" #messageNothingToShow>
      <div class="rgi-ui-snackbar rgi-ui-default">
        <span class="rgi-ui-message" translate>_ANAG_._MSG_._NO_ELEMENT_TO_SHOW_</span>
      </div>
    </ng-template>
  </div>
</div>

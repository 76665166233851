import { Component, OnInit, Input, Inject, Optional } from '@angular/core';
import { KarmaProfileDefinition } from '../../model/karma-profile-definition';
import {
  LpcCurrencyCache,
  CurrencyCacheService,
} from '../../../../services/currency-cache.service';
import { KarmaFundDefinition } from '../../model/karma-fund-definition';

@Component({
  selector: 'lpc-karma-fund-table',
  templateUrl: './lpc-karma-fund-table.component.html',
  styleUrls: ['./lpc-karma-fund-table.component.scss'],
})
export class LpcKarmaFundTableComponent implements OnInit {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency',
  };
  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumIntegerDigits: 1,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  // - questo componente funziona passando in input profiles (lo possiede qualunque operazione con gli investimenti)
  //     sarà esso stesso a filtrare i profili e i fondi selezionati, scartando quelli da non visualizzare
  //     infatti nell'html viene ciclato il getter selectedProfiles e viene usato selectedFundsFilter per filtrare i fondi
  // - la percentuale può trovarsi in percent o in percentage a seconda delle operazioni, quando vado ad utilizzarla
  //     la recupero tramite il metodo getPercentage (fund o profile) che si occupa di recuperarla ovunque essa sia
  // - le percentuali dovranno essere in un range da 0 a 1, se vengono passate in un range classico da 0 a 100
  //     tramite il metodo haveToFormatPercentage verrà recepito e corretto con formatPercentageValue
  // - se il fondo o il profilo non conviene l'amount, verrà utilizzato il totalAmount per calcolare gli importi
  // - la colonna degli importi può essere nascosta attivando showAmountColumnOnViewOnly
  // - il flag enableOpenListDefault apre tutti i profili per visualizzare i risultati all'onInit

  @Input() profiles: KarmaProfileDefinition[]; // profili e fondi
  @Input() totalAmount: number; // serve in caso il profilo/fondo non abbia l'amount calcolato
  @Input() showAmountColumnOnViewOnly = true; // nasconde la colonna dei valori in valuta
  @Input() enableOpenListDefault = false; // true => apre le liste in automatico
  @Input() isFreeManagement;
  @Input() investedProfiles: KarmaProfileDefinition[];

  public profileToggle = {};

  constructor(
    @Optional()
    @Inject(LpcCurrencyCache)
    protected currencyService: CurrencyCacheService
  ) {
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit() {
    this.profileToggle = {};
    this.profiles.forEach(
      (profile) => (this.profileToggle[profile.id] = this.enableOpenListDefault)
    );
    if (this.haveToFormatPercentage()) {
      this.formatPercentageValue();
    }
  }


  // restituisce i fondi investiti di un profilo, ovvero quellli da cui si può disivestire
  public getInvestedFundsByProfileId(id: number): KarmaFundDefinition[] {
    const profileFound = this.investedProfiles.find(el => el.id.toString() === id.toString());
    if (!!profileFound) {
      return profileFound.funds;
    }
  }


  /** filtra tutti i profili selezionati */
  get selectedProfiles(): KarmaProfileDefinition[] {
    if (!!this.profiles && this.profiles.length > 0) {
      // se il profilo e' singolo ritorna solo quello come selezionato
      if (this.profiles.length === 1) {
        return [this.profiles[0]];
      }
      return this.profiles.filter((p) => this.getPercentage(p) > 0);
    } else {
      return [];
    }
  }

  /** filtra tutti i fondi selezionati */
  selectedFundsFilter(funds) {
    return funds.filter((f) => this.getPercentage(f) > 0);
  }

  /** recupera la percentuale da percentage o percent (in base a quello che è valorizzato) */
  getPercentage(fund: any): number {
    return !!fund.percent
      ? Number(fund.percent)
      : !!fund.percentage
      ? Number(fund.percentage)
      : 0;
  }

  /* recupera la percentuale dalla somma dei fondi da esporre in tabella
   * nel caso dei disinvestimenti la percentuale segnata sul fondo indica
   * la percentuale disinvestita, ma a noi interessa esporre il totale
   */
  getPercentageProfile(profile: any): number {
    let sum = 0;
    profile.funds.forEach((f) => {
      sum += this.getPercentage(f);
    });
    return sum;
  }

  /** apre/chiude il dettaglio del profilo cliccato */
  public toggleProfile(profileId: string) {
    this.profileToggle[profileId] = !this.profileToggle[profileId];
  }

  /**
   * la tabella utilizza la pipe -> 1 => 100% | 0.5 => 50% | 0.25 => 25%
   * quindi gli passo le percentuali divise per 100
   */
  formatPercentageValue() {
    this.profiles.forEach((p) => {
      p.funds.forEach((f) => {
        f.percent = Number(f.percent) ? Number(f.percent) / 100 : 0;
      });
      p.percent = Number(p.percent) ? Number(p.percent) / 100 : 0;
    });
  }

  /** la percentuale di un fondo è calcolata sul totale del profilo al quale appartiene
   * ESEMPIO: profilePerc = 0.5 | fundPerc = 0.5 | totalAmount = 100
   * il profilo avrà 50 investiti, di cui il 50% sono del fondo in questione => (0.5 * 100) * 0.5 = 25
   */
  getFundAmount(profile, fund) {
    if (this.isFreeManagement) {
        return this.getInvestedFundsByProfileId(profile.id).find(
          selectedFund => selectedFund.id.toString() === fund.id.toString()).counterValue * this.getPercentage(fund);
      }
    // Default calculation for cases when isFreeManagement is false or for other fund types
    if (!!fund.amount && Number(fund.amount) > 0) {
      return fund.amount;
    } else if (!!Number(profile.amount)) {
      return this.totalAmount * profile.percentage * this.getPercentage(fund);
    } else {
      return (
        this.getPercentage(profile) *
        this.totalAmount *
        this.getPercentage(fund)
      );
    }
  }

  /** controlla le somme dei profili e dei fondi per capire se è da formattare (nel range 0-1) */
  haveToFormatPercentage() {
    let profilesSum = 0;
    let fundsSum = 0;

    this.profiles.forEach((p) => {
      profilesSum += this.getPercentage(p);
    });

    this.profiles.forEach((p) => {
      p.funds.forEach((f) => {
        fundsSum += this.getPercentage(f);
    }); });

    return profilesSum === 100 || fundsSum === 100;
  }
}

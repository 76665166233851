import {AfterViewChecked, Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {JS_EVENT, PV_TOKEN} from '../../../models/consts/lpc-consts';
import {Profile} from '../../../models/life-detail.model';

@Component({
  selector: 'lpc-life-investment-plan-detail',
  templateUrl: './life-investment-plan-detail.component.html',
  styleUrls: ['../life-movement-detail/life-movement-detail.component.scss', './life-investment-plan-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LifeInvestmentPlanDetailComponent implements OnInit, AfterViewChecked {

  @Input() id: string;
  @Input() data: any;
  @Output() eventPropagation = new EventEmitter<any>();

  public showLifeInvestmentPlanDetail: boolean;
  public investmentPlan: Array<Profile> = [];

  constructor(@Inject(PV_TOKEN.PV_GRAPHIC_FIX) protected plcGraphicFix) { }

  ngOnInit() {
    this.showLifeInvestmentPlanDetail = !!this.data && Array.isArray(this.data.investmentPlan) && this.data.investmentPlan.length > 0;
    this.investmentPlan = this.showLifeInvestmentPlanDetail ? this.data.investmentPlan : [];
  }

  ngAfterViewChecked(): void {
    this.plcGraphicFix.fixCardGraphic(this.id);
  }

  public back() {
    this.eventPropagation.emit(JS_EVENT.BACK_TO_LIFE_DETAIL);
  }

}

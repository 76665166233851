import {RgiRxi18nModuleLoadType, RgiRxTranslations} from '@rgi/rx/i18n';
import {RGI_RX_TABLE_i18n} from './i18n/localization';

export function load18nTableIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_TABLE_i18n.it);
  });
}

export function load18nTableEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_TABLE_i18n.en);
  });
}

export function load18nTableFR(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_TABLE_i18n.fr);
  });
}

export function load18nTableES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_TABLE_i18n.es);
  });
}

export function load18nTableDE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(RGI_RX_TABLE_i18n.de);
  });
}


export const RGI_RX_TABLE_TRANSLATIONS: RgiRxTranslations = [
  {
    locale: 'it',
    load: load18nTableIT
  },
  {
    locale: 'en',
    load: load18nTableEN
  },
  {
    locale: 'fr',
    load: load18nTableFR
  },
  {
    locale: 'es',
    load: load18nTableES
  },
  {
    locale: 'de',
    load: load18nTableDE
  }
];

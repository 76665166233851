import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'lib-update-report-modal',
  templateUrl: './update-report-modal.component.html'
})
export class UpdateReportModalComponent implements OnInit {

  @Output() modalClose = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  close() {
    this.modalClose.emit({close: true});
  }
}

<div class="modal-header">
  <h4 class="modal-title" translate>lpc_ACTIONS</h4>
</div>
<div class="modal-body">
  <div *ngIf="availOperationsTree && availOperationsTree.size === 0"
    class="align-center" data-qa="operation-list-empty">
    <span>{{'lpc_NoOperations' | translate}}</span>
  </div>
  <div *ngFor="let operationType of availOperationsTree | keyvalue">
    <div (click)="accordionEvent(accordion)" class="operation-group operation-list">
      <span [ngClass]="{'rgi-plus':accordion.hidden,'rgi-minus':!accordion.hidden }">
      </span><span>{{operationType.key}}</span>
    </div>
    <div #accordion data-qa="operation-list" hidden>
      <div *ngFor="let operation of operationType.value">
        <a (click)="openSession(operation.slug, operation.description, operation.isFinancial)"
           class="operation-line clickable"
           [attr.data-qa]="'operation_'+operation.description">{{operation.description}}</a>
      </div>
    </div>
  </div>
  <div id="box-loading" *ngIf="mobileLoaderActive && loader">
    <lpc-loader-mobile [loader]="loader"></lpc-loader-mobile>
  </div>
</div>
<div class="modal-footer">
  <div class="app-modal-footer">
    <div class="btn-group btn-group-justified">
      <div class="btn-group">
        <button (click)="close()" class="btn btn-warning" data-qa="close-button-action" translate>lpc_close_button</button>
      </div>
    </div>
  </div>
</div>



import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Questionnaire } from '../model/questionnaire.model';

@Component({
  selector: 'lib-questionnaires-list',
  templateUrl: './questionnaires-list.component.html',
  styleUrls: ['./questionnaires-list.component.css']
})
export class QuestionnairesListComponent implements OnInit {

  @Input() quests: Array<Questionnaire>;
  @Input() readOnlyCategories: Array<string>;
  @Output() getQuest = new EventEmitter<string>();
  @Input() loader = false;
  @Output() selectedQuest = new EventEmitter<string>();

  constructor() { }

  isReadOnly(quest: Questionnaire) {
    if (!!this.readOnlyCategories && this.readOnlyCategories.length > 0) {
      return this.readOnlyCategories.includes(quest.questionnaireType.code);
    } else {
      return false;
    }
  }

  ngOnInit() {
    if (!this.quests) {
      throw new Error('Attribute \'quests\' is required');
    }
  }

  getSelectedQuest(event: string) {
    this.getQuest.emit(event);
  }

  // emette un evento che contiene il questionario da aprire
  selectedQuestEmitter(event) {
    this.selectedQuest.emit(event);
  }

}

import {State} from '@rgi/rx/state';
import {ProposalStatus, Quotation} from '../../models/domain-models/quotation';
import {Asset} from '../../models/domain-models/asset';
import {PolicyTechnicalData} from '../../models/domain-models/parameters/policy-technical-data';
import {LpsData} from '../../models/domain-models/parameters/policy-lps-data';
import {QuestionnaireFlatI} from '../../models/domain-models/questionnaire-flatI';
import {Clause, ClausesResponse} from '../../models/domain-models/clause';
import {AnagIssueSubject} from '../re-issue-anag/anag-issue';
import {PolicyContact} from '../../models/domain-models/policy-contact';
import {Warning} from '../../models/domain-models/warning';
import {PcProposal} from '../../models/pc-portfolio-models/proposal-models/save-proposal-response';
import {PcAssetInstance} from '../../models/pc-portfolio-models/assets-models/pc-asset-instance';
import {Package} from '../../models/pc-portfolio-models/assets-models/pc-insured-asset';
import {Variable} from '../../models/domain-models/variable';
import {Beneficiary} from '../../models/domain-models/beneficiary';
import {Beneficiaries} from '../../models/domain-models/beneficiaries';

export class ReIssueQuotationState extends State { // this is the model of the state
  resourceId: string;
  quotation: Quotation;
  personAsset: Asset | null;
  quotationReady: boolean;
  policyTechnicalData: PolicyTechnicalData;
  lpsData: LpsData;
  questionnaire: QuestionnaireFlatI = new QuestionnaireFlatI();
  allClauses: Array<Clause>;
  clause: ClausesResponse;
  allAssets: Asset[] | null;
  isClausoleConfirmed: boolean;
  subject: AnagIssueSubject;
  contact: PolicyContact;
  warnings: Warning[] | null;
  nonBlockingWarnings: Warning[] | null;
  questionnaireValid = null;
  clauseIsValid = null;
  productName: string;
  productId: number;
  productCode: string;
  proposalStatus: ProposalStatus;
  node: string;
  companyId: string;
  editMode: boolean;
  isPersonaGiuridica: boolean;
  questionnaireCommitted: boolean;
  isSubjectModified: boolean;
  clauseCommitted: any;
  editFunctionality: boolean;
  isSubstitution: boolean;
  isToDisableFraz: boolean;
  proposal: PcProposal;
  assetInstances: Array<PcAssetInstance> = new Array<PcAssetInstance>();
  packages: Package[];
  sync: boolean;
  authorize: boolean;
  bIntermediateSaving: boolean;
  variables: Array<Variable>;
  beneficiariesProduct: Beneficiary[];
  listBeneficiariesUnit: Array<Beneficiaries> = new Array<Beneficiaries>();
  isBeneficiaryUnitVisibility: boolean;
  isBeneficiaryUnitVisibilityArray = new Array<string>();
  beneficiarySelected: Array<Beneficiary> = new Array<Beneficiary>();
  thirdPersonContact: any;
  clausesFromAsset: Array<PcAssetInstance>;
  beneficiaryParties = [];
  beneficiariesSelected = [];
}

import {Injectable} from '@angular/core';
import {EnigmaService} from '../services/enigma.service';
import {ReportElement} from '../model/report-element';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsSharedService {

  constructor() { }

  openQlikSense(url: string): void {
    window.open(url, '_blank');
  }

  async renderCharts(elementId: any, nebula: any, selector: string) {
    return await nebula.render({
      element: document.querySelector(selector),
      id: elementId,
    });
  }

  async unrenderCharts(nebulaCharts: any, nebula: any) {
    if (nebula) {
      const selections = await nebula.selections();
      if (selections) {
        await selections.unmount();
      }
      for (const element of nebulaCharts) {
        if (element) {
          element.destroy();
        }
      }
    }
  }

  async renderFilters(allFilters: any, element: ReportElement, nebula: any) {
    const field = allFilters.find(el => el.qInfo.qId === element.idQlikElement);
    if (field) {
      let filterSelector = document.querySelector('.filter' + element.id);
      if (filterSelector) {
        const fieldOptions = {title: field.qMeta.title};
        (await nebula.field(field.qData.info[0].qName)).mount(filterSelector, fieldOptions);
      }
    }
  }
}

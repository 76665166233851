import {Component, EventEmitter, Inject, OnInit, Optional} from '@angular/core';
import {
  DIALOG_DATA,
  OnModalClose,
  RgiRxDataTableDataSource,
  RgiRxDatatableRowAction,
  TableSchema
} from '@rgi/rx/ui';
import {AnagStateManagerPartyEditor} from '../../anag-states/party-editor/anag-state-manager-party-editor';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {AnagApiAddress, AnagApiParty} from '../../anag-model/anag-domain/anag-api-party';
import {
  AnagDynamicAttribute,
  AnagDynamicObj,
  AnagRowDataDynamicObject
} from '../../anag-model/anag-domain/anag-dynamic-obj';
import {AnagDynamicObjectsService} from '../../services/anag-dynamic-objects.service';
import {PARTY_DYNAMIC_OBJECT_EDIT_TABLE_SCHEMA} from '../../anag-constants/anag-party-dynamic-object-tableschema';
import {PARTY_DYNAMIC_OBJECT_EDIT_TABLE_SCHEMA_LIM} from '../../anag-constants/anag-party-dynamic-object-tableschema';
import {ANAG_DYNAMIC_OBJ_MODE} from '../../anag-constants/anag-constants';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {PushMessageHandlerService, RgiRxPushMessage} from '@rgi/rx';
import {RgiRxTranslationService} from '@rgi/rx/i18n';

@Component({
  selector: 'rgi-anag-dynamic-objects',
  templateUrl: './anag-dynamic-objects.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagDynamicObjectsComponent implements OnInit, OnModalClose {

  /**
   * @internal set the Type when including this or will fail to link @rgi/rx 1.x in view Engine libraries
   * since the compiler fail to reference the import correctly ang generates:
   * XXXSCHEMA: import("@rgi/rx/ui/rgi-rx-ui").TableSchema;
   * Hence the ngcc linker fails.
   * Specify the type : TableSchema to prevent this issue.
   * Also prevent using the same property name to reference a const from an outside module, this is a code smell.
   * Eventually transform those constants in factories or the clone the reference because this is another code smell,
   * since modifying the reference will modify the original object and can produce nasty bugs when a component modifies
   * the schema!
   */
  PARTY_DYNAMIC_OBJECT_EDIT_TABLE_SCHEMA: TableSchema = PARTY_DYNAMIC_OBJECT_EDIT_TABLE_SCHEMA;
  PARTY_DYNAMIC_OBJECT_EDIT_TABLE_SCHEMA_LIM: TableSchema = PARTY_DYNAMIC_OBJECT_EDIT_TABLE_SCHEMA_LIM;

  modalClose: EventEmitter<any> = new EventEmitter();

  inputParty: AnagApiParty;
  inputDynamicObject: AnagDynamicObj;
  editableDynamicObject: AnagDynamicObj;
  dynamicObjectTableData = new RgiRxDataTableDataSource<AnagRowDataDynamicObject>([]);
  prevEditorMode: number;
  editorMode = ANAG_DYNAMIC_OBJ_MODE.VIEW;
  ANAG_DYNAMIC_OBJ_MODE = ANAG_DYNAMIC_OBJ_MODE;
  ubicationForm = new UntypedFormGroup({});
  errorMsg: string;
  editDynaObjForm: UntypedFormGroup = new UntypedFormGroup({});
  editableDynaObjIndex: number;
  isFormSubmitted = false;
  noObjToShow = false;
  tmpAddress: AnagApiAddress;
  isIstanceOfLimitedDynamicObj: boolean;
  visibilityVal: string;

  constructor(
    public pushMessageHandler: PushMessageHandlerService,
    protected translateService: RgiRxTranslationService,
    public stateMgr: AnagStateManagerPartyEditor,
    public anagStorage: AnagStorageService,
    public dynamicObjectService: AnagDynamicObjectsService,
    protected statelessService: AnagStatelessService,
    @Optional() @Inject(DIALOG_DATA) data: any
  ) {
    this.inputParty = data.party;
    this.inputDynamicObject = this.statelessService.deepClone(data.dynamicObject);
  }

  ngOnInit() {
    this.translation();
    this.pushMessageHandler.clearTag('address-tag');
    this.dynamicObjectService.getDynamicObjectTableData$(this.inputDynamicObject).subscribe(value => {
      this.visibilityVal = this.getVisibilityByName("CF", this.inputDynamicObject.dynamicAttributes);
      this.isIstanceOfLimitedDynamicObj = this.visibilityVal === '3' || this.visibilityVal === '4';
      this.noObjToShow = value.length === 0;
      this.dynamicObjectTableData.update(value);
    });
  }

  getVisibilityByName(attributeName, dynamicAttributes) {
    let visibilityValue = null;
    for (let i = 0; i < dynamicAttributes.length; i++) {
      if (dynamicAttributes[i].codeDynAttribute === attributeName && dynamicAttributes[i].visibilityValues && dynamicAttributes[i].visibilityValues.codice) {
        visibilityValue = dynamicAttributes[i].visibilityValues.codice ? dynamicAttributes[i].visibilityValues.codice : null;
      }
    }
    return visibilityValue;
  }

  actionClose() {
    this.modalClose.emit();
  }

  updateDynamicObject() {
    this.stateMgr.updateDynamicObject(this.inputDynamicObject);
    this.modalClose.emit();
  }

  onActionClick($event: RgiRxDatatableRowAction<AnagRowDataDynamicObject>) {
    switch ($event.name) {
      case 'DELETE':
        this.dynamicObjectService.removeRowToDynamicObject(this.inputDynamicObject, $event.row.idObjectInstance, $event.row.index);
        this.dynamicObjectService.getDynamicObjectTableData$(this.inputDynamicObject).subscribe(value => {
          this.noObjToShow = value.length === 0;
          this.dynamicObjectTableData.update(value);
        });
        break;
      case 'EDIT':
        this.editableDynaObjIndex = $event.row.index;
        this.editableDynamicObject = this.dynamicObjectService.createEditableDynamicObject(
          this.inputDynamicObject,
          $event.row.idObjectInstance,
          $event.row.index
        );
        this.addElementToForm();
        this.isFormSubmitted = false;
        this.prevEditorMode = this.editorMode;
        this.editorMode = ANAG_DYNAMIC_OBJ_MODE.EDIT;
        break;
    }
  }

  protected addElementToForm() {
    this.editableDynamicObject.dynamicAttributes.forEach(attribute => {
      const formControl = new UntypedFormControl('', this.dynamicObjectService.getValidatorByType(attribute));
      formControl.setValue(
        this.dynamicObjectService.getValueAttributeByType(attribute.values[0], attribute.typeAttribute.codice)
      );
      this.editDynaObjForm.addControl('formControl' + attribute.codeDynAttribute, formControl);
    });
  }

  onAddObj($event: MouseEvent) {
    this.editableDynamicObject = this.dynamicObjectService.createInsertableDynamicObject(this.inputDynamicObject);
    this.addElementToForm();
    this.isFormSubmitted = false;
    this.prevEditorMode = this.editorMode;
    this.editorMode = ANAG_DYNAMIC_OBJ_MODE.INSERT;
  }

  onUndoEdit($event: MouseEvent) {
    this.pushMessageHandler.clearTag('error-tag');
    this.editableDynamicObject = null;
    this.editDynaObjForm = new UntypedFormGroup({});
    this.isFormSubmitted = false;
    this.prevEditorMode = this.editorMode;
    this.editorMode = ANAG_DYNAMIC_OBJ_MODE.VIEW;
  }

  onConfirmEdit($event: MouseEvent) {
    this.pushMessageHandler.clearTag('error-tag');
    this.isFormSubmitted = true;
    if (this.editDynaObjForm.invalid) {
      this.translateService.translate('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_').subscribe(stringMsg =>
        this.pushMessageHandler.notify(new RgiRxPushMessage(stringMsg, {
          tag: 'error-tag',
          status: 'danger',
          dismissible: false
        }))
      ).unsubscribe();
    } else {
      this.inputDynamicObject.dynamicAttributes.forEach(dynaAttribute => {
        const editedDynaAttr = this.editableDynamicObject.dynamicAttributes.find(editedAttr =>
          editedAttr.codeDynAttribute === dynaAttribute.codeDynAttribute
        );
        if (this.editorMode === ANAG_DYNAMIC_OBJ_MODE.INSERT) {
          const value = this.editDynaObjForm.get('formControl' + dynaAttribute.codeDynAttribute).value;
          this.dynamicObjectService.setValueAttributeByType(editedDynaAttr.values[0], dynaAttribute.typeAttribute.codice, value, editedDynaAttr.values[0]);
          dynaAttribute.values.push(editedDynaAttr.values[0]);
        } else if (this.editorMode === ANAG_DYNAMIC_OBJ_MODE.EDIT) {
          let anagDynAttributeValue;
          if (editedDynaAttr.values[0].idDynObjectInstance) {
            anagDynAttributeValue = dynaAttribute.values.find(val =>
              val.idDynObjectInstance === editedDynaAttr.values[0].idDynObjectInstance
            );
          } else {
            anagDynAttributeValue = dynaAttribute.values[this.editableDynaObjIndex];
          }
          const value = this.editDynaObjForm.get('formControl' + dynaAttribute.codeDynAttribute).value;
          this.dynamicObjectService.setValueAttributeByType(anagDynAttributeValue, dynaAttribute.typeAttribute.codice, value, editedDynaAttr.values[0]);
        }
      });
      this.prevEditorMode = this.editorMode;
      this.editorMode = ANAG_DYNAMIC_OBJ_MODE.VIEW;
      this.dynamicObjectService.getDynamicObjectTableData$(this.inputDynamicObject).subscribe(value => {
        this.dynamicObjectTableData.update(value);
        this.noObjToShow = value.length === 0;
      });
      this.editDynaObjForm = new UntypedFormGroup({});
    }
  }

  isFieldInvalid(attribute: AnagDynamicAttribute): boolean {
    const formControl = this.editDynaObjForm.get('formControl' + attribute.codeDynAttribute);
    return formControl.invalid && (formControl.touched || this.isFormSubmitted);
  }

  updateDynaObjAddress(address: AnagApiAddress) {
    this.tmpAddress = address;
  }

  onAddAddress($event: MouseEvent) {
    this.pushMessageHandler.clearTag('error-tag');
    this.prevEditorMode = this.editorMode;
    this.editorMode = ANAG_DYNAMIC_OBJ_MODE.ADDRESS;
  }

  onUndoAddAddress($event: MouseEvent) {
    this.pushMessageHandler.clearTag('address-tag');
    this.editorMode = this.prevEditorMode;
    this.tmpAddress = null;
  }

  onConfirmAddAddress($event: MouseEvent) {
    this.pushMessageHandler.clearTag('address-tag');
    if(this.ubicationForm.valid) {
      this.setAddressToEditableObj(this.tmpAddress);
      this.editorMode = this.prevEditorMode;
      this.tmpAddress = null;
    }
    else {
      let msg = this.errorMsg;
      this.ubicationForm.get('addressForm').markAllAsTouched();
      this.notifyFormValidationMsg(msg);
    }
  }

  notifyFormValidationMsg(stringMsg) {
    this.pushMessageHandler.notify(new RgiRxPushMessage(stringMsg, {
      tag: 'address-tag',
      status: 'danger',
      dismissible: false
    }));
  }

  onEditAddress($event: MouseEvent) {
    this.tmpAddress = this.getAddressFromEditableObj();
    this.prevEditorMode = this.editorMode;
    this.editorMode = ANAG_DYNAMIC_OBJ_MODE.ADDRESS;
  }

  isAddressPresent(): boolean {
    return !!this.getAddressFromEditableObj();
  }

  protected getAddressFromEditableObj(): AnagApiAddress {
    return this.editableDynamicObject.dynamicAttributes.find(attr => attr.typeAttribute.codice === '8').values[0].addressValue;
  }

  protected setAddressToEditableObj(address: AnagApiAddress) {
    this.editableDynamicObject.dynamicAttributes.find(attr => attr.typeAttribute.codice === '8').values[0].addressValue = address;
  }

  onDeleteAddress($event: MouseEvent) {
    this.editableDynamicObject.dynamicAttributes.find(attr => attr.typeAttribute.codice === '8').values[0].addressValue = null;
    this.tmpAddress = null;
  }

  getValuedAddressLabel(address: AnagApiAddress): string {
    return address.formatAddress ? address.formatAddress : ''.concat(address.toponym ? address.toponym.descrizione + ' ' : '')
      .concat(address.placeAddress ? address.placeAddress : ' ')
      .concat(address.number ? ', ' + address.number : ' ')
      .concat(address.cap ? ' - ' + address.cap + ' ' : ' ')
      .concat(address.adminLevel3 ? address.adminLevel3 : ' ')
      .concat(address.adminLevel2Short ? ' (' + address.adminLevel2Short + ') - ' : ' - ')
      .concat(address.countryCode ? address.countryCode : '');
  }

  translation() {
    this.translateService.translate('_ANAG_._MSG_._GENERAL_ERROR_MANDATORY_FIELDS_').subscribe(translated =>  this.errorMsg = translated).unsubscribe();
  }
}

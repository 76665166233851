<div class="rgi-ui-panel-container">
  <div class="rgi-ui-panel-header rgi-anag-p-1">
    <span class="anag-icon-m rgi-ui-icon-tariff-price"></span>
    <span class="rgi-ui-panel-title rgi-ui-text-1">{{'_ANAG_._LABEL_._TAX_CLASSIFICATION_' | translate}}</span>
    <span class="rgi-ui-btn-close" data-qa="close-modal" (click)="actionClose()"></span>
  </div>
  <div class="rgi-ui-panel-content">
    <div>
      <form [formGroup]="newTaxClassificationForm">
        <rgi-rx-tab-group (onTabSelectionChange)="onTypeTabSelected($event)" [selectedIndex]="getSelectedTabIndex()">
          <rgi-rx-tab label="FATCA" [disabled]="getDisabledTab() === 1">
            <ng-template rgiRxTabContent>

            </ng-template>
          </rgi-rx-tab>
          <rgi-rx-tab label="CRS" [disabled]="getDisabledTab() === 0">
            <ng-template rgiRxTabContent>

            </ng-template>
          </rgi-rx-tab>
        </rgi-rx-tab-group>
        <div *ngIf="typeSelected">
          <div class="rgi-ui-grid-2">
            <div class="rgi-ui-col">
              <rgi-rx-form-field>
                <label rgiRxLabel translate>{{'_ANAG_._LABEL_._COUNTRY_' | translate}}</label>
                <select rgiRxNativeSelect formControlName="country" data-qa="country">
                  <option [value]=""></option>
                  <option *ngFor="let country of filteredCountries"
                          [value]="country.abbreviation">
                    {{country.description}}
                  </option>
                </select>
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col" *ngIf="isFieldVisible('tin')">
              <rgi-rx-form-field>
                <input rgiRxInput formControlName="tin" data-qa="tin">
                <label rgiRxLabel>TIN</label>
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col" *ngIf="isFieldVisible('classificationRisk')">
              <rgi-rx-form-field>
                <label rgiRxLabel translate>{{'_ANAG_._LABEL_._RISK_CLASSIFICATION_' | translate}}</label>
                <select rgiRxNativeSelect formControlName="classificationRisk" data-qa="risk">
                  <option [value]=""></option>
                  <option *ngFor="let risk of filteredRiskList"
                          [value]="risk.codice">
                    {{risk.descrizione}}
                  </option>
                </select>
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col" *ngIf="isFieldVisible('classificationReason')">
              <rgi-rx-form-field>
                <label rgiRxLabel translate>{{'_ANAG_._LABEL_._REASON_CLASSIFICATION_' | translate}}</label>
                <select rgiRxNativeSelect formControlName="classificationReason" data-qa="reason">
                  <option [value]=""></option>
                  <option *ngFor="let reason of filteredReasonList"
                          [value]="reason.codice">
                    {{reason.descrizione}}
                  </option>
                </select>
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col" *ngIf="isFieldVisible('classificationBasis')">
              <rgi-rx-form-field>
                <label rgiRxLabel translate>{{'_ANAG_._LABEL_._BASIS_CLASSIFICATION_' | translate}}</label>
                <select rgiRxNativeSelect formControlName="classificationBasis" data-qa="basis">
                  <option [value]=""></option>
                  <option *ngFor="let basis of filteredBasisList"
                          [value]="basis.codice">
                    {{basis.descrizione}}
                  </option>
                </select>
              </rgi-rx-form-field>
            </div>
            <div class="rgi-ui-col" *ngIf="isFieldVisible('classificationDate')">
              <div>
                <rgi-rx-form-field style="display: contents">
                  <div style="display: block">
                    <label rgiRxLabel><span>{{'_ANAG_._LABEL_._CLASSIFICATION_DATE_' | translate}}</span></label>
                    <div style="display:flex;">
                      <input [rgiRxDateTimeInput]="classificationDatePicker"
                             style="flex: 1"
                             data-qa="classificationDate"
                             formControlName="classificationDate"
                             selectMode="single"
                             rangeSeparator="-"
                             [placeholder]="datepickerPlaceholder" #inputToTriggerClassificationDate="rgiRxDateTimeInput">
                      <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                              [rgiRxDateTimeTrigger]="inputToTriggerClassificationDate"></button>
                    </div>
                  </div>
                  <rgi-rx-date-time #classificationDatePicker
                                    pickerType="calendar"
                                    [hour12Timer]="true"
                  ></rgi-rx-date-time>
                </rgi-rx-form-field>
              </div>
            </div>
            <div class="rgi-ui-col">
              <div>
                <rgi-rx-form-field style="display: contents">
                  <div style="display: block">
                    <label rgiRxLabel><span>{{'_ANAG_._LABEL_._COUNTRY_START_DATE_' | translate}}</span></label>
                    <div style="display:flex;">
                      <input [rgiRxDateTimeInput]="countryStartDatePicker"
                             style="flex: 1"
                             data-qa="countryStartDate"
                             formControlName="countryStartDate"
                             selectMode="single"
                             rangeSeparator="-"
                             [placeholder]="datepickerPlaceholder" #inputToTriggerCountryStartDate="rgiRxDateTimeInput">
                      <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                              [rgiRxDateTimeTrigger]="inputToTriggerCountryStartDate"></button>
                    </div>
                  </div>
                  <rgi-rx-date-time #countryStartDatePicker
                                    pickerType="calendar"
                                    [hour12Timer]="true"
                  ></rgi-rx-date-time>
                </rgi-rx-form-field>
              </div>
            </div>
            <div class="rgi-ui-col" *ngIf="isFieldVisible('countryEndDate')">
              <div>
                <rgi-rx-form-field  style="display: contents">
                  <div style="display: block">
                  <label rgiRxLabel><span>{{'_ANAG_._LABEL_._COUNTRY_END_DATE_' | translate}}</span></label>
                    <div style="display:flex;">
                      <input [rgiRxDateTimeInput]="countryEndDatePicker"
                             style="flex: 1"
                             data-qa="countryEndDate"
                             formControlName="countryEndDate"
                             selectMode="single"
                             rangeSeparator="-"
                             [placeholder]="datepickerPlaceholder" #inputToTriggerCountryEndDate="rgiRxDateTimeInput">
                      <button style="flex: 0; order: 1" rgi-rx-button variant="icon" class="rgi-ui-icon-calendar2"
                              [rgiRxDateTimeTrigger]="inputToTriggerCountryEndDate"></button>
                    </div>
                  </div>
                  <rgi-rx-date-time #countryEndDatePicker
                                    pickerType="calendar"
                                    [hour12Timer]="true"
                  ></rgi-rx-date-time>
                </rgi-rx-form-field>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <rgi-rx-snackbar tag="error-msg-tag"></rgi-rx-snackbar>
  <div class="rgi-ui-panel-footer">
    <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="actionConfirm()"
            data-qa="anag-btn-upd-residence" translate>_ANAG_._BTN_._CONFIRM_
    </button>
  </div>
</div>


import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {EventNotificator} from '../event-notificator';
import {RoutableComponent} from '../routable-component';
import {Variable} from '../models/domain-models/variable';
import {Province} from '../models/domain-models/province';
import {City} from '../models/domain-models/city';
import {GISService} from '../gis-service';
import {ProposalService} from '../proposal.service';
import {VariablesService} from './variables-service';
import {CustomModalService} from '../custom-modal.service';
import {DatePipe} from '@angular/common';
import {Subscription} from 'rxjs';
import {Modal} from '../modal';
import {VariablesModalComponent} from './variables-modal/variables-modal.component';

@Component({
  selector: 'mic-variables',
  templateUrl: './variables.component.html',
  styleUrls: ['./variables.component.scss']
})
export class VariablesComponent implements OnInit, OnDestroy, RoutableComponent, EventNotificator {

  @Output() navigation: EventEmitter<any> = new EventEmitter<string>();
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  @Output() variablesCompleted: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  variables: Array<Variable> = new Array<Variable>();

  filteredVariables: Array<Variable>;
  @Input()
  variablesRows: Variable[][];
  @Input()
  variablesCompletenessErrors = false;
  @Input()
  partyAreaVariablesCompletenessErrors = false;
  @Input()
  assetAreaVariablesCompletenessErrors = false;
  @Input()
  variablesType = null;
  provinces: Array<Province>;
  cities: Array<City>;

  @Input() editable = true;
  @Input() showHeader = true;

  @ViewChild('variablesModalOverlay', {
    read: ViewContainerRef,
    static: true
  }) variablesModalViewContainerRef: ViewContainerRef;
  componentRef: any;
  variablesModal: Type<Modal>;
  protected subscriptions: Subscription = new Subscription();

  constructor(
    protected variablesService: VariablesService,
    protected gisService: GISService,
    protected proposalService: ProposalService,
    protected datePipe: DatePipe,
    protected modalService: CustomModalService,
    @Inject('variablesModalComponent') variablesModalComponent?: Type<Modal>
  ) {
    this.variablesModal = variablesModalComponent;
  }

  ngOnInit() {
    this.initializeVariables();

    const variablesChannelSubscription = this.getVariablesObservable().subscribe(
      (data) => {
        this.filteredVariables = this.filterVariables(data);
        this.loadVariables();
      }
    );
    this.subscriptions.add(variablesChannelSubscription);

    const citiesSubscription = this.gisService.getCities().subscribe(
      (cities) => {
        this.cities = cities;
      }
    );
    this.subscriptions.add(citiesSubscription);
  }

  initializeVariables() {
    this.filteredVariables = this.filterVariables(this.variables);

    this.loadVariables();

    if (this.areVariablesCompleted() && !this.variablesCompletenessErrors) {
      this.variablesCompleted.emit(true);
    }
  }

  loadVariables() {
    const variablesNum = this.filteredVariables.length;
    if (variablesNum === 0) {
      return;
    }

    if (this.variablesType === 'PARTY') {

      // data nascita
      this.filteredVariables.forEach((variable) => {
        if (variable.code === '1DTNA1' && variable.valueDescription === null && this.proposalService.getDateOfBirth()) {
          variable.valueDescription = String(this.proposalService.getDateOfBirth());
          variable.value = this.datePipe.transform(variable.valueDescription, 'dd/MM/yyyy');
        }
      });

      this.chargeProvincesAndCities();
      this.sortVariables();

      this.variablesRows = new Array<Array<Variable>>(Math.ceil(variablesNum / 3));

      let i: number;
      let j = 0;

      for (i = 0; i < variablesNum; i++) {
        if (i !== 0 && i % 3 === 0) {
          j++;
        }
        if (!this.variablesRows[j]) {
          this.variablesRows[j] = [];
        }
        this.variablesRows[j].push(this.filteredVariables[i]);
      }

    }

  }

  goToEditVariables() {
    this.variablesService.setLastSelectedVariablesType(this.variablesType);

    this.modalService.openModal(this.variablesModalViewContainerRef,
      (this.variablesModal ? this.variablesModal : VariablesModalComponent),
      this.eventPropagation, () => {

        const cachedVariables = this.getCachedVariables();
        this.variables = this.filterVariables(cachedVariables);
        this.filteredVariables = this.filterVariables(cachedVariables);
        this.loadVariables();
        if (this.areVariablesCompleted()) {
          this.variablesCompleted.emit(true);
        }
      });
  }

  variablesTrackByFn(index, variable: Variable) {
    return variable.code;
  }

  getVariablesType() {
    if (this.variablesType === 'ASSET') {
      return 'Asset';
    } else if (this.variablesType === 'PARTY') {
      return 'Party';
    } else if (this.variablesType === 'EXTRA') {
      return 'Extra';
    } else {
      return '';
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  protected filterVariables(variablesToFilter: Array<Variable>) {
    return variablesToFilter.filter(
      (variable) => {
        if (!this.variablesType) {
          return true;
        } else {
          if (this.variablesType === 'ASSET') {
            return variable.visibilityArea === 0
              || variable.visibilityArea === 1
              || variable.visibilityArea === 4;
          } else if (this.variablesType === 'PARTY') {
            return variable.visibilityArea === 2;
          } else if (this.variablesType === 'EXTRA') {
            return variable.visibilityArea === 3;
          } else {
            return false;
          }
        }
      }
    );
  }

  protected getCachedVariables() {
    return this.variablesService.getCachedVariables();
  }

  protected getVariablesObservable() {
    return this.variablesService.getVariablesObserable();
  }

  protected areVariablesCompleted(): boolean {
    let variableCompleted = true;
    this.filteredVariables.forEach((variable) => {
      if (variable.compulsory && !variable.value) {
        variableCompleted = false;
      }
    });

    return variableCompleted;
  }

  private sortVariables() {
    this.filteredVariables.sort(
      (a: Variable, b: Variable) => {
        if (a.order > b.order) {
          return 1;
        } else if (a.order < b.order) {
          return -1;
        } else {
          return 0;
        }
      }
    );
  }

  private chargeProvincesAndCities() {
    const cityVariables = this.filteredVariables.filter((variable) => variable.variableClass === 7);
    const cityId: string = cityVariables && cityVariables.length > 0 ? cityVariables[0].value : null;

    const provinceVariable = this.filteredVariables.find((variable) => {
      return variable.variableClass === 8;
    });

    const cityVariable = this.filteredVariables.find((variable) => {
      return variable.variableClass === 7;
    });

    const prov = this.gisService.getProvinces();
    if (prov && prov.length > 0) {
      this.provinces = prov;

      this.initProvices(provinceVariable, prov, cityVariable, cityId);
    } else {
      const citiesGISSubscription = this.gisService.retrieveProvinces().subscribe(
        (provinces) => {
          this.provinces = provinces;
          this.gisService.setProvinces(provinces);

          this.initProvices(provinceVariable, provinces, cityVariable, cityId);
        },
        (err) => {
        }
      );
      this.subscriptions.add(citiesGISSubscription);
    }
  }

  private initProvices(provinceVariable, provinces, cityVariable, cityId: string) {
    if (provinceVariable && provinceVariable.valueDescription) {
      let provCode = '';
      for (const province of provinces) {
        if (province.id === parseFloat(provinceVariable.value)) {
          provCode = province.code;
          break;
        }
      }
      provinceVariable.dummyValue = this.gisService.getProvinceName(provinceVariable.value);

      if (provCode && cityId) {
        this.gisService.retrieveCities(provCode).subscribe(cities => {
          this.cities = cities;
          this.gisService.setCities(cities);
          cityVariable.valueDescription = this.gisService.getCityName(cityId);
        });
      }
    }
  }
}

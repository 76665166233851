import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormatterUtils} from '../../../invest-standalone-session/utils/FormatterUtils';

@Component({
  selector: 'lic-funds-message',
  templateUrl: './lic-funds-message.component.html',
  styleUrls: ['./lic-funds-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LicFundsMessageComponent implements OnInit {

  @Input() hide: boolean;
  @Input() leftPercent = 0;
  @Input() leftAmount = 0;
  constructor(public formatter: FormatterUtils) { }

  ngOnInit() {
  }

  isLowerThanRequired(): boolean {
    return this.leftAmount <= 0;
  }

  isOk(): boolean {
    return this.leftAmount !== 0;
  }

  isHigherThenRequired(): boolean {
    return this.leftAmount >= 0;
  }

  getLeftAmount(): string {
    return Math.abs(this.leftAmount).toFixed(2);
  }

  getLeftPercentage(): string {
    return Math.abs(this.leftPercent).toFixed(3);
  }
}


import {Inject, Injectable, Optional} from '@angular/core';
import {
  RGI_RX_STATE_CONFIG,
  RGI_RX_STATE_DEFAULT_CONFIG,
  RgiRxStateConfig,
  RgiRxStateStoreHandler,
  State
} from './state-manager-api';
import {Logger, LoggerFactory} from '@rgi/rx';
import {cloneDeep} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class StateStoreService implements RgiRxStateStoreHandler<State> {

  private readonly logger: Logger = LoggerFactory();
  private readonly states = new Map<string, any>();
  private readonly config: RgiRxStateConfig;

  constructor(@Optional() @Inject(RGI_RX_STATE_CONFIG) config?: RgiRxStateConfig) {
    this.config = config ? config : RGI_RX_STATE_DEFAULT_CONFIG;
    this.logger.debug('StateStoreService::init', this.config);
  }

  get<T extends State>(key: string): T {
    return this.states.get(key);
  }

  destroy(key: string) {
    if (this.states.has(key)) {
      this.states.delete(key);
      this.logger.debug(`StateStoreService::destroy destroyed ${key}`);
    }
  }

  destroyAllMatching(id: string) {
    this.states.forEach((value, key) => {
      if (key.includes(id)) {
        this.destroy(key as string);
      }
    });
  }

  destroyAll() {
    this.states.clear();
  }

  list<T extends State>(): IterableIterator<[string, T]> {
    return this.states.entries();
  }

  getAll<T extends State>(): Map<string, T> {
    return this.states;
  }

  set<T extends State>(key, data: T) {
    if (this.config.deepClone) {
      this.states.set(key, cloneDeep({...data, $$id: key}));
    } else {
      this.states.set(key, {...data, $$id: key});
    }
  }


  has(key: string): boolean {
    return this.states.has(key);
  }

  put<T extends State>(state: T) {
    const store = this.get(state.$$id);
    if (!store) {
      throw new Error('Cannot perform put for objects that does not exist already');
    }
    if (this.config.deepClone) {
      this.set(state.$$id, cloneDeep(state));
    } else {
      this.set(state.$$id, state);
    }
  }
}

import {ConfMetadata} from './conf-metadata';
import {GenericEntity} from "./generic-entity";


export class Metadata{

  public id: string;
  public code: string;
  public description: string;
  public value: string;
  public valueDescription: string;
  public listValue: GenericEntity[];
  public visible: boolean;
  public usedForValidation: boolean;
  public mandatory: boolean;
  public multiple: GenericEntity;
  public complianceStatus: GenericEntity;
  public type: GenericEntity;
  public usedAsIndex: boolean;
  public modifiable: boolean;
  public systemMetadato: boolean;
  public conf: ConfMetadata;

  constructor( ) {

  }


}

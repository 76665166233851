import {ToolConfDefinition} from './tool-conf-definition';
import {KarmaProfileDefinition} from '../../lpc-karma-funds/model/karma-profile-definition';
import { ToolCodeIdType, ToolCodeType } from '../../../models/enums/vita.enum';

export interface ToolDefinition {
  id?: string;
  toolName: string;
  operationCodeId: ToolCodeIdType;
  operationCode?: ToolCodeType;
  factors?: ToolConfDefinition[];
  investmentProfiles?: KarmaProfileDefinition[];
  disinvestmentProfiles?: KarmaProfileDefinition[];
  preChecked?: boolean;
  usePolicyProfiles?: boolean;
  selectionState?: ToolStateStatus;
}

export enum ToolStateStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  MANDATORY = 'MANDATORY'
}

import {Component, Input} from '@angular/core';
import { ContractDetails } from '../../../models/details/contract-details';

@Component({
  selector: 'lib-consultation-warranties',
  templateUrl: './consultation-warranties.component.html',
  styleUrls: ['./consultation-warranties.component.css']
})
export class ConsultationWarrantiesComponent {
  @Input() contract:ContractDetails;
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Results} from '../model/results';
import {AdapterprintHomeNodeModel} from '../model/adapterprint-home-node-model';
import {map, share} from 'rxjs/operators';
import {DatePipe} from '@angular/common';
import {OperatorService} from '@rgi/portal-ng-core';

@Injectable({
  providedIn: 'root'
})
export class OutcomeresultsService {

  private ADAPTERPRINT_URL: string;
  private API_URL_REST_NATIVE: string;
  private API_URL_LEGACY: string;
  private LOGIN_NODE: any;

  constructor(private http: HttpClient, @Inject('environment') environmentPortal: any, operatorService: OperatorService,
              private datePipe: DatePipe) {
    this.LOGIN_NODE = operatorService.getSalePointLogin().objectId;
    this.API_URL_LEGACY = environmentPortal.api.portal.host + environmentPortal.api.portal.path;
    this.API_URL_REST_NATIVE = this.API_URL_LEGACY + '/v2/';
    this.ADAPTERPRINT_URL = 'adapterprint/prints/mailingoutcomes';
    this.ADAPTERPRINT_URL = this.API_URL_REST_NATIVE + this.ADAPTERPRINT_URL;
  }

  getOutcomeResults(node: any, dateFrom: string, dateTo: string, state: string, policyNumber: string, addressed: string): Observable<Results> {

    // REQUEST FILTER
    let params = new HttpParams()
    if (policyNumber) {
      params = params.set('policyNumber', policyNumber)
    }
    if (node) {
      params = params.set('nodeManagement', node)
    }
    if (dateFrom) {
      dateFrom = this.datePipe.transform(new Date(dateFrom), 'dd-MM-yyyy')
      params = params.set('dateFrom', dateFrom)
    }
    if (dateTo) {
      dateTo = this.datePipe.transform(new Date(dateTo), 'dd-MM-yyyy')
      params = params.set('dateTo', dateTo)
    }
    if (state) {
      params = params.set('state', state)
    }
    if (addressed) {
      params = params.set('addressed', addressed)
    }

    // CALL
    return this.http.get<Results>(this.ADAPTERPRINT_URL, {params: params});
  }

  getNode(node: string): Observable<Array<AdapterprintHomeNodeModel>> {
    return this.http.get<any>(this.getNodeUri(node)).pipe(share(), map(val => {
      const nodeList: Array<AdapterprintHomeNodeModel> = [];
      val.agencies.forEach((element => {
        nodeList.push(element);
      }));
      return nodeList;
    }));
  }

  getNodeUri(node: string) {
    return this.API_URL_LEGACY + '/admin/agencies?loginNodeId=' + node;
  }


}


import {Injectable} from '@angular/core';
import {ReIssueProposalPrintResourceService} from '../re-issue-resources/re-issue-proposal-print-resource.service';
import {Observable} from 'rxjs';
import {PrintDocumentsRequest} from '../../models/api-models/print-documents/print-documents-request';
import {DocumentsResponse} from '../../models/api-models/print-documents/print-documents-response';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReIssueCardPolicyDocumentsBusinessService {

  constructor(
    private printResourceService: ReIssueProposalPrintResourceService
  ) {
  }


  printEmissionDocuments(
    policyNumber: string,
    proposalNumber: string,
    operationCode: string,
    reprint?: boolean,
    movementId?: number
  ): Observable<DocumentsResponse> {
    const request = new PrintDocumentsRequest();

    request.policyNumber = policyNumber;
    request.proposalNumber = proposalNumber;
    request.operationCode = operationCode;
    request.reprint = reprint;
    request.movementId = movementId;

    return this.printResourceService.printDocuments(request).pipe(map(
      resp => {
        return resp.documentsResponse;
      }
    ));
  }
}

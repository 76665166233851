import { Component, Inject, OnInit } from '@angular/core';
import { CardComponent, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from '../external-portfolio/utility.service';

@Component({
  selector: 'claims-assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.scss']
})
export class AssistantComponent extends CardComponent implements OnInit {
  public searchForm: UntypedFormGroup;
  public criteriaOn: boolean;
  public waitingOn: boolean;
  public actionOn: boolean;
  public answwerOn: boolean;
  public charge: boolean;
  public criteria: string;
  public themes: string[][];
  public details: string[][];
  public rootImg = 'assets/img/Assistant/';
  public wait1Img = this.rootImg + 'wait1.png';
  public wait2Img = this.rootImg + 'wait2.png';
  public answer1Img = this.rootImg + 'answ1.png';
  public answer2Img = this.rootImg + 'answ2.png';
  public img: string;
  public classImg = 'col-xs-12';
  public classDlg = 'col-xs-8';
  public count = 0;
  public faqList: any[];
  public faqSel: any;
  public srcCurrent: string;
  public faqDb = [
  ];

  public faqSrchDB = [
    {idFaq: 1, srchStr: 'processiautosoft'},
    {idFaq: 2, srchStr: 'processoautosoftrestituzioneperizia'},
    {idFaq: 3, srchStr: 'processoautosoftvalidazioneparcella'},
    {idFaq: 4, srchStr: 'processoautosoftvalidazioneparcella'},
    {idFaq: 5, srchStr: 'processoautosoftnuovodocumento'},
    {idFaq: 6, srchStr: 'autosoftfatturazione'},
    {idFaq: 7, srchStr: 'taskrerdenicjavalangnumberformatexceptionnull'},
    {idFaq: 8, srchStr: 'nonstartanoiprocessilegatiadautosoft'},
    {idFaq: 9, srchStr: 'jsknontrovanoclassideibatchautosoft'},
    {idFaq: 10, srchStr: 'processoscadenzascambiodocumentale'},
    {idFaq: 11, srchStr: 'listinofunzionipsnsgdgestionedeicambistato'},
    {idFaq: 12, srchStr: 'cardfunzionestatic1richiestacai2ne'},
    {idFaq: 13, srchStr: 'listinofunzionisxaudbautosoftdashboard'},
    {idFaq: 14, srchStr: 'listinofunzionisxfnol'},
    {idFaq: 15, srchStr: 'listinofunzionisxassalvasinistro'},
    {idFaq: 16, srchStr: 'listinofunzionisxapsc'},
    {idFaq: 17, srchStr: 'listinofunzionisxscamm'},
    {idFaq: 18, srchStr: 'listinofunzionisxcte'},
    {idFaq: 19, srchStr: 'listinofunzionisxdace'},
    {idFaq: 20, srchStr: 'listinofunzionisxedlm'},
    {idFaq: 21, srchStr: 'listinofunzionisxemce'},
    {idFaq: 22, srchStr: 'listinofunzionisxfnol'},
    {idFaq: 23, srchStr: 'listinofunzionisxinlm'},
    {idFaq: 24, srchStr: 'listinofunzionixipnv'},
    {idFaq: 25, srchStr: 'listinofunzionisxlqui'},
    {idFaq: 26, srchStr: 'slistinofunzionixnliq'},
    {idFaq: 43, srchStr: 'amtrustaggiornapolicid'},
    {idFaq: 44, srchStr: 'funzioneassegnaincarichiversonetworkperitalecomeidentificarechifaperizia'},
    {idFaq: 45, srchStr: 'funzioneassegnaincarichiversonetworkperitalecomeidentificarechifaperizia'},


  ];

  constructor(
    @Inject('sessionService') private session: any,
    cardComponentService: CardComponentService,
    @Inject('authService') private authService,
    @Inject('eventService') private eventService: any,
    sessionService: SessionService,
    public formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private utilytyServ: UtilityService,
    ) {
      super(cardComponentService, sessionService);
     }

  ngOnInit() {
    super.ngOnInit();
    this.waitingOn = true;
    this.criteriaOn = false;
    this.answwerOn = false;
    this.img = this.wait2Img;
    this.count = 0;
    this.searchForm = this.formBuilder.group({
      criteria: [],
      tags: [],
    });

    const arch = this.utilytyServ.getArchiveAssistant();

    if (arch) {
      this.srcCurrent = arch[0];
      this.searchForm.controls.criteria.setValue(this.srcCurrent);
      this.faqSel = arch[1];
      this.faqList = arch[2];

      this.actionOn = true;

      if (this.faqSel) {
        this.answwerOn = true;
      }

      this.action();
    }
  }

  action() {
    if (this.waitingOn) {
      // si attiva immagine
      this.waitingOn = false;
      this.actionOn = true;
      this.img = this.answer1Img;
      this.classImg = 'col-xs-2';
    }  else if (!this.actionOn) {
      // si disattiva immagine
      this.waitingOn = true;
      this.actionOn = false;
      const v = Math.random() ;
      // console.log(v);
      if (v < 0.5) {
        this.img = this.wait1Img;
      } else {
        this.img = this.wait2Img;
      }
      this.classImg = 'col-xs-12';
    }
  }

  search() {
      const excl = ' e && il && lo && la && gli && le && delle && l\' && del && di && da && do && de && della && dello ' +
      '&& degli && in && su && per && tra && fra && un && uno && una ' +
      '&& un\' && se && ma && invece && perché && perché' +
      '&& via && è && è && ho && hai && abbiamo && sono && sei && siamo && siete && hanno && ebbi && fui && fu ' +
      '&& mi && fa && si && mia && mio && tuo && nostro && nostra ' +
      '&& vostro && vostra && tua && sua && io && tu && voi && essi && esso && ella && essa &&=&&+&&;&&–&&-&&_&&<&&>';
      const exclSpl = excl.split('&&');
      this.count = 0;
      this.faqList = [];
      this.answwerOn = false;
      this.faqSel = null;
      this.charge = false;
      // il sistema inizia a cercare ed elenca le faq trovate in base alla stringa digitata dall'utente
      this.count = 0;
      this.srcCurrent = ' ' + this.searchForm.controls.criteria.value; // stringa digitata dall'utente
      if (this.srcCurrent && this.srcCurrent.length > 3) {
        this.srcCurrent = this.srcCurrent.toLowerCase();
        exclSpl.forEach( ex => {
          this.srcCurrent = this.srcCurrent.split(ex).join('');
        });

        this.srcCurrent = this.srcCurrent.split(' ').join('');

        if (this.srcCurrent.length > 2) {
          const faqs = this.faqSrchDB.filter(str =>
                str.srchStr.indexOf(this.srcCurrent) >= 0);

          faqs.forEach(id => {
            this.faqList.push(this.faqDb.find(el => el.idFaq === id.idFaq));
          });
        }
      }

  }

  select(idFaq) {
    // l'utente ha selezionato una faq --> entra nel dettaglio
    this.img = this.answer2Img;
    this.count = 0;
    this.faqSel = this.faqList.find(el => el.idFaq === idFaq );
    this.answwerOn = true;

  }

  usefull() {
    const strTags = this.searchForm.controls.tags.value;

    if (strTags) {
      const list = strTags.split('#');
      if (list.length > 0) {
        list.forEach(el => {
          if (!this.faqSrchDB.find(fq => fq.srchStr === el)) {
            const temp = {idFaq:  this.faqSel.idFaq, srchStr:  el};
            this.faqSrchDB.push(temp);
          }
        });
      }
    }
    this.searchForm.controls.tags.setValue('');
    this.answwerOn = false;
  }

  close() {
    this.count = 0;
    this.actionOn = false;
    this.faqSel = null;
    this.faqList = null;
    this.srcCurrent = '';
    this.answwerOn = false;
    this.searchForm.controls.criteria.setValue('');
    this.searchForm.controls.tags.setValue('');
    this.utilytyServ.cleanArchAssistant();
    this.action();
  }

  zzz() {
    this.count++;
    if (this.count % 10 === 0) {
      console.log(this.count);
    }
    if (this.count === 200) {
      this.count = 0;
      this.close();
    }
    return '';
  }

  openWin(link) {
    this.utilytyServ.archiveAssistant(this.faqList, this.faqSel, this.srcCurrent);
    const IRheight = 500;
    const IRwidth = 700;
    const IRscreenWidth = screen.width;
    const IRscreenHeight = screen.height;
    const IRTop = (IRscreenHeight - IRheight) / 2;
    const IRLeft = (IRscreenWidth - IRwidth) / 2;
    const IRconstModOpen = 'modal=yes, toolbar=no, location=no, directories=no, status=no, ' +
    'scrollbars=yes, resizable=no, width=' +
    IRwidth + ', height=' + IRheight + ', screenY=' + IRTop + ', screenX=' + IRLeft;
    const IRwinChild = window.open(link, 'ModalWindowPianoDescrescenza', IRconstModOpen);
    // if (IR_winChild != null) {
    // 	IR_winChild.focus();
    // }
  }
}

import {AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CardComponent, CardComponentService, SessionService} from '@rgi/digital-claims-common-angular';
import {RoutingHostDirective, RoutingService} from '@rgi/portal-ng-core';
import {CompensationBoardComponent} from './compensation-board/compensation-board.component';
import {CompensationEntity} from '../new-payment/domain/compensation-entity';
import {CompensationUtilityService} from './compensation-utility.service';

@Component({
  selector: 'claims-compensation-list',
  templateUrl: './compensation-list.component.html',
  styleUrls: ['./compensation-list.component.scss']
})
export class CompensationListComponent extends CardComponent implements OnInit, AfterViewInit {
  @ViewChild(RoutingHostDirective, {static: true}) hostViewRef: RoutingHostDirective;
  routes = null;
  @Input() objects: CompensationEntity[];
  @Input() claim: any;
  @Input() party: any;
  @Input() card: any;
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private routingService: RoutingService,
    private activeModal: NgbActiveModal,
    private utilityService: CompensationUtilityService,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    @Inject('eventService') private eventService: any,
    @Inject('authService') private auth: any,
    @Inject('compensationBoardComponent') compensationBoardCompoent,
    @Inject('compensationDiaryComponent') compensationDiaryComponent,
    @Inject('containerCacheService') private cache,
  ) {
    super(cardComponentService, sessionService);
    this.routes = {
      board: compensationBoardCompoent,
      diary: compensationDiaryComponent
    };
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {

    this.routingService.routes = this.routes;
    this.routingService.host = this.hostViewRef.viewContainerRef;

    this.utilityService.setCompensations(this.objects);
    this.utilityService.setParty(this.party);
    this.utilityService.setModal(this.activeModal);
    this.utilityService.setClaim(this.claim);
    this.utilityService.setCard(this.card);
    this.utilityService.setUser(this.auth.getUsername());
    this.utilityService.setEventEmitter(this.eventPropagation);


    this.routingService.loadComponent(this.routes.board);
    (this.routingService.componentRef.instance as CompensationBoardComponent).card = this.card;

  }


}

import {TableSchema} from '@rgi/rx/ui';

export const CLUSTER_LIST_DETAIL_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'clusterNumber',
      title: '_GP_._LABEL_._CLUSTER_NUMBER_'
    },
    {
      name: 'clusterName',
      title: '_GP_._LABEL_._CLUSTER_NAME_'
    },
    {
      name: 'clusterOwner',
      title: '_GP_._LABEL_._CLUSTER_OWNER_'
    },
    {
      name: 'clusterStatusDescription',
      title: '_GP_._SUMMARY_._STATUS_',
    }
  ],
  header: ['clusterNumber', 'clusterName', 'clusterOwner', 'clusterStatusDescription']
};
export const CLUSTER_LIST_TABLE_SCHEMA: TableSchema = {
  rows: [
    ...CLUSTER_LIST_DETAIL_TABLE_SCHEMA.rows,
    {
      name: 'action',
      title: '_GP_._LABEL_._ACTIONS_',
      actions: [
        {
          name: 'EDIT',
          styleClass: ' rgi-ui-icon-edit'
        },
        {
          name: 'COPY',
          styleClass: 'rgi-ui-icon-duplicate'
        },
        {
          name: 'DELETE',
          styleClass: 'rgi-ui-icon-delete2'
        }
      ]
    }
  ],
  header: [...CLUSTER_LIST_DETAIL_TABLE_SCHEMA.header, 'action']
};

export const CLUSTER_LIST_APPINCLUSION_TABLE_SCHEMA: TableSchema = {
  rows: [
    {
      name: 'clusterSelection',
      title: '_GP_._LABEL_._SELECT_'
    },
    {
      name: 'clusterName',
      title: '_GP_._LABEL_._CLUSTER_NAME_'
    },
    {
      name: 'clusterOwner',
      title: '_GP_._LABEL_._CLUSTER_OWNER_'
    },
    {
      name: 'extDescr',
      title: '_GP_._LABEL_._DESCRIPTION_',
    }
  ],
  header: ['clusterSelection', 'clusterName', 'clusterOwner', 'extDescr']
};

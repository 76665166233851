import { UntypedFormGroup } from "@angular/forms";
import { RequestFactor } from "../../../models/factor.model";
import { FactorDefinition, RiskRequest } from "../../../models/postsales-operations-response.model";
import { PlcDateUtils } from "../../../utils/plc-date-utils";
import { FiscalPeriod } from "./pension-buyback.model";
import { KEY_TO_PERIOD_MAPPING } from "./pension-buyback.const";
import { SumDecimalsUtils } from "../../../utils/sum-decimal-utils";
import { LpcBeneficiaryUtils } from "../../../modules/lpc-beneficiary/util/lpc-beneficiary-utils";

export class PensionBuyBackUtils {
  public static convertRiskFactors(
    form: UntypedFormGroup,
    factorDefinitionList: any
  ): { codeRisk: string; factors: RequestFactor[] }[] {
    const mapTypeCode = new Map([
      ['0', 'list'],
      ['1', 'number'],
      ['2', 'list'],
      ['3', 'boolean'],
      ['4', 'date'],
      ['5', 'string']
    ]);
    
    const convertedRisks: { codeRisk: string; factors: RequestFactor[] }[] = [];
  
    if (form != null && form.controls != null) {
      Object.keys(form.controls).forEach(codeRisk => {
        const riskGroup = form.get(codeRisk) as UntypedFormGroup;
        if (riskGroup && riskGroup.controls) {
          const riskFactors: RequestFactor[] = Object.keys(riskGroup.controls).map(factorCode => ({
            code: factorCode,
            label: null,
            value: riskGroup.controls[factorCode].value,
            userInput: false,
            typeCode: null
          }));
          
          riskFactors.forEach(factor => {
            const definitionFactor = factorDefinitionList.find(f => f.code === factor.code);
            const actualValue = riskGroup.get(factor.code)?.value;
            
            if (definitionFactor != null) {
              if (mapTypeCode.get(definitionFactor.typeCode) === 'date') {
                factor.value = PlcDateUtils.localDateToString(PlcDateUtils.dateToLocaleDate(actualValue));
              } else {
                factor.value = actualValue;
              }
              if (definitionFactor.defaultValue !== factor.value) {
                factor.userInput = true;
              }
              factor.label = definitionFactor.label;
              factor.typeCode = definitionFactor.typeCode;
            } else {
              console.error('Fattore ' + factor.code + ' non trovato nelle definitions');
            }
          });
          
          convertedRisks.push({
            codeRisk: codeRisk,
            factors: riskFactors
          });
        }
      });
    }
  
    return convertedRisks;
  }
  
  public static convertFormFactors(
    form: UntypedFormGroup,
    factorDefinitionList: FactorDefinition[]
  ): RequestFactor[] {
    const mapTypeCode = new Map([
      ['0', 'list'],
      ['1', 'number'],
      ['2', 'list'],
      ['3', 'boolean'],
      ['4', 'date'],
      ['5', 'string']
    ]);
    
    const converted: RequestFactor[] = [];
    if (form != null && form.controls != null) {
      Object.keys(form.controls).forEach(key => {
        converted.push({
          code: key,
          label: null,
          value: null,
          userInput: false,
          typeCode: null
        });
      });
    }
  
    converted.map(factor => {
      const definitionFactor: FactorDefinition = factorDefinitionList.find(f => f.code === factor.code);
      const actualValue = form.get(factor.code)?.value;
      if (definitionFactor != null) {
        if (mapTypeCode.get(definitionFactor.typeCode) === 'date') {
          factor.value = PlcDateUtils.localDateToString(PlcDateUtils.dateToLocaleDate(actualValue));
        } else {
          factor.value = actualValue;
        }
        if (definitionFactor.defaultValue !== factor.value) {
          factor.userInput = true;
        }
        factor.label = definitionFactor.label;
        factor.typeCode = definitionFactor.typeCode;
      } else {
        console.error('Fattore ' + factor.code + ' non trovato nelle definitions');
      }
    });
  
    return converted;
  }


      static convertToPayoutObject(input: Record<string, number | null | undefined>, defaultPayout: FiscalPeriod[], maxPayout: number): FiscalPeriod[] {
        if (!input || typeof input !== 'object') return [];
    
        if (defaultPayout) {
          return defaultPayout;
        }
    
        let payouts = [];
        const fiscalPeriods: FiscalPeriod[] = Object.entries(input).map(([key, value]) => {
          const payoutValue = (value === null || value === undefined ? 0 : value).toString();
          payouts.push(Number(payoutValue.replace(/,/g, '.')));
          return {
            period: KEY_TO_PERIOD_MAPPING[key] || 'UNKNOWN_PERIOD',
            payout: payoutValue.replace(/,/g, '.') // removing any char and spaces, saves dot and commas
          };
        });
    
        if (maxPayout == SumDecimalsUtils.sumDecimalsArray(payouts, 100)) {
          return fiscalPeriods;
        }
    
        return [];
      }
    
      static createOrGetRiskGroup(requestRiskFactor: RiskRequest[], codeRisk: string): RiskRequest {
        let riskGroup = requestRiskFactor.find(group => group.codeRisk === codeRisk);
        if (!riskGroup) {
          riskGroup = { codeRisk, factors: [] };
          requestRiskFactor.push(riskGroup);
        }
        return riskGroup;
      }
    
      static updateRiskFactors(requestRiskFactor: RiskRequest[], factors: any[], codeRisk: string, refreshCallback: () => void) {
        let changesMade = false;
    
        let riskGroup = this.createOrGetRiskGroup(requestRiskFactor, codeRisk);
    
        factors.forEach(factor => {
          if (factor.value === null) return;
          const existingFactorIndex = riskGroup.factors.findIndex(el => el.code === factor.code);
          if (existingFactorIndex !== -1) {
            if (JSON.stringify(riskGroup.factors[existingFactorIndex]) !== JSON.stringify(factor)) {
              riskGroup.factors.splice(existingFactorIndex, 1, factor);
              changesMade = true;
            }
          } else {
            riskGroup.factors.push(factor);
            changesMade = true;
          }
        });
    
        if (changesMade) {
          refreshCallback();
        }
      }
    
      static getClaimBeneficiaries(beneficiaries: any): any[] {
        if (beneficiaries) {
          return (beneficiaries as any[]).map(beneficiary => {
            return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
          });
        }
        return [];
      }
}

import {GroupPolicyTranslationsKeys} from './gp-translations-keys';

/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */

export const GROUP_POLICY_EN: GroupPolicyTranslationsKeys = {
  _GP_: {
    _BTN_: {
      _ADD_ASSET_: 'Add asset',
      _ADD_PAYMENT_: 'Add payment method',
      _BACK_: 'Back',
      _CLOSE_: 'Close',
      _COMMISSIONS_: 'Commissions',
      _COMMISSIONS_DISCOUNT_: 'Commissions / Flex',
      _CONFIGURE_: 'Configure',
      _CONTINUE_: 'Continue',
      _EMPTY_: 'Empty',
      _LEAVE_: 'Leave',
      _NEW_: 'New',
      _SAVE_: 'Save',
      _ORDINARY_ADDRESS_: 'Ordinary Address',
      _EMAIL_: 'Email',
      _HOME_INSURANCE_: 'Home Insurance',
      _INCLUDE_APPLICATION_: 'Include Application',
      _UPLOAD_FILE_: 'Upload File',
      _ADD_NEW_INCLUSION_: 'New inclusion',
      _UNDO_: 'Undo',
      _CONFIRM_: 'Confirm',
      _COINSURANCE_SHARES_: 'Co-insurance Shares',
      _COINSURANCE_DELEGATED_: 'Delegated',
      _DEBIT_: 'Debit',
      _CREDIT_: 'Credit',
      _YES_: 'YES',
      _NO_: 'NO',
      _CREATE_CLUSTER_: 'Create Cluster',
      _SAVE_CLUSTER_: 'Save Cluster',
      _ASSIGN_: 'Assign',
      _GOTO_INQUIRY_: 'Consult MP',
    },
    _COMMON_: {
      _MASTER_POLICY_: 'Master policy',
      _PROPOSAL_: 'Proposal'
    },
    _LABEL_: {
      _ACCOUNTHOLDERS_: 'Account Holders',
      _AMENDMENT_EFF_DATE_: 'Amendment effective date',
      _COMMISSIONS_: 'Commissions',
      _COMPANY_: 'Company',
      _CONVENTION_: 'Convention',
      _CONTACT_DETAILS_: 'Contact Details',
      _CREDIT_: 'Credit',
      _CREDIT_PAYMENT_: 'Credit payment',
      _DEBIT_: 'Debit',
      _DEBIT_PAYMENT_: 'Debit payment',
      _EFFECTIVE_DATE_: 'Effective Date',
      _MOVEMENT_EFFECTIVE_DATE_: 'Movement Effective Date',
      _INDEXING_: 'Indexing Type',
      _ISSUE_DATE_: 'Issue Date',
      _NODE_: 'Node',
      _NOT_EDITABLE_: 'Not Editable',
      _OTHER_DATA_: 'Other Data ...',
      _PAYMENT_FREQUENCY_: 'Payment Frequency',
      _POLICY_HOLDER_: 'Policy Holder',
      _PRODUCT_: 'Product',
      _PROPOSAL_NUMBER_: 'Proposal Number',
      _POLICY_NUMBER_: 'Policy Number',
      _REQUIRED_: 'required',
      _PHONE_NUMBER_: 'Phone Number',
      _DATIAP_: 'Application Data',
      _REGOPM_: 'Adjustment',
      _TECHNICAL_ACC_TRANSACTIONS_: 'Technical Accounting Transactions',
      _BATCHP_: 'Batch execution',
      _TARIPM_: 'Rate Management',
      _NO_ACTION_FOUND_: 'No action available',
      _ACC_TRANS_AMOUNT_: 'Accounting transaction amount',
      _ACC_TRANS_STATE_: 'Accounting transaction state',
      _DESCRIPTION_: 'Description',
      _APPLICATION_DOCUMENT_: 'Application Documents',
      _CLICK_CONFIRM_ADD_INCLUSION_: 'click confirm to include a new application​',
      _FILE_UPLOAD_OK_: 'File uploaded successfully',
      _SHARE_PERC_: '% Share',
      _COMMISSIONS_PERC_: '% Commissions',
      _INTERMEDIARY_: 'Intermediary',
      _OUR_PERC_: 'Our percentage',
      _TOTAL_PERC_: 'Total percentage',
      _TOTAL_PERC_ERROR_: 'The total percentage is over 100%',
      _TOTAL_PERC_COMMISSIONS_ERROR_: 'The total commissions percentage is over 100%',
      _ACTIONS_: 'Actions',
      _PAYMENT_TYPE_: 'Payment type',
      _DEBIT_PAYMENT_METHOD_: 'Debit payment mean',
      _CREDIT_PAYMENT_METHOD_: 'Credit payment mean',
      _START_DATE_: 'Data Inizio',
      _END_DATE_: 'Data Fine',
      _FREE_TEXT_: 'Free Text',
      _HIDE_ON_APPLICATION_: 'Hide on the application',
      _SUBCAUSES_: 'Reason variation',
      _SELECT_: 'Select',
      _CLUSTER_NAME_: 'Name',
      _CLUSTER_OWNER_: 'Owner',
      _CLUSTER_NUMBER_: 'Cluster Nr.',
      _ASSET_CLASS_: 'Vehicle class',
      _ASSET_USE_: 'Vehicle use',
      _ASSET_SECTOR_: 'Vehicle Sector',
      _ASSET_EXTENSION_: 'Vehicle Extension',
      _REQUEST_: 'Request',
      _NOTIFICATIONS_: 'Notifications',
      _APPLICANT_: 'Applicant',
      _REQUEST_DATE_: 'Request Date',
      _AUTHORIZATION_REQUEST_INSERT_: 'Authorization Request in insert phase',
      _DEROGATION_LEVEL_: 'Derogation Level',
      _REQUEST_STATE_: 'Request State',
      _LAST_ACTION_: 'Last Action',
      _COMPETENCE_LEVEL_: 'Competence Level',
      _LAST_ACTION_DATE_: 'Last Action Date',
      _MANUAL_NOTE_: 'Manual Notes',
      _PRODUCT_TYPE_: 'Product Type',
      _PROPOSAL_DESCRIPTION_: 'Proposal Description',
      _PRIORITY_: 'Priority',
      _PROPOSAL_AUTHORIZATION_INFO_: 'Proposal on which the authorization has been requested',
      _USER_APPLICANT_: 'Reference User',
      _NOTE_: 'Note',
      _BLOCK_LEVEL_: 'Block',
      _ERROR_: 'Error',
      _TYPE_: 'Type',
      _ORIGIN_ENTITY_: 'Origin Entity',
      _REQUEST_AUTHORIZATION_DONE_: 'Policy number {{proposalNumber}} was saved Authorization request forwarding to qualified user',
      _BACK_GUARANTEES_CLUSTER_ERROR_: 'Attention before continuing it is necessary to save the cluster or delete it',
      _VOID_: ' ',
      _ALIGN_APPLICATION_IN_PROGRESS_: 'Aligning applications in progress',
      _USER_: 'User',
      _BATCH_EXECUTION_: 'Batch Execution',
      _REGULATION_: 'Regulation',
      _TARIFF_MANAGEMENT_: 'Tariff Management',
      _TECH_ACC_: 'Technical Accounting',
      _BALANCE_ON_TODAY_: 'Balance on today\'s date',
      _GENERATE_TECH_ACC_: 'Generate MP Techical Accounting',
      _RENUMBER_APPS_: 'Renumber Applications',
      _SUBST_TYPE_FULL_: 'Full',
      _SUBST_TYPE_INCREMENTAL_: 'Incremental',
      _SUBSTITUTION_TYPE_: 'Substitution Type',
      _SPECIAL_PLATE_: 'Alternative plates',
      _SUBSTITUITED_: 'Substituted',
      _SUBSTITUENT_: 'Sobstituent',
      _GENERIC_VARIATION_: 'Generic Variation',
      _RENEWAL_PROPOSAL_: 'Renewal proposal',
      _TO_RENEW_: 'Contract to be renewed',
      _FIXED_TEXT_: 'Fixed Text'
    },
    _MODAL_: {
      _OPERATION_: 'Operation executed correctly',
      _SAVED_: 'saved correctly',
      _SAVED_NO_APP_UPDATE_: 'saved correctly. The MP linked applications will not be updated automatically.',
    },
    _PLACEHOLDER_: {
      _CLICK_HERE_: 'Click Here To Add'
    },
    _TITLE_: {
      _APPLICATION_CONFIGURATION_: 'Application Configuration',
      _MP_AMENDMENT_: 'Master Policy Amendment',
      _BALANCE_SUMMARY_: 'Balance Summary',
      _SUBSTITUTED_POLICIES_LIST: 'Substitution / Variation',
      _CONTRACTDETAIL_: 'Contract Detail n° ',
      _CLAUSES_: 'Clauses',
      _GUARANTEES_: 'Guarantees',
      _MEANS_PAYMENT_: 'Means Of Payment',
      _POLICY_GENERAL_DATA_: 'Policy General Data',
      _SUBJECTS_: 'Subjects',
      _QUESTIONNAIRES_: 'Questionnaires',
      _DOCUMENTS_: 'Documents',
      _APPLICATIONS_LIST_: 'Applications list',
      _ACTIONS_: 'Actions',
      _MOVEMENTS_: 'Movements',
      _DATA_: 'Data',
      _MOD_INCLUSION_: 'Inclusion Mode',
      _SELECT_UPLOAD_DOCUMENT_: 'select and upload document',
      _COINSURANCES_: 'Coinsurance',
      _COINSURANCE_SHARES_: 'Policy shares',
      _COINSURANCE_DELEGATED_: 'Policy Delegated',
      _EDIT_PAYMENT_: 'Edit payment mean',
      _NEW_PAYMENT_: 'New payment mean',
      _POLICY_DATA_: 'Policy Data',
      _MP_CONFIGURATION_: 'Configuration',
      _ASSET_GUARANTEES_: 'Asset Data',
      _SUMMARY_: 'Summary',
      _ISSUE_: 'Issue',
      _SEL_ASSETS_CAT_: 'Select asset categories',
      _INS_POL_NUM_: 'Insert policy number',
      _DATES_: 'Date variation',
      _VARIATION_: 'Variation',
      _QUESTIONNAIRE_: 'Questionnaire',
      _CONFIRMS_: 'Confirms',
      _EDIT_SELECTION_: 'Edit selection',
      _ASSET_LIST_: 'Assets List',
      _CLUSTER_LIST_: 'Clusters List',
      _CLUSTER_DATA_: 'Cluster Data',
      _VARIABLES_: 'Variables',
      _ASSET_GUARANTEES_DATA_: 'Assets and Guarantees Data',
      _AUTHORIZATION_REQUEST_: 'Authorization Request',
      _SELECTED_VALUES_: 'Selected values',
      _COMMISSION_PERCENTAGE_FIRST_YEAR_: '% Comm. 1st year',
      _COMMISSION_PERCENTAGE_NEXT_YEAR_: '% Comm. next year',
      _COMMISSION_DISCOUNT_: 'Discount',
      _COMMISSION_DISCOUNTS_: 'Discounts',
      _COMMISSION_RISK_: 'Risk',
      _SUBSTITUTION_DATA_: 'Substitution Data',
      _SYSTEM_MESSAGE_: 'System Message',
    },
    _SUMMARY_: {
      _MANAGEMENT_NODE_: 'Management Node',
      _STATUS_: 'Status',
      _PRODUCT_DESCRIPTION_: 'Product Description',
      _EFFECTIVE_DATE_: 'Effective Date',
      _EXPIRY_DATE_: 'Expiry Date',
      _PAYMENT_FREQUENCY_: 'Payment Frequency',
      _AGREEMENT_: 'Agreement',
      _DATE_OF_BIRTHDAY_: 'Date Of Birthday',
      _NAME_: 'Name',
      _SURNAME_: 'Surname',
      _COMPANY_NAME_: 'Company Name',
      _PERSON_TYPE_: 'Person Type',
      _ID_PARTY_LOCK_: 'Id Party Lock',
      _ID_PARTY_: 'Id Party',
      _ROLE_: 'Role',
      _DESCRIPTION_: 'Description',
      _MAXIMUM_INSTANCES_: 'Maximum Instances',
      _SHOW_PERCENTAGE_: 'Show Percentage',
      _MINIMUM_PERCENTAGE_: 'Minimum Percentage',
      _PERCENTAGE_: 'Percentage',
      _LINKED_ROLES_: 'Linked Roles',
      _INITIAL_STATE: 'Initial State',
      _APPLICATIONS_LIST_: 'Applications list'
    },
    _APPLICATIONS_: {
      _MANAGEMENT_NODE_: 'Management Node',
      _STATUS_: 'Status',
      _PRODUCT_DESCRIPTION_: 'Product Description',
      _EFFECTIVE_DATE_: 'Effective Date',
      _EXPIRY_DATE_: 'Expiry Date',
      _ISSUE_DATE_: 'Issue Date',
      _POLICY_DESCRIPTION_: 'Description',
      _POLICY_REFERENCE_: 'Proposal / Policy number',
      _OPEN_DETAIL_: 'Open Detail'
    },
    _QUOTATION_: {
      _GUARANTEE_: 'Guatantee',
      _PURE_PREMIUM_: 'Pure Premium',
      _LOADING: 'Loading',
      _OVER_PREMIUM_: 'Over Premium',
      _NET_: 'Net',
      _INIT_PAYMENT_FREQ_: 'Initial Payment Frequency',
      _TAX_: 'Tax',
      _RIGHT_: 'Right',
      _GROSS_: 'Gross',
      _TOTAL_: 'Total',
      _FIRST_INSTALLMENT_: 'First Installment',
      _NEXT_INSTALLMENT_: 'Next Installment',
      _ANNUAL_PREMIUM: 'Annual Premium',
      _DETAIL_: 'Quotation Detail',
      _GUARANTEE_DETAILS_: 'Guarantees Details',
      _VIEW_: 'View',
      _GROSS_TITLE_: 'Gross',
      _UNIT_INITIAL_STATUS_: 'Initial status',
      _SSN_: 'SSN',
    },
    _FLOW_: {
      _EDIT_PROPOSAL: 'Edit proposal n° ',
      _ISSUE_PROPOSAL_: 'Issue proposal n° ',
      _VCONT_: 'Contract Change '
    },
    _MSG_: {
      _GUARANTEES_NOT_EXCLUDED: 'One guarantees with state different to excluded must be selected',
      _MANDATORY_FIELD_: 'Mandatory field',
      _INVALID_FIELD_: 'Invalid field',
      _ISSUED_CORRECTLY_: 'issued correctly',
      _OUT_OF_RANGE_: 'Value out of range',
      _MAX_LESS_THAN_MIN_: 'Less than minimum',
      _MIN_GREATER_THAN_MAX_: 'Greater than maximum',
      _UNIT_SELECTION_WARNING_: 'Select at least one guarantee',
      _BALANCE_RECALCULATE_WARNING_: 'The balance summary might not be updated. ' +
        'Align application and recalculate the balance to see updated data',
      _BALANCE_SUMMARY_WARNING_: 'The balance is not available. ' +
        'Ensure to include at least one application and calculate the balance to see the data',
      _SUBST_INCREMENTAL_DISCLAIMER_: 'Warning! Applications will be added to existing ones.',
      _SUBST_FULL_DISCLAIMER_: 'Warning! Existing applications will be deleted',
      _BATCH_SCHEDULED_OK_: 'The system scheduled the batch process correctly.',
      _BATCH_SCHEDULED_KO_: 'The system encountered a problem scheduling the batch process.',
      _CONFIRM_CHECK_APPLICATION_: 'This contract has applications to be authorized, do you want to continue anyway with the issue?',
      _CONFIRM_CLUSTER_CANCELLATION_: 'Attention, all polices and proposals with future data effect will be cancelled.'
    }
  },
  _GP_TITLE_GROUP_POLICIES_: 'Master Policy Issue',
};

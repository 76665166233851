import {RgiRxLocalStateManager, State, StateStoreService} from '@rgi/rx/state';
import {ActiveRoute} from '@rgi/rx/router';
import {CardAnalyticsReducerService} from './card-analytics-reducer.service';
import {of, Subscription} from 'rxjs';
import {Report} from '../model/report';
import {ReportService} from '../services/report.service';

export class CardAnalyticsStateEditReport extends State {
  reports: Report[];
}


export class CardAnalyticsStateManagerEditReport extends RgiRxLocalStateManager<CardAnalyticsStateEditReport> {
  private reportSubjectSubscription: Subscription = Subscription.EMPTY;

  constructor(activeRoute: ActiveRoute, stateStoreService: StateStoreService, private reducer: CardAnalyticsReducerService,
              private reportService: ReportService) {
    super(activeRoute, stateStoreService);
    const st = !!stateStoreService.get<CardAnalyticsStateEditReport>(this.activeRoute.key) ?
      stateStoreService.get<CardAnalyticsStateEditReport>(this.activeRoute.key) :
      new CardAnalyticsStateEditReport(this.activeRoute.key);
    this.updateState$(of(st)); // this update the state
  }


  getReports(reports: Report[]) {
    const currentState = this.getCurrentState();
    this.updateState$(of(currentState));
  }

  actionOpen() {
    this.reducer.backHome(this.activeRoute);
  }

  backToReport(template) {
    this.reducer.backHome(this.activeRoute);
  }
}


<rgi-rx-expansion-panel #first [expanded]="true">
	<div rgi-rx-expansion-panel-header>
		<!-- <div rgi-rx-expansion-panel-label> -->


		<!-- <span class="rgifont rgi-user"></span>&nbsp;&nbsp; -->
		<!-- {{ party.surname }} {{ party.name }}&nbsp;&nbsp; -->
		<!-- <span class="party-role-value">{{ party.role | translate }}</span> -->
		<!-- <span class="technical-state-label">{{ '_CLAIMS_._TECHNICAL_STATE' | translate }}: </span> -->
		<!-- <span class="technical-state-value">{{ party.technicalState }}</span> -->

		<!-- <span class="rgifont rgi-credito party-icon" *ngIf="hasTolDamage"></span> -->
		<!-- <span class="rgifont rgi-man party-icon"*ngIf="hasTolLesions"></span> -->
		<!-- <span class="rgifont rgi-car_claims party-icon"*ngIf="hasTolVehicle"></span> -->
		<!-- <span class="rgifont rgi-libra party-icon" *ngIf="hasLitigation"></span> -->
		<!-- <span class="death" *ngIf="hasTolDeath">{{ '_CLAIMS_._DECEASE' | translate }}</span> -->

		<!-- ---- -->

		<!-- <div class="row">

				<div class="col-xs-6 col-md-6">
					<label class="cld-cell-padding">{{ party.surname }} {{ party.name }}</label>
				</div>
	
				<div class="col-xs-6 col-md-6">
					<label class="cld-cell-padding">{{ party.role }}</label>
				</div>
					
				<div class="col-xs-6 col-md-6">
					<label class="cld-cell-padding">{{ '_CLAIMS_._TECHNICAL_STATE' | translate }}:&nbsp;</label>
					<label class="cld-cell-padding">{{ party.technicalState }}</label>
				</div>

				<div class="col-xs-6 col-md-6">
					<span class="rgifont rgi-credito party-icon" *ngIf="hasTolDamage"></span>
					<span class="rgifont rgi-man party-icon"*ngIf="hasTolLesions"></span>
					<span class="rgifont rgi-car_claims party-icon"*ngIf="hasTolVehicle"></span>
					<span class="rgifont rgi-libra party-icon" *ngIf="hasLitigation"></span>
					<span class="death" *ngIf="hasTolDeath">{{ '_CLAIMS_._DECEASE' | translate }}</span>
				</div>
		
			</div> -->

		<!-- </div> -->

		<!-- ---- -->
		<span class="rgifont rgi-user"></span>&nbsp;&nbsp;
		{{ party.surname }} {{ party.name }}
		-
		{{ party.role }}
		-
		{{ '_CLAIMS_._TECHNICAL_STATE' | translate }}:&nbsp;
		{{ party.technicalState }}
		&nbsp;&nbsp;&nbsp;&nbsp;

		<span class="rgifont rgi-credito involved-party-icon" *ngIf="hasTolDamage"></span>
		<span class="rgifont rgi-man involved-party-icon" *ngIf="hasTolLesions"></span>
		<span class="rgifont rgi-car_claims involved-party-icon" *ngIf="hasTolVehicle"></span>
		<span class="rgifont rgi-libra involved-party-icon" *ngIf="hasLitigation"></span>
		<span class="death" *ngIf="hasTolDeath">{{ '_CLAIMS_._DECEASE' | translate }}</span>


	</div>

	<div *ngFor="let damageDescriptionsElement of party.damageDescriptions">

		<!-- <span class="causal-descr">{{damageDescriptionsElement.causative}}</span> -->
		<!-- <span class="causal-state-title"> -{{ '_CLAIMS_._TECHNICAL_STATE' | translate }}: </span> -->
		<!-- <span class="causal-state">{{damageDescriptionsElement.technicalStatus}}</span> -->

		<!-- TODO - translate -->
		<!-- <span class="causal-state" *ngIf="damageDescriptionsElement.deprecated" > <br/> Causale Deprecata</span> -->

		<!-- <span class="causal-state-title">{{ '_CLAIMS_._RESERVE_AMOUNT' | translate }}: </span> -->
		<!-- <span class="causal-state">{{damageDescriptionsElement.reserveTechAmount | currency:'EUR'}}</span> -->

		<!-- <span class="causal-state-title">{{ '_CLAIMS_._INDEMNITY_AMOUNT' | translate }}: </span> -->
		<!-- <span class="causal-state">{{damageDescriptionsElement.reserveTechAmount | currency:'EUR'}}</span> -->

		<!-- <span class="causal-state-title">{{ '_CLAIMS_._FEES_AMOUNT_RESERVE' | translate }}: </span> -->
		<!-- <span class="causal-state">{{damageDescriptionsElement.reserveTechAmount | currency:'EUR'}}</span> -->

		<!-- ------- -->
		<div class="container-fluid table-white">

			<div class="row">

				<div class="col-xs-6 col-md-3">
					<label><b>{{ damageDescriptionsElement.causative }}</b></label>
				</div>

				<div class="col-xs-6 col-md-2">
					<label>{{ '_CLAIMS_._TECHNICAL_STATE' | translate }}:&nbsp;</label>
					<label><b>{{ (damageDescriptionsElement.technicalStatus) || '-' }}</b></label>
					<!-- TODO - translate -->
					<label *ngIf="damageDescriptionsElement.deprecated">Causale Deprecata</label>
				</div>

				<div class="col-xs-6 col-md-2">
					<label>{{ '_CLAIMS_._RESERVE_AMOUNT' | translate }}:&nbsp;</label>
					<label><b>{{ (damageDescriptionsElement.reserveTechAmount | currency:'EUR':true) || '-'
							}}</b></label>
				</div>

				<div class="col-xs-6 col-md-2">
					<label>{{ '_CLAIMS_._INDEMNITY_AMOUNT' | translate }}:&nbsp;</label>
					<label><b>{{ (damageDescriptionsElement.totalPaymentIndemnity | currency:'EUR':true) || '-'
							}}</b></label>
				</div>

				<div class="col-xs-6 col-md-2">
					<label>{{ '_CLAIMS_._FEES_AMOUNT' | translate }}:&nbsp;</label>
					<label><b>{{ (damageDescriptionsElement.totalPaymentFees | currency:'EUR':true) || '-'
							}}</b></label>
				</div>

			</div>

		</div>

	</div>

</rgi-rx-expansion-panel>
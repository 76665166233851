import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxPaginatorComponent} from './rgi-rx-paginator/rgi-rx-paginator.component';
import {FormsModule} from '@angular/forms';
import {RGI_RX_PAGINATOR_TRANSLATIONS} from './i18n/localization';
import { RgiRxI18nModule } from '@rgi/rx/i18n';


@NgModule({
  declarations: [RgiRxPaginatorComponent],
  imports: [
    CommonModule,
    FormsModule,
    RgiRxI18nModule,
    RgiRxI18nModule.forRoot(RGI_RX_PAGINATOR_TRANSLATIONS)
  ],
  exports: [RgiRxPaginatorComponent]
})
/**
 * @since 0.3.1
 */
export class RgiRxPaginatorModule {
}

import {LpcCheckboxAccordionComponent} from './lpc-checkbox-accordion.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {ReactiveFormsModule} from '@angular/forms';
import { RgiRxI18nModule } from '@rgi/rx/i18n';


@NgModule({
  declarations: [LpcCheckboxAccordionComponent],
  imports: [
    CommonModule,
    PortalNgCoreModule,
    ReactiveFormsModule,
    RgiRxI18nModule
  ],
  exports: [LpcCheckboxAccordionComponent],
})
export class LpcCheckboxAccordionModule { }

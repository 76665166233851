import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'ppevo-yesno',
  templateUrl: './yesno.component.html',
  encapsulation: ViewEncapsulation.None
})
export class YesnoComponent implements OnInit {

  @Input() ngModel:string;
  @Input() disabled: boolean = false;
  @Output() ngModelChange = new EventEmitter<string>();


  constructor() { }

  ngOnInit() {
  }

  onClick(value:string){
    if(this.disabled){
      return;
    }
    this.ngModel = value;
    this.ngModelChange.emit(this.ngModel);
  }
}

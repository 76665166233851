/**
 * date-time.module
 */

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {A11yModule} from '@angular/cdk/a11y';
import {OverlayModule} from '@angular/cdk/overlay';
import {RGI_RX_DTPICKER_SCROLL_STRATEGY_PROVIDER, RgiRxDateTimeComponent} from './date-time/date-time-picker.component';
import {RgiRxDateTimeContainerComponent} from './date-time-container/date-time-container.component';
import {RgiRxDateTimeInputDirective} from './date-time-input.directive';
import {RgiRxFormElementsModule} from '../form-elements/rgi-rx-form-elements.module';
import {RgiRxDateTimeTriggerDirective} from './rgi-rx-date-time-trigger.directive';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {RGI_RX_DATEPICKER_TRANSLATIONS} from './datepicker.18n';
import {RgiRxDatePickerComponent} from './date-picker/rgi-rx-date-picker.component';
import {RgiRxCalendarModule} from './calendar/rgi-rx-calendar.module';
import {RgiRxDateTimeTimerModule} from './date-time-timer/rgi-rx-date-time-timer.module';
import {RGI_RX_DATETIME_CONFIG, RGI_RX_DEFAULT_DATETIME_CONFIG} from './adapter/locale.providers';
import {RGI_RX_DATEPICKER_CONFIG, RGI_RX_DEFAULT_DATEPICKER_CONFIG} from './date-picker-api';
import {RgiRxFormFieldErrorComponent} from '../form-elements/rgi-rx-form-field-error/rgi-rx-form-field-error.component';


@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        A11yModule,
        RgiRxCalendarModule,
        RgiRxDateTimeTimerModule,
        RgiRxFormElementsModule,
        RgiRxI18nModule.forRoot(RGI_RX_DATEPICKER_TRANSLATIONS),
        RgiRxFormFieldErrorComponent
    ],
    exports: [
        RgiRxDateTimeInputDirective,
        RgiRxDateTimeComponent,
        RgiRxDateTimeTriggerDirective,
        RgiRxDatePickerComponent
    ],
    declarations: [
        RgiRxDateTimeInputDirective,
        RgiRxDateTimeComponent,
        RgiRxDateTimeContainerComponent,
        RgiRxDateTimeTriggerDirective,
        RgiRxDatePickerComponent
    ],
    providers: [
        RGI_RX_DTPICKER_SCROLL_STRATEGY_PROVIDER,
        { provide: RGI_RX_DATETIME_CONFIG, useValue: RGI_RX_DEFAULT_DATETIME_CONFIG },
        { provide: RGI_RX_DATEPICKER_CONFIG, useValue: RGI_RX_DEFAULT_DATEPICKER_CONFIG }
    ]
})
export class RgiRxDatePickerModule {
}

<rgi-rx-panel>

  <rgi-rx-panel-header [closeable]="true" (onClose)="onCancel()">
    {{ getTitle() | translate }}
  </rgi-rx-panel-header>

  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="onCancel()" data-qa="rgi-btn-cancel" translate>
      UNDO
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" [disabled]="editPaymentForm.invalid || paymentFieldsForm.invalid"
            data-qa="rgi-btn-confirm" (click)="onConfirm()" translate>
      CONFIRM
    </button>
  </rgi-rx-panel-footer>

  <ng-container [formGroup]="editPaymentForm" *ngIf="payments">
    <rgi-rx-form-field>
      <label rgiRxLabel>
        <span translate>Payment type</span>
      </label>
      <rgi-rx-button-toggle color="info" formControlName="paymentMethod">
        <button rgi-rx-button rgiRxValueOption rg value="debit" data-qa="rgi-btn-debit"
                *ngIf="!isDebitBtnDisabled" translate>
          Debit
        </button>
        <button rgi-rx-button rgiRxValueOption value="credit" data-qa="rgi-btn-credit"
                *ngIf="!isCreditBtnDisabled" translate>
          Credit
        </button>
      </rgi-rx-button-toggle>
    </rgi-rx-form-field>

    <div class="rgi-nmf-flex-row">
      <div class="rgi-nmf-field-container">
        <div class="rgi-nmf-field">
          <rgi-rx-form-field *ngIf="editPaymentForm.get('paymentMethod').value">
            <label rgiRxLabel data-qa="rgi-paymentMethodLabel">{{ selectedMethodLabel }}</label>
            <select rgiRxNativeSelect formControlName="payment" data-qa="rgi-paymentMethod">
              <option value=""></option>
              <option *ngFor="let mean of selectedPayments" [value]="stringify(mean)">
                {{mean?.paymentConfig?.meanOfPayment?.description}}
              </option>
            </select>
          </rgi-rx-form-field>
        </div>
      </div>
    </div>
    <mic-rgi-payment-fields *ngIf="paymentFields && paymentFields.length"
                           [fields]="paymentFields"
                           [form]="paymentFieldsForm">
    </mic-rgi-payment-fields>
  </ng-container>


</rgi-rx-panel>

import { Component, EventEmitter, Input, ViewEncapsulation } from "@angular/core";
import { Observable } from "rxjs";

@Component({
    template: `
    <ppevo-panel [class]="'ppevo-panel-confirm'" [closable]="true" [notifyCloseOnly]="true" (close)="onClickClose()">
        <div class="ppevo-confirm-header" header>{{'NG_PASSPROPRO_CORE.system_message' | translate}}</div>
        <div class="ppevo-confirm-body" body>
            <div class="ppevo-confirm-icon"></div>
            <div class="ppevo-confirm-message">{{message | translate}}</div>
        </div>
        <div class="ppevo-confirm-footer" footer>
            <button class="ppevo-btn ppevo-btn-back" (click)="onClickClose()" translate>NG_PASSPROPRO_CORE.cancel</button>
            <button class="ppevo-btn ppevo-btn-highlighted" (click)="onConfirm()" translate>NG_PASSPROPRO_CORE.confirm</button>
        </div>
    </ppevo-panel>`,
    encapsulation: ViewEncapsulation.None
})
export class ConfirmComponent {
    @Input() message: string;
    @Input() title;

    protected clickEvent = new EventEmitter<boolean>();

    onClickClose() {
        this.clickEvent.emit(false);
    }

    onConfirm() {
        this.clickEvent.emit(true);
    }

    getConfirm$() :Observable<boolean> {
        return this.clickEvent;
    }
}

<div class="ac_cont_dati">
    <div class="ac_cont_riga">
        <div>
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._AUTHORIZATION_LEVEL</span>
                <span>: <b>{{ elementCurrentDetail.level?.description || '-'  }}</b></span>
            </div>
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._COMPETENT_USER</span>
                <span>: <b>{{ elementCurrentDetail.competentUser?.description || '-'  }}</b></span>
            </div>
        </div>
    </div>
    <div class="ac_cont_riga">
        <div>
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._STATUS_AFTER_EVENT</span>
                <span>: <b>{{ elementCurrentDetail.state.description}}</b></span>
            </div>
            <div class="ac_cont_single_data nmf_cont_single_data_color">
                <span translate>_AUTH_._COMMENT</span>
                <span>: <b>{{elementCurrentDetail.note || '-' }}</b></span>
            </div>
        </div>
    </div>
</div>

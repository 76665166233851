<div *ngIf="addressData && addressData.length > 0">
  <div *ngFor="let address of addressData;let i = index" class="display-inline-flex">
    <div class="rgi-ui-col-1 addresses_min_width address_row_margin">
      <rgi-rx-form-field>
        <input type="checkbox" rgiRxInput [checked]="address.main" [value]="i" data-qa="Main"
               (change)="updateAddressMain($event, i)">
      </rgi-rx-form-field>
    </div>
    <div class="rgi-ui-col-2 address_row_margin">
      {{ address.formatAddress }}
      <button class="rgi-ui-btn" *ngIf="!isMainAsset || firstAddressMode !== 2"
              (click)="changeAddressData(address)"><span class="rgi-ui-icon-edit2"></span>
      </button>
      <button class="rgi-ui-btn" *ngIf="!isMainAsset || firstAddressMode === 1"
              (click)="deleteAddress($event,address)"><span class="rgi-ui-icon-delete"></span>
      </button>
    </div>
  </div>
</div>
<div class="row" [ngClass]="{'mt-3': !formatAddress}" *ngIf="!this.addressData || this.addressData.length === 0">
  <div class="rgi-ui-col-4">
    <button type="button" (click)="addAddress()"
            class="rgi-ui-btn rgi-ui-btn-info pull-left text-uppercase"
            data-qa="find-actions-btn">
      <span translate>RE_ISSUE.ADD_ADDRESS</span>
    </button>
  </div>
</div>

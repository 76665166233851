import {Component, Input} from '@angular/core';
import {GPAsset} from '../../../../group-policy-models/group-policy-issue-guarantees';
import {
  GPDetailClusterSubStateManager
} from '../../../../group-policy-state/group-policy-sub-statemanagers/group-policy-cluster-sub-statemanager';


@Component({
  selector: 'rgi-gp-group-policy-summary-assets-component',
  templateUrl: './group-policy-summary-assets.component.html'
})
export class GroupPolicySummaryAssetsComponent {


  @Input() summaryAssets: GPAsset[];

  @Input() stateManagerCluster: GPDetailClusterSubStateManager<any>;

  constructor() {
  }

}

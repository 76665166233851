import {AfterContentInit, ContentChildren, Directive, forwardRef, Input, OnDestroy, QueryList} from '@angular/core';
import {merge, Subscription} from 'rxjs';
import {RgiRxExpansionPanelComponent} from './expansion-panel/rgi-rx-expansion-panel.component';
import {RgiRxVirtualDOMError} from '@rgi/rx';
import {startWith, switchMap, tap} from 'rxjs/operators';
import {coerceNumberProperty} from '@angular/cdk/coercion';


@Directive({
  selector: '[rgi-rx-accordion]',
  host: {
    class: 'rgi-ui-accordion'
  },
  exportAs: 'rgiRxAccordion'
})
export class RgiRxAccordionDirective implements AfterContentInit, OnDestroy {
  @Input() multi = false;

  @ContentChildren(RgiRxExpansionPanelComponent) expansionPanels: QueryList<RgiRxExpansionPanelComponent>;

  @ContentChildren(forwardRef(() => RgiRxAccordionDirective), {descendants: true}) innerAccordions: QueryList<RgiRxAccordionDirective>;

  private toggleEventSubscription = Subscription.EMPTY;


  ngAfterContentInit(): void {
    this.toggleEventSubscription = this.expansionPanels.changes.
      pipe(
        startWith(this.expansionPanels),
        switchMap(panels => {
          return merge(...panels.map((panel: RgiRxExpansionPanelComponent) => {
            return panel.toggleEvent
              .pipe(
                tap(toggle => {
                  if (!!toggle) {
                    panel.expand();
                    if (!this.multi) {
                      panels.filter(p => p !== panel).forEach(p => p.contract());
                    }
                  }
                })
              );
          }));
        })
    ).subscribe();
  }


  ngOnDestroy(): void {
    this.toggleEventSubscription.unsubscribe();
  }

  contract(index: number) {
    const target = coerceNumberProperty(index);
    const contactable = this.expansionPanels.find((value, i) => {
      return i === target;
    });
    if (!contactable) {
      this.throwNoIndexExist(target);
    }
    contactable.contract();
  }


  private throwNoIndexExist(index: number) {
    throw new RgiRxVirtualDOMError(`No children element exist for index ${index}`);
  }

  contractAll(opts: { descendants: boolean } = {descendants: false}): void {
    this.expansionPanels.forEach(
      panel => {
        panel.contract();
      }
    );
    if (opts && opts.descendants) {
      this.innerAccordions.forEach(
        i => {
          i.expansionPanels.forEach(p => p.contract());
        }
      );
    }
  }

  expand(index: number) {
    const target = coerceNumberProperty(index);
    const expandable = this.expansionPanels.find((value, i) => {
      return i === target;
    });

    if (!expandable) {
      this.throwNoIndexExist(index);
    }
    expandable.expand();
  }

  expandAll(opts: { descendants: boolean } = {descendants: false}): void {
    this.expansionPanels.forEach(
      panel => {
        panel.expand();
      }
    );
    if (opts && opts.descendants) {
      this.innerAccordions.forEach(
        i => {
          i.expansionPanels.forEach(p => p.expand());
        }
      );
    }
  }
}

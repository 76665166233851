<div class="rgi-ui-snackbar"
     [rgi-rx-qa]="notification.id"
     qa-type="snackbar-notification"
     [qa-value]="qaValue"
     [class.rgi-ui-snackbar-panel]="expanded"
     *ngIf="notification" (toggle)="expanded = $event" [rgiRxSdkDelayedDestroy]="notification.delay" (onDestroy)="onRemove()"
     rgiRxSDKExpandableHost [expanded]="notification.detail && notification.options?.expanded || false"  #expandHost="rgiRxSDKExpandableHost"
>
  <rgi-rx-snackbar-header></rgi-rx-snackbar-header>
  <rgi-rx-snackbar-body *ngIf="notification.detail && expandHost.expanded"></rgi-rx-snackbar-body>
</div>

import {Component, Input} from '@angular/core';
import {LabeledEntity} from "./resources/models/entities";
import {fromEvent, Subject} from "rxjs";
import { debounceTime, takeUntil } from 'rxjs/operators';
import {SUMMARY_SECTION_DETAIL_TYPE} from "./resources/models/constants";

@Component({
  selector: 'rgi-generic-detail',
  templateUrl: './generic-detail.component.html'
})
export class GenericDetailComponent {

  @Input() details: LabeledEntity[] | LabeledEntity[][];
  displayType: SUMMARY_SECTION_DETAIL_TYPE;
  colNum: number;
  _unsubscriber$: Subject<any> = new Subject();

  // Media Breakpoints
  sm = 768;
  md = 1024;
  lg = 1280;

  constructor() { }

  ngOnInit() {
    if(this.details?.length) {
      this.displayType = Array.isArray(this.details[0]) ? SUMMARY_SECTION_DETAIL_TYPE.ROWS : SUMMARY_SECTION_DETAIL_TYPE.KEYVALUES
    }
    this.takeBreakpoint();
  }

  ngOnDestroy(): void {
    this._unsubscriber$.next({});
    this._unsubscriber$.complete();
  }

  showSeparator(index: number): boolean {
    return index < ((Math.ceil(this.details.length / this.colNum) -1) * this.colNum);
  }

  public isDate(description: any): boolean {
    const date = new Date(description);
    return date instanceof Date && !isNaN(date.getTime());
  }

  takeBreakpoint(){
    this.colNum = this.calculateColNum(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(10),
        takeUntil(this._unsubscriber$)
      ).subscribe((evt: any) => {
      this.colNum = this.calculateColNum(evt.target.innerWidth);
    });
  }

  private calculateColNum(width: number): number {
    if (width <= this.sm) {
      return 1
    } else if (width > this.sm && width <= this.md) {
      return 2
    } else if (width > this.md && width <= this.lg) {
      return 3
    } else {
      return 4
    }
  }

}

import { Component, Input, Output, HostListener, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { Factor } from '../../models/factor.model';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'lpc-factors',
  templateUrl: './factors.component.html',
  styleUrls: ['./factors.component.css']
})

export class FactorsComponent implements OnInit, OnChanges {
  @Input() fattori: Factor[];
  @Input() parentForm: UntypedFormGroup;

  @Output() changeValEmitter = new EventEmitter<string>();

  @HostListener('changeVal')
  onChangeVal() {
    this.changeValEmitter.emit();
  }

  constructor(protected fb: UntypedFormBuilder)  {

  }
  ngOnInit(): void {
    if (!!this.fattori) {
      this.setFactors(this.fattori);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetForm(this.fattori);
  }

  setFactors(fattori: Factor[]) {
    fattori.map((x: Factor) => {
      this.parentForm.addControl(
      x.code, new UntypedFormControl(x.value)
      );
    });
  }

  private resetForm(fattori: Factor[]) {
    Object.keys(this.parentForm.controls)
    .filter(code => !fattori.map(f => f.code).includes(code))
    .forEach(controlToBeRemoved => this.parentForm.removeControl(controlToBeRemoved));
  }
}

<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>LPS Taxes</span>
        </h3>
      </div>
      <div class="modal-body">

        <div class="hidden-xs">
          <div class="header-text">
            <span translate>Instalment at Inception Date </span>
          </div>
        </div>

        <div class="tbld tbld_commissions hidden-xs">
          <div class="tbld_row tbld_row_commissions_header">
            <div class="tbld_col tbld_col_gross"></div>
            <div class="tbld_col tbld_col_text tbld_col_taxes"><span translate>Taxable amount</span></div>
            <div class="tbld_col tbld_col_double tbld_col_insurance tbld_col_text"><span translate>Insurance Tax</span>
            </div>
          </div>
          <div class="tbld_row tbld_row_commissions">
            <div class="tbld_col"></div>
            <div class="tbld_col"></div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single tbld_col_text tbld_col_single_left"><span translate>Rate</span></div>
              <div class="tbld_col tbld_col_single tbld_col_single_center tbld_col_text"><span translate>Amount</span>
              </div>
            </div>
          </div>
          <div *ngFor="let lpsTaxType of lpsTaxDetailSubscriptionInstallment.lpsTaxTypeList"
               class="tbld_row tbld_row_commissions">
            <div class="tbld_col tbld_col_text">{{ lpsTaxType.description }}</div>
            <div class="tbld_col">{{ lpsTaxType.taxRate | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;
            </div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single tbld_col_single_left">
                <span>{{ lpsTaxType.taxable | number : '1.2-2' :'it'}}&nbsp;%</span>
              </div>
              <div class="tbld_col tbld_col_single tbld_col_single_center">
                <span>{{ lpsTaxType.taxes | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;</span>
              </div>
            </div>
          </div>
          <div *ngFor="let lpsTax of lpsTaxDetailSubscriptionInstallment.lpsTaxList"
               class="tbld_row tbld_row_commissions">
            <div class="tbld_col tbld_col_text">{{ lpsTax.description }}</div>
            <div class="tbld_col">{{ lpsTax.taxblAmt | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;
            </div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single tbld_col_single_left">
                <span>{{ lpsTax.taxable | number : '1.2-2' :'it'}}&nbsp;%</span>
              </div>
              <div class="tbld_col tbld_col_single tbld_col_single_center">
                <span>{{ lpsTax.taxes | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;</span>
              </div>
            </div>
          </div>
          <div class="tbld_row tbld_row_commissions_total">
            <div class="tbld_col tbld_col_single tbld_col_text" translate>Total</div>
            <div class="tbld_col tbld_col_single"></div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single">
                <span>&nbsp;</span>
              </div>
              <div class="tbld_col tbld_col_single tbld_col_single_center">
                <span>{{ lpsTaxDetailSubscriptionInstallment.lpsTaxTotal | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;</span>
              </div>
            </div>
          </div>
        </div>

        <br>

        <div class="hidden-xs">
          <div class="header-text">
            <span translate>Next instalment</span>
          </div>
        </div>

        <div class="tbld tbld_commissions hidden-xs">
          <div class="tbld_row tbld_row_commissions_header">
            <div class="tbld_col tbld_col_gross"></div>
            <div class="tbld_col tbld_col_text tbld_col_taxes"><span translate>Taxable amount</span></div>
            <div class="tbld_col tbld_col_double tbld_col_insurance tbld_col_text"><span translate>Insurance Tax</span>
            </div>
          </div>
          <div class="tbld_row tbld_row_commissions">
            <div class="tbld_col"></div>
            <div class="tbld_col"></div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single tbld_col_text tbld_col_single_left"><span translate>Rate</span></div>
              <div class="tbld_col tbld_col_single tbld_col_single_center tbld_col_text"><span translate>Amount</span>
              </div>
            </div>
          </div>
          <div *ngFor="let lpsTaxType of lpsTaxDetailInstallment.lpsTaxTypeList"
               class="tbld_row tbld_row_commissions">
            <div class="tbld_col tbld_col_text">{{ lpsTaxType.description }}</div>
            <div class="tbld_col">{{ lpsTaxType.taxRate | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;
            </div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single tbld_col_single_left">
                <span>{{ lpsTaxType.taxable | number : '1.2-2' :'it'}}&nbsp;%</span>
              </div>
              <div class="tbld_col tbld_col_single tbld_col_single_center">
                <span>{{ lpsTaxType.taxes | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;</span>
              </div>
            </div>
          </div>
          <div *ngFor="let lpsTax of lpsTaxDetailInstallment.lpsTaxList"
               class="tbld_row tbld_row_commissions">
            <div class="tbld_col tbld_col_text">{{ lpsTax.description }}</div>
            <div class="tbld_col">{{ lpsTax.taxblAmt | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;
            </div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single tbld_col_single_left">
                <span>{{ lpsTax.taxable | number : '1.2-2' :'it'}}&nbsp;%</span>
              </div>
              <div class="tbld_col tbld_col_single tbld_col_single_center">
                <span>{{ lpsTax.taxes | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;</span>
              </div>
            </div>
          </div>
          <div class="tbld_row tbld_row_commissions_total">
            <div class="tbld_col tbld_col_single tbld_col_text" translate>Total</div>
            <div class="tbld_col tbld_col_single"></div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single">
                <span>&nbsp;</span>
              </div>
              <div class="tbld_col tbld_col_single tbld_col_single_center">
                <span>{{ lpsTaxDetailInstallment.lpsTaxTotal | currency:'EUR':'symbol':'1.2-2':'it'}}&nbsp;</span>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="close()" class="btn btn-warning" data-qa="btn-lps-taxes-modal-close" translate>Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Modal} from '../../modal';
import {ProposalService} from '../../proposal.service';
import {AuthorizationRequest} from './authorization-request';
import {ModalService, TableElement} from '@rgi/portal-ng-core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {GenericEntity} from '../../models/domain-models/generic-entity';

@Component({
  selector: 'mic-authorization-request',
  templateUrl: './authorization-request.component.html',
  styleUrls: ['authorization-request.component.scss'],
  providers: [NgbTabsetConfig]
})
export class AuthorizationRequestComponent implements Modal, OnInit {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  @Output() authorizationSent: EventEmitter<boolean> = new EventEmitter<boolean>();
  componentRef: import ('@angular/core').ComponentRef<any>;
  authorizationRequest: AuthorizationRequest;
  authorizationRequestForm: UntypedFormGroup = new UntypedFormGroup({
    referenceUser: new UntypedFormControl(),
    priority: new UntypedFormControl(),
    notes: new UntypedFormControl()
  });

  arrayOfElements: Array<Array<any>> = new Array<Array<any>>();
  tableHeader: TableElement[] = new Array<TableElement>();
  proposalNumber: string;
  proposalState: GenericEntity;

  private nestedTablesOpen: Map<number, boolean> = new Map();

  constructor(
    protected proposalService: ProposalService,
    protected formBuilder: UntypedFormBuilder,
    protected modalService: ModalService,
    protected translate: TranslateService,
    tabsetConfig: NgbTabsetConfig
  ) {
    tabsetConfig.type = 'pills';
  }

  ngOnInit() {
    this.tableHeader.push(new TableElement('Block'));
    this.tableHeader.push(new TableElement('Error'));
    this.tableHeader.push(new TableElement('Type'));
    this.tableHeader.push(new TableElement('Origin Entity'));

    this.proposalNumber = this.proposalService.getProposalNumber();
    this.proposalService.getProposalState().subscribe(state => {
      if (state) {
        this.proposalState = state;
      }
    });

    this.proposalService.getAuthorizationData('RAO_REQUEST').subscribe(
      (data) => {
        this.authorizationRequest = data;

        this.authorizationRequestForm = this.formBuilder.group({
          referenceUser: [this.authorizationRequest.referenceUser],
          priority: [this.authorizationRequest.priority],
          notes: [this.authorizationRequest.notes]
        });

        if (this.authorizationRequest.notifications) {
          this.authorizationRequest.notifications.forEach(
            (notification) => {
              const tableRow: TableElement[] = [
                new TableElement(String(notification.block), 'span', String(notification.id)),
                new TableElement(String(notification.errorDescription), 'span', String(notification.id)),
                new TableElement(notification.type ? String(notification.type.description) : '', 'span',
                  String(notification.id)),
                new TableElement(String(notification.originEntity), 'span', String(notification.id))];
              this.arrayOfElements.push(tableRow);

              const nestedTableRows = [];

              nestedTableRows.push([
                new TableElement(String('Error:'), 'span', String(notification.id)),
                new TableElement(String(notification.errorDescription), 'span', String(notification.id)),
                new TableElement(String('Type:'), 'span', String(notification.id)),
                new TableElement(notification.type ? String(notification.type.description) : '', 'span',
                  String(notification.id))]);

              nestedTableRows.push([
                new TableElement(String('Level:'), 'span', String(notification.id)),
                new TableElement(String(notification.block), 'span', String(notification.id)),
                new TableElement(String('Origin Entity:'), 'span', String(notification.id)),
                new TableElement(String(notification.originEntity), 'span', String(notification.id))]);
              nestedTableRows.push([
                new TableElement(String('Description:'), 'span', String(notification.id)),
                new TableElement(String(notification.description), 'span', String(notification.id)),
                new TableElement(String(''), 'span', String(notification.id)),
                new TableElement(String(''), 'span', String(notification.id))]);

              this.arrayOfElements.push(nestedTableRows);
            }
          );
        }


        this.arrayOfElements.forEach(
          (data2) => {
            this.nestedTablesOpen.set(Number(data2[0].description), false);
          }
        );

      }
    );

  }

  toggleAccordion(index) {
    const rowId = Number(this.arrayOfElements[index][0].getId());
    if (this.nestedTablesOpen.get(rowId) === true) {
      this.nestedTablesOpen.set(rowId, false);
    } else {
      this.nestedTablesOpen.set(rowId, true);
    }
  }

  isNestedTableOpen(index): boolean {
    if (index % 2 > 0) {
      index = index - 1;
    }
    if (!this.arrayOfElements || !this.arrayOfElements[index]
      || !this.arrayOfElements[index][0]
      || !this.arrayOfElements[index][0].getId()) {
      return false;
    }
    const rowId = Number(this.arrayOfElements[index][0].getId());
    return this.nestedTablesOpen.get(rowId);
  }

  close() {
    this.componentRef.destroy();
  }

  authorize() {
    this.authorizationRequest.referenceUser = this.authorizationRequestForm.get('referenceUser').value;
    this.authorizationRequest.priority = this.authorizationRequestForm.get('priority').value;
    this.authorizationRequest.notes = this.authorizationRequestForm.get('notes').value;

    this.proposalService.updateAuthorizationData(this.authorizationRequest).subscribe(data => {
        this.authorizationSent.emit(true);
        this.modalService.open(
          [this.translate.instant('The authorization request has been sent to the competent user')],
          this.translate.instant('SYSTEM MESSAGE'));
      },
      () => {
      },
      () => {
        this.close();
      });

  }

  compareGenericEntities(ent1, ent2): boolean {
    return ent1 && ent2 ? ent1.code === ent2.code : ent1 === ent2;
  }

}

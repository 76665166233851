import {Injectable} from '@angular/core';
import {formatIban} from '../iban/rgi-country-layer-iban.fns';

@Injectable({
  providedIn: 'root'
})
export class RgiCountryLayerFormatter {
  iban(rawValue: string): string {
    return formatIban(rawValue);
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PassproproAuthService} from './passpropro-auth.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    PassproproAuthService
  ],
})
class PassproproAuthModule {
}
export {
  PassproproAuthModule,
  PassproproAuthService
};

import {RgiRxSerializedI18n} from '@rgi/rx/i18n';

export const IT: RgiRxSerializedI18n = {
  CONTRACT_CONSULTATION_CARD_TITLE: 'Consultazione Contratto',

  CONTRACT_CONSULTATION: {
    NUMBER: 'Numero',
    DESCRIPTION: 'Descrizione',
    POLICYHOLDER: 'Contraente',
    EFFECTIVE_DATE: 'Data Effetto',
    STATE: 'Stato',
    ANNUAL_PREMIUM_EXCEPT_RIGHTS: 'Premio Annuo (escluso diritti)',
    MANAGEMENT_NODE: 'Nodo di gestione',
    NODE_LIST: 'Lista Nodi',
    ISSUE_DATE: 'Data Emissione',
    CANCELLATION_DATE: 'Data annullamento',
    PRODUCT: 'Prodotto',
    EXPIRE_DATE: 'Data scadenza',
    CURRENT_MOVEMENT: 'Movimento corrente',
    CONTRACTS_LIST: 'Elenco contratti',
    SEARCH_RESULTS: 'Risultati di ricerca',
    BACK: 'INDIETRO',
    FIND: 'TROVA',
    EMPTY: 'SVUOTA',
    REPORT:'REPORT',
    CONTRACT_BRANCH:'Ramo Contratto',
    TYPE:'Tipo',
    SUBTYPE:'Sottotipo',
    LIMITATION:'Limitazione',
    POINT_OF_SALE:'Nodo di Gestione',
    SEARCH_UNDER_SUBNET:'Ricerca nella Sottorete',
    WHOLE_SALES_NETWORK: 'Ricerca In Tutta La Rete Vendita',
    COMPANY:'Compagnia',
    INDEPENDENT_AGENT:'Segnalatore',
    BRANCH_OFFICE:'Filiale',
    CONTRACT_TYPE:'Tipo Contratto',
    PROPOSAL_NUMBER:'Numero Proposta',
    POLICY_NUMBER:'Numero Polizza',
    PRODUCT_TYPE:'Tipo Prodotto',
    CONTRACT_CONDITION:'Situazione del Contratto',
    SUBJECT:'Soggetto',
    SUBJECTS:'Soggetti',
    ALL: 'Tutti',
    PC: 'P&C',
    LIFE: 'Vita',
    INDIVIDUAL: 'Individuale',
    COLLECTIVE:'Collettive',
    NOT_ANONYMIZED: 'Non Anonimizzati',
    ANONYMIZED: 'Anonimizzati',
    MASTER_POLICY: 'Polizza Madre',
    MASTER_PROPOSAL_NUMBER:'Numero Proposta Madre',
    MASTER_POLICY_NUMBER:'Numero Polizza Madre',
    APPLICATION:'Applicazione',
    AT_PRESENT_DAY:'Alla data odierna',
    APPLICATION_NUMBER: 'Numero Applicazione',
    LAST:'Ultimo',
    ACTIONS:'Azioni',
    CODE:'Codice',
    CANCEL:'Annulla',
    CLOSE:'Chiudi',
    POLICIES: 'Polizze',
    PROPOSALS:'Proposte',
    POLICIES_OR_PROPOSALS:'Polizze o proposte',
    QUOTE_W_REG_PARTY:'Preventivi anagrafati',
    ANONYMOUS_QUOTE:'Preventivi anonimi',
    QUOTE_NUMBER:'Numero preventivo',
    SYSTEM_MESSAGE:'Messaggio di sistema',
    VALORIZE_NUMBER:'Valorizzare numero proposta, numero polizza o numero applicazione.',
    SELECT_NODE:'SELEZIONARE NODO',
    FIELDS_MARKED_IN_RED: 'I campi marcati in rosso sono richiesti o contengono valori errati',
    NO_RESULTS:'Nessun risultato',
    NO_ACTIONS:'Nessuna azione disponibile per il contratto selezionato.',
    WAIT: 'Attendere la richiesta è stata mandata al server...',
    LABEL_ANONIMIZED:'I dati visualizzati sono stati anonimizzati, pertanto potrebbero essere privi di significato',
    SUBJECT_ROLE:'Ruolo soggetto',
    PLATE_NUMBER: 'Targa / Telaio / Numero motore',
    SHIP_NAME: 'Nome nave / codice IMO',
    ERROR_FORMAT_DATE: 'Errore formato data',
    CONTRACT_STATES: 'Stati contratto',
    CONTRACT_SUBSTATES: 'Sottostati contratto',
    ACTION: 'Azioni',
    CREATE_COPY: 'Crea Copia',
    CONTRACT_SUMMARY: 'Riepilogo Contratto',
    NOMINATIVE: 'Nominativo Contraente',
    FISCAL_CODE: 'Codice Fiscale',
    TACIT_RENEWAL: 'Tacito Rinnovo',
    FRACTIONATION:'Frazionamento',
    USER: 'Utente',
    MOVEMENTS: 'Movimenti',
    YES: 'Si',
    NO:"No",
    NOTES: 'Note',
    MIGRATED: 'Migrato',
    ORIGINAL_EFFECTIVE_DATE:'Data Effetto Originale',
    EXPIRY_DATE:'Data Scadenza',
    COVERAGE_EXPIRY_DATE:'Data Scadenza Copertura',
    TERMINATION_DATE:'Data Rescindibilità',
    PROPOSAL_VALIDITY_END_DATE:'Data Fine Validità Proposta',
    SETTLEMENT_BLOCK_DATE:'Data Blocco Quietanze',
    MIGRATED_POLICY:'Contratto Migrato',
    AGREEMENT:'Convenzione',
    POLICY_DELIVERY_METHOD:'Recapito Polizza',
    POLICY_DETAILS:'Dettaglio Polizza',
    ADMINISTRATIVE_DATA: 'Dati Amministrativi',
    SIGN_INSTALMENT: 'Rata Firma',
    NEXT_INSTALMENT: 'Rata Successiva',
    ANNUAL_PREMIUM: 'Premio Annuale',
    DISCOUNTS: 'Sconti applicati',
    COMMISSIONS: 'Provvigioni',
    PREMIUM: 'Premio',
    CLAUSES: 'Clausole',
    FACTORS: 'Fattori',
    NET: 'Netto',
    ACCESSORIES: 'Accessori',
    FRACTIONING_INTERESTS: 'Interessi di Frazionamento',
    TAXABLE: 'Imponibile',
    TAXES: 'Tasse',
    GROSS: 'Lordo',
    WARNINGS:"Segnalazioni",
    WARNINGS_LIST: 'Lista Segnalazioni',
    BLOCKING_LEVEL: "Livello Deroga",
    ORIGINAL_ENTITY: "Entità Origine",
    SUBSTITUTION_EFFECTIVE_DATE: 'Data Effetto Sostituzione',
    SUBSTITUTION_REASON: 'Motivo Sostituzione',
    SUBSTITUTIONS:'Sostituzioni',
    SUBSTITUENT_POLICIES:'Polizze Sostituenti',
    SUBSTITUTED_POLICIES:'Polizze Sostituite',
    SUBSTITUENT_PROPOSALS:'Proposte Sostituenti',
    PROPOSAL_DETAILS:'Dettaglio Proposta',
    WARRANTIES: 'Garanzie e Beni Assicurati',
    TOTAL_GROSS: 'Premio Totale Lordo',
  }
};

import {
  Catalog,
  CatalogGetOptions,
  RGI_RX_LOCALE,
  RgiRxI18nError,
  RgiRxSerializedI18n,
  TranslationCatalog
} from './i18n-api';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {merge} from 'lodash';
import {RgiRxTemplateInterpolationService, walk} from '@rgi/rx';

@Injectable({
  providedIn: 'root'
})
export class RgiRxTranslationCatalogService implements TranslationCatalog {

  private _catalog$: BehaviorSubject<Catalog> = new BehaviorSubject<Catalog>({});

  constructor(private interpolator: RgiRxTemplateInterpolationService) {
  }

  add(key: string, value: string, locale: RGI_RX_LOCALE) {
    if (!this._catalog$.getValue()[locale][key]) {
      this._catalog$.getValue()[locale][key] = value;
    }
  }

  replace(key: string, value: string, locale: RGI_RX_LOCALE) {
    this._catalog$.getValue()[locale][key] = value;
  }

  load(data: RgiRxSerializedI18n, locale: RGI_RX_LOCALE | string) {
    const catalog = this._catalog$.getValue();
    if (!catalog[locale]) {
      catalog[locale] = {};
    }
    merge(catalog[locale], JSON.parse(JSON.stringify(data)));
    this._catalog$.next(catalog);
  }

  getKey$(key: string, locale: RGI_RX_LOCALE, opts?: CatalogGetOptions): Observable<string | undefined> {
    return this._catalog$.pipe(
      map(c => {
        if (key === ''){
          throw new RgiRxI18nError(`Empty keys cannot be translated`, {
            locale,
            key
          });
        }
        const i18n = c[locale];
        if (!i18n) {
          throw new RgiRxI18nError(`Missing translation for language ${locale}`, {
            locale
          });
        }
        let translated: string;

        const traversed = walk(key, i18n);
        if (typeof traversed === 'string') {
          translated = traversed;
        } else if (traversed !== undefined && typeof traversed === 'object') {
          this.throwTraversedNodeIsATranslationKey(key, locale, traversed as any);
        }

        if (!translated) {
          throw new RgiRxI18nError(`Missing translation for language ${locale} with key ${key}`, {
            locale,
            key
          });
        }
        if (opts && opts.interpolation) {
          translated = this.interpolator.interpolate(translated, opts.interpolation);
        }
        return translated;
      })
    );
  }


  getCatalog$(locale: RGI_RX_LOCALE): Observable<RgiRxSerializedI18n> {
    return this._catalog$.pipe(
      map(c => c[locale])
    );
  }

  /**
   * @description check weather locale has registered translations.
   * @deprecated this method return also true for partial loaded locales, use RgiRxTranslationLoaderService.hasLoaded for
   * better results
   * @param locale the locale to check
   */
  hasLoaded(locale: RGI_RX_LOCALE) {
    return !!this._catalog$.value[locale];
  }

  private throwTraversedNodeIsATranslationKey(key: string, locale: RGI_RX_LOCALE, traversed: RgiRxSerializedI18n) {
    throw new RgiRxI18nError(`Traversed node ${key} is not a translation key but rather a node`, {
      locale,
      node: traversed,
      key
    });
  }
}

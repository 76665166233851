import {RePostsalesState} from './re-postsales-state';
import {GenericEntity, SubReason} from '../re-postsale-model/substitution';
import {Message} from '../../../models/message';
import {Node} from "../../../models/node";

export interface RePostsalesSubstitutionNavigationData {
  policy: any; // TODO: set interface
  backNavigate: string;
  contract: any;
  authenticationNodeCode: string;
  productionNodeCode: string;
  node: any;
  userCode: string;
}

export class RePostsalesSubstitutionState extends RePostsalesState {
  policy: any; // TODO: set interface
  backNavigate: string;
  reasonList: Array<SubReason> = new Array<SubReason>();
  productList: Array<GenericEntity> = new Array<GenericEntity>();
  products: Array<GenericEntity> = new Array<GenericEntity>();
  productTypes: Array<GenericEntity> = new Array<GenericEntity>();
  nodeList: Array<Node> = new Array<Node>();
  validationMsgReasonList: Array<Message> = new Array<Message>();
  validationMsgProductList: Array<Message> = new Array<Message>();
  authenticationNodeCode: string;
  productionNodeCode: string;
  node: any;
  userCode: string;
}

import {Inject, Injectable} from '@angular/core';
import {RGI_RX_QA_CONFIG, RgiRxQAConfig, RgiRxQARenderType} from './rgi-rx-data-qa-api';
import {config} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RgiRxQAConfigService {

  private _config: RgiRxQAConfig;
  constructor(
    @Inject(RGI_RX_QA_CONFIG) defaults: RgiRxQAConfig
  ) {
    this._config = {...defaults};
  }
  get enabled(): boolean {
    return this._config.enabled;
  }
  set enabled(bol: boolean) {
    this._config.enabled = bol;
  }
  set debug(renderMode: RgiRxQARenderType | false) {
    this._config.debug = renderMode;
  }
  get debug(): RgiRxQARenderType | false {
    return this._config.debug;
  }


  get config(): RgiRxQAConfig {
    return {...this._config};
  }
}

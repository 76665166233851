export class Warning {
  public additionalLabel: any;
  public authorizationMessage: boolean;
  public blockingMessage: boolean;
  public code: any;
  public codeType: string;
  public description: string;
  public title: any;
  public type: string;
  public warningMessage: boolean;
  public key: string;
}

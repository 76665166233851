<div [class]="{'subcard': issubcard}">
    <rgi-rx-datatable [data]="rgiRxDatatableDatasource"
                    [schema]="issubcard ? AUTHORIZATIONS_LIST_TABLE_SCHEMA_SUB : AUTHORIZATIONS_LIST_TABLE_SCHEMA"
                    (onAction)="onActionClick($event)"
                    [sortHeader]="true"
                    [expansionRow]="authorizationDetails">
    </rgi-rx-datatable>
</div>
<ng-template #authorizationDetails let-ctx>
    <ac-authorizations-list-detail [subcard]="issubcard" [elementCurrentDetail]="getDetailModel(ctx)"></ac-authorizations-list-detail>
</ng-template>

import {Injectable} from '@angular/core';
import {ReIssuePortfolioResourceService} from '../re-issue-resources/re-issue-portfolio-resource.service';
import {Observable} from 'rxjs';
import {PortfolioMovementResponse} from '../../models/api-models/portfolio-movement-response';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePortfolioBusinessService {

  constructor(
    private portfolioResourceService: ReIssuePortfolioResourceService
  ) {
  }

  getPolicyMovementList$(policyNumber: string): Observable<PortfolioMovementResponse> {
    return this.portfolioResourceService.getPolicyMovementList$(policyNumber);
  }

}

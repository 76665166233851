<template #openSubstitutionModal></template>
<div>
  <div class="nmf_cont_titsez nmf_cont_titsez_color">
    <span class="header-icon rgi-nmf rgi-nmf-info"></span>
    <h1><span translate>Substitute policy n.</span> {{policyNumber}}</h1>
    <div *ngIf="" class="nmf_errorautomaticbranch_icon"><span
      class="rgifont rgi-survey_ko"></span></div>
  </div>
  <div>
    <form [formGroup]="substitutionForm">
      <div class="form-group row">
        <div class="col-sm-6 col-xs-12">
          <label translate>Substitution reason</label>
          <pnc-required required="true"></pnc-required>
          <select #substReason [compareWith]="compareGenericEntities" class="select-nativa" formControlName="substReason"
                  name="substReason">
            <option disabled selected></option>
            <option *ngFor="let substReason of substReasons; index as i; trackBy: genericEntitiesTrackByFn"
                    [ngValue]="substReason.id">{{substReason?.description}}
            </option>
          </select>
          <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-6 col-xs-12">
          <label rgiRxLabel translate>Effective Date</label><pnc-required required="true"></pnc-required>
          <rgi-rx-date-picker [openOnClick]="false">
            <input [rgiRxDateTimeInput]="configPicker3" formControlName="effect" name="Effective date">
            <rgi-rx-date-time #configPicker3></rgi-rx-date-time>
          </rgi-rx-date-picker>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-6 col-xs-12">
          <label translate>Product</label>
          <pnc-required required="true"></pnc-required>
          <select #product [compareWith]="compareGenericEntities" class="select-nativa" formControlName="product"
                  name="product">
            <option disabled selected></option>
            <option *ngFor="let product of products; index as i; trackBy: genericEntitiesTrackByFn"
                    [ngValue]="product.id">{{product?.description}}
            </option>
          </select>
          <span class="pull-right arrow-select-native rgifont rgi-chevron-down"></span>
        </div>
      </div>
      <pnc-form-message *ngIf="validationMessages?.length > 0" [messages]="validationMessages" data-qa="action-error-messages"
                        detail="validationError" objectType="complex" type="error">
      </pnc-form-message>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="goBack()" class="btn btn-warning" data-qa="btn-substitution-back" translate>back</button>
          </div>
          <div class="btn-group">
            <button (click)="onSubmit()" [disabled]="validationMessages?.length" class="btn btn-warning"
                    data-qa="btn-substitution-next" translate>CONFIRM
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

import {RgiRxEventService} from '@rgi/rx';
import {ActiveRoute, RgiRxRouteData} from '@rgi/rx/router';
import {RgiRxLocalStateManager, State, StateStoreService} from '@rgi/rx/state';
import {of} from 'rxjs';
import {AnagApiParty} from '../../anag-model/anag-domain/anag-api-party';
import {
  SubjectSelectedEvent,
  SubjectSelectedEventInstance
} from '../../anag-events/anag-event';
import {AnagRouteData} from '../../anag-model/anag-domain/anag-route-data';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {AnagIntegrationService} from '../../services/anag-integration.service';
import {ModalService} from '@rgi/rx/ui';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {AnagFlowData} from '../../anag-model/anag-domain/anag-flow-data';
import {AnagStatelessOpResultHomonymyService} from './anag-stateless-op-result-homonymy.service';


export class AnagStateResultHomonymy extends State {
  parties: Array<AnagApiParty>;
  serviceError: string;
  isModal = false;
  party: AnagApiParty;
  canCreateParty: boolean;
  canEditParty: boolean;
  totalCounterParties: string;
  redirectToMainFlow = false;
  flowData: AnagFlowData;
  isUmaPartyHomonymyEnable: boolean;
  isBackFromHomonymy: boolean;
  isUmaPartyCFDuplicate: boolean;
  isUmaPartyGroupCompanies: boolean;
}

export class AnagStateManagerResultHomonymy extends RgiRxLocalStateManager<AnagStateResultHomonymy> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected statelessOps: AnagStatelessOpResultHomonymyService,
    protected eventService: RgiRxEventService,
    protected anagStorage: AnagStorageService,
    protected anagIntegrationService: AnagIntegrationService,
    protected modalService: ModalService,
    protected statelessService: AnagStatelessService,
    protected i18nService: RgiRxTranslationService
  ) {
    super(activeRoute, stateStoreService);
    this.initResultsHomonymy(activeRoute.getRouteData<AnagRouteData>());
  }

  initResultsHomonymy(routeData: RgiRxRouteData<AnagRouteData>) {
    const newResultHomonymyState = new AnagStateResultHomonymy(this.activeRoute.key);
    const initResultHomonymyState$ = this.statelessOps.initResultsHomonymy$(of(newResultHomonymyState), routeData);
    this.updateState$(initResultHomonymyState$);
  }

  datatableActionSelectSubject(row, name, stateMgrKeyData) {
    if (row.isModal) {
      this.eventService.emit<SubjectSelectedEvent>(
        new SubjectSelectedEventInstance(
          this.getCurrentState().parties.find(party => {
            return party.objectId === row.objectId;
          })
        )
      );
    } else {
      this.editOrSelectSubject(row, name, stateMgrKeyData);
    }
  }

  actionEditParty(party: any) {
    const routeData = {
      ...this.activeRoute.getRouteData<AnagRouteData>(),
      party: this.statelessService.deepClone(party),
      action: 'newParty',
      lastAction: 'keyData',
    };
    this.anagIntegrationService.openEditSubject(routeData);
  }

  goToResultFromHomonymy() {
    this.statelessOps.goToResultHomonymy(this.activeRoute);
  }

  editOrSelectSubject(row, name, stateMgrKeyData) {
    const currentState = this.getCurrentState();
    this.updateState$(this.statelessOps.findPartyAndGoTo$(of(currentState), this.activeRoute, row, name, stateMgrKeyData));
  }

  setTitleHomonymy() {
    this.i18nService.translate('_ANAG_TITLE_SEARCH_RESULTS_HOMONYMY').subscribe(title => {
      this.anagIntegrationService.changeTitle(this.activeRoute.id, title);
    }).unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Authorized } from './user-api';

/**
 * @description  Enum for the ACL intersection logic
 */
export enum AclInclusionType {
  ALL = 'ALL',
  PARTIAL = 'PARTIAL'
}

@Injectable({
  providedIn: 'root'
})
export class RgiRxUserAuthorizationService implements Authorized {

  private _authorizations = new BehaviorSubject<string[]>([]);

  constructor() { }

  isAuthorizedFor$(permission: string | string[], inclusionType: AclInclusionType = AclInclusionType.ALL): Observable<boolean> {
    if (typeof permission === 'string') {
      return this.getAuthorizations$().pipe(
        map(a => this.isAclIncluded(a, permission))
      );
    }
    return this.getAuthorizations$().pipe(
      map(list => {
        return this.isAclListIncluded(list, permission, inclusionType);
      })
    );
  }

  isAuthorizedFor(permission: string | string[], inclusionType: AclInclusionType = AclInclusionType.ALL): boolean {
    if (typeof permission === 'string') {
      return this.isAclIncluded(this._authorizations.getValue(), permission);
    }
    return this.isAclListIncluded(this._authorizations.getValue(), permission, inclusionType);
  }

  getAuthorizations$(): Observable<string[]> {
    return this._authorizations.asObservable();
  }

  getAuthorizations(): string[] {
    return this._authorizations.value;
  }

  putAuthorizations(...permissions: string[]) {
    const value = this._authorizations.getValue();
    this._authorizations.next(value.concat(...permissions.filter(p => value.indexOf(p) === -1)));
  }

  clear() {
    this._authorizations.next([]);
  }

  private isAclListIncluded(list: string[], permission: string[], inclusionType: AclInclusionType): boolean {
    const length = permission.filter(
      p => list.indexOf(p) > -1
    ).length;

    return (inclusionType === AclInclusionType.ALL) ? (length === permission.length) : (length > 0);
  }

  private isAclIncluded(a: string[], permission: string): boolean {
    return !!a.find(p => p === permission);
  }

}

/**
 * @deprecated use RgiRxUserAuthorizationService
 * @see RgiRxUserAuthorizationService
 */
export class UserAuthorizationService extends RgiRxUserAuthorizationService { }

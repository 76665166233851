import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CardService,
  DigitalClaimsCommonAngularModule,
  enumServiceProvider,
  getSystemPropertyProvider,
} from '@rgi/digital-claims-common-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ElaborationRequestComponent } from './elaboration-request.component';
import { ElaborationRequestSearchComponent } from './elaboration-request-search/elaboration-request-search.component';
// tslint:disable-next-line:max-line-length
import { ElaborationRequestListComponent } from './elaboration-request-single-page/elaboration-request-list/elaboration-request-list.component';
import { RgiRxModalModule } from '@rgi/rx/ui';
import { RgiRxPanelModule } from '@rgi/rx/ui';
// tslint:disable-next-line:max-line-length
import { ElaborationRequestDetailComponent } from './elaboration-request-single-page/elaboration-request-detail/elaboration-request-detail.component';
import { RgiRxFormElementsModule } from '@rgi/rx/ui';
// tslint:disable-next-line:max-line-length
import { ElaborationRequestSinglePageComponent } from './elaboration-request-single-page/elaboration-request-single-page.component';
// tslint:disable-next-line:max-line-length
import { NewElaborationRequestComponent } from './elaboration-request-single-page/new-elaboration-request/new-elaboration-request.component';
import { RgiRxI18nModule, RgiRxTranslationService } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../../assets/i18n/translations';

@NgModule({
  declarations: [
    ElaborationRequestComponent,
    ElaborationRequestSearchComponent,
    ElaborationRequestListComponent,
    ElaborationRequestDetailComponent,
    ElaborationRequestSinglePageComponent,
    NewElaborationRequestComponent,
  ],
  imports: [
    CommonModule,
    PortalNgCoreModule,
    NgSelectModule,
    DigitalClaimsCommonAngularModule,
    RgiRxI18nModule,
    RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
    [NgbModule],
    RgiRxModalModule,
    RgiRxPanelModule,
    RgiRxFormElementsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ElaborationRequestComponent,
    ElaborationRequestSearchComponent,
    ElaborationRequestListComponent,
    ElaborationRequestDetailComponent,
    ElaborationRequestSinglePageComponent,
    NewElaborationRequestComponent,
  ],
  providers: [
    {
      provide: 'elaborationRequestSearchComponent',
      useValue: ElaborationRequestSearchComponent,
    },
    {
      provide: 'elaborationRequestListComponent',
      useValue: ElaborationRequestListComponent,
    },
    {
      provide: 'elaborationRequestDetailComponent',
      useValue: ElaborationRequestDetailComponent,
    },
    {
      provide: 'elaborationRequestSinglePageComponent',
      useValue: ElaborationRequestSinglePageComponent,
    },
    {
      provide: 'newElaborationRequestComponent',
      useValue: NewElaborationRequestComponent,
    },
    enumServiceProvider,
    getSystemPropertyProvider,
  ],
})
export class ElaborationRequestModule {
  constructor(cardService: CardService, private rxTranslationsService: RgiRxTranslationService,) {
    // card invoice - start
    const title = '_CLAIMS_CONNECTOR';
    const moduleName = 'elaborationRequest';

    cardService.registerCard(
      moduleName,
      title,
      ElaborationRequestComponent,
      undefined,
      undefined,
      undefined,
      undefined,
      true,
      'Claims'
    );

    this.addSubcard(
      cardService,
      'Claims Connector',
      'ElaborationRequestSinglePage',
      'claimsFinder',
      ElaborationRequestSinglePageComponent
    );
  }

  private addSubcard(
    cardService: CardService,
    subCardTitle: string,
    subCardModuleName: string,
    subCardParentCardName: string,
    classN: any
  ) {
    const routes = [
      {
        path: 'action',
        route: {
          template:
            'coreportal/cardpass/partials/core-portal-integrationpass.tpl.html',
          controller: 'CardPassCtrl',
          label: 'Action',
        },
      },
    ];

    const communication = {
      reload: 'reload',
    };

    cardService.registerSubCard(
      subCardModuleName,
      subCardTitle,
      classN,
      subCardParentCardName,
      null,
      'ClaimsCommunicationCtrl',
      communication,
      routes,
      false,
      false
    );
  }
}

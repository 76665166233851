<ng-container *ngIf="summaryParties" data-qa="rgi-gp-parties">

  <div class="rgi-gp-grid-container">
    <div *ngFor="let singleParty of summaryParties; index as i">
      <label class="rgi-ui-form-control-label">
        <span class="rgi-ui-text-5" data-qa="rgi-gp-party-descr">{{singleParty.description}}</span>
        <span class="rgi-ui-text-5">:
              <b data-qa="rgi-gp-party-denomination">{{singleParty.companyName ? singleParty.companyName :
                (singleParty.name + ' ' + singleParty.surname + getPerc(singleParty.percentage))}}
              </b>
            </span>
      </label>
      <div *ngIf="summaryParties.length > 3 && i < (integerDiv(summaryParties.length)*3)">
            <hr class="rgi-gp-separator">
          </div>
        </div>
    </div>

</ng-container>

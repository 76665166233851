import { Serialized18n } from '@rgi/rx/i18n';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-postsales-card library.
 */
export const LPC_LIFE_PPEVO_ES: Serialized18n = {
 /* "Il Contraente è una persona": 'El titular es',
 "Data di nascita": 'Fecha de nacimiento',
 'La sua consistenza patrimoniale complessiva è:': 'Su patrimonio total es:',
 'Il suo reddito netto annuo è:': 'Su ingreso neto anual es:',
 'La sua capacità media di risparmio annuo, valutata… degli impegni finanziari già in essere è pari a:': 'Su capacidad de ahorro anual promedio, considerando los compromisos financieros existentes, es:',
 'I suoi impegni finanziari mensili sono:': 'Sus compromisos financieros mensuales son:',
 "E' intestatario di un mutuo o di un finanziamento?": '¿Es titular de una hipoteca o un préstamo?' */
}

import {AnagTranslationsKeys} from './anag-translations-keys';

// Please keep the translation keys alphabetically ordered
export const ANAG_DE: AnagTranslationsKeys = {
  _ANAG_: {
    _BTN_: {
      _ADD_: 'Neu',
      _ADD_ADDRESS_: 'Adressen',
      _BACK_: 'ZURÜCK',
      _CALCULATE_: 'Berechnen',
      _CANCEL_: 'Abbrechen',
      _CLOSE_: 'Schließen',
      _CONFIRM_: 'BESTÄTIGEN',
      _DETAIL_: 'Details',
      _EDIT_: 'BEARBEITEN',
      _EMPTY_: 'ZURÜCKSETZEN',
      _FIND_: 'Suchen',
      _NEW_: 'NEU',
      _NEW_PAYMENT_: 'Neue Zahlung',
      _NORMALIZE_: 'NORMALISIEREN',
      _SAVE_: 'SPEICHERN',
      _DELETE_: 'Löschen',
      _DELETE_DEFINITIVELY_: 'Endgültig löschen',
      _HISTORY_: 'Historie',
      _LIMITATION_MANAGEMENT_: 'Begrenzungsmanagement',
      _UNDO_: 'Aufheben',
      _CONTINUE_: 'WEITER'
    },
    _LABEL_: {
      _ACTIONS_: 'Vorgänge',
       _ACTION_TERRORISM_: 'Informationen zum Terrorismus',
      _ADDRESS_: 'Adresse',
      _AE_: 'AE',
      _AGENCY_: 'Agentur',
      _ATI_ASSOCIATIONS_: 'ATI-Verbände',
      _BIRTH_DATE_: 'Geburtstag',
      _BIRTH_PLACE_: 'Geburtsort',
      _BIRTH_SURNAME_: 'Geburtsname / Zweiter Nachname',
      _CITIZENSHIP_: 'Nationalität ',
      _CITY_OF_BIRTH_: 'Geburtsort',
      _CIVIL_STATE_: 'Familienstand',
      _CLIENT_NUMBER_: 'Kundennummer',
      _COMPANY_NAME_: 'Name / Firmenname',
      _CONTACTS_: 'Verträge',
      _CORPORATE_FORM_: 'Formular für Unternehmen',
      _CORPORATE_SECTOR_: 'Sektor des Unternehmens',
      _CORPORATION_: 'Gesellschaft',
      _COWORKER_: 'Co-Worker Steuernummer',
      _CREDIT_: 'Guthaben',
      _CRITERIA_: 'Kriterien',
      _DEBIT_: 'Verbindlichkeit',
      _DOCUMENT_: 'Dokument',
      _DOCUMENT_NO_: 'Nummer des Dokuments',
      _DOCUMENT_TYPE_: 'Typ des Dokuments',
      _EMAIL_: 'Email',
      _EMAIL2_: 'Email 2',
      _EMAIL3_: 'Email 3',
      _EXPIRATION_DATE_: 'Fälligkeitsdatum',
      _FAX_NO_: 'Fax-Nummer',
      _FEMALE_: 'Weiblich',
      _FILL_ID_DATA_: 'Identifikationsdaten ausfüllen',
      _FISCAL_CODE_: 'Steuernummer',
      _FISCAL_CODE_VAT_NUMBER_: 'Steuernummer / USt-IdNr.',
      _GENDER_: 'Geschlecht',
      _GENDER_IDENTITY_: 'Geschlechtsidentität',
      _IDENTIFICATION_DATA_: 'Daten zur Identifizierung',
      _INT_PREFIX_: 'Int. Vorwahl',
      _LANDLINE_NO_: 'Festnetznummer',
      _PHONE_NO_: 'Telefonnummer',
      _LEADER_: 'Anführer',
      _LINK_TYPE_: 'Art der Verbindung',
      _LINKED_PARTIES_: 'Verknüpfte Parteien',
      _MALE_: 'Männlich',
      _MAIN_CONTACT_: 'Hauptkontakt',
      _MANAGEMENT_NODE_: 'Verwaltungsknoten',
      _MOBILE_NO_: 'Handy-Nummer',
      _MOBILE_NO_2_: 'Handy-Nummer 2',
      _MUNICIPALITY_: 'Kommune',
      _NAME_: 'Vorname',
      _NATION_: 'Land',
      _NODES_: 'Knotenpunkte',
      _NO_ADDRESS_MSG_: 'Keine Adresse',
      _NUMBER_: 'Nummer',
      _OPEN_DATE_: 'Offener Termin',
      _PARTICIPANTS_: 'Teilnehmer',
      _PARTIES_: 'Partner',
      _PARTY_: 'Partner',
      _PARTY_DATA_: 'Daten der Parteien',
      _PARTY_MANAGEMENT_: 'Partner Verwaltung',
      _PARTY_MERGER_: 'Partner Zusammenführen',
      _PARTY_NUMBER_: 'Nummer der Partei',
      _PARTY_TYPE_: 'Partei Typ',
      _PAYMENT_: 'Zahlung',
      _PERSON_TYPE_: 'Person Typ',
      _PHYSICAL_: 'Physikalisch',
      _POTICALLY_EXP: 'Politisch exponierte Person',
      _PREFIX_: 'Vorsilbe',
      _PRIVACY_: 'Zustimmung zum Datenschutz',
      _PRIVACY_CONSENSUS_DATE_: 'Datum',
      _CHANGED_ON_: 'Geändert am',
      _PRODUCTION_NODES_: 'Produktionsknoten',
      _PROFESSION_: 'Beruf',
      _PROFESSION_DETAIL_: 'Beruf Detail',
      _REGISTERED_OFFICE_: 'Firmensitz',
      _RELEASE_AUTHORITY_: 'Freigabebehörde',
      _RELEASE_DATE_: 'Datum der Veröffentlichung',
      _RELEASE_LOCATION_: 'Freigabe Standort',
      _REQUIRED_FIELD_: 'Erforderlich',
      _RESIDENCE_: 'Wohnsitz',
      _ANTI_TERRORISM_: 'Anti-Terrorismus',
      _RESIDENCE_REGISTERED_OFFICE_: 'Wohnsitz / Geschäftssitz',
      _SAE_: 'SAE',
      _SALE_POINT_: 'Knoten',
      _SAVED_OK_: 'erfolgreich gespeichert',
      _SELECT_PARTY_: 'Partei wählen',
      _SEX_: 'Geschlecht',
      _STATUS_: 'State',
      _STATUS_NOT_PROCESSED_: 'Nicht verabeitet',
      _STATUS_PROCESSED_: 'Verarbeitet',
      _SUBJECT_: 'Partner',
      _SUBJECT_DATA_: 'Partnerdetails',
      _SUBJECT_TYPE_: 'Partner Typ',
      _SUBJECT_UNIQUE_KEY_: 'Partner IdNr.',
      _SUBJNO_: 'Party n.',
      _SUBSYSTEM_: 'Teilsystem',
      _SUBSYSTEM_PERSON_ID_: 'Teilsystem Personen-ID',
      _SURNAME_: 'Nachname',
      _SURNAMEORCOMPANY_: 'Name / Firmenname',
      _VOID_: ' ',
      _VAT_NUMBER_: 'Vat number',
      _DATE_MODIFIED_COUNTER_TERRORISM_INDICATOR_: 'Datum der Änderung des Antiterrorismus-Indikator',
      _COUNTER_TERRORISM_INDICATOR_: 'Anti-Terrorismus-Indikator',
      _DOMICILES_: 'Domizile',
      _DOMICILE_: 'Domizil',
      _PRINCIPAL_ADDRESS_: 'Hauptadresse',
      _AVAILABILITY_: 'Verfügbarkeit',
      _TYPE_: 'Typ',
      _AVAILABILITY_START_DATE_: 'Startdatum Erreichbarkeit',
      _AVAILABILITY_END_DATE_: 'Enddatum Erreichbarkeit',
      _AVAILABLE_FROM_: 'Uhrzeit Erreichbarkeit ab',
      _AVAILABLE_UNTIL_: 'Uhrzeit Erreichbarkeit',
      _INTERNATIONAL_DIALLING_CODE_: 'Internationale Vorwahl',
      _LOCAL_AREA_DIAL_CODE_: 'Vorwahl',
      _YES_: 'Ja',
      _NO_: 'Nein',
      _COUNTERTERRORISM_STATUS_: 'Anti-Terror-Staat',
      _COUNTERTERRORISM_INDICATOR_CHANGE_DATE_: 'Datum ändern Status Terrorismus',
      _ORIGIN_: 'Herkunft',
      _LANGUAGE_ : 'Zunge',
      _HONOR_TITLE_ : 'Ehrentitel',
      _SONS_: 'Anzahl der Kinder',
      _ROBINSON_LIST_: 'Robinsonliste',
      _CITIZENSHIP_PERMIT_: 'Aufenthaltsgenehmigung',
      _CITIZENSHIP_EXPIRE_: 'Wohnsitz gültig bis',
      _REG_COUNTRY_: 'Land der Registrierung',
      _EXTERNALS_IDENTIFIERS_: 'Externe Identifikatoren',
      _IDENTIFIERS_SUBJECT_SUBSYSTEM_ : 'Identifikatoren Betreff-Subsystem',
      _TAX_CLASSIFICATION_ : 'Steuerklassifikationen',
      _RISK_CLASSIFICATION_ : 'Risikoklassifizierung',
      _REASON_CLASSIFICATION_ : 'Grundklassifizierung',
      _BASIS_CLASSIFICATION_ : 'Basisklassifizierung',
      _CLASSIFICATION_DATE_ : 'Datum der Klassifizierung',
      _COUNTRY_START_DATE_ : 'Startdatum des Landes',
      _COUNTRY_END_DATE_ : 'Enddatum des Landes',
      _NO_TAX_CLASSIFICATIONS_ : 'Keine Steuerliche Einstufung',
      _COUNTRY_ : 'Land' ,
      _NOMINATIVE_ : 'Nominativ',
      _HISTORY_CONSULTATION_: 'Geschichtsberatung',
      _DO_NOT_REPLY_: 'Nicht gesetzt',
      _DRIVING_LICENSE_TYPE_: 'Führerscheintyp',
      _DRIVER_QUALIFICATION_CARD_: 'Fahrerqualifikationskarte',
      _ADD_DRIVING_LICENSE_TYPE_: 'Führerscheintyp hinzufügen',
      _PRIVACY_INFO_MSG_: 'Das Feld konsultiert die offiziellen Werbeausschlussdateien, die als Robinson-Liste (RL) bekannt sind, und teilt uns mit, ob der Kunde in der RL registriert ist oder nicht.\n' +
        '\n' +
        'Wenn die Robinson-Liste auf Ja gesetzt ist, können wir keine kommerziellen Kommunikationen senden.\n' +
        '\n' +
        'Wenn die Robinson-Liste auf Nein gesetzt ist, werden kommerzielle Kommunikationen nur gesendet, wenn das Feld Werbung ausschließen auf Nein gesetzt ist.\n' +
        '\n' +
        'Wenn der Kunde nicht in der RL konsultiert wurde, erscheint das Feld ohne Wert.',
      _LEI_CODE_: 'Rechtsträger-Identifikationsnummer',
      _RATING_: 'Wertung',
      _RATING_DATE_: 'Bewertungsdatum',
      _RATING_PERIOD_: 'Bewertungszeitraum',
      _RATING_USER_: 'Benutzer',
      _RATING_ORIGIN_: 'Herkunft',
      _RATING_HISTORY_: 'Bewertungsverlauf',
      _COWORKERS_: ' Mitarbeiter',
      _COMPANY_: 'Firma'
    },
    _MSG_: {
      _ASK_DELETION_CONFIRM_: 'Bestätigen Sie die Löschung des Betreffs?',
      _DELETION_CONFIRMED_: 'Der Betreff wurde korrekt gelöscht',
      _GENERAL_ERROR_MANDATORY_FIELDS_: 'Die rot markierten Felder sind Pflichtfelder bzw. der Inhalt ist fehlerhaft',
      _ERROR_DATE_: 'Proband unter 16 Jahren. Als Einwilligung zur Datenverarbeitung gilt die Einwilligung der Eltern/Erziehungsberechtigten',
      _ERROR_DATE_SP_: 'Einwilligung zur Verarbeitung personenbezogener Daten von Kindern unter 14 Jahren',
      _INVALID_DATE_VALUE: 'Ungültiges Datum',
      _INVALID_FIELD_: 'Ungültiges Feld',
      _LENGTHERR_: 'Feldlänge nicht erlaubt',
      _LINK_ERROR_: 'Partei bereits eingefügt',
      _LINK_ERROR_SAME_PARTY_: 'Unmöglich, eine Partei mit sich selbst zu verbinden',
      _NO_ELEMENT_TO_SHOW_: 'eine Einträge vorhanden',
      _NO_LINKED_PARTIES_: 'Keine Links',
      _NO_RESULTS_: 'Keine Ergebnisse',
      _NORMALIZED_ADDRESS_NOT_FOUND_: 'Keine normalisierte Adresse gefunden',
      _PATTERNERR_: 'Feld enthält falsche Zeichen',
      _REFINE_SEARCH_: 'Die Extraktion ist partiell. Bitte verfeinern Sie Ihre Suche',
      _SEARCH_FORM_MANDATORY_FIELDS_ERROR_: 'Geben Sie mindestens eine der folgenden Angaben ein: Nachname/Firmenname, Geburtsname/Zweiter Nachname, IdNr, Kundennummer, Vertragsnummer, Festnetznummer, gültige Postleitzahl oder Personen-ID des Teilsystems',
      _WRONG_INPUT_DATA_: 'Der Partner Verwaltung fließen wird mit nicht unterstützten Eingabewerten aufgerufen',
      _INVALID_END_DATE_DOMICILE_: 'Geben Sie für die Erreichbarkeit ein Enddatum nach dem entsprechenden Startdatum ein!',
      _INVALID_START_DATE_DOMICILE_: 'Geben Sie für die Erreichbarkeit ein Startdatum vor dem entsprechenden Enddatum ein!',
      _INHERITANCE_: 'Im Vertriebsnetz gibt es bereits eine Registrierung mit demselben eindeutigen Schlüssel. Klicken Sie auf „Bestätigen“, wenn Sie die Daten des bereits im Register registrierten Betreffs verwenden möchten. Andernfalls ist es nicht möglich, mit dieser Erweiterung des eindeutigen Schlüssels fortzufahren',
      _TAX_CLASSIFICATION_SAME_COUNTRY_TYPE_ERROR_ : 'Für das ausgewählte Land, die Art und den Zeitraum liegt bereits eine gültige Steuerklassifizierung vor',
      _INFO_HOMONYMIES_: 'Achtung! Es wurden gleichlautende Einträge gefunden.\n' +
        'Bitte wählen Sie zum Bearbeiten einen bestehenden Eintrag aus der Liste oder WEITER um den neuen Eintrag einzugeben.',
      _INFO_NATURAL_PERSON_ALLOWED_: 'Nur natürliche Personen erlaubt',
      _INFO_LEGAL_ENTITY_ALLOWED_: 'Nur juristische Personen zulässig',
      _EXPIRATION_DATE_BEFORE_RELEASE_: 'Das Veröffentlichungsdatum muss vor dem Ablaufdatum liegen',
      _IBAN_INVALID_: 'Falsches IBAN-Format',
      _EXPIRATION_DATE_AFTER_RELEASE_: 'Geben Sie ein Ablaufdatum nach dem Ausstellungsdatum des Dokuments ein',
      _ASK_DELETION_TAX_CLASSIFICATION_CONFIRM_: 'Löschung der aktuellen Steuerklassifizierung bestätigen?',
      _ASK_DELETION_DOCUMENT_CONFIRM_: 'Bestätigen Sie das Löschen des aktuellen Dokuments?',
      _DOC_ALREADY_EXIST: 'Belegnummer bereits vorhanden',
      _INSERT_DOC_TYPE: 'Geben Sie den Dokumenttyp ein, bevor Sie fortfahren.',
      _INVALID_FORMAT_: 'Ungültiges Format',
      _NORMALIZE_ERROR_MSG: 'Es ist zwingend erforderlich, die Adresse zu normalisieren'
    }
  },
  _ANAG_TITLE_EDIT_PARTY_: 'Bearbeiten Partner',
  _ANAG_TITLE_PARTY_DETAIL_: 'Partner Detail',
  _ANAG_TITLE_PARTY_MANAGEMENT_: 'Partner Verwaltung',
  _ANAG_TITLE_PARTY_ZOOM_: 'Partner Zoom',
  _ANAG_TITLE_SEARCH_RESULTS_: 'Partner Suche',
  _ANAG_TITLE_SEARCH_RESULTS_HOMONYMY: ''
};

import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {PaymentsField} from '../payments-data';
import {RgiCountryLayerValidators} from '@rgi/country-layer';


@Component({
  selector: 'mic-rgi-payment-fields',
  templateUrl: './payment-fields.component.html'
})
export class PaymentFieldsComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() fields: PaymentsField[] = [];
  private oldIban: string;

  constructor() {
  }

  ngOnInit() {
  }

  enableIban(name: string) {
    const formControl = this.form.get(name);
    if (formControl.value) {
      const field = this.fields.find(el => el.name === name);
      formControl.enable();
      this.oldIban = formControl.value;
      formControl.patchValue('');
      formControl.setValidators([RgiCountryLayerValidators.iban, field.mandatory ?  Validators.required : null]);
      this.form.updateValueAndValidity({ emitEvent: false });
    }
  }

  isFieldEnable(name: string) {
    const formControl = this.form.get(name);
    return formControl && formControl.enabled;
  }

  canRevert(name: string) {
    const formControl = this.form.get(name);
    return formControl && formControl.enabled && !!this.oldIban;
  }

  revertIban(controlName: string) {
    const formControl = this.form.get(controlName);
    if (this.oldIban) {
      formControl.patchValue(this.oldIban);
      formControl.setValidators(null);
      formControl.disable();
      this.form.updateValueAndValidity({emitEvent: false});
    }
  }
}

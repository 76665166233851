<div [formGroup]="formGroup" class="btn-fund" [ngClass]="{'btn-selected': selected}">
  <div [ngClass]="{'fund-description-full': !selected}" (click)="onSelectChange()">
    <div>{{name}}</div>
    <div>{{description}}</div>
  </div>
  <div [hidden]="!selected" class="input-container">
    <label class="switch-value">
      <input formControlName="id" [hidden]="true"/>
      <input formControlName="isPercent" [hidden]="true" />
      <input formControlName="profileId" [hidden]="true" />
      <input formControlName="name" [hidden]="true"/>
      <input formControlName="selected" [hidden]="true" type="checkbox" />
      <input formControlName="percent" *ngIf="isPercent" class="form-control" placeholder="%"/>
      <input formControlName="currency" *ngIf="!isPercent" class="form-control" placeholder="{{currencyCode}}"/>
      <input formControlName="minPercentage" [hidden]="true" />
      <input formControlName="maxPercentage" [hidden]="true"/>
      <input formControlName="readOnly" [hidden]="true"/>
    </label>
    <label class="switch">
      <input type="checkbox" [ngModel]="isPercent" [ngModelOptions]="{standalone: true}" class="form-control" (click)="changeSwitch($event)">
      <span class="slider" true="%" [attr.false]="currencyCode" ></span>
    </label>
  </div>
</div>

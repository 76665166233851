import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ActiveRoute, RoutableComponent} from '@rgi/rx/router';
import {GroupPolicyDateAdapter} from '../../adapters/ngbDateCustomParserFormatter ';
import {FACTOR_ACCORDION, FACTOR_PROP} from '../../group-policy-constants/factors';
import {CONTRACT_STATUS} from '../../group-policy-constants/general';
import {FactorAccordionConf} from '../../group-policy-models/group-policy-issue-configuration-pm';
import {DataForSteps} from '../../group-policy-models/group-policy-issue-home';
import {Factor} from '../../group-policy-models/group-policy-issue-policy-data';
import {GroupPolicyCrossService} from '../../group-policy-services/group-policy-cross.service';
import {GroupPolicyStateConfigurationPm} from '../../group-policy-state/group-policy-state';
import {
  GroupPolicyStatemanagerConfigurationPm
} from '../../group-policy-state/group-policy-statemanager-configuration-pm/group-policy-statemanager-configuration-pm';
import {GPVariableService} from '../../group-policy-services/gpvariable.service';

/**
 * @description: Component used for page configuration pm
 */
@Component({
  selector: 'rgi-gp-group-policy-configuration-pm',
  templateUrl: './group-policy-configuration-pm.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyConfigurationPmComponent extends RoutableComponent implements OnInit, OnDestroy {

  public stateConfigurationPm: GroupPolicyStateConfigurationPm;
  public tmpFactorsCode: Factor[] = [];
  public previousStepData: DataForSteps;
  public accordions: Array<FactorAccordionConf> = [];
  public contractStates = CONTRACT_STATUS;
  public configurationPmForm = new UntypedFormGroup({});

  constructor(protected activeRoute: ActiveRoute,
              protected dateAdapter: GroupPolicyDateAdapter,
              protected stateManagerConfigurationPm: GroupPolicyStatemanagerConfigurationPm,
              protected crossService: GroupPolicyCrossService,
              protected gpVariablesService: GPVariableService) {
    super();
  }

  ngOnInit(): void {
    this.previousStepData = this.activeRoute.getRouteData();
    this.stateManagerConfigurationPm.getState$()
      .subscribe((nextState: GroupPolicyStateConfigurationPm) => {
        this.initPage(nextState);
      });
  }

  protected initPage(nextState: GroupPolicyStateConfigurationPm) {
    this.stateConfigurationPm = nextState;
    if (this.stateConfigurationPm.factors) {
      this.accordions = [];
      if (this.configurationPmForm) {
        this.gpVariablesService.updateVariablesFormGroup(this.stateConfigurationPm.factors, this.configurationPmForm);
      } else {
        this.configurationPmForm = this.gpVariablesService.createVariablesFormGroup(this.stateConfigurationPm.factors);
      }
      this.mapAccordion(this.stateConfigurationPm.factors);
    }
  }

  ngOnDestroy(): void { }

  public updateFactors(variableToUpdate: Factor) {
    const variableControl = this.configurationPmForm.get(variableToUpdate.code);
    this.gpVariablesService.updateVariableInList(variableToUpdate, variableControl.value, this.stateConfigurationPm.factors);
    this.stateManagerConfigurationPm.actionUpdateFactors(this.previousStepData, this.stateConfigurationPm.factors);
  }

  public callActionGoToGuarantees() {
    this.configurationPmForm.markAllAsTouched();
    if (this.configurationPmForm.valid) {
      this.stateManagerConfigurationPm.actionGoToGuarantees(this.previousStepData);
    }
  }

  public callActionSave() {
    this.stateManagerConfigurationPm.actionSave(this.previousStepData);
  }

  public callActionBack(targetRoute?: string) {
    this.stateManagerConfigurationPm.actionBack(this.previousStepData, this.activeRoute, targetRoute);
  }

  protected mapAccordion(factors: Factor[]) {
    factors.forEach((singleFactor: Factor) => {
      if (singleFactor && singleFactor.properties) {
        singleFactor.properties.forEach(singleProp => {
          if (singleProp && singleProp.key === FACTOR_PROP) {
            const accordion = this.accordions.find(acc => acc.label === FACTOR_ACCORDION[singleProp.value]);
            if (!accordion) {
              this.accordions.push({
                label: FACTOR_ACCORDION[singleProp.value],
                variables: [singleFactor]
              });
            } else {
              accordion.variables.push(singleFactor);
            }
          }
        });
      }
    });
  }
  evalIntermediateSaveVisibility() {
    return this.crossService.isContractStatusUndefined(this.previousStepData);
  }

}

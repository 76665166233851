import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClaimLimitedDetailComponent } from './claim-limited-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import {
  CardService,
} from '@rgi/digital-claims-common-angular';
import { InvolvedPartyComponent } from './involved-party/involved-party.component';
import {  RgiRxPanelModule, RgiRxExpansionModule } from '@rgi/rx/ui';
import { AmountBarComponent } from './amount-bar/amount-bar.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';


@NgModule({
    declarations: [
        ClaimLimitedDetailComponent,
        InvolvedPartyComponent,
        AmountBarComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
        FormsModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        RgiRxPanelModule,
        RgiRxExpansionModule
    ],
    exports: [
        ClaimLimitedDetailComponent
    ]
})
export class ClaimLimitedDetailModule {
  constructor(cardService: CardService) {
    const title = '_CLAIM_DETAIL';
    const moduleName = 'claim-limited-detail';
    const communication = {
    };
    const routes = [{
      path: 'action', route: {
        template: 'coreportal/cardpass/partials/core-portal-integrationpass.tpl.html',
        controller: 'CardPassCtrl',
        label: 'Action'
      }
    }];
    const enumerates: string[] = [];

    cardService.registerCard(
      moduleName,
      title,
      ClaimLimitedDetailComponent,
    );

  }}

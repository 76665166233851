import {Component, ComponentRef, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {Modal} from '../../modal';
import {CoassContainer} from '../../models/domain-models/parameters/coass-container';
import {QuotaCoass} from '../../models/domain-models/quota-coass';
import {QuotaCoassList} from '../../models/domain-models/quota-coass-list';

@Component({
    selector: 'mic-coinsurance-quote-list',
    templateUrl: './coinsurance-quote-list.modal.component.html'
})
export class QuotaCoinsuranceListModalComponent implements OnInit, Modal {

    componentRef: ComponentRef<any>;
    coass: CoassContainer;
    coassQuotes: QuotaCoassList;
    createEditCoassEmitter: EventEmitter<QuotaCoass> = new EventEmitter();
    deleteCoassEmitter: EventEmitter<QuotaCoass> = new EventEmitter();
    @Output() modifiedCoass: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        protected formBuilder: UntypedFormBuilder
    ) {

    }

    ngOnInit(): void {

    }

    getCompany(quota: QuotaCoass) {

        const company = quota.companies.find(tmpCompany => String(tmpCompany.code) === String(quota.idCompany));
        return company ? company.description : '';
    }

    getIntermediary(quota: QuotaCoass) {

        const intermediary = quota.intermediaries.find(tmpIntermendiary => String(tmpIntermendiary.code) === String(quota.idIntermediary));
        return intermediary ? intermediary.description : '';
    }

    newQuoteCoass() {
        this.createEditCoassEmitter.emit(null);
    }

    editQuoteCoass(quota: QuotaCoass) {
        this.createEditCoassEmitter.emit(quota);
    }

    deleteQuoteCoass(quota: QuotaCoass) {
        this.deleteCoassEmitter.emit(quota);
    }

    cancel() {
      this.modifiedCoass.emit(true);
      this.componentRef.destroy();
    }
}

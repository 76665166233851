import {Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Variable} from '../../models/domain-models/variable';
import {VariablesService} from '../variables-service';
import {Modal} from '../../modal';
import {Subscription} from 'rxjs';
import {Message} from '../../models/message';
import {GISService} from '../../gis-service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'mic-variables-modal',
  templateUrl: './variables-modal.component.html',
  styleUrls: ['./variables-modal.component.scss']
})
export class VariablesModalComponent implements Modal, OnInit, OnDestroy {

  @Input() variablesType = null;
  @Input() variables: Variable[] = [];
  originVariables: Array<Variable>;
  variablesForm: UntypedFormGroup;
  variablesRows: Variable[][];
  componentRef: ComponentRef<any>;
  readyToBeClose = false;
  validationMessages: Message[] = [];
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  @Output() variablesOnInitValidation: EventEmitter<boolean> = new EventEmitter<boolean>();
  areaCode = 'VARIABLES_MODAL';

  protected subscriptions: Subscription = new Subscription();

  constructor(
    protected variablesService: VariablesService,
    protected gisService: GISService,
    protected translate: TranslateService) {
  }

  ngOnInit() {
    if (this.variablesType === null) {
      this.variablesType = this.variablesService.getLastSelectedVariablesType();
    }
    this.originVariables = JSON.parse(JSON.stringify(this.variablesService.getVariablesObject()));

    if (this.variables && this.variables.length > 0) {
      this.filterVariables(this.variables);
      this.loadVariables();
    } else {
      this.variablesService.getVariables().subscribe(
        (data) => {
          if (data) {
            this.filterVariables(data);
            this.loadVariables();
          }
        }
      );
    }

    /* Only at change of temporary variables. */
    const variablesChannelSubscription = this.variablesService.getVariablesObserable().subscribe(
      (data) => {
        if (data) {
          this.filterVariables(data);

          if (this.readyToBeClose) {
            this.componentRef.destroy();
          }
        }
      }
    );
    this.subscriptions.add(variablesChannelSubscription);
  }

  loadVariables() {

    this.variablesForm = this.variablesService.toFormGroup(this.variables);

    this.variables.forEach(variable => {

      if (variable.type === 4) {
        const variableValueChangesSubscription = this.variablesForm.get(variable.identificationCode).valueChanges.subscribe(
          (data) => {
            if (this.variablesForm.get(variable.identificationCode).errors
              && !this.variablesForm.get(variable.identificationCode).errors.required) {
              this.variablesForm.get(variable.identificationCode).setErrors(null);
            } else {
              this.updateErrorMessages();
            }
          }
        );
        this.subscriptions.add(variableValueChangesSubscription);
      } else {
        this.updateErrorMessages();
      }
    });


    const variablesNum = this.variables.length;

    if (variablesNum === 0) {
      return;
    }

    this.variables.sort(
      (a: Variable, b: Variable) => {
        if (a.order > b.order) {
          return 1;
        } else if (a.order < b.order) {
          return -1;
        } else {
          return 0;
        }
      }
    );

    this.variablesRows = new Array<Array<Variable>>(Math.ceil(variablesNum / 2));

    let i: number;
    let j = 0;

    for (i = 0; i < variablesNum; i++) {
      if (i !== 0 && i % 2 === 0) {
        j++;
      }
      if (!this.variablesRows[j]) {
        this.variablesRows[j] = [];
      }
      this.variablesRows[j].push(this.variables[i]);
    }

    if (this.variablesForm.invalid) {
      this.variablesOnInitValidation.emit(false);
    }

  }

  onSubmit() {
    this.updateErrorMessages();

    if (this.variablesForm.valid) {
      this.onVariableChange('confirm');
    }
  }

  updateErrorMessages() {
    this.validationMessages.length = 0;

    for (const variable of this.variables) {
      const variableFormControl = this.variablesForm.get(variable.identificationCode);
      const variableDescription = variable.extendedDescription || variable.description;

      if ((variableFormControl.errors && variableFormControl.errors.required) ||
        (variable.compulsory && ((!variableFormControl.value && variableFormControl.value !== 0) || variableFormControl.value === '-1'))) {
        this.validationMessages.push(new Message(this.areaCode, variableDescription + ' ' + this.translate.instant('is required')));
      } else if (variableFormControl.invalid) {
        this.validationMessages.push(new Message(this.areaCode, variableDescription + ' ' + this.translate.instant('is invalid')));
      } else {
        variableFormControl.setErrors(null);
      }
    }
  }

  close() {
    this.updProvinceVariableValue(this.originVariables);

    /* reset the initial values */
    this.getUpdateVariablesObservable(this.originVariables).subscribe(
      data => {
        this.readyToBeClose = true;
        this.variablesService.setVariables(data);
      },
      err => {
        this.componentRef.destroy();
      });
  }

  onVariableChange(event: string) {
    this.updateErrorMessages();

    if (event === 'updateProvinceVariable') {
      this.updProvinceVariableValue(this.variables);
      return;
    }

    if (event !== 'confirm'
      && event !== 'updateDependentVariable' && event !== 'updateProvinceVariable') {
      return;
    }

    this.getUpdateVariablesObservable(this.variables).subscribe(
      (data) => {
        if (event === 'confirm') {
          this.readyToBeClose = true;
          this.variablesService.setVariables(data);
          return;
        }
        this.filterVariables(data);
        this.loadVariables();
      },
      (err) => {
        if (event === 'confirm') {
          this.componentRef.destroy();
        }
      }
    );
  }

  variablesTrackByFn(index, variable: Variable) {
    return variable.code;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  protected filterVariables(data) {
    this.variables = data.filter(
      (variable) => {
        if (!this.variablesType) {
          return true;
        } else {
          if (this.variablesType === 'ASSET') {
            return variable.visibilityArea === 0 || variable.visibilityArea === 1
              || variable.visibilityArea === 4;
          } else if (this.variablesType === 'PARTY') {
            return variable.visibilityArea === 2;
          } else if (this.variablesType === 'EXTRA') {
            return variable.visibilityArea === 3;
          } else {
            return false;
          }
        }
      }
    );
  }

  protected getUpdateVariablesObservable(variables: Array<Variable>) {
    return this.variablesService.updateVariables(variables);
  }

  protected updProvinceVariableValue(variables: Array<Variable>) {
    const provinces = this.gisService.getProvinces();


    variables.forEach(variable => {
      if (variable.variableClass === 8) {
        let province = provinces.find(prov =>
          prov.code === variable.value);

        if (!province) {
          province = provinces.find(prov =>
            prov.id === parseInt(variable.value, 10));
        }
        let provinceCode;
        if (province) {
          provinceCode = province.code;
        } else {
          provinceCode = null;
        }
        variable.value = provinceCode;
      }
    });
  }

}

import { NgModule } from '@angular/core';
import { ChatComponent } from './chat.component';
import { BrowserModule } from '@angular/platform-browser';
import { ChatComponentsModule } from './components/chat.components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  CardService,
  containerCacheServiceProvider,
  authServiceProvider
} from '@rgi/digital-claims-common-angular';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';
@NgModule({
    declarations: [
        ChatComponent
    ],
    imports: [
        BrowserModule,
        ChatComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS)
    ],
    exports: [
        ChatComponent
    ],
    providers: [
        containerCacheServiceProvider,
        authServiceProvider
    ]
})

export class ChatModule {
  constructor(cardService: CardService) {
    const title = '_CHAT';
    const moduleName = 'chat';
    const moduleNameSub = 'chatSubCard';
    const parentCardName = 'claimsFinder';

    cardService.registerCard(
      moduleName,
      title,
      ChatComponent
    );

    cardService.registerSubCard(
      moduleNameSub,
      title,
      ChatComponent,
      parentCardName
    );

  }
}

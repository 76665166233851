import { Factor } from './factor';
import { EnumType } from '../enum-type';

export class DamageFactor {
    public factor: Factor;
    public dateValue: Date;
    public numberValue: number;
    public stringValue: string;
    public type: EnumType;
    public factorType: string;
    public descriptionListValue: string;
    public format: string;
    public value: string;

    // TODO: check @Inject(LOCALE_ID) private locale: string
    constructor() {
    }
}

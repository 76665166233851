import {
  ICoreResult, PortalCard,
  PortalCardConfig,
  PortalCards,
  PortalConfig,
  portalControllerFactory,
  RGI_RX_PORTAL_TPL
} from '@rgi/rx/portal';
import {ActiveRoute, RoutingService} from '@rgi/rx/router';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {
  ContractConsultationFieldConfigurationService
} from '@rgi/contract-consultation-card';
import {
  FIELD,
  TABGROUP_CC_LIMITATIONS,
  FIELD_CC_APPLICATIONNUMBER,
  FIELD_CC_BRANCHOFFICE,
  TABGROUP_CC_BRANCH, FIELD_CC_COMPANY, FIELD_CC_CONTRACTCONDITION, FIELD_CC_CONTRACTSTATES, FIELD_CC_CONTRACTSUBSTATES,
  FIELD_CC_EFFECTIVEDATE, FIELD_CC_EXPIRATIONDATE,
  FIELD_CC_INDIPENDENTAGENT, FIELD_CC_ISSUEDATE,
  FIELD_CC_NUMBERPLATE,
  FIELD_CC_POLICYNUMBER,
  FIELD_CC_PRODUCT, FIELD_CC_PRODUCTTYPE,
  FIELD_CC_PROPOSALNUMBER, FIELD_CC_QUOTENUMBER,
  FIELD_CC_SHIPNAME,
  FIELD_CC_SUBJECT,
  TABGROUP_CC_SUBTYPE,
  FIELD_CC_TAB_CONTRACTTYPE,
  TABGROUP_CC_TYPE,
  FIELD_CC_TAB_ANONYMIZED,
  FIELD_CC_TAB_NOT_ANONYMIZED,
  FIELD_CC_TABALL, FIELD_CC_TABAPPLICATION, FIELD_CC_TABCOLLECTIVE, FIELD_CC_TABINDIVIDUAL,
  FIELD_CC_TABLIFE, FIELD_CC_TABMASTERPOLICY,
  FIELD_CC_TABPC
} from '@rgi/contract-consultation-card';


export const CONTRACT_CONSULTATION_CARD: PortalCardConfig =

    {
        card: {
            name: 'contractConsultationCard',
            type: 'main',
            category: 'Contract Management',
            title: 'CONTRACT_CONSULTATION_CARD_TITLE',
            navigationInSession: true,
            subCards: [],
            customize: {
              enabled: true,
              hideHeader: false,
              hideContainerBody: false,
              menuVoiceAdvancedSearch: true,
              fields: [TABGROUP_CC_BRANCH, TABGROUP_CC_TYPE, TABGROUP_CC_SUBTYPE,
                TABGROUP_CC_LIMITATIONS, FIELD_CC_COMPANY, FIELD_CC_INDIPENDENTAGENT, FIELD_CC_BRANCHOFFICE, FIELD_CC_TAB_CONTRACTTYPE,
                FIELD_CC_POLICYNUMBER, FIELD_CC_APPLICATIONNUMBER, FIELD_CC_PROPOSALNUMBER, FIELD_CC_PRODUCTTYPE, FIELD_CC_PRODUCT,
                FIELD_CC_NUMBERPLATE, FIELD_CC_SHIPNAME, FIELD_CC_ISSUEDATE, FIELD_CC_EFFECTIVEDATE, FIELD_CC_EXPIRATIONDATE,
                FIELD_CC_CONTRACTCONDITION, FIELD_CC_SUBJECT, FIELD_CC_CONTRACTSTATES, FIELD_CC_CONTRACTSUBSTATES,
                ]
            }
        } as PortalCard,
        routes: [
            {
                type: 'home',
                route: 'home',
                label: 'CONTRACT_CONSULTATION_CARD_TITLE',
                destination: 'consultation-start-card',
                controller: HomeController,
                template: RGI_RX_PORTAL_TPL
            },
            {
                route: 'results',
                label: 'CONTRACT_CONSULTATION_CARD_TITLE',
                destination: 'consultation-results'
            }/*,
            {
                route: 'detail',
                label: 'CONTRACT_CONSULTATION_CARD_TITLE',
                destination: 'consultation-detail'
            }*/,
            {
              route: 'customize',
              template: 'coreportal/card/directives/rgiCard-customize.tpl.html',
              label: 'Customize - Contract Consultation Finder',
              destination: ''
            }
        ]

    }
;
export const CONTRACT_CONSULTATION_CARDS: PortalCards = [CONTRACT_CONSULTATION_CARD];


function addConfiguredField($scope, contractConsultationFieldConfigurationService: ContractConsultationFieldConfigurationService, field: FIELD) {
  if ($scope[field.name]) {
    contractConsultationFieldConfigurationService.addField($scope.$$id, $scope[field.name]);
  }
}

HomeController.$inject = ['$scope', '$controller', 'coreResult', 'RgiRxRoutingService', 'RgiRxTranslationService', 'ContractConsultationFieldConfigurationService'];

// @ts-ignore
export function HomeController($scope, $controller, coreResult: ICoreResult, rgiRxRoutingService: RoutingService, rgiRxTranslateService: RgiRxTranslationService, contractConsultationFieldConfigurationService: ContractConsultationFieldConfigurationService) {
  portalControllerFactory('consultation-start-card', 'home').apply(this, arguments); // apply the portalControllerFactory
  $scope.onRouteChange = (_activeRoute: ActiveRoute) => {

  };
  $scope.$on('$destroy', () => {
    contractConsultationFieldConfigurationService.clearField($scope.$$id);
  });

  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_TABPC);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_TABLIFE);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_TABALL);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, TABGROUP_CC_BRANCH);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_TABINDIVIDUAL);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_TABCOLLECTIVE);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, TABGROUP_CC_TYPE);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_TABMASTERPOLICY);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_TABAPPLICATION);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, TABGROUP_CC_SUBTYPE);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_TAB_NOT_ANONYMIZED);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_TAB_ANONYMIZED);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, TABGROUP_CC_LIMITATIONS);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_INDIPENDENTAGENT);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_SHIPNAME);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_NUMBERPLATE);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_PROPOSALNUMBER);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_POLICYNUMBER);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_APPLICATIONNUMBER);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_QUOTENUMBER);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_BRANCHOFFICE);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_PRODUCTTYPE);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_PRODUCT);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_CONTRACTCONDITION);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_SUBJECT);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_ISSUEDATE);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_EFFECTIVEDATE);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_EXPIRATIONDATE);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_CONTRACTSTATES);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_CONTRACTSUBSTATES);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_COMPANY);
  addConfiguredField($scope, contractConsultationFieldConfigurationService, FIELD_CC_TAB_CONTRACTTYPE);

  $scope.myCustomData = {};
}
export function angularGridInstanceFactory(injector: any) {
  return injector.get('angularGridInstance');
}

export const angularGridInstance = {
  provide: 'angularGridInstance',
  useFactory: angularGridInstanceFactory,
  deps: ['$injector']
};



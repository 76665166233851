import { Component, OnInit, Output, EventEmitter, Inject, Input } from '@angular/core';
import { RoutableComponent } from '@rgi/portal-ng-core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  ApiPolicyFilter, ApiEnumType, Policy, PolicyService, ApiKeyValue, Unit, CardComponentService, FunctionService
} from '@rgi/digital-claims-common-angular';
import { NgForm, UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { ClaimOpeningService } from '../../claim-opening.service';
import { DamageCategory } from './dto/damage-categories';
import { DamageCategoryService } from '../../damage-category.service';
import { TimeRequest } from './dto/time-request';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'claims-claim-opening-policy-search',
  templateUrl: './claim-opening-policy-search.component.html',
  styleUrls: ['./claim-opening-policy-search.component.scss']
})
export class ClaimOpeningPolicySearchComponent implements RoutableComponent, OnInit {
  private static PROPERTY_LEGAL_PROTECTION_MANAGEMENT: string = 'ClaimsLegalProtectionManagement';
  private static PROPERTY_ENABLED: string = 'Enable';
  today: NgbDate;
  salesPointCode: number;
  salesPointId: number;
  companyCode: string;
  errorMessage: string;
  time: TimeRequest;
  selectedCategory: DamageCategory;
  isPolicyNumberFieldEnabled: boolean;

  emitter: any;
  session: any;
  @Input() public card: any;

  damageCategories = null;

  searchPolicyForm: UntypedFormGroup;
  submitted = false;

  constructor(
    @Inject('authService') auth: any,
    @Inject('eventService') eventService: any,
    @Inject('sessionService') session: any,
    @Inject('angularGridInstance') private gridInstance: any,
    @Inject('getSystemProperty') private getSystemProperty: any,
    private policyService: PolicyService,
    private claimOpeningService: ClaimOpeningService,
    private damageCategoryService: DamageCategoryService,
    public formBuilder: UntypedFormBuilder,
    private cardComponentService: CardComponentService,
    private functionService: FunctionService,
    private rxTranslationsService: RgiRxTranslationService
    ) {
    const today = new Date();
    this.today = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
    this.salesPointCode = auth.getOperator().salePoint.code;
    this.salesPointId = auth.getOperator().salePoint.objectId;
    this.companyCode = auth.getOperator().salePoint.company ? auth.getOperator().salePoint.company.code : null;
    this.initTime();
    this.emitter = eventService;
    this.session = session;
    this.isPolicyNumberFieldEnabled = this.functionService.isAuthorizedForClaimFeature('POLICYNUMBER_FNOL');

  }

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    this.damageCategoryService.getCategories().subscribe(
      (response: Response) => {
        this.damageCategories = response;
        this.gridInstance.cards.refresh();
        this.defaultValueRadioEvent();
      },
      (error: Error) => {
        console.log(error);
      }
    );
    this.searchPolicyForm = this.formBuilder.group({
      occurrenceDate: [, Validators.required],
      occurrenceTime: [this.time],
      plate: [],
      policynumber: [],
      cat: [, Validators.required]
    }, {
      validator: [this.validateOccurrenceDate('occurrenceDate')]
    });

  }

  protected defaultValueRadioEvent() {
    this.getSystemProperty.findProperty(ClaimOpeningPolicySearchComponent.PROPERTY_LEGAL_PROTECTION_MANAGEMENT).then((result) => {
      if (result && result.systemProperties && result.systemProperties.length) {
        for (const variable of result.systemProperties) {
          if (variable.key === ClaimOpeningPolicySearchComponent.PROPERTY_LEGAL_PROTECTION_MANAGEMENT) {
            if (variable.value && ClaimOpeningPolicySearchComponent.PROPERTY_ENABLED === variable.value) {
              this.searchPolicyForm.controls.cat.setValue(3);
              this.damageCategories.forEach(element => {
                if (element.idCategory === 3) {
                  this.selectedCategory = element;
                }
              });
            }
            break;
          }
        }
      }
    });
  }

  validateOccurrenceDate(controlName: string) {

    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];

      const min: Date = new Date(1990, 1, 1);

      if (control.value) {
        if (control.value instanceof Date) {

          const inputDate = control.value;
          inputDate.setHours(0, 0, 0, 0);
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
          if (inputDate > currentDate) {
            control.setErrors({ futureDate: true });
          } else if (inputDate < min) {
            control.setErrors({ tooOld: true });
          } else {
            control.setErrors(null);
          }
        } else {
          control.setErrors({ invalidDate: true });
        }
      } else {
        const validator: any = control.validator && control.validator(new UntypedFormControl());

        if (validator && validator.required) {
          control.setErrors({ required: true });
        } else {
          control.setErrors(null);
        }
      }
    };
  }

  goToSearch() {
    this.navigation.emit('search');
  }

  isMissingPlateAndPolicyNumber() {
    if (!this.submitted) {
      return false;
    }

    return !this.searchPolicyForm.controls.plate.value &&
      !(this.searchPolicyForm.controls.policynumber && this.searchPolicyForm.controls.policynumber.value);
  }

  validatePlateAndPolicyNumber() {
    if (!this.searchPolicyForm.controls.plate.value &&
      !(this.searchPolicyForm.controls.policynumber && this.searchPolicyForm.controls.policynumber.value)) {
      this.searchPolicyForm.controls.plate.setErrors({ incorrect: true });
      if (this.searchPolicyForm.controls.policynumber) {
        this.searchPolicyForm.controls.policynumber.setErrors({ incorrect: true });
      }
    }
  }

  goToSearchResults(event: any) {
    this.errorMessage = null;
    this.submitted = true;
    this.searchPolicyForm.controls.policynumber.updateValueAndValidity();
    this.searchPolicyForm.controls.plate.updateValueAndValidity();
    this.searchPolicyForm.controls.occurrenceDate.markAsDirty();
    this.searchPolicyForm.controls.plate.markAsDirty();
    this.searchPolicyForm.controls.cat.markAsDirty();
    this.validatePlateAndPolicyNumber();
    if (this.isMissingPlateAndPolicyNumber()) {
      const observable1$ = this.rxTranslationsService.translate('_CLAIM._MESSAGE_._INSERT_POLICY_NUMBER_OR_PLATE');
      const observable2$ = this.rxTranslationsService.translate('_CLAIM._FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES');
      combineLatest([observable1$, observable2$]).subscribe(
        ([result1, result2]) => {
          this.errorMessage = this.isPolicyNumberFieldEnabled ? result1 :
          result2;
        }
      )
    
      this.gridInstance.cards.refresh();
      return;
    }

    if (this.searchPolicyForm.invalid) {
      this.rxTranslationsService.translate('_CLAIM._FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES')
      .subscribe( res => this.errorMessage = res )
      this.gridInstance.cards.refresh();
      return;
    }
    this.gridInstance.cards.refresh();
    this.emitter.broadcastEvent('start-loader');
    const req = {
      filterPolicies: null
    };
    const filter = new ApiPolicyFilter();
    filter.contractType = 1;
    filter.searchParentNodes = true;
    filter.salePointCode = this.salesPointCode;
    filter.salePointId = this.salesPointId;
    filter.contractTodayEffectDate = false;
    filter.categories = [new ApiEnumType('1', 'Danni Auto'), new ApiEnumType('2', 'Rami Elementari'), new ApiEnumType('6', 'Trasporti')];
    filter.companyId = this.companyCode;
    const occurrenceDate = this.searchPolicyForm.controls.occurrenceDate.value;
    filter.effectDateTo = occurrenceDate;
    filter.expirationDateFrom = occurrenceDate;
    filter.states = [new ApiEnumType('11', '')];
    filter.numberPlate = this.searchPolicyForm.controls.plate.value ?
      this.searchPolicyForm.controls.plate.value.toUpperCase() : '';
    filter.policyNumber = this.searchPolicyForm.controls.policynumber && this.searchPolicyForm.controls.policynumber.value ?
      this.searchPolicyForm.controls.policynumber.value.toUpperCase() : '';
    filter.extension = { properties: [new ApiKeyValue('GET_INFO_SX', '1')] };
    req.filterPolicies = filter;

    let policiesRes = [];
    const policies: Policy[] = [];
    this.policyService.getPoliciesList(req)
      .subscribe((response: any) => {
        policiesRes = response.policies;
        if (policiesRes && policiesRes.length > 0) {
          policiesRes.forEach(policyRes => {
            const policy = new Policy();
            policy.number = policyRes.policyNumber;
            policy.description = policyRes.description;
            policy.effectDate = policyRes.effectDate;
            policy.expirationDate = policyRes.expirationDate;
            policy.holder = policyRes.contractingSubject;
            policy.objectId = policyRes.objectId;
            policy.product = policyRes.product;
            policy.nodeCode = policyRes.nodeCode;
            policy.originalEffectDate = policyRes.originalEffectDate;
            if (policyRes.extension) {
              policy.units = this.getUnits(policyRes.extension.properties);
              policy.claimBranchCodes = this.getClaimBranchCodes(policyRes.extension.properties);
            }
            policies.push(policy);
          });
          this.claimOpeningService.setPolicies(policies);
          const sessionId = this.session.open('claimsClaimOpening', 'home', '', true);
          this.claimOpeningService.setSessionPolicies(sessionId, policies);
          this.claimOpeningService.setSessionOccurrenceDate(sessionId, occurrenceDate);
          this.claimOpeningService.setSessionTimeRequest(sessionId,
            this.createTimeRequest(this.searchPolicyForm.controls.occurrenceTime.value.hour,
              this.searchPolicyForm.controls.occurrenceTime.value.minute));
          this.claimOpeningService.setCategory(sessionId, this.selectedCategory);
          if (this.searchPolicyForm.controls.plate && this.searchPolicyForm.controls.plate.value) {
            this.claimOpeningService.setSessionPlate(sessionId, this.searchPolicyForm.controls.plate.value.toUpperCase());
          }
        } else {
          this.rxTranslationsService.translate('_CLAIM._MESSAGE_._NO_RESULTS')
          .subscribe( res => this.errorMessage = res )
        }
        this.emitter.broadcastEvent('stop-loader');
      },
        (error: Error) => {
          console.log(error);
          this.rxTranslationsService.translate('_SEARCH_ERROR')
          .subscribe( res => this.errorMessage = res )
          this.emitter.broadcastEvent('stop-loader');
        }
      );
  }

  goToDetails() {
    this.navigation.emit('details');
  }

  emptyFields() {
    this.searchPolicyForm.reset();
    this.initTime();
    this.searchPolicyForm.controls.occurrenceTime.setValue(this.time);
  }

  initTime() {
    this.time = new TimeRequest();
    this.time.hour = 0;
    this.time.minute = 1;

  }

  createTimeRequest(hour: number, minute: number): TimeRequest {
    const time = new TimeRequest();
    time.hour = hour;
    time.minute = minute;
    return time;
  }

  onSelectionChange(category: DamageCategory) {
    this.selectedCategory = category;
  }

  getUnits(properties: ApiKeyValue[]): Unit[] {
    const units: Unit[] = [];
    properties.forEach(property => {
      if (property.chiave === 'DESCR_UNIT') {
        const descriptions = property.valore.split('#');
        descriptions.forEach(description => {
          const unit = new Unit();
          unit.description = description;
          units.push(unit);
        });
      }
    });
    return units;
  }

  getClaimBranchCodes(properties: ApiKeyValue[]): string[] {
    let codes: string[];
    properties.forEach(property => {
      if (property.chiave === 'RAMI_SX') {
        codes = property.valore.split('#');
      }
    });
    return codes;
  }

  goToNewPayment(event: any) {
    const sessionId = this.session.open('claimsNewPayment', 'home', '', true);
  }

}

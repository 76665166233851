export class ContractorDataRequest {
  additionalLabels: any;
  id: number;
  snapshotId: number;
  uuid: string;
  extensions: any;

  constructor() {
  }
}

import {Component, forwardRef, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';
import {PlcDateUtils} from '../../../utils/plc-date-utils';
import {LocaleDate} from '../../../models/locale-date';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-karma-datepicker',
  templateUrl: './lpc-karma-datepicker.component.html',
  styleUrls: ['./lpc-karma-datepicker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcKarmaDatepickerComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class LpcKarmaDatepickerComponent implements OnInit, OnDestroy, ControlValueAccessor {

  private subscription: Subscription;

  public formGroup: UntypedFormGroup;

  @Input() public id: string;
  @Input() public label: string;
  @Input() public required = false;
  @Input() public value: Date;
  @Input() public min: Date | string;
  @Input() public max: Date | string;
  @Input() public disabled = false;

  get minLocaleDate(): LocaleDate {
    if (typeof this.min === 'string') {
      return PlcDateUtils.isoToLocaleDate(this.min);
    }
    return PlcDateUtils.dateToLocaleDate(this.min);
  }

  get maxLocaleDate(): LocaleDate {
    if (typeof this.max === 'string') {
      return PlcDateUtils.isoToLocaleDate(this.max);
    }
    return PlcDateUtils.dateToLocaleDate(this.max);
  }

  get minDate(): Date {
    if (typeof this.min === 'string') {
      return PlcDateUtils.isoToDate(this.min);
    }
    return this.min;
  }

  get maxDate(): Date {
    if (typeof this.max === 'string') {
      return PlcDateUtils.isoToDate(this.max);
    }
    return this.max;
  }

  public get dateLabel(): string {
    return this.label ? this.translate.getImmediate(this.label) : EMPTY_STR;
  }

  constructor(protected translate: TranslationWrapperService) {
  }

  onChange = (obj: string) => {
  }

  onTouched = () => {
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      date: new UntypedFormControl()
    });

    this.subscription = this.formGroup.get('date').valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe((date) => {
      this.onChange(PlcDateUtils.dateToString(date));
      /* if (this.isValidDate(date)) {
        this.writeValue(date);
        this.onChange(date);
      } else if (date === null) {
        this.writeValue(null);
        this.onChange(null);
      } */
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({emitEvent: false});
    } else {
      this.formGroup.enable({emitEvent: false});
    }
    this.disabled = isDisabled;
  }

  writeValue(val: string): void {
    const value: Date = PlcDateUtils.isoToDate(val);
    /* const isDate: boolean = (val instanceof Date);
    if (isDate) {
      val.setHours(0, 0, 0, 0);
    }
    const aboveMin: boolean = !this.minDate || (this.minDate && val > this.minDate);
    const belowMax: boolean = !this.maxDate || (this.maxDate && val < this.maxDate);
    if (!(aboveMin && belowMax)) {
      val = null;
    } */
    this.formGroup.get('date').setValue(value, {emitEvent: false});
  }

  isValidDate(val: Date): boolean {
    return (val instanceof Date) && val.getFullYear() > 999;
  }
}

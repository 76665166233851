import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StickableCheckerDirective} from './stickable-checker.directive';


@NgModule({
  declarations: [StickableCheckerDirective],
  imports: [
    CommonModule
  ],
  exports: [StickableCheckerDirective],
  providers: []
})
export class RgiRxStickableModule {
}


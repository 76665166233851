export class RgiRxVirtualDOMError extends Error {
  constructor(message: string) {
    super(`RgiRx Virtual DOM error: ${message}`);
  }
}


export class RgiRxRuntimeError extends Error {
  constructor(message: string) {
    super(`RgiRxRuntimeError: ${message}`);
  }
}


export type RgiRxSemanticStatus = 'default' | 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info';

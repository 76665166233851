import {Directive, OnDestroy, ViewContainerRef} from '@angular/core';
import {Subject} from 'rxjs';

@Directive({
  selector: '[rgiRxRoutingHostDirective]'
})
export class RgiRxRoutingHostDirective implements OnDestroy {

  _onTickChange = new Subject<void>();

  constructor(public viewContainerRef: ViewContainerRef) {
  }

  ngOnDestroy(): void {
    this._onTickChange.complete();
  }
}

import {CommissionsElement} from './commissions-element';

export class CommissionTable {
  constructor(
    public subscription: Array<CommissionsElement>,
    public nextInstallment: Array<CommissionsElement>,
    public status: number,
    public updateAmount: boolean,
    public derogate: boolean
  ) {
  }
}

import { Input, Component, OnInit, OnDestroy, ComponentRef, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Modal } from '../../modal';
import { Metadata } from '../metadata';
import { DocumentsService } from '../documents.service';
import { DocumentFile } from '../document-file';
import { Document } from '../document';
import { LoaderService } from '@rgi/portal-ng-core';
import { ConfMetadata } from '../conf-metadata';

@Component({
  selector: 'pcm-document-upload-file-modal',
  templateUrl: './document-upload-file-modal.component.html',
  styleUrls: ['./document-upload-file-modal.component.css']
})
export class DocumentUploadFileModalComponent implements OnInit, Modal, OnDestroy {

  @Input() title: string;
  @Input() contractId: string;
  @Input() document: Document;
  @Input() file: DocumentFile;
  @Input() bUploadFile: boolean;
  @Input() arInpDocValidation: Array<any>;
  @Input() maxKBFileSize = 5020;
  @Input() fileTypes: Array<string>;

  @Output() eventModifiedDocument: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  upFileForm: UntypedFormGroup;
  componentRef: ComponentRef<any>;
  stateSubscription: Subscription;
  fileData: File = null;
  fileFormat = ['.pdf'];
  arrayBuffer: any;
  validationMessages: string[] = [];
  submitted = false;

  constructor(
    protected loaderService: LoaderService,
    protected documentsService: DocumentsService,
    protected http: HttpClient
  ) { }


  ngOnInit() {
    this.title = this.componentRef.instance.title;
    this.contractId = this.componentRef.instance.contractId;
    this.document = this.componentRef.instance.document;
    this.file = this.componentRef.instance.file;
    this.maxKBFileSize = this.componentRef.instance.maxKBFileSize;
    this.fileTypes = this.componentRef.instance.fileTypes;
    if (this.fileTypes) {
      this.fileFormat = this.fileTypes;
    }
    this.upFileForm = this.toFormGroup(this.document.confMetadataList, this.file.metadataList);
  }

  ngOnDestroy(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

  close() {
    this.componentRef.destroy();
  }

  searchAttachment() {
  }

  fileProgress(event) {
    let fileIn = event[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      this.arrayBuffer = reader.result;
    };

    reader.readAsArrayBuffer(fileIn);

    this.validationMessages.length = 0;
    if (this.checkFileFormat(fileIn)) {
      this.fileData = fileIn;
      /*this.upFileForm.patchValue({
        attachmentFile: fileIn
      });
      this.upFileForm.get('attachmentFile').updateValueAndValidity();*/
    } else {
      fileIn = null;
    }
  }

  checkFileFormat(fileIn: File) {
    this.upFileForm.controls.attachmentFile.setErrors(null);
    if ((fileIn.size / 1024) > this.maxKBFileSize) {
      this.validationMessages.push('File exceed the maximum file size');
      this.upFileForm.controls.attachmentFile.setErrors({required: true});
      return false;
    } else {
      for (const format of this.fileFormat) {
        if (fileIn.name.toLowerCase().endsWith(format.toLowerCase().trim())) {
          return true;
        }
      }
      this.upFileForm.controls.attachmentFile.setErrors({required: true});
      this.validationMessages.push('File type not admitted');
      return false;
    }
    return true;
  }

  confirmUpFile() {
    this.submitted = true;
    this.validateForm();
    this.setFieldsDirty();

    if (this.upFileForm.valid) {
      this.loaderService.startLoader(this.eventPropagation, true);
      this.file.metadataList.forEach(metadata => {
        if (metadata.visible) {
          const metInput = this.upFileForm.controls[metadata.code];
          metadata.value = metInput.value;
        }
      });

      if (this.bUploadFile) {
        this.confirmUploadFile();
      } else {
        this.confirmUpdateFile();
      }
    }
  }


private setFieldsDirty() {
    Object.keys(this.upFileForm.controls).forEach(field => {
        this.upFileForm.get(field).markAsDirty();
    });
}

validateForm() {
  const controls = this.upFileForm.controls;
  this.validationMessages.length = 0;

  if (!this.upFileForm.valid) {
    this.validationMessages.push('Set mandatory fields');
  } else {
    if (this.bUploadFile) {
      if (this.checkFileFormat(this.fileData)) {
        this.upFileForm.controls.attachmentFile.setErrors(null);
      }
    }
  }

  this.eventPropagation.emit('layoutChanged');
}

  confirmUploadFile() {
    this.file.name = this.fileData.name;
    const fileByteArray = [];
    const array = new Uint8Array(this.arrayBuffer);
    array.forEach(elem => {
      fileByteArray.push(elem);
    });

    this.file.body = fileByteArray;

    this.documentsService.uploadFile(this.contractId, this.document.code, this.file).subscribe((file: DocumentFile) => {
      this.document.files.push(file);
      if (this.document.toBeValidated) {
        this.componentRef.instance.arInpDocValidation[this.document.id] = false;
      }
      this.eventModifiedDocument.emit(true);
    },
      () => {
        console.log('Errore caricamento file ');
      },
      () => {
        this.loaderService.stopLoader(this.eventPropagation, true);
        this.close();
      });
  }

  confirmUpdateFile() {
    this.documentsService.updateFileMetadata(this.contractId, this.document.id, this.file.id, this.file.metadataList)
      .subscribe((metadataList: Array<Metadata>) => {
        if (metadataList) {
          this.file.metadataList.forEach((metadata: Metadata) => {
            const metadataIn = metadataList.find((md: Metadata) => md.code === metadata.code);
            metadata.value = metadataIn.value;
            metadata.valueDescription = metadataIn.valueDescription;
            metadata.id = metadataIn.id;
          });

          this.eventModifiedDocument.emit(true);
        }
      },
        () => { },
        () => {
          this.loaderService.stopLoader(this.eventPropagation, true);
          this.close();
        });
  }

  toFormGroup(confMetadataList: ConfMetadata[], metadataList: Metadata[]) {
    const group: any = {};

    confMetadataList.forEach(confMetadata => {
      if (confMetadata.visible) {
        let found = false;
        let value = null;
        for (const metadata of metadataList) {
          if (confMetadata.code === metadata.code) {
            found = true;
            value = metadata.value;
            break;
          }
        }

        if (!found) {
          const metadata = new Metadata();
          metadata.code = confMetadata.code;
          metadata.description = confMetadata.description;
          metadata.value = confMetadata.value;
          metadata.valueDescription = confMetadata.valueDescription;
          metadata.listValue = confMetadata.listValue;
          metadata.visible = confMetadata.visible;
          metadata.usedForValidation = confMetadata.usedForValidation;
          metadata.mandatory = confMetadata.mandatory;
          metadata.complianceStatus = confMetadata.complianceStatus;
          metadata.type = confMetadata.propertyType;
          metadata.usedAsIndex = confMetadata.usedAsIndex;
          metadata.modifiable = confMetadata.modifiable;
          metadata.systemMetadato = confMetadata.systemMetadato;
          metadataList.push(metadata);
        }

        const control = new UntypedFormControl(value, confMetadata.mandatory ? Validators.required : null);
        control.setValidators(this.confMetadataValidator(confMetadata));
        this.patchValueToForm(control, value, confMetadata);
        if (!confMetadata.modifiable) {
          control.disable();
        } else {
          control.enable();
        }
        group[confMetadata.code] = control;
      }
    });

    if (this.bUploadFile) {
      const formControl = new UntypedFormControl(null, Validators.required);
      group.attachmentFile = formControl;
    }

    return new UntypedFormGroup(group);
  }

  patchValueToForm(control: UntypedFormControl, value: any, confmetadata: ConfMetadata) {
    if (value) {
      if (confmetadata.propertyType.code === 'S') {
        control.patchValue(value.toUpperCase());
      } else {
        control.patchValue(value);
      }
    }
  }

  confMetadataValidator(metadata: ConfMetadata): ValidatorFn {
    return (control: UntypedFormControl): {
      [key: string]: any
    } | null => {
      if (metadata.mandatory) {
        if (!control.value || control.value === '-1') {
          return { required: true };
        }
        return null;
      }
    };
  }

}

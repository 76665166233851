import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DialogueHandlerSummaryStateManager } from '../dialogue-handler-service/dialogue-handler-summary.state.manager';
import { ActiveRoute } from '@rgi/rx/router';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { RgiRxDataTableExpansionModel, RgiRxTableExpansionTrigger, TableSchema } from '@rgi/rx/ui';
import { Logger, LoggerFactory } from '@rgi/rx';
import { schemaActorAs, schemaTableDiscarded, schemaTableDocuments, schemaTableIn, schemaTableOut } from './schema-table-summary/schema-table-summary.model';
import { DatePipe } from '@angular/common';
import { CausalData, DettaglioSinistroDenunciaData, DialogueSummaryObject, MessageList, ParameterFieldRecord } from '../dialogue-handler-configuration/dh-summary.model';
import { Subscription, of } from 'rxjs';
import { CardComponent } from '@rgi/digital-claims-common-angular';
import { SummaryState } from '../dialogue-handler-configuration/dialogue-handler.model';
import { ListDialogueViewConfiguration } from '../constants/listEnumConstants';

const canExpand = (logger: Logger): RgiRxTableExpansionTrigger => (row: any) => {
  logger.info('can expand?', row);
  return true;
};

const canContract = (logger: Logger): RgiRxTableExpansionTrigger => (row: any) => {
  logger.info('can contract?', row);
  return true;
};

export enum SummaryViewConfiguration {
  SUMMARY_VIEW_CONF = 'SSV'
}

export enum ParaRequestResponseFilter {
  REQUEST = 1,
  RESPONSE = 2
}

export enum DocuRequestResponseFilter {
  REQUEST = 1,
  RESPONSE = 2
}

export enum RecordMessageListType {
  DOCUMENTS = 'DOC',
  PARAMETERS = 'PAR'
}

export enum DialogueStatus {
  ONGOING = '_CLAIMS_._ONGOING',
  EVADE = '_CLAIMS_._EVADE',
  CLOSED = '_CLAIMS_._CLOSED',
  CANCELED = '_CLAIMS_._CANCELED',
  RESERVED = '_CLAIMS_._RESERVED',
  CANCEL_MESSAGE = '_CLAIMS_._CANCEL_MESSAGE'
}

export enum RoleCompany {
  CREDITOR = '8',
  DEBITOR = '9'
}

export enum Activity {
  END = '4',
  INFO = '7',
}

@Component({
  selector: 'claims-dialogue-summary',
  templateUrl: './dialogue-summary.component.html',
  styleUrls: ['./dialogue-summary.component.scss'],
})

export class DialogueSummaryComponent extends CardComponent implements OnInit, AfterViewInit {
  @ViewChild('modalOutcome', { static: false }) modal: any;
  @ViewChild('modalClaim', { static: false }) modalClaim: any;

  associationForm: UntypedFormGroup;
  summaryForm: UntypedFormGroup;
  claimList: any[] = [];
  modalError: string = 'PLACEHOLDER';
  localObject: SummaryState;
  outcome: string;
  modalType: string;
  index: any;
  subscriptions: Subscription[] = [];
  errorMessage: string;
  status: string = '';
  statusOptions = DialogueStatus;
  displayedParam: ParameterFieldRecord[] = [];
  displayedDetails: DettaglioSinistroDenunciaData[][] = [];
  localCausals: CausalData[] = [];
  roleCompany = RoleCompany;
  greenDotIndex: number;
  rowGreenValue;
  baseApiUrlV2: string;
  btnCond = {
    resetAdvanceCond: false,
    responseCond: false,
    cancellationCond: false,
    tempRequestCond: false
  }
  creditorCodeList : string[] = ['1','3','5','7','9','25'];
  debtorCodeList: string[]= ['2','4','6', '8','10','26'];

  private readonly logger = LoggerFactory();

  schemaTable: TableSchema;
  schemaTableDocuments: TableSchema = schemaTableDocuments;
  schemaTableDiscarded: TableSchema = schemaTableDiscarded;
  schemaActorAs: TableSchema = schemaActorAs;
  private isDetailsInitialized = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogueHandlerSummaryState: DialogueHandlerSummaryStateManager,
    public activeRoute: ActiveRoute,
    public translateService: RgiRxTranslationService,
    private datePipe: DatePipe,
    @Inject('eventService') private emitter: any,
    @Inject('authService') private authService,
    @Inject('coreResult') private coreResult: any
  ) {
    super();

  }
  ngAfterViewInit(): void {
    this.checkState();
  }

  ngOnInit() {
    const listFunz = this.authService.getOperator().enabledFeatures;
    this.dialogueHandlerSummaryState.checkAssociation$();
    this.initValidators();
    this.checkState();
    this.checkPermissions(listFunz);
    this.onChangeForm();

  }

  initValidators() {
    this.associationForm = this.formBuilder.group({
      claimNumberPass: [],
      // idTypeOfLoss: [],
    })
  }

  checkPermissions(listFunz) {
    let translateStatus_ONGOING ='';
    this.translateService.translate(DialogueStatus.ONGOING).subscribe(res => {
      translateStatus_ONGOING= res;
    });
    if (listFunz && listFunz.indexOf('PSNDCN') >= 0 && translateStatus_ONGOING == this.status) {
      this.btnCond.cancellationCond = true;
    }
    if (listFunz && listFunz.indexOf('PSNSGD') >= 0) {
      this.btnCond.responseCond = true;
    }
  }

  stampa(ctx: any) {
    console.log("stampa==============>", ctx);
  }

  initData = [];
  customExpansionModel: RgiRxDataTableExpansionModel<any> = new RgiRxDataTableExpansionModel<any>(this.initData, {
    multiple: false,
    canExpand: canExpand(this.logger),
    canContract: canContract(this.logger)
  });

  associateClaim() {
    this.modalClaimClose();
    this.emitter.broadcastEvent('start-loader');
    this.dialogueHandlerSummaryState.associateClaim$(this.localCausals);
  }

  searchCausal() {
    this.dialogueHandlerSummaryState.searchCausal$();
  }

  modalOpen(type, outcome?) {
    if (outcome) {
      this.outcome = outcome;
    }
    this.modalType = type;
    this.modal.open();
  }

  modalClose() {
    this.outcome = null;
    this.modalType = null;
    this.modal.close();
    // this.modalError='';
  }

  modalClaimClose() {
    this.errorMessage = null;
    this.modalClaim.close();
  }

  goToResponse(ctx: MessageList) {
    this.unsubscribeState();
    this.dialogueHandlerSummaryState.goToResponse$(ctx);
  }

  unsubscribeState() {
    this.subscriptions.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
        sub = null;
      }
    })
  }

  formatData(date: Date): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy');
  }


  initObject(state: SummaryState) {
    this.localObject = state;
    this.initReqResp(RecordMessageListType.DOCUMENTS);
    this.initReqResp(RecordMessageListType.PARAMETERS);
  }


  initReqResp(type: RecordMessageListType) {

    this.localObject.dialogueSummaryObject.messageList.forEach((message) => {
      let arrayRequest = [];
      let arrayResponse = [];
      let array, field;

      if (type == RecordMessageListType.PARAMETERS) {
        message.paramRequest = [];
        message.paramResponse = [];

        array = message.parameterFieldRecord;
        arrayResponse = message.paramResponse;
        arrayRequest = message.paramRequest;
        field = "type";
      } else if (type == RecordMessageListType.DOCUMENTS) {
        message.docuRequest = [];
        message.docuResponse = [];

        array = message.documentList;
        arrayResponse = message.docuResponse;
        arrayRequest = message.docuRequest;
        field = "origin";
      }
      for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if (field == "type" && element.nature == 1 ) {
          this.displayedParam.push(element);
        } else if (field == "type" && element.nature == 3) {
          // console.log(element);
        } else {
          if (array[i][field] == ParaRequestResponseFilter.REQUEST || array[i][field] == DocuRequestResponseFilter.REQUEST) {
            arrayRequest.push(element);

          }
          else if (array[i][field] == ParaRequestResponseFilter.RESPONSE || array[i][field] == DocuRequestResponseFilter.RESPONSE) {
            arrayResponse.push(element);

          }
        }
      }
    })
  }


  cancelDialogue() {
    this.dialogueHandlerSummaryState.cancelDialogue$();
  }

  resetAdvance(idHistoryStatus: number) {
    this.dialogueHandlerSummaryState.resetAdvance$(idHistoryStatus);
  }

  checkState() {
    this.subscriptions.push(this.dialogueHandlerSummaryState.getState$().subscribe((state) => {
      console.log('state dettaglio dialogo', state.dialogueSummaryObject)
      if (state && this.localObject == null) {
        this.greenDotIndex = state.dialogueSummaryObject?.messageList?.findIndex((elem)=> elem?.activity?.code != Activity.INFO);
        if(this.greenDotIndex >= 0) {
          state.dialogueSummaryObject.messageList[this.greenDotIndex].greenDot = true;
        }
        if (state.dialogueSummaryObject?.messageList[this.greenDotIndex]?.inputIdMessage){
          this.btnCond.tempRequestCond = true;
        }
        if(state.dialogueSummaryObject?.roleCompany?.code == this.roleCompany.CREDITOR){
          this.schemaTable = schemaTableIn
        } else
        {
          this.schemaTable = schemaTableOut
        }
        if(state.dialogueSummaryObject.idCl || state.dialogueSummaryObject.idPotClaim){
          this.dialogueHandlerSummaryState.getDeatail$();
        }
        this.initObject(state);
      }
      if (state && state.dettaglioSinistroDenuncia.response.length > 0) {
        this.initDetails();
      }
      if (state && state.outcomeService && this.modal) {
        this.emitter.broadcastEvent('stop-loader');
        if (state.outcomeService.code == '1' || state.outcomeService.code == '2') {
          this.modalOpen(state.outcomeService.descr, state.outcomeService.detail)
        }
        state.outcomeService = null;
      }
      if (state && state.causalResponse && state.causalResponse.requestOutcome) {
        console.log(state.causalResponse)
        if (state.causalResponse.requestOutcome.code == "KO") {
          this.errorMessage = state.causalResponse.requestOutcome.description
        }
        else {
          this.errorMessage = null;
          switch(state.dialogueSummaryObject.roleCompany.code) {
            case this.roleCompany.CREDITOR: {
              state.causalResponse.response = state.causalResponse.response.filter((item) =>
              this.creditorCodeList.map(role => role).includes(item.ruolo.code));
              break;
            }
            case this.roleCompany.DEBITOR: {
              state.causalResponse.response = state.causalResponse.response.filter((item) =>
                this.debtorCodeList.map(role => role).includes(item.ruolo.code));
                break;
            }
          }

        }
      }
      if (state) {
        if (state?.dialogueSummaryObject?.messageList[this.greenDotIndex]?.activity?.code == Activity.END || state.responseFromSearchList?.endDate != null) {
          this.translateService.translate(DialogueStatus.CLOSED).subscribe(res => {
            this.status= res;
          });
        }
        if (state.dialogueSummaryObject?.cancelDate != null) {
          this.translateService.translate(DialogueStatus.CANCELED).subscribe(res => {
            this.status= res;
          });
          this.btnCond.cancellationCond = false;
        }
        else if (state.dialogueSummaryObject?.roleCompany?.code == RoleCompany.DEBITOR && state?.dialogueSummaryObject?.messageList?.length == 0) {
          this.translateService.translate(DialogueStatus.RESERVED).subscribe(res => {
            this.status= res;
          });

        }
        else {
          this.translateService.translate(DialogueStatus.ONGOING).subscribe(res => {
            this.status= res;
          });

        }
      }
      return of(state);
    },
      err => {
        console.log(err);

        this.translateService.translate('_CLAIMS_._SEARCH_ERROR').subscribe(res => {
          this.errorMessage = res;
        });

        this.emitter.broadcastEvent('stop-loader');
      }))
  }

  onChangeForm() {
    this.subscriptions.push(this.associationForm.valueChanges.subscribe((res) => {
      this.dialogueHandlerSummaryState.updateSummaryForm$(
        res,
      );
    })
    )
  }

  initDetails() {
    if (!this.isDetailsInitialized) {
      this.isDetailsInitialized = true;
      const details = this.localObject.dettaglioSinistroDenuncia;
      let counter = 0;
      this.displayedDetails[counter] = [];
      console.error(details);
      if (details.response.length > 0) {
        details.response.forEach((detail) => {
          //da modificare la condizione
          if (detail.tipo == "T") {
            this.displayedDetails[++counter] = [detail];
            counter++;
          } else {
            if (!this.displayedDetails[counter]) {
              this.displayedDetails[counter] = [];
            }
            this.displayedDetails[counter].push(detail)
          }

        })
      }
      console.log(this.displayedDetails)
    }
  }


  onCheckboxChange(elem){
    elem.row.selected = !elem.row.selected
    console.log(elem)
    if(elem.row.selected == true){
      if(this.localCausals.length == 0 || this.localCausals[0]?.actorName == elem.row.actorName)
        {
          this.localCausals.push(elem.row);
        }
    }
    else
    {
      this.localCausals = this.localCausals.filter(x => x == elem.row.causaleId);
    }

    console.error(this.localCausals)
  }


  checkGreenDot(rowValue){
    if(rowValue.index == this.greenDotIndex){
      this.rowGreenValue = rowValue;
      console.log(this.rowGreenValue)
      return true;
    }
  }

  goBack(){
    if(this.localObject.checkList == ListDialogueViewConfiguration.SEARCH_LIST_VIEW){
      this.dialogueHandlerSummaryState.goToDialogueList$()
    } else if (this.localObject.checkList == ListDialogueViewConfiguration.DOUBLE_LIST_VIEW) {
      this.dialogueHandlerSummaryState.goBack();
    }
  }
}



<span *ngIf="sectionTitle!=''" id="headerTitle" class="header-text" translate> {{sectionTitle}} </span>
<table class="tbld">
    <tr class="tbld_row" *ngFor="let n of rows;">
        <td class="tbld_col" *ngFor="let element of objectMap.get(n); let i = index; last as isLast" >
            <span class="tbld_col_title">{{element.chiave}}<span *ngIf="element.chiave!=''">: </span> </span>
            <span *ngIf="!isVectorValue(element.valore); else vector" class="tbld_col_value">{{element.valore}}</span>
            <ng-template #vector>
              <span  *ngFor="let el of element.valore">
                {{el}}<br>
              </span>
            </ng-template>

        </td>
        <td class="tbld_col" *ngFor="let e of composeArray((3-objectMap.get(n).length))"></td>
    </tr>
</table>

import {ChangeDetectorRef, Component, Inject, Optional} from '@angular/core';
import {AbsOperationComponent} from '../abs-operation-component/abs-operation.component';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {PostsalesOperationsService} from '../../services/postsales-operations.service';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import {QuestionnaireCacheService} from '@rgi/questionnaires-manager';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotifierService} from '@rgi/portal-ng-core';
import {PlcQuestService} from '../../services/plc-quest.service';
import { AuthService } from '../../services/auth.service';
import { AnagService } from '../../services/anag.service';
import { PV_TOKEN } from '../../models/consts/lpc-consts';

@Component({
  selector: 'lpc-cancel-appendix',
  templateUrl: './cancel-appendix.component.html',
  styleUrls: ['./cancel-appendix.component.scss']
})
export class CancelAppendixComponent extends AbsOperationComponent {

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authorizationService: AuthService,
    protected anag: AnagService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authorizationService, anag);
  }

  public data: any = {};

  protected operationDataKey = 'dummy';

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe(result => {
        this.data = result.data.operationData.data;
      })
    );
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl()
    });
  }

  public onNext(step: string = null, publish: boolean = false, context: string = step) {
    this.publish();
    this.stepper.next();
  }
}

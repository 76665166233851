import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IncidentData } from './dsc-incident-data.model';
import { DatePipe } from '@angular/common';
import { OpenTypeSharedComponent } from '../dsc-shared-model/dsc-model-enums';

@Component({
  selector: 'claims-dsc-incident-data',
  templateUrl: './dsc-incident-data.component.html',
  styleUrls: ['./dsc-incident-data.component.scss'],
})
export class DscIncidentDataComponent implements OnInit {
  constructor(public formBuilder: UntypedFormBuilder, private datepipe: DatePipe) {}

  @Input() incidentData: IncidentData;
  @Input() typeOpenComponent: OpenTypeSharedComponent =
    OpenTypeSharedComponent.isAccordion;
  @Input() isPot: OpenTypeSharedComponent = OpenTypeSharedComponent.isPot;
  @Input() isNewClaim: OpenTypeSharedComponent =
    OpenTypeSharedComponent.isNewClaim;7
  @Output() outputFormIncidentData: EventEmitter<IncidentData> =
    new EventEmitter();

  isAccordion: boolean = false;
  incidentDataForm: UntypedFormGroup;
  occurrenceDateSelected: string;
  occurrenceTimeSelected: string;
  dateReported: string;
  intermediaryReceiptDate: string;
  insuredReportDate: string;
  chcReceiptDate: string;
  actualOccurrenceDate: string;
  actualOccurrenceTime: string;
  isInValidDateReported: boolean = false;
  expanded: boolean = true;
  isInvalidReportDate: boolean = false;
  isInvalidInsuredReportDate: boolean = false;
  isInvalidReceivedIntermediary: boolean = false;
  isInvalidChcReceiptDate: boolean = false;
  minDate = '';
  maxDate = '';
  viewFormIncidentData: boolean = false;

  ngOnInit() {
    console.log(this.typeOpenComponent);
    console.log(this.isPot);
    this.isAccordion =
      this.typeOpenComponent === OpenTypeSharedComponent.isAccordion
        ? true
        : false;
    /*   this.incidentDataForm.patchValue(this.incidentData); */
    this.initFormValue();
  }

  initFormValue() {
    this.incidentDataForm = this.formBuilder.group({
      occurrenceDate: ['', Validators.required],
      occurrenceTime: ['', Validators.required],
      dateReported: ['', Validators.required],
      intermediaryReceiptDate: ['', Validators.required],
      incidentDescription: [''],
      chcReceiptDate: ['',  this.isNewClaim !== 'isReopened' && this.isNewClaim !== 'isNewClaimReopen' ? Validators.required : null],
      insuredReportDate: ['', Validators.required],
      certifiedOccurrenceDate: [false],
      actualOccurrenceDate: [''],
      actualOccurrenceTime: [''],
      additionalNotes: ['']
    });
    if (this.isNewClaim === OpenTypeSharedComponent.isNewClaim) {
      this.occurrenceDateSelected = this.incidentData.occurrenceDate;
      this.actualOccurrenceDate = this.incidentData.actualOccurrenceDate;
      this.actualOccurrenceTime = this.incidentData.actualOccurrenceTime;
      this.insuredReportDate = this.incidentData.insuredReportDate;
      this.chcReceiptDate = this.incidentData.chcReceiptDate;
      this.dateReported = this.incidentData.dateReported;
      this.intermediaryReceiptDate = this.incidentData.intermediaryReceiptDate;
      const date = new Date();
      if (this.incidentData.occurrenceTime.toString().length <= 5) {
        if (this.incidentData.occurrenceTime !== '') {
          const splitTime = this.incidentData.occurrenceTime.split(':');
          date.setHours(Number(splitTime[0]));
          date.setMinutes(Number(splitTime[1]));
        } else {
          date.setHours(0);
          date.setMinutes(1);
        }
        this.occurrenceTimeSelected = date.toISOString();
        this.incidentData.occurrenceTime = date.toISOString();
      }
      this.incidentDataForm.patchValue(this.incidentData, {emitEvent: false});
      if (!this.incidentData.occurrenceDateEditable && this.incidentDataForm.controls.insuredReportDate.value !== null ) {
        this.incidentDataForm.controls.occurrenceDate.disable();
        this.incidentDataForm.controls.occurrenceTime.disable();
      } else {
        this.incidentDataForm.controls.occurrenceDate.enable();
        this.incidentDataForm.controls.occurrenceTime.enable();
      }
      
      !this.incidentData.dateReportedEditable && this.incidentDataForm.controls.insuredReportDate.value !== null ? 
        this.incidentDataForm.controls.insuredReportDate.disable() :
        this.incidentDataForm.controls.insuredReportDate.enable();
      !this.incidentData.intermediaryReceiptDateEditable && this.incidentDataForm.controls.insuredReportDate.value !== null ?
       this.incidentDataForm.controls.intermediaryReceiptDate.disable() :
        this.incidentDataForm.controls.intermediaryReceiptDate.enable();
      !this.incidentData.incidentDescriptionEditable && this.incidentDataForm.controls.insuredReportDate.value !== null ? 
        this.incidentDataForm.controls.incidentDescription.disable() :
        this.incidentDataForm.controls.incidentDescription.enable();
      !this.incidentData.additionalNotesEditable && this.incidentDataForm.controls.insuredReportDate.value !== null ? 
        this.incidentDataForm.controls.additionalNotes.disable() :
        this.incidentDataForm.controls.additionalNotes.enable();
      if (this.incidentData.certifiedDataConfigurationVisible) {
        this.incidentDataForm.controls.actualOccurrenceDate.setValidators(Validators.required);
        this.incidentDataForm.controls.actualOccurrenceTime.setValidators(Validators.required);
      }
    } else {
      if (this.incidentData.dateReported === '') {
        this.incidentData.dateReported = this.incidentData.occurrenceDate;
      }
      const date = new Date();
      if (this.incidentData.occurrenceTime !== '') {
        const splitTime = this.incidentData.occurrenceTime.split(':');
        date.setHours(Number(splitTime[0]));
        date.setMinutes(Number(splitTime[1]));
      } else {
        date.setHours(0);
        date.setMinutes(1);
      }
      this.incidentData.occurrenceTime = date.toISOString();
      this.incidentDataForm.patchValue(this.incidentData, { emitEvent: false });
      this.incidentDataForm.get('occurrenceDate').disable();
      this.incidentDataForm.get('occurrenceTime').disable();
      this.occurrenceTimeSelected = date.toISOString();
      this.occurrenceDateSelected = this.incidentData.occurrenceDate;
      this.insuredReportDate = this.incidentData.insuredReportDate;
      this.chcReceiptDate = this.incidentData.chcReceiptDate;
      this.dateReported = this.incidentData.dateReported;
      if (this.incidentData.certifiedDataConfigurationVisible) {
        this.incidentDataForm.controls.actualOccurrenceDate.setValidators(Validators.required);
        this.incidentDataForm.controls.actualOccurrenceTime.setValidators(Validators.required);
      }
    }
    const dataOcc = this.formatDateTimezone(
      this.datepipe.transform(this.incidentData.occurrenceDate, 'yyyy-MM-dd')
    );
    const dateMin = new Date(dataOcc);
    const dateMax = new Date(dataOcc);
    dateMin.setDate(dateMin.getDate() - 1);
    dateMin.setHours(0);
    dateMin.setMinutes(1);
    dateMax.setDate(dateMax.getDate() + 1);
    dateMax.setHours(23);
    dateMax.setMinutes(59);
    this.minDate = this.datepipe.transform(dateMin, 'yyyy-MM-dd');
    this.maxDate = this.datepipe.transform(dateMax, 'yyyy-MM-dd');
    this.viewFormIncidentData = true;
    this.isInValidDateReported = this.incidentData.dateReported !== this.incidentData.occurrenceDate;
    this.validateFormOutput(this.incidentData);
    this.onChangeValueForm();
  }


  isValidDateReport() {
    // controllo insured date
    if (this.incidentDataForm.get('insuredReportDate').value !== '')  {
      const dtInsuredReported = this.datepipe.transform(this.incidentDataForm.get('insuredReportDate').value, 'yyyy-MM-dd');
      const occurrenceDate = this.datepipe.transform(new Date(this.incidentData.occurrenceDate), 'yyyy-MM-dd');
      if (dtInsuredReported === occurrenceDate) {
        this.isInvalidInsuredReportDate = false;
        this.incidentDataForm.controls.insuredReportDate.setErrors(null);
        this.incidentDataForm.controls.insuredReportDate.clearValidators();
        // this.incidentDataForm.controls.insuredReportDate.updateValueAndValidity({ onlySelf: true });
      } else {
        this.isInvalidInsuredReportDate = true;
        this.incidentDataForm.controls.insuredReportDate.setErrors({'': true});
        // this.incidentDataForm.controls.insuredReportDate.updateValueAndValidity({ onlySelf: true });
      }
    } else {
      this.isInvalidInsuredReportDate = false;
    }
    // controllo dateReported
    if (this.incidentDataForm.get('dateReported').value !== '') {
      const dtReported = this.datepipe.transform(this.incidentDataForm.get('dateReported').value, 'yyyy-MM-dd');
      const occurrenceDate = this.datepipe.transform(new Date(this.incidentData.occurrenceDate), 'yyyy-MM-dd');
      if (dtReported === occurrenceDate) {
        this.isInvalidReportDate = false;
        this.incidentDataForm.controls.dateReported.setErrors(null);
        this.incidentDataForm.controls.dateReported.clearValidators();
      } else {
        this.isInvalidReportDate = true;
        this.incidentDataForm.controls.dateReported.setErrors({'': true});
      }
    } else {
      this.isInvalidReportDate = false;
    }
    // controllo received intermediary
    if (this.incidentDataForm.get('intermediaryReceiptDate').value !== '') {
      const dtIntermediaryReceiptDate = this.datepipe.transform(this.incidentDataForm.get('intermediaryReceiptDate').value, 'yyyy-MM-dd');
      const occurrenceDate = this.datepipe.transform(new Date(this.incidentData.occurrenceDate), 'yyyy-MM-dd');
      if (new Date(dtIntermediaryReceiptDate) >= new Date(occurrenceDate)) {
        this.isInvalidReceivedIntermediary = false;
        this.incidentDataForm.controls.intermediaryReceiptDate.setErrors(null);
        this.incidentDataForm.controls.intermediaryReceiptDate.clearValidators();
      } else {
        this.isInvalidReceivedIntermediary = true;
        this.incidentDataForm.controls.intermediaryReceiptDate.setErrors({'': true});
      }
    } else {
      this.isInvalidReceivedIntermediary = false;
    }
    // controllo chcReceiptDate
    if (this.incidentDataForm.get('chcReceiptDate').value) {
      const dtChcReceiptDate = this.datepipe.transform(this.incidentDataForm.get('chcReceiptDate').value, 'yyyy-MM-dd');
      const occurrenceDate = this.datepipe.transform(new Date(this.incidentData.occurrenceDate), 'yyyy-MM-dd');
      if (new Date(dtChcReceiptDate) >= new Date(occurrenceDate)) {
        this.isInvalidChcReceiptDate = false;
        this.incidentDataForm.controls.chcReceiptDate.setErrors(null);
        this.incidentDataForm.controls.chcReceiptDate.clearValidators();
      } else {
        this.isInvalidChcReceiptDate = true;
        this.incidentDataForm.controls.chcReceiptDate.setErrors({'': true});
      }
    } else {
      this.isInvalidChcReceiptDate = false;
    }
    this.incidentDataForm.updateValueAndValidity({emitEvent: false});
  }

  onChangeValueForm() {
    this.incidentDataForm.valueChanges.subscribe((incident: IncidentData) => {
      this.validateFormOutput(incident);
    });
  }


  validateFormOutput(incident: IncidentData) {
    if (
      incident.occurrenceDate !== '' &&
      (this.incidentDataForm.get('insuredReportDate').value === '' ||
        this.incidentDataForm.get('insuredReportDate').value === null ||
        this.incidentDataForm.get('insuredReportDate').value === undefined)
    ) {
      // Se la data denuncia è valorizzata e viene svuotata

      this.incidentData.isOccurrenceDateChanged = true;
      this.incidentData.isOccurrenceDateFromEmptyToValued = false;
    } else if (
      incident.occurrenceDate === '' &&
      this.incidentDataForm.get('insuredReportDate').value !== '' &&
      this.incidentDataForm.get('insuredReportDate').value !== null &&
      this.incidentDataForm.get('insuredReportDate').value !== undefined
    ) {
      // Se la data denuncia era vuota e viene popolata

      this.incidentData.isOccurrenceDateChanged = false;
      this.incidentData.isOccurrenceDateFromEmptyToValued = true;
    } else {
      // In tutti gli altri casi

      this.incidentData.isOccurrenceDateChanged = false;
      this.incidentData.isOccurrenceDateFromEmptyToValued = false;
    }
    this.incidentData.occurrenceDate = this.incidentDataForm.get('occurrenceDate').value;
    this.incidentData.occurrenceTime = this.incidentDataForm.get('occurrenceTime').value;
    this.incidentData.dateReported = this.incidentDataForm.get('dateReported').value;
    this.incidentData.insuredReportDate = this.incidentDataForm.get('insuredReportDate').value;
    this.incidentData.intermediaryReceiptDate = this.incidentDataForm.get('intermediaryReceiptDate').value;
    this.incidentData.incidentDescription = this.incidentDataForm.get('incidentDescription').value;
    this.incidentData.additionalNotes = this.incidentDataForm.get('additionalNotes').value;
    this.incidentData.chcReceiptDate = this.incidentDataForm.get('chcReceiptDate').value;
    this.incidentData.actualOccurrenceTime = this.incidentDataForm.get('actualOccurrenceTime').value;
    this.incidentData.actualOccurrenceDate = this.incidentDataForm.get('actualOccurrenceDate').value;
    this.incidentData.certifiedOccurrenceDate = this.incidentDataForm.get('certifiedOccurrenceDate').value;
    this.isInValidDateReported = this.incidentData.dateReported !== this.incidentData.occurrenceDate;
    this.incidentData.isValidForm = this.incidentDataForm.valid;
    this.chcReceiptDate = this.incidentData.chcReceiptDate;
    this.intermediaryReceiptDate = this.incidentData.intermediaryReceiptDate;
    this.insuredReportDate = this.incidentData.insuredReportDate;
    this.dateReported = this.incidentData.dateReported;
    this.isValidDateReport();
    this.outputFormIncidentData.emit(this.incidentData);
  }

  setAccordion() {
    this.expanded = !this.expanded;
  }

  formatDateTimezone(date: string): string {
    const miaData = new Date(date);
    const timezoneOffset = Math.abs(new Date().getTimezoneOffset());
    miaData.setTime(miaData.getTime() + timezoneOffset * 60000);
    const dataOutput = this.datepipe.transform(miaData, 'yyyy-MM-dd');
    return dataOutput;
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SelectableCovertureResponse } from '@rgi/digital-claims-common-angular';

@Injectable({
  providedIn: 'root'
})
export class DscPhisicalInjuryService {

  private baseApiUrl: string;
  private urlServiceSelectableCovertures = '/claims/selectablecovertures';


  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  getSelectableCovertures(
    policyNumber: string,
    occurrenceDate: string,
    categoryCode: string,
    idTipoPortafoglio: string,
    externalPolicy: any,
    referenceDate: string,
    idBeneAss?: string
  ) {
    let queryString = '?policynumber=' + policyNumber;
    if (occurrenceDate) {
      queryString += '&occurrencedate=' + occurrenceDate;
    }
    if (categoryCode) {
      queryString += '&categorycode=' + categoryCode;
    }

    if (idTipoPortafoglio) {
      queryString += '&idTipoPortafoglio=' + idTipoPortafoglio;
    }

    if (idBeneAss) {
      queryString += '&idBeneAss=' + idBeneAss;
    }
    if (referenceDate) {
      queryString += '&referenceDate=' + referenceDate;
    }
    const headers = new HttpHeaders();
    // headers = headers.set('Content-Type', 'application/json');
    return this.httpClient.post<SelectableCovertureResponse>(this.baseApiUrl +
      this.urlServiceSelectableCovertures + queryString, externalPolicy, { headers });
  }

}

import {DatePipe} from '@angular/common';
import {NgModule} from "@angular/core";
import {SurveyCommonOperations} from './tools/survey-common-operations.service';
import {SurveyEditStateOperations} from './operations/survey-edit-state-operations.service';
import {SurveyEvaluateStateOperations} from './operations/survey-evaluate-state-operations.service';
import {SurveyEditStateManagerService} from './survey-edit-state-manager.service';
import {SurveyEvaluateStateManagerService} from './survey-evaluate-state-manager.service';
import {SurveyEvalValidationTool} from './tools/survey-eval-validation.service';
import {SurveyEvalTools} from './tools/survey-eval-tools.service';
import {SurveyEvalProductsCfgService} from './tools/survey-eval-products-cfg.service';


@NgModule({
    providers: [
        { provide: DatePipe, useClass: DatePipe},
        SurveyCommonOperations,
        SurveyEditStateOperations,
        SurveyEvaluateStateOperations,
        SurveyEvalValidationTool,
        SurveyEvalTools,
        SurveyEvalProductsCfgService
    ]
})
class StateManagerSurveyModule {}

export {
    StateManagerSurveyModule,
    SurveyCommonOperations,
    SurveyEditStateOperations,
    SurveyEvaluateStateOperations,
    SurveyEditStateManagerService,
    SurveyEvaluateStateManagerService,
    SurveyEvalValidationTool,
    SurveyEvalTools,
    SurveyEvalProductsCfgService
}

export * from "./models/eval-product-cfg";
export * from "./models/eval-validation";
export * from "./models/navigation";
export * from "./models/survey-edit-route-data";
export * from "./models/survey-edit-state";
export * from "./models/survey-evaluate-route-data";
export * from "./models/survey-evaluate-state";

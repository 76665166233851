import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable(
  {providedIn: 'root'}
)
export class TranslationService {

  constructor(protected translate: TranslateService) {
  }

  public translateBoolean(value) {
    const msg = (value === 'true') ? 'Yes' : 'No';
    return this.translate.instant(msg);
  }

}

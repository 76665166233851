<ng-container [formGroup]="confirmsForm" *ngIf="stateVcontConfirms">
  <rgi-gp-stepper (targetRoute)="callActionBack($event)" [steps]="stateVcontConfirms.steps" [vertical]="vertical"
    [showIndex]="showIndex" [labels]="stateVcontConfirms.labels" [disabled]="disabled">
  </rgi-gp-stepper>

  <span class="rgi-ui-title-3" translate>{{'_GP_._MODAL_._OPERATION_'}}</span>

  <ng-container *ngIf="stateVcontConfirms.documents && stateVcontConfirms.documents.length > 0">
    <div class="rgi-gp-section-title">
      <span class="rgi-gp-icon rgi-ui-icon-document"></span>
      <b translate>_GP_._TITLE_._DOCUMENTS_</b>
    </div>
    <div *ngFor="let document of stateVcontConfirms.documents">
      <span class="rgi-ui-icon-note" style="font-size: 20px; margin-right: 5px;"></span>
      <span class="rgi-ui-title-3">{{document.description}}</span>
    </div>
  </ng-container>

  <div data-qa="errorMessage">
    <rgi-gp-group-policy-errors-component [errors]="stateVcontConfirms.errors"
      *ngIf="!!stateVcontConfirms.errors && !!stateVcontConfirms.errors.length" [areaCode]="'FOOTER'">
    </rgi-gp-group-policy-errors-component>
  </div>

  <div class="rgi-ui-panel-footer">
    <button rgi-rx-button color="primary" [disabled]="confirmsForm.invalid" (click)="callActionClose()"
      translate>{{'_GP_._BTN_._CLOSE_'}}
    </button>
  </div>

</ng-container>
<div class="grid-item card card-height-normal card-main">
    <div class="modal-content">
    <form [formGroup]="modPolicyForm">
        <div class="modal-header">{{ '_CLAIMS_._EDIT_SUBJECT' | translate }} </div>
        <div class="modal-body" style="padding-top: 5px" *ngIf="tipoSogg == 'CNT'">

            <div class="row">
                <div class="col-xs-6 col-md-6"> <label ng-bind=" label" style="margin-right: 1%; border-left:#1569C7;">
                    {{ '_CLAIMS_._SURNAME__BUSINESS_NAME' | translate }}:</label>
                    <input type="text"  
                        class="form-control" 
                        name="denomination"
                        attr.data-qa="external-portfolio-denomination-input"
                        formControlName="denomination"/> 
                   
                </div>
                <div class="col-xs-6 col-md-6">
                    <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._NAME' | translate }}:</label>
                    <input type="text"  
                        class="form-control" 
                        name="firstName"
                        attr.data-qa="external-portfolio-firstName-input"
                        formControlName="firstName" *ngIf="attivaNomeCnt"/>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6 col-md-6">
                    <label ng-bind="label"
                        style="margin-right: 1%;">{{ '_CLAIMS_._TAX_CODE__VAT_NUMBER' | translate }}:</label>
                        <input type="text"  
                        class="form-control" 
                        name="fiscalCodeVat"
                        attr.data-qa="external-portfolio-fiscalCodeVat-input"
                        formControlName="fiscalCodeVat"/>
                </div>
                <div class="col-xs-6 col-md-6">
                    <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._PERSON_TYPE' | translate }}:</label>
                    <select class="core-select form-control" id="subjectCode" name="subjectCode" formControlName="subjectCode" 
                        attr.data-qa="external-portfolio-subjectCode-input" (change)="attivaNome('CNT')">
                        <option value="F">{{ '_CLAIMS_._PHISICAL_PERSON' | translate }}</option>
                        <option value="G">{{ '_CLAIMS_._LEGAL_PERSON' | translate }}</option>
                    </select>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-6 col-md-6">
                    <label ng-bind="label">{{ '_CLAIMS_._NATION' | translate }}</label>
                    <select class="core-select form-control" id="countrySel" name="country" formControlName="country" 
                        attr.data-qa="external-portfolio-country-input" >
                        <option></option>
                        <option *ngFor="let aCountry of countries; " value={{aCountry.sigla}}>{{aCountry.descrizione}}</option>
                    </select>
                    <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                </div>
                <div class="col-xs-6 col-md-6">
                    <label ng-bind="label">{{ '_CLAIMS_._PROVINCE' | translate }}</label>
                    <select class="core-select form-control" id="provinceSel" name="province"  formControlName="province"
                        attr.data-qa="external-portfolio-stateCode-input" (change)="caricaComuni('CNT')">
                        <option></option>
                        <option *ngFor="let aProv of provinces; " value={{aProv.sigla}}>{{aProv.descrizione}}</option>
                    </select>
                    <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-6 col-md-6">
                    <label ng-bind="label">{{ '_CLAIMS_._MUNICIPALITY' | translate }}</label>
                <select class="core-select form-control" id="municipalitySel" name="municipality" formControlName="municipality" 
                        attr.data-qa="external-portfolio-municipality-input" (change)="caricaCap('CNT')">
                        <option></option>
                        <option *ngFor="let aCom of comunisCnt; " value={{aCom.descrizione}}>{{aCom.descrizione}}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                </div>
                <div class="col-xs-6 col-md-6">
                    <label ng-bind="label">{{ '_CLAIMS_._POSTAL_CODE' | translate }}</label>
                    <select class="core-select form-control" id="postalCodeSel" name="postalCode" formControlName="postalCode" 
                    attr.data-qa="external-portfolio-postalCode-input">
                    <option></option>
                    <option *ngFor="let aCap of capsCnt; " value={{aCap}}>{{aCap}}</option>
                   </select>
                    <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-md-12">
                    <label ng-bind="label">{{ '_CLAIMS_._ADDRESS' | translate }}</label>
                <input type="text"  
                        class="form-control" 
                        name="address"
                        attr.data-qa="external-portfolio-address-input"
                        formControlName="address"/>
                </div>
               
            </div>
        </div>

        <div class="modal-body" style="padding-top: 5px" *ngIf="tipoSogg == 'ASS'">

            <div class="row">
                <div class="col-xs-6 col-md-6"> <label ng-bind=" label" style="margin-right: 1%; border-left:#1569C7;">
                {{ '_CLAIMS_._SURNAME__BUSINESS_NAME' | translate }}:</label>
                    <input type="text"  
                        class="form-control" 
                        name="denominationInsured"
                        attr.data-qa="external-portfolio-denominationInsured-input"
                        formControlName="denominationInsured"/> 
                   
                </div>
                <div class="col-xs-6 col-md-6">
                    <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._NAME' | translate }}:</label>
                    <input type="text"  
                        class="form-control" 
                        name="firstNameInsured"
                        attr.data-qa="external-portfolio-firstNameInsured-input"
                        formControlName="firstNameInsured" *ngIf="attivaNomeAss"/>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6 col-md-6">
                    <label ng-bind="label"
                        style="margin-right: 1%;">{{ '_CLAIMS_._TAX_CODE__VAT_NUMBER' | translate }}:</label>
                        <input type="text"  
                        class="form-control" 
                        name="fiscalCodeVatInsured"
                        attr.data-qa="external-portfolio-fiscalCodeVatInsured-input"
                        formControlName="fiscalCodeVatInsured"/>
                </div>
                <div class="col-xs-6 col-md-6">
                    <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._PERSON_TYPE' | translate }}:</label>
                    <select class="core-select form-control" id="subjectCodeInsured" name="subjectCodeInsured" 
                    formControlName="subjectCodeInsured" 
                        attr.data-qa="external-portfolio-subjectCodeInsured-input" (change)="attivaNome('ASS')">
                        <option value="F">{{ '_CLAIMS_._PHISICAL_PERSON' | translate }}</option>
                        <option value="G">{{ '_CLAIMS_._LEGAL_PERSON' | translate }}</option>
                    </select>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-6 col-md-6">
                    <label ng-bind="label">{{ '_CLAIMS_._NATION' | translate }}</label>
                    <select class="core-select form-control" id="countrySelInsured" name="countryInsured" formControlName="countryInsured" 
                        attr.data-qa="external-portfolio-countryInsured-input" >
                        <option></option>
                        <option *ngFor="let aCountry of countries; " value={{aCountry.sigla}}>{{aCountry.descrizione}}</option>
                    </select>
                    <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                </div>
                <div class="col-xs-6 col-md-6">
                    <label ng-bind="label">{{ '_CLAIMS_._PROVINCE' | translate }}</label>
                    <select class="core-select form-control" id="provinceSelInsured" name="provinceInsured"  formControlName="provinceInsured"
                        attr.data-qa="external-portfolio-stateCodeInsured-input" (change)="caricaComuni('ASS')">
                        <option></option>
                        <option *ngFor="let aProv of provinces; " value={{aProv.sigla}}>{{aProv.descrizione}}</option>
                    </select>
                    <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-6 col-md-6">
                    <label ng-bind="label">{{ '_CLAIMS_._MUNICIPALITY' | translate }}</label>
                <select class="core-select form-control" id="municipalityInsuredSel" name="municipalityInsured" formControlName="municipalityInsured" 
                        attr.data-qa="external-portfolio-municipalityInsured-input" (change)="caricaCap('ASS')">
                        <option></option>
                        <option *ngFor="let aCom of comunisAss; " value={{aCom.descrizione}}>{{aCom.descrizione}}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                </div>
                <div class="col-xs-6 col-md-6">
                    <label ng-bind="label">{{ '_CLAIMS_._POSTAL_CODE' | translate }}</label>
                    <select class="core-select form-control" id="postalCodeInsuredSel" name="postalCodeInsured" formControlName="postalCodeInsured" 
                    attr.data-qa="external-portfolio-postalCodeInsured-input">
                    <option></option>
                    <option *ngFor="let aCap of capsAss; " value={{aCap}}>{{aCap}}</option>
                   </select>
                    <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-md-12">
                    <label ng-bind="label">{{ '_CLAIMS_._ADDRESS' | translate }}</label>
                <input type="text"  
                        class="form-control" 
                        name="addressInsured"
                        attr.data-qa="external-portfolio-addressInsured-input"
                        formControlName="addressInsured"/>
                </div>
               
            </div>
        </div>

        <div class="modal-footer">
            <div class="btn-group btn-group-justified btn-group-justified-claim">
                <div class="btn-group">
                    <button class="btn btn-warning pull-right" (click)="closeModal()"
                        attr.data-qa="claim-opening-policy-details-close-btn">{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
                </div>
            </div>
        </div>
    </form>
    </div>
</div>
<rgi-rx-expansion-panel [expanded]="true">
  <rgi-rx-expansion-panel-header class="rgi-cc-accordion-title-header">
    <span rgi-rx-expansion-panel-label translate>CONTRACT_CONSULTATION.PREMIUM</span>
  </rgi-rx-expansion-panel-header>
  <div class="rgi-ui-tab-container">
    <div class="rgi-ui-tab-header rgi-ui-grid-sm-1">
      <consultation-premium-tab rgi-rx-tab class="rgi-ui-col rgi-cc-tab-hover" [title]="'CONTRACT_CONSULTATION.SIGN_INSTALMENT'"
                                [gross]="signInstalment?.dueTotal?.gross"
                                [currencyCode]="currency.code"
                                (click)="openSignInstalmentModal()"
                                data-qa="sign-instalment">
        <ng-template rgiRxTabContent>
        </ng-template>
      </consultation-premium-tab>
      <consultation-premium-tab rgi-rx-tab class="rgi-ui-col rgi-cc-tab-hover"
                                [title]="'CONTRACT_CONSULTATION.NEXT_INSTALMENT'"
                                [gross]="nextInstalment?.dueTotal?.gross"
                                [currencyCode]="currency.code"
                                (click)="openNextInstalmentModal()"
                                data-qa="next-instalment">
        <ng-template rgiRxTabContent>
        </ng-template>
      </consultation-premium-tab>
      <consultation-premium-tab rgi-rx-tab class="rgi-ui-col rgi-cc-tab-hover" [title]="'CONTRACT_CONSULTATION.ANNUAL_PREMIUM'"
                                [gross]="annualPremium?.premium?.gross"
                                [currencyCode]="currency?.code"
                                (click)="openAnnualPremiumModal()"
                                data-qa="annual-premium">
        <ng-template rgiRxTabContent>
        </ng-template>
      </consultation-premium-tab>
    </div>
  </div>
  <div class="rgi-ui-grid-10-md-4 rgi-ui-grid-10-sm-2 rgi-cc-margin-top-half">
    <div>
      <button class="rgi-ui-btn-outline rgi-cc-color-outline" (click)="openComissionModal()" data-qa="commissions-button" translate>CONTRACT_CONSULTATION.COMMISSIONS</button>
    </div>
    <div class="rgi-ui-col">
      <button class="rgi-ui-btn-outline rgi-cc-color-outline" (click)="openDiscountModal()" data-qa="discount-button" translate>CONTRACT_CONSULTATION.DISCOUNTS</button>
    </div>
    </div>
</rgi-rx-expansion-panel>

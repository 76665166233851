export class UseTax {
  constructor(
    public idUseTax: number,
    public description: string,
    public code: string,
    public identification: string,
    public selected: boolean
  ) {
  }
}

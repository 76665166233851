import {LabeledEntity} from "../../../../generic-detail/resources/models/entities";
import {RgiRxDatasource} from '@rgi/rx/ui';
import {DocumentFile} from '../api/document-file';

export class OutboundDocument{
  id: string;
  description: string;
  isDownloadable: boolean;
  isSignable: boolean;
  isArchivable: boolean;
  isSendable: boolean;
  statusCode: string;
  statusDescription: string;
  typeCode: string;
  typeDescription: string;
  statusColor: string;
  details: LabeledEntity[];
  file: DocumentFile;
  signIcon: string;
  signTooltipDescr: string;
}

export class OutboundDocumentDataSource extends RgiRxDatasource<OutboundDocument> {
  constructor() {
    super();
  }
}

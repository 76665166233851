import { Role } from '../../models/postsales-operations-response.model';

export type DistributionToggle = typeof DISTRIBUTION_PERC | typeof DISTRIBUTION_AMOUNT | null;


export const DISTRIBUTION_PERC = '2';
export const DISTRIBUTION_AMOUNT = '1';

export class DistributionToggleError {
  requiredError: string;
  errorDescription: string;
  isAmount: boolean;
  totalDistribution: number;
  errorType: string;
  benefName: string;

  constructor(
    requiredError?: string,
    errorDescription?: string,
    isAmount?: boolean,
    totalDistribution?: number,
    errorType?: string,
    benefName?: string
    ) {
      this.requiredError = requiredError;
      this.errorDescription = errorDescription;
      this.isAmount = isAmount;
      this.totalDistribution = totalDistribution;
      this.errorType = errorType;
      this.benefName = benefName;
  }
}

export class DistributionValidatorConfig {
  totalAmount: number;
  distributionType: DistributionToggle;
  beneficiary: Role;
  min: number;
  max: number;

 constructor(totalAmount: number, distributionType: DistributionToggle, beneficiary: Role, min: number, max: number) {
  this.totalAmount = totalAmount;
  this.distributionType = distributionType;
  this.beneficiary = beneficiary;
  this.min = min;
  this.max = max;

 }
}

export interface DistributionConfigDefinition {
  totalAmount: number;
  showDistributionSwitch: boolean;
  distributionType: DistributionToggle;
}

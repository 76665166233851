import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { JS_EVENT } from '../models/consts/lpc-consts';
import { AngularCommunicationService } from './angular-communication.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private activeBeCalls = 0;
  private behaviourSubj: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private angularCommunicationService: AngularCommunicationService) {
    this.behaviourSubj.asObservable().subscribe(ac => {
      if (ac > 0 ) {
        this.angularCommunicationService.communicate(JS_EVENT.LOADER.START);
      } else {
        this.angularCommunicationService.communicate(JS_EVENT.LOADER.STOP);
      }
    });
   }

  registerBeCall(obs: Observable<any>): Observable<any> {
    this.activeBeCalls ++;
    this.behaviourSubj.next(this.activeBeCalls);
    return obs.pipe(
      finalize(() => {
        this.activeBeCalls --;
        this.behaviourSubj.next(this.activeBeCalls);
      })
    );
  }

}

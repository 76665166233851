import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Factor } from '../../../models/policy.model';
import { FACTOR_TYPE } from '../../../enum/life-issue.enum';


@Component({
  selector: 'lic-input-factor',
  templateUrl: './input-factor.component.html',
  styleUrls: ['./input-factor.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InputFactorComponent implements OnInit {
  value: string;
  config: Factor;
  group: UntypedFormGroup;
  prevVal: string;
  showTooltip = false;
  tooltipMessage: string;
  configValue: string;
  modifiable: string;
  mandatory: string;

  FACTOR_TYPE = FACTOR_TYPE;

  get isInputType(): boolean {
    return !(!this.config.modifiable && !!this.config.value && this.config.value.length > 100);
  }


  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    if (this.config.value === '-1') {
      this.prevVal = null;
    } else {
      this.prevVal = this.config.value;
    }

    this.initInput();
  }

  onBlur() {
    let newVal = null;
    if (!!this.group.controls[this.config.code].value && !!this.group.controls[this.config.code].value.trim()) {
      newVal = this.group.controls[this.config.code].value.trim();
    } else {
      // setto il control a null per non passare le stringhe vuote al BE
      this.group.controls[this.config.code].setValue(null);
    }

    // genero evento solo se ho cambiato il valore rispetto al precedente
    if (newVal !== this.prevVal) {
      this.el.nativeElement.dispatchEvent(new CustomEvent('changeVal',  { bubbles: true, detail: this.config.code }));
      this.prevVal = newVal;
    }
  }

  initInput() {
    if (!this.group.contains(this.config.code)) {
      this.group.addControl(this.config.code, new UntypedFormControl());
    }
    if (this.config.value === null || this.config.value === '' || this.config.value === '-1') {
      this.group.controls[this.config.code].setValue(null);
    } else {
      this.group.controls[this.config.code].setValue(this.config.value);
    }
    if (this.config.compulsory) {
      this.mandatory = 'Obbligatorio';
      this.group.controls[this.config.code].setValidators(Validators.required);
    } else {
      this.mandatory = 'Non obbligatorio';
    }
    if (this.config.modifiable) {
      this.modifiable = 'Modificabile';
      this.group.controls[this.config.code].enable();
    } else {
      this.modifiable = 'Non modificabile';
      this.group.controls[this.config.code].disable();
    }
  }

  setFactor(factor: Factor, form: UntypedFormGroup) {
    this.config = factor;
    this.value = this.config.description;
    this.group = form;
  }

  isTABNOorTABSI() {
    return this.config.code === 'TABSI' || this.config.code === 'TABNO';
  }

}

<rgi-rx-expansion-panel *ngIf="isAccordion" [expanded]="true" #first color="info">
    <!-- Intestazione / Titolo -->
    <div rgi-rx-expansion-panel-header>
        <div class="row rgi-claims-title-section-shared" style="padding:5px;">
            <div class="col-xs-6 col-md-6">
                <label ng-bind="label">
                    {{ '_CLAIMS_._INJURY_CODE' | translate }}
                </label>
            </div>
        </div>
    </div>
    <!-- Form -->
    <ng-container *ngTemplateOutlet="containerInjuryCode"></ng-container>
</rgi-rx-expansion-panel>



<div *ngIf="!isAccordion">
    <div class="row rgi-claims-sub-title-section-shared">
      <div class="col-xs-12 col-md-12">
        <label ng-bind="label">
            {{ '_CLAIMS_._INJURY_CODE' | translate }}
        </label>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="containerInjuryCode"></ng-container>
</div>
  
<ng-template #containerInjuryCode>
    <form [formGroup]="dataForm" *ngIf="enableFormDisplay">
        <div class="form-group row">
            <!-- Reparto -->
            <div class="col-sm-4 col-xs-12">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._DEPARTMENT' | translate }}</label>
                    <select
                        (change)="outputFormChange()"
                        rgiRxNativeSelect formControlName="departmentCodeSel" id="departmentCodeSel"
                        name="departmentCodeSel" attr.data-qa="dsc-injury-code-departmentCodeSel-input">
                        <option value=""></option>
                        <option *ngFor="let dept of dataCombo.listDepartment;" value="{{dept.code}}">
                            {{dept.description}}</option>
                    </select>
                </rgi-rx-form-field>
            </div>

            <!-- Tipo perdita -->
            <div class="col-sm-4 col-xs-12">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._TYPE_OF_DAMAGE' | translate }}</label>
                    <select 
                        (change)="outputFormChange()"
                        rgiRxNativeSelect formControlName="injuryCodeSel" id="injuryCodeSel"
                        name="injuryCodeSel" attr.data-qa="dsc-injury-code-injuryCodeSel-input">
                        <option value=""></option>
                        <option *ngFor="let tod of dataCombo.listTypeOfDamage;" value="{{tod.code}}">
                            {{tod.description}}</option>
                    </select>
                </rgi-rx-form-field>
            </div>

            <!-- Tipologia civile/penale -->
            <div class="col-sm-4 col-xs-12">
                <rgi-rx-form-field>
                    <label rgiRxLabel>{{ '_CLAIMS_._TYPE_CIVIL__PENAL' | translate }}</label>
                    <select
                        (change)="outputFormChange()"
                        rgiRxNativeSelect formControlName="lossTypeSel" id="lossTypeSel"
                        name="lossTypeSel" attr.data-qa="dsc-injury-code-lossTypeSel-input">
                        <option value=""></option>
                        <option *ngFor="let tcp of dataCombo.listTypeCivilPenal;" value="{{tcp.code}}">
                            {{tcp.description}}</option>
                    </select>
                </rgi-rx-form-field>
            </div>

            <!-- Descrizione asserzione -->
            <div class="form-group row">
                <div class="col-sm-12 col-xs-12">
                    <rgi-rx-form-field>
                        <label rgiRxLabel>{{ '_CLAIMS_._ALLEGATION_DESCRIPTION' | translate }}</label>
                        <textarea (blur)="outputFormChange()"
                            rgiRxInput formControlName="allegationDescription"
                            id="allegationDescription" name="allegationDescription"
                            attr.data-qa="dsc-injury-code-allegationDescription-input"></textarea>
                    </rgi-rx-form-field>
                </div>
            </div>

            <!-- Note -->
            <div class="form-group row">
                <div class="col-sm-12 col-xs-12">
                    <rgi-rx-form-field>
                        <label rgiRxLabel>{{ '_CLAIMS_._NOTE' | translate }}</label>
                        <textarea (blur)="outputFormChange()"
                            rgiRxInput formControlName="injuryNote" id="injuryNote" name="injuryNote"
                            attr.data-qa="dsc-injury-code-injuryNote-input"></textarea>
                    </rgi-rx-form-field>
                </div>
            </div>

        </div>
    </form>
</ng-template>
import { TypeEnum, TypeIdentifier } from "../model/generated/models";

export class ObjectUtil {

    public static convertToIdentifier(id: string): TypeIdentifier {
        return Number(id);
    }

    public static convertToTypeEnum(code: TypeIdentifier, description?: string): TypeEnum {
        const result: TypeEnum = {code, description }
        return result;
    }
   
}

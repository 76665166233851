<rgi-rx-date-time-timer-box
  [upBtnAriaLabel]="'RGI_RX.DATEPICKER.UP_HOUR_LABEL' | translate"
  [downBtnAriaLabel]="'RGI_RX.DATEPICKER.DOWN_HOUR_LABEL' | translate"
  [upBtnDisabled]="!upHourEnabled()"
  [downBtnDisabled]="!downHourEnabled()"
  [boxValue]="hourBoxValue"
  [value]="hourValue" [min]="0" [max]="23"
  [step]="stepHour" [inputLabel]="'Hour'"
  (inputChange)="setHourValueViaInput($event)"
  (valueChange)="setHourValue($event)"></rgi-rx-date-time-timer-box>
<rgi-rx-date-time-timer-box
  [showDivider]="true"
  [upBtnAriaLabel]="'RGI_RX.DATEPICKER.UP_MINUTE_LABEL' | translate"
  [downBtnAriaLabel]="'RGI_RX.DATEPICKER.DOWN_MINUTE_LABEL' | translate"
  [upBtnDisabled]="!upMinuteEnabled()"
  [downBtnDisabled]="!downMinuteEnabled()"
  [value]="minuteValue" [min]="0" [max]="59"
  [step]="stepMinute" [inputLabel]="'Minute'"
  (inputChange)="setMinuteValue($event)"
  (valueChange)="setMinuteValue($event)"></rgi-rx-date-time-timer-box>
<rgi-rx-date-time-timer-box
  *ngIf="showSecondsTimer"
  [showDivider]="true"
  [upBtnAriaLabel]="'RGI_RX.DATEPICKER.UP_SECOND_LABEL' | translate"
  [downBtnAriaLabel]="'RGI_RX.DATEPICKER.DOWN_SECOND_LABEL' | translate"
  [upBtnDisabled]="!upSecondEnabled()"
  [downBtnDisabled]="!downSecondEnabled()"
  [value]="secondValue" [min]="0" [max]="59"
  [step]="stepSecond" [inputLabel]="'Second'"
  (inputChange)="setSecondValue($event)"
  (valueChange)="setSecondValue($event)"></rgi-rx-date-time-timer-box>

<div *ngIf="hour12Timer" class="rgi-ui-datepicker-timer-hour12">
  <button class="rgi-ui-datepicker-control-button rgi-ui-datepicker-timer-hour12-box"
          type="button" tabindex="0"
          (click)="setMeridiem($event)">
        <span *ngIf="isPM" class="rgi-ui-datepicker-control-button-content rgi-ui-btn" tabindex="-1">
            {{'RGI_RX.DATEPICKER.HOUR_12_PM_LABEL' | translate}}
        </span>
    <span *ngIf="!isPM" class="rgi-ui-datepicker-control-button-content rgi-ui-btn" tabindex="-1">
            {{'RGI_RX.DATEPICKER.HOUR_12_AM_LABEL' | translate}}
        </span>
  </button>
</div>

import { PolicyService } from './policy-service';
import { Injectable } from '@angular/core';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';

@Injectable({
  providedIn: 'root'
})

export class NavigationSummaryService {

  holderDescr: string = null;
  productDescr: string = null;
  id: string = null;

  constructor(
    private translateService: TranslationWrapperService,
    private policyService: PolicyService) {
  }

  setDescriptions(holder, product) {
    this.holderDescr = holder;
    this.productDescr = product;
  }

  updateNavigationSummary() {
    if (!document.getElementById('navigation-summary-' + this.id)) {
      const actualElem = document.getElementById('lic-id-' + this.id);

      const cardTitleParent = (actualElem
        .parentElement
        .parentElement
        .parentElement
        .parentElement
        .parentElement.getElementsByClassName('row card-title-container')[0]) ? (actualElem
          .parentElement
          .parentElement
          .parentElement
          .parentElement
          .parentElement.getElementsByClassName('row card-title-container')[0]
          .getElementsByClassName('card-title')[0]) :
          actualElem.parentElement.parentElement.parentElement.parentElement.children[0].children[0];

      const newElement = document.createElement('div');
      newElement.classList.add('attach-to-title');
      newElement.id = 'navigation-summary-' + this.id;

      const prodotto = document.createTextNode(this.translateService.getImmediate('lic_product') + ': ');
      const prodottoVal = document.createElement('strong');
      prodottoVal.innerHTML = this.productDescr;
      newElement.appendChild(prodotto);
      newElement.appendChild(prodottoVal);

      if (!!this.holderDescr) {
        const separator = document.createTextNode(' - ');
        let label = this.translateService.getImmediate('lic_Policyholder') + ': ';
        if (this.policyService.mainProposal.quote.product.type.code === 'CPI') {
          label = this.translateService.getImmediate('lic_subscriber') + ': ';
        } else if (this.policyService.isCAC) {
          label = this.translateService.getImmediate('lic_Aderente') + ': ';
        }
        const holder = document.createTextNode(label);
        const holderVal = document.createElement('strong');
        holderVal.innerHTML = this.holderDescr;
        newElement.appendChild(separator);
        newElement.appendChild(holder);
        newElement.appendChild(holderVal);
      }

      cardTitleParent.appendChild(newElement);
    }
  }

  resetNavigationSummary() {
    const actualElem = document.getElementById('navigation-summary-' + this.id);
    if (!!actualElem) {
      (actualElem.parentElement).removeChild(actualElem); // elimino il vecchio
    }
  }

}

import { downgradeComponent } from '@angular/upgrade/static';
import { Type, Injectable, Inject } from '@angular/core';

// AngularJS configuration
declare var angular: angular.IAngularStatic;

@Injectable({
  providedIn: 'root'
})
export class CardService {
  // TODO: bring to 0 after tests
  private idCardRegister = 1000;

  constructor(
    /*@Inject('boardBaseService') private boardBaseService: any*/
  ) { }

  // TODO: refactor types
  registerCard(
    moduleName: string,
    title: string,
    cardComponent: Type<any>,
    controllerName?: string,
    controllerModels?: any,
    routes?: any,
    enumerates?: string[],
    showInMainMenu = true,
    category?: string
  ) {
    const cardType = showInMainMenu ? 'main' : 'dontShow';
    const cardName = this.camelizeKebab('claims-' + moduleName);
    const claimsModule = angular.module('claims-card-' + moduleName, []);
    const that = this;

    function getDirective() {
      return { name: 'claims-' + moduleName + '-' + that.getIdCard() };
    }

    const controller = this.registerCommunnicationController(moduleName, getDirective, controllerName, controllerModels);

    const card = this.configureModuleForCard(claimsModule, title, cardType, cardName, category);

    this.configureParallelRoutingProvider(claimsModule, cardName, controller, title, routes);
    this.configureEnumerates(claimsModule, enumerates);

    const dir = claimsModule.directive(
      cardName, downgradeComponent({
        component: cardComponent,
        inputs: [
          'card'
        ]
      })
    );

    angular.module('claims-cards').requires.push('claims-card-' + moduleName);

    return claimsModule;
  }

  registerSubCard(
    moduleName: string,
    title: string,
    cardComponent: Type<any>,
    parentCardName: string,
    enumerates?: any,
    controllerName?: string,
    controllerModels?: any,
    routes?: any,
    enabledPotentialClaim?: boolean,
    enabledClaim?: boolean,
    category?: string
  ) {
    const cardName = this.camelizeKebab('claims-' + moduleName);
    const claimsModule = angular.module('claims-card-' + moduleName, []);
    const cardType = 'subCard';
    const that = this;

    function getDirective() {
      return { name: 'claims-' + moduleName + '-' + that.getIdCard() };
    }
    const controller = this.registerCommunnicationController(moduleName, getDirective, controllerName, controllerModels);

    this.configureModuleForSubCard(claimsModule, title, cardType, cardName, parentCardName, enabledPotentialClaim, enabledClaim, category);
    this.configureParallelRoutingProvider(claimsModule, cardName, controller, title, routes);
    this.configureEnumerates(claimsModule, enumerates);

    claimsModule.directive(
      cardName, downgradeComponent({ component: cardComponent })
    );

    angular.module('claims-cards').requires.push('claims-card-' + moduleName);

  }

  // TODO: execute a coherent refactoring
  registerCommunnicationController(
    name: string,
    getDirective: () => void,
    controllerName?: string,
    controllerModels?: any
  ) {
    const claimsModule = angular.module('claims-communication' + name, []);

    claimsModule.config(['eventServiceProvider', (evProvider) => {
      evProvider.registerEvent('angular-card-event', { type: 'card-event' });
    }]);

    claimsModule.controller('ClaimsCommunication' + name + 'Ctrl', ClaimsCommunicationCtrl);

    ClaimsCommunicationCtrl.$inject = [
      '$scope', '$compile', '$timeout', '$controller'
    ];
    function ClaimsCommunicationCtrl(
      $scope, $compile, $timeout, $controller) {

      if (controllerName) {
        $controller(controllerName, { $scope });
      }

      $scope.directive = getDirective();

      let elementStr = '<claims-' + name + ' data-qa="claims-entry-{{card.id}}" ';
      if (controllerModels) {
        for (const key in controllerModels) {
          if (key && controllerModels[key]) {
            elementStr += '[' + key + ']="' + controllerModels[key] + '" ';
          }
        }
      }
      elementStr += '[id]="id" ';
      elementStr += '[card]="card" ';
      elementStr += '[deadsession]="deadSession" ';
      elementStr += '[target-route]="targetRoute" ';
      elementStr += '[extended-card]="isExtend" ';
      elementStr += '[session-card]="isSession" ';
      elementStr += '[subcard]="isSubcard" ';
      elementStr += '[reload]="reload" ';
      elementStr += '(event-propagation)="wrapEvent($event)"> </claims-' + name + '>';

      $timeout(
        () => {
          const divElement = angular.element(document.querySelector('#' + $scope.directive.name + 'Directive'));
          const htmlElement = angular.element(elementStr);
          divElement.append(htmlElement);
          $compile(divElement)($scope);
        }
      );
    }

    // Refactoring
    angular.module('claims-cards').requires.push('claims-communication' + name);

    return 'ClaimsCommunication' + name + 'Ctrl';
  }

  private camelizeKebab(str) {
    const arr = str.split('-');
    const capital = arr.map((item, index) => index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item);
    const capitalString = capital.join('');

    return capitalString;
  }

  private configureModuleForCard(
    claimsModule: any,
    title: string,
    type: string,
    cardName: string,
    category?: string
  ) {
    const card = this.getCardObject(cardName, title, type, category);

    claimsModule.config([
      'cardManagementServiceProvider',
      (cardManagementServiceProvider) => {
        cardManagementServiceProvider.register(card);
      }
    ]);

    return card;
  }

  private configureModuleForSubCard(
    claimsModule: any,
    title: string,
    type: string,
    cardName: string,
    parentCardName: string,
    enabledPotentialClaim: boolean,
    enabledClaim: boolean,
    category?: string) {
    const card = this.getCardObject(cardName, title, type, category);
    const that = this;

    if (enabledPotentialClaim == null) {
      enabledPotentialClaim = true;
    }

    if (enabledClaim == null) {
      enabledClaim = true;
    }

    claimsModule.config([
      'cardManagementServiceProvider',
      (cardManagementServiceProvider) => {
        cardManagementServiceProvider.register(card);
        that.registerAsSubcard(cardManagementServiceProvider, parentCardName, cardName,
          { potentialClaim: enabledPotentialClaim, claim: enabledClaim });
      }

    ]);
    return card;
  }

  private registerAsSubcard(
    cardManagementServiceProvider: any,
    mainCardName: string,
    subCardName: string,
    type: { potentialClaim: boolean, claim: boolean }) {

    const mainCard = cardManagementServiceProvider.$get().getAvailableCardByName(mainCardName);
    const claimsSubcard = mainCard.subCards[0].cards;
    const potentialClaimSubcard = mainCard.subCards[1].cards;

    if (type.potentialClaim) {
      potentialClaimSubcard.push({ name: subCardName, route: 'home' });
    }

    if (type.claim) {
      claimsSubcard.push({ name: subCardName, route: 'home' });
    }
  }

  private getCardObject(
    cardName: string,
    title: string,
    type: string,
    category?: string
  ) {
    return {
      category: category ? category : 'Claims',
      name: cardName,
      navigationInSession: false,
      subCards: [],
      subCardsActive: [],
      title,
      tpl: '',
      type,
      customize: {
        enabled: false,
        hideHeader: false,
        hideContainerBody: false
      },
    };
  }

  private configureParallelRoutingProvider(
    claimsModule: any,
    cardName: string,
    controller: any,
    title: string,
    routes?: any
  ) {
    claimsModule.config(['parallelRoutingProvider', (pRouter) => {
      pRouter.when(cardName, 'home', {
        controller,
        template: 'assets/claims-communication.tpl.html',
        label: 'Home - ' + title
      });
      if (routes) {
        for (const key in routes) {
          if (routes.hasOwnProperty(key)) {
            const element = routes[key];
            pRouter.when(cardName, element.path, element.route);
          }
        }
      }
    }]);
  }

  private configureEnumerates(claimsModule: any, enumerates: string[]) {

    if (!enumerates) { return; }

    claimsModule.config(['core.enumServiceProvider', (enumServiceProvider) => {

      enumerates.forEach(enumerate => {

        enumServiceProvider.registerEnum(enumerate);
      });
    }]);
  }

  private getIdCard() {
    return this.idCardRegister++;
  }

  public addRouteToParallelRoutingProvider(
    claimsModule: any,
    cardName: string,
    path: string,
    route: any) {
    claimsModule.config(['parallelRoutingProvider', (pRouter) => {
      pRouter.when(cardName, path, route);
    }]);
  }

  public initCardsAfterBootstrap(injector: any) {

    const operator = injector.get('authService').getOperator();
    const cardManagementService = injector.get('cardManagementService');
    this.manageClaimOpeningCard(cardManagementService, operator);
    this.manageTimeLine(cardManagementService, operator);
    this.manageActivityList(cardManagementService, operator);
    this.manageAutosoftBoard(cardManagementService, operator);
    this.manageClaimLimitedDetail(cardManagementService);
    this.manageExperts(cardManagementService, operator);
    this.manageLetterTemplate(cardManagementService, operator);


  }

  private manageClaimOpeningCard(cardManagementService: any, operator: any) {
    if (operator.enabledFeatures.indexOf('SXFNOL') === -1) {
      this.disableCard(cardManagementService, 'claim-opening', 'Claims', 'claims', false);
    }
  }

  private manageTimeLine(cardManagementService: any, operator: any) {
    if (operator.enabledFeatures.indexOf('SXTMLN') === -1) {
      this.disableCard(cardManagementService, 'timeline', 'Claims', 'claims', false);
    }
  }

  private manageActivityList(cardManagementService: any, operator: any) {
    if (operator.enabledFeatures.indexOf('SXBLGS') >= 0) {
      this.disableCard(cardManagementService, 'activity-list', 'Claims', 'claims', false);
    }
  }

  private manageAutosoftBoard(cardManagementService: any, operator: any) {
    if (operator.enabledFeatures.indexOf('SXAUDB') === -1) {
      this.disableCard(cardManagementService, 'autosoft-board', 'Claims', 'claims', false);
    }
  }

  private manageExperts(cardManagementService: any, operator: any) {
    if (operator.enabledFeatures.indexOf('SXFIDU') === -1) {
      this.disableCard(cardManagementService, 'experts', 'Claims', 'claims', false);
    }
  }

  private manageClaimLimitedDetail(cardManagementService: any) {
    this.disableCard(cardManagementService, 'claim-limited-detail', 'Claims', 'claims', true);
  }

  private manageLetterTemplate(cardManagementService: any, operator: any) {
    if (operator.enabledFeatures.indexOf('SXMODL') === -1) {
      this.disableCard(cardManagementService, 'letterTemplates', 'Cross', 'claims', false);
    }
  }

  private disableCard(cardManagementService: any, modulName: string, categoryName: string, modulPrefix: string, onlyFromCategory: boolean) {
    const card = cardManagementService.getAvailableCardByName(this.camelizeKebab(modulPrefix + '-' + modulName));
    if (card) {
      if (!onlyFromCategory) {
        cardManagementService.getAvailableCards().splice(cardManagementService.getAvailableCards().indexOf(card), 1);
      }
      cardManagementService.getAvailableCardsByCategory(categoryName).splice(
      cardManagementService.getAvailableCardsByCategory(categoryName).indexOf(card), 1);
    }
  }
}

import { Injectable, Inject } from '@angular/core';
import {Subject, Observable} from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Authorization } from '../resources/models/authorization';
import { AuthorizationFilter } from '../resources/models/authorization-filter';
import { AuthorizationRequest } from '../resources/models/authorization-request';
import {FilterData} from '../resources/models/filter-data';
import {map, share} from 'rxjs/operators';
import {RgiRxHttpClientFactory, RgiRxHttpClientWrapper} from "@rgi/rx/http";
import {RgiRxUserService} from "@rgi/rx/auth";
import {PolicyType} from "../resources/constants/policy-type";
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationsSearchService {

    protected baseApiUrl;
    protected baseApiUrlV2;
    protected basePtfDanni;
    protected authorizationsEndpoint;

    protected authorizations: Array<any>;
    protected authorization: Authorization;
    protected authorizationFilter: AuthorizationFilter;

    protected advancedSearch = false;

    protected partyChannel: Subject<Array<any>> =
        new Subject<Array<any>>();

    protected httpClient: RgiRxHttpClientWrapper;

    protected subCard: boolean;

    constructor(
        protected httpClientFactory: RgiRxHttpClientFactory,
        protected userService: RgiRxUserService,
        @Inject('environment') environment: any,
        protected translate: RgiRxTranslationService
    ) {
        this.httpClient = this.httpClientFactory.buildWrapper();

        const newMotorFlowServicesPath = environment.api.portal.nmf;

        this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
        this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
        ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
        : environment.api.portal.host + environment.api.portal.path + '/v2';

        this.authorizationsEndpoint = this.baseApiUrlV2 + '/authorizations';
        this.basePtfDanni = environment.api.portal.host + environment.api.portal.path + '/ptfdanni';
    }

    public getPartyObservable() {
        return this.partyChannel.asObservable();
    }

    public setParty(newParty: Array<any>) {
        this.partyChannel.next(newParty);
    }

    getFilterValues() {
        return this.httpClient.get<FilterData>(this.authorizationsEndpoint + '/filter');
    }

    getAuthorizationsList(filter: AuthorizationFilter) {
        let requestParams: HttpParams = new HttpParams();

        for (const key in filter) {
            if (filter[key]) {
                requestParams = requestParams.set(key, filter[key]);
            }
        }
        return this.httpClient.get<Array<Authorization>>(this.authorizationsEndpoint + '/authorizations', {params: requestParams});
    }

    getAuthorizationsListFromSubcard(filter: AuthorizationFilter) {
        this.setAuthorizationFilter(filter);
        return this.getAuthorizationsList(filter);
    }

    getFilteredAuthorizations(): Array<any> {
        return this.authorizations;
    }

    setFilteredAuthorizations(authList: Array<any>) {
        this.authorizations = authList;
    }

    getAuthorizationDetail(authorization: Authorization) {
        return this.getAuthorizationDetailById(authorization.id);
    }

    getAuthorizationDetailById(id: string) {
        return this.httpClient.get<Authorization>(this.authorizationsEndpoint + '/authorization/' + id);
    }

    getAuthorization(): Authorization {
        return this.authorization;
    }

    setAuthorization(auth: Authorization) {
        this.authorization = auth;
    }

    getAuthorizationFilter(): AuthorizationFilter {
        return this.authorizationFilter;
    }

    setAuthorizationFilter(authorizationFilter: AuthorizationFilter) {
        this.authorizationFilter = authorizationFilter;
    }

    isAdvancedSearch(): boolean {
        return this.advancedSearch;
    }

    setAdvancedSearch(advancedSearch: boolean) {
        this.advancedSearch = advancedSearch;
    }

    putAuthorizationStatus(authRequest: AuthorizationRequest) {
        let requestParams: HttpParams = new HttpParams();
        for (const key in authRequest) {
            if (key !== 'authorizationId' && authRequest[key]) {
            requestParams = requestParams.set(key, authRequest[key]);
            }
        }
        return this.httpClient.put<Authorization>(this.authorizationsEndpoint + '/authorization/' + authRequest.authorizationId,
        {}, { params: requestParams });
    }

    cancelRequest(authorizationId: string): Observable<any> {
        const requestParams: HttpParams = new HttpParams();
        requestParams.set('authorizationId', authorizationId);
        return this.httpClient.put(this.authorizationsEndpoint + '/cancelAuthorization/' + authorizationId, {}, { params: requestParams });
    }

    cancelRequestDamage(proposalNumber: string): any {
        const user = this.userService.getUser();
        return this.httpClient.post(this.basePtfDanni + '/proposalcancellation',
            {proposalCancellationInput: {proposalNumber,
                                           authenticationNodeCode: user.salePoint.code,
                                           cancellationType: {codice: 1, descrizione: 'Logical'},
                                           userCode: user.username
                                           }},
            {});
    }

    getDamageProposal(propNumber: string, edit) {
        const queryString = '?isEditProposal=' + edit;
        return this.httpClient.get<any>(this.getContractUri(propNumber) + queryString).pipe(share(), map(val => {
            return val;
        }));
    }

    getContractUri(propNumber: string) {
        return this.baseApiUrl + '/ptfdanni/contract/' + propNumber +  '/' + 'proposal';
    }

    getLifeProposal(propNumber: string) {
        return this.httpClient.get(this.baseApiUrl + '/ptflife/proposal/quote/' + propNumber);
    }

    lifeAuthsaveProposal(contractId: string, nodeCode: string, authId: string) {
        /* const requestParams: HttpParams = new HttpParams()
            .set('userCode', nodeCode)
            .set('authId', authId); */
        return this.httpClient.put(this.baseApiUrlV2 + '/life/auth/proposalIssue/' + contractId
            + '?userCode=' + nodeCode + '&authId=' + authId, {});
    }

    lifeAuthsavePolicy(contractId: string, nodeCode: string, authId: string) {
        /* const requestParams: HttpParams = new HttpParams();
        requestParams.set('userCode', nodeCode);
        requestParams.set('authId', authId); */
        return this.httpClient.put(this.baseApiUrlV2 + '/life/auth/policyIssue/' + contractId
            + '?userCode=' + nodeCode + '&authId=' + authId, {});
    }

    lifeSavePolicy(quote: any) {
        /* const requestParams: HttpParams = new HttpParams();
        requestParams.set('userCode', nodeCode);
        requestParams.set('authId', authId); */
        return this.httpClient.post(this.baseApiUrl + '/ptflife/policy/savefromquote/', {quote});
    }

    lifeConfirmVariation(contractId: string, authId: string) {
        const user = this.userService.getUser();
        const request = {
            username: user.username,
            authorizationId: authId,
            loginNode: user.salePoint.code
        };
        return this.httpClient.post(this.baseApiUrlV2 + '/life/contracts/' + contractId
            + '/authorization/published', request, {});
    }

    getContractOrigin(proposalNumber, policyNumber): Observable<any> {
        const contractNumber = {
            policyNumber,
            proposalNumber
        };
        return this.httpClient.post<any>(this.baseApiUrlV2 + '/portfolio/contract/origin', {contractNumber}, {});
    }


    public isSubCard() {
        return this.subCard;
    }

    public setSubCard(isSubcard: boolean) {
        this.subCard = isSubcard;
    }

    getAuthForList(auth: any, isSubcard: boolean) {
        let stateDescr = auth.state.description;
        if (!isSubcard) {
            stateDescr +=  ' (' + auth.workflowState.description + ')'
        }
        return {
            authId: auth.id,
            authCode: auth.requestCode,
            authRequestType: auth.requestType.description,
            authContractType: auth.policyType ? auth.policyType.description : null,
            authOperation: auth.operation,
            authContractNumber: auth.policyNumber ? auth.policyNumber : auth.proposalNumber,
            authDescription: this.evaluteDescription(auth),
            authReference: this.evaluateReference(auth),
            authLastEvent: auth.lastEvent.description,
            authRequestDate: auth.requestDate,
            authNodeCode: auth.requestNode.code,
            authStatus: stateDescr
        };
    }

    evaluteDescription(auth: Authorization) {
        if (!!auth.policyType && (PolicyType.CHILD === auth.policyType.code)) {
            return auth.plate ? auth.plate : null;
        }
        return auth.product && auth.product.description ? auth.product.description : null;
    }

    evaluateReference(auth: Authorization) {
        let label;
        if (!! auth.policyType && (PolicyType.CHILD === auth.policyType.code)) {
            if (auth.masterPolicyNumber) {
                this.translate.translate('_AUTH_._PM_POLICY_NUMBER').subscribe(text => label = text);
                return label.concat(' ').concat(auth.masterPolicyNumber);
            } else {
                this.translate.translate('_AUTH_._PM_PROPOSAL_NUMBER').subscribe(text => label = text);
                return label.concat(' ').concat(auth.masterProposalNumber);
            }
        }
        return label;
    }
}

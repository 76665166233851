import { LpcTranslationsKeys } from './lpc-translations-keys';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-postsales-card library.
 */
export const LPC_FR: LpcTranslationsKeys = {
  lpc_Investment_profiles: 'Profils d\'investissement',
  lpc_payment_method: 'Méthode de paiement',
  lpc_payment_type: 'Type de paiement',
  lpc_investment_funds: 'Fonds d\'investissement',
  lpc_fund_type: 'Type de fonds',
  lpc_profile_type: 'Type de profil',
  lpc_elements: ' éléments',
  lpc_fund_name: 'Nom du fonds',
  lpc_profile_name: 'Nom du profil',
  lpc_profiles: 'Profils',
  lpc_funds: 'Fonds',
  lpc_division_type: 'Type de division',
  lpc_occurenceNumber: 'Le numéro d\'occurrence doit être supérieur à {{min}}',
  lpc_division_description: 'Description de l\'allocation',
  lpc_start_validity_date: 'Date de début de validité',
  lpc_fund_description: 'Description du fonds',
  lpc_ISIN_code: 'Code ISIN',
  lpc_Allocation: 'Allocation',
  lpc_fields_generic_error: 'Les champs obligatoires sont incomplets ou contiennent des valeurs incorrectes',
  lpc_profile_details: 'Détails du profil',
  lpc_portfolio_fund_display: 'Affichage du portefeuille de fonds',
  lpc_payment_data: 'Données de paiement',
  lpc_Warning_you_are_deleting_the_contractor: 'Attention, vous supprimez l\'intervenant. Voulez-vous continuer ?',
  lpc_Investment_amount: 'Montant de l\'investissement :',
  lpc_mov_status: 'Titre du statut',
  lpc_mov_Amount: 'Montant du titre',
  lpc_ticket_status_label: 'Évaluation en attente des quotas',
  lpc_status_investment_label: 'Statut de l\'investissement',
  lpc_order_status_label: 'Statut de la commande',
  lpc_selected_funds: 'Fonds sélectionnés',
  lpc_Premium: 'Prime',
  lpc_InvestmentByFund: 'Investissement par fonds',
  lpc_Loading: 'Frais',
  lpc_NetPremium: 'Prime nette',
  lpc_IntFract: 'Int. Fract.',
  lpc_Fees: 'Frais fixes',
  lpc_Taxes: 'Taxes',
  lpc_GrossPremium: 'Prime brute',
  lpc_FirstInstallment: 'Première échéance',
  lpc_AnnualPremium: 'Prime annuelle',
  lpc_NextInstallment: 'Prochaine échéance',
  lpc_Guarantee: 'Couverture',
  lpc_BenefitOnDate: 'Avantage à la date',
  lpc_Benefit: 'Avantage',
  lpc_AnnualSinglePremium: 'Prime annuelle / unique',
  lpc_ActiveGuarantees: 'Couvertures actives',
  lpc_Enhancement: 'Données financières',
  lpc_CalculationData: 'Date de calcul',
  lpc_ControvaloreAllaData: 'Valeur au guichet à la date',
  lpc_AccumulationOfPrizes: 'Somme des primes',
  lpc_TotalSurrender: 'Total des rachats',
  lpc_AccumulationOfReducedPremiums: 'Somme des primes réduites',
  lpc_TotalBalance: 'Solde total',
  lpc_EffectiveDate: 'Date d\'effet',
  lpc_Movement: 'Mouvement',
  lpc_Movements: 'Mouvements',
  lpc_booking_number: 'Numéro de réservation',
  lpc_booking_policyData: 'Données de police',
  lpc_booking_assetData: 'Données d\'actifs',
  lpc_PolicyLinked: 'Polices liées',
  lpc_Motivo_Legame: 'Motivation de liaison',
  lpc_ProposalIssueDate: 'Date de proposition',
  lpc_PolicyIssueDate: 'Date de police',
  lpc_ExpirationDate: 'Date d\'expiration',
  lpc_NextInstalmentDate: 'Date de prochaine échéance',
  lpc_found_duplicate: 'Avertissement',
  lpc_duplicate_subject_message: 'Sujet sélectionné déjà présent parmi les bénéficiaires',
  lpc_SelectSettlement: 'Sélectionnez le règlement',
  lpc_You_must_select_the_settlement_to_perform_the_variation: 'Vous devez sélectionner le règlement pour effectuer la variation',
  lpc_beneficiaryPercentage: 'Pourcentage pour le sujet',
  lpc_beneficiaryPercentage_greater: 'doit être supérieur à',
  lpc_beneficiaryPercentage_lower: 'doit être inférieur à',
  lpc_beneficiaryDuplicate: 'Sujet dupliqué',
  lpc_NoOperations: 'Aucune action disponible',
  lpc_confirm: 'Confirmer',
  lpc_description: 'Description',
  lpc_issueDate: 'Date de délivrance',
  lpc_effectiveDate: 'Date d\'effet',
  lpc_MovementData: 'Annuler l\'annexe',
  lpc_Are_you_sure_you_want_to_confirm_this_claim: 'Êtes-vous sûr de vouloir confirmer cette réclamation ?',
  lpc_Are_you_sure_you_want_to_cancel_this_claim: 'Êtes-vous sûr de vouloir annuler cette réclamation ?',
  lpc_warnings: 'Avertissements',
  lpc_warning: 'Avertissement',
  lpc_warning_message: 'Cliquez sur \'DEMANDE D\'AUTORISATION\' pour envoyer une demande d\'approbation pour l\'opération',
  lpc_ClaimCanceled: 'Réclamation annulée avec succès',
  lpc_ClaimConfirmed: 'Réclamation confirmée avec succès',
  lpc_reserve: 'Sauvegarder',
  lpc_questionnaire: 'Questionnaire',
  lpc_bonus_amount: 'Montant du bonus',
  lpc_life_detail_beneficiary: 'Bénéficiaire',
  lpc_life_detail_percentage: 'Pourcentage',
  lpc_life_detail_net_amount: 'Montant net',
  lpc_life_detail_questionnaire: 'Questionnaire',
  lpc_life_detail_beneficiaries: 'Bénéficiaires',
  lpc_AppendixNumber: 'Numéro d\'annexe',
  lpc_nessuna_variazione: 'Aucune variation',
  lpc_appendixLabel: 'Annexe bénéficiaire',
  lpc_operation_rescission_reason: 'Raison d\'annulation de l\'opération',
  lpc_Lien_type_creditor: 'Type de créancier de gage',
  lpc_creditor: 'Créancier',
  lpc_Open_lien: 'Ouvrir le gage',
  lpc_assignment_holder_type: 'Type de titulaire de l\'affectation',
  lpc_assignment_holder: 'Titulaire de l\'affectation',
  lpc_assignment_amount: 'Montant',
  lpc_assignment_policy_number: 'Numéro de contrat',
  lpc_assignment_company: 'Société',
  lpc_assignment_type: 'Type d\'affectation',
  lpc_assignment_benefit: 'Avantage',
  lpc_amount_less_of_benefit: 'Le montant doit être inférieur à l\'avantage.',
  lpc_open_assignment: 'Ouvrir l\'affectation',
  lpc_sum_det_cancel_proposal: 'annuler la proposition',
  lpc_sum_det_success_cancel: 'La proposition est annulée avec succès',
  lpc_sum_det_success_cancel_prev: 'La citation est annulée avec succès',
  lpc_sum_det_confirm_cancel: 'Confirmez-vous que la proposition sera annulée ?',
  lpc_sum_det_confirm_cancel_prev: 'Voulez-vous confirmer l\'annulation de cette citation ?',
  lpc_sum_det_confirm_prop_issue: 'La proposition sera émise. Confirmez-vous ?',
  lpc_sum_det_confirm_pol_issue: 'La police sera émise. Confirmez-vous ?',
  lpc_health_questionnaire: 'Questionnaire de santé',
  lpc_quotation_show_details: 'Afficher les détails',
  lpc_quotation_premium_details: 'Détails de la prime',
  lpc_quotation_warranties_details: 'Résumé de la couverture',
  lpc_quotation_commissions_details: 'Commission',
  lpc_Reference_Third_Party: 'Tierce partie de référence',
  lpc_policyHolder: 'Titulaire de la police',
  lpc_DelegateEqualToPolicyHolder: 'Délégué égal au titulaire de la police ?',
  lpc_LegalGuardianEqualToPolicyHolder: 'Tuteur légal égal au titulaire de la police ?',
  lpc_ThirdPayerEqualToPolicyHolder: 'Tiers payeur égal au titulaire de la police ?',
  lpc_DelegateEqualToPolicyHolderWarning: 'Sujet pour le rôle de délégué égal au titulaire de la police',
  lpc_LegalGuardianEqualToPolicyHolderWarning: 'Sujet pour le rôle de tuteur légal égal au titulaire de la police',
  lpc_ThirdPayerEqualToPolicyHolderWarning: 'Sujet pour le rôle de tiers payeur égal au titulaire de la police',
  lpc_but_confirm_accept: 'confirmer et accepter',
  lpc_Date_Start_Variation: 'Date de début de variation',
  lpc_New_Frequent_Payment: 'Nouveau paiement fréquent',
  lpc_start_date_profit: 'Date de début du profit',
  lpc_end_date_profit: 'Date de fin du profit',
  lpc_last_rate_profit: 'Dernier taux de profit',
  lpc_last_profit: 'dernier profit',
  lpc_Fund_Code: 'Code du fonds',
  lpc_OperationSynstesis: 'Synthèse de l\'opération',
  lpc_PreviusPerceent: 'Pourcentage précédent',
  lpc_PreviusValue: 'Valeur précédente',
  lpc_NumberOfUnit: 'Nombre d\'unités précédentes',
  lpc_PostPErcent: 'Pourcentage postérieur',
  lpc_PostValue: 'Valeur postérieure',
  lpc_PostNumberOfUnit: 'Nombre de citations postérieur',
  lpc_Dati: 'Données',
  lpc_PIP_Data: 'Données PIP',
  lpc_PIP_General: 'Données générales',
  lpc_PIP_Contribution: 'Contribution PIP',
  lpc_HolderData: 'Données de l\'assuré',
  lpc_RiskData: 'Données de risque',
  lpc_IBAN_error: 'L\'IBAN n\'est pas correct',
  lpc_BACK: 'Retour',
  lpc_ACTIONS: 'Actions',
  lpc_DETAIL: 'Détail',
  lpc_CANCEL: 'Annuler la proposition',
  lpc_CONFIRM_PROPOSAL: 'Confirmer la proposition',
  lpc_CONFIRM_POLICY: 'Confirmer la police',
  lpc_not_linked_detail: 'Détail de {{name}}',
  lpc_quote_number: 'Numéro de devis',
  lpc_valid_quote: 'Devis valide',
  lpc_BUY_PREV: 'Acheter',
  lpc_EDIT_PREV: 'Modifier',
  lpc_EDIT: 'Modifier',
  lpc_CANCEL_PREV: 'Annuler',
  lpc_modify_quote: 'Modifier le devis',
  lpc_modify_proposal: 'Modifier la proposition',
  lpc_buy_quote: 'Acheter un devis',
  lpc_Close_assignment: 'Fermer l\'affectation',
  lpc_Close_lien: 'Fermer le gage',
  lpc_disinvestment_funds: 'Fonds de désinvestissement',
  lpc_error_mandatory_fields: 'Les champs marqués en rouge sont obligatoires ou contiennent des valeurs incorrectes',
  lpc_disinvestment_msg: '{{amount}} à désinvestir, ou {{percent}}',
  lpc_disinvestment_allocation_msg: 'L\'allocation d\'investissement dépasse le montant disponible',
  lpc_maximum_percentage: 'Pourcentage maximum accepté',
  lpc_maximum_amount: 'Montant maximum accepté',
  lpc_minimum_percentage: 'Pourcentage minimum accepté',
  lpc_minimum_amount: 'Montant minimum accepté',
  lpc_investment_msg: '{{amount}} à investir, ou {{percent}}',
  lpc_Fields_marked_in_red_are_required: 'Les champs marqués en rouge sont obligatoires',
  lpc_amount_format_validation: 'Le montant est invalide, il contient incorrectement le mauvais séparateur décimal, veuillez le corriger en utilisant \'.\'',
  lpc_select_at_least_one_beneficiary: 'Sélectionnez au moins un bénéficiaire',
  lpc_quote_due_date: 'Date d\'échéance du devis',
  lpc_roles_variation: 'Rôles',
  lpc_fiscal_data: 'Données fiscales',
  lpc_data_calc: 'Date de calcul',
  lpc_tipo_liquidazione: 'Type de règlement',
  lpc_valore_polizza: 'Valeur de la police',
  lpc_imponibile_imp: 'Impôt taxable',
  lpc_imposta_base: 'Base d\'imposition',
  lpc_imposta_calcol: 'Impôt calculé',
  lpc_imposta_da_add: 'Impôt à facturer',
  lpc_imposta_add: 'Impôt facturé',
  lpc_imposta_bollo: 'Timbre fiscal',
  lpc_fiscal_data_detail: 'Détail des données fiscales',
  lpc_quote_white_list: 'Liste blanche de devis',
  lpc_data_rif: 'Date de référence',
  lpc_tipo_perc: 'Pourcentage WL',
  lpc_CancellInstallment: 'Résumé de la demande d\'annulation d\'échéance',
  lpc_DescOperazione: "Description de l'opération",
  lpc_RateAmount: 'Montant total de l\'acompte',
  lpc_OPEN_LATEST_VERSION: 'Ouvrir la dernière version',
  lpc_TOTAL_INVESTED: 'Total investi',
  lpc_REMAINING_AMOUNT: 'Montant restant',
  lpc_descrizione_rischio: 'Description du risque',
  lpc_prestazione_ridotta_iniz: 'Réduction de l\'avantage initial',
  lpc_prestazione_rivalutata: 'Réduction de l\'avantage réévalué',
  lpc_provvigioni_da_recuperare: 'Commission à récupérer',
  lpc_reg41: 'Nominal pour envoyer des communications à',
  lpc_disinvestment_amount: 'Montant de désinvestissement',
  lpc_occurrenceNumber: 'Numéro d\'occurrence',
  lpc_frequecy: 'Fréquence',
  lpc_the_occurenceNumber_must_be_a_value: 'Le numéro d\'occurrence doit être supérieur à {{min}}',
  lpc_disinvestment_range: 'Entrez un montant de désinvestissement supérieur à {{max}} et inférieur ou égal à {{min}}',
  lpc_state: 'État',
  lpc_substate: 'Sous-état',
  lpc_product_type: 'Type de produit',
  lpc_managementnode: 'Nœud de gestion',
  lpc_subagency: 'Sous-agence',
  lpc_branch_name: 'Agence',
  lpc_agreement: 'Accord',
  lpc_payment_frequency: 'Fréquence de paiement',
  lpc_mean_of_payment: 'Moyen de paiement',
  lpc_Subcause: 'lpc_Sous-cause',
  lpc_premium_payment_suspension_date: 'Date de suspension de paiement de la prime',
  lpc_imposta_sostitutiva: 'Impôt substitutif',
  lpc_novazione_contr: 'Novation contractuelle',
  lpc_acc_premium_initial_2011: 'Prime accumulée 31/12/2011 initiale',
  lpc_amount_intital_2011: 'Montant 31/12/2011 initial',
  lpc_acc_premium_2011: 'Prime accumulée 31/12/2011',
  lpc_amount_2011: 'Montant 31/12/2011',
  lpc_acc_premium_initial_2014: 'Prime accumulée 30/06/2014 initiale',
  lpc_acc_premium_2014: 'Prime accumulée 30/06/2014',
  lpc_amount_intital_2014: 'Montant 30/06/2014 initial',
  lpc_amount_2014: 'Montant 30/06/2014',
  lpc_acc_premium_initial_2022: 'Somme initiale des primes le 31/12/2022',
  lpc_acc_premium_2022: 'Somme des primes le 31/12/2022',
  lpc_amount_intital_2022: 'Principal et intérêts initiaux le 31/12/2022',
  lpc_amount_2022: 'Principal et intérêts le 31/12/2022',
  lpc_acc_premium_intial_tax_change: 'Prime accumulée changement d\'impôt initial',
  lpc_amount_initial_tax_change: 'Montant changement d\'impôt initial',
  lpc_acc_premium_tax_change: 'Prime accumulée changement d\'impôt',
  lpc_amount_tax_change: 'Montant changement d\'impôt',
  lpc_regime_change_date: 'Date de changement de régime',
  lpc_please_enter_a_disinvestment_amount: 'Veuillez entrer un montant de désinvestissement pour le fonds : {{fund}}',
  lpc_please_select_at_least_a_disinvestment: 'Veuillez sélectionner au moins un désinvestissement',
  lpc_reg41Msg: 'Destinataire des communications',
  lpc_cost: 'Coût',
  lpc_paymentFrequencyInterest: 'Freq. Intér.',
  lpc_netpremium: 'Prime nette',
  lpc_grosspremium: 'Prime brute',
  lpc_information: 'Information :',
  lpc_reinvest_data_detail: 'Détail du réinvestissement',
  lpc_polizza: 'Police',
  lpc_data_effetto_mov: 'Date d\'effet',
  lpc_netto_liquidazione: 'Montant net de règlement',
  lpc_netto_reinvestire: 'Montant net de réinvestissement',
  lpc_stato_reinvest: 'État de réinvestissement de règlement',
  lpc_reinvested_settlements: 'Règlements réinvestis',
  lpc_loans: 'Prêts',
  lpc_elaboration_date: 'Date d\'élaboration',
  lpc_calculation_date: 'Date de calcul',
  lpc_annuity_data: 'Données de rente',
  lpc_extra_premium: 'Prime supplémentaire',
  lpc_loan_interest: 'Intérêts sur les prêts',
  lpc_fractionation_interest: 'Intérêts sur la fractionnalisation',
  lpc_default_interest: 'Intérêts de défaut',
  lpc_reactivation_interest: 'Intérêts de réactivation',
  lpc_installment_detail: 'Détail de l\'échéance',
  lpc_effectiveAnnuityDate: 'Date d\'annuité effective',
  lpc_firstInstallmentDate: 'Date de la première échéance',
  lpc_expiryAnnuityDate: 'Date d\'expiration de l\'annuité',
  lpc_amountFirstInstallment: 'Montant de la première échéance',
  lpc_initialAnnuityAmount: 'Montant initial de l\'annuité',
  lpc_periodicityInstallmentAnnuity: 'Périodicité de l\'échéance de l\'annuité',
  lpc_typePaymentAnnuity: 'Type de paiement d\'annuité',
  lpc_performanceType: 'Type de performance',
  lpc_annuityType: 'Type d\'annuité',
  lpc_annuity_detail: 'Détail de l\'annuité',
  lpc_canceled_liquidation_detail: 'Détail de la liquidation annulée',
  lpc_Percentage: 'Pourcentage',
  lpc_loan_data: 'Données de prêt',
  lpc_maxLoanAmount: 'Montant maximum payable',
  lpc_loanRepaymentProcedure: 'Procédure de remboursement de prêt',
  lpc_loanAmount: 'Montant du prêt',
  lpc_rateType: 'Type de taxe d\'intérêt',
  lpc_maxLoanAmountKO: '0 € (erreur dans le calcul du montant',
  lpc_grant_date: 'Date d\'octroi',
  lpc_loan_amount: 'Montant du prêt',
  lpc_return_way: 'Mode de remboursement',
  lpc_interest_rate_type: 'Type de taux d\'intérêt',
  lpc_residual_amount: 'Montant résiduel',
  lpc_refund_type: 'Type de remboursement',
  lpc_refund_amount: 'Montant du remboursement',
  lpc_select_lpc_refund_type: 'Sélectionnez un type de remboursement',
  lpc_leftInsuredAmount: 'Capital assuré résiduel',
  lpc_insuredAmount: 'Montant investi',
  lpc_reactivatedCapital: 'Capital réactivé',
  lpc_premium_reactivation: 'Réactivation de prime',
  lpc_negative_amount: 'Montant résiduel négatif',
  lpc_zero_amount: 'Aucun montant résiduel, veuillez insérer un montant supérieur à 0',
  lpc_error_mandatory_selected_type_of_refund: 'Veuillez sélectionner un type de remboursement',
  lpc_select_refund: 'Veuillez sélectionner au moins un prêt',
  lpc_selected_profiles: 'Profils sélectionnés :',
  lpc_coupon_beneficiary: 'Bénéficiaire du coupon',
  lpc_bookings: 'Réservations',
  lpc_booking_type: 'Type de réservation',
  lpc_booking_state: 'État',
  lpc_booking_effective_date: 'Date d\'effet',
  lpc_booking_extrapremiumHealth: 'Prime supplémentaire santé',
  lpc_booking_extrapremiumProf: 'Prime supplémentaire professionnelle',
  lpc_booking_extrapremiumSport: 'Prime supplémentaire sportive',
  lpc_booking_extrapremiumOther: 'Autres primes supplémentaires',
  lpc_booking_effectiveDate: 'Date d\'effet',
  lpc_booking_detail_label: 'Détails de la réservation',
  lpc_Rischi: 'Garanties',
  lpc_booking_destinationNode: 'Nœud de destination',
  lpc_booking_cause: 'Cause',
  lpc_PolicyData: 'Données de police',
  lpc_booking_cancellationDate: 'Date d\'annulation',
  lpc_extra_premiums: 'Primes supplémentaires',
  lpc_error_reservation_cancel: 'Erreur lors de l\'annulation de la {{descr}} numéro {{num}}',
  lpc_success_reservation_cancel: 'L\'annulation de la {{descr}} numéro {{num}} a été confirmée',
  lpc_incomplete_disinvestment_for_the_funds: 'Désinvestissement incomplet pour les fonds',
  lpc_Sinistro_morte: 'Déclaration de décès',
  lpc_Sinistro_invalidita: 'Déclaration d\'invalidité',
  lpc_Sinistro_inabilità: 'Déclaration d\'incapacité',
  lpc_Malattia_grave: 'Déclaration de maladie grave',
  lpc_insured_form: 'Formulaire d\'assuré',
  lpc_other_contract_list: 'Autres contrats',
  lpc_modified_personal_data_for_the_subject: 'Données personnelles modifiées pour le sujet',
  lpc_subject_roles: 'Rôles des parties',
  lpc_no_variation_modpd: 'Aucune modification effectuée, modifiez un sujet',
  lpc_DeductiblePremium: 'Prime déductible',
  lpc_firstterm: 'Premier terme',
  lpc__1TERM_ATOOL: 'Date d\'activation de l\'outil de modification',
  lpc__1TERM_MTOOL: 'Date de modification de l\'outil',
  lpc__1TERM_DTOOL: 'Date de suppression de l\'outil',
  lpc_management_fees: 'Frais de gestion',
  lpc_investment_not_found: 'Aucun élément à sélectionner',
  lpc_No_policies: 'Aucune police pour le sujet modifié',
  lpc_variation_convention: 'Variation de convention',
  lpc_andamento_polizza_alla_data: 'Évolution de la police à la date',
  lpc_appendice_dichiarativa: 'Annexe déclarative',
  lpc_policy_number_dot: 'Police n. ',
  lpc_investment_sum_must_be100: 'La somme des investissements doit être égale à 100 %',
  lpc_Operation_detail: 'Détails de l\'opération',
  lpc_Notwithstanding_any_ongoing_operations: 'Les valeurs peuvent être sujettes à des modifications en raison d\'opérations en cours',
  lpc_percentage_max_zero: 'Insérez un pourcentage supérieur à zéro',
  lpc_Mostra_tutti: 'Afficher tous',
  lpc_Visualizza_dettaglio: 'Voir le détail',
  lpc_consultazione_storica: 'Consultation historique',
  lpc_dettaglio_movimento: 'Détail du mouvement',
  lpc_mensile: 'mensuel',
  lpc_annuale: 'annuel',
  lpc_sixmonthly: 'semestriel',
  lpc_trimestrale: 'trimestriel',
  lpc_Titolo_non_incassato: 'Titre non encaissé',
  lpc_Rid: 'Prélèvement automatique',
  lpc_In_attesa_valorizzazione_quote: 'En attente de valorisation des actions investies',
  lpc_switch_di_perequazione: 'Switch programmé',
  lpc_SELEZIONA: '-- SÉLECTIONNER --',
  lpc_settimanale: 'hebdomadaire',
  lpc_Valido: 'Valide',
  lpc_quotidiano: 'quotidien',
  lpc_Assegno: 'Chèque',
  lpc_Bonifico: 'Virement bancaire',
  lpc_Sdd: 'Ordre de prélèvement automatique',
  lpc_severeDisabilityMsg: 'Bénéficiaire en situation de handicap grave',
  lpc_benDg: 'Bénéficiaire handicapé',
  lpc_distribution: 'Répartition par',
  lpc_Distribution_amount_exceeds: 'Le montant de la répartition dépasse le montant total du règlement disponible',
  lpc_distribution_invalid_greater: 'Le {{type}} est invalide, il doit être supérieur à {{min}}',
  lpc_distribution_invalid_greater_equal: 'Le {{type}} est invalide : il doit être inférieur ou égal à {{max}}',
  lpc_perc: 'Pourcentage',
  lpc_amount: 'Montant',
  lpc_Please_insert_a_distribution: 'Veuillez insérer une répartition',
  lpc_For_the_Beneficiary: 'Pour le bénéficiaire',
  lpc_global_distribution: 'Le montant total à régler doit être inférieur ou égal à {{totalAmount}}',
  lpc_invalid_char: 'Le format du {{type}} contient des caractères incorrects',
  lpc_toDistribute: 'à distribuer',
  lpc_noClaims: 'Aucune réclamation n\'a été trouvée',
  lpc_Data_sinistro: 'Date de déclaration',
  lpc_Data_Pervenimento_Denuncia: 'Date de réception de la notification de réclamation',
  lpc_Data_Denuncia_Sinistro: 'Date de notification de réclamation',
  lpc_Tipo_sinistro: 'Type de réclamation',
  lpc_inserisci_denuncia: 'insérer une réclamation',
  lpc_Conferma_inserimento_denuncia: 'Confirmation de l\'insertion de la réclamation',
  lpc_Denuncia_inserita_correttamente: 'Réclamation insérée avec succès',
  lpc_Stato_denuncia: 'État de la réclamation',
  lpc_marital_status: 'État civil',
  lpc_fiscal_code: 'Code fiscal',
  lpc_birthdate: 'Date de naissance',
  lpc_address: 'Adresse',
  lpc_sex: 'Sexe',
  lpc_annulla_denuncia: 'annuler la réclamation',
  lpc_modifica_denuncia: 'modifier la réclamation',
  lpc_conferma_denuncia: 'confirmer la réclamation',
  lpc_new_beneficiary: 'Nouveau bénéficiaire',
  lpc_Seleziona: 'Sélectionner',
  lpc_insert_phisical_sbj_for: 'Insérer un sujet physique pour le rôle de {{role}}',
  lpc_insert_legal_sbj_for: 'Insérer un sujet juridique pour le rôle de {{role}}',
  lpc_payment_incomplete: 'Détails de paiement incomplets',
  lpc_add_mandatory_linkedroles: 'Saisissez les rôles obligatoires pour le sujet saisi',
  lpc_beneficiary_owner_recipient_must_be_physical: 'Le bénéficiaire propriétaire du destinataire doit être physique',
  lpc_enter_the_beneficial_owners_of_the_recipient: 'Entrez les bénéficiaires du destinataire',
  lpc_global_percentage_must_be_a_maximum_100: 'Le pourcentage global doit être au maximum de 100',
  lpc_overall_percentage_must_equal100: 'Le pourcentage global doit être égal à 100',
  lpc_Questionario_deve_essere_compilato: 'Le questionnaire doit être rempli',
  lpc_enter_mandatory_data: 'Entrez les données obligatoires',
  lpc_enter_an_adult: 'Entrez un adulte',
  lpc_enter_a_beneficial_owner: 'Entrez un bénéficiaire',
  lpc_enter_a_physical_beneficial_owner: 'Entrez un bénéficiaire physique',
  lpc_global_percentage_of_beneficial_owners_must_not_exceed_100: 'Le pourcentage global des bénéficiaires ne doit pas dépasser 100',
  lpc_beneficial_owners_percentages_must_be_greater_than0: 'Les pourcentages des bénéficiaires doivent être supérieurs à 0.0',
  lpc_effective_holder_perc: 'Le pourcentage de propriétaire bénéficiaire de {{benefName}} {{linkedName}} est inférieur au minimum autorisé de {{linkedPerc}}%',
  lpc_effective_holder_duplicated: 'Propriétaire effectif dupliqué',
  lpc_enter_the_minimum_number_of_subjects: 'Entrez le nombre minimum de sujets',
  lpc_percentage_must_be_greater_than_0: 'Le pourcentage doit être supérieur à 0.0',
  lpc_enter_free_text: 'Entrez un texte libre',
  lpc_beneficiary_executor: 'Exécuteur bénéficiaire',
  lpc_effective_recipient: 'Destinataire effectif',
  lpc_recipient_owner: 'Propriétaire du destinataire',
  lpc_beneficial_owner_of_beneficiary: 'Bénéficiaire propriétaire',
  lpc_confirmed_claim_modify: 'Modification de réclamation confirmée',
  lpc_claim_modified_correctly: 'Réclamation modifiée correctement',
  lpc_Assicurato: 'Assuré',
  lpc_Assicurati: 'Assurés',
  lpc_holderType: 'Type d\'emploi du demandeur',
  lpc_ageRetirement: 'Âge de la retraite',
  lpc_accessTransfer: 'Demandeur de transfert',
  lpc_emptyContribution: 'Contribution de l\'employeur',
  lpc_firstEmployment: 'Date de début de l\'emploi',
  lpc_oldUnderWritt: 'Ancien adhérent',
  lpc_underWitt1993: 'Inscrit avant le 29/4/1993',
  lpc_maturato2001: 'Acquis avant le 31/12/2000',
  lpc_maturato2006: 'Acquis avant le 31/12/2006',
  lpc_riduzImponibiTFR: 'Réduction de TFR imposable',
  lpc_aliquotaTFR: 'Taux de TFR (pré-2001)',
  lpc_PIP_Contribution_Type: 'Type de contribution',
  lpc_YES: 'Oui',
  lpc_PIP: 'Plan de pension personnel',
  lpc_PIP_Contribution_Percentage: 'Pourcentage',
  lpc_PIP_Contribution_Amount: 'Montant',
  lpc_transfer_data: 'Données de transfert',
  lpc_unit_questionnaire: 'Questionnaire d\'unité',
  lpc_amount_reimbured: 'Montant à rembourser',
  lpc_Descr_not_handled: 'Description non gérée pour ce code d\'opération',
  lpc_select_transfer: 'Sélection du transfert',
  lpc_fiscalFolderDataPost2006: 'Système fiscal post-2006',
  lpc_fiscalFolderData20012006: 'Système fiscal 2001 - 2006',
  lpc_fiscalFolderDataBefore2001: 'Système fiscal avant 2001',
  lpc_filledOut: 'Rempli',
  lpc_blank: 'Vide',
  lpc_desc_previousPenFund: 'Fonds de pension précédent',
  lpc_companyPreviousPenFund: 'Nom de l\'entreprise du fonds de pension précédent',
  lpc_typePreviousPenFund: 'Type de fonds de pension précédent',
  lpc_note: 'Remarques',
  lpc_Benef_irrevocabile: 'Bénéficiaire irrévocable',
  lpc_PIP_Transfer: 'Transferts d\'autres fonds',
  lpc_requestDate: 'Date de demande',
  lpc_Type_Previous_Pension_Fund: 'Type de fonds de pension précédent',
  lpc_Previous_Pension_Fund: 'Fonds de pension précédent',
  lpc_Import_Transfert: 'Montant du transfert',
  lpc_Note: 'Remarques',
  lpc_relief: 'Réduction d\'impôt 2022',
  lpc_selected_settlement: 'Règlement sélectionné',
  lpc_EDIT_ANONYMOUS_PREV: 'Modifier',
  lpc_invalid_quote: 'Devis invalide',
  lpc_reasonForRequest: 'Motif de la demande',
  lpc_advancePaymentAmount: 'Montant de l\'acompte',
  lpc_repaidAdvance: 'Acompte remboursé',
  lpc_PIP_AdvancedPayment: 'Acomptes',
  lpc_requestReason: 'Motif de la demande',
  lpc_notRepaidAmount: 'Montant non remboursé',
  lpc_fiscalDate: 'Date d\'effet',
  lpc_previousPension: 'Plan de pension précédent',
  lpc_fiscalCompetence: 'Compétence fiscale',
  lpc_settlementStatus: 'État du paiement anticipé',
  lpc_repaidAmount: 'Montant remboursé',
  lpc_advancePaymentTransfer: 'Acomptes de transfert',
  lpc_policy_factors: 'Facteurs de police',
  lpc_asset_factors: 'Facteurs d\'actif',
  lpc_Date_Variation: 'Période de variation',
  lpc_penalties_amount: 'Montant des pénalités',
  lpc_tax_amount: 'Montant des taxes',
  lpc_warranties_detail: 'Détail des garanties',
  lpc_fixedCharges: 'Frais fixes',
  lpc_catastrophe: 'Prime extraordinaire Catastrophe',
  lpc_health: 'Prime extraordinaire Santé',
  lpc_other: 'Autre prime extraordinaire',
  lpc_professional: 'Prime extraordinaire Professionnelle',
  lpc_sports: 'Prime extraordinaire Sports',
  lpc_collectionFee: 'Frais de recouvrement',
  lpc_lambdaFee: 'Frais Lambda',
  lpc_managementFee: 'Frais de gestion',
  lpc_purchaseFee: 'Frais d\'achat',
  lpc_continue_button: 'Continuer',
  lpc_confirm_button: 'Confirmer',
  lpc_authorization_request: 'Demande d\'autorisation',
  lpc_questionSTR_invalid: 'La réponse à la question : {{question}}, n\'est pas correcte',
  lpc_questionNUM_invalid: 'Le montant saisi pour la question : {{question}} n\'est pas correct',
  lpc_Beneficiaryowner: 'Bénéficiaire effectif',
  lpc_confirm_operation: 'Confirmer l\'opération',
  lpc_financial_questionnaire: 'Questionnaire financier',
  lpc_avc_questionnaire: 'Questionnaire AVC',
  lpc_confirm_variation: 'Confirmer la variation',
  lpc_Declarative_Appendix: 'Annexe déclarative',
  lpc_investment_funds_step: 'Fonds de placement',
  lpc_disinvestment_profile_step: 'Profils de désinvestissement',
  lpc_startDate: 'Date de début',
  lpc_end_date: 'Date de fin',
  lpc_six_monthly: 'Semestriel',
  lpc_quarterly: 'Trimestriel',
  lpc_weekly: 'Hebdomadaire',
  lpc_monthly: 'Mensuel',
  lpc_annual: 'Annuel',
  lpc_daily: 'Quotidien',
  lpc_start_date_cannot_be_earlier_than_the_effective_date: 'La date de début ne peut pas être antérieure à la date d\'effet {{value}}',
  lpc_start_date_must_be_earlier_than_the_end_date: 'La date de début doit être antérieure à la date de fin',
  lpc_contract_value: 'Valeur du contrat',
  lpc_invested_amount: 'Montant investi',
  lpc_funds_details_and_summary: 'Détails et résumé des fonds',
  lpc_separate_management_detail: 'Détail de la gestion séparée',
  lpc_denuncia_sinistro: 'Notification de sinistre',
  lpc_duration_in: 'Durée en',
  lpc_years: 'années',
  lpc_months: 'mois',
  lpc_days: 'jours',
  lpc_policy_number: 'Numéro de police',
  lpc_proposal_number: 'Numéro de proposition',
  lpc_LifeBeneficiary: 'Bénéficiaire en cas de vie',
  lpc_DeathBeneficiary: 'Bénéficiaire en cas de décès',
  lpc_NO: 'Non',
  lpc_claim_data: '',
  lpc_node: 'Nœud',
  lpc_sbj: 'Sujet',
  lpc_empty: 'vide',
  lpc_search_result: 'Résultats de la recherche',
  lpc_show_elemets: 'Afficher les éléments',
  lpc_insured: 'Assuré',
  lpc_notification_status: 'État de notification',
  lpc_nuova_denuncia: 'nouvelle notification',
  lpc_insured_policy: 'Polices assurées',
  lpc_product: 'Produit',
  lpc_find: 'Trouver',
  lpc_status: 'Statut',
  lpc_fund_trend: 'Tendance du fonds',
  lpc_category_fund: 'Profil/ Fonds',
  lpc_equivalent_on_date: 'Équivalent à la date',
  lpc_quotes_number: 'Nombre d\'unités',
  lpc_damages_policy: 'Police Dommages',
  lpc_life_policy: 'Police d\'assurance-vie',
  lpc_next_installment_premium: 'Prime de la prochaine échéance',
  lpc_no_result: 'Aucun résultat',
  lpc_data_value: 'Date de valeur de l\'action',
  lpc_paidAmount: 'Montant payé',
  lpc_total_gross_surrender: 'Total des rachats bruts',
  lpc_left_premiums_paid: 'Primes restantes payées',
  lpc_left_premiums_amount: 'Montant des primes restantes',
  lpc_portfolio_volatility_on_subscription_date: 'Volatilité du portefeuille à la date de souscription',
  lpc_portfolio_volatility: 'Volatilité du portefeuille le',
  lpc_andamento_controvalore: 'Tendance de la valeur nominale de la police',
  lpc_policy_trend: 'Tendance de la police',
  lpc_calculate: 'Calculer',
  lpc_frequency: 'Fréquence',
  lpc_date_variation_step: 'Variation de date',
  lpc_quotation_step: 'Cotation',
  lpc_confirm_variation_step: 'Confirmer la variation',
  lpc_financial_questionnaire_step: 'Questionnaire financier',
  lpc_close_button: 'Fermer',
  lpc_Operation_of_executed_correctly: 'Opération de {{operation}} exécutée correctement',
  lpc_AuthorizatioPolicyForwarded: 'La demande d\'autorisation pour la police numéro {{policyNumber}} a été transmise à l\'utilisateur approprié',
  lpc_are_you_sure_you_want_to_cancel_this_operation: 'Êtes-vous sûr de vouloir annuler cette opération?',
  lpc_cancel_button: 'Annuler',
  lpc_cancel_reservation: 'Annuler la réservation',
  lpc_system_error: 'Erreur système',
  lpc_generic_error_title: 'Erreur',
  lpc_select_label: 'Sélectionner',
  lpc_Warning_you_are_deleting_the_policyholder_Do_you_want_to_proceed: 'Avertissement, vous supprimez le titulaire de la police. Voulez-vous continuer?',
  lpc_factor_must_be_equal: 'Le {{factor}} doit être égal à {{amount}}',
  lpc_factor_must_be_greater_than: 'Le {{factor}} doit être supérieur à {{amount}}',
  lpc_factor_must_be_less_than: 'Le {{factor}} doit être inférieur à {{amount}}',
  lpc_Please_enter_threshold_percentage_value: 'Fonds : {{value}} - Veuillez saisir un pourcentage de seuil',
  lpc_please_enter_a_value_between: 'Veuillez saisir une valeur entre {{min}} et {{max}}',
  lpc_variation_step_label: 'Variation',
  lpc_contractual_option: 'Options contractuelles',
  lpc_costs_step_label: 'Coûts',
  lpc_variation_detail_step_label: 'Détails de la variation',
  lpc_ongoing_operations: 'Les valeurs peuvent être sujettes à des changements en raison d\'opérations en cours',
  lpc_exchange_rate: 'Taux de change',
  lpc_last_share_value: 'Dernière valeur de l\'action',
  lpc_movement_description: 'Description du mouvement',
  lpc_settlement_amount: 'Montant du règlement',
  lpc_Annual_SinglePremium: 'Prime annuelle/unique',
  lpc_policy_address: 'Adresse de la police',
  lpc_holders: 'Titulaires',
  lpc_last_revaluation: 'Dernière réévaluation',
  lpc_revalueted_benef: 'Avantage réévalué',
  lpc_investment: 'Investissements',
  lpc_position_number: 'Numéro de position',
  lpc_invested_premium: 'Prime investie',
  lpc_operation_details: 'Détails de l\'opération',
  lpc_contract: 'Contrat',
  lpc_dis_date: 'Date de désinvestissement',
  lpc_gross_amount: 'Montant brut',
  lpc_net_amount: 'Montant net',
  lpc_net: 'Net',
  lpc_quote: 'Cotation',
  lpc_quote_and_save: 'Coter et sauvegarder',
  lpc_info_investment_complete: 'Information : investissement terminé',
  lpc_effective_holders: 'Bénéficiaires effectifs',
  lpc_effective_holder: 'Bénéficiaire effectif',
  lpc_third_party_role: 'Tiers payeur',
  lpc_lien_credit: 'Type de créancier',
  lpc_end_validity_date: 'Date de fin de validité',
  lpc_start_date: 'Date de début',
  lpc_unit_linked_value: 'Valeur liée à l\'unité',
  lpc_allocation_profile: 'Profil d\'allocation',
  lpc_search_filter: 'Filtre de recherche',
  lpc_expiry_option_detail: 'Détail de l\'option d\'expiration',
  lpc_annuity_frequency: 'Fréquence de la rente',
  lpc_annuity_payment_type: 'Type de paiement de la rente',
  lpc_percentage_reversibility: 'Réversibilité en pourcentage',
  lpc_reversionary: 'Réversible',
  lpc_certain_revenue_years: 'Années de revenus certaines',
  lpc_postponement_type: 'Type de report',
  lpc_postponement_years: 'Années de report',
  lpc_clauses: 'Clauses',
  lpc_active_contractual_option: 'Option contractuelle active',
  lpc_expiry_option: 'Option d\'expiration',
  lpc_revaluation_history: 'Historique de réévaluation',
  lpc_monetary_fund: 'Fonds monétaire',
  lpc_benefit_effect_date: 'Date d\'effet des avantages',
  lpc_initial_benefit: 'Avantage initial',
  lpc_movement_update: 'Mise à jour du mouvement',
  lpc_update_effect_date: 'Date d\'effet de la mise à jour',
  lpc_net_rate: 'Taux net',
  lpc_macro_category: 'Macro-catégorie',
  lpc_quotation_frequency: 'Fréquence de cotation',
  lpc_volatility: 'Volatilité',
  lpc_volatility_date: 'Date de volatilité',
  lpc_start_quotation: 'Début de la cotation',
  lpc_last_quotation: 'Dernière cotation',
  lpc_valuation: 'Évaluation',
  lpc_percentage_total: 'Pourcentage total',
  lpc_euro_valuation_not_fund_currency: 'La valorisation est exprimée en € et non en devise du fonds',
  lpc_disinvstements: 'Désinvestissements',
  lpc_payment_amount: 'Montant du paiement',
  lpc_surrender_distribution: 'Distribution de la reddition',
  lpc_formula: 'Formule',
  lpc_roles_connected_benef: 'Rôles liés au bénéficiaire',
  lpc_quotation_details: 'Détails de la cotation',
  lpc_Disinvestmentsfunds: 'Fonds de désinvestissement',
  lpc_same_fund_distribution: 'Distribution de fonds identique au paiement initial',
  lpc_not_same_fund_distribution: 'Distribution de fonds modifiée par rapport au paiement initial',
  lpc_guarantees_oveview: 'Vue d\'ensemble des garanties',
  lpc_calculate_volatility: 'Calculer la volatilité des actifs',
  lpc_benef_death_not_same: 'Le bénéficiaire en cas de décès ne peut pas être la personne assurée',
  lpc_global_percentage_equal_hundred: 'Le pourcentage total doit être égal à 100',
  lpc_effective_holder_natural_person_req: '{{benefCat}} {{benefRole}} - La partie {{linkedName}} en tant que bénéficiaire effectif doit être une personne physique',
  lpc_benefPresente_default_msg: 'Le sujet a déjà été sélectionné comme bénéficiaire dans la même catégorie',
  lpc_effective_holder_duplicate: '{{linkedName}} a déjà été saisi en tant que bénéficiaire effectif de {{benefName}}',
  lpc_effective_holder_adult_req: '{{benefCat}} {{benefRole}} - La partie {{linkedName}} en tant que bénéficiaire effectif doit être adulte',
  lpc_investment_plan: 'Plan d\'investissement',
  lpc_contract_detail: 'Détail du contrat',
  lpc_variation_reason: 'Raison de la variation',
  lpc_investment_plan_detail: 'Détail du plan d\'investissement',
  lpc_revaluation_rate: 'Taux de réévaluation',
  lpc_benefit_commencement_date: 'Avantage initial',
  lpc_update_effective_date: 'Date d\'effet de la mise à jour',
  lpc_last_odds_value: 'Dernière valeur des chances',
  lpc_total: 'Total',
  lpc__EFFOP: 'Date d\'effet',
  lpc__OPZFR: 'Fractionnement',
  lpc__OPZPM: 'Méthode de paiement',
  lpc__OPBEN: 'Bénéficiaire',
  lpc__MINTS: 'Pourcentage de seuil minimum',
  lpc__MAXTS: 'Pourcentage de seuil maximum',
  lpc__NUMOC: 'Nombre d\'occurrences',
  lpc__AMTOP: 'Montant de rachat',
  lpc__MALMT: 'Montant minimum',
  lpc__CIBAN: 'IBAN',
  lpc__PCOUP: 'Pourcentage de coupon périodique',
  lpc_threshold_percentage: 'Pourcentage de seuil',
  lpc_pure: 'Prime pure',
  lpc_starting_fund: 'Fonds de départ',
  lpc_details: 'Détails',
  lpc_treshold: 'Seuil',
  lpc_inv_date: 'Date d\'investissement',
  lpc_beneficiary_perc_req: '{{benefCat}} {{benefRole}} {{benefName}}: Entrez un pourcentage',
  lpc_beneficiary_perc_req_range: ' {{benefCat}} {{benefRole}} {{benefName}}: Le pourcentage doit être compris entre 0,01 et 100',
  lpc_invalid_total_position_percent: 'Le montant en pourcentage pour le groupe "{{groupType}}" sur le bénéficiaire {{cat}} doit être de 100%',
  lpc_beneficiary_from_party_req: ' {{benefCat}} {{benefRole}}: Entrez un bénéficiaire du référentiel de la partie',
  lpc_benefPresente: '{{benefName}} a déjà été saisi en tant que {{benefCat}} {{benefRole}}',
  lpc_benMorteNoAssic: '{{benefName}} saisi en tant que {{benefCat}} {{benefRole}} a déjà été saisi comme assuré',
  lpc_life_beneficiary: 'Bénéficiaire en vie',
  lpc_death_beneficiary: 'Bénéficiaire en cas de décès',
  lpc_beneficiary_position_mandatory: 'Bénéficiaire {{benefCat}} {{benefName}}, sélectionnez un "Numéro de position"',
  lpc_reversionary_subject: 'Bénéficiaire réversionnaire',
  lpc_beneficiary_qualification: ' {{benefCat}} {{benefRole}} ',
  lpc_gross_detail: 'Détail de la prime brute',
  lpc_type_of_covers: 'Type de couvertures',
  lpc_clausesinsuredClauses: 'Clauses assurées',
  lpc_clausespolicyClauses: 'Clauses de police',
  lpc_clausesriskClauses: 'Clauses de risque',
  lpc_max_capital_amount: 'IMC - Montant maximum du capital',
  lpc_fiscal_period_M1: 'M1 - Montant accumulé au 31/12/2000',
  lpc_fiscal_period_M2: 'M2 - Montant accumulé du 1/1/2001 au 31/12/2006',
  lpc_fiscal_period_M3: 'M3 - Montant accumulé à partir du 1/1/2007',
  lpc_fiscal_period_capital_request_K1: 'Partie de M1 demandée en capital',
  lpc_fiscal_period_capital_request_K2: 'Partie de M2 demandée en capital',
  lpc_fiscal_period_capital_request_K3: 'Partie de M3 demandée en capital',
  lpc_max_amount_exceeded: 'Le montant demandé en capital dépasse le maximum possible.',
  lpc_max_amount_error: 'Le montant du capital demandé doit être entièrement alloué aux montants M1 et M2.',
  lpc_performance_data: 'Données de performance',
  lpc_role_is_mandatory: 'Vous devez saisir un(e) {{role}}',
  lpc_issue_authorized_proposal: 'Émettre une proposition/politique autorisée',
  lpc_confirm_prop_pol_auth: 'Confirmer la proposition/politique dans Autorisation',
  lpc_settlement: 'Indemnité de départ',
  lpc_annuity: 'Rente',
  lpc_percentage_in_capital: '% dans le capital',
  lpc_insured_amount: 'Montant Assuré',
  lpc_custom_field_mandatory_error: 'Le champ {{fieldName}} est obligatoire',
  lpc_print_docs: 'Imprimer des documents',
  lpc_revisionary_err: 'Entrer le sujet réversionnaire',
  lpc_revisionary_perc_err: 'Entrer le pourcentage du sujet réversionnaire',
  lpc_percentage_must_be_between: 'Le pourcentage doit être compris entre 0,01 et 100',
  lpc_PIP_UndeductedPremiums: '',
  lpc_contributionYear: '',
  lpc_undeductedPremium : '',
  lpc_reversionario: "Reversionary",
  lpc_annualPremium: '',
  lpc_firstEnrollmentComplementaryPension: '',
  lpc_PIP_amountForTaxation: '',
  lpc_PIP_amount: '',
  lpc_PIP_contribempl4Perc: '',
  lpc_PIP_reducttaxbltfr: '',
  lpc_PIP_aliqtfr: '',
  lpc_PIP_premdeducted2001: '',
  lpc_PIP_premdeductoffset: '',
  lpc_PIP_annuitytaxdeduct: '',
  lpc_pip_contributions_installment_detail: 'FR_Contributions EStails',
  lpc_contribution_type: 'FR_Contribution type',
  lpc_contribution_amount: 'FR_Amount',
  lpc_contribution_year: 'FR_Year',
  lpc_contribution_month_from: 'FR_Month (from)',
  lpc_contribution_month_to: 'FR_Month (to)',
  lpc_contribution_thirteenth: 'FR_13^',
  lpc_contribution_fourteenth: 'FR_14^',
  lpc_incomplete_fund_investment: 'Investissement incomplet pour les fonds',
  lpc_incomplete_profile_investment: 'Investissement incomplet pour les profils',
  lpc_tax_credit: '',
  lpc_clausesinsuredClause: '',
  lpc_clausespolicyClause: '',
  lpc_clausesriskClause: '',
};
export default LPC_FR;

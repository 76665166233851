import { NgModule } from '@angular/core';
import {QuestionnaireService} from "./questionnaire.service";
import {QuestionnaireResourcesService} from "./questionnaire-resources.service";



@NgModule({
  declarations: [],
  imports: [
  ],
  providers:[
    QuestionnaireService,
    QuestionnaireResourcesService
  ]
})
export class QuestionnaireResourceModule { }

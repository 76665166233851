import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectItem } from './dto/select-item';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'claims-select-autocomplete',
  templateUrl: './select-autocomplete.component.html',
  styleUrls: ['./select-autocomplete.component.scss']
})
export class SelectAutocompleteComponent implements OnInit {

  @Input() label: string;
  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();
  @Input() values: SelectItem[];
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() customFormGroup: UntypedFormGroup;
  @Input() customFormControlName: string;

  invalid: boolean;

  constructor() { }

  ngOnInit() {
  }

  onChange(event: any) {
    this.valueChange.emit(event.item);
  }

  onKeyUp(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    if (!target.value) {
      this.valueChange.emit(null);
    }
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.values.filter(v => v.value.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 200))
    )

  formatter = m => m.value;

  isValid(): boolean {
    if (this.customFormGroup.get(this.customFormControlName)) {
      return !this.customFormGroup.get(this.customFormControlName).dirty ||
        (this.customFormGroup.get(this.customFormControlName).dirty && !this.customFormGroup.get(this.customFormControlName).invalid);
    }
    return true;
  }

}

<div class="box-detail" *ngIf="contractDetails.substitutedPolicies && contractDetails.substitutedPolicies.length">
  <header class="box-detail-header" translate>_GP_._TITLE_._SUBSTITUTED_POLICIES_LIST</header>
  <div class="box-detail-body">
    <table rgi-rx-table [dataSource]="contractDetails.substitutedPolicies">
      <ng-container rgiRxColumnDef="linkedType">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._LABEL_._TYPE_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row">{{row.contractType | gpSubstVariationType | translate}}</td>
      </ng-container>
      <ng-container rgiRxColumnDef="policyNumber">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._LABEL_._POLICY_NUMBER_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.policy ? row.policyNumber : row.proposalNumber}}</td>
      </ng-container>
      <ng-container rgiRxColumnDef="policyEffectiveDate">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._LABEL_._EFFECTIVE_DATE_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.policyEffectDate | gpDate}}</td>
      </ng-container>
      <ng-container rgiRxColumnDef="movementEffectiveDate">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._LABEL_._MOVEMENT_EFFECTIVE_DATE_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.movementEffectDate | gpDate}}</td>
      </ng-container>
      <ng-container rgiRxColumnDef="product">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._LABEL_._PRODUCT_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.productDescription}}</td>
      </ng-container>
      <ng-container rgiRxColumnDef="action">
        <td rgi-rx-header-cell *rgiRxHeaderCellDef translate>_GP_._TITLE_._ACTIONS_</td>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          <button rg-rx-button class="rgi-ui-btn-outline rgi-ui-icon-search" (click)="openPolicyDetail(row)"></button>
        </td>
      </ng-container>
      <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedColumns"></tr>
      <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>

<lpc-stepper [formGroup]="formGroup" (stepSlide)="onSlide($event)" [isPublished]="publishMessage !== null">

  <lpc-step label="lpc_date_variation_step" fieldId="dates" id="dates" [errors]="errors" (next)="onNext($event)">
    <lpc-dates-step [inputFields]="dateDefinitions" [formControlName]="'dates'" (dateChange)="onDateChange($event)"
      (reloadRequest)="updateData()" [hide]="activeStep<1">
    </lpc-dates-step>
  </lpc-step>

  <lpc-step label="lpc_subject_roles" fieldId="roles" id="roles" [errors]="errors" (next)="onNext($event)">
      <div *ngIf="isActiveStep('roles') && roles?.length !== 0" class="row" data-qa="step-roles">
          <ng-container *ngFor="let subj of roles; index as i" formArrayName="roles">
            <div class="col-lg-6" [ngClass]="{'holder-box': subj.roleCode === HOLDER, 'roles-box': subj.roleCode !== HOLDER}">
              <label class="label-core">
                <span translate>{{subj.roleLabel}}</span>
              </label>
              <div class="input-group" formGroupName="{{i}}">
                <input
                     readonly
                     type="text"
                     class="form-control"
                     [value]="subj.name"
                     [attr.data-qa]="'name-box-'+subj.roleCode">
                <div class="input-group-btn">
                  <button type="button" class="btn btn-default" (click)="editSubject(subj.id, subj.roleCode)" [attr.data-qa]="'modify-' + subj.roleCode">
                    <span class="rgifont rgi-pencil"></span>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
      </div>
      <!-- riepilogo -->
      <div *ngIf="isAfterId('roles')" data-qa="summary-roles-step">
        <div class="row">
          <div class="col-lg-6">
            <span translate>lpc_modified_personal_data_for_the_subject</span>
            <span>: {{ getSummaryRoles().name }}</span>
          </div>
        </div>
      </div>
  </lpc-step>

  <lpc-step label="lpc_other_contract_list" fieldId="contracts" id="contracts" [errors]="errors" (next)="onNext($event)">
      <div *ngIf="isActiveOrPassedStep('contracts') && contractsList?.length !== 0 " class="row" data-qa="step-contracts">
          <lpc-contract-list-table
            formControlName="contractsList"
            [contractList]="contractsList">
          </lpc-contract-list-table>
      </div>
      <ng-container *ngIf="noPolicies">
        <div class="row">
          <div class="col-lg-6">
            <span translate>lpc_No_policies</span>
          </div>
        </div>
      </ng-container>
  </lpc-step>

  <lpc-step
    label="lpc_confirm_variation_step"
    [errors]="errors"
    fieldId="documents"
    id="documents"
    [nextVisible]=false>

    <lpc-document-wrapper
      *ngIf="!!publishMessage"
      [contractId]="getContractId"
      [operationCode]="getOperationCode"
      [publishMessage]="publishMessage"
      [isAuth]="isAuth"
      [authorizationId]="authorizationId"
      [printMode]="printMode"
      [config]="documentsConfig"
      (documentEvent)="handleDocumentEvent($event)">
    </lpc-document-wrapper>

    <lpc-navigation-buttons
      id="last-step-buttons"
      (back)="closeCardWithoutModal()"
      [backLabel]="'lpc_close_button'"
      [backVisible]="true"
      [nextVisible]="false">
    </lpc-navigation-buttons>

  </lpc-step>
</lpc-stepper>

<div class="btn-group btn-group-justified external" *ngIf="!isActiveStep('documents')">
    <lpc-navigation-buttons (back)="closeCard()" [nextVisible]="false" [qa]="'annulla'"></lpc-navigation-buttons>
</div>

<!-- <pre>
  <code>{{rawValue() | json}}</code>
</pre> -->

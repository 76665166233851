<div [formGroup]="formGroup" class="example-row">
  <div class="col-lg-6" >
    <pnc-checkbox formControlName="active"></pnc-checkbox>
    <span>{{definition.description}}</span>
  </div>
  <div class="col-lg-4" >
    <label class="switch">
      <input type="checkbox" formControlName="isPercent" class="form-control">
      <span class="slider" true="%" [attr.false]="currencyCode" ></span>
    </label>
    <label class="switch-value">
      <input #percent formControlName="percent" *ngIf="isPercent" [value]="formGroup.get('percent').value" class="form-control" placeholder="%" (blur)="roundValue('percent')"/>
      <input #amount formControlName="amount" *ngIf="!isPercent" [value]="formGroup.get('amount').value" class="form-control" placeholder="currencyCode" (blur)="roundValue('amount')"/>
    </label>
  </div>
  <div class="col-lg-2" id="investment-slider">
    <input class="slider-top" *ngIf="isPercent" formControlName="percent" step="0.01" type="range" min="0" max="100">
    <input class="slider-top" *ngIf="!isPercent" formControlName="amount" step="0.01" type="range" min="0" max="{{totalAmount}}">
    <div class="col-md-6 col-xs-6">0</div>
    <div class="col-md-6 col-xs-6" style="text-align: right;">{{isPercent? '100' : (totalAmount | rgiCountryLayerNumberFormat: currencyService.locale: {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2}) }}</div>
  </div>
</div>

<div class="">
  <div class="">
    <div class="">
      <div class="">
        <h3 class="">
          <span translate>{{ data.title }}</span>
          <span class="" (click)="close()"></span>
        </h3>
      </div>
      <div class="">
        <form [formGroup]="validationFileForm">
          <div class="">
            <label translate>RGI_PORTAL_COMPONENTS.DOCUMENT_VALIDATION_MESSAGE</label>
            <span (click)="data.file.validate = !data.file.validate"
                  class="" type="checkbox">
                            <span class="" *ngIf="data.file.validate">
                            </span>
                        </span>
          </div>
          <div class="">
            <div>
              <label translate>RGI_PORTAL_COMPONENTS.NOTE</label>
            </div>
            <div>
<!--              FIXME: remove ngModel-->
<!--              <textarea formControlName="note" [rows]="5" class="" [(ngModel)]="data.file.note"></textarea>-->
            </div>
          </div>
        </form>
      </div>
      <div class="">
        <div class="">
          <div class="">
            <button class="" (click)="confirmValidationFile()" translate>RGI_PORTAL_COMPONENTS.SAVE</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="analytics-dashboard">
  <h1 class="analytics-report-title">
      <span class="rgi-ui-icon-document"></span>
      {{report.description}}
      <span class="rgi-ui-icon-settings" style="margin-left:auto;" (click)="modal2.open()"></span>
  </h1>

  <rgi-rx-modal #modal2>
    <rgi-rx-panel>
      <rgi-rx-panel-header [closeable]="true" (onClose)="modal2.close()">SETTINGS</rgi-rx-panel-header>
      <rgi-rx-panel-footer>
        <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="modal2.close()">close</button>
      </rgi-rx-panel-footer>
      <div class="analytics-filters-switch-container">
        <h5 *ngIf="!areFiltersVisible" class="analytics-filters-switch">{{ '_SHOW_FILTERS_' | translate}}</h5>
        <h5 *ngIf="areFiltersVisible" class="analytics-filters-switch">{{ '_HIDE_FILTERS_' | translate}}</h5>
        <rgi-rx-switch [(ngModel)]="areFiltersVisible"></rgi-rx-switch>
      </div>
    </rgi-rx-panel>
  </rgi-rx-modal>


  <div class="analytics-dashboard-content">
    <div class="toolbar" style="height: 50px;" ></div>

    <div class="analytics-filter-container" *ngIf="showCharts" [ngClass]="{'analytics-show-filters' :
    areFiltersVisible,'analytics-hide-filters': !areFiltersVisible}">
        <div class="analytics-filters" *ngFor="let filter of reportDetail.elements | chartFilterPipe:reportFilterPipe;
        let i = index"
            style="height: inherit;">
            <div class="analytics-filter-box">
              <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                <div [rgiRxAnimatedLoading]="getLoading$(filter.idQlikElement)" loaderClass="analytics-loader"
                     class="filter{{filter.id}}" style="height: 200px; width: 100%; padding: 10px;"></div>
              </div>
            </div>
        </div>

    </div>


    <div style="height: 100vh; margin-top: 20px;" *ngIf="showCharts" >
        <gridster [options]="options">
          <gridster-item [item]="item" *ngFor="let item of dashboard;">
            <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
              <div style="width: 100%; height: 100%; display: flex; align-items: flex-start;
                   text-align: center;" class="chart{{item.id}}"
                   [rgiRxAnimatedLoading]="getLoading$(item.id)" [delay]="2000" loaderClass="analytics-loader">
                <span class="drag rgi-ui-btn rgi-ui-icon-move"></span>
              </div>
            </div>
          </gridster-item>
        </gridster>
    </div>


    <rgi-rx-panel-footer style="margin-top:20px;">
        <button rgi-rx-button color="secondary" (click)="closeSession()" style="margin: 0.1em;">
            {{'_BACK_BTN_ '| translate | uppercase}}
        </button>
        <button rgi-rx-button color="primary" (click)="editReport()" style="margin: 0.1em;"
                *ngIf="isAdminFunctionEnabled" [disabled]="modifyReportDisabled">
            {{'_MODIFY_REPORT_BTN_' | translate| uppercase}}
        </button>
        <button rgi-rx-button color="primary" (click)="copyReportConfirm()" style="margin: 0.1em;"
                *ngIf="isAdminFunctionEnabled">
            {{'_COPY_REPORT_BTN_' | translate| uppercase}}
        </button>
        <button rgi-rx-button color="primary" (click)="openQlikSense()" style="margin: 0.1em;">
            {{ '_OPEN_APP_QLIK_' | translate| uppercase}}
        </button>
        <button rgi-rx-button color="primary" (click)="saveReport()" style="margin: 0.1em;"
                *ngIf="isAdminFunctionEnabled">
          {{ '_SAVE_BTN_' | translate| uppercase}}
        </button>
        <button rgi-rx-button color="danger" (click)="deleteReportConfirm(report)" style="margin: 0.1em;"
            *ngIf="isAdminFunctionEnabled">
            {{'_DELETE_REPORT_BTN_' | translate| uppercase}}
        </button>
    </rgi-rx-panel-footer>
  </div>
</div>

<div [formGroup]="formGroup">
  <div data-qa="list">
      <div class="title-content-card" translate>lpc_search_result</div>
    <div class="col-lg-6 col-sm-6 padding-bottom25-smartphone">
      <span class="styled-select">
        <ng-select class="col-lg-12 col-sm-6 col-xs-6" id="custom" [clearable]="false" formControlName="count" (change)="handlePageSizeChange($event)">
        <ng-option *ngFor="let size of pageSizes" [value]="size">
            {{ size }}
        </ng-option>
      </ng-select>
      </span>
      <div class="line col-sm-6 col-xs-6" translate>lpc_elements</div>
    </div>
    <div class="col-lg-6 col-sm-6 padding-top-life-45-xs">
      <input placeholder="{{'Find'}}" formControlName="search" name="filter">
    </div>

    <div class="row" style="margin-top: 50px;">
      <div class="col-md-12">
        <div class="tbld life-detail-margin-top">
          <div class="tbld_row life-detail-tbld_row_header">
            <div class="tbld_col tbld_col_title" translate>lpc_description</div>
            <div class="tbld_col tbld_col_title" translate>{{!!isFundList() ? 'lpc_fund_type' : 'lpc_profile_type'}}</div>
            <div class="tbld_col tbld_col_title" translate>lpc_status</div>
            <div class="tbld_col tbld_col_title"></div>
          </div>
          <ng-container *ngFor="let element of elements | paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count }">
            <div class="tbld_row">
              <div class="tbld_col tbld_col_value">{{element.name}}</div>
              <div class="tbld_col tbld_col_value">{{!!isFundList() ? element.fundType : element.type }}</div>
              <div class="tbld_col tbld_col_value">{{element.status}}</div>
              <div class="tbld_col tbld_col_value" style="text-align: center">
                <button (click)="openSession(element)" type="button" class="btn dt-btn">
                    <span class="rgifont rgi-chevron-right"></span>
                  </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
      <div class="right-align">
        <pagination-controls previousLabel=""
        nextLabel="" (pageChange)="handlePageChange($event)" responsive="true"></pagination-controls>
    </div>
  </div>
  <div class="btn-group btn-group-justified">
    <div class="btn-group" ng-controller="ContainerSessionCtrl">
      <button type="button" (click)="cancel()" class="btn btn-warning btn-secondary pull-right text-uppercase" translate>lpc_BACK</button>
    </div>
  </div>
</div>


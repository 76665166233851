<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit(actions.CLOSE)">
    {{title}}
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button rgi-rx-button color="primary" data-qa="rgi-gp-btn-cancel"
            (click)="modalClose.emit(actions.CLOSE)"
            translate>
      _GP_._BTN_._UNDO_
    </button>
    <button rgi-rx-button color="primary" data-qa="rgi-gp-btn-confirm"
            (click)="modalClose.emit(actions.CONFIRM)"
            translate>
      _GP_._BTN_._CONFIRM_
    </button>
  </rgi-rx-panel-footer>
  <p>{{text}}</p>
</rgi-rx-panel>

<div class="core-header-icon re-issue-card-styles">
  <span class="rgi-re-header-icon rgifont rgi-questionari nfm_font_size"></span>
  <span class="header-text" translate>RE_ISSUE.QUESTIONNAIRES</span>&nbsp;
  <span class="pull-right header-text"> </span>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-sm-5 col-xs-11">
            <span class="rgifont float-left" [class.rgi-survey_ok]="(questionnaireAdequate && questionnaireComplete)"
                  [class.rgi-survey_ko]="(!questionnaireAdequate || !questionnaireComplete)"
                  [ngClass]="(questionnaireAdequate && questionnaireComplete) ? 'isValid':'isInvalid'"></span>

      <div class="container-fluid  quest-background">
        <div class="row">
          <div class="col-sm-12 col-xs-12">
                        <span class="nmf_cont_titsez nmf_cont_titsez_color">
                            {{questionnaire?.description}}
                        </span>

          </div>
        </div>

        <div class="row">
          <div class="col-sm-11 col-xs-11" *ngIf="!questionnaireSubmitted && !questionnaireComplete">
            <span translate>RE_ISSUE.NOT_COMPILED</span>
          </div>
          <div class="col-sm-11 col-xs-11" *ngIf="questionnaireSubmitted || questionnaireComplete">
            <span *ngIf="!questionnaireAdequate" translate>Not Adequate</span>
            <span *ngIf="questionnaireAdequate" translate>Adequate</span>
          </div>
          <div class="col-sm-1 col-xs-1">
            <button (click)="openQuestionnaireModal()" class="drag-del-item border-transparent">
              <span class="rgifont float-right rgi-pencil-square-o"></span>
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-xs-12">
                        <span class="quest-font" translate>
                           RE_ISSUE.TO_BE_FILLED_IN_BY_THE_SUBJECT
                        </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

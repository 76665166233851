import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExternalPortfolioExtrainfoService {

  private baseApiUrl;
  private urlservice = '/claims/externalportfolioExtraInfo';
  private urlserviceUpdate = '/claims/externalportfolioExtraInfo/update';

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any
  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
  }


  getListExtrainfo(idPotencialClaim) {
    return this.httpClient.get(this.baseApiUrl + this.urlservice + '?idPotencialClaim=' + idPotencialClaim);
  }

  updateExtraInfo(idExtraInfo: string, unit: string, rischio: string) {
    let queryString = '?';
    queryString += 'idExtraInfo=' + idExtraInfo + '&';
    queryString += 'unit=' + unit + '&';
    queryString += 'rischio=' + rischio;

    return this.httpClient.put(this.baseApiUrl + this.urlserviceUpdate + queryString, null);
  }
}

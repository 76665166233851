import { LoaderService } from './loader.service';
import {Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { EMPTY_STR, PV_TOKEN } from '../models/consts/lpc-consts';
import {_DOCUMENT_} from '../models/document.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  baseApiUrl: any;
  operator: any;

  constructor(
    private httpClient: HttpClient,
    @Inject(PV_TOKEN.ENV) environment: any,
    @Inject(PV_TOKEN.CORE_INJECTOR) private injector: any,
    protected loaderService: LoaderService
  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    const authService = this.injector.get('authService');
    this.operator = authService.getOperator();
  }

  public getOperatorUsername(): string {
    return this.operator.username;
  }

  public getDocumentList(idPolicy: string, operationCode: string, documentCodes: string[], printMode: string): Observable<any> {
    let filterReq = null;
    if (!!printMode && printMode !== EMPTY_STR) { // TODO: togliere if dopo merge
      filterReq = {
        documentsFilter: {
          idPolicy,
          operationCode,
          documentCodes,
          userCode: this.getOperatorUsername(),
          printMode
        },
        saveDocuments: false,
        generateDocuments: false
      };
    } else {
      filterReq = {
        documentsFilter: {
          idPolicy,
          operationCode,
          documentCodes,
          userCode: this.getOperatorUsername()
        },
        saveDocuments: false,
        generateDocuments: false
      };
    }
    return this.loaderService.registerBeCall(
      this.httpClient.post(this.baseApiUrl + '/ptflife/proposal/documents', {filter: filterReq}, {})
    );
  }

  downloadDocument(idMovement: string, documentCode: string): Observable<{fileUrl: string, code: string}> {
    if (!idMovement) {
      console.error('Movement id null');
      return of(null);
    }
    const body = [{
      code: documentCode
    }];
    return this.httpClient.put<{fileUrl: string, code: string}>(`${this.baseApiUrl}/v2/life/movement/${idMovement}/documents`, body)
      .pipe(
        map(response => this.addExtension(response[0]))
      );
  }

  addExtension(response: {fileUrl: string; code: string}): {fileUrl: string; code: string} {
    const arrayUrl =  response.fileUrl.split('/');
    const fileName = arrayUrl.pop();
    const arrayfilename = fileName.split('.');
    if (arrayfilename.length === 1) {
      return {
        fileUrl: `${response.fileUrl}.${_DOCUMENT_.EXTENSION_PDF}`,
        code: response.code
      };
    }
    return response;
  }

  callDocument(documentUrl): Observable<any> {
    return this.httpClient.get(`${this.baseApiUrl}/system${documentUrl}`, {responseType: 'arraybuffer'});
  }

  getDocumentContentTypeByURL(url) {
    const arrayUrl =  url.split('/');
    const fileName = arrayUrl.pop();
    const arrayfilename = fileName.split('.');
    const extension = arrayfilename.pop();
    switch (extension) {
      case _DOCUMENT_.EXTENSION_PDF:
        return 'application/pdf';
      case _DOCUMENT_.EXTENSION_XLS:
        return 'application/vnd.ms-excel';
      case _DOCUMENT_.EXTENSION_XLSX:
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case _DOCUMENT_.EXTENSION_CSV:
        return 'text/csv';
      case _DOCUMENT_.EXTENSION_HTML:
        return 'text/html';
      case _DOCUMENT_.EXTENSION_RTF:
        return 'application/rtf';
      default:
        return 'application/pdf';
    }
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnviromentsService } from '../../services/enviroments.service';
import { PassProfileData } from '../model/PassProfileData';
import { SustainabilityResponse, VolatilityResponse } from '../model/PassResponse';

@Injectable()
export class FundService {

  private baseApiUrl: string;

  constructor(
    protected environment: EnviromentsService,
    private http: HttpClient
  ) {
    this.baseApiUrl = environment.baseApiURL;
  }

  public calculateSustainability(
    funds: PassProfileData[],
    effectDate: string,
    productCode: string,
    netPremiumAmount: number,
    managementNode: string
  ): Observable<SustainabilityResponse> {
    const request: any = {
      nodeCode: managementNode,
      productCode,
      netPremiumAmount,
      effectDate,
      profiles: funds
    };
    return this.http.post<SustainabilityResponse>(this.baseApiUrl + '/v2/life/funds/sustainability', request);
  }

  public calculateVolatility(
    funds: PassProfileData[],
    effectDate: string,
    idProduct: string,
    netPremiumAmount: number,
    idContraente: number,
    managementNode: string
  ): Observable<VolatilityResponse> {
    const request: any = {
      managementNode,
      idSoggetto: idContraente,
      idProdotto: idProduct,
      effectDate,
      premiumDemand: {
        netPremiumAmount,
        investmentType: 1,
        profAlloc: funds
      }
    };
    return this.http.post<VolatilityResponse>(this.baseApiUrl + '/ptflife/proposal/volatility', request);
  }
}


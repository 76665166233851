import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CaiOpeningState, OutputDataFormSubjectHolder } from '../../config-model/cai-opening-config.module';
import { Observable } from 'rxjs';
import { VehicleData } from '../../../shared/dsc-shared-model/dsc-model-vehicle';
import { OpenTypeSharedComponent } from '../../../shared/dsc-shared-model/dsc-model-enums';
import { PolicyData } from '../../../shared/dsc-shared-model/dsc-model-policy';
import { CarBodywork } from '../../../shared/dsc-shared-model/dsc-model-bodywork';
import { IncidentData } from '../../../shared/dsc-incident-data/dsc-incident-data.model';
import { AddressEntityData } from '../../../shared/dsc-shared-model/dsc-model-address';
import { CircumstanceIncident } from '../../../shared/dsc-circumstances-data/dsc-circumstances-model/dsc-circumstances.config';
import { AdditionalData } from '../../../shared/dsc-additional-data/dsc-additional-data.model';
import { Guardian } from '../../../shared/dsc-shared-model/dsc-model-subject';

@Component({
    selector: 'claims-cai-opening-single',
    templateUrl: './cai-opening-single.component.html',
    styleUrls: ['./cai-opening-single.component.scss']
})

export class CaiOpeningSingleComponent {

    constructor() {}

    enumTypeOpenTitle: OpenTypeSharedComponent = OpenTypeSharedComponent.isTitleComponent;
    enumIsClaim: OpenTypeSharedComponent = OpenTypeSharedComponent.isClaim;
    enumTypeOpenAccordion: OpenTypeSharedComponent = OpenTypeSharedComponent.isAccordion;
    enumTypeIsNewClaimReopen: OpenTypeSharedComponent = OpenTypeSharedComponent.isNewClaimReopen;
    enumTypeIsReopened: OpenTypeSharedComponent = OpenTypeSharedComponent.isReopened;
    @Input() activeRouteId: string;
    @Input() selectedNode: string;
    @Input() selectedCategories: any;
    @Input() caiOpeningState$: Observable<CaiOpeningState>;
    @Output() outputPolicyHolder = new EventEmitter<OutputDataFormSubjectHolder>();
    @Output() outputPartyRole = new EventEmitter<string>();
    @Output() outputVehicle = new EventEmitter<VehicleData>();
    @Output() outputPolicyData = new EventEmitter<PolicyData>();
    @Output() outputOwner = new EventEmitter<OutputDataFormSubjectHolder>();
    @Output() outputDriver = new EventEmitter<OutputDataFormSubjectHolder>();
    @Output() outputDamagedParts = new EventEmitter<VehicleData>();
    @Output() outputCarBodywork = new EventEmitter<CarBodywork>();
    @Output() outputClaimParties = new EventEmitter<any>();
    @Output() outputIncidentData = new EventEmitter<IncidentData>();
    @Output() outputAddress = new EventEmitter<AddressEntityData>();
    @Output() outputCircumstance = new EventEmitter<CircumstanceIncident>();
    @Output() outputAdditionalData = new EventEmitter<AdditionalData>();
    @Output() outputAddressDataDamagedParts = new EventEmitter<AddressEntityData>();
    @Output() outputFormCheckHowner = new EventEmitter<OutputDataFormSubjectHolder>();
    @Output() outputHolderHeir = new EventEmitter<string>();
    @Output() outputOwnerHeir = new EventEmitter<string>();
    @Output() outputDriverHeir = new EventEmitter<string>();
    expanded: boolean = true;

    outputPolicyHolderForm(event: OutputDataFormSubjectHolder) {
        this.outputPolicyHolder.emit(event);
    }
    outputVehicleForm(event: VehicleData) {
        this.outputVehicle.emit(event);
    }
    outputPolicyDataForm(event: PolicyData) {
        this.outputPolicyData.emit(event);
    }
    outputOwnerForm(event: OutputDataFormSubjectHolder) {
        this.outputOwner.emit(event);
    }
    outputDriverForm(event: OutputDataFormSubjectHolder) {
        this.outputDriver.emit(event);
    }
    outputDamagedPartsForm(event: VehicleData) {
        this.outputDamagedParts.emit(event);
    }
    outputCarbodyworkForm(event: CarBodywork) {
        this.outputCarBodywork.emit(event);
    }

    outputFormIncidentData(event: IncidentData) {
        this.outputIncidentData.emit(event);
    }
    outputFormAddress(event: AddressEntityData) {
        this.outputAddress.emit(event);
    }
    outputFormCircumstanceIncident(event: CircumstanceIncident) {
        this.outputCircumstance.emit(event);
    }
    outputFormAdditionalData(event: AdditionalData) {
        this.outputAdditionalData.emit(event);
    }
    setAccordion() {
        this.expanded = !this.expanded;
    }

    outputAddressData(event: AddressEntityData) {
        this.outputAddressDataDamagedParts.emit(event)
    }

    outputHolderHeirData(event) {
        this.outputHolderHeir.emit(event);
      }
    outputOwnerHeirData(event) {
        this.outputOwnerHeir.emit(event);
      }
    outputDriverHeirData(event) {
        this.outputDriverHeir.emit(event);
      }
}


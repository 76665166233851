import {Injectable} from '@angular/core';
import {ReIssuePrintsService} from '../re-issue-resources/re-issue-prints.service';
import {Observable} from 'rxjs';
import {DocumentPrint} from '../../models/api-models/document-print';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePrintsBusinessService {


  constructor(
    private issuePrintsService: ReIssuePrintsService
  ) {
  }

  getPrint(idPol: string): Observable<DocumentPrint> {
    return this.issuePrintsService.get(idPol);
  }

  getPrintPolicy(policyNumber: string): Observable<DocumentPrint> {
    return this.issuePrintsService.getPolicy(policyNumber);
  }
}

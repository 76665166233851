import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RgiRxUserService} from '@rgi/rx/auth';
import {RestApiConf} from '../anag-constants/rest-api-conf';
import {OperatorLight} from '../services/external-service';
import {AnagSubjectsFilter} from '../anag-model/anag-api/anag-subjects-filter';
import {AnagApiSubjects} from '../anag-model/anag-api/anag-api-subjects';
import {AnagApiSubappCountries} from '../anag-model/anag-api/anag-api-subapp-countries';
import {
  AnagApiPartyKeyConfigRequest,
  AnagPartyUniqueKeyResponse
} from '../anag-model/anag-api/anag-api-party-key-config-request';
import {AnagPartyKeyResponse} from '../anag-model/anag-api/anag-party-key-response';
import {Agency} from '../anag-model/anag-api/sales-network-api-agency';
import {EnumsApiDetail, EnumsApiList, EnumsApiReq} from '../anag-model/enum-api/enums-api';
import {
  AnagApiConfiguration,
  AnagApiConfigurationFilterRequest,
  AnagApiPartyTypeConfig,
  AnagApiPartyTypeConfigRequest
} from '../anag-model/anag-api/anag-api-configuration-filter';
import {AnagApiSystemEnumsTable} from '../anag-model/anag-api/anag-api-system-enumstable';
import {
  AnagApiSystemPropertiesRequest,
  AnagApiSystemPropertiesResponse
} from '../anag-model/anag-api/anag-api-system-properties';
import {AnagApiCreateSubject, AnagApiSaveSubjectResponse} from '../anag-model/anag-api/anag-api-create-subject';
import {AnagApiParty, AnagPartyKeyService} from '../anag-model/anag-domain/anag-api-party';
import {AnagApiSubject} from '../anag-model/anag-api/anag-api-subject';
import {AnagApiInternationalPrefixesResp} from '../anag-model/anag-api/anag-api-international-prefixes';
import {AnagDeleteResponse} from '../anag-model/anag-domain/anag-api-ptfall-outcome';
import {AvailableLinksResponse} from '../anag-model/anag-api/available-links-response';
import {MeansOfPaymentAnag, PaymentConfigAnag} from '../anag-model/anag-domain/anag-payments';
import {OutputMajorAge} from "../anag-model/anag-api/anag-api-major-age";
import {
  AnagCheckDrivingLicenseRequest,
  AnagCheckDrivingLicenseResponse,
  AnagDrivingLicenseTypeList
} from "../anag-model/anag-domain/anag-document";
import {AnagCompanies} from "../anag-model/anag-domain/anag-company";


@Injectable({
  providedIn: 'root'
})
export class AnagResourceService {

  hostPath: string;
  nodeId: string;

  constructor(
    private httpClient: HttpClient,
    private userService: RgiRxUserService,
    @Inject('environment') private apiConf: RestApiConf) {
    this.hostPath = this.apiConf.api.portal.host + this.apiConf.api.portal.path;

  }

  findParties$(request: AnagSubjectsFilter) {
    return this.httpClient.post<AnagApiSubjects>(`${this.hostPath}/anag/subjects`, request);
  }

  retrieveCountries$() {
    return this.httpClient.get<AnagApiSubappCountries>(`${this.hostPath}/subapp/countries`);
  }

  checkPartyKey$(partyKeyConfigFilter: AnagPartyKeyService) {
    return this.httpClient.post<AnagPartyKeyService>(`${this.hostPath}/anag/checkpartykey`, partyKeyConfigFilter);
  }

  duplicateParty$(partyFilter: AnagApiParty) {
    return this.httpClient.post<AnagApiParty>(`${this.hostPath}/anag/duplicateparty` + '?nodeCode=' + partyFilter.node.code, {party: partyFilter});
  }

  getPartyKeyConfig$(partyKeyConfigFilter: AnagApiPartyKeyConfigRequest) {
    return this.httpClient.post<AnagPartyKeyResponse>(`${this.hostPath}/anag/partykeyconfig`, partyKeyConfigFilter);
  }

  getPartyMajorAge$(codCountry: string): Observable<OutputMajorAge> {
    return this.httpClient.get<OutputMajorAge>(`${this.hostPath}/v2/party/getMajorAge` + '?codCountry=' + codCountry);
  }

  getEnumsConfig$(enums: EnumsApiReq): Observable<EnumsApiList> {
    return this.httpClient.post<EnumsApiList>(`${this.hostPath}/system/enums`, enums);
  }

  getCitizenshipPermitTypes$(countryCode: string): Observable<any> {
    const query = '?' + 'codCountry=' + countryCode;
    return this.httpClient.get<any>(`${this.hostPath}/v2/party/getResidentPermitType` + query);
  }

  getEnumByCode$(enumCode: string, value: string): Observable<EnumsApiDetail> {
    let params = new HttpParams();
    params = params.append('searchType', '323');
    params = params.append('enumCode', value);
    return this.httpClient.get<EnumsApiDetail>(`${this.hostPath}/system/enum/${enumCode}`, {params});
  }

  getEnumtable(enumTable: string): Observable<AnagApiSystemEnumsTable> {
    return this.httpClient.get<AnagApiSystemEnumsTable>(`${this.hostPath}/system/enumtable/${enumTable}`);
  }

  getSystemProperties(request: AnagApiSystemPropertiesRequest): Observable<AnagApiSystemPropertiesResponse> {
    return this.httpClient.post<AnagApiSystemPropertiesResponse>(`${this.hostPath}/system/systemproperty`, request);
  }

  getNodes$(): Observable<Agency[]> {
    let params = new HttpParams();
    if (this.userService.isLoggedIn()) {
      params = params.append('loginNodeId', this.userService.getUser<OperatorLight>().salePoint.objectId);
      params = params.append('showGroupingNodes', 'true');
    }
    return this.httpClient.get<Agency[]>(`${this.hostPath}/v2/common/agencies`, {params});
  }

  getNodesSinistri$(valueSx: string): Observable<Agency> {
    let params = new HttpParams();
    params =   params.append('codeSp', valueSx);
    params = params.append('loadOverTreePOS', 'true');
    return this.httpClient.get<Agency>(`${this.hostPath}/v2/common/agencies`, {params});
  }


  getPartyTypeConfig$(partyTypeReq: AnagApiPartyTypeConfigRequest): Observable<AnagApiPartyTypeConfig> {
    let params = new HttpParams();
    if (partyTypeReq.personType) {
      params = params.append('personType', '' + partyTypeReq.personType);
    }
    if (partyTypeReq.legalForm) {
      params = params.append('legalForm', '' + partyTypeReq.legalForm);
    }
    if (partyTypeReq.professionCode) {
      params = params.append('professionCode', '' + partyTypeReq.professionCode);
    }
    return this.httpClient.get<AnagApiPartyTypeConfig>(`${this.hostPath}/anag/subjecttype`, {params});
  }

  getPartyConfig$(partyConfigReq: AnagApiConfigurationFilterRequest): Observable<AnagApiConfiguration> {
    return this.httpClient.post<AnagApiConfiguration>(`${this.hostPath}/anag/party/configuration`, partyConfigReq);
  }

  saveParty(anagApiCreateSubject: AnagApiCreateSubject) {
    let params = new HttpParams();
    anagApiCreateSubject.subject.mainSubject = true;
    params = params.append('idNode', `${anagApiCreateSubject.subject.node.identification}`);
    if (anagApiCreateSubject.subject.links) {
      anagApiCreateSubject.subject.links.forEach(link => {
        params = params.append('idObjectLinkSubject', link.linkedPartyObjectId);
      });
    }
    return this.httpClient.post<AnagApiSaveSubjectResponse>(`${this.hostPath}/anag/subject`, anagApiCreateSubject, {params});
  }

  calculatePartyKey$(party: AnagApiParty): Observable<AnagPartyUniqueKeyResponse> {
    return this.httpClient.post<AnagPartyUniqueKeyResponse>(`${this.hostPath}/anag/partyuniquekey`, {party});
  }

  getSubjectDetail$(idSubject: string, idNode: string, nodeCode: string): Observable<AnagApiSubject> {
    let params = new HttpParams();
    if (idNode) {
      params = params.append('idNode', idNode);
    }
    if (nodeCode) {
      params = params.append('nodeCode', nodeCode);
    }
    return this.httpClient.get<AnagApiSubject>(`${this.hostPath}/anag/subject/${idSubject}`, {params});
  }

  getInternationalPrefixes$() {
    return this.httpClient.get<AnagApiInternationalPrefixesResp>(`${this.hostPath}/anag/internationalPrefixes`);
  }
  getIntPrefixes$(countryCode: string) {
    let params = new HttpParams();
    if (countryCode) {
      params = params.append('codCountry', countryCode);
    }
    return this.httpClient.get<AnagApiInternationalPrefixesResp>(`${this.hostPath}/v2/party/getIntPrefix`, {params});
  }

  getDrivingLicenseType$(countryCode: string) {
    let params = new HttpParams();
    if (countryCode) {
      params = params.append('codCountry', countryCode);
    }
    return this.httpClient.get<AnagDrivingLicenseTypeList[]>(`${this.hostPath}/v2/party/getDrivingLicenseType`, {params});
  }

  checkDrivingLicense$(body: AnagCheckDrivingLicenseRequest) {
    return this.httpClient.post<AnagCheckDrivingLicenseResponse>(`${this.hostPath}/v2/party/checkDrivingLicense`, body)
  }

  deleteSubject(nodeId: string, subjectId: string, deleteMode: string): Observable<AnagDeleteResponse> {
    let params = new HttpParams();
    if (nodeId) {
      params = params.append('nodeId', nodeId);
    }
    if (subjectId) {
      params = params.append('subjectId', subjectId);
    }
    if (deleteMode) {
      params = params.append('deleteMode', deleteMode);
    }
    return this.httpClient.delete<AnagDeleteResponse>(`${this.hostPath}/anag/party/deleteSubject`, {params});
  }

  getLinkTypes$(idSubjectType: string, idLinkSubjectTypes: string[]): Observable<AvailableLinksResponse> {
    let params = new HttpParams();
    if (idLinkSubjectTypes) {
      idLinkSubjectTypes.forEach(idLinkSubjectType => {
        params = params.append('idLinkSubjectType', idLinkSubjectType);
      });
    }
    if (idSubjectType) {
      params = params.append('idSubjectType', idSubjectType);
    }
    return this.httpClient.get<AvailableLinksResponse>(`${this.hostPath}/anag/linkSubjectType`, {params});
  }

  retrievePaymentTypes$(paymentConfigInput: PaymentConfigAnag): Observable<MeansOfPaymentAnag> {
    paymentConfigInput.nodeId = this.nodeId !== null && this.nodeId !== undefined ?
      this.nodeId : this.userService.getUser<OperatorLight>().salePoint.objectId;
    return this.httpClient.post<MeansOfPaymentAnag>(`${this.hostPath}/admin/meansofpayment`, {paymentConfigInput});
  }

  setNodeId(nodeid: string) {
    this.nodeId = nodeid;
  }

  getCompanies$(): Observable<AnagCompanies> {
    return this.httpClient.get<AnagCompanies>(`${this.hostPath}/v2/common/companies/internal`);
  }

}

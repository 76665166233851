import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxFormElementsModule} from '../form-elements/rgi-rx-form-elements.module';
import {RgiRxAutoCompleteComponent} from './rgi-rx-auto-complete/rgi-rx-auto-complete.component';
import {RgiRxAutoCompleteDirective} from './rgi-rx-auto-complete.directive';
import {RgiRxAutoCompleteContentDirective} from './rgi-rx-auto-complete-content.directive';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OverlayModule} from '@angular/cdk/overlay';
import {RgiRxBadgeModule} from '../badge/rgi-rx-badge.module';
import {A11yModule} from '@angular/cdk/a11y';

@NgModule({
  declarations: [RgiRxAutoCompleteDirective, RgiRxAutoCompleteComponent, RgiRxAutoCompleteContentDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RgiRxFormElementsModule,
    OverlayModule,
    RgiRxBadgeModule,
    A11yModule
  ],
  exports: [RgiRxAutoCompleteDirective, RgiRxAutoCompleteComponent, RgiRxAutoCompleteContentDirective]
})
export class RgiRxAutoCompleteModule {
}




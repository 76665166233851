import { ApiEnumType } from '@rgi/digital-claims-common-angular';
import { LimitClaimDto } from './limit-claim-dto';
import { LimitDetailDto } from './limit-detail-dto';

export class LimitDto {

  public id: number;
  public type: ApiEnumType;
  public startValidityDate: string;
  public endValidityDate: string;
  public initialAmount: number;
  public level: ApiEnumType;
  public levelCode: ApiEnumType;
  public regime: ApiEnumType;
  public productDesc: string;
  public grossExcess: boolean;
  public factorCode: string;
  public name: string; // Description
  public remainingAmount: number;
  public subtype: ApiEnumType;
  public subtypeCode: ApiEnumType;
  public masterPolicy: boolean;
  public unique: boolean;
  public active: boolean;
  public counterByTyepeOfLoss: number;

  // CAMPI DETTAGLIO
  public limitDetailList: LimitDetailDto[];

  // CAMPI SINISTRI
  public limitClaimList: LimitClaimDto[];

  constructor(init?: Partial<LimitDto>) {

    this.type = init ? init.type : new ApiEnumType('', '');
    this.level = init ? init.level : new ApiEnumType('', '');
    this.levelCode = init ? init.levelCode : new ApiEnumType('', '');
    this.regime = init ? init.regime : new ApiEnumType('', '');
    this.subtype = init ? init.subtype : new ApiEnumType('', '');
    this.subtypeCode = init ? init.subtypeCode : new ApiEnumType('', '');

    Object.assign(this, init);
  }
}

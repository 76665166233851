<ng-container *ngIf="clauses && clauses.length > 0">
  <div class="rgi-ui-text-3 rgi-gp-mb-m" translate *ngIf="showTitle">
    _GP_._TITLE_._CLAUSES_
  </div>
  <div [ngClass]="{
          'rgi-ui-grid-2': !showVertical,
          'rgi-ui-grid-1': showVertical}">
    <div class="rgi-ui-col rgi-ui-form-group" *ngFor="let clause of clauses; index as i">
      <div class="rgi-ui-text-5 rgi-gp-flex-gr-1">
        {{ clause.extendedDescription }}
      </div>
      <button class="rgi-ui-btn" (click)="openModalText(clause)" data-qa="rgi-gp-show-or-edit-clause"
              [ngClass]="{'rgi-ui-btn-outline rgi-gp-flex': showText(clause)}"
              [disabled]="!clause.selected && !showText(clause)">
        <span *ngIf="showPreviewText(clause)" class="rgi-gp-prev-clause-text rgi-ui-text-5"
              data-qa="rgi-gp-clause-text">
          {{ clause.text }}
        </span>
        <span class="rgi-ui-icon-resources"></span>
      </button>
    </div>
  </div>
</ng-container>

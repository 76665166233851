import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardSummaryComponent } from './dashboard-summary/dashboard-summary.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { RgiRxPortalModule } from '@rgi/rx/portal';
import { RgiRxHttpModule } from '@rgi/rx/http';
import { DashboardSearchComponent } from './dashboard-search/dashboard-search.component';
import {
    RgiRxDatePickerModule,
    RgiRxFormElementsModule,
    RgiRxModalModule,
    RgiRxNativeDateTimeModule, RgiRxPanelModule, RgiRxSnackbarModule,
    RgiRxTableModule,
    RgiRxTabModule
} from '@rgi/rx/ui';
import { RgiRxFormModule } from '@rgi/rx';
import {ICD_ROUTES} from '../icd-routes';
import {RgiRxRoutingModule} from '@rgi/rx/router';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {TRANSLATIONS_ICD} from '../../translate_i18n/i18n';
import { DashboardDetailComponent } from './dashboard-detail/dashboard-detail.component';
import { DashboardDetailParamComponent } from './dashboard-detail-param/dashboard-detail-param.component';
import { DashboardDetailTableComponent } from './dashboard-detail-table/dashboard-detail-table.component';
import { DashboardDetailTableRiskComponent } from './dashboard-detail-table-risk/dashboard-detail-table-risk.component';
import {DashboardDetailTableAllRiskComponent} from './dashboard-detail-table-all-risk/dashboard-detail-table-all-risk.component';

@NgModule({
    declarations: [
        DashboardSearchComponent,
        DashboardSummaryComponent,
        DashboardDetailComponent,
        DashboardDetailParamComponent,
        DashboardDetailTableComponent,
        DashboardDetailTableRiskComponent,
        DashboardDetailTableAllRiskComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        BrowserModule,
        UpgradeModule,
        RgiRxPortalModule,
        RgiRxHttpModule,
        ReactiveFormsModule,
        RgiRxFormModule,
        RgiRxFormElementsModule,
        RgiRxDatePickerModule,
        RgiRxNativeDateTimeModule,
        RgiRxTableModule,
        RgiRxTabModule,
        RgiRxRoutingModule.forRoot(ICD_ROUTES),
        RgiRxI18nModule.forRoot(TRANSLATIONS_ICD),
        RgiRxModalModule,
        RgiRxPanelModule,
        RgiRxSnackbarModule,
    ],
    providers: [],
    exports: [
        DashboardSearchComponent,
        DashboardSummaryComponent,
        DashboardDetailTableComponent,
        DashboardDetailParamComponent,
        DashboardDetailComponent
    ]
})
export class DashboardModule { }

import { AssetsContainer } from './assets-container';
import { PartiesContainer } from './parties-container';
import { FieldsContainer } from './fields-container';

export class SelectableCoverture {

    public assetscontainer: AssetsContainer;
    public branchclaimcode: string;
    public branchclaimdesc: string;
    public coverturecode: string;
    public coverturedescr: string;
    public coverturetypecode: string;
    public coverturetypedesc: string;
    public garancycode: string;
    public garancydescr: string;
    public partiescontainer: PartiesContainer;
    public riskcode: string;
    public riskdesc: string;
    public riskFactors: string;
    public dShortage: string;
    public existingCoverage: boolean;
    public covertureFactors: string;
    public fieldscontainer: FieldsContainer;
}

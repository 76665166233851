import { Component, OnInit, Input, EventEmitter, Output, Inject, OnDestroy } from '@angular/core';
import { ApiEnumType, CardComponentService, Outcome } from '@rgi/digital-claims-common-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreControlsComponent } from '../pre-controls/pre-controls.component';
import { RoutableComponent } from '@rgi/portal-ng-core';
import { InitPotentialPaymentEntity } from '../domain/init-potential-payment-entity';
import { NewPaymentService } from '../new-payment.service';
import { PaymentEntity } from '../domain/payment-entity';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { PaymentConstants, TypeEnum } from '../domain/payment-constants';
import { UtilityService } from '../../external-portfolio/utility.service';
import { PaymentModalComponent } from '../payment-modal/payment-modal.component';
import { LiqDocumentEntity } from '../domain/liq-document-entity';
import { ENUMSLISTPAYMENT } from '../domain/payment-constants.config';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-new-payment-board',
  templateUrl: './new-payment-board.component.html',
  styleUrls: ['./new-payment-board.component.scss']
})
export class NewPaymentBoardComponent implements OnInit, OnDestroy, RoutableComponent {
  public currentStep: number = 0;
  public saveDisabled: boolean = true;
  public afterAccess: boolean = false;
  public resCore: any;
  public claimCurrent: any;
  public partyCurrent: any;
  public causalCurrent: any;
  public authSrv: any;
  public companies: any[];
  public newPayForm: UntypedFormGroup;
  public session: any;
  public msg: string = '';
  public msgPresent: boolean;
  public exitDisabled: boolean;
  public classMsg: string = 'alert alert-danger';
  public valueBar: number = 0;

  public potPayFE: InitPotentialPaymentEntity;
  @Input() card: any;
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();

  eRoleLegaleAvversario: TypeEnum;
  eTipoPagamentoSpeseLegali: TypeEnum;
  eSuccessService: TypeEnum;
  eFailureService: TypeEnum;
  eTipoCoassAll: TypeEnum[] = [];

  constructor(
    cardComponentService: CardComponentService,
    @Inject('sessionService') session: any,
    @Inject('coreResult') coreResult,
    @Inject('authService') authService,
    public translateService: RgiRxTranslationService,
    @Inject('eventService') private eventService: any,
    @Inject('enumService')  private enumService: any,
    @Inject('getSystemProperty') private getSystemProperty: any,
    @Inject('claimsBaseObjectService') private claimsBaseObjectService: any,
    public formBuilder: UntypedFormBuilder,
    private newPaymentService: NewPaymentService,
    private utilytyService: UtilityService,
    private modalService: NgbModal,
    private paymentConstants: PaymentConstants,
  ) {
    this.session = session;
    this.resCore = coreResult;
    this.authSrv = authService;
    this.companies = this.authSrv.getOperator().visibleCompanies;
  }

  initEnums() {
    this.eRoleLegaleAvversario = this.paymentConstants.getSingleERoleLegaleAvversario(ENUMSLISTPAYMENT.EROLE_LEGALE_AVVERSARIO);
    this.eTipoPagamentoSpeseLegali = this.paymentConstants.getSingleERoleLegaleAvversario(ENUMSLISTPAYMENT.ETIPOPAGAMENTO_SPESE_LEGALI);
    this.eSuccessService = this.paymentConstants.getSingleSuccessServer(ENUMSLISTPAYMENT.SUCCESS_SERVICE);
    this.eFailureService = this.paymentConstants.getSingleSuccessServer(ENUMSLISTPAYMENT.FAILURE_SERVICE);
    this.eTipoCoassAll = this.paymentConstants.ETIPOCOASS_ALL();
  }

  ngOnInit() {
    this.initEnums();
    // inizializzazione uuid per gestione dei dati nella cache del server
    this.msgPresent = false;
    this.exitDisabled = true;
    this.potPayFE = this.newPaymentService.getPotPayCache();
    this.claimCurrent = this.resCore.getResult(this.card.id, 'detail');
    this.partyCurrent = this.resCore.getResult(this.card.id, 'party');
    this.causalCurrent = this.resCore.getResult(this.card.id, 'causal');



    this.eventService.broadcastEvent('start-loader');
    this.valueBar = 10;

    this.newPayForm = this.formBuilder.group({
      paymentType: [, Validators.required],
      receiptType: [, Validators.required],
      priority: new UntypedFormControl({ value: null, disabled: false }),
      dueDate: new UntypedFormControl({ value: null, disabled: false }),
      managementPayment: new UntypedFormControl({ value: this.potPayFE ? this.potPayFE.managementPayment : null, disabled: false },
        Validators.nullValidator),
      receiptDate: new UntypedFormControl({ value: new Date(), disabled: false },
        Validators.required),
      separateReceipt: [, Validators.nullValidator],
      procedureCode: [, Validators.nullValidator],
      correctModalClose: [, Validators.nullValidator],
      currencyCode:  [, Validators.nullValidator],
      currencyCodeDate: new UntypedFormControl({value: new Date(), disabled: false}, Validators.required),
      note: [, Validators.nullValidator]


    });
    this.valueBar = 20;
    this.newPayForm.controls.correctModalClose.setValue(true);
    if (!this.potPayFE) {
      this.potPayFE = new InitPotentialPaymentEntity();
      this.potPayFE.legaleComeAttoreAbilitato = Boolean(this.utilytyService
        .getPISystemPorperty('SinistriAbilitazioneLegAvvAsAttCoinvolto'));
      this.potPayFE.invoiceDataControl = Boolean(this.utilytyService
        .getPISystemPorperty('ClaimsInvoiceDataControl'));
      // recupero la PISYSTEMPROP
      // CONFIG_ABILITAZIONE_LEGALE_AVV_COME_ATTORE_COINVOLTO = "SinistriAbilitazioneLegAvvAsAttCoinvolto";

      this.potPayFE.uuid = this.newPaymentService.getSessionUuid('sessionId');
      this.newPaymentService.initPotentialPayment(this.potPayFE).subscribe((outcome: Outcome) => {
        console.log('initPotentialPayment - outcome:  ', outcome);
      });

      this.initializeInitPotPay();
      this.newPaymentService.setPotPayCache(this.potPayFE);

      this.newPaymentService.getSubsidiaryChecks(this.claimCurrent.idClaim,
        this.authSrv.getOperator().liquidationCentre.code,
        this.authSrv.getOperator().objectId).subscribe((response: any) => {
          /*         const dummy: { [key: string]: string } = {
                    'showForceAdmCoverage': 'true',
                    'showConfirmResp': 'true'
                  };
                  response = dummy; */
          let showModal = false;
          for (const key of Object.keys(response)) {
            if (response[key] === 'true') {
              showModal = true;
              break;
            }
          }
          if (showModal) {
            const modalRef = this.modalService.open(PreControlsComponent, {
              windowClass: 'basic',
              size: 'lg'
            });
            modalRef.componentInstance.subsidiaryChecks = response;
            modalRef.componentInstance.claimCurrent = this.claimCurrent;
            modalRef.componentInstance.enteLogIn = this.authSrv.getOperator().liquidationCentre.code;
            modalRef.componentInstance.idUserLogIn = this.authSrv.getOperator().objectId;
            modalRef.result.then(
              (result) => {
                let next = true;
                for (const key of Object.keys(result)) {
                  if (key === 'forceAdmCoverage' && result[key] === 'true') {
                    this.potPayFE.administrativeReg = 'SI(FORZATA)';
                  }
                  if (result[key] === 'false') {
                    next = false;
                    break;
                  }
                }
                if (next === false) {
                  const session = this.session.list().find(el => el.isActive);
                  this.session.remove(session.idSession);
                }
              },
              (reason) => {
                const session = this.session.list().find(el => el.isActive);
                this.session.remove(session.idSession);
              });
          }

          // Attenzione a seguito del salvataggio di conferma della regolarità amministrativa
          // si deve aggiornare l’attributo  InitPotentialPaymentEntity.administrativeReg con SI (FORZATA)
        },
          (error: any) => {
            this.eventService.broadcastEvent('stop-loader');
          });
    }
    this.valueBar = 50;
    // servizio determinazione tipi pagamento gestione e tipi quietanza
    this.potPayFE.receiptTypeLoaded = false;
    this.newPaymentService.getListConfigurationPayment(this.potPayFE.claimNumber, this.potPayFE.actorId)
      .subscribe((configPaymentEntity: any) => {
        console.log('getListConfigurationPayment - outcome:  ', configPaymentEntity);

        this.potPayFE.receiptTypeList = configPaymentEntity.receiptTypeList;
        this.potPayFE.priorityListCombo = configPaymentEntity.priorityList;
        this.potPayFE.procedureCodeList = configPaymentEntity.procedureList;
        this.potPayFE.managementPaymentList = configPaymentEntity.managementPaymentList;
        this.potPayFE.nodoFittizioSx = configPaymentEntity.nodoFittizioSx;
        if (this.potPayFE.managementPaymentList && this.potPayFE.managementPaymentList.length > 0) {
          this.potPayFE.managementPayment = this.potPayFE.managementPaymentList[0].idObject;
          this.newPayForm.controls.managementPayment.setValue(this.potPayFE.managementPaymentList[0].idObject);
        }
        console.log(this.potPayFE.paymentList);
        this.potPayFE.paymentTypeList = configPaymentEntity.typePaymentList;
        if (this.potPayFE.paymentTypeList) {
          this.getSystemProperty.findProperty('SinistriAbilitazioneLegAvvAsAttCoinvolto').then((result) => {
            if (result && result.systemProperties && result.systemProperties.length) {
              for (const variable of result.systemProperties) {
                if (variable.key === 'SinistriAbilitazioneLegAvvAsAttCoinvolto') {
                  this.potPayFE.legaleComeAttoreAbilitato = (variable.value === 'Enable');
                  this.potPayFE.paymentTypeListCombo = this.potPayFE.paymentTypeList.filter(payConf => {
                    if (this.potPayFE.actorRoleCode === this.eRoleLegaleAvversario.eTypeCode) {
                      // visualizzare solo tipo pagamento spese legali (3)
                      return payConf.usable && payConf.eTypeCode === this.eTipoPagamentoSpeseLegali.eTypeCode;
                    } else if (this.potPayFE.legaleComeAttoreAbilitato) {
                      return payConf.usable && payConf.eTypeCode !== this.eTipoPagamentoSpeseLegali.eTypeCode;
                    } else {
                      return payConf.usable;
                    }
                  });
                }
              }
            }
          });
        }
        this.potPayFE.receiptTypeLoaded = true;
      },
        (error: any) => {
          this.eventService.broadcastEvent('stop-loader');
        });
    this.valueBar = 80;
    // servizio elenco legali e elenco coperture
    this.potPayFE.exposuresLoaded = false;
    this.newPaymentService.getListExposuresProfs(this.potPayFE.claimNumber, this.potPayFE.actorId,
      this.authSrv.getOperator().liquidationCentre.code, this.authSrv.getOperator().objectId)
      .subscribe((configPaymentEntity: any) => {
        console.log('getListExposuresProfs - outcome:  ', configPaymentEntity);
        this.potPayFE.exposureList = configPaymentEntity.exposureList;
        this.potPayFE.professionistList = configPaymentEntity.professionistList;
        this.potPayFE.antiFraudMessage = configPaymentEntity.antiFraudMessage;
        this.potPayFE.paymentList = configPaymentEntity
        if (this.potPayFE.professionistList) {
          this.potPayFE.ctpLawyerFound = (this.potPayFE.professionistList.some(subj => subj.type.toUpperCase() === 'LAV'));
        }

        for (let i = 0; i < this.potPayFE.exposureList.length; i++) {
          const aExp = this.potPayFE.exposureList[i];
          aExp.idCampo = i + '';
          aExp.visible = false;
          this.newPayForm.addControl('expSel' + i,
            new UntypedFormControl({ disabled: aExp.disableSelection }, Validators.nullValidator));
          this.newPayForm.addControl('expAmount' + i,
            new UntypedFormControl({ disabled: aExp.disableAmount }, Validators.nullValidator));
          this.newPayForm.addControl('currencyAmount' + i,
            new UntypedFormControl({ disabled: false }, Validators.nullValidator));
          this.newPayForm.addControl('expDefinition' + i,
            new UntypedFormControl({ disabled: aExp.disableDefinition }, Validators.nullValidator));
          if (aExp.substatus) {
            this.newPayForm.addControl('expIdSubstatus' + i,
            new UntypedFormControl({disabled: false }, Validators.nullValidator));
            this.newPayForm.controls['expIdSubstatus' + i].setValue('');
          }

          if (this.potPayFE.ministerialBranch.startsWith('000010')) {
            this.newPayForm.addControl('expCoherence' + i,
            new UntypedFormControl({disabled: false }, Validators.nullValidator));
            this.newPayForm.controls['expCoherence' + i].setValue(aExp.idCoherence);
          }


          if (aExp.subExposureList) {
              for (let j = 0; j < aExp.subExposureList.length; j++) {
              const aSubExp = aExp.subExposureList[j];
              aSubExp.idCampo = i + '*' + j;
              aSubExp.visible = false;
              this.newPayForm.addControl('subExpSel' + i + '*' + j,
                new UntypedFormControl({ disabled: aSubExp.disableSelection }, Validators.nullValidator));
              this.newPayForm.addControl('subExpAmount' + i + '*' + j,
                new UntypedFormControl({ disabled: aSubExp.disableAmount }, Validators.nullValidator));
              this.newPayForm.addControl('subExpLegalFeeAmount' + i + '*' + j,
                new UntypedFormControl({ disabled: aSubExp.disableOmniaAmount }, Validators.nullValidator));
              this.newPayForm.addControl('subExpDefinition' + i + '*' + j,
                new UntypedFormControl({ disabled: aSubExp.disableDefinition }, Validators.nullValidator));
            }
          }

          // aExp.subExposureList.forEach(element => {
          //   element.visible=false;
          // });

          // if (this.potPayFE.actorRole === PaymentConstants.EROLE_LEGALE_AVVERSARIO) {
          //   aExp.feeDataNeeded = true;
          //   aExp.disableAmount = true;
          //   aExp.feeDataOmniaNeeded = false;
          //   aExp.disableDefinition = false;
          //   aExp.disableSelection = false;
          //   aExp.visible = true
          // }

        }
        this.potPayFE.exposuresLoaded = true;
        this.eventService.broadcastEvent('stop-loader');
      },
        (error: any) => {
          this.eventService.broadcastEvent('stop-loader');
        });

    this.valueBar = 99;
  }

  progress(nestStep: number) {
    // TODO: step logic here
    if (nestStep > this.currentStep) {
      this.currentStep = nestStep;
    }
  }

  saveAvailable(saveAvailable) {
    this.saveDisabled = !saveAvailable;
  }

  getCurrentSession() {
    return this.session.list().find(el => el.isActive);
  }

  exit() {
    const session = this.getCurrentSession();
    this.session.remove(session.idSession);

  }

  save() {
    this.msgPresent = false;
    this.classMsg = 'alert alert-danger';
    this.eventService.broadcastEvent('start-loader');

    const payOrig = this.newPaymentService.getPaymentDB();

    if (payOrig) {
      this.newPaymentService.cancelReceipt(payOrig.idPayment, this.potPayFE.claimNumber).subscribe(
        (response: any) => {
          console.log('ANNULLAMENTO TERMINATO:  ', response);
          this.callFinalSaveService();
        },
        (error: any) => {
          console.log('ANNULLAMENTO ERRORE:  ', error);
          this.msg = 'Upload error';
          this.msgPresent = true;
          this.exitDisabled = false;
          this.eventService.broadcastEvent('stop-loader');
        }
      );
      this.newPaymentService.setPaymentDB(null);
    } else {
      this.callFinalSaveService();
    }
  }

  callFinalSaveService() {

    this.potPayFE.paymentList.forEach(element => {
      const id = element.id;
      const transferDescrFixMap = this.newPaymentService.getTransferDescrFixMap();

      // const prevDefault = this.translate.instant('Claim') + ' n. ';
      const prevDefault = transferDescrFixMap.get(element.id);
      /* swift, estero sepa, bonifico */
      // format string with [CLAIM NUMBER][SPACE][TRANSFER DESCRIPTION]
      if (element.methodPayment.methodId === '2' || element.methodPayment.methodId === '6' || element.methodPayment.methodId === '3') {
        // tslint:disable-next-line:max-line-length
        element.methodPayment.transferDescription = prevDefault  + (element.methodPayment.transferDescription == null ? '' : (' - ' + element.methodPayment.transferDescription));
      }
    });

    this.newPaymentService.finalSavePayment(this.potPayFE,
      this.authSrv.getOperator().liquidationCentre.code,
      this.authSrv.getOperator().objectId).subscribe((outCome: any) => {
        // console.log('SAVATAGGIO TERMINATO:  ', outCome);
        this.saveDisabled = true;
        this.exitDisabled = false;
        this.msg = outCome.descr;
        this.msgPresent = true;
        if (outCome.code === this.eSuccessService.eTypeCode) {
          this.classMsg = 'alert alert-success';
        }

        const finPays = new Array<PaymentEntity>();
        const map: Map<string, any[]> = outCome.objects;

        // se map è popolata qualche salvataggio è andato a buon fine
        if (map) {
          this.potPayFE.paymentList.forEach(aPotPaySaved => {
            if (map[aPotPaySaved.id]) {
              const temp = new PaymentEntity();
              temp.claimNumber = this.potPayFE.claimNumber; // + '(' + this.potPayFE.company + ')';
              const obj = map[aPotPaySaved.id];
              const riserve: Map<string, number> = obj[4];
              const liqDocs: Map<string, Array<LiqDocumentEntity>> = obj[5];

              temp.number = obj[0];
              temp.userLiq = obj[3];
              temp.partyRole = this.potPayFE.actorRole;
              temp.involvedParty = this.potPayFE.actorNominative;
              temp.movementType = obj[1];
              temp.status = obj[2];
              temp.recordingDate = new Date();
              temp.idPayment = obj[6];
              temp.priority = this.potPayFE.priority;
              temp.dueDate = this.potPayFE.dueDate;

              temp.paymentManagement = aPotPaySaved.paymentManagement;
              temp.receiptType = aPotPaySaved.receiptType;
              temp.paymentType = aPotPaySaved.paymentType;
              temp.receiptDate = aPotPaySaved.receiptDate;
              temp.generatePayment = aPotPaySaved.generatePayment;
              temp.exposureList = aPotPaySaved.exposureList;
              temp.exposureList.forEach(element => {
                element.reserveAmount = riserve[element.idCausaleDanno];
              });

              temp.totalAmount = aPotPaySaved.totalAmount;
              temp.totalAmountNet = aPotPaySaved.totalAmountNet;
              temp.listBeneficiary = aPotPaySaved.listBeneficiary;
              temp.listPayee = aPotPaySaved.listPayee;
              temp.procedureCode = aPotPaySaved.procedureCode;
              temp.separateReceipt = aPotPaySaved.separateReceipt;
              temp.methodPayment = aPotPaySaved.methodPayment;

              // aggiorno il receiver per la visualizzazione
              if (temp.methodPayment.receiver && temp.methodPayment.receiver.type) {
                if (temp.methodPayment.receiver.type.toUpperCase() === 'RLAV') {
                  temp.methodPayment.receiver = this.potPayFE.professionistList.
                         filter(subj => subj.idSubject === temp.methodPayment.receiver.legalCode)[0];
                }
              }

              temp.driveType = aPotPaySaved.driveType;
              temp.documentList = aPotPaySaved.documentList;
              temp.currencyAmount = aPotPaySaved.currencyAmount;
              temp.currencyCode = aPotPaySaved.currencyCode;
              temp.currencyCodeDate = aPotPaySaved.currencyCodeDate;
              temp.note = aPotPaySaved.note;
              temp.idPayment = obj[6];

              temp.documentList.forEach(aDoc => {
                aDoc.validated = true;
                aDoc.listLiqGenerations = new Array<LiqDocumentEntity>();
                if (liqDocs && liqDocs[aDoc.documentId]) {
                  aDoc.listLiqGenerations = liqDocs[aDoc.documentId];
                }

              });


              finPays.push(temp);
            }
          });

          if (outCome.code === this.eFailureService.eTypeCode) {
            this.newPaymentService.setMessageCache(outCome.descr, this.classMsg);
          } else {
            this.translateService.translate('_CLAIMS_._OPERATION_PROPERLY_TERMINATED').subscribe(res=> {
              this.newPaymentService.setMessageCache(res, this.classMsg);
            }
            )
          }

          this.newPaymentService.setFinalPayCache(finPays);
          this.newPaymentService.setCardCache(this.card);
          this.newPaymentService.setClaimCache(this.claimCurrent);

          this.eventService.broadcastEvent('stop-loader');
          this.navigation.emit('detail');
        } else {
          this.eventService.broadcastEvent('stop-loader');
        }
      },
        (error: any) => {
          this.msg = 'Upload error';
          this.msgPresent = true;
          this.exitDisabled = false;
          this.eventService.broadcastEvent('stop-loader');
        }
      );
  }

  initializeInitPotPay() {
    this.potPayFE.idCausaleOrigine = this.causalCurrent ? this.causalCurrent.damagesDescriptionId : null;
    this.potPayFE.claimNumber = this.claimCurrent.claimNumber;
    this.potPayFE.companyCode = this.claimCurrent.aniaCompanyCode;
    this.potPayFE.company = this.companies.find(value => value.aniaCode === this.potPayFE.companyCode).description;
    this.potPayFE.coassType = this.claimCurrent.coinsuranceType;
    this.potPayFE.legalProtectionEnabled = this.claimCurrent.isLegalProtectionEnabled;
    // 1	Nessuna
    // 2	Delega nostra
    // 3	Delega terzi
    // const enumTCoass: ApiEnumType[] = this.enumService.getEnumList('claims.CoinsuranceType');
    this.potPayFE.coassTypeDescr = this.eTipoCoassAll.
                                   find(el => el.eTypeCode === this.claimCurrent.coinsuranceType).eTypeDescription;
    // this.potPayFE.coassTypeDescr = this.claimCurrent.coinsuranceType === PaymentConstants.ETIPOCOASS_NO_COASS.eTypeCode ?
    //   PaymentConstants.ETIPOCOASS_NO_COASS.eTypeDescription :
    //   this.claimCurrent.coinsuranceType === PaymentConstants.ETIPOCOASS_DELEGA_NOSTRA ?
    //     PaymentConstants.ETIPOCOASS_DELEGA_NOSTRA.eTypeDescription :
    //     PaymentConstants.ETIPOCOASS_DELEGA_ALTRUI.eTypeDescription;
    this.potPayFE.agency = this.claimCurrent.policyAgencyData.agencyDescription;
    this.potPayFE.policyHolder = this.claimCurrent.contractor;
    this.potPayFE.policyNumber = this.claimCurrent.policyNumber;
    this.potPayFE.productDescription = this.claimCurrent.product;
    const branches: string = this.claimCurrent.taxBranches;
    if (branches.split(';') && branches.split(',').length > 0) {
      this.potPayFE.ministerialBranch = branches.split(',')[0];
    } else {
      this.potPayFE.ministerialBranch = this.claimCurrent.taxBranches;
    }
    this.potPayFE.claimType = this.claimCurrent.tipoCard ? this.claimCurrent.tipoCard.descrizione : null;
    this.potPayFE.complaintDate = this.claimCurrent.reportDate;
    this.potPayFE.effectiveLossDate = this.claimCurrent.occurrenceDateCertified;
    this.potPayFE.lossDate = this.claimCurrent.occurrenceDate;
    this.potPayFE.administrativeReg = (this.claimCurrent.accountingCoverage ? 'SI' : 'NO') +
      (this.claimCurrent.forcingAdministrative ? '(Forzata)' : '');
    this.potPayFE.technicalReg = (this.claimCurrent.technicalCoverage ? 'SI' : 'NO');
    const nomin = (this.partyCurrent.surname ? this.partyCurrent.surname : '') + ' ' +
                 (this.partyCurrent.name ? this.partyCurrent.name : '');
    this.potPayFE.actorNominative = (nomin.trim() !== '') ? nomin :
                                    this.partyCurrent.subject.designation;
    this.potPayFE.actorFcVat = this.partyCurrent.fiscalCode;
    this.potPayFE.actorRole = this.partyCurrent.role;
    this.potPayFE.actorRoleCode = this.partyCurrent.partyRole.codice;
    this.potPayFE.actorId = this.partyCurrent.identifier;
    this.potPayFE.matterFound = this.partyCurrent.litigationsList && this.partyCurrent.litigationsList.length > 0;
    this.potPayFE.requestDamagesFound = this.partyCurrent.damagesRequests && this.partyCurrent.damagesRequests.length > 0;
    this.potPayFE.reserved = this.partyCurrent.reserve;
    this.potPayFE.paid = this.partyCurrent.amount;
    this.potPayFE.receiptSavedList = [];
    this.potPayFE.paymentSavedList = [];
    let receiptTotalAmount = 0;
    this.partyCurrent.adjustements.forEach(liq => {
      if (liq.cancellationDate == null) {
        const aPayFE = liq;
        aPayFE.typeVis = liq.movementType.codice;

        if (aPayFE.typeVis === '1') {
          this.potPayFE.receiptSavedList.push(aPayFE);
          receiptTotalAmount += parseFloat(aPayFE.amount);
        }
        if (aPayFE.typeVis === '2') {
          this.potPayFE.paymentSavedList.push(aPayFE);
        }
      }
    });
    this.partyCurrent.receipts.forEach(rcp => {
      if (rcp.cancellationDate == null) {
        const aPayFE = rcp;
        aPayFE.typeVis = rcp.movementType.codice;
        if (aPayFE.typeVis === '1') {
          this.potPayFE.receiptSavedList.push(aPayFE);
          receiptTotalAmount += parseFloat(aPayFE.amount);
        }
      }

    });
    // this.potPayFE.numReceipt = this.potPayFE.receiptSavedList ? this.potPayFE.receiptSavedList.length : 0;
    this.potPayFE.numReceipt = receiptTotalAmount;
    if (this.potPayFE.receiptSavedList && this.potPayFE.receiptSavedList.length > 0) {
      this.potPayFE.receiptPresent = true;
    }
    if (this.potPayFE.paymentSavedList && this.potPayFE.paymentSavedList.length > 0) {
      this.potPayFE.paymentPresent = true;
    }
    this.potPayFE.paid = this.partyCurrent.amount;

    this.potPayFE.compensationSavedList = this.partyCurrent.compensations;
    this.potPayFE.compensationPresent = this.potPayFE.compensationSavedList &&
      this.potPayFE.compensationSavedList.length > 0 ? true : false;
    this.potPayFE.totalReservedCompensation = this.partyCurrent.totalReservedCompensation;
    this.potPayFE.totalCashedCompensation = this.partyCurrent.totalCashedCompensation;
    this.potPayFE.totalAssInd = 0;
    this.potPayFE.totalAssSpLiq = 0;
    this.potPayFE.totalAssSpRes = 0;

    this.potPayFE.assessmentSavedList = [];
    this.partyCurrent.damageDescriptions.forEach(caus => {
      caus.appointmentList.forEach(ass => {
        this.potPayFE.assessmentSavedList.push(ass);

        if (ass.paymentSurveyType && ass.paymentSurveyType === '1') {
          this.potPayFE.totalAssSpLiq++;
        }
        if (ass.paymentSurveyType && ass.paymentSurveyType === '2') {
          this.potPayFE.totalAssSpRes++;
        }
        if (ass.paymentSurveyType && ass.paymentSurveyType === '3') {
          this.potPayFE.totalAssInd++;
        }
      });
    });

    this.potPayFE.assessmentPresent = this.potPayFE.assessmentSavedList &&
      this.potPayFE.assessmentSavedList.length > 0 ? true : false;
    this.potPayFE.receiptDate = new Date();
    // this.potPayFE.legaleComeAttoreAbilitato = Boolean(this.utilytyService
    //   .getPISystemPorperty('SinistriAbilitazioneLegAvvAsAttCoinvolto'));
    // this.potPayFE.invoiceDataControl = Boolean(this.utilytyService
    //   .getPISystemPorperty('ClaimsInvoiceDataControl'));

  }

  settlementList(content) {
    content.stopPropagation();

    const modalRef = this.modalService.open(PaymentModalComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    modalRef.componentInstance.potPayFE = this.potPayFE;
    modalRef.componentInstance.settlementsDetailsTypeOfLiq = true;

    modalRef.result.then((result) => {
      if (result) {
        console.log('Hello');
      }
    });

    return false;

  }

  ngOnDestroy(): void {
    if (this.claimCurrent && this.claimCurrent.claimNumber) {
      this.claimsBaseObjectService.reloadClaim(this.claimCurrent.claimNumber, this.claimCurrent.aniaCompanyCode, this.card.idSessionParent);
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AssignmentHolderResp, AssignmentHolderRespPOST} from '../models/assignmentholder.model';
import { PolicyService } from './policy-service';
import { EnviromentsService } from './enviroments.service';


@Injectable({
  providedIn: 'root'
})
export class AssignmentHolderService {
  protected baseApiUrl: string;
  protected baseApiPassURL: string;

  constructor(private httpClient: HttpClient,
              protected policyService: PolicyService,
              protected environment: EnviromentsService) {
    this.baseApiUrl = environment.baseApiURL;
    this.baseApiPassURL = environment.baseApiPassURL;
  }

  getAssignmentHolderData(
    proposalId: string,
    productCode: string,
    effectiveDate: string,
    employee: string): Observable<AssignmentHolderResp> {
    const params = new HttpParams()
    .set('productCode' ,  productCode)
    .set('effectiveDate', effectiveDate)
    .set('employee', employee);
    return this.httpClient
    .get<AssignmentHolderResp>(this.baseApiUrl + '/v2/life/proposal/' + proposalId + '/assignment/definitions', {params});
  }

  saveAssignmentHolderData(assignmentHolderData: { proposalId: string; holderId: string; data: {} })
  : Observable<AssignmentHolderRespPOST> {
      return this.httpClient.post<AssignmentHolderRespPOST>(this.baseApiPassURL + '/ptflife/proposal/' +
      assignmentHolderData.proposalId + '/assignment/' + assignmentHolderData.holderId, assignmentHolderData.data);
  }

}

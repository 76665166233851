<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>Commissions</span>
        </h3>
      </div>
      <div class="modal-body">
        <div *ngIf="derogate && isProposal && commissionsDerogationForm" [formGroup]="commissionsDerogationForm"
             class="tbld tbld_commissions hidden-xs hidden-sm">
          <div class="tbld_row tbld_row_commissions_header">
            <div class="tbld_col">
              <span translate="">Authority Limit</span>
            </div>
            <div class="tbld_col tbld_col_double tbld_col_perc_unit">
              <span translate="">% Acquired Commission</span>
            </div>
            <div class="tbld_col tbld_col_double tbld_col_perc_unit">
              <span translate="">% Acquired Charges</span>
            </div>
            <div class="tbld_col tbld_col_double tbld_col_perc_unit">
              <span translate="">% Collected Commission (Net)</span>
            </div>
            <div class="tbld_col tbld_col_double tbld_col_perc_unit">
              <span translate="">% Collected Charges</span>
            </div>
            <div class="tbld_col">
              <span></span>
            </div>
          </div>
          <div class="tbld_row tbld_row_commissions">
            <div class="tbld_col">All Risks</div>
            <div class="tbld_col tbld_col_double">
              <input [formControlName]="'purchasePercDerogation'" class="form-control input-sm ng-pristine ng-untouched ng-valid ng-not-empty" max="100" min="0"
                     step=".01"
                     type="number"></div>
            <div class="tbld_col tbld_col_double">
              <input [formControlName]="'purchaseAdditionalPercDerogation'" class="form-control input-sm ng-pristine ng-untouched ng-valid ng-not-empty" max="100" min="0"
                     step=".01"
                     type="number">
            </div>
            <div class="tbld_col tbld_col_double">
              <input [formControlName]="'collectionPercDerogation'" class="form-control input-sm ng-pristine ng-untouched ng-valid ng-not-empty" max="100" min="0"
                     step=".01"
                     type="number">
            </div>
            <div class="tbld_col tbld_col_double">
              <input [formControlName]="'collectionAdditionalPercDerogation'" class="form-control input-sm ng-pristine ng-untouched ng-valid ng-not-empty" max="100" min="0"
                     step=".01"
                     type="number">
            </div>
            <div class="tbld_col tbld_col_double">
              <button (click)="applyDerogations()" class="btn btn-commissions" translate>APPLY</button>
            </div>
          </div>
        </div>
        <br>
        <div *ngIf="derogate && isProposal">
          <label translate>Edit Amount </label>
          <span (click)="editAmount = !editAmount" class="btn btn-checkbox checkbox-unit nmf-unit-box-checkbox"
                type="checkbox">
                        <span [ngClass]="{'glyphicon-ok': editAmount }" class="glyphicon">
                        </span>
                    </span>
        </div>
        <br>
        <br>
        <div [formGroup]="commissionsForm" *ngIf="commissionsForm" class="tbld tbld_commissions hidden-xs hidden-sm">
          <div class="tbld_row tbld_row_commissions_header">
            <div class="tbld_col tbld_col_der">
              <span translate="">Der.</span>
            </div>
            <div class="tbld_col tbld_col_unit">
              <span translate="">Commissions at Inception Date</span>
            </div>
            <div class="tbld_col tbld_col_double tbld_col_perc_unit">
              <span translate="">Acquired Commission</span>
            </div>
            <div class="tbld_col tbld_col_double tbld_col_perc_unit">
              <span translate="">Acquired Charges</span>
            </div>
            <div class="tbld_col tbld_col_double tbld_col_perc_unit">
              <span translate="">Collected Commission (Net)</span>
            </div>
            <div class="tbld_col tbld_col_double tbld_col_perc_unit">
              <span translate="">Collected Charges</span>
            </div>
            <div class="tbld_col tbld_col_double tbld_col_perc_unit">
              <span translate="">Total</span>
            </div>
          </div>
          <div class="tbld_row tbld_row_commissions">
            <div class="tbld_col">
            </div>
            <div class="tbld_col">
            </div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single tbld_col_single_left tbld_col_text">
                <span translate="">%</span>
              </div>
              <div class="tbld_col tbld_col_single tbld_col_text">
                <span translate="">Amount</span>
              </div>
            </div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single tbld_col_single_left tbld_col_text">
                <span translate="">%</span>
              </div>
              <div class="tbld_col tbld_col_single tbld_col_text">
                <span translate="">Amount</span>
              </div>
            </div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single tbld_col_single_left tbld_col_text">
                <span translate="">%</span>
              </div>
              <div class="tbld_col tbld_col_single tbld_col_text">
                <span translate="">Amount</span>
              </div>
            </div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single tbld_col_single_left tbld_col_text">
                <span translate="">%</span>
              </div>
              <div class="tbld_col tbld_col_single tbld_col_text">
                <span translate="">Amount</span>
              </div>
            </div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single tbld_col_single_left tbld_col_text">
                <span translate="">%</span>
              </div>
              <div class="tbld_col tbld_col_single tbld_col_text">
                <span translate="">Amount</span>
              </div>
            </div>
          </div>
          <ng-container *ngFor="let commission of subscriptionInstalmentCommissions">
            <div *ngIf="!commission?.total" class="tbld_row tbld_row_commissions">
              <div class="tbld_col tbld_col_der">
                                <span (click)="commission.derogate = !commission.derogate" *ngIf="derogate && isProposal && !commission?.total && !commission?.fees"
                                      class="btn btn-checkbox checkbox-unit nmf-unit-box-checkbox"
                                      type="checkbox">
                                    <span [ngClass]="{'glyphicon-ok': commission?.derogate }" class="glyphicon">
                                    </span>
                                </span>
              </div>
              <div class="tbld_col" translate>{{ commission?.fees ? 'Fees' : commission?.description }}
              </div>
              <div class="tbld_col tbld_col_double">
                <div class="tbld_col tbld_col_single tbld_col_single_left">
                  <input [formControlName]="'purchasePerc-' + commission?.key" [readonly]="commission?.fees || commission?.total || !derogate || !isProposal || (derogate && isProposal && !commission?.derogate) || (derogate && isProposal && editAmount)"
                         [value]="commission?.purchasePercentage" class="form-control input-sm ng-pristine ng-untouched ng-valid ng-not-empty"
                         max="100"
                         min="0"
                         step=".01"
                         type="number">
                </div>
                <div class="tbld_col tbld_col_single">
                  <input [formControlName]="'purchaseAmount-' + commission?.key" [readonly]="commission?.fees || commission?.total || !derogate || !isProposal || (derogate && isProposal && !commission?.derogate) || (derogate && isProposal && !editAmount)"
                         [value]="commission?.purchaseAmount"
                         class="form-control input-sm ng-pristine ng-untouched ng-valid ng-not-empty"
                         step=".01"
                         type="number">
                </div>
              </div>
              <div class="tbld_col tbld_col_double">
                <div class="tbld_col tbld_col_single tbld_col_single_left">
                  <input [formControlName]="'purchaseAdditionalPerc-' + commission?.key" [readonly]="commission?.fees || commission?.total || !derogate || !isProposal || (derogate && isProposal && !commission?.derogate) || (derogate && isProposal && editAmount)"
                         [value]="commission?.purchaseAdditionalPercentage" class="form-control input-sm ng-pristine ng-untouched ng-valid ng-not-empty"
                         max="100"
                         min="0"
                         step=".01"
                         type="number">
                </div>
                <div class="tbld_col tbld_col_single">
                  <input [formControlName]="'purchaseAdditionalAmount-' + commission?.key" [readonly]="commission?.fees || commission?.total || !derogate || !isProposal || (derogate && isProposal && !commission?.derogate) || (derogate && isProposal && !editAmount)"
                         [value]="commission?.purchaseAdditionalAmount"
                         class="form-control input-sm ng-pristine ng-untouched ng-valid ng-not-empty"
                         step=".01"
                         type="number">
                </div>
              </div>
              <div class="tbld_col tbld_col_double">
                <div class="tbld_col tbld_col_single tbld_col_single_left">
                  <input [formControlName]="'collectionPerc-' + commission?.key" [readonly]="commission?.fees || commission?.total || !derogate || !isProposal || (derogate && isProposal && !commission?.derogate) || (derogate && isProposal && editAmount)"
                         [value]="commission?.collectionPercentage" class="form-control input-sm ng-pristine ng-untouched ng-valid ng-not-empty"
                         max="100"
                         min="0"
                         step=".01"
                         type="number">
                </div>
                <div class="tbld_col tbld_col_single">
                  <input [formControlName]="'collectionAmount-' + commission?.key" [readonly]="commission?.fees || commission?.total || !derogate || !isProposal || (derogate && isProposal && !commission?.derogate) || (derogate && isProposal && !editAmount)"
                         [value]="commission?.collectionAmount"
                         class="form-control input-sm ng-pristine ng-untouched ng-valid ng-not-empty"
                         step=".01"
                         type="number">
                </div>
              </div>
              <div class="tbld_col tbld_col_double">
                <div class="tbld_col tbld_col_single tbld_col_single_left">
                  <input [formControlName]="'collectionAdditionalPerc-' + commission?.key" [readonly]="commission?.fees || commission?.total || !derogate || !isProposal || (derogate && isProposal && !commission?.derogate) || (derogate && isProposal && editAmount)"
                         [value]="commission?.collectionAdditionalPercentage" class="form-control input-sm ng-pristine ng-untouched ng-valid ng-not-empty"
                         max="100"
                         min="0"
                         step=".01"
                         type="number">
                </div>
                <div class="tbld_col tbld_col_single">
                  <input [formControlName]="'collectionAdditionalAmount-' + commission?.key" [readonly]="commission?.fees || commission?.total || !derogate || !isProposal || (derogate && isProposal && !commission?.derogate) || (derogate && isProposal && !editAmount)"
                         [value]="commission?.collectionAdditionalAmount"
                         class="form-control input-sm ng-pristine ng-untouched ng-valid ng-not-empty"
                         step=".01"
                         type="number">
                </div>
              </div>
              <div class="tbld_col tbld_col_double">
                <div class="tbld_col tbld_col_single tbld_col_single_left">
                  {{ commission?.totalPercentage | number : '1.2-2' : 'it' }}
                </div>
                <div class="tbld_col tbld_col_single">
                  {{ commission?.totalAmount | currency:'EUR':'symbol':'1.2-2':'it' }}</div>
              </div>
            </div>
          </ng-container>

          <div class="tbld_row tbld_row_commissions_total">
            <div class="tbld_col"></div>
            <div class="tbld_col">
              <span translate="">Total</span>
            </div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single tbld_col_single_left">
                {{ subscriptionInstalmentTotal?.purchasePercentage | number : '1.2-2' :'it'}}
              </div>
              <div class="tbld_col tbld_col_single">
                {{ subscriptionInstalmentTotal?.purchaseAmount | currency:'EUR':'symbol':'1.2-2':'it' }}
              </div>
            </div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single tbld_col_single_left">
                {{ subscriptionInstalmentTotal?.purchaseAdditionalPercentage | number : '1.2-2':'it' }}
              </div>
              <div class="tbld_col tbld_col_single">
                {{ subscriptionInstalmentTotal?.purchaseAdditionalAmount | currency:'EUR':'symbol':'1.2-2':'it' }}
              </div>
            </div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single tbld_col_single_left">
                {{ subscriptionInstalmentTotal?.collectionPercentage | number : '1.2-2':'it' }}
              </div>
              <div class="tbld_col tbld_col_single">
                {{ subscriptionInstalmentTotal?.collectionAmount | currency:'EUR':'symbol':'1.2-2':'it' }}
              </div>
            </div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single tbld_col_single_left">
                {{ subscriptionInstalmentTotal?.collectionAdditionalPercentage | number : '1.2-2':'it' }}
              </div>
              <div class="tbld_col tbld_col_single">
                {{ subscriptionInstalmentTotal?.collectionAdditionalAmount | currency:'EUR':'symbol':'1.2-2':'it' }}
              </div>
            </div>
            <div class="tbld_col tbld_col_double">
              <div class="tbld_col tbld_col_single tbld_col_single_left">
                {{ subscriptionInstalmentTotal?.totalPercentage | number : '1.2-2':'it' }}
              </div>
              <div class="tbld_col tbld_col_single">
                {{ subscriptionInstalmentTotal?.totalAmount | currency:'EUR':'symbol':'1.2-2':'it' }}
              </div>
            </div>
          </div>
        </div>

        <!-- provvigioni rata successiva -->
        <div *ngIf="!nextInstalmentCommissions">
          <div class="tbld tbld_commissions hidden-xs hidden-sm">
            <div class="tbld_row tbld_row_commissions_header">
              <div class="tbld_col tbld_col_der"></div>
              <div class="tbld_col tbld_col_unit">
                <span translate="">Next Comm Instalment</span>
              </div>
              <div class="tbld_col tbld_col_double tbld_col_perc_unit">
                <span translate="">Acquired Commission</span>
              </div>
              <div class="tbld_col tbld_col_double tbld_col_perc_unit">
                <span translate="">Acquired Charges</span>
              </div>
              <div class="tbld_col tbld_col_double tbld_col_perc_unit">
                <span translate="">Collected Commission (Net)</span>
              </div>
              <div class="tbld_col tbld_col_double tbld_col_perc_unit">
                <span translate="">Collected Charges</span>
              </div>
              <div class="tbld_col tbld_col_double tbld_col_perc_unit">
                <span translate="">Total</span>
              </div>
            </div>
            <div class="tbld_row tbld_row_commissions">
              <div class="tbld_col"></div>
              <div class="tbld_col"></div>
              <div class="tbld_col tbld_col_double">
                <div class="tbld_col tbld_col_single tbld_col_single_left tbld_col_text">
                  <span translate="">%</span>
                </div>
                <div class="tbld_col tbld_col_single tbld_col_text">
                  <span translate="">Amount</span>
                </div>
              </div>
              <div class="tbld_col tbld_col_double">
                <div class="tbld_col tbld_col_single tbld_col_single_left tbld_col_text">
                  <span translate="">%</span>
                </div>
                <div class="tbld_col tbld_col_single tbld_col_text">
                  <span translate="">Amount</span>
                </div>
              </div>
              <div class="tbld_col tbld_col_double">
                <div class="tbld_col tbld_col_single tbld_col_single_left tbld_col_text">
                  <span translate="">%</span>
                </div>
                <div class="tbld_col tbld_col_single tbld_col_text">
                  <span translate="">Amount</span>
                </div>
              </div>
              <div class="tbld_col tbld_col_double">
                <div class="tbld_col tbld_col_single tbld_col_single_left tbld_col_text">
                  <span translate="">%</span>
                </div>
                <div class="tbld_col tbld_col_single tbld_col_text">
                  <span translate="">Amount</span>
                </div>
              </div>
              <div class="tbld_col tbld_col_double">
                <div class="tbld_col tbld_col_single tbld_col_single_left tbld_col_text">
                  <span translate="">%</span>
                </div>
                <div class="tbld_col tbld_col_single tbld_col_text">
                  <span translate="">Amount</span>
                </div>
              </div>
            </div>
            <ng-container *ngFor="let commission of nextInstalmentCommissions">
              <div *ngIf="!commission?.total" class="tbld_row tbld_row_commissions">
                <div class="tbld_col tbld_col_der"></div>
                <div class="tbld_col" translate>{{ commission?.fees ? 'Fees' : commission?.description }}
                </div>
                <div class="tbld_col tbld_col_double">
                  <div class="tbld_col tbld_col_single tbld_col_single_left">
                    {{ commission?.purchasePercentage | number : '1.2-2':'it' }}
                  </div>
                  <div class="tbld_col tbld_col_single">
                    {{ commission?.purchaseAmount | currency:'EUR':'symbol':'1.2-2':'it' }}</div>
                </div>
                <div class="tbld_col tbld_col_double">
                  <div class="tbld_col tbld_col_single tbld_col_single_left">
                    {{ commission?.purchaseAdditionalPercentage | number : '1.2-2' :'it'}}
                  </div>
                  <div class="tbld_col tbld_col_single">
                    {{ commission?.purchaseAdditionalAmount | currency:'EUR':'symbol':'1.2-2':'it' }}
                  </div>
                </div>
                <div class="tbld_col tbld_col_double">
                  <div class="tbld_col tbld_col_single tbld_col_single_left">
                    {{ commission?.collectionPercentage | number : '1.2-2':'it' }}
                  </div>
                  <div class="tbld_col tbld_col_single">
                    {{ commission?.collectionAmount | currency:'EUR':'symbol':'1.2-2':'it' }}
                  </div>
                </div>
                <div class="tbld_col tbld_col_double">
                  <div class="tbld_col tbld_col_single tbld_col_single_left">
                    {{ commission?.collectionAdditionalPercentage | number : '1.2-2':'it' }}
                  </div>
                  <div class="tbld_col tbld_col_single">
                    {{ commission?.collectionAdditionalAmount | currency:'EUR':'symbol':'1.2-2':'it' }}
                  </div>
                </div>
                <div class="tbld_col tbld_col_double">
                  <div class="tbld_col tbld_col_single tbld_col_single_left">
                    {{ commission?.totalPercentage | number : '1.2-2':'it' }}
                  </div>
                  <div class="tbld_col tbld_col_single">
                    {{ commission?.totalAmount | currency:'EUR':'symbol':'1.2-2':'it' }}</div>
                </div>
              </div>
            </ng-container>
            <div class="tbld_row tbld_row_commissions_total">
              <div class="tbld_col"></div>
              <div class="tbld_col">
                <span translate="">Total</span>
              </div>
              <div class="tbld_col tbld_col_double">
                <div class="tbld_col tbld_col_single tbld_col_single_left">
                  {{ nextInstalmentTotal?.purchasePercentage | number : '1.2-2' :'it'}}
                </div>
                <div class="tbld_col tbld_col_single">
                  {{ nextInstalmentTotal?.purchaseAmount | currency:'EUR':'symbol':'1.2-2':'it' }}</div>
              </div>
              <div class="tbld_col tbld_col_double">
                <div class="tbld_col tbld_col_single tbld_col_single_left">
                  {{ nextInstalmentTotal?.purchaseAdditionalPercentage | number : '1.2-2' :'it' }}
                </div>
                <div class="tbld_col tbld_col_single">
                  {{ nextInstalmentTotal?.purchaseAdditionalAmount | currency:'EUR':'symbol':'1.2-2':'it' }}
                </div>
              </div>
              <div class="tbld_col tbld_col_double">
                <div class="tbld_col tbld_col_single tbld_col_single_left">
                  {{ nextInstalmentTotal?.collectionPercentage | number : '1.2-2' :'it' }}
                </div>
                <div class="tbld_col tbld_col_single">
                  {{ nextInstalmentTotal?.collectionAmount | currency:'EUR':'symbol':'1.2-2':'it' }}</div>
              </div>
              <div class="tbld_col tbld_col_double">
                <div class="tbld_col tbld_col_single tbld_col_single_left">
                  {{ nextInstalmentTotal?.collectionAdditionalPercentage | number : '1.2-2' :'it'}}
                </div>
                <div class="tbld_col tbld_col_single">
                  {{ nextInstalmentTotal?.collectionAdditionalAmount | currency:'EUR':'symbol':'1.2-2':'it' }}
                </div>
              </div>
              <div class="tbld_col tbld_col_double">
                <div class="tbld_col tbld_col_single tbld_col_single_left">
                  {{ nextInstalmentTotal?.totalPercentage | number : '1.2-2' :'it' }}
                </div>
                <div class="tbld_col tbld_col_single">
                  {{ nextInstalmentTotal?.totalAmount | currency:'EUR':'symbol':'1.2-2':'it' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div *ngIf="derogate && isProposal" class="btn-group">
            <button (click)="save()" class="btn btn-warning" data-qa="btn-commission-modal-recalculate-save" translate>
              RECALCULATE AND SAVE
            </button>
          </div>
          <div class="btn-group">
            <button (click)="close()" class="btn btn-warning" data-qa="btn-commission-modal-recalculate-close"
                    translate>CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import {Component, EventEmitter, Inject, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';

export class ModalDialogData {
  message: string;
  confirm: boolean;
  constructor(message: string, confirm?: boolean) {
    this.message = message;
    this.confirm = confirm;
  }
}

@Component({
  selector: 'rgi-anag-anag-dialog',
  templateUrl: './anag-dialog.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class AnagDialogComponent implements OnInit, OnModalClose {

  modalClose: EventEmitter<any> = new EventEmitter();
  message: string;
  confirmMode: boolean;

  constructor(@Optional() @Inject(DIALOG_DATA) data: ModalDialogData) {
    this.message = data.message;
    this.confirmMode = data.confirm;
  }

  ngOnInit() {
  }

  close() {
    this.modalClose.emit();
  }

  confirm() {
    this.modalClose.emit({confirm: true});
  }
}

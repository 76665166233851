import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, ApiClaim, CardComponent, CardComponentService, Outcome, SessionService } from '@rgi/digital-claims-common-angular';
import { Invoice } from '../dto/invoice';
import { Typeofloss } from '../dto/typeofloss';
import { InvoiceService } from '../invoice.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-invoice-typeofloss-list',
  templateUrl: './invoice-typeofloss-list.component.html',
  styleUrls: ['./invoice-typeofloss-list.component.scss']
})
export class InvoiceTypeoflossListComponent implements OnInit {

  @Input() invoice: Invoice;
  @Input() claim: ApiClaim;
  errorMessage: string;
  validationMessages: string[];
  updateInvoiceForm: UntypedFormGroup;
  selectedTypeofloss: Typeofloss;
  resCore: any;
  claimCurrent: any;
  typeofloss: any;
  typeoflosslist: Typeofloss[] = [];
  submitted = false;

  constructor(
    @Inject('sessionService') private session: any,
    @Inject('angularGridInstance') private gridInstance: any,
    @Inject('eventService') private eventService: any,
    // @Inject('coreResult') coreResult,
    private invoiceService: InvoiceService,
    private formBuilder: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    public translateService: RgiRxTranslationService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.typeoflossListSearch();
    this.initValidators();
  }

  typeoflossListSearch() {
    this.eventService.broadcastEvent('start-loader');

    // TODO gestire l'array di dettagli per i proforma dato che viene preso sempre il valore 0
    this.invoiceService.getTypeoflossList(this.invoice.invoiceDetailsList[0].idDetails.toString())
    .subscribe((response: any) => {
      this.typeoflosslist = response.typeoflossList;
      if (!this.typeoflosslist || this.typeoflosslist.length === 0) {
        this.translateService.translate('_CLAIMS_._NO_RESULT').subscribe(res=> {
          this.errorMessage=res;
        }
        )
      }
      this.eventService.broadcastEvent('stop-loader');
    },
      (error: Error) => {
        console.log(error);
        this.translateService.translate('_CLAIMS_._MESSAGE_._SEARCH_ERROR').subscribe(res=> {
          this.errorMessage=res;
        }
        )
        this.eventService.broadcastEvent('stop-loader');
      }
    );
  }

  initValidators() {
    this.updateInvoiceForm = this.formBuilder.group({
      typeofloss: [, Validators.required]
    }
    );
  }

  confirmInvoice() {
    if (this.updateInvoiceForm.valid) {
      this.eventService.broadcastEvent('start-loader');

      // TODO - for future proforma management (more details for itself invoice) detailsTotAmountUnit e detailsTaxableAmount manage in cicle
      this.invoice.invoiceDetailsList[0].idTypeofloss = this.selectedTypeofloss.id;
      this.invoice.invoiceDetailsList[0].idParty = this.selectedTypeofloss.idparty;

      this.saveInvoice(this.invoice);
    } else {
      // this.validationMessages = [];
      // this.validationMessages.push('Fields marked in red are required or contain incorrect values');
      this.translateService.translate('_CLAIMS_._SELECT_TYPE_OF_LOSS').subscribe(res=> {
        this.errorMessage=res;
      }
      )
    }
    return null;
  }

  saveInvoice(invoice: Invoice) {
    this.invoiceService.saveInvoice(invoice, this.invoice.invoiceDetailsList[0].rejectType.codice).subscribe(
      (response: Outcome) => {
        if (response.code === Outcome.OK) {
          this.activeModal.close(response);
          this.translateService.translate('_CLAIMS_._MESSAGE_._UPDATE_SUCCESSFUL').subscribe(res=> {
            this.alertService.translatedMessage(res);
          }
          )
        } else {
          this.validationMessages = [response.descr];
        }
        this.eventService.broadcastEvent('stop-loader');
      },
      (error: Error) => {
        console.error(error);
        this.translateService.translate('_CLAIMS_._MESSAGE_._SAVING_ERROR').subscribe(res=> {
          this.validationMessages.push(res);
        }
        )
        this.eventService.broadcastEvent('stop-loader');
      });
  }

  onSelectionChange(selectedTypeofloss: Typeofloss) {
    this.selectedTypeofloss = selectedTypeofloss;
  }

  hasResult() {
    if (this.typeoflosslist && this.typeoflosslist.length > 0) {
      return true;
    }
    return false;
  }

  closeModal(event: any) {
    this.activeModal.close();
    event.stopPropagation();
    return false;
  }

}



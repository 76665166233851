import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AddressesService} from '../../addresses.service';

@Component({
  selector: 'mic-address-normalize',
  templateUrl: './normalize-address.component.html',
  styleUrls: ['./normalize-address.component.scss']
})
export class NormalizeAddressComponent implements OnInit {

  areaCode = 'ADDRESS_MODAL';
  addressesList: any[];
  filteredAddresses: any[];
  itemsForPage = 10;
  page = 0;
  search: string = null;
  loaded = false;

  @Input() address: any;

  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  @Output() changedAddress: EventEmitter<any> = new EventEmitter<any>();

  constructor(protected addressesService: AddressesService) {
  }

  ngOnInit() {
    this.addressesService.normalizeAddress(this.address).subscribe(res => {
      this.loaded = true;
      if (res && res.result && res.result.normalizedAddresses) {
        this.addressesList = res.result.normalizedAddresses;
        this.filteredAddresses = this.addressesList;
      }
    });
  }

  setPage(n: number) {
    this.page = n;
  }

  filterAddresses() {
    if (this.search && this.search.trim() !== '') {
      this.filteredAddresses = this.addressesList.filter(elem => {
        return elem.formatAddress.toUpperCase().includes(this.search.trim().toUpperCase());
      });
    } else {
      this.filteredAddresses = this.addressesList;
    }
    this.setPage(0);
  }

  getItemsToShow() {
    return this.filteredAddresses.slice(this.page * this.itemsForPage, this.page * this.itemsForPage + this.itemsForPage);
  }

  select(address) {
    this.changedAddress.emit({
      adminLevel1: address.adminLevel1,
      adminLevel1Short: address.adminLevel1Short,
      adminLevel2: address.adminLevel2,
      adminLevel2Short: address.adminLevel2Short,
      adminLevel3: address.adminLevel3,
      adminLevel3Short: address.adminLevel3Short,
      cap: address.cap,
      city: address.city ? address.city : address.adminLevel3,
      country: address.country ? address.country : {code: address.countryCode},
      countryCode: address.countryCode,
      formatAddress: address.formatAddress,
      fraction: address.fraction,
      locality: address.locality,
      normalized: address.normalized,
      number: address.number,
      placeAddress: address.placeAddress,
      province: address.province ? address.province :
        {code: address.adminLevel2Short, description: address.adminLevel2},
      toponym: {code: address.toponym ? address.toponym.descrizione : undefined,
        description: address.toponym ? address.toponym.descrizione : undefined}
    });
  }

  getPages() {
    if (this.filteredAddresses && this.filteredAddresses.length > 0) {
      return new Array(Math.ceil((this.filteredAddresses.length / this.itemsForPage)));
    }
    return [{}];
  }

}

import { Component, Inject, Input, LOCALE_ID, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CardComponentService,
  DamagedAsset,
  Field,
  Policy,
  Party,
  SelectableCovertureResponse,
  DomainTranslatorFieldService} from '@rgi/digital-claims-common-angular';
import { ClaimOpeningService } from '../../../../claim-opening.service';
import { DamageCategoriesCacheEntity } from '../../damage-category-panel-content/dto/damage-categories-cache-entity';
import { Bodywork } from '../dto/bodywork';
import { MultiPartyEntity } from '../dto/multi-party-entity';
import { PartyData } from '../dto/party-data';
import { PartyDataNewAssetComponent } from '../party-data-new-asset/party-data-new-asset.component';
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Component({
  selector: 'claims-add-party',
  templateUrl: './add-party.component.html',
  styleUrls: ['./add-party.component.scss']
})
export class AddPartyComponent implements OnInit {
  @Input() partiesWithoutDuplication: Party[] = [];
  @Input() partiesWithoutDuplicationIdOnly: string[] = [];
  @Input() thirdParty: Party;
  @Input() typesOfLoss: { id: string, description: string }[] = [];
  @Input() selectableCoverturesResponse: SelectableCovertureResponse;
  @Input() thirdPartyEnabled: boolean;
  @Input() enabled: boolean;
  @Input() public card: any;
  @Input() driverParty: Party;
  @Input() driverEnabled: boolean;
  @Input() selectedPartyRadio: Party;
  @Input() damagedAssetsVehicle: DamagedAsset[] = [];
  @Input() damagedAssetsIdsVehicle: string[] = [];
  @Input() damagedAssetsWithoutDuplication: DamagedAsset[] = [];
  @Input() damagedAssetsWithoutDuplicationIdOnly: string[] = [];
  @Input() selectedAsset: any;
  @Input() damagedAssetsInjury: DamagedAsset[] = [];
  @Input() damagedAssetsIdsInjury: string[] = [];
  @Input() damagedAssetsMaterialDamage: DamagedAsset[] = [];
  @Input() damagedAssetsIdsMaterialDamage: string[] = [];
  @Input() partyDataForm: UntypedFormGroup;
  @Input() isAddAssetEnabled: boolean;
  @Input() fields: Field[];
  @Input() bodyworkData: Bodywork;
  @Input() multiDamages: MultiPartyEntity;
  @Input() damageCategoriesData: DamageCategoriesCacheEntity;
  @Input() pes: any;
  @Input() pncAccordion: any;
  @Input() selectedInsuredRadio: Party;
  @Output() closeEventEmitter = new EventEmitter<boolean>();
  @Output() addEventEmitter = new EventEmitter<boolean>();
  validationMessages: string[];
  selectCovertures: boolean = false;
  isSelectedThirdParty: boolean;
  policy: Policy;
  public abilitaAperturaSenzaCausale: boolean;

  constructor(
    private cardComponentService: CardComponentService,
    public formBuilder: UntypedFormBuilder,
    private claimOpeningService: ClaimOpeningService,
    private domainTranslatorFieldService: DomainTranslatorFieldService,
    private modalService: NgbModal,
    @Inject(LOCALE_ID) private locale: string,
    @Inject('eventService') private eventService: any,
    @Inject('claimsBodyworkService') private claimsBodyworkService: any,
    @Inject('sessionService') private sessionService: any,
    @Inject('coreResult') private coreResult: any, //
    @Inject('AnagFinderSubject') private anagFinderSubject: any,
    @Inject('authService') private authService: any,
    private rxTranslationsService: RgiRxTranslationService) {

      const listFunz = this.authService.getOperator().enabledFeatures;

      if (listFunz && listFunz.indexOf('SXSCOP') >= 0) {
        this.abilitaAperturaSenzaCausale = true;
      }
    }

  ngOnInit() {
    this.policy = this.claimOpeningService.getSessionSelectedPolicy(this.card.id);
    this.selectCovertures = false;
  }

  onThirdPartyChange(event: any) {
    if (!event) {
      this.selectedPartyRadio = null;
    }
  }

  openNewAssetModal(event: any) {
    event.stopPropagation();

    const modalRef = this.modalService.open(PartyDataNewAssetComponent, {
      windowClass: 'basic',
      size: 'lg'
    });
    const dann: Party = this.selectedPartyRadio ? this.selectedPartyRadio : this.partiesWithoutDuplication[0];

    modalRef.componentInstance.nextId = this.damagedAssetsMaterialDamage.length;
    modalRef.componentInstance.party = dann;
    if (dann.type === 'G') {
      modalRef.componentInstance.typesOfLoss = this.typesOfLoss.filter(el => el.id !== '2');
    } else {
      modalRef.componentInstance.typesOfLoss = this.typesOfLoss;
    }
    modalRef.componentInstance.card = this.card;
    modalRef.componentInstance.injuryCauseList = this.selectableCoverturesResponse.injuryCauseList;
    modalRef.componentInstance.injuryNatureList = this.selectableCoverturesResponse.injuryNatureList;
    modalRef.componentInstance.professionList = this.selectableCoverturesResponse.professionList;
    modalRef.componentInstance.injuryLocationList = this.selectableCoverturesResponse.injuryLocationList;

    this.claimOpeningService.setDamagedAssetsMaterialDamage(this.damagedAssetsMaterialDamage);
    this.claimOpeningService.setDamagedAssetsInjury(this.damagedAssetsInjury);
    this.claimOpeningService.setDamagedAssetsVehicle(this.damagedAssetsVehicle);
    this.claimOpeningService.setDamagedAssetsWithoutDuplication(this.damagedAssetsWithoutDuplication);
    this.claimOpeningService.setNextId(modalRef.componentInstance.nextId);
    this.claimOpeningService.setTypesOfLoss(modalRef.componentInstance.typesOfLoss);
    this.claimOpeningService.setParty(modalRef.componentInstance.party);
    this.claimOpeningService.setCard(modalRef.componentInstance.card);
    this.claimOpeningService.setInjuryCauseList(modalRef.componentInstance.injuryCauseList);
    this.claimOpeningService.setInjuryNatureList(modalRef.componentInstance.injuryNatureList);
    this.claimOpeningService.setInjuryLocationList(modalRef.componentInstance.injuryLocationList);
    this.claimOpeningService.setProfessionList(modalRef.componentInstance.professionList);


    const that = this;

    modalRef.result.then((result) => {
      if (result) {
        console.log('openNewAssetModal -->', result);
        if (result.assettype === '2') {
          this.claimOpeningService.getDamagedAssetsInjury().push(result);
        } else if (result.assettype === '1') {
          this.claimOpeningService.getDamagedAssetsVehicle().push(result);
        } else {
          this.claimOpeningService.getDamagedAssetsMaterialDamage().push(result);
        }
        this.claimOpeningService.getDamagedAssetsWithoutDuplication().push(result);
        if (this.damagedAssetsMaterialDamage.length === 1) {

        }
      }
    });

    return false;
  }

  addBodywork() {
    const nodeId = this.authService.getSalePointId();
    this.cardComponentService.emit({
      eventName: 'bodywork',
      salePointId: nodeId
    }, this.card.id);
  }

  deleteBodywork(event: any) {
    this.bodyworkData = null;
    event.stopPropagation();
  }

  closeModal(event: any) {
    return false;
  }

  onConfirmDamage() {
    this.selectedDamagedAssets();

    if (this.validateContent()) {
     this.isSelectedThirdParty = this.selectedPartyRadio === this.thirdParty;
     this.selectCovertures = true;
    }

  }

  progress(nextStep: number) {
  }

  onEditDone(edited: boolean) {
  }

  protected validateContent(): boolean {
    let valid = true;
    this.validationMessages = [];
    const partyToSend: Party[] = this.selectedPartyRadio ? [this.selectedPartyRadio] : this.partiesWithoutDuplication;

    if (this.multiDamages.partyList.find(partyChg =>
      partyChg.parties[0].id === partyToSend[0].id &&
      partyChg.damagedassets[0].assettype === this.selectedAsset[0].assettype
    )) {
      this.validationMessages.push('The damage has already been registered');
      valid = false;
    }

    if (this.fields) {
      this.fields.forEach((el: Field) => {
        if (!el.disabled && el.mandatory && !el.value) {
          if (this.validationMessages.length === 0) {
            this.rxTranslationsService.translate('_CLAIM_._MESSAGE_._FIELDS_MARKED_IN_RED_ARE_REQUIRED_OR_CONTAIN_INCORRECT_VALUES').subscribe(
              res =>  this.validationMessages.push(res)
            )
          }
          valid = false;
        }
      });
    }

    if (this.isSelectedPartyError()) {
      this.rxTranslationsService.translate('_CLAIM_._MESSAGE_._YOU_HAVE_TO_SELECT_AT_LEAST_ONE_PARTY').subscribe(
        res =>  this.validationMessages.push(res)
      )
      valid = false;
    }

    if (this.isSelectedAssetError()) {
      this.rxTranslationsService.translate('_CLAIM_._MESSAGE_._YOU_HAVE_TO_SELECT_AT_LEAST_ONE_ASSET').subscribe(
        res =>  this.validationMessages.push(res)
      )
      valid = false;
    }

    if (this.driverEnabled && !this.driverParty) {
      this.validationMessages.push('Driver mandatory');
      valid = false;
    }

    return valid;
  }

  isSelectedPartyError(): boolean {
    return (this.partiesWithoutDuplication.length === 0 && !this.thirdParty) ||
      (this.thirdParty || this.partiesWithoutDuplication.length > 1) && !this.selectedPartyRadio;
  }

  isSelectedAssetError() {
    return this.damagedAssetsWithoutDuplication.length === 0 ||
      (this.isMultipleAssetsPresent() && !this.selectedAsset);
  }

  isMultipleAssetsPresent() {
    return this.damagedAssetsWithoutDuplication && this.damagedAssetsWithoutDuplication.length > 1;
  }


  selectedDamagedAssets(): DamagedAsset[] {
    let selectedAsset: DamagedAsset[] = [];

    if (this.damagedAssetsWithoutDuplication && this.damagedAssetsWithoutDuplication.length > 1) {
      for (const asset of this.damagedAssetsWithoutDuplication) {
        if (asset.id === this.selectedAsset ||
            asset.id === this.selectedAsset[0].id) {
          selectedAsset = [asset];
          break;
        }
      }
    } else if (this.damagedAssetsWithoutDuplication) {
      selectedAsset = [this.damagedAssetsWithoutDuplication[0]];
    }
    // this.selectedAssetEmitter.emit(selectedAsset);
    this.selectedAsset = selectedAsset;
    return selectedAsset;
  }

  rectify(event: any) {
    // c
     this.selectCovertures = false;
  }

  close() {
    this.closeEventEmitter.emit(true);
  }

  add(event: DamageCategoriesCacheEntity) {
    const partyToSend: Party[] = this.selectedPartyRadio ? [this.selectedPartyRadio] : this.partiesWithoutDuplication;

    const bodywork = new Bodywork(this.bodyworkData ? this.bodyworkData.code : null);
    let assDamaged = this.selectedInsuredRadio.injured;
    partyToSend.forEach(aPar => {
        aPar.injured = true;
        if (aPar === this.selectedInsuredRadio) {
          aPar.insured = true;
          assDamaged = true;
          this.selectedInsuredRadio.injured = true;
        }
    });

    if (!assDamaged ) {
        this.selectedInsuredRadio.insured = true;
        this.selectedInsuredRadio.injured = false;
        // partyToSend.push(this.selectedInsuredRadio);
    }
    const data = new PartyData(
        this.claimOpeningService.getSessionUuid(this.card.id),
        this.selectedDamagedAssets(),
        partyToSend,
        this.driverParty ? this.driverParty.id : null,
        this.fields ? this.domainTranslatorFieldService.toDomainFields(this.fields) : null,
        bodywork,
        event
    );
    this.multiDamages.partyList.push(data);
    if (!this.multiDamages.regAmm) {
     this.multiDamages.regAmm = event.regAmm;
    }
    if (!this.multiDamages.regTech) {
      this.multiDamages.regTech = event.regTech;
    }
    this.addEventEmitter.emit(true);
  }
}

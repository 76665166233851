export class Company {

    public objectId: string;
    public aniaCode: string;
    public extension: string;
    public code: string;
    public damages: boolean;
    public life: boolean;
    public description: string;

    constructor() {}

}

import { TaxregimeEntity } from './taxregime-entity';

export class LegalFeeEntity {
    public taxableAmount: number; // imponibile
    public notTaxableAmount: number; // non imponibile
    public bolliAmount: number;
    public netAmount: number;
    public cash1Amount: number;
    public cash2Amount: number;
    public totalAmount: number;
    public ivaAmount: number;
    public withHoldingAmount: number; // importo ritenuta
    public idSoggLegal: string;
    public taxIvaRegime: TaxregimeEntity;
    public taxRegime: TaxregimeEntity;
    public feeNumber: string;
    public legalCode: string;
    public taxIvaRegimeAggregate: string;
    public taxRegimeAggregate: string;
    public feeYear: number;
    public country: string;
    public feeTotalNumber: string;
    public feeDate: Date;
    public feeDateRecording: Date;
    public generatePayment: boolean;
    public feeType: string;
    public SDIProtocolCod: string;
    public SAPProtocolKey: string;

}

import {Component, Input} from '@angular/core';
import { Factor } from '../../../../../models/details/contract-details';

@Component({
  selector: 'lib-consultation-warranties-assets-factors',
  templateUrl: './consultation-factors.component.html',
  styleUrls: ['./consultation-factors.component.css']
})
export class ConsultationWarrantiesAssetsFactorsComponent {
  @Input() factors:Factor[];
  dateFormat:string ='dd/MM/yyyy';
}

import { Injectable } from '@angular/core';
import {combineLatest, Observable, of} from "rxjs";
import {ActiveRoute, RoutingService} from "@rgi/rx/router";
import {DataForSteps} from "../../group-policy-models/group-policy-issue-home";
import {map, mergeMap} from "rxjs/operators";
import {GroupPolicyIntegrationService} from "../../group-policy-services/group-policy-integration.service";
import {ButtonsAction} from "../../group-policy-models/group-policy-issue-summary";
import {
  ACTION_ENUM_ISSUE_COMPLETED
} from "../../group-policy-models/group-policy-issue-confirm";
import {RgiRxTranslationService} from "@rgi/rx/i18n";
import {GroupPolicyStateIssueConfirm} from '../group-policy-state';

@Injectable({
  providedIn: 'root'
})

export class GroupPolicyStatelessOpIssueConfirmService {

  constructor(protected  routingService: RoutingService,
              protected integrationService: GroupPolicyIntegrationService,
              protected translationService: RgiRxTranslationService) { }

  initIssueConfirmData$(gpStateIssueConfirm$: Observable<GroupPolicyStateIssueConfirm>, activeRoute: ActiveRoute): Observable<GroupPolicyStateIssueConfirm> {
    const previousStepData = activeRoute.getRouteData<DataForSteps>();
    const endActionBtn = {} as ButtonsAction;
    return gpStateIssueConfirm$.pipe(
      mergeMap((st: GroupPolicyStateIssueConfirm) => {
        return combineLatest(of(st), this.translationService.translate('_GP_._BTN_._LEAVE_'))
      }),
      map(([st, translation]: [GroupPolicyStateIssueConfirm, string]) => {
          endActionBtn.code = ACTION_ENUM_ISSUE_COMPLETED.END;
          endActionBtn.description = translation;
          st.contractData = previousStepData.issuedContractData;
          st.contractData.actionButtons = this.addEndActionBtn(endActionBtn, st.contractData.actionButtons);
          return st;
        }
      )
    );
  }

  actionGoToInquiry$(groupPolicyStateIssueConfirm$: Observable<GroupPolicyStateIssueConfirm>, previousStepData: DataForSteps) {
    return groupPolicyStateIssueConfirm$.pipe(
      map((st) => {
          if (st.contractData && (!!st.contractData.proposalNumber || !!st.contractData.policyNumber)) {
            previousStepData.errors = [];
            previousStepData.type = undefined;
            previousStepData.issuedContractData = st.contractData;
            this.integrationService.goToInquiry(previousStepData);
          } else {
            throw ('Policy number not  set');
          }
          return st;
        }
      )
    );
  }

  actionBackHome$(groupPolicyStateIssueConfirm$: Observable<GroupPolicyStateIssueConfirm>, activeRoute: ActiveRoute,) {
    return groupPolicyStateIssueConfirm$.pipe(
      map( (st) => {
          this.integrationService.backToHome(activeRoute);
          return st;
        }
      )
    )
  }

  addEndActionBtn (endBtn: ButtonsAction, buttonsList: ButtonsAction[]): ButtonsAction[]{
    let actionButtons = !!buttonsList ? buttonsList : [];
    if (!!endBtn) {
      actionButtons = actionButtons.concat([endBtn]);
    }
    return actionButtons;
  }
}

<div [hidden]="!active" data-qa="step-roles-roles">
  <ng-template #lpcRoleStepReference></ng-template>
</div>
<div *ngIf="!active && !!rolesForSummary && rolesForSummary.length > 0" data-qa="summary-roles-step">
  <div class="col-lg-12">
    <div class="col-lg-6" *ngFor="let role of rolesForSummary">
      <ng-container *ngIf="!!role?.label && !!role?.value">
        <span>{{role?.label}}: </span><span>{{role?.value}}</span>
      </ng-container>
      <span *ngIf="!!role && !!role.percentage"> ({{role?.percentage}})</span>
    </div>
  </div>
</div>
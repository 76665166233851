<div class="card shadow" style="min-height: 10em;">
  <form [formGroup]="searchForm">
    <div class="form-group">
      <div class="form-group">
        <div class="col-xs-9">
          <label ng-bind="label">{{ '_CLAIMS_._CLAIM_NUMBER' | translate }} </label>

          <input type="text" class="form-control" name="claimNumber" formControlName="claimNumber"
            attr.data-qa="autosoftsearch-claimNumber-input">
        </div>
        <div class="col-xs-3">
          <br><button class="btn btn-warning pull-right" (click)="searchNoTransmission()">{{ '_CLAIMS_._BUTTONS_._FAILED_TRANSMISSION' |
            translate }}</button>
        </div>
      </div>
    </div>
    <br> <br> <br>
    <div class="form-group">
      <div class="form-group">
        <label ng-bind="label">{{ '_CLAIMS_._ASSIGNMENT' | translate }} </label>

        <input type="text" class="form-control" name="assignmentNumber" formControlName="assignmentNumber"
          attr.data-qa="autosoftsearch-assignmentNumber-input">
      </div>
    </div>
    <div class="form-group">
      <div class="form-group">
        <div class="col-xs-6">
          <pnc-datepicker label="{{ '_CLAIMS_._FROM' | translate }}" customFormControlName="dateFrom" [customFormGroup]="searchForm"
            [maxDate]="today" attr.data-qa="autosoftsearch-dateFrom-input">
          </pnc-datepicker>
        </div>
        <div class="col-xs-6">
          <pnc-datepicker label="{{ '_CLAIMS_._TO' | translate }}" customFormControlName="dateTo" [customFormGroup]="searchForm"
            [maxDate]="today" attr.data-qa="autosoftsearch-dateTo-input">
          </pnc-datepicker>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group">
        <label ng-bind="label">{{ '_CLAIMS_._EVENTS' | translate }} </label>
        <select class="core-select form-control" id="event" name="event" formControlName="event"
          attr.data-qa="autosoftsearch-event-input">
          <option value="">&nbsp;</option>
          <option value="R-PER">{{ '_CLAIMS_._R_PER' | translate }}</option>
          <option value="A-COM">{{ '_CLAIMS_._A_COM' | translate }}</option>
          <option value="S-RIF">{{ '_CLAIMS_._S_RIF' | translate }}</option>
          <option value="R-FAT">{{ '_CLAIMS_._R_FAT' | translate }}</option>
        </select>
        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
      </div>
    </div>
    <!-- <div class="form-group">
      <div class="form-group">
        <div class="col-xs-3">

          <label ng-bind="label">{{ '_CLAIMS_._IN_ERROR' | translate }} </label>
        </div>
        <div class="col-xs-9">
          <input type="radio" class="btn btn-radio" name="inError" value="true" formControlName="inError"
            attr.data-qa="autosoftsearch-inError-input"> &nbsp;&nbsp; <label ng-bind="label">{{ 'Yes' | translate
            }}</label> &nbsp;&nbsp;
          <input type="radio" class="btn btn-radio" name="inError" value="false" formControlName="inError"
            attr.data-qa="autosoftsearch-inError-input"> &nbsp;&nbsp; <label ng-bind="label">{{ 'No' | translate
            }}</label> &nbsp;&nbsp;
          <input type="radio" class="btn btn-radio" name="inError" value="null" formControlName="inError"
            attr.data-qa="autosoftsearch-inError-input"> &nbsp;&nbsp; <label ng-bind="label">{{ 'All' | translate
            }}</label>

        </div>

      </div>
    </div> -->
    <br><br>
    <div class="form-group">
      <div class="alert alert-danger" *ngIf="errore" role="alert">{{ errorMessage }} </div>
      <div *ngIf="!errore">&nbsp;<br> </div>
    </div>

    <div class="btn-group btn-group-justified">
      <div class="btn-group">
        <button class="btn btn-warning pull-right" (click)="clearFilter()">{{ '_CLAIMS_._BUTTONS_._EMPTY' | translate }}</button>
      </div>
      <div class="btn-group">
        <button class="btn btn-warning pull-right" (click)="searchTransmission()">{{ '_CLAIMS_._BUTTONS_._TRANSMISSION' | translate
          }}</button>
      </div>
      <div class="btn-group">
        <button class="btn btn-warning pull-right" (click)="searchEvents()">{{ '_CLAIMS_._BUTTONS_._EVENTS' | translate }}</button>
      </div>
    </div>
  </form>
</div>
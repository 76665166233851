import { Injectable } from '@angular/core';
import {TranslationWrapperService} from '../../i18n/translation-wrapper.service';
import {ErrorLevel, ErrorMessageFromService} from '../models/error-message.model';

@Injectable({
  providedIn: 'root'
})
export class MessageHandlerService {

  constructor(protected translateService: TranslationWrapperService) { }

  public static createSimpleWarningMessage(description: string): ErrorMessageFromService {
    const error: ErrorMessageFromService = new ErrorMessageFromService();
    error.level = ErrorLevel.warning;
    error.description = description;
    error.otherInfo = null;
    return error;
  }

  public static createSimpleErrorMessage(description: string): ErrorMessageFromService {
    const error: ErrorMessageFromService = new ErrorMessageFromService();
    error.level = ErrorLevel.error;
    error.description = description;
    error.otherInfo = null;
    return error;
  }
}

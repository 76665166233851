import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CompatibleProducts} from '../models/api-models/api-compatible-products';
import {PolicySubstitution} from '../models/api-models/api-substitution-policy';
import {ApiSubstitutionReasonConf} from '../models/api-models/api-substitution-reason-config';
import {SubReasons} from '../models/api-models/api-substitution-reasons';
import {PolicySubstitute} from '../models/domain-models/policy-substitution';
import {PolicySubstitutionFeasibility} from '../models/api-models/api-substitution-feasibility';
import {MicHttpService} from '../http/mic-http.service';

@Injectable({
  providedIn: 'root'
})
export class SubstitutionService {
  private baseApiUrl: any;
  private baseApiUrlV2: any;
  private portfolioPolicyEndpoint: any;
  warningMessages: Array<string> = [];
  protected httpClient: HttpClient;

  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any,
    protected translate: TranslateService) {
    const portfolioServicePath = environment.api.portal.nmf;

    this.httpClient = this.micHttpClient.getHttpClient();

    this.baseApiUrl = (portfolioServicePath !== null && portfolioServicePath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + portfolioServicePath
      : environment.api.portal.host + environment.api.portal.path;

    this.baseApiUrlV2 = (portfolioServicePath !== null && portfolioServicePath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + portfolioServicePath
      : environment.api.portal.host + environment.api.portal.path + '/v2';

    this.portfolioPolicyEndpoint = this.baseApiUrlV2 + '/portfolio/policy';
  }

  public getSubstitutionReasons(policyNumber: string) {
    const path = this.portfolioPolicyEndpoint + '/' + policyNumber + '/substitutionreasons';
    return this.httpClient.get<SubReasons>(path, {params: new HttpParams()});
  }

  public getCompatibleProducts(policyNumber: string, operationTimestamp: number) {
    const path = this.portfolioPolicyEndpoint + '/' + policyNumber + '/compatibleproducts';
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('effectDate', String(operationTimestamp));
    return this.httpClient.get<CompatibleProducts>(path, {params: httpParams});
  }

  public createSubstituentContract(policyNumber: string, operationTimestamp: number, sIdSubstitutionReason: string,
                                   sIdProduct: string, clusterCode: string, assetCode: string ) {
    const path = this.portfolioPolicyEndpoint + '/' + policyNumber + '/substitute';
    const policySubstitute = new PolicySubstitute(String(operationTimestamp), sIdSubstitutionReason, sIdProduct, clusterCode, assetCode);
    return this.httpClient.post<PolicySubstitution>(path, policySubstitute);
  }

  public getSubstitutionReasonConfig(resourceId: string) {
    const path = this.portfolioPolicyEndpoint + '/' + resourceId + '/substitutionreasonconfig';
    return this.httpClient.get<ApiSubstitutionReasonConf>(path);
  }

  public checkPartyRoleChanged(resourceId: string, role: number) {
    const path = this.portfolioPolicyEndpoint + '/' + resourceId + '/checkrolechanged';
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.set('role', String(role));
    return this.httpClient.get<boolean>(path, {params: httpParams});
  }

  public checkSubstitutionFeasibility(policyNumber: string, operationTimestamp: number, sIdSubstitutionReason: string,
                                      sIdProduct: string, clusterCode: string, assetCode: string ) {
    const path = this.portfolioPolicyEndpoint + '/' + policyNumber + '/substitutionfeasibility';
    const policySubstitute = new PolicySubstitute(String(operationTimestamp), sIdSubstitutionReason, sIdProduct, clusterCode, assetCode);
    return this.httpClient.post<PolicySubstitutionFeasibility>(path, policySubstitute);
  }

  setWarningMessages(warningMessages: string[]) {
    this.warningMessages = warningMessages;
  }

  getWarningMessages() {
    return this.warningMessages;
  }
}

import { TableSchema } from '@rgi/rx/ui';
import { AddressResult } from '../../dsc-shared-model/dsc-model-address';
import { ServiceInfo } from '../../dsc-shared-model/dsc-model-utility';

export interface OutputAddressList {
    serviceInfo: ServiceInfo;
    result?: AddressResult;
}

export const CLAIMS_ADDRESS_NORMALIZED: TableSchema = {
    rows: [
        {
            name: 'formatAddress',
            title: '_CLAIMS_._ADDRESS'
        },
        {
            name: 'actions',
            title: '_CLAIMS_._BUTTONS_._ACTIONS',
            styleClass: 'rgi-gp-action-cell',
            actions: [
                {
                    name: 'SUMMARY',
                    styleClass: 'rgifont dt-btn rgi-chevron-right cus',
                }
            ]
        }
    ],
    header: ['formatAddress', 'actions']
};



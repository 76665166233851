import {InjectionToken} from '@angular/core';
import {RgiRxGlobalOverlayPositionStrategy} from '../rgi-ui-api';
import {RgiRxOverlayOptions} from '../overlay/overlay-api';
import {RgiRxPushOptions, RgiRxSemanticStatus} from '@rgi/rx';

/**
 * Snackbar overlay configuration
 */
export interface RgiRxSnackbarOverlayConfig {
  positionStrategy: RgiRxGlobalOverlayPositionStrategy;
  options: RgiRxOverlayOptions;
}


/**
 * The context of a dynamic template or component
 */
export interface RgiRxSnackbarData<T> {
  id?: string;
  context: T;
  status: RgiRxSemanticStatus | string;
  opts?: RgiRxPushOptions;
}


/**
 * A token that can be injected into Dynamic components used for the overlay content and detail.
 * The value is assumed by the PushMessage context
 * @see RgiRxPushMessage
 */
export const RGI_RX_SNACKBAR_DATA = new InjectionToken<RgiRxSnackbarData<any>>('RGI_RX_SNACKBAR_DATA');

/**
 * Token for setting the snackbar overlay config
 */
export const RGI_RX_SNACKBAR_OVERLAY_CONFIG = new InjectionToken<RgiRxSnackbarOverlayConfig>('RGI_RX_SNACKBAR_OVERLAY_CONFIG');

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'claims-damaged-asset-material-damage',
  templateUrl: './damaged-asset-material-damage.component.html',
  styleUrls: ['./damaged-asset-material-damage.component.scss']
})
export class DamagedAssetMaterialDamageComponent implements OnInit {
  @Input() damagedAsset;
  @Input() damagedAssetIndex;
  @Input() allDamagedAssets;

  @Input() selectedAsset;
  @Input() disabled: boolean;
  @Output() selectedAssetChange = new EventEmitter<string>();
  private selectedAssetInternal: string;

  constructor() { }

  ngOnInit() {
  }
  isSelectedAssetError() {
    return this.isMultipleAssetsPresent() && !this.selectedAsset;
  }

  isMultipleAssetsPresent() {
    return this.allDamagedAssets && this.allDamagedAssets.length > 1;
  }

  transcodeNationCode(nationCode: string) {
    if (nationCode === 'IT') {
      return 'IT';
    } else {
      return 'Other';
    }
  }

  set selectedAssetRadio(val: string) {
    this.selectedAssetChange.emit(val);
    this.selectedAssetInternal = val;
  }

  get selectedAssetRadio() {
    return this.selectedAssetInternal;
  }

}

<div>
  <div class="card shadow" style="min-height: 10em;">
    <form [formGroup]="searchPolicyForm">
      <div class="form-group">

        <label ng-bind="label">{{ '_CLAIMS_._COMPANY' | translate }} </label>
        <!--<span class="field-required" translate="">obbligatorio</span>-->
        <select class="core-select form-control" id="companySel" name="company" formControlName="company"
          attr.data-qa="external-portfolio-company-input">
          <option *ngIf="!disableCompany"></option>
          <option *ngFor="let aComp of companies; " value={{aComp.aniaCode}}>{{aComp.description}}</option>
        </select>
        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
      </div>
      <div class="form-group">
        <label ng-bind="label">{{ '_CLAIMS_._POLICY_NUMBER' | translate }} </label>

        <input type="text" class="form-control" name="policyNumber" formControlName="policyNumber"
          attr.data-qa="external-portfolio-policyNumber-input">
      </div>

      <pnc-datepicker label="{{ '_CLAIMS_._OCCURRENCE_DATE' | translate }}" customFormControlName="occurrenceDate"
        [customFormGroup]="searchPolicyForm" [maxDate]="today" attr.data-qa="policy-search-occurrence-date-input">
      </pnc-datepicker>

      <div class="form-group">
        <label ng-bind="label">{{ '_CLAIMS_._ASSET_TYPE' | translate }} </label>

        <select class="core-select form-control" id="typeAssSel" name="typeAsset" formControlName="typeAsset"
          attr.data-qa="external-portfolio-typeAsset-input">
          <option></option>
          <option *ngFor="let ta of typeAssets; " value={{ta.value}}>{{ta.label}}</option>
        </select>
        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
      </div>

      <div class="form-group">
        <label ng-bind="label">{{ '_CLAIMS_._ASSET_INSURED' | translate }} </label>

        <input type="text" class="form-control" name="insuredAsset" attr.data-qa="external-portfolio-Asset-input"
          formControlName="insuredAsset">

      </div>
      <div class="form-group">
        <label ng-bind="label" style="color:#145F9E; font-size: 16px;">{{ '_CLAIMS_._SUBJECT_IDENTITY' | translate }}</label>
      </div>


      <div class="form-group">
        <label ng-bind="label">{{ '_CLAIMS_._SURNAME__BUSINESS_NAME' | translate }}</label>

        <input type="text" class="form-control" name="surBusinessName"
          attr.data-qa="external-portfolio-surBusinessName-input" formControlName="surBusinessName">

      </div>
      <div class="form-group">
        <label ng-bind="label">{{ '_CLAIMS_._NAME' | translate }} </label>

        <input type="text" class="form-control" name="firstName" attr.data-qa="external-portfolio-firstName-input"
          formControlName="firstName">

      </div>

      <div class="form-group">
        <label ng-bind="label">{{ '_CLAIMS_._FISCAL_CODE' | translate }} </label>

        <input type="text" class="form-control" name="fiscalCode" attr.data-qa="external-portfolio-fiscalCode-input"
          formControlName="fiscalCode">

      </div>

      <div class="form-group">
        <div class="alert alert-danger" *ngIf="errore" role="alert">{{ errorMessage | translate }} </div>
        <div *ngIf="!errore">&nbsp; </div>
      </div>
      <div class="col-xs-12">
        <div class="alert alert-danger" *ngIf="externalError" role="alert">{{ externalErrorMessage | translate }} </div>
        <div *ngIf="!externalError">&nbsp; </div>
      </div>

      <div class="btn-group btn-group-justified">
        <div class="btn-group">
          <button class="btn btn-warning pull-right" (click)="clearFilter()">{{ '_CLAIMS_._BUTTONS_._EMPTY' | translate }}</button>
        </div>
        <div class="btn-group">
          <button class="btn btn-warning pull-right" (click)="searchPolicy()">{{ '_CLAIMS_._BUTTONS_._FIND' | translate }}</button>
        </div>
        <div class="btn-group">
          <button class="btn btn-warning pull-right" (click)="newPolicy()" *ngIf="abilitaNew">{{ '_CLAIMS_._BUTTONS_._NEW' | translate
            }}</button>
          <button class="btn btn-warning pull-right" *ngIf="!abilitaNew" disabled>{{ '_CLAIMS_._BUTTONS_._NEW' | translate }}</button>
        </div>
      </div>
    </form>
  </div>
</div>
import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { InputFieldDefinition } from '../../models/postsales-operations-response.model';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { PlcDateUtils } from '../../utils/plc-date-utils';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { CLOSE_ASSIGNMENT_CONFIG, CloseStepConfiguration } from './config/close-assignment-configuration';



@Component({
  selector: 'lpc-close-assignment',
  templateUrl: './close-assignment.component.html',
  styleUrls: ['./close-assignment.component.css']
})
export class CloseAssignmentComponent extends AbsOperationComponent implements OnInit {

  public readonly CLOSE_ASSIGNMENT: CloseStepConfiguration = CLOSE_ASSIGNMENT_CONFIG;
  protected operationDataKey = 'close-assignment';
  public assignmentDates: InputFieldDefinition[];
  responseOperation: { [key: string]: any | any[] };

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected anag: AnagService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag);
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe(result => {
        if (!!result.definitions.assignmentClosingDate) {
          this.assignmentDates = result.definitions.assignmentClosingDate as InputFieldDefinition[];
          this.initAssignmentDatesForm();
        }
        this.updateClosingCauses(result);
        this.responseOperation = result;
      }));
  }

  private updateClosingCauses(result: any) {
    if (!!result.data && !!result.data.operationData && !!result.data.operationData.data) {
      this.formGroup.get(CLOSE_ASSIGNMENT_CONFIG.DATE.formName).get(CLOSE_ASSIGNMENT_CONFIG.CLOSE.formChildren.assignmentClosedCause).setValue(result.data.operationData.data.closingCauses);
    }
  }

  protected getFormGroup() {
    return new UntypedFormGroup({
      [CLOSE_ASSIGNMENT_CONFIG.DATE.formName]: new UntypedFormControl(),
      [CLOSE_ASSIGNMENT_CONFIG.CLOSE.formName]: new UntypedFormGroup({
      [CLOSE_ASSIGNMENT_CONFIG.CLOSE.formChildren.assignmentClosedCause]: new UntypedFormControl(null)
      })
    });
  }

  protected initAssignmentDatesForm() {
    this.assignmentDates.map(date => {
      if (date.code === '_ASSIGNMENT_CLOSE_DATA' ) {
        (this.formGroup.get(CLOSE_ASSIGNMENT_CONFIG.DATE.formName) as UntypedFormGroup).addControl(
          date.code,
          new UntypedFormControl(PlcDateUtils.isoToDate(date.defaultValue)));
        this.formGroup.get(CLOSE_ASSIGNMENT_CONFIG.DATE.formName).get(date.code).setValue(PlcDateUtils.isoToDate(this.formGroup.value.dates._1OEFF));
      } else {
         (this.formGroup.get(CLOSE_ASSIGNMENT_CONFIG.DATE.formName) as UntypedFormGroup).addControl(
        date.code,
        new UntypedFormControl(PlcDateUtils.isoToDate(date.defaultValue))
      );
      }
      if (!date.editable) {
        this.formGroup.get(CLOSE_ASSIGNMENT_CONFIG.DATE.formName).get(date.code).disable();
      }
    });
  }

  public updateDraft(step: string, reload?: boolean, opDataType?: string): Observable<any> {
    return super.updateDraft(step, reload, 'ASS_LIEN_CLOS').pipe(
      tap(result => {
        this.initAssignmentDatesForm();
        this.assignmentDates = result.definitions.assignmentClosingDate as InputFieldDefinition[];
        this.updateClosingCauses(result);
      })
      );
  }

  protected getTransformedOperationData(): any {
    return  {
      closingCauses: this.formGroupValue.close.assignmentClosedCause,
      expireDate: this.formGroup.get(CLOSE_ASSIGNMENT_CONFIG.DATE.formName).get('_ASSIGNMENT_CLOSE_DATA').value
    };
  }


}

import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {PaymentFieldDefinition} from '../../../models/postsales-operations-response.model';


@Component({
  selector: 'lpc-payment',
  templateUrl: './lpc-payment.component.html',
  styleUrls: ['./lpc-payment.component.css']
})

export class LpcPaymentComponent implements OnInit {
  @Input() pagamenti: PaymentFieldDefinition[];
  @Input() parentForm: UntypedFormGroup;
  @Input() editable: boolean;
  /* @Input() disableBoolean: boolean; */
  @Output() changeValOfEmitter = new EventEmitter<string>();
  @Output() messageValOfEmitter = new EventEmitter<string>();

  @HostListener('changeValore')
  onChange() {
    this.changeValOfEmitter.emit();
  }

  @HostListener('errorMessage')
  ibanError() {
    if (this.messageValOfEmitter.error !== null) {
      this.messageValOfEmitter.emit();
    }
  }

  constructor(protected fb: UntypedFormBuilder)  {
  }

  ngOnInit() {
  }

}

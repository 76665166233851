import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lic-help-content',
  templateUrl: './lic-help-content.component.html',
  styleUrls: [ './lic-help-content.component.scss' ]
})
export class LicHelpContentComponent {

  public html: string;
  public title: string;

  constructor(
    protected activeModal: NgbActiveModal
  ) {
  }

  onClose() {
    this.activeModal.close();
  }

}

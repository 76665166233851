<ng-container [formGroup]="group">

  <rgi-rx-form-field>
    <label rgiRxLabel>
      <span>{{factor.description}}</span>
    </label>
    <rgi-rx-date-picker [openOnClick]="false">
      <input [rgiRxDateTimeInput]="picker1"
             rgiGpFocusElement
             [attr.data-qa]="'factor' + factor.code"
             formControlName="{{factor.code}}">
    </rgi-rx-date-picker>
    <rgi-rx-date-time #picker1></rgi-rx-date-time>
    <div *ngIf="factor.mandatory" class="rgi-ui-error-message" translate>_GP_._MSG_._MANDATORY_FIELD_</div>
  </rgi-rx-form-field>

</ng-container>

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EditablePanelContentComponent, PanelService, PanelSignal, PanelStatus } from '@rgi/digital-claims-common-angular';
import { ClaimOpeningDetailsService } from '../../claim-opening-details.service';
import { InjuryEntity } from '../dto/injury-entity';


@Component({
  selector: 'claims-injury-code',
  templateUrl: './injury-code.component.html',
  styleUrls: ['./injury-code.component.scss']
})
export class InjuryCodeComponent implements OnInit {

  @Input() injuryEntity: InjuryEntity;
  @Input() dataForm: UntypedFormGroup;
  @Input() enabled: boolean;

  constructor(public openingDetailsService: ClaimOpeningDetailsService) {
  }

  ngOnInit() {
    const ramoRCG = this.injuryEntity.ministerialBranch === '000013';
    if (!this.dataForm.controls.departmentInjury) {
      this.dataForm.addControl('departmentInjury',
      new UntypedFormControl({value: ''}));
      this.dataForm.addControl('typeOfLossInjury',
      new UntypedFormControl({value: ''}));
      this.dataForm.addControl('matterTypeInjury',
      new UntypedFormControl({value: ''}));
      this.dataForm.addControl('injuryNode',
      new UntypedFormControl({value: ''}));
      this.dataForm.addControl('noteInjury',
      new UntypedFormControl({value: ''}));
      this.dataForm.addControl('allegationDescrInjury',
      new UntypedFormControl({value: ''}));

      this.dataForm.controls.departmentInjury.setValue('');
      this.dataForm.controls.typeOfLossInjury.setValue('');
      this.dataForm.controls.matterTypeInjury.setValue('');
      this.dataForm.controls.injuryNode.setValue('');
      this.dataForm.controls.noteInjury.setValue('');
      this.dataForm.controls.allegationDescrInjury.setValue('');
    }
    this.openingDetailsService.populateInjuryComponent(this.injuryEntity)
        .subscribe((response: InjuryEntity) => {
          this.injuryEntity = response;
          this.dataForm.controls.injuryNode.setValue(response.injuryCodeSel);
    });

    // if (!this.enabled || !ramoRCG) {
    //   this.dataForm.get('departmentInjury').disable();
    //   this.dataForm.get('typeOfLossInjury').disable();
    //   this.dataForm.get('matterTypeInjury').disable();
    //   this.dataForm.get('injuryNode').disable();
    //   this.dataForm.get('noteInjury').disable();
    // } else {
    //   this.dataForm.get('departmentInjury').enable();
    //   this.dataForm.get('typeOfLossInjury').enable();
    //   this.dataForm.get('matterTypeInjury').enable();
    //   this.dataForm.get('injuryNode').enable();
    //   this.dataForm.get('noteInjury').enable();
    // }

  }


}

import {GenericEntity} from './generic-entity';

export class Warning extends GenericEntity {

  constructor(
    public id: string,
    public code: string,
    public description: string,
    public mandatory: boolean,
  ) {
    super(id, code, description);
  }
}

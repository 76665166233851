import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ac-auth-messages-detail',
  templateUrl: './auth-messages-detail.component.html',
  host: {
    class: 'rgi-authorizations-style'
  }
})
export class AuthMessagesDetailComponent implements OnInit {
  @Input() elementCurrentDetail: any;

  constructor() { }

  ngOnInit() {
  }
}

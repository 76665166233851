<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>Add Lien</span>
        </h3>
      </div>

      <div class="modal-body modal-datepicker">
        <form [formGroup]="lienControlForm">
          <div class="form-group row">
            <div class="col-sm-12 col-xs-12">
              <label for="description" translate>Description</label>
              <pnc-required *ngIf="bindingEntityMandatory" required="true"></pnc-required>
              <input #description class="form-control mic-input" formControlName="description" type="text">
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-6 col-xs-12">
              <pnc-datepicker [customFormGroup]="lienControlForm" customFormControlName="effectiveDate"
                              data-qa="effectiveDate" id="effectiveDate"
                              label="{{'Effective Date' | translate}}">
                <pnc-required *ngIf="effectiveDateMandatory" required="true"></pnc-required>
              </pnc-datepicker>
            </div>
            <div class="col-sm-6 col-xs-12">
              <pnc-datepicker [customFormGroup]="lienControlForm" customFormControlName="expirationDate"
                              data-qa="expirationDate" id="expirationDate"
                              label="{{'Expiration Date' | translate}}">
                <pnc-required *ngIf="expirationDateMandatory" required="true"></pnc-required>
              </pnc-datepicker>
            </div>
          </div>
        </form>

        <div *ngIf="(submitted && lienControlForm?.invalid && validationMessages && validationMessages?.length > 0)" id="error-container"
             style="margin-top: 2em">
          <pnc-form-message [messages]="validationMessages" data-qa="action-error-messages" detail="validationError"
                            objectType="complex" type="error"></pnc-form-message>
        </div>
      </div>

      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="close()" class="btn btn-warning" data-qa="btn-lien-modal-cancel" translate>CANCEL</button>
          </div>
          <div class="btn-group">
            <button (click)="onSubmit()" class="btn btn-warning" data-qa="btn-lien-modal-confirm" translate>CONFIRM
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

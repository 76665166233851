import {Address} from './address';

export class SubjectContact {
  email?: string;
  postalAddress?: Address;
  phoneOrEmail?: string;
  declarativeAppendix?: string;
  contactType?: string;
}


import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClaimSubjectsService {

  private baseApiUrl: string;
  private urlRootClaimSubjects = '/claims/printabledocument/addressee/list/';
  private urlRootClaimSubjectsClaim = '/claims/printabledocument/addressee/listclaim/';

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  getClaimSubjects(idPotentialClaim: number, idClaim: number) {
    let urlClaimSubjects;
    /*** RDC-756 Richiesta aggiunta attori non convolti in card soggetti sinistro ***/
    // if (idClaim != null) {
    //   urlClaimSubjects = this.urlRootClaimSubjectsClaim + idPotentialClaim + '/' + idClaim;
    // } else {
    //   urlClaimSubjects = this.urlRootClaimSubjects + idPotentialClaim;
    // }
    urlClaimSubjects = this.urlRootClaimSubjects + idPotentialClaim;
    return this.httpClient.get(this.baseApiUrl + urlClaimSubjects);
  }

}

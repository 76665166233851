import { ActiveRoute } from "@rgi/rx/router";
import { AbstractStateManager, StateStoreService } from "@rgi/rx/state";
import { Injectable } from "@angular/core";
import { PaymentsSearchboardService } from "../services/payments-searchboard.service";
import { of } from "rxjs";
import { Searchboard } from "../states/searchboard.state";
import { SearchForfait } from "../models/forfait/search-forfait.model";
import { SearchPayments } from "../models/payments/search-payments.model";


@Injectable()
export class PaymentsSearchboardStateManager extends AbstractStateManager<Searchboard> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected statelesOps: PaymentsSearchboardService
  ) {
    super(activeRoute, stateStoreService);

    const st = !!stateStoreService.get<Searchboard>(this.storeKey)
      ? stateStoreService.get<any>(this.activeRoute.key)
      : new Searchboard(this.storeKey);

    this.updateState$(this.statelesOps.initStateObject(of(st)));
  }

  searchPayments$(searchPaymentsInputDTO: SearchPayments) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.searchPayments(of(st), searchPaymentsInputDTO, this.activeRoute));
  }

  searchForfait$(searchElement: SearchForfait) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.searchForfait(of(st), searchElement, this.activeRoute));
  }
}
import { LicTranslationsKeys } from './lic-translations-keys';

/**
 * !! IMPORTANT !!
 * Add a new key on the bottom of the list and start declaring from the LpcTranslationsKeys
 *
 * @description
 * This is the list of all the translations keys used in the application for the life-issue-card library.
 */

/* eslint-disable max-len */
export const LIC_EU_ES: LicTranslationsKeys = {
  lic_PolicyCorrectlyIssued: '{{num}}. poliza jaulki da',
  lic_ProposalCorrectlyIssued: '{{num}}. proposamena jaulki da',
  lic_LifeProposalCorrectlyIssued: '{{num}}. bizitza-proposamena jaulki da',
  lic_LifePolicyCorrectlyIssued: '{{num}}. bizitza-poliza jaulki da',
  lic_DamagesPolicyCorrectlyIssued: '{{num}}. kalte-poliza jaulki da',
  lic_AuthorizatioProposalForwarded: '{{num}}. proposamenerako baimen-eskaera dagokion erabiltzaileari bidali zaio',
  lic_ProposalCorrectlySaved: '{{num}}. proposamena gorde da',
  lic_PreventiveCorrectlySaved: '{{num}}. kotizazioa gorde da',
  lic_benMorteNoAssic: 'Heriotzaren onuradun gisa hautatutako subjektua asegurudun gisa hautatuta dago jada; aukeratu beste subjektu bat',
  lic_benMorteNoRef: 'Heriotzaren onuradun gisa hautatutako subjektua harremanetarako hirugarren pertsona gisa hautatuta dago jada',
  lic_benefPresente: 'Heriotzaren onuradun gisa hautatutako subjektua hautatuta dago jada; aukeratu beste subjektu bat',
  lic_refTerzoNoAssic: 'Harremanetarako hirugarren pertsona gisa hautatutako subjektua asegurudun gisa hautatuta dago jada; aukeratu beste subjektu bat',
  lic_refTerzoNoContr: 'Harremanetarako hirugarren pertsona gisa hautatutako subjektua polizaren titular gisa hautatuta dago jada; aukeratu beste subjektu bat',
  lic_refTerzoPresente: 'Harremanetarako hirugarren pertsona gisa hautatutako subjektua heriotza-kasuan onuradun gisa hautatuta dago jada',
  lic_DelegateEqualToPolicyHolder: 'Ordezkaria eta hartzailea berdinak dira?',
  lic_LegalGuardianEqualToPolicyHolder: 'Legezko tutorea eta hartzailea berdinak dira?',
  lic_ThirdPayerEqualToPolicyHolder: 'Hirugarren ordaintzailea eta hartzailea berdinak dira?',
  lic_DelegateEqualToPolicyHolderWarning: 'Delegatuaren eginkizuna duen subjektua eta hartzailea berdinak dira',
  lic_LegalGuardianEqualToPolicyHolderWarning: 'Legezko tutorearen funtzioa duen subjektua eta hartzailea berdinak dira',
  lic_ThirdPayerEqualToPolicyHolderWarning: 'Hirugarren ordaintzailearen eginkizuna duen subjektua eta hartzailea berdinak dira',
  lic_MasterPolicyHolder: 'Polizaren titular nagusia',
  lic_SubjectXalreadyselectedfortherole: '{{subject}} subjektua eginkizunerako hautatuta dago jada',
  lic_InsuredAmount: 'Aseguratutako zenbatekoa',
  lic_InvestedPremium: 'Inbertsioaren prima',
  lic_FirstInstalment: 'Lehenengo epea',
  lic_InsuredNotEqualHolder: 'Asegurudunak eta titularrak desberdinak izan behar dute',
  lic_SaveEstimate: 'Gorde kotizazioa',
  lic_LifeQuote: 'Bizitza-kotizazioa',
  lic_PreventiveIssue: 'Kotizazioa jaulkitzea',
  lic_ProposalIssue: 'Jaulkipen-proposamena',
  lic_PolicyIssue: 'Jaulki poliza',
  lic_appendixLabel: 'Onuradunen eranskina',
  lic_summary_save_and_accept: 'gorde eta baieztatu',
  lic_summary_save_accept_pol_issue: 'gorde, baieztatu eta jaulki poliza',
  lic_summary_save_accept_prop_issue: 'gorde, baieztatu eta jaulki proposamena',
  lic_effectivebeneficiaryholder: 'titular onuradun efektiboa',
  lic_LifePolicy: 'Bizitzaren gaineko poliza',
  lic_DamagesPolicy: 'Kalteen gaineko poliza',
  lic_view: 'Ikusi',
  lic_premium_detail: 'Primaren xehetasunak',
  lic_guarantee_summary: 'Bermeen laburpenak',
  lic_commissions: 'Komisioak',
  lic_Parties: 'Alderdiak',
  lic_Party: 'Alderdia',
  lic_Employer: 'Enplegu-emailea',
  lic_SpecificPIPdata: 'PIP datu zehatzak',
  lic_prev_continue: 'Hurrengoa',
  lic_Transferdata: 'Transferitu datuak',
  lic_Selectatleastonecontribution: 'Hautatu ekarpen bat, gutxienez',
  lic_Globalpercentagemustbe100: 'Ehuneko globalak % 100 izan behar du',
  lic_Selectcontributionsmandatoryfields: 'Hautatu ekarpenen nahitaezko eremuak',
  lic_Contributiondata: 'Ekarpenaren datuak',
  lic_Globalamountmustbemajorthan0: 'Zenbateko globalak 0 baino handiagoa izan behar du',
  lic_Everycontributionselectedmustbemajorthan0: 'Hautatutako ekarpen guztiek 0 baino handiagoak izan behar dute',
  lic_error_mandatory_fields: 'Gorriz markatutako eremuak derrigorrezkoak dira edo balio okerrak dituzte',
  lic_error_iban: 'IBAN zenbakia ez da zuzena',
  lic_authorization_request: 'BAIMEN-ESKAERA',
  lic_selected_profiles_: 'Hautatutako profilak',
  lic_Disinvestmentsfunds: 'Desinbertsio-funtsak',
  lic_reg41_comunication: 'Jakinarazpenak bidaltzeko izena',
  lic_disinvestment_amount: 'Desinbertsio-zenbatekoa',
  lic_occurrenceNumber: 'Gertakaria',
  lic_TheoccurenceNumbermustbeavalue: 'Gertakari-kopurua {{min}} baino handiagoa izan behar da',
  lic_Disinvestmentrange: 'Sartu desinbertitu nahi duzun zenbatekoa, {{max}} baino handiagoa eta {{min}} edo hori baino txikiagoa dena',
  lic_Incompleteinvestmentforthefunds: 'Inbertsioa ez da osatu; osatu funtsetarako inbertsioa',
  lic_Incompleteinvestmentfortheprofiles: 'Inbertsioa ez da osatu; osatu profiletarako inbertsioa',
  lic_Settlementstoreinvest: 'Berrinbertitzeko likidazioa',
  lic_Policynumber: 'Poliza-zenbakia',
  lic_Type: 'Mota',
  lic_Beneficiary: 'Onuraduna',
  lic_State: 'Egoera',
  lic_Net: 'Garbia',
  lic_Nettoreinvest: 'Berrinbertitzeko garbia',
  lic_Reinvestmentmustbelargerthanquotation: 'Berrinbertsioak kotizazioa baino handiagoa izan behar du.',
  lic_Mustselectatleastonesettlementtoreinvest: 'Berrinbertitu nahi duzun likidazio bat hautatu behar duzu gutxienez.',
  lic_Nettoreinvestmustbeequalorlessthannet: 'Berrinbertitu beharreko zenbateko garbiak zenbateko garbia adinakoa edo txikiagoa izan behar du.',
  lic_Nosettlementstoreinvestexist: 'Ez dago berrinbertitzeko finkapenik.',
  lic_Missingvalueonnettoreinvest: 'Berrinbertitu nahi den zenbateko garbiaren balioa falta da.',
  lic_information: 'Informazioa:',
  lic_Percentage: 'Ehunekoa',
  lic_Durationin: 'Iraupena -',
  lic_years: 'urteak',
  lic_months: 'hilabeteak',
  lic_days: 'egunak',
  lic_Complementary: '(Osagarrizkoa)',
  lic_PleaseprovideapaymentmethodtotheCouponBeneficiary: 'Eman ordaintzeko metodo bat kupoiaren onuradunari',
  lic_Netavailable: 'Eskuragarri dagoen garbia',
  lic_Proposal_Saved: 'Proposamena zenbaki honekin gorde da',
  lic_add_employer: 'Gehitu langilea',
  lic_AvailableGuarantees: 'Eskuragarri dauden bermeak',
  lic_error: 'Errorea',
  lic_error_msg: 'Errorea poliza jaulkitzean',
  lic_auth_error_msg: 'Errorea eskaera prozesatzean',
  lic_common_risk: 'Arrisku arrunta',
  lic_no_common_risk: 'Ez dago asegurudunarentzat arriskuan dagoen kapitalaren kalkuluan sartzen den proposamenik edo polizarik',
  lic_assignment_holder: 'Esleipenaren titularra',
  lic_Sustainability: 'Jasangarritasuna',
  lic_ecoSustainabilityAmount: 'Ekojasangarritasunaren zenbatekoa',
  lic_ecoSustainabilityPerc: 'Ekojasangarritasuna %',
  lic_envSustainabilityAmount: 'Ingurumen-jasangarritasunaren zenbatekoa',
  lic_envSustainabilityPerc: 'Ingurumen-jasangarritasuna %',
  lic_paiAmount: 'PAI zenbatekoa',
  lic_paiPerc: 'PAI %',
  lic_socialSustainabilityAmount: 'Gizarte-jasangarritasunaren zenbatekoa',
  lic_socialSustainabilityPerc: 'Gizarte-jasangarritasuna %',
  lic_benefSevereDisability: 'Desgaitasun larria duen onuraduna',
  lic_SecondInsured: 'Bigarren aseguruduna',
  lic_Secondinsuredmustbeaphysicalperson: 'Bigarren asegurudunak pertsona fisikoa izan behar du',
  lic_Selectasecondinsured: 'Hautatu bigarren aseguruduna',
  lic_Aderente: 'Atxikia​',
  lic_NumeroProposta: 'Proposamen-zenbakia',
  lic_DescrizioneProdotto: 'Produktuaren deskribapena',
  lic_DescrizioneRischio: 'Arriskuaren deskribapena',
  lic_CapitaleAssicurato: 'Aseguratutako zenbatekoa',
  lic_SELEZIONA: '-- HAUTATU --',
  lic_PrNetto: 'Primaren % garbia',
  lic_ImpAcq: 'Erosketaren gaineko zerga',
  lic_profileInvestmentIncomplete: 'Inbertsioa osatu gabe, inbertsio-profilak osatuta',
  lic_fundsInvestmentIncomplete: 'Inbertsioa osatu gabe, inbertsio-funtsak osatuta',
  lic_Clauses: 'Klausulak',
  lic_quote_type: 'Kotizazio mota',
  lic_quote_type_anonymous: 'Ezezaguna',
  lic_Policyholder: 'Polizaren titularra',
  lic_quote_type_registered: 'Erregistratuta',
  lic_text_required: '*Testua beharrezkoa da',
  lic_text_required_error: 'Klausularen testua beharrezkoa da.',
  lic_close: 'itxi',
  lic_save: 'gorde',
  lic_NEXT: 'HURRENGOA',
  lic_policyData: 'Polizaren datuak',
  lic_pipData: 'PIP datuak',
  lic_parties: 'Alderdiak',
  lic_assets: 'Aktiboak',
  lic_quotation: 'Kotizazioa',
  lic_summary: 'Laburpena',
  lic_issue: 'Jaulkipena',
  lic_investments: 'Inbertsioak',
  lic_invest_value_range: 'Sartu {{min}} eta {{max}} arteko balioa',
  lic_disinvestment_range: 'Sartu {{min}} eta {{max}} arteko desinbertsio-balioa',
  lic_mandatory_threshold_perc: 'Funtsa: {{value}} - Sartu atalasearen ehunekoa',
  lic_mandatory_one_fund: 'Hautatu funts bat, gutxienez',
  lic_invest_factor_min_validation: '{{factor}} faktoreak {{amount}} baino txikiagoa izan behar du',
  lic_invest_factor_max_validation: '{{factor}} faktoreak {{amount}} baino handiagoaizan behar du',
  lic_invest_factor_eq_validation: '{{factor}} faktoreak {{amount}} izan behar du',
  lic_calculate_asset_volatility: 'Kalkulatu aktiboen hegakortasuna',
  lic_calculate_sustainability_asset: 'Kalkulatu aktiboen jasangarritasuna',
  lic_occurance_number_range: 'Gertaera-kopuruak {{min}} baino handiagoa izan behar du',
  lic_policy_holder: 'Polizaren titularra',
  lic_guarantee: 'Bermea',
  lic_co_applicant_not_same_holder: 'Eskatzailekidea ezin da polizaren titularra izan',
  lic_co_applicant_physical: 'Eskatzailekideak pertsona fisikoa izan behar du',
  lic_co_applicant_mandatory: 'Eskatzailekidea derrigorrezkoa da',
  lic_co_applicant: 'Eskatzailekidea',
  lic_insured_must_be_physical: 'Asegurudunak pertsona fisikoa izan behar du',
  lic_mandatory_product: 'Hautatu produktu bat',
  lic_mandatory_insured: 'Hautatu asegurudun bat',
  lic_mandatory_holder: 'Hautatu polizaren titular bat',
  lic_contribution_frequency: 'Kontribuzio-maiztasunak {{frequency}} berdina izan behar du',
  lic_mandatory_benef: 'Onuraduna {{cathegory}}: "Onuraduna derrigorrezkoa da"',
  lic_mandatory_appendix_benef: 'Onuraduna {{cathegory}}: Eranskinaren onuraduna nahitaezkoa da',
  lic_add_benef: 'Onuraduna {{cathegory}}: Gehitu erregistro zibilaren onuraduna',
  lic_add_benef_perc: 'Onuraduna {{cathegory}} {{nominative}}: Gehitu ehunekoa',
  lic_benef_perc_range: 'Onuraduna {{cathegory}} {{nominative}}: Ehunekoak 0.01 eta 100 bitartean egon behar du',
  lic_benef_total_perc: 'Onuraduna {{cathegory}}: "Ehuneko totalak % 100 izan behar du"',
  lic_guarantee_mandatory_data: 'Bermea {{unit}}: Bete derrigorrezko datu guztiak',
  lic_quotation_not_done: 'Kotizazioa ez da egin: hautatu "Kotizatu" kotizazioa eguneratzeko',
  lic_quotation_modified_value_quote: 'Datuak eguneratu dira: hautatu "Kotizatu" kotizazioa eguneratzeko',
  lic_quotation_benef_custom_msg: 'Onuraduna {{cathegory}} - {{msg}}',
  lic_product: 'Produktua',
  lic_managementNode: 'Kudeaketa-nodoa',
  lic_branch: 'Sukurtsala',
  lic_agreement: 'Akordioa',
  lic_effect_date: 'Eragin-data',
  lic_exp_date: 'Epemuga',
  lic_payment_frequency: 'Ordainketa-maiztasuna',
  lic_means_of_payment: 'Ordaintzeko moduak',
  lic_unit_type: 'Unitate mota',
  lic_risk_type: 'Arrisku mota',
  lic_substate: 'Azpiegoera',
  lic_benefit: 'Mozkina',
  lic_decreasing_frequency: 'Maiztasuna gero eta txikiagoa',
  lic_damages: 'Kalteak',
  lic_effective_holder: 'Onuradun titularrak',
  lic_insured_role: 'Aseguruduna',
  lic_third_contact_person: 'Harremanetarako hirugarren pertsona',
  lic_quotation_benef_owner_perc_validation: '{{benefName}} onuradunaren {{roleDescr}} {{name}} funtzioak ez du onartutako {{minPerc}} gutxieneko ehunekoa errespetatzen',
  lic_subscriber: 'Harpideduna',
  lic_yes: 'bai',
  lic_no: 'ez',
  lic_new_insured: 'Asegurudun berria',
  lic_simple_select: 'Hautatu',
  lic_first_instalment: 'Lehenengo ordainketa',
  lic_next_instalment_premium: 'Hurrengo ordainketaren prima',
  lic_payment_type: 'Ordainketa mota',
  lic_first_instalment_payment_type: 'Lehenengo ordainketaren ordainketa mota',
  lic_annual_premium: 'Urteko prima',
  lic_quote_button: 'Kotizatu',
  lic_quote_save: 'Kotizatu eta gorde',
  lic_purchasing_commission: 'Erosketa-komisioa',
  lic_beneficiaries_label: 'Onuradunak',
  lic_general_data_label: 'Datu orokorrak',
  lic_contractual_option_not_allowed: 'Baimendu gabeko kontratu-aukerak',
  lic_investment_value_label: 'Inbertsio-balioa',
  lic_investment_profile: 'Inbertsio-profila',
  lic_investment_funds: 'Inbertsio-funtsak',
  lic_calculate_finantial_parameters: 'Kalkulatu finantza-parametroak',
  lic_confirm_button: 'Baieztatu',
  lic_cancel_button: 'Ezeztatu',
  lic_save_button: 'Gorde',
  lic_continue_button: 'Jarraitu',
  lic_next_button: 'Hurrengoa',
  lic_required: 'derrigorrezkoa',
  lic_node: 'Nodoa',
  lic_company: 'Konpainia',
  lic_product_type: 'Produktu mota',
  lic_taxes_label: 'Zergak',
  lic_fees_label: 'Tarifak',
  lic_frequency_interests: 'Interesen maiztasuna',
  lic_frequency: 'Maiztasuna',
  lic_accessories: 'Osagarriak',
  lic_taxable: 'Zergapetugarria',
  lic_quotation_detail_modal: 'Kotizazioaren xehetasunak',
  lic_guarantees_overview_modal: 'Bermeen laburpenak',
  lic_gross_label: 'Gordina',
  lic_int_fract_label: 'Aurrezenbaki Zat.',
  lic_next_instalment_label: 'Hurrengo ordainketa',
  lic_pure_premium_label: 'Prima purua',
  lic_extra_premium_label: 'Prima estra',
  lic_loadings_label: 'Errekarguak',
  lic_total_label: 'Guztira',
  lic_beneficiary_perc_is_over: '{{benefCat}} {{benefRole}} eginkizunari lotutako egingizunen ehunekoen batura 100 baino handiagoa da',
  lic_questionSTR_invalid: 'Hurrengo galderaren erantzuna: {{question}} ez da zuzena',
  lic_purchase_commissions: 'Erosketa-komisioak',
  lic_sales_commissions: 'Salmenta-komisioak',
  lic_IBAN_formally_incorrect: 'IBAN zenbakiaren formatua ez da baliozkoa',
  lic_holder_physical_person_req: 'Polizaren titularrak pertsona fisikoa izan behar du',
  lic_holder_legal_person_req: 'Polizaren titularrak pertsona juridikoa izan behar du',
  lic_available_covers: 'Eskuragarri dauden estaldurak',
  lic_covers_overview: 'Estalduren laburpena',
  lic_covers: 'Estaldurak',
  lic_cover: 'Estaldura',
  lic_config_profile_warning: 'ABISUA: Konfigurazio-profilak aldatu dira.',
  lic_select: 'Hautatu',
  lic_Irrevocable_beneficiary: 'Onuradun ezeztaezina',
  lic_Base: '(Oinarrizkoa)',
  lic_Accessory: '(Osagarria)',
  lic_investment_value: 'Inbertsio-balioa',
  lic_volatility: 'Hegakortasuna',
  lic_name: 'Izena',
  lic_to_be_invested: 'Inbertitzeko',
  lic_info_investment_completed: 'Informazioa: inbertsioa egin da',
  lic_fund: 'Funtsa',
  lic_max_percentage: 'onartutako gehienezko ehunekoa',
  lic_min_percentage: 'onartutako gutxienezko ehunekoa',
  lic_selected_funds: 'Hautatutako funtsak',
  lic_allocation_exceeds: 'Inbertsioaren esleipena eskuragarri dagoen zenbatekoa baino handiagoa da',
  lic_percentage_threshold: 'Atalasearen %',
  lic_fund_distribution_identical: 'Funtsen banaketa hasierako ordainketaren berdina da',
  lic_fund_not_available: 'ez dago aukerarako funtsik',
  lic_details: 'Xehetasunak',
  lic_starting_fund: 'Hasierako funtsa',
  lic_fund_category: 'Profila/ Funtsa',
  lic_equivalent: 'Baliokidea datan',
  lic_policy_address: 'Polizaren helbidea',
  lic_policy_master_details: 'Polizaren xehetasun nagusiak',
  lic_administrative_data: 'Administrazio-datuak',
  lic_ordinary_mail: 'Posta arrunta',
  lic_threshold: 'Atalasea',
  lic_payment_method: 'Hurrengo ordainketak ordaintzeko modua',
  lic_additional_payment: 'Ordainketa osagarria',
  lic_contractual_options: 'Kontratuaren aukerak',
  lic_elements: 'Elementuak',
  lic_position_number: 'Posizio-zenbakia',
  lic_questionnaires_section: 'Galdetegiak',
  lic_fees: 'Kargu finkoak',
  lic__1TERM: 'Lehenengo epea',
  lic__EFFOP: 'Eragin-data',
  lic__OPZFR: 'Maiztasuna',
  lic__OPZPM: 'Ordainketa-modua',
  lic_expirationDate: 'Iraungitze-data',
  lic__OPBEN: 'Onuraduna',
  lic__MINTS: 'Gutxieneko atalasea',
  lic__MAXTS: 'Gehieneko atalasea',
  lic__NUMOC: 'Gertaera-zenbakia',
  lic__AMTOP: 'Errendimendu-zenbatekoa',
  lic__MALMT: 'Gutxieneko zenbatekoa',
  lic_amount: 'Zenbatekoa',
  lic_currency: 'Moneta',
  lic__CIBAN: 'IBAN',
  lic_fixedFees: 'Tarifa finkoak',
  lic__PCOUP: 'Aldizkako deskontuaren ehunekoa',
  lic_holder: 'Titularra',
  lic_ACTIVE_STATE: 'AKTIBO',
  lic_MANDATORY_STATE: 'DERRIGORREZKOA',
  lic_DEACTIVATE_STATE: 'DESAKTIBATU',
  lic_premium_label: 'Prima',
  lic_holders_title: 'Titularrak',
  lic_beneficiary_qualification: ' {{benefCat}} {{benefRole}} ',
  lic_invalid_total_position_percentage: '"{{groupType}}" talderako ehuneko totalak {{cat}} onuradunean % 100 izan behar du',
  lic_beneficiary_position_mandatory: '{{benefCat}} {{benefName}} onuradunak \"posizio-zenbaki\" bat hautatu behar du',
  lic_role_is_mandatory: '{{rol}} derrigorrezkoa da',
  lic_effective_holder_duplicated: 'Titular efektiboa bikoiztuta dago',
  lic_beneficiary_perc_req_range: '{{benefCat}} {{benefRole}} {{benefName}}: Ehunekoak 0.01 eta 100 bitartean egon behar du',
  lic_beneficiary_perc_req: ' {{benefCat}} {{benefRole}} {{benefName}}: Sartu ehunekoa',
  lic_beneficiary_from_party_req: ' {{benefCat}} {{benefRole}}: Sartu alderdi-biltegiaren onuradun bat',
  lic_effective_holder_perc: '{{benefName}} onuradunaren {{linkedName}} titular efektiboaren ehunekoak ez du % {{linkedPerc}} gutxieneko ehunekoa betetzen',
  lic_effective_holder_perc_req: '{{linkedName}} {{benefName}} onuradunaren titular efektiboa: Sartu ehunekoa',
  lic_or_junction: 'o',
  lic_backdating_not_allowed: 'Atzerapenak ez dira onartzen',
};
export default LIC_EU_ES ;

<div class="rgi-ui-datatable-toolbar rgi-rx-datatable-toolbar" [style]="stickyHeader ? getStickyRootStyle('top', 3) : undefined" #toolBar>
  <div class="rgi-ui-datatable-action-buttons">
    <ng-container *ngIf="schema.headerActions">
      <ng-container *ngFor="let headerAction of schema.headerActions">
        <button [rgi-rx-qa]="id + '-header-action-' + headerAction.name"
                qa-type="datatable-header-action"
                [qa-value]="headerAction.name"
                class="rgi-ui-btn rgi-rx-table-btn"
                [disabled]="!!headerAction.disabled"
                (click)="onHeaderActionClick($event, headerAction)"
                *ngIf="shouldShowHeaderAction(headerAction)"
                [ngClass]="headerAction.styleClass"
                [rgiRxTooltip]="headerAction.description | translate"
                [disableTooltip]="!headerAction.description"
                [attr.aria-label]="(headerAction.ariaLabel || headerAction.label) | translate"
        >
          {{headerAction.label | translate}}
        </button>
      </ng-container>
    </ng-container>
  </div>
  <div class="rgi-ui-table-search">
    <span class="rgi-ui-icon"></span>
    <input rgiRxInput [disabled]="disableSearch" type="search" placeholder="{{'RGI_RX.DATATABLE.SEARCH_LABEL' | translate}}"
      [(ngModel)]="filter" (ngModelChange)="onFilterChange($event)">
  </div>
</div>

<table rgi-rx-table  [multiTemplateDataRows]="!!expansionRow" [class.rgi-ui-table-expandable]="!!expansionRow">

  <ng-container [rgiRxColumnDef]="'rgiRxDataTableSort'" [sticky]="stickySort">
      <td rgi-rx-header-cell *rgiRxHeaderCellDef>
        #
      </td>
      <td rgi-rx-cell *rgiRxCellDef="let row; let i = index; let di = dataIndex;"
        [rgi-rx-qa]="id + '-sort-cell-' + ((i > -1 ? i :di)+1)" qa-type="datatable-sort-cell">
        <input rgiRxDatatableInputSort [disabled]="disableSort" type="number" class="rgi-ui-form-control" min="1"
          [max]="paginator.elementCount + ''" (onChanged)="onSortChange($event, row)"
          [value]="calculatePageIndex(i > -1 ? i : di)"
          [attr.aria-label]="'RGI_RX.DATATABLE.SORT_ROW_LABEL' | translate : {index: ((i > -1 ? i :di)+1)}"
        />
      </td>
  </ng-container>

  <ng-container [rgiRxColumnDef]="'rgiRxDatatableSelect'" [sticky]="stickySelection">
      <td rgi-rx-header-cell *rgiRxHeaderCellDef>
        <input #pageChecker
               type="checkbox"
               rgiRxInput
               [disabled]="disableSelection"
               (change)="onSelectAll($event)"
               [attr.aria-label]="'RGI_RX.DATATABLE.SELECT_ALL_ROW_LABEL' | translate"/>
      </td>
      <td rgi-rx-cell *rgiRxCellDef="let row; let i = index; let di = dataIndex;"
        [rgi-rx-qa]="id + '-selection-cell-' + ((i > -1 ? i :di)+1)" qa-type="datatable-selection-cell">
        <input type="checkbox" rgiRxInput [disabled]="disableSelection" [checked]="isSelected(row)"
               [attr.aria-label]="'RGI_RX.DATATABLE.SELECT_ROW_LABEL' | translate : {index: ((i > -1 ? i :di)+1)}"
          (change)="onRowCheckBoxChange($event, row)" />
      </td>
  </ng-container>

  <ng-container *ngFor="let rowSchema of schema.rows; let i = index;" [rgiRxColumnDef]="rowSchema.name" [sticky]="isSticky(rowSchema)" [stickyEnd]="isStickyEnd(rowSchema)">
    <td rgi-rx-header-cell [rgi-rx-qa]="id+'-header-cell-'+ (i+1)" qa-value="{{rowSchema.title|translate}}"
      qa-type="datatable-header-cell" [rgiRxTooltip]="rowSchema.description | translate"
      [disableTooltip]="!rowSchema.description" *rgiRxHeaderCellDef
    >
      {{rowSchema.title | translate}}
        <rgi-rx-table-header-sort *ngIf="canBeSorted(rowSchema)" [class.rgi-rx-datatable-hidden]="!hasSortHeader()"
          [disabled]="disableSortHeader"
          [comparator]="getSortComparator(rowSchema)" [rowSchema]="rowSchema"
          (sorted)="sortedHeader.emit($event)"
          [attr.aria-label]="'RGI_RX.DATATABLE.SORT_HEADER_LABEL' | translate : {name: rowSchema.title}"
        >
        </rgi-rx-table-header-sort>
    </td>
    <td rgi-rx-cell [ngClass]="getStylesFromSchema(rowSchema, row)"
      [rgi-rx-qa]="id + '-cell-' + rowSchema.name + '-' + ((i > -1 ? i :di)+1)" [qa-value]="transform(row, rowSchema)"
      qa-type="datatable-cell" *rgiRxCellDef="let row; let i=index; let di = dataIndex;">
      <ng-container [ngSwitch]="hasTableCell(rowSchema)">
        <ng-container *ngSwitchCase="false">
          <ng-container class="rgi-ui-datatable-cell-text" *ngIf="isTextRow(rowSchema)">{{transform(row, rowSchema)}}
          </ng-container>
          <ng-container class="rgi-ui-table-cell-actions" *ngIf="isEventRow(rowSchema)">
            <button [ngClass]="getStylesFromSchema($any(action), row)"
              [rgi-rx-qa]="id + '-action-'+ action.name + '-' + ((i > -1 ? i :di)+1)" [qa-value]="action.name"
              qa-type="datatable-action" class="rgi-ui-btn"
              [class.rgi-rx-datatable-hidden]="evalAssertionOnRow(action.hidden, row, $any(action))"
              [disabled]="evalAssertionOnRow(action.disabled, row, $any(action))" *ngFor="let action of rowSchema.actions"
              (click)="onActionClick(row, action.name, $event)" [rgiRxTooltip]="action.description | translate"
              [disableTooltip]="!action.description" translate
              [attr.aria-label]="(action.ariaLabel || action.label) | translate"
            >{{action.label}}</button>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="true">
          <ng-container
            *ngTemplateOutlet="getTableCell(rowSchema); context: getDataTableCellContext(row,((i > -1 ? i :di)), rowSchema)"></ng-container>
        </ng-container>
      </ng-container>

    </td>
  </ng-container>


  <ng-container [rgiRxColumnDef]="'rgiRxDataTableExpanded'" *ngIf="!!expansionRow" [sticky]="stickyExpansion">
    <td rgi-rx-header-cell *rgiRxHeaderCellDef></td>
    <td rgi-rx-cell *rgiRxCellDef="let row; let i = dataIndex; " class="rgi-ui-datatable-expansion-cell">
      <i role="button" class="rgi-ui-expansion-cell-open" *ngIf="!expansionModel.isExpanded(row)"
         [attr.aria-controls]="'rgi-rx-table-expansion-cell-'+i"
         [attr.aria-label]="'RGI_RX.DATATABLE.EXPAND_ROW_LABEL' | translate : {index: (i+1)}"
         (click)="expansionModel.expand(row)"
      ></i>
      <i role="button" class="rgi-ui-expansion-cell-close" *ngIf="expansionModel.isExpanded(row)"
         [attr.aria-controls]="'rgi-rx-table-expansion-cell-'+i"
         [attr.aria-label]="'RGI_RX.DATATABLE.COLLAPSE_ROW_LABEL' | translate : {index: (i+1)}"
         (click)="expansionModel.contract(row)"></i>
    </td>
  </ng-container>
  <ng-container [rgiRxColumnDef]="'rgiRxDataTableExpansionContent'" *ngIf="!!expansionRow">
    <td rgi-rx-header-cell *rgiRxHeaderCellDef></td>
    <td rgi-rx-cell [id]="'rgi-rx-table-expansion-cell-'+i" [attr.aria-expanded]="expansionModel.isExpanded(row) ? 'true': 'false'" *rgiRxCellDef="let row; let i = dataIndex;" [attr.colspan]="getColumnLength()"
      class="rgi-ui-datatable-cell-expansion">
      <div class="rgi-ui-cell-expansion-content" *ngIf="expansionModel.isExpanded(row)">
        <ng-container *ngTemplateOutlet="expansionRow; context: {$implicit : row }"></ng-container>
      </div>
    </td>
  </ng-container>

  <tr rgi-rx-header-row *rgiRxHeaderRowDef="headers; sticky: stickyHeader" #headerRow></tr>
  <tr rgi-rx-row *rgiRxRowDef="let row; columns: columns; let i=index; let di = dataIndex;"></tr>
  <ng-container *ngIf="!!expansionRow">
    <tr rgi-rx-row *rgiRxRowDef="let row; columns: ['rgiRxDataTableExpansionContent'];"
      [ngClass]="{'rgi-ui-active': expansionModel.isExpanded(row)}" class="rgi-ui-datatable-expansion-row"></tr>
  </ng-container>
</table>

<div>
  <ng-container *ngIf="paginator && paginator.elementCount === 0">
    <div class="rgi-ui-table-empty" translate>RGI_RX.DATATABLE.EMPTY</div>
  </ng-container>
</div>

<rgi-rx-paginator
  class="rgi-ui-datatable-paginator"
  [ngClass]="{'rgi-rx-datatable-paginator-hidden' : !showPaginator}"
  [pageOptions]="pageOption"
  [pageSize]="pageOption[0]"
  [style]="stickyPaginator ? getStickyRootStyle('bottom') : undefined"
  [disabled]="disablePaginator">
</rgi-rx-paginator>

import { EMPTY_STR, StepperConfiguration } from '../../../models/consts/lpc-consts';

export interface AdvancePaymentStepperConfig extends StepperConfiguration {
  /**
   * @stepField id `'disinvestmentProfiles'`
   * @stepField label `'Disinvestment profiles'`
   * @stepField fieldId `'disinvestmentProfiles'`
   * @stepField formName `'disinvestmentProfiles'`
   */
  PROFILE_DISININVESTMENT: {
    id: string,
    label: string,
    fieldId: string,
    formName: string
  };
  /**
   * @stepField id `'disinvestmentFunds'`
   * @stepField label `'Dislpc_investment_funds'`
   * @stepField fieldId `'disinvestmentFunds'`
   * @stepField formName `'disinvestmentFunds'`
   */
  FUND_DISININVESTMENT: {
    id: string,
    label: string,
    fieldId: string,
    formName: string
  };
  BENFICIARIES: {
    id: string,
    label: string,
    fieldId: string,
    formName: string
  };
}

export const ADVANCE_PAYMENT: AdvancePaymentStepperConfig = {
  DATE: {
    id: 'dates',
    label: 'lpc_date_variation_step',
    fieldId: 'dates',
    formName: 'dates'
  },
  POLICY_FACTORS: {
    id: 'factors',
    label: 'lpc_policy_factors',
    fieldId: 'factors',
    formName: 'factors'
  },
  QUESTS_UNIT: {
    id: 'questsFin',
    label: 'lpc_financial_questionnaire_step',
    fieldId: 'questsFin',
    formName: 'questsFin'
  },
  ASSET_FACTORS: {
    id: 'assetFactors',
    label: 'lpc_asset_factors',
    fieldId: 'assetFactors',
    formName: 'assetFactors'
  },
  QUOTATION: {
    id: 'quotation',
    label: 'lpc_quotation_step',
    fieldId: 'quotation',
    formName: 'quotation'
  },
  PROFILE_DISININVESTMENT: {
    id: 'disinvestmentProfiles',
    label: 'lpc_disinvestment_profile_step',
    fieldId: 'disinvestmentProfiles',
    formName: 'disinvestmentProfiles'
  },
  FUND_DISININVESTMENT: {
    id: 'disinvestmentFunds',
    label: 'lpc_disinvestment_funds',
    fieldId: 'disinvestmentFunds',
    formName: 'disinvestmentFunds'
  },
  BENFICIARIES: {
    id: 'beneficiaries',
    label: 'lpc_life_detail_beneficiaries',
    fieldId: 'beneficiaries',
    formName: 'beneficiaries'
  },
  WARNING: {
    id: 'warnings',
    label: 'lpc_warnings',
    fieldId: 'warnings',
    formName: EMPTY_STR
  },
  DOCUMENT: {
    id: 'documents',
    label: 'lpc_confirm_variation_step',
    fieldId: 'documents',
    formName: EMPTY_STR
  },
};


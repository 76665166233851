import {InsuranceStatus} from '../models/domain-models/insurance-status';
import {GenericEntity} from '../models/domain-models/generic-entity';
import {ClaimDetail} from '../models/domain-models/claim-detail';

export class ClaimsData {
  constructor(
    public isNewClaim: boolean,
    public insuranceStatus: InsuranceStatus,
    public damageTypeList: GenericEntity[],
    public yearList: Array<string>,
    public selectedClaimDetails: ClaimDetail
  ) {
  }
}

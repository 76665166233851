import {NgModule} from '@angular/core';
import { API_PATHS } from '@rgi/ng-passpropro-core';
import {ProductResourcesModule} from "./resources/product-resources.module";
import {PRODUCT_API_PATHS} from "./product-api";



@NgModule({
  declarations: [],
  imports: [ProductResourcesModule],
  exports: [],
  providers: [
    {provide: API_PATHS, useValue: PRODUCT_API_PATHS, multi: true}
  ]
})
export class NgPassproproProductModule { }

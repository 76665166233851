import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {QuestionnaireService} from '../questionnaires/questionnaire.service';

@Injectable()
export class PartiesInterceptor implements HttpInterceptor {

  constructor(
    private questionnaireService: QuestionnaireService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler)
    : Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (request.url.includes('/motor/contract/') && request.url.endsWith('/parties')
          && request.method === 'PUT' && event instanceof HttpResponse
          && event.status === 200) {
          this.questionnaireService.sendRefreshQuestionnaireSignal();
        }
        return event;
      }));
  }
}

import { ActiveRoute } from "@rgi/rx/router";
import { AbstractStateManager, StateStoreService } from "@rgi/rx/state";
import {  ResponseFromSearchState } from "../dialogue-handler-configuration/dialogue-handler.model";
import { of } from "rxjs";
import { DialogueHandlerIntegrationService } from "../dialogue-handler-configuration/dialogue-handler-integration.service";
import { DialogueHandlerListService } from "./dialogue-handler-list.service";
import { Injectable } from "@angular/core";
import {  NewDialogueViewConfiguration } from "../new-dialogue/new-dialogue.component";
import { SummaryViewConfiguration } from "../dialogue-summary/dialogue-summary.component";

@Injectable()
export class DialogueHandlerListStateManager extends AbstractStateManager<ResponseFromSearchState> {

    constructor(activeRoute: ActiveRoute,
        stateStoreService: StateStoreService,
        protected statelesOps: DialogueHandlerListService,
        private integrationService: DialogueHandlerIntegrationService)
        {
        super(activeRoute, stateStoreService);
        
        const st = !!stateStoreService.get<ResponseFromSearchState>(this.storeKey)
            ? stateStoreService.get<ResponseFromSearchState>(this.activeRoute.key)
            : new ResponseFromSearchState(this.storeKey);          
            // console.log('Router data handler list ===> ', this.activeRoute.getRouteData<any>());
            if (this.activeRoute.getRouteData<any>()?.filterList) {
            this.updateState$(this.statelesOps.initStateList$(of(st),this.activeRoute.getRouteData<any>().filterList));            
        }
          else{          
          this.updateState$(this.statelesOps.initStateListFromClaim$(of(st)));            
      }
      //  else {
      //       this.updateState$(of(st));
      //   }
        
      }

      goToPartialNewDialogue$() {
        const st = this.getCurrentState();
        const viewCond = NewDialogueViewConfiguration.PARTIAL_VIEW_CONF;
        this.updateState$(this.statelesOps.getNewDialogueData(of(st), this.activeRoute, viewCond));
      }

      goToCompleteNewDialogue$() {
        const st = this.getCurrentState();
        const viewCond = NewDialogueViewConfiguration.COMPLETE_VIEW_CONF;
        this.updateState$(this.statelesOps.getNewDialogueData(of(st), this.activeRoute, viewCond));
      }

      goToDialogueHandlerSummary$(data,matrixIndex?: number) {
        const st = this.getCurrentState();
        const viewCond = SummaryViewConfiguration.SUMMARY_VIEW_CONF;
        this.updateState$(this.statelesOps.goToSummaryData(of(st), this.activeRoute, viewCond,data,matrixIndex ));
      }

      goBack(){
        this.closeCardSession();
        }
      
        closeCardSession() {
          this.statelesOps.closeSession$(this.activeRoute.id, this.getCurrentState());
        }
        
}

<div *ngIf="active" class="reversionary m-0 factor-box" [formGroup]="reversionaryForm">
  <div *ngIf="!!reversionary" class="col-lg-6 reversionary-box" style="padding-bottom: 1%;padding-left: 1%;">
      <label class="label-core" translate>lpc_reversionario</label>
      <lpc-required required="true"></lpc-required>
      <div *ngIf="getReversionaryValue(); else empty">
          <lpc-beneficiary-control
              required
              formControlName="reversionary"
              (delete)="deleteRevers($event)"
              [items]="reversionary[0]?.values">
          </lpc-beneficiary-control>
      </div>
      <ng-template #empty>
          <input
              required
              (click)="openAnagSubjectModal()"
              [value]="'lpc_Seleziona' | translate"
              class="col-lg-12 drop-container dashedSelect factor-box"
              data-qa="dashed-select-thirdParty" readonly
          >
      </ng-template>
    </div>
</div>
<div class="m-0" *ngIf="!active">
<span translate>lpc_reversionary_subject</span>:<span> {{reversionaryForm.get('reversionary').value.value.name}} </span>
  <br>
  <span translate>lpc_percentage_reversibility</span>:<span> {{reversionaryForm.get('reversionary').value.value.percentage  / 100 | rgiCountryLayerNumberFormat: currencyService.locale : percentFormatterOptions}}</span>
</div>
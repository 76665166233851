export class PolicySubstitute {

  constructor(public operationTimestamp: string,
              public idSubstitutionReason: string,
              public idProduct: string,
              public clusterCode: string,
              public assetCode: string) {
  }

  public static createEmptyObject(): PolicySubstitute {
    return new PolicySubstitute(null, null, null, null, null);
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {Commission, Units} from '../../../group-policy-models/group-policy-issue-guarantees';
import {BaseEntityMultiDescriptions, Factor} from '../../../group-policy-models/group-policy-issue-policy-data';
import {GPVariableService} from '../../../group-policy-services/gpvariable.service';
import {RgiRxTranslationService} from '@rgi/rx/i18n';

@Component({
  selector: 'rgi-gp-units-detail',
  templateUrl: './g-p-units-detail.component.html'
})
export class GPUnitsDetailComponent implements OnInit {

  @Input() units: Units[];
  @Input() showTitle = true;
  constructor(
    protected variableService: GPVariableService,
    protected translationService: RgiRxTranslationService
  ) {
  }

  ngOnInit() {
  }

  getUnitDetails(listVariable: Factor[], commissions: Commission) {
    let details: BaseEntityMultiDescriptions[] = [];
    if (!!listVariable) {
      details = listVariable.map(variable => {
        return {
          code: variable.description,
          description: this.variableService.getVariableDisplayValue(variable),
          multiDescriptions: this.variableService.getVariableSelectableValues(variable),
        };
      });
    }
    if (!!commissions && !!commissions.percFlex && !!commissions.percFlex.value) {
      let label;
      this.translationService.translate('_GP_._TITLE_._COMMISSION_DISCOUNT_').subscribe(translated => label = translated);
      details = details.concat([new BaseEntityMultiDescriptions(label, commissions.percFlex.value.toString() + ' %', null)]);
    }
    return details;
  }
}

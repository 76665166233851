export enum QuoteStatusLabel {
    VALID = 'lpc_valid_quote',
    CANCELLED = 'lpc_invalid_quote',
    NUMBER = 'lpc_quote_number',
}

export enum PolicyStatusCode {
    VALID = '11',
    CANCELLED = '13',
    VOID = '300000', // stornata
}

export enum QuoteStatusCode {
    VALID = '301000',
    CANCELLED = '301001',
}

<div class="msg-modal-content">
    <rgi-rx-panel>
        <rgi-rx-panel-header [closeable]="true" (onClose)="actionCancel()">
            <span translate>_RGIPNC_._TITLE_._SYSTEM_MESSAGE_</span>
        </rgi-rx-panel-header>
        <rgi-rx-panel-footer>
            <ng-container *ngFor="let btn of data.buttons">
                <button rgi-rx-button *ngIf="btn.visible"
                        [color]="btn.color"
                        (click)="onAction(btn.action)"
                        [disabled]="btn.disabled"
                        [attr.data-qa]="btn.action">{{btn.label | translate}}
                </button>
            </ng-container>
        </rgi-rx-panel-footer>
        <p><span>{{data.msg}}</span></p>
    </rgi-rx-panel>
</div>

import {ActiveRoute} from '@rgi/rx/router';
import {RgiRxLocalStateManager, State, StateStoreService} from '@rgi/rx/state';
import {RgiRxEventService} from '@rgi/rx';
import {of} from 'rxjs';
import {AnagStatelessOpKeyDataService} from './anag-stateless-op-key-data.service';
import {Agency} from '../../anag-model/anag-api/sales-network-api-agency';
import {AnagCountry} from '../../anag-model/anag-domain/anag-country';
import {AnagEntityIta} from '../../anag-model/anag-api/anag-subject-api';
import {
  KeyDataUpdateEvent,
  KeyDataUpdateEventInstance,
  NewPartyEvent,
  NewPartyEventInstance
} from '../../anag-events/anag-event';
import {AnagRouteData} from '../../anag-model/anag-domain/anag-route-data';
import {AnagApiParty, AnagPartyKey, AnagPartyKeyService} from '../../anag-model/anag-domain/anag-api-party';
import {PERSON_TYPE_ENUM} from '../../anag-constants/enums-constants';
import {AnagConfiguration} from '../../anag-model/anag-domain/anag-configuration';
import {AnagApiPartyTypeConfigRequest} from '../../anag-model/anag-api/anag-api-configuration-filter';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {AnagStatelessService} from '../../anag-resources/anag-stateless.service';
import {AnagFlowData} from '../../anag-model/anag-domain/anag-flow-data';


export class AnagStateKeyData extends State {
  party: AnagApiParty;
  partyConfiguration: AnagConfiguration;
  nodes: Agency[] = [];
  professionDetails: Array<AnagEntityIta>;
  partyKeyConf: AnagPartyKey;
  inputPartyMerged = false;
  fromSearchFlow: boolean;
  fromResultFlow: boolean;
  errors: Array<string> = [];
  flowData: AnagFlowData;
  redirectToMainFlow = false;
  isUmaPartyHomonymyEnable: boolean;
  isHomonymySearch ? = false;
  isUmaPartyCFDuplicate: boolean;
  isUmaPartyGroupCompanies: boolean;
  partyType: string;
  searchNodeCode: string;
  partyKey: AnagPartyKey;
  clientNumber: string;
  birthName: string;
  subsystemCode: string;
  subsystemIdentifier: string;
  searchNodeId: string;
  fiscalCodeDynamicObj: string;
  isBackFromHomonymy: boolean;
  anagNodeFitSx: string;
}

export class AnagStateManagerKeyData extends RgiRxLocalStateManager<AnagStateKeyData> {

  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected statelessOps: AnagStatelessOpKeyDataService,
    protected eventService: RgiRxEventService,
    protected anagStorage: AnagStorageService,
    protected anagStatelessService: AnagStatelessService
  ) {
    super(activeRoute, stateStoreService);
  }

  initKeyData(party: AnagApiParty) {
    const newStateKeyData = new AnagStateKeyData(this.activeRoute.key);
    const routeData = this.activeRoute.getRouteData<AnagRouteData>();
    if (routeData) {
      newStateKeyData.flowData = routeData.flowData;
      if (routeData.isModalFlow) {
        newStateKeyData.party = routeData.party ? routeData.party : new AnagApiParty();
        newStateKeyData.fromSearchFlow = routeData.lastAction === 'searchFlow';
        newStateKeyData.fromResultFlow = routeData.lastAction === 'resultFlow';
      }
      newStateKeyData.isUmaPartyHomonymyEnable = routeData.isUmaPartyHomonymyEnable;
      newStateKeyData.isBackFromHomonymy = routeData.isBackFromHomonymy;
    }
    if (party) {
      newStateKeyData.party = this.anagStatelessService.deepClone(party);
    }
    newStateKeyData.redirectToMainFlow = routeData ? routeData.redirectToMainFlow : null;
    this.updateState$(this.statelessOps.initKeyData$(of(newStateKeyData)));
  }

  retrieveProfessionDetails(professionCode: string) {
    const currState = this.getCurrentState();
    this.updateState$(this.statelessOps.retrieveProfessionDetails$(of(currState), professionCode));
  }

  getEnumsByCode(code: string) {
    return this.anagStorage.getEnumsByCode(code);
  }

  actionUpdateConfig(formRawData) {
    const partyTypeReq: AnagApiPartyTypeConfigRequest = this.adaptFormToFilterPartyType(formRawData);
    if (partyTypeReq !== null) {
      this.updateState$(this.statelessOps.actionUpdateConfig$(of(this.getCurrentState()), formRawData, partyTypeReq));
    }
  }

  protected adaptFormToFilterPartyType(formRawData): AnagApiPartyTypeConfigRequest {
    const req = new AnagApiPartyTypeConfigRequest();
    if (formRawData.personType === '1') {
      req.personType = 1;
      if (formRawData.physicalPartyForm && formRawData.physicalPartyForm.professionType) {
        req.professionCode = formRawData.physicalPartyForm.professionType;
      }
    } else if (formRawData.personType === '2') {
      req.personType = 2;
      req.legalForm = formRawData.legalPartyForm && formRawData.legalPartyForm.corporateForm ?
        formRawData.legalPartyForm.corporateForm : 999;
    } else {
      return null;
    }
    return req;
  }

  actionCancel() {
    const currState = this.getCurrentState();
    this.statelessOps.actionCancel(currState.fromSearchFlow, this.activeRoute, currState.fromResultFlow);
  }

  actionNewParty(formRawData: any) {
    const currState = this.getCurrentState();
    const routeData = new AnagRouteData();
    routeData.party = this.anagStatelessService.deepClone(this.anagStatelessService.updatePartyWithKeyData(currState, formRawData));
    routeData.action = 'newParty';
    routeData.lastAction = 'keyData';
    routeData.configuration = currState.partyConfiguration;
    routeData.redirectToMainFlow = currState.redirectToMainFlow;
    routeData.flowData = currState.flowData;
    this.eventService.emit<NewPartyEvent>(new NewPartyEventInstance(routeData));
  }

  actionUpdateParty(formRawData: any) {
    this.eventService.emit<KeyDataUpdateEvent>(new KeyDataUpdateEventInstance(
      this.anagStatelessService.updatePartyWithKeyData(this.getCurrentState(), formRawData),
      this.getCurrentState().partyConfiguration
    ));
  }

  updatePartyFromState(formRawData: any) {
    this.anagStatelessService.updatePartyWithKeyData(this.getCurrentState(), formRawData);
  }

  get countriesList(): Array<AnagCountry> {
    return this.anagStorage.countries;
  }

  copyPartyToState(party: AnagApiParty) {
    const currState = this.getCurrentState();
    currState.party = party;
    currState.inputPartyMerged = true;
    if (party.personType && party.personType.codice === '1') {
      this.updateState$(this.statelessOps.retrieveProfessionDetails$(of(currState), party.professionType.codice));
    } else {
      this.updateState$(of(currState));
    }
  }

  setPersonType(personType: string) {
    const currState = this.getCurrentState();
    const party = currState.party;
    party.personType = this.getEnumsByCode(PERSON_TYPE_ENUM).find(type => type.codice === personType);
    if (party.personType.codice === '1') {
      party.corporateForm = undefined;
    } else {
      party.professionType = undefined;
      party.professionDetail = undefined;
    }
    this.updateState$(of(currState));
  }

  duplicateParty(filter: AnagApiParty) {
    return this.statelessOps.duplicateParty$(filter);
  }

  getSubjectDetail(idSubject, idNode, nodeCode) {
    return this.statelessOps.getSubjectDetail$(idSubject, idNode, nodeCode);
  }

  actionFindParties(filterReq) {
    return this.statelessOps.actionFindParties$(of(this.getCurrentState()), filterReq, this.activeRoute);
  }

  checkPartyKey(filter: AnagPartyKeyService) {
    return this.statelessOps.checkPartyKey$(filter);
  }
}

<div [formGroup]="parentForm" [attr.data-qa-role]="role">
  <label class="lbl"> {{label}}</label> <br>
  <input *ngIf="isEmpty; else isFilled" [attr.data-qa-role]="role+'-role-no-value'"
  class="drop-container dashedSelect" [value]="select"  (click)="openAnag()">
  <ng-template #isFilled>
    <div class="input-group col-lg-6" formGroupName="insured">
      <input
        value="{{fill()}}"
        style="width:100%"
        type="text"
        name="extraInformations_0"
        class="form-control effectiveHolderName"
        readonly=""
        [attr.data-qa-subj]="fill()+'-role-'+role"
        [attr.data-qa-role]="role+'-role-value'">
      <div class="input-group-btn">
        <button [disabled]="disabled" type="button" class="btn btn-default" (click)="resetMethod()" [attr.data-qa-role]="role+'-role-button-del'">
          <span class="rgifont rgi-trash"></span>
        </button>
        <button type="button" class="btn btn-default" (click)="editSubject(val.objectId, role)" [attr.data-qa]="'modify-' + role">
          <span class="rgifont rgi-pencil"></span>
        </button>
      </div>
    </div>
  </ng-template>
</div>

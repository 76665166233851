/**
 * date-time-format.class
 */

import {InjectionToken} from '@angular/core';

export interface RgiRxDateTimeFormats {
  parseInput: any;
  fullPickerInput: any;
  datePickerInput: any;
  timePickerInput: any;
  monthYearLabel: any;
  dateA11yLabel: any;
  monthYearA11yLabel: any;
}

/** InjectionToken for date time picker that can be used to override default format. */
export const RGI_RX_DATE_TIME_FORMATS = new InjectionToken<RgiRxDateTimeFormats>('RGI_RX_DATE_TIME_FORMATS');

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClaimSubjectsComponent } from './claim-subjects.component';
import {
  CardService,
  containerCacheServiceProvider,
  anagManageSubjectValidationServiceProvider
} from '@rgi/digital-claims-common-angular';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { CLAIMS_TRANSLATIONS } from '../../assets/i18n/translations';

@NgModule({
    declarations: [
        ClaimSubjectsComponent
    ],
    imports: [
        CommonModule,
        RgiRxI18nModule,
        RgiRxI18nModule.forRoot(CLAIMS_TRANSLATIONS),
    ],
    exports: [
        ClaimSubjectsComponent
    ],
    providers: [
        containerCacheServiceProvider,
        anagManageSubjectValidationServiceProvider
    ]
})
export class ClaimSubjectsModule {
  constructor(cardService: CardService) {
    const title = '_CLAIM_SUBJECTS';
    const moduleName = 'claimSubjects';
    const parentCardName = 'claimsFinder';

    const routes = [{
      path: 'action', route: {
        template: 'coreportal/cardpass/partials/core-portal-integrationpass.tpl.html',
        controller: 'CardPassCtrl',
        label: 'Action'
      }
    }];

    const communication = {
      reload: 'reload'
    };

    cardService.registerSubCard(
      moduleName,
      title,
      ClaimSubjectsComponent,
      parentCardName,
      null,
      'ClaimsCommunicationCtrl',
      communication,
      routes
    );

  }
}

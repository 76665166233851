<ng-container [formGroup]="unitFormGroup">
  <div class="rgi-gp-mb-l">
    <rgi-rx-expansion-panel [expanded]="isUnitExpanded()" [attr.data-qa]="unit.code"
                            (toggle)="updateAccordionMap($event)">
      <div rgi-rx-expansion-panel-header
           [ngClass]="{'rgi-gp-selected': unit.selected, 'rgi-gp-disabled': !unit.enable}">
        <div class="rgi-gp-flex rgi-gp-w-100 rgi-gp-fl-align-center">
          <div class="rgi-gp-flex-gr-1 rgi-gp-flex rgi-gp-fl-align-center" rgi-rx-expansion-panel-label>
            <rgi-rx-form-field class="rgi-gp-no-mb">
              <input type="checkbox" rgiRxInput data-qa="rgi-gp-unit-checkbox"
                     [value]="unit.selected === true" (change)="manageUnitCheckBox()">
            </rgi-rx-form-field>
            {{unit.description}}
            <div data-qa="rgi-gp-initialstatus-descr">
              &nbsp;{{ unit.selected ? '- ' + unit.startStatusDescr : '' }}
            </div>
          </div>
          <div *ngIf="unitPremium" data-qa="rgi-gp-unit-premium">
            <span>{{'_GP_._QUOTATION_._GROSS_TITLE_' | translate}}:
              {{unitPremium | currency:'EUR':true}}
            </span>
          </div>
          <div *ngIf="unitFormGroup.invalid" data-qa="rgi-gp-unit-invalid" class="rgi-ui-icon-alert"></div>
        </div>
      </div>
      <ng-template rgiRxExpansionPanelContent>
        {{'_GP_._QUOTATION_._UNIT_INITIAL_STATUS_' | translate}}
        <br>
        <rgi-rx-button-toggle [disabled]="!unit.selected" [value]="'' + unit.startStatusType"
                              (onToggle)="onInitialStateChange($event)"
                              data-qa="rgi-gp-initialstatus-toggle">
          <button rgi-rx-button rgiRxValueOption *ngFor="let status of unitInitialStatuses" [value]="status.code">
            {{status.description}}
          </button>
        </rgi-rx-button-toggle>
        <br>
        <rgi-gp-group-policy-factors-component *ngIf="unit.listVariable && unit.listVariable.length"
                                               [factors]="unit.listVariable"
                                               [parentForm]="unitFormGroup"
                                               (updateFactors)="onVariableUpdate($event)"
                                               class="rgi-gp-flex-row">
        </rgi-gp-group-policy-factors-component>
        <div *ngIf="linkedUnits && linkedUnits.length">
          <rgi-gp-unit *ngFor="let linked of linkedUnits; trackBy: trackLinkedUnit"
                       [unit]="linked"
                       [accordionMap]="accordionMap"
                       [accordionPrefix]="accordionPrefix + linked.code + '_'"
                       (updateUnit)="updateLinked($event)"
                       [parentForm]="parentForm"
                       [unitInitialStatuses]="unitInitialStatuses">
          </rgi-gp-unit>
        </div>
        <rgi-gp-group-policy-clause
          *ngIf="!!unitsClause"
          [clauses]="unitsClause.unitClauses"
          [parentForm]="parentForm"
          (formChange)="parentForm=$event"
          (updateClauses)="updateClauses($event)"
        ></rgi-gp-group-policy-clause>
      </ng-template>
    </rgi-rx-expansion-panel>
  </div>
</ng-container>

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RgiRxAccordionDirective} from './rg-rx-accordion.directive';
import {RgiRxExpansionPanelComponent} from './expansion-panel/rgi-rx-expansion-panel.component';
import {RgiRxExpansionPanelContentDirective} from './rgi-rx-expansion-panel-content.directive';
import {RgiRxExpansionPanelHeaderComponent} from './expansion-panel-header/rgi-rx-expansion-panel-header.component';
import {PortalModule} from '@angular/cdk/portal';
import {RgiRxExpansionPanelLabelDirective} from './rgi-rx-expansion-panel-label.directive';


@NgModule({
  declarations: [
    RgiRxAccordionDirective,
    RgiRxExpansionPanelComponent,
    RgiRxExpansionPanelContentDirective,
    RgiRxExpansionPanelHeaderComponent,
    RgiRxExpansionPanelLabelDirective
  ],
  imports: [
    CommonModule,
    PortalModule
  ],
  exports: [
    RgiRxAccordionDirective,
    RgiRxExpansionPanelComponent,
    RgiRxExpansionPanelContentDirective,
    RgiRxExpansionPanelHeaderComponent,
    RgiRxExpansionPanelLabelDirective
  ]
})
export class RgiRxExpansionModule {

}

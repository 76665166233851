import {NgModule} from '@angular/core';
import {DocumentsComponent} from "./components/documents/inbound-documents-component/documents.component";
import {
  DocumentValidationFileModalComponent
} from './components/documents/modals/document-validation-file-modal/document-validation-file-modal.component';
import {
  DocumentUploadFileModalComponent
} from './components/documents/modals/document-upload-file-modal/document-upload-file-modal.component';
import {DocumentAddModalComponent} from './components/documents/modals/document-add-modal/document-add-modal.component';
import {ReactiveFormsModule} from "@angular/forms";
import {CommonModule, JsonPipe, NgForOf, NgIf} from "@angular/common";
import {RgiRxI18nModule} from "@rgi/rx/i18n";
import {
  RgiRxBadgeModule, RgiRxDatePickerModule,
  RgiRxExpansionModule,
  RgiRxFormElementsModule,
  RgiRxModalModule,
  RgiRxPanelModule, RgiRxTableModule
} from "@rgi/rx/ui";
import {RGI_SHARED_COMPONENTS_TRANSLATIONS} from "./i18n/translations";
import { GenericDetailComponent } from './components/generic-detail/generic-detail.component';
import {
  OutboundDocumentsComponent
} from "./components/documents/outbound-documents-component/outbound-documents.component";
import { OutboundDocumentsModalComponent } from './components/documents/modals/outbound-documents-modal/outbound-documents-modal/outbound-documents-modal.component';

@NgModule({
  declarations: [
    DocumentsComponent,
    DocumentValidationFileModalComponent,
    DocumentUploadFileModalComponent,
    DocumentAddModalComponent,
    GenericDetailComponent,
    OutboundDocumentsComponent,
    OutboundDocumentsModalComponent
  ],
  imports: [
    ReactiveFormsModule,
    JsonPipe,
    NgIf,
    RgiRxI18nModule,
    RgiRxFormElementsModule,
    NgForOf,
    RgiRxModalModule,
    RgiRxPanelModule,
    RgiRxExpansionModule,
    RgiRxBadgeModule,
    RgiRxI18nModule,
    RgiRxI18nModule.forRoot(RGI_SHARED_COMPONENTS_TRANSLATIONS),
    CommonModule,
    RgiRxDatePickerModule,
    RgiRxTableModule
  ],
  exports: [
    DocumentsComponent,
    GenericDetailComponent,
    OutboundDocumentsComponent
  ]
})
export class RgiSharedComponentsModule {

}

<div class="modal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <span translate>{{ title }}</span>
                    <span class="glyphicon glyphicon-remove pull-right cursorPointer" (click)="close()"></span>
                </h3>
            </div>
            <div class="modal-body">
                <form [formGroup]="addDocumentForm">
                    <div class="form-group row">
                        <ng-container>
                            <div class="form-group row col-xs-12">
                                <label translate>PORTAL_NG_COMMON.DOCUMENT_TYPE</label>
                                <select formControlName="documentType" class="select-nativa">
                                    <option [value]="-1"></option>
                                    <option *ngFor="let documentType of arDocumentType"
                                            [value]="documentType.code">{{ documentType.description }}</option>
                                </select>
                            </div>
                        </ng-container>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <div class="btn-group btn-group-justified">
                    <div class="btn-group">
                        <button class="btn btn-warning" (click)="confirmAddDocument()" translate>PORTAL_NG_COMMON.ADD</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import {Inject, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor(@Inject('environment') private environment: any) { }

  log(...data: any[]): void {
    if (!this.environment.production) {
      console.log(data);
    }
  }

  error(...data: any[]): void {
    if (!this.environment.production) {
      console.error(data);
    }
  }

  warn(...data: any[]): void {
    if (!this.environment.production) {
      console.warn(data);
    }
  }

  info(...data: any[]): void {
    if (!this.environment.production) {
      console.info(data);
    }
  }

  debug(...data: any[]): void {
    if (!this.environment.production) {
      console.debug(data);
    }
  }
}

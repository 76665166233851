import {Injectable} from '@angular/core';
import {RiskCertificateCell} from './risk-certificate-cell';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {RiskCertificateData} from './risk-certificate-data';
import {ClaimsData} from './claims-data';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RiskCertificateService {

  private riskCertificateData: RiskCertificateData;
  private claimsData: ClaimsData;
  private recalculateInsuranceStatusChannel: Subject<any> = new Subject<any>();

  public toFormGroup(riskCertificateTable: RiskCertificateCell[][]): UntypedFormGroup {
    const group: any = {};

    riskCertificateTable.forEach((column, index) => {
      column.forEach((cell) => {
        group[index + '.' + cell.type] = new UntypedFormControl(cell.amount,
          [Validators.required, Validators.pattern(/[0-9]|^--$|^N.A.$|^N.D.$/)]);
      });
    });

    return new UntypedFormGroup(group);
  }

  setRiskCertificateData(riskCertificateData: RiskCertificateData) {
    this.riskCertificateData = riskCertificateData;
  }

  getRiskCertificateData() {
    return this.riskCertificateData;
  }

  setClaimsData(claimsData: ClaimsData) {
    this.claimsData = claimsData;
  }

  getClaimsData() {
    return this.claimsData;
  }

  getCurrentYear(lastYearOfTheReport: string) {
    return lastYearOfTheReport ? parseInt(lastYearOfTheReport, 10) : (new Date()).getFullYear();
  }

  getNumberOfYears(currentYear: number) {
    return (currentYear >= 2019) ? 11 : 6;
  }

  getRecalculateInsuranceStatusSignal() {
    return this.recalculateInsuranceStatusChannel.asObservable();
  }

  sendRecalculateInsuranceStatusSignal() {
    this.recalculateInsuranceStatusChannel.next();
  }

}

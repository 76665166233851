import {GenericEntityPcPortfolio} from '../pc-portfolio-models/genericEntityPcPortfolio';

export class ApiPcAddress {
  id: string;
  main: boolean;
  normalized: boolean;
  country: GenericEntityPcPortfolio;
  region: GenericEntityPcPortfolio;
  district: GenericEntityPcPortfolio;
  city: GenericEntityPcPortfolio;
  address: string;
  toponym: any;
  postalCode: string;
  number: string;
  formattedAddress: string;
  additionalLabels?: any;
}

<div class="rgi-ui-panel-container" *ngIf="stateMgr.getState$() | async as state">
  <div class="rgi-ui-panel-header rgi-anag-p-1">
    <span class="anag-icon-m rgi-ui-icon-passport"></span>
    <span class="rgi-ui-panel-title rgi-ui-text-1" translate>_ANAG_._LABEL_._DOCUMENT_</span>
    <span class="rgi-ui-btn-close" (click)="actionClose()" data-qa="close-modal"></span>
  </div>
  <ng-container [formGroup]="documentForm" *ngIf="editorMode === ANAG_DOCUMENT_OBJ_MODE.EDIT">
    <div class="rgi-ui-panel-content">
      <div class="rgi-ui-grid-2">
        <div class="rgi-ui-col" *ngIf="isFieldVisible('documentType')">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_ANAG_._LABEL_._DOCUMENT_TYPE_' | translate }}</label>
            <select rgiRxNativeSelect [formControlName]="'documentType'" data-qa="rgi-anag-document-type">
              <option selected></option>
              <option *ngFor="let type of documentTypes" [value]="type.codice">{{ type.descrizione }}</option>
            </select>
          </rgi-rx-form-field>
        </div>
        <div class="rgi-ui-col" *ngIf="isFieldVisible('documentNumber')">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_ANAG_._LABEL_._DOCUMENT_NO_' | translate }}</label>
            <input rgiRxInput [formControlName]="'documentNumber'" (change)="onDocNumberChange()"
                   data-qa="rgi-anag-document-number" placeholder="{{'_ANAG_._LABEL_._DOCUMENT_NO_'| translate}}">
          </rgi-rx-form-field>
        </div>
        <div class="rgi-ui-col" *ngIf="isFieldVisible('releaseDate')">
          <rgi-rx-form-field style="display: block">
            <label rgiRxLabel><span>{{ '_ANAG_._LABEL_._RELEASE_DATE_' | translate }}</span></label>
            <rgi-rx-date-picker>
              <input [rgiRxDateTimeInput]="releaseDate" formControlName="releaseDate" placeholder="{{datepickerPlaceholder}}"/>
          </rgi-rx-date-picker>
          <rgi-rx-date-time pickerType="calendar" #releaseDate></rgi-rx-date-time>
          </rgi-rx-form-field>
        </div>
        <div class="rgi-ui-col" *ngIf="isFieldVisible('expirationDate')">
          <rgi-rx-form-field style="display: block">
            <label rgiRxLabel><span>{{ '_ANAG_._LABEL_._EXPIRATION_DATE_' | translate }}</span></label>
            <rgi-rx-date-picker>
              <input [rgiRxDateTimeInput]="expirationDate" formControlName="expirationDate" placeholder="{{datepickerPlaceholder}}"/>
          </rgi-rx-date-picker>
          <rgi-rx-date-time pickerType="calendar" #expirationDate></rgi-rx-date-time>
        </rgi-rx-form-field>
        </div>

        <div class="rgi-ui-col" *ngIf="isFieldVisible('locationsRelease')">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_ANAG_._LABEL_._RELEASE_LOCATION_' | translate }}</label>
            <input rgiRxInput [formControlName]="'locationsRelease'"
                   data-qa="rgi-anag-release-location" placeholder="{{'_ANAG_._LABEL_._RELEASE_LOCATION_'| translate}}">
          </rgi-rx-form-field>
        </div>
        <div class="rgi-ui-col" *ngIf="isFieldVisible('authoritiesRelease')">
          <rgi-rx-form-field>
            <label rgiRxLabel>{{ '_ANAG_._LABEL_._RELEASE_AUTHORITY_' | translate }}</label>
            <select rgiRxNativeSelect [formControlName]="'authoritiesRelease'" data-qa="rgi-anag-release-auth">
              <option selected></option>
              <option *ngFor="let authority of authorities" [value]="authority.codice">{{ authority.descrizione }}
              </option>
            </select>
          </rgi-rx-form-field>
        </div>
        <div class="rgi-ui-col" *ngIf="documentForm.get('documentType')?.value === '2'">
          <div rgi-rx-accordion>
            <rgi-rx-expansion-panel color="default" *ngFor="let drivingLic of drivingLicenseList; let i = index" [attr.data-qa]="'anag-driving-license_'+i">
              <rgi-rx-expansion-panel-header>
                {{ drivingLic.descr }}{{ ' ' }}{{ drivingLic.dendDrivingLicense | date:'dd/MM/yyyy' }}
                <div style="margin-left: auto">
                  <button type="button" *ngIf="canEditLicenseType" (click)="editDrivingLicense(drivingLic, i)"
                          class="rgi-ui-btn rgi-ui-btn-outline rgi-ui-icon-edit2 rgi-anag-text-tone"></button>
                  <button type="button" *ngIf="canDeleteLicenseType" (click)="deleteDrivingLicense(i)"
                          class="rgi-ui-btn rgi-ui-btn-outline rgi-ui-icon-delete rgi-anag-text-tone"></button>
                </div>
              </rgi-rx-expansion-panel-header>
              <div class="rgi-ui-grid-2">
                <div class="rgi-ui-col">
                  <div>{{ '_ANAG_._LABEL_._RELEASE_DATE_' | translate }}:</div>
                </div>
                <div class="rgi-ui-col">
                  <b
                    translate>{{ drivingLic.dstartDrivingLicense ? (drivingLic.dstartDrivingLicense | date:'dd/MM/yyyy') : '-' }}</b>
                </div>
                <div class="rgi-ui-col">
                  <div>{{ '_ANAG_._LABEL_._DRIVER_QUALIFICATION_CARD_' | translate }}:</div>
                </div>
                <div class="rgi-ui-col">
                  <b translate>{{ drivingLic.bdriverQualifCard ? drivingLic.bdriverQualifCard : '-' }}</b>
                </div>
              </div>
            </rgi-rx-expansion-panel>
          </div>
          <div class="rgi-anag-pt-m" *ngIf="canAddLicenseType && state.configuration?.partyConfig?.drivingLicense?.dEndDrivingLicense?.configuration?.codice !== '2'">
            <button rgi-rx-button (click)="addDrivingLicenceType()"
                    class="rgi-ui-btn rgi-ui-btn-secondary rgi-ui-btn-dashed" translate>
              _ANAG_._LABEL_._ADD_DRIVING_LICENSE_TYPE_
            </button>
          </div>
        </div>
      </div>
    </div>
    <rgi-rx-snackbar tag="error-tag"></rgi-rx-snackbar>
    <rgi-rx-snackbar tag="doc-number-error-tag"></rgi-rx-snackbar>
    <rgi-rx-snackbar tag="no-doc-error-tag"></rgi-rx-snackbar>
    <div class="rgi-ui-panel-footer">
      <button class="rgi-ui-btn rgi-ui-btn-secondary" type="button" (click)="actionClose()" data-qa="anag-btn-cancel"
              translate>_ANAG_._BTN_._CANCEL_
      </button>
      <button class="rgi-ui-btn rgi-ui-btn-primary" type="button" (click)="updateDocument()" data-qa="anag-btn-confirm"
              translate>_ANAG_._BTN_._CONFIRM_
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="editorMode === ANAG_DOCUMENT_OBJ_MODE.DRIVING_LICENCE_TYPE">
    <rgi-anag-document-driving-license-type (onCloseDrivingLicence)="onCloseDrivingLicence()"
                                            (onConfirmDrivingLicence)="onConfirmDrivingLicence($event)"
                                            [configuration]="state.configuration"
                                            [drivingLicense]="drivingLicenseModify" [party]="state.party"
                                            [expirationDateDocument]="documentForm.get('expirationDate').value"
                                            [drivingLicenseList]="drivingLicenseList"
                                            [editMode]="editMode"
                                            [indexDrivingLicense]="indexDrivingLicense"></rgi-anag-document-driving-license-type>
  </ng-container>
</div>

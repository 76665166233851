import {ApiPcAddress} from '../../api-models/apiPcAddress';
import {AdditionalLabel} from '../proposal-models/additional-label';
import {Package} from './pc-insured-asset';
import {PcSection} from './pc-section';
import {Clause} from '../../domain-models/clause';
import {AnagIssueSubject} from '../../../re-issue/re-issue-anag/anag-issue';

export class PcAssetInstance {
  public asset: PcAsset;
  public subject: AnagIssueSubject;
  public sections: Array<PcSection> = new Array<PcSection>();
  sync: boolean;

  constructor() {
  }
}

export class PcAsset {
  additionalLabels?: AdditionalLabel[];
  key?: string;
  name?: string;
  help?: string;
  messages?: any;
  factors?: Array<any>;
  activityPacket?: AcitvityPacket;
  secondaryActivityPacket?: AcitvityPacket;
  activitiesMin?: number;
  activitiesMax?: number;
  selected?: boolean;
  extentions?: any;
  package?: Package[];
  selectedPackage?: Package;
  address: ApiPcAddress;
  code: string;
  clauses: Array<Clause>;
  lienMandatory: boolean;


  constructor(asset?: PcAsset) {
    this.additionalLabels = asset ? asset.additionalLabels : null;
    this.key = asset ? asset.key : null;
    this.name = asset ? asset.name : null;
    this.help = asset ? asset.help : null;
    this.messages = asset ? asset.messages : null;
    this.factors = asset ? asset.factors : null;
    this.activityPacket = asset ? asset.activityPacket : null;
    this.secondaryActivityPacket = asset ? asset.secondaryActivityPacket : null;
    this.activitiesMin = asset ? asset.activitiesMin : null;
    this.activitiesMax = asset ? asset.activitiesMax : null;
    this.selected = asset ? asset.selected : null;
    this.extentions = asset ? asset.extentions : null;
    this.clauses = asset ? asset.clauses : null;
    this.lienMandatory = asset ? asset.lienMandatory : null;
  }

  public hasFactors() {
    return this.factors && this.factors.length;
  }
}

export interface AcitvityPacket {
  additionalLabels?: AdditionalLabel[];
  lvl1?: Level;
  lvl2?: Level;
  lvl3?: Level;
  lvl4?: Level;
  labels?: Label;
  values?: Value;
}

export interface Level {
  additionalLabels?: AdditionalLabel[];
  code?: string;
  description?: string;
}

export interface Label {
  additionalLabels?: AdditionalLabel[];
  lvl1?: string;
  lvl2?: string;
  lvl3?: string;
  lvl4?: string;
}

export interface Value {
  additionalLabels?: AdditionalLabel[];
  lvl1?: Level[];
  lvl2?: Level[];
  lvl3?: Level[];
  lvl4?: Level[];
}

import {Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {LiensService} from '../liens.service';
import {Message} from '../../../models/message';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {Lien} from '../../../models/domain-models/lien';
import {ProposalService} from '../../../proposal.service';
import {EventNotificator} from '../../../event-notificator';
import {DateComparisonValidator} from '../../../date-comparison.validator';

@Component({
  selector: 'mic-lien',
  templateUrl: './lien.component.html',
  styleUrls: ['./lien.component.scss']
})
export class LienComponent implements OnInit, OnDestroy, EventNotificator {

  @Input() bondElement: Lien;

  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();

  lienControlForm: UntypedFormGroup;
  componentRef: ComponentRef<any>;

  validationMessages: Message[] = [];
  areaCode = 'LIEN';

  bindingEntityMandatory: boolean;
  effectiveDateMandatory: boolean;
  expirationDateMandatory: boolean;
  submitted: boolean;
  private subscriptions: Subscription = new Subscription();

  constructor(
    protected proposalService: ProposalService,
    protected formBuilder: UntypedFormBuilder,
    protected liensService: LiensService,
    protected translate: TranslateService) {
  }

  ngOnInit() {
    this.bindingEntityMandatory = this.liensService.getBindingEntityMandatory();
    this.effectiveDateMandatory = this.liensService.getEffectiveDateMandatory();
    this.expirationDateMandatory = this.liensService.getExpirationDateMandatory();

    this.lienControlForm = this.formBuilder.group(
      {
        description: ['', this.bindingEntityMandatory ? Validators.required : null],
        effectiveDate: [undefined, this.effectiveDateMandatory ? Validators.required : null],
        expirationDate: [undefined, this.expirationDateMandatory ? Validators.required : null]
      }, {
        validator: DateComparisonValidator('effectiveDate', 'expirationDate')
      }
    );

    const lienFormValueChangesSubscription = this.lienControlForm.valueChanges.subscribe(data => {
      this.validateForm();
    });
    this.subscriptions.add(lienFormValueChangesSubscription);
  }

  onSubmit() {
    this.submitted = true;
    this.validateForm();

    if (this.lienControlForm.valid) {
      const id = this.liensService.getNewLienId();
      const descr = this.lienControlForm.controls.description.value;
      const effectiveDate = this.lienControlForm.controls.effectiveDate.value;
      const expirationDate = this.lienControlForm.controls.expirationDate.value;

      // Add new lien in list
      this.liensService.addLien(new Lien(id, descr, effectiveDate, expirationDate));

      // Save lien
      this.saveLiens();
    }
  }

  saveLiens() {
    const liensList = this.liensService.getLiensList();

    if (liensList.length > 0) {
      this.liensService.updateLiens(this.proposalService.getContractId(), liensList).subscribe(
        (event) => {
          this.close();
        });
    }
  }

  close() {
    this.componentRef.destroy();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private validateForm() {
    const controls = this.lienControlForm.controls;

    this.validationMessages.length = 0;

    if (controls.description.errors && controls.description.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Description is mandatory'));
    }
    if (controls.effectiveDate.errors && controls.effectiveDate.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Effective date is mandatory'));
    }
    if (controls.expirationDate.errors && controls.expirationDate.errors.required) {
      this.validationMessages.push(new Message(this.areaCode, 'Expiration date is mandatory'));
    }
    if (controls.effectiveDate.errors && controls.effectiveDate.errors.date1AfterDate2 ||
      controls.expirationDate.errors && controls.expirationDate.errors.date1AfterDate2) {
      this.validationMessages.push(new Message(this.areaCode, 'Expiration Date must be greater then Effective Date'));
    }
    this.eventPropagation.emit('layoutChanged');
  }
}

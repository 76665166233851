import { AbstractStateManager, StateStoreService } from "@rgi/rx/state";
import { Injectable } from "@angular/core";
import { ActiveRoute } from "@rgi/rx/router";
import { DialogueHandlerIntegrationService } from "../dialogue-handler-configuration/dialogue-handler-integration.service";
import { DialogueHandlerNewDialogueService } from "./dialogue-handler-new-dialogue.service";
import { of } from "rxjs";
import { NewDialogueState } from "../dialogue-handler-configuration/dialogue-handler.model";
import { Document, NewDialogueRequest } from "../dialogue-handler-configuration/new-dialogue.model";

@Injectable()
export class DialogueHandlerNewDialogueStateManager extends AbstractStateManager<NewDialogueState> {

    constructor(activeRoute: ActiveRoute,
        stateStoreService: StateStoreService,
        protected statelesOps: DialogueHandlerNewDialogueService,
        private integrationService: DialogueHandlerIntegrationService)
        {
        super(activeRoute, stateStoreService);
        const st = !!stateStoreService.get<NewDialogueState>(this.storeKey)
            ? stateStoreService.get<NewDialogueState>(this.activeRoute.key)
            : new NewDialogueState(this.storeKey);
        if (this.activeRoute.getRouteData<any>().filterList) {
            this.updateState$(this.statelesOps.initStateNew$(of(st),this.activeRoute.getRouteData<any>().filterList));
        } else {
            this.updateState$(of(st));
        }
        
      }

      updateFormSearch$(form: NewDialogueRequest) {
        this.updateState$(this.statelesOps.updateFormNew(form, of(this.getCurrentState())));

      }

        
  saveDialogue$(documents: Document[],param?,msgId?){
    const st = this.getCurrentState();
    console.log("VALORE DI st nello state dopo aver acquisito i valori da form ===>" ,st);  
    this.updateState$(this.statelesOps.saveDialogue(of(st), this.activeRoute,documents,param,msgId));
    }

    popolateCascadeSections$(funStatus: number) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.popolateCascadeSections(of(st), this.activeRoute,funStatus));
      }

      popolateLastTableData$(index: number) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.popolateLastTableData(of(st), this.activeRoute,index));
      }  

      partialPopolateCascadeSections$(funStatus: number) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.partialPopolateCascadeSections(of(st), this.activeRoute,funStatus));
      }

      responsePopolateCascadeSections$(funStatus: number) {
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.popolateResponseCascadeSections(of(st), this.activeRoute,funStatus));
      }

      goBackToSummary$(){
        const st = this.getCurrentState();
        this.updateState$(this.statelesOps.goBackToSummary(of(st),this.activeRoute));
      }

      goBack(){
        this.closeCardSession();
        }
      
        closeCardSession() {
          this.statelesOps.closeSession$(this.activeRoute.id, this.getCurrentState());
        }
}
import { BaremEntity, EnumType, Outcome, ServiceInfo } from "../../dsc-shared-model/dsc-model-utility";

export class CircumstanceIncident {
    signatureType: number = undefined; // Tipo firma
    comboSignatureType?: [number[], string[]];
    comboSignatureTypeEnabled?: boolean = false; // Abilitazione multiselect "Tipo firma"
    signatureTypeRequired?: boolean = true; // "Tipo firma" obbligatorio

    percInsuredLiability: number = undefined; // Percentuale responsabilità assicurato
    percInsuredLiabilityEnabled?: boolean = false; // Abilitazione "Percentuale responsabilità assicurato"
    percInsuredLiabilityRequired?: boolean = true; // "Percentuale responsabilità assicurato" obbligatorio

    percOtherPartyLiability: number = undefined; // Percentuale responsabilità controparte
    percOtherPartyLiabilityEnabled?: boolean = false; // Abilitazione "Percentuale responsabilità controparte"
    percOtherPartyLiabilityRequired?: boolean = true; // "Percentuale responsabilità controparte" obbligatorio

    eventDenial: boolean = false; // Negazione evento
    eventDenialEnabled?: boolean = true; // Abilitazione "Negazione evento"
    eventDenialRequired?: boolean = false; // "Negazione evento" obbligatorio

    classificationType: string = ''; // Tipo di classificazione (ANIA)
    comboClassificationType?: [number[], string[]]; // Multiselect "Tipo di classificazione" (ANIA)
    comboClassificationTypeEnabled?: boolean = false; // Abilitazione multiselect "Tipo di classificazione" (ANIA)
    classificationTypeRequired?: boolean = false; // "Tipo di classificazione" (ANIA) obbligatorio

    forcingTechnicalResponsibility: boolean = false; // Applica forzatura percentuale responsabilità (civilistica)
    forcingTechnicalResponsibilityEnabled?: boolean = false; // Abilitazione "Applica forzatura percentuale responsabilità (civilistica)"
    forcingTechnicalResponsibilityRequired?: boolean = false; // "Applica forzatura percentuale responsabilità (civilistica)" obbligatorio
    forcingTechnicalResponsibilityNotes: string = ''; // Note

    forcingAniaResponsibility: boolean = false; // Applica forzatura responsabilità ANIA
    forcingAniaResponsibilityEnabled?: boolean = false; // Abilitazione "Applica forzatura responsabilità ANIA"
    forcingAniaResponsibilityRequired?: boolean = false; // "Applica forzatura responsabilità ANIA" obbligatorio

    aniaNotes: string = ''; // Note ANIA
    reasonToForceLiability: string = ''; // Motivo forzatura
    forcedLiability: string = ''; // Responsabilità forzata
    comboForcedLiability?: [number[], string[]] = [[], []];
    comboForcedLiabilityEnabled?: boolean = false; // Abilitazione multiselect "Responsabilità forzata"

    liabilityType: string = ''; // Tipo di responsabilità
    liabilityTypeEnabled?: boolean = false; // Abilitazione "Tipo di responsabilità"
    liabilityTypeRequired?: boolean = true; // "Tipo di responsabilità" obbligatorio

    liabilityBySystem: string = ''; // Ragione / Torto definita da sistema
    liabilityBySystemEnabled?: boolean = false; // Abilitazione "Ragione / Torto definita da sistema"
    liabilityBySystemRequired?: boolean = true; // "Ragione / Torto definita da sistema" obbligatorio

    liabilityDeclared: string = ''; // Responsabilità dichiarata
    liabilityDeclaredEnabled?: boolean = false; // Abilitazione "Responsabilità dichiarata"
    liabilityDeclaredRequired?: boolean = true; // "Responsabilità dichiarata" obbligatorio

    vehiclesNumber: number = undefined;

    baremEntityA: BaremEntity = new BaremEntity();
    baremEntityARequired?: boolean = true; // Barem A obbligatorio

    baremEntityB: BaremEntity = new BaremEntity();
    baremEntityBRequired?: boolean = true; // Barem B obbligatorio

    outcome: Outcome = new Outcome();

    isValidForm?: boolean;

    isOccurrenceDateChanged?: boolean;
    isOccurrenceDateFromEmptyToValued?: boolean;
    version?: string;
}

export interface CircumstanceMultiselect {
    comboForcedLiability: EnumType[];
    comboSignatureType: EnumType[];
    comboClassificationType: EnumType[];
    comboForcingResponsibilityReasons: EnumType[];
    comboResponsibilityType: EnumType[];
    outcome?: Outcome;
}

export const INIT_COMBO_CIRCUMSTANCE: CircumstanceMultiselect = {
    comboForcedLiability: [],
    comboSignatureType: [],
    comboClassificationType: [],
    comboForcingResponsibilityReasons: [],
    comboResponsibilityType: []
};


export class OutputLiabilityDetermination {
    serviceInfo: ServiceInfo = new ServiceInfo();
    result?: Result;
}

export class Result2 {
    resultOutcome: string = '';
    resultDescription: any = undefined;
}

export class DeterminatedLiability {
    codice: string = '';
    descrizione: string = '';
}

export class LiabilityType {
    codice: string = '';
    descrizione: string = '';
}

export class Liability {
    liabilityPercentage: string = '';
    determinatedLiability: DeterminatedLiability = new DeterminatedLiability();
    liabilityType: LiabilityType = new LiabilityType();
}

export interface Result {
    result: Result2;
    liability: Liability;
}

export class OutputClaimContext {
    id: string;
    code: string;
    description: string;
}

export class OutputLiabilityDeterminationV2 {
  liability?: string;
}


import {Directive, ElementRef, Input, Optional, Self} from '@angular/core';
import {
  RgiRxControlForwardClass,
  RgiRxErrorStateMatcher,
  RgiRxFormControl
} from './rgi-rx-form-elements-api';
import {FormGroupDirective, NgControl, NgForm} from '@angular/forms';
import {RgiRxFormControlDirective} from './rgi-rx-form-control.directive';
import {RGI_RX_FORM_FILED_INPUTS} from './rgi-rx-form-elements-meta';

@Directive({
  selector: 'input[rgiRxInput], textarea[rgiRxInput]',
  host: {
    class: 'rgi-ui-form-control',
    '[id]': 'id',
    '[attr.required]': 'required || null',
    '[attr.disabled]': 'disabled || null',
    '[class.rgi-ui-error]': 'hasError()',
    '(focus)': 'focused = true',
    '(blur)': 'focused = false',
    '(click)': '_handleClick()'
  },
  inputs: [
    ...RGI_RX_FORM_FILED_INPUTS
  ],
  providers: [
    {
      provide: RgiRxFormControl,
      useExisting: RgiRxInputDirective
    }
  ]
})
/**
 * Directive that provides rgi/rx bindings for native inputs
 * @see RgiRxFormControl
 * @see RgiRxErrorStateMatcher
 */
export class RgiRxInputDirective extends RgiRxFormControlDirective<any> {

  constructor(
    elementRef: ElementRef,
    _errorStateMatcher: RgiRxErrorStateMatcher,
    @Optional() @Self() ngControl?: NgControl,
    @Optional() _parentForm?: NgForm,
    @Optional()  _parentFormGroup?: FormGroupDirective,
  ) {
    super(elementRef, _errorStateMatcher, ngControl, _parentForm, _parentFormGroup);
  }
  protected _type = 'text';
  @Input() get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = !!value ? value : 'text';
  }

  get forwardClasses(): RgiRxControlForwardClass {
    if (this.type === 'checkbox' || this.type === 'radio') {
      return ['rgi-ui-form-group-label-static'];
    }
  }


  @Input() get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
    if (this._type === 'text' || this._type === 'number' && typeof value === 'string') {
      this.elementRef.nativeElement.value = value;
    }
    if (this._type === 'checkbox') {
      this.elementRef.nativeElement.checked = value;
    }
  }
}

<div class="rgi-psales-taxes-container rgi-ui-text-5">
  <div *ngFor="let tax of taxes" class="rgi-psales-tax-container">
    <strong>{{tax.taxDescription}}</strong>
    <div class="rgipsales-flex">
      <div class="rgi-psales-tax-label rgi-ui-text-5" translate>_PCPSALES_._LABEL_._AMOUNT_</div>
      <div class="rgi-ui-text-5">
          {{ tax.amount | rgiCountryLayerNumberFormat:undefined:{maximumFractionDigits: 2, minimumFractionDigits: 2} }}
        </div>
    </div>
    <div class="rgipsales-flex">
      <div class="rgi-psales-tax-label rgi-ui-text-5" translate>_PCPSALES_._LABEL_._RATE_</div>
      <div class="rgi-ui-text-5">{{tax.taxRate}}</div>
    </div>
    <div class="rgipsales-flex" *ngIf="tax.antiracket">
      <div class="rgi-psales-tax-label rgi-ui-text-5" translate>_PCPSALES_._LABEL_._ANTIRACKET_</div>
      <div class="rgi-ui-text-5">
          {{ tax.antiracket | rgiCountryLayerNumberFormat:undefined:{
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      } }}
        </div>
    </div>
  </div>
</div>

import {Component, Inject, Input, EventEmitter, OnDestroy, OnInit, Output, ChangeDetectorRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {RoutableComponent, RoutingService} from '@rgi/portal-ng-core';
import {ExpertUtilityService} from '../expert-utility-service.service';
import {ExpertEntity} from '../dto/expert-entity';

@Component({
  selector: 'claims-experts-search',
  templateUrl: './experts-search.component.html',
  styleUrls: ['./experts-search.component.scss']
})
export class ExpertsSearchComponent implements OnDestroy, OnInit, RoutableComponent {

  public routes: any = null;
  constructor(
    @Inject('sessionService') private session: any,
    @Inject('claimsCardService') private cardService: any,
    @Inject('authService') private authService,
    @Inject('eventService') private eventService: any,
    @Inject('expertsListComponent') expertsListComponent,
    private utilityServ: ExpertUtilityService,
    public formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private routingService: RoutingService,
  ) {
    this.routes = {
      // from the search component we should only be able to travel to the list
      list : expertsListComponent,
    };
  }
  @Input() card: any;
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  public searchForm: UntypedFormGroup;
  public formConfig: any = {};
  public showError: any = false; // boolean to show hidden div in case user performs search without valued fields
  // triggered when button FIND is clicked on search form
  public showNoExpertsFound: boolean = false; // show if upon clicking on 'find', no records are returned
  public isLegalSubject: any = false;
  public isUmaUniqueKeyManagementEnabled;
  public umaConfigKeys: any; // array of uma configs (PSSINCONFKEY)
  public conditionalUmaFields: string = '';


  ngOnInit() {
    this.utilityServ.cleanExpertSearch();
    this.eventService.broadcastEvent('start-loader');
    this.utilityServ.getConfigExpertsFilter().subscribe(
      (res: any) => {
        console.log('getConfigExpertsFilter --> ', res);
        this.isUmaUniqueKeyManagementEnabled = res.umaUniqueKeyManagementEnabled;
        this.formConfig.subjectTypes = res.subjectTypes;
        this.formConfig.expertTypes = res.expertTypes;
        this.eventService.broadcastEvent('stop-loader');
        //  depending on uma enabled or not
        if (this.isUmaUniqueKeyManagementEnabled) {
          // countries are handled only if uma enabled
          // add form fields
          for (const cn of res.countries) {
              const countryCode: string = cn.code;
              // giuridico key 1
              this.searchForm.addControl(countryCode + '-G-Ckey1',
              new UntypedFormControl([], Validators.nullValidator));
              // fisico key1
              this.searchForm.addControl(countryCode + '-Ckey1',
              new UntypedFormControl([], Validators.nullValidator));
            // giuridico key 2
              this.searchForm.addControl(countryCode + '-G-Ckey2',
              new UntypedFormControl([], Validators.nullValidator));
            // fisico key2
              this.searchForm.addControl(countryCode + '-Ckey2',
              new UntypedFormControl([], Validators.nullValidator));
            // giuridico key3
              this.searchForm.addControl(countryCode + '-G-Ckey3',
              new UntypedFormControl([], Validators.nullValidator));
            // fisico key3
              this.searchForm.addControl(countryCode + '-Ckey3',
              new UntypedFormControl([], Validators.nullValidator));
          }
          this.formConfig.countries = res.countries;
          this.umaConfigKeys = res.umaConfigKeysMap.umaConfigKeysByCountry;

          // this.searchForm.controls.country.valueChanges.subscribe(value => {
          //
          // });

        }

      },
      (error) => {
        this.eventService.broadcastEvent('stop-loader');
      }
    );

    this.searchForm = this.formBuilder.group({
      country: ['IT'],
      subjectType: ['1'],
      expertCode: [],
      name: [],
      surname: [],
      businessName: [],
      vatNumber : [],
      fiscalCode : [],
      expertType: [],
    });

    this.searchForm.controls.country.valueChanges.subscribe(value => {
      console.log('change country');
      this.changeDetectorRef.detectChanges();
    });

    // subscribe to subjectType changes, determines disabled behaviour of input name/surname -- businessName
    this.searchForm.controls.subjectType.valueChanges.subscribe(value => {
      if (value === '1') {
        this.isLegalSubject = false;
        this.searchForm.controls.businessName.setValue(null);
      } else if (value === '2') {
        this.isLegalSubject = true;
        this.searchForm.controls.name.setValue(null);
        this.searchForm.controls.surname.setValue(null);
      }
    });


  }


  ngOnDestroy(): void {

  }

  // triggered when button EMPTY is clicked on search form
  onEmpty() {
    this.searchForm.reset();
    this.showError = false;
    this.showNoExpertsFound = false;
    this.isLegalSubject = false;
  }
  onFind() {
    this.showNoExpertsFound = false;
    // check if one of Codice,Nome, Cognome, CF, Partita iva has value
    if ( ((this.searchForm.controls.subjectType.value === '1' &&
          (!this.searchForm.value.name || !this.searchForm.value.name.trim().length ) &&
          (!this.searchForm.value.surname || !this.searchForm.value.surname.trim().length)) ||
         (this.searchForm.controls.subjectType.value === '2' &&
          (! this.searchForm.controls.businessName || !this.searchForm.value.businessName.trim().length )))
      && (!this.searchForm.value.expertCode || !this.searchForm.value.expertCode.trim().length)
      && (!this.searchForm.value.vatNumber || !this.searchForm.value.vatNumber.trim().length)
      && (!this.searchForm.value.fiscalCode || !this.searchForm.value.fiscalCode.trim().length)
      // TODO : consider UMA cases where fields may be required
      // && (this.isUmaUniqueKeyManagementEnabled && (this.searchForm.value.))
    ) {
      this.showError = true;
    } else {
      this.showError = false;
      // perform search
      this.searchForm.addControl('isUmaUniqueKeyEnabled',
        new UntypedFormControl(this.isUmaUniqueKeyManagementEnabled, Validators.nullValidator));
      this.utilityServ.setExpertsSearchFormGroup(this.searchForm);
      // determine which of the form values are actually relevant
      // this.session.open('claimsExperts', 'home', '', true);
      this.eventService.broadcastEvent('start-loader');
      this.utilityServ.getExpertSearchResult().subscribe((res: any[]) => {
        if (res.length > 0) {
          for (const x of res) {
            const tmp: ExpertEntity = new ExpertEntity();
            tmp.abi = x.abi;
            tmp.isAvailableCarCollection = x.availableCarCollection;
            tmp.isAvailableReplacementCar = x.availableReplacementCar;
            tmp.bankAccount = x.bankAccount;
            tmp.cabi = x.cabi;
            tmp.carBrandDescription = x.carBrandDescription;
            tmp.code = x.code;
            tmp.countryCode = x.countryCode;
            tmp.expertType = x.expertType;
            tmp.expertTypeProvider = x.expertTypeProvider;
            tmp.expertTypeDescription = x.expertTypeDescription;
            tmp.fiscalCode = x.fiscalCode;
            tmp.id = x.id;
            tmp.maxNumAssignments = x.maxNumAssignments;
            tmp.name = x.name;
            tmp.percCash = x.percCash;
            tmp.percVAT = x.percVAT;
            tmp.percWithHolding = x.percWithHolding;
            tmp.isPublicDepartment = x.publicDepartment;
            tmp.subjectBusinessName = x.subjectBusinessName;
            tmp.subjectKey1 = x.subjectKey1;
            tmp.subjectKey2 = x.subjectKey2;
            tmp.subjectKey3 = x.subjectKey3;
            tmp.surname = x.surname;
            tmp.taxRegimeDescription = x.taxRegimeDescription;
            tmp.taxRegimeId = x.tagRegimeId;
            tmp.vatNumber = x.vatNumber;
            tmp.expertTypeDescription = x.expertTypeDescription;
            tmp.expertTypeProviderDescription = x.expertTypeProviderDescription;
            tmp.subjectKeyWithDescription = x.subjectKeyWithDescription;
            tmp.nameBySubjectType = x.nameBySubjectType;

            this.utilityServ.getExperts().push(tmp);
          }
          // if (this.utilityServ.getExperts().length > 0) {
          this.session.open('claimsExperts', 'home', '', true);
          // }

        } else {
          this.showNoExpertsFound = true;
        }
        this.eventService.broadcastEvent('stop-loader');
      },
      (error) => {
        this.eventService.broadcastEvent('stop-loader');
        this.showNoExpertsFound = true;
      });



    }
  }


}



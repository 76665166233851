import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {integerDiv} from '../../../../adapters/group-policy-utils';
import {MapKey, SummaryAdministrativeData} from '../../../../group-policy-models/group-policy-issue-summary';


/**
 * @author: dmasone
 * @description: Component used for render summary admin data
 */
@Component({
    selector: 'rgi-gp-group-policy-summary-adm-data-component',
    templateUrl: './group-policy-summary-adm-data.component.html'
})
export class GroupPolicySummaryAdmDataComponent implements OnInit, OnDestroy {


    @Input() summaryAdministrativeData: SummaryAdministrativeData;

    public mapKey = MapKey;
    public modelKeys = [];
    public integerDiv = integerDiv;


    constructor() { }

    ngOnInit(): void {
        this.modelKeys = Object.keys(this.summaryAdministrativeData).filter(key => key !== 'proposalNumber');
    }

    ngOnDestroy(): void { }

    public formatDate(value: string | null, key: string): string | null {
        if (value != null && (key === 'expiryDate' || key === 'effDate')) {
          const dateParts = value.split('-');
          return (`${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`);
        }
        return value;
    }

}

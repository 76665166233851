import {Component, EventEmitter, Inject, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {PncPsalesButton} from '../../../resources/model/common/button';
import {PNC_PSALES_BTN_CANCEL, PNC_PSALES_BTN_CONTINUE} from '../../../resources/constants/buttons';
import {PNC_PSALES_ACTIONS} from '../../../resources/constants/actions';

export class RgiPncConfirmationModalData {
  msg: string;
  buttons: PncPsalesButton[] = [PNC_PSALES_BTN_CANCEL, PNC_PSALES_BTN_CONTINUE];

  constructor(msg: string, buttons?: PncPsalesButton[]) {
    this.msg = msg;
    if (!!buttons) {
      this.buttons = buttons;
    }
  }
}

@Component({
  selector: 'rgi-pnc-confirmation-modal',
  templateUrl: './confirmation-modal.component.html'
})
export class PncPsalesConfirmationModalComponent implements OnModalClose {
  modalClose: EventEmitter<PNC_PSALES_ACTIONS | string> = new EventEmitter();
  data: RgiPncConfirmationModalData;

  constructor(
    @Optional() @Inject(DIALOG_DATA) data: RgiPncConfirmationModalData
  ) {
    if (data) {
      this.data = data;
    }
  }

  onAction(action: string) {
    this.modalClose.emit(action);
  }

  actionCancel() {
    this.modalClose.emit(PNC_PSALES_ACTIONS.CANCEL);
  }

}

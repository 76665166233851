import {Injectable} from "@angular/core";
import {formatISO, isValid, parseISO} from "date-fns";
import {
  AnagCheckDrivingLicenseRequest,
  AnagDocument,
  AnagDrivingLicense,
  AnagDrivingLicenseRequest
} from "../anag-model/anag-domain/anag-document";
import { UntypedFormGroup } from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class AnagUtilsService {

  constructor() {}

  convertDateToString(obj) {
    if (typeof obj === 'object' && obj !== null) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (this.isDateString(value) || value instanceof Date) {
            obj[key] = this.formatDate(value);
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            obj[key] = this.convertDateToString(obj[key])
          }
        }
      }
      return obj;
    }
  }

  formatDate(date) {
    if (date) {
      return formatISO(new Date(date), { representation: 'date' });
    }
    return date;
  }

  isDateString(value: any): boolean {
    const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?(Z|[+-]\d{2}:\d{2})?$/;

    if (!isoDateTimeRegex.test(value)) {
      return false;
    }
    const valueWithoutTimeZone = value.replace(/(Z)$/, '');
    return isValid(parseISO(valueWithoutTimeZone));
  }

  createCheckDrivingLicenseFilter(dateOfBirth: Date, expirationDateDocument: Date, drivingLicenseList: Array<AnagDrivingLicense>): AnagCheckDrivingLicenseRequest {
    const req = new AnagCheckDrivingLicenseRequest();
    req.birthDate = dateOfBirth;
    req.licenseExpirationDate = expirationDateDocument;
    req.drivingLicenses = new Array<AnagDrivingLicenseRequest>();
    drivingLicenseList.forEach(drivingLicense => {
      const anagDrivingLicenseReq = new AnagDrivingLicenseRequest();
      anagDrivingLicenseReq.idTypeDrivingLicense = drivingLicense?.idTypeDrivingLicense.toString();
      anagDrivingLicenseReq.dqcManagement = drivingLicense?.bdriverQualifCard;
      anagDrivingLicenseReq.dstartDrivingLicense = drivingLicense?.dstartDrivingLicense;
      anagDrivingLicenseReq.dendDrivingLicense = drivingLicense?.dendDrivingLicense;
      req.drivingLicenses.push(anagDrivingLicenseReq);
    })
    this.convertDateToString(req);
    return req;
  }

  checkNormalize(isNormalizeOptional: boolean, ubicationForm: UntypedFormGroup ) {
    return !isNormalizeOptional && !ubicationForm.get('addressForm').get('formatAddress').value;
  }

  isDocumentNumberUnique(partyDocuments: AnagDocument[], documentForm: UntypedFormGroup): boolean {
    let isDocumentNumberUnique = true;
    if (partyDocuments && partyDocuments.length > 0 && documentForm.get('documentType').value) {
      let filterDocByType = partyDocuments.filter(doc => doc.documentType.codice === documentForm.get('documentType').value);
      if (filterDocByType.length) {
        isDocumentNumberUnique = filterDocByType.every(doc => doc.documentNumber !== documentForm.get('documentNumber').value);
      }
    }
    return isDocumentNumberUnique;
  }
}

<div>
    <!-- style="min-height: 120em;" -->
    <form [formGroup]="modPolicyForm">
        <div class="card shadow">

            <div class="row">
                <span class="rgifont rgi-dettagli_tecnici font-size: 40px;"></span>&nbsp;
                <label ng-bind="label" style="color:#72BCFF; font-size: 20px;">{{ '_CLAIMS_._GENERAL DATA' | translate }}</label>
            </div>
            <div class="container-fluid table-white">
                <div class="row">
                    <div class="col-xs-4 col-md-4" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._COMPANY' | translate
                            }}:</label><br>
                        <label ng-bind="label">{{modPolicy.company}} {{descrCompany}}</label>
                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._POLICY_NUMBER' | translate
                            }}:</label>
                        <input type="text" class="form-control text-uppercase" name="policyNumber"
                            attr.data-qa="external-portfolio-policyNumber-input" formControlName="policyNumber" />

                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._ORIGIN' | translate
                            }}:</label><br>
                        &nbsp;&nbsp;<label ng-bind="label">{{modPolicy.origin}}</label>
                    </div>

                </div>
                <div class="row">
                    <div class="col-xs-4 col-md-4" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._DATE_OF_INSERTION' |
                            translate }}:</label><br>
                        <label ng-bind="label">{{modPolicy.insertDate | date:'dd/MM/yyyy'}}</label>
                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._LAST_UPDATE' | translate
                            }}:</label><br>
                        <label ng-bind="label">{{modPolicy.updateDate | date:'dd/MM/yyyy'}}</label>
                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 14px;" *ngIf="!modPolicy.canceled">
                        <br> &nbsp;&nbsp;
                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 14px; color: red;" *ngIf="modPolicy.canceled">
                        <br>{{'_CLAIMS_._CANCELED_POLICY' | translate}}
                    </div>
                </div>
            </div>


            <div class="row">
                <span class="rgifont rgi-dettagli_tecnici font-size: 40px;"></span>&nbsp;
                <label ng-bind="label" style="color:#72BCFF; font-size: 20px;">{{ '_CLAIMS_._POLICY_DATA' | translate }}</label>
            </div>

            <div class="container-fluid table-white">
                <div class="form-group">
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._EFFECT_DATE' | translate }}:</label><br>
                        <label ng-bind="label">{{modPolicy.assetList[0].effectDate | date:'dd/MM/yyyy'}}&nbsp; </label>
                    </div>
                    <div class="col-xs-4 col-md-4">
                        <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._EXPIRATION_DATE' | translate
                            }}:</label><br>
                        <label ng-bind="label">{{modPolicy.assetList[0].expirationDate |
                            date:'dd/MM/yyyy'}}&nbsp;</label>
                    </div>
                    <div class="col-xs-4 col-md-4">
                        <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._EMISSION_DATE' | translate }}:</label><br>
                        <label ng-bind="label">{{modPolicy.emissionDate | date:'dd/MM/yyyy'}}&nbsp;</label>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <label ng-bind="label">{{ '_CLAIMS_._POLICY_REPLACED_NUMBER' | translate }} </label>
                        <input type="text" class="form-control text-uppercase" name="policyReplacedNumber"
                            attr.data-qa="external-portfolio-policyReplacedNumber-input"
                            formControlName="policyReplacedNumber" />

                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <label ng-bind="label">{{ '_CLAIMS_._POLICY_REPLACEMENT_NUMBER' | translate }}</label>
                        <input type="text" class="form-control text-uppercase" name="policyReplacementNumber"
                            attr.data-qa="external-portfolio-policyReplacementNumber-input"
                            formControlName="policyReplacementNumber" />

                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <label ng-bind="label">{{ '_CLAIMS_._ADMINISTRATIVE_REGULARITY' | translate }}</label>
                        <select class="core-select form-control" id="regAmm" name="regAmm" formControlName="regAmm"
                            attr.data-qa="external-portfolio-regAmm-input">
                            <option value="S">SI</option>
                            <option value="N">NO</option>
                            <option value="NP">NP</option>
                        </select>

                        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <pnc-datepicker label="{{ '_CLAIMS_._SUSPENSION_DATE_FROM' | translate }}"
                            customFormControlName="suspensionDateFrom" [customFormGroup]="modPolicyForm"
                            attr.data-qa="external-portfolio-suspensionDateFrom-input">
                        </pnc-datepicker>
                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <pnc-datepicker label="{{ '_CLAIMS_._SUSPENSION_DATE_TO' | translate }}"
                            customFormControlName="suspensionDateTo" [customFormGroup]="modPolicyForm"
                            attr.data-qa="external-portfolio-suspensionDateTo-input">
                        </pnc-datepicker>
                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <pnc-datepicker label="{{ '_CLAIMS_._DATE_OF_COLLECTION' | translate }}" customFormControlName="releaseDate"
                            [customFormGroup]="modPolicyForm" attr.data-qa="external-portfolio-releaseDate-input">
                        </pnc-datepicker>
                    </div>

                </div>
                <div class="form-group">
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <pnc-datepicker label="{{ '_CLAIMS_._PREMIUM_DATE_FROM' | translate }}" customFormControlName="premiumFrom"
                            [customFormGroup]="modPolicyForm" attr.data-qa="external-portfolio-premiumFrom-input">
                        </pnc-datepicker>
                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <pnc-datepicker label="{{ '_CLAIMS_._PREMIUM_DATE_TO' | translate }}" customFormControlName="premiumTo"
                            [customFormGroup]="modPolicyForm" attr.data-qa="external-portfolio-premiumTo-input">
                        </pnc-datepicker>
                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <label ng-bind="label">{{ '_CLAIMS_._OUT_OF_RANGE' | translate }} </label><br>
                        <label class="btn rgi-radio-btn btn-primary" [ngClass]="attivaOutRangeS"
                            (click)="valOutRange('S',false)">{{'_CLAIMS_._YES' | translate}}</label>
                        <label class="btn rgi-radio-btn btn-primary" [ngClass]="attivaOutRangeN"
                            (click)="valOutRange('N',false)"> {{'_CLAIMS_._NO' | translate}}</label>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12 col-md-12" style="font-size: 12px;"> &nbsp;</div>
                </div>
                <div class="form-group">
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <label ng-bind="label">{{ '_CLAIMS_._FRACTIONING' | translate }}</label>
                        <input type="text" class="form-control" name="policySplitting"
                            attr.data-qa="external-portfolio-policySplitting-input" formControlName="policySplitting" />
                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <label ng-bind="label" style="margin-right: 1%; ">{{ '_CLAIMS_._DIARIA_DAYS' | translate }}</label>
                        <input type="number" class="form-control" name="ggDiaria"
                            attr.data-qa="external-portfolio-ggDiaria-input" formControlName="ggDiaria" />
                    </div>
                    <div class="col-xs-4 col-md-4">
                        <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._ARREARS_DAYS' | translate }}</label>
                        <input type="number" class="form-control" name="ggMora"
                            attr.data-qa="external-portfolio-ggMora-input" formControlName="ggMora" />
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <label ng-bind="label">{{ '_CLAIMS_._PRODUCT_CODE' | translate }} </label>
                        <input type="text" class="form-control text-uppercase" name="productCode"
                            attr.data-qa="external-portfolio-productCode-input" formControlName="productCode" />

                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <label ng-bind="label">{{ '_CLAIMS_._PRODUCT_DESCRIPTION' | translate }}</label>
                        <input type="text" class="form-control text-uppercase" name="productDescription"
                            attr.data-qa="external-portfolio-productDescription-input"
                            formControlName="productDescription" />

                    </div>

                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <label ng-bind="label">{{ '_CLAIMS_._DIARIA' | translate }}</label>
                        <input type="number" class="form-control" name="diaria"
                            attr.data-qa="external-portfolio-diaria-input" formControlName="diaria" />

                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-4 col-md-4" style="font-size: 12px; ">
                        <label ng-bind="label">{{ '_CLAIMS_._ASSET_TYPE' | translate }}</label>
                        <select class="core-select form-control" id="assetInsuredType" name="assetInsuredType"
                            formControlName="assetInsuredType" attr.data-qa="external-portfolio-assetInsuredType-input">
                            <option value="ALTRO">{{ '_CLAIMS_._OTHER' | translate }}</option>
                            <option value="UBICAZIONE">{{ '_CLAIMS_._LOCATION' | translate }}</option>
                            <option value="VEICOLO">{{ '_CLAIMS_._VEHICLE' | translate }}</option>
                        </select>

                        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <label ng-bind="label">{{ '_CLAIMS_._ASSET_INSURED' | translate }}</label>
                        <input type="text" class="form-control text-uppercase" name="assetInsured"
                            attr.data-qa="external-portfolio-assetInsured-input" formControlName="assetInsured" />

                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <label ng-bind="label">{{ '_CLAIMS_._AGENCY' | translate }}</label>
                        <input type="text" class="form-control text-uppercase" name="agency"
                            attr.data-qa="external-portfolio-agency-input" formControlName="agency" />

                    </div>

                </div>
                <div class="form-group">
                    <div class="col-xs-12 col-md-12" style="font-size: 12px;"> &nbsp;</div>
                </div>
                <div class="form-group">
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <label ng-bind="label">{{ '_CLAIMS_._CLAIM_BRANCH' | translate }}</label>
                        <input type="text" class="form-control text-uppercase" name="branch"
                            attr.data-qa="external-portfolio-branch-input" formControlName="branch" />

                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <label ng-bind="label">{{ '_CLAIMS_._TERM_CODE' | translate }}</label>
                        <input type="text" class="form-control" name="codTerm"
                            attr.data-qa="external-portfolio-codTerm-input" formControlName="codTerm" />

                    </div>
                    <div class="col-xs-4 col-md-4" style="font-size: 12px;">
                        <label ng-bind="label">{{ '_CLAIMS_._RISK' | translate }}</label>
                        <input type="text" class="form-control text-uppercase" name="riskObject"
                            attr.data-qa="external-portfolio-riskObject-input" formControlName="riskObject" />

                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <span class="rgifont rgi-dettagli_tecnici font-size: 40px;"></span>&nbsp;
                <label ng-bind="label" style="color:#72BCFF; font-size: 20px;">{{ '_CLAIMS_._ADDITIONAL_DATA' | translate }}</label>
            </div>
            <div class="container-fluid table-white" data-qa="transactions-list">

                <table class="table-responsive table coreDT">
                    <thead>
                        <tr class="head-result thDataTable">

                            <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                <span attr.data-qa="extrainfo-typr-label">{{ '_CLAIMS_._TYPE' | translate }}</span>
                            </td>

                            <!-- <td class="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                <span attr.data-qa="extrainfo-value-label">{{ '_CLAIMS_._VALUE' | translate }}</span>
                            </td> -->

                            <td class="col-xl-1 col-lg-1">
                                <span attr.data-qa="extrainfo-desc-label">{{ '_CLAIMS_._DESCRIPTION' | translate }}</span>
                            </td>

                            <!-- <td class="col-xl-1 col-lg-1">
                                <span attr.data-qa="extrainfo-code-label">{{ '_CLAIMS_._CODE' | translate }}</span>
                            </td> -->

                            <td class="col-md-1 col-lg-1">
                                <span attr.data-qa="tot-amount-label">{{ '_CLAIMS_._AMOUNT' | translate }}</span>
                            </td>

                            <td class="col-xl-1 col-lg-1">
                                <span attr.data-qa="extrainfo-day-label">{{ '_CLAIMS_._DAYS' | translate }}</span>
                            </td>

                            <td class="col-xl-1 col-lg-1">
                                <span attr.data-qa="extrainfo-date-label">{{ '_CLAIMS_._SELECTABLE' | translate }}</span>
                            </td>
 <!--
                            <td class="col-xl-1 col-lg-1">
                                <span attr.data-qa="extrainfo-date-label">{{ '_CLAIMS_._END_DATE' | translate }}</span>
                            </td> -->

                            <td class="col-xl-1 col-lg-1">
                                <span attr.data-qa="extrainfo-checked-label"> </span>
                            </td>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let extrainfo of listExtraInfo; let even = even; let odd = odd; let extrainfoIndex = index"
                            [ngClass]=" extrainfo.level == 1 ? 'unit-color' :  extrainfo.level == 2 ? 'rischio-color' : 'void'" class="table-row"
                            attr.data-qa="extrainfo-result-{{extrainfoIndex}}"  >

                            <!-- <td class="col-xl-1 col-lg-1">
                                <span attr.data-qa="extrainfo-type-{{extrainfoIndex}}">{{ extrainfo.type }}</span>
                            </td> -->

                            <td class="col-xl-1 col-lg-1">
                                <span attr.data-qa="extrainfo-value-{{extrainfoIndex}}">{{ extrainfo.value }}</span>
                            </td>

                            <td class="col-xl-1 col-lg-1">
                                <span attr.data-qa="extrainfo-desc-{{extrainfoIndex}}">{{ extrainfo.desc}}</span>
                            </td>

                            <!-- <td class="col-md-1 col-lg-1">
                                <span attr.data-qa="extrainfo-code-{{extrainfoIndex}}">{{ extrainfo.code }}</span>
                            </td> -->

                            <td class="col-md-1 col-lg-1">
                                <span attr.data-qa="extrainfo-amount-{{extrainfoIndex}}">{{ extrainfo.amount |
                                    currency:'EUR':true}}</span>
                            </td>

                            <td class="col-md-1 col-lg-1">
                                <span attr.data-qa="extrainfo-day-{{extrainfoIndex}}">{{ extrainfo.day }}</span>
                            </td>

                            <td class="col-xl-1 col-lg-1">
                                <span attr.data-qa="extrainfo-start-{{extrainfoIndex}}">{{ extrainfo.selectable }}</span>
                            </td>
<!-- 
                            <td class="col-xl-1 col-lg-1">
                                <span attr.data-qa="extrainfo-end-{{extrainfoIndex}}">{{ extrainfo.end | date:'dd/MM/yyyy'}}</span>
                            </td> -->

                            <td class="col-md-1 col-lg-1">
                                <span attr.data-qa="extrainfo-checked-{{extrainfoIndex}}">
                                    <!-- {{ extrainfo.checked }}                   -->
                                </span> 
                            </td>

                        </tr>
                    </tbody>
                </table>

            </div>
            <br>


            <div class="row">
                <span class="rgifont rgi-utenti font-size: 40px;"></span>&nbsp;
                <label ng-bind="label" style="color:#72BCFF; font-size: 20px;">{{ '_CLAIMS_._SUBJECTS' | translate }}</label>
            </div>

            <div class="container-fluid table-white">
                <div class="row">
                    <label _ngcontent-eor-c3="" ng-bind="label"
                        style="background-color: #1569C7; color:white; font-size: 20px; width: 93%;  height:30px;">
                        <span style="font-size: 14px;">{{ '_CLAIMS_._CONTRACTOR' | translate }}: </span>
                        <span style="font-size: 20px; "
                            class="text-uppercase">{{this.modPolicyForm.controls.denomination.value}}
                            {{this.modPolicyForm.controls.firstName.value}}</span></label>
                    <button _ngcontent-eor-c3="" class="btn-actions pull-right"
                        style="margin:0px 0px 0px; background-color: #d1d1d1; color:White; border-color: white; height:31px; width: 7%;"
                        *ngIf="consultazione" disabled>{{ '_CLAIMS_._BUTTONS_._EDIT' | translate }}</button>
                    <button _ngcontent-eor-c3="" class="btn-actions pull-right"
                        style="margin:0px 0px 0px; background-color:  #1569C7; color:White; border-color: white; height:31px; width: 7%;"
                        *ngIf="!consultazione" (click)="selectSubject(modPolicy, 'CNT')">{{ '_CLAIMS_._BUTTONS_._EDIT' | translate
                        }}</button>
                </div>


                <div class="row">
                    <div class="col-xs-4 col-md-4">
                        <label ng-bind="label" style="margin-right: 1%; border-left:#1569C7;">{{ '_CLAIMS_._FISCAL_CODE__VAT_CODE' |
                            translate }}:</label>
                        <label ng-bind="label"
                            class="text-uppercase">{{this.modPolicyForm.controls.fiscalCodeVat.value}}</label>
                    </div>
                    <div class="col-xs-4 col-md-4">
                        <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._NATION' | translate }}:</label>
                        <label ng-bind="label"
                            class="text-uppercase">{{this.modPolicyForm.controls.country.value}}</label>
                    </div>
                    <div class="col-xs-4 col-md-4">
                        <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._PROVINCE' | translate }}:</label>
                        <label ng-bind="label"
                            class="text-uppercase">{{this.modPolicyForm.controls.province.value}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 col-md-4">
                        <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._MUNICIPALITY' | translate }}:</label>
                        <label ng-bind="label" class="text-uppercase"
                            style="font-weight: bold; border: 1px; border-right: #1569C7;">{{this.modPolicyForm.controls.municipality.value}}</label>
                    </div>
                    <div class="col-xs-4 col-md-4">
                        <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._ADDRESS' | translate }}:</label>
                        <label ng-bind="label"
                            class="text-uppercase">{{this.modPolicyForm.controls.address.value}}</label>
                    </div>
                    <div class="col-xs-4 col-md-4">
                        <label ng-bind="label" style="margin-right: 1%; border-left:#1569C7;">{{ 'Postal Code' |
                            translate }}:</label>
                        <label ng-bind="label">{{this.modPolicyForm.controls.postalCode.value}}</label>
                    </div>
                </div>

                <div class="row">
                    <label _ngcontent-eor-c3="" ng-bind="label"
                        style="background-color: #1569C7; color:white;  width: 93%;  height:30px;">
                        <span style="font-size: 14px; ">{{ '_CLAIMS_._INSURED' | translate }}: </span>
                        <span style="font-size: 20px; "
                            class="text-uppercase">{{this.modPolicyForm.controls.denominationInsured.value}}
                            {{this.modPolicyForm.controls.firstNameInsured.value}}</span>
                    </label>
                    <!-- <button class="btn-actions pull-right" (click)="selectSubject(modPolicy)">{{ '_CLAIMS_._EDIT' | translate }}</button>  -->
                    <button _ngcontent-eor-c3="" class="btn-actions pull-right"
                        style="margin:0px 0px 0px; background-color:  #d1d1d1; color:White; border-color: white; height:31px; width: 7%;"
                        *ngIf="consultazione" disabled>{{ '_CLAIMS_._BUTTONS_._EDIT' | translate }}</button>
                    <button _ngcontent-eor-c3="" class="btn-actions pull-right"
                        style="margin:0px 0px 0px; background-color:  #1569C7; color:White; border-color: white; height:31px; width: 7%;"
                        *ngIf="!consultazione" (click)="selectSubject(modPolicy,'ASS')">{{ '_CLAIMS_._BUTTONS_._EDIT' | translate
                        }}</button>
                </div>

                <div class="row">
                    <div class="col-xs-4 col-md-4">
                        <label ng-bind="label" style="margin-right: 1%; border-left:#1569C7;">{{ '_CLAIMS_.__TAX_CODE__VAT_NUMBER' |
                            translate }}:</label>
                        <label ng-bind="label"
                            class="text-uppercase">{{this.modPolicyForm.controls.fiscalCodeVatInsured.value}}</label>
                    </div>
                    <div class="col-xs-4 col-md-4">
                        <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._NATION' | translate }}:</label>
                        <label ng-bind="label"
                            class="text-uppercase">{{this.modPolicyForm.controls.countryInsured.value}}</label>
                    </div>
                    <div class="col-xs-4 col-md-4">
                        <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._PROVINCE' | translate }}:</label>
                        <label ng-bind="label"
                            class="text-uppercase">{{this.modPolicyForm.controls.provinceInsured.value}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-4 col-md-4">
                        <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._MUNICIPALITY' | translate }}:</label>
                        <label ng-bind="label" class="text-uppercase"
                            style="font-weight: bold; border: 1px; border-right: #1569C7;">{{this.modPolicyForm.controls.municipalityInsured.value}}</label>
                    </div>
                    <div class="col-xs-4 col-md-4">
                        <label ng-bind="label" style="margin-right: 1%;">{{ '_CLAIMS_._ADDRESS' | translate }}:</label>
                        <label ng-bind="label"
                            class="text-uppercase">{{this.modPolicyForm.controls.addressInsured.value}}</label>
                    </div>
                    <div class="col-xs-4 col-md-4">
                        <label ng-bind="label" style="margin-right: 1%; border-left:#1569C7;">{{ '_CLAIMS_._POSTAL_CODE' |
                            translate }}:</label>
                        <label ng-bind="label">{{this.modPolicyForm.controls.postalCodeInsured.value}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <span class="rgifont rgi-dettagli_tecnici font-size: 20px;"></span>&nbsp;
                <label ng-bind="label" style="color:#72BCFF; font-size: 20px;">{{ '_CLAIMS_._POLICY_CONDITION' | translate
                    }}</label>
            </div>

            <div class="container-fluid table-white">
                <div class="row">
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ 'CLAIMS_._POLICY_CEILING' |
                            translate }}:</label>
                        <input type="number" class="form-control" name="policyCeiling"
                            attr.data-qa="external-portfolio-policyCeiling-input" formControlName="policyCeiling" />

                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._DEDUCTIBLE' | translate
                            }} (%):</label>
                        <input type="number" class="form-control" name="uncovered"
                            attr.data-qa="external-portfolio-uncovered-input" formControlName="uncovered" />

                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._MINIMUM_UNCOVERED_VALUE'
                            | translate }}:</label>
                        <input type="number" class="form-control" name="miniDiscoveredValue"
                            attr.data-qa="external-portfolio-miniDiscoveredValue-input"
                            formControlName="miniDiscoveredValue" />

                    </div>
                    <div class="col-xs-3 col-md-3" style="font-size: 14px;">
                        <label ng-bind="label" style="margin-right: 1%; font-weight: bold;">{{ '_CLAIMS_._EXCESS' | translate
                            }}</label>
                        <input type="number" class="form-control" name="deductible"
                            attr.data-qa="external-portfolio-deductible-input" formControlName="deductible" />

                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-4 col-md-4"> <span class=""></span>&nbsp;</div>
                    <div class="col-xs-4 col-md-4"> <span class=""></span>&nbsp;</div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div [ngClass]="classMsg" *ngIf="errore" role="alert">{{ errorMessage | translate }} </div>
            <div [ngClass]="classMsg2" *ngIf="errorMessage2" role="alert">{{ errorMessage2 | translate }} </div>
            <div *ngIf="!errore">&nbsp; </div>
        </div>

        <div class="btn-group btn-group-justified btn-group-justified-claim" *ngIf="!consultazione">
            <div class="btn-group">
                <button class="btn btn-warning pull-right" (click)="back()">{{ '_CLAIMS_._BUTTONS_._BACK' | translate }}</button>
            </div>
            <div class="btn-group" *ngIf="!isDisabilitato">
                <button class="btn btn-warning pull-right" (click)="annulla()">{{ '_CLAIMS_._BUTTONS_._CANCEL' | translate }}</button>
            </div>
            <div class="btn-group">
                <button class="btn btn-warning pull-right" (click)="conferma()">{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
            </div>
        </div>
        <div class="btn-group btn-group-justified btn-group-justified-claim" *ngIf="consultazione">
            <div class="btn-group">
                <button class="btn btn-warning pull-right" (click)="back()">{{ '_CLAIMS_._BUTTONS_._BACK' | translate }}</button>
            </div>
        </div>
        <p>
            <ngb-progressbar type="warning" [value]="valueBar" height=".5rem">&nbsp;</ngb-progressbar>
        </p>
    </form>
</div>
<div class="rgi-ui-datepicker-calendar-control">
  <!-- focus when keyboard tab (http://kizu.ru/en/blog/keyboard-only-focus/#x) -->
  <button class="rgi-ui-datepicker-control rgi-ui-datepicker-control-button rgi-ui-datepicker-control-arrow-button"
          type="button" tabindex="0"
          [style.visibility]="showControlArrows? 'visible': 'hidden'"
          [disabled]="!prevButtonEnabled()"
          [attr.aria-label]="prevButtonLabel | translate"
          (click)="previousClicked()">
        <span class="rgi-ui-datepicker-control-content rgi-ui-datepicker-control-button-content rgi-ui-icon-left-arrow"
              tabindex="-1">
        </span>
  </button>
  <div class="rgi-ui-datepicker-calendar-control-content">
    <button class="rgi-ui-datepicker-control rgi-ui-datepicker-control-button rgi-ui-datepicker-control-period-button"
            type="button" tabindex="0"
            [attr.aria-label]="periodButtonLabel | translate"
            (click)="toggleViews()">
            <span class="rgi-ui-datepicker-control-content rgi-ui-datepicker-control-button-content" tabindex="-1">
                {{periodButtonText}}

              <span class="rgi-ui-datepicker-control-button-arrow rgi-ui-icon-up-arrow2"
                    [style.transform]="'rotate(' + (isMonthView? 0 : 180) +'deg)'">
                </span>
            </span>
    </button>
  </div>
  <button class="rgi-ui-datepicker-control rgi-ui-datepicker-control-button rgi-ui-datepicker-control-arrow-button"
          type="button" tabindex="0"
          [style.visibility]="showControlArrows? 'visible': 'hidden'"
          [disabled]="!nextButtonEnabled()"
          [attr.aria-label]="nextButtonLabel | translate"
          (click)="nextClicked()">
        <span class="rgi-ui-datepicker-control-content rgi-ui-datepicker-control-button-content rgi-ui-icon-right-arrow"
              tabindex="-1">
        </span>
  </button>
</div>
<div class="rgi-ui-datepicker-calendar-main" cdkMonitorSubtreeFocus [ngSwitch]="currentView" tabindex="-1">
  <rgi-rx-calendar-month-view
    *ngSwitchCase="'month'"
    [pickerMoment]="pickerMoment"
    [firstDayOfWeek]="firstDayOfWeek"
    [selected]="selected"
    [selecteds]="selecteds"
    [selectMode]="selectMode"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [dateFilter]="dateFilter"
    [hideOtherMonths]="hideOtherMonths"
    (pickerMomentChange)="handlePickerMomentChange($event)"
    (selectedChange)="dateSelected($event)"
    (userSelection)="userSelected()"></rgi-rx-calendar-month-view>

  <rgi-rx-calendar-year-view
    *ngSwitchCase="'year'"
    [pickerMoment]="pickerMoment"
    [selected]="selected"
    [selecteds]="selecteds"
    [selectMode]="selectMode"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [dateFilter]="dateFilter"
    (keyboardEnter)="focusActiveCell()"
    (pickerMomentChange)="handlePickerMomentChange($event)"
    (monthSelected)="selectMonthInYearView($event)"
    (change)="goToDateInView($event, 'month')"></rgi-rx-calendar-year-view>

  <rgi-rx-calendar-multi-year-view
    *ngSwitchCase="'multi-years'"
    [pickerMoment]="pickerMoment"
    [selected]="selected"
    [selecteds]="selecteds"
    [selectMode]="selectMode"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [dateFilter]="dateFilter"
    (keyboardEnter)="focusActiveCell()"
    (pickerMomentChange)="handlePickerMomentChange($event)"
    (yearSelected)="selectYearInMultiYearView($event)"
    (change)="goToDateInView($event, 'year')"></rgi-rx-calendar-multi-year-view>
</div>

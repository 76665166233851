import {Component, EventEmitter, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ClauseComponent} from '../clause/clause.component';
import {CustomModalService} from '../custom-modal.service';
import {Clause} from '../models/domain-models/clause';
import {ContractClauseModalComponent} from './clause-modal/contract-clause-modal.component';
import {ContractClauseService} from './contract-clause-service';

@Component({
  selector: 'mic-contract-clause',
  templateUrl: '../clause/clause.component.html',
  styleUrls: ['../clause/clause.component.scss']
})

export class ContractClauseComponent extends ClauseComponent {

  @Output() recalculatePremium: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventPropagation = new EventEmitter<any>();


  constructor(
    protected clauseService: ContractClauseService,
    protected modalService: CustomModalService,
    protected translate: TranslateService) {

    super(clauseService, modalService, translate);
  }

  updateClauseAndReloadPremium() {
    this.clauseService.updateAssetClauses(this.clauseList).subscribe((response) => {
      this.recalculatePremium.emit();
    });
    this.dataChanged = false;
  }

  decodeClause(text) {
    if (text) {
      return decodeURIComponent(text);
    } else {
      return '';
    }
  }

  openClause(clause: Clause) {
    if (!clause) {
      return;
    }
    clause = JSON.parse(JSON.stringify(clause));
    this.clauseService.setClause(clause);
    const modalClause = this.modalService.openModal(this.openClauseModalViewContainerRef, ContractClauseModalComponent,
      null, () => {
        let performCall = false;
        this.clauseList.forEach(
          (currentClause) => {
            if (currentClause.code === clause.code) {
              if (!modalClause.instance.textOutput || currentClause.text === modalClause.instance.textOutput) {
                performCall = false;
              } else {
                currentClause.text = modalClause.instance.textOutput;
                performCall = true;
              }
            }
          }
        );
        if (performCall) {
          this.dataChanged = true;
          this.updateClauseAndReloadPremium();
        }
      });
  }

  toggleClause(clause: Clause) {
    super.toggleClause(clause);
    if (this.isClauseEnabled(clause)) {
      this.updateClauseAndReloadPremium();
    }
  }

  isFormChanged(): boolean {
    return this.clauseForm.dirty || this.dataChanged;
  }
}

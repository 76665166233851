import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ModalService, RgiRxTableComponent} from '@rgi/rx/ui';
import {ReIssueAddPolicyModalComponent} from './re-issue-add-policy-modal/re-issue-add-policy-modal.component';
import {Company} from '../../../../models/domain-models/co-insurance';
import {PolicyGenericDto} from '../../../../models/pc-portfolio-models/policy-generic-dto';
import {ReIssueProductsConfigEnum} from '../../../../enums/re-issue-products-config-enum';

@Component({
  selector: 're-issue-generic-accordion-with-modal',
  templateUrl: './re-issue-generic-accordion-with-modal.component.html',
  styleUrls: ['./re-issue-generic-accordion-with-modal.component.css']
})
export class ReIssueGenericAccordionWithModalComponent implements OnChanges {
  @Input() type: string;
  @Input() tableData$: PolicyGenericDto[];
  @Input() companies: Company[];
  @Input() isChecked: boolean;
  checkboxConfig$: number;

  @Input() set tableData(value: PolicyGenericDto[]) {
    this.tableData$ = value;
    this.tableRenderRows();
    this.emitUpdatePropertiesForm();
  }

  @Input() set checkboxConfig(value: number) {
    this.checkboxConfig$ = value;
    if (this.tableData$ && this.tableData$.length > 0) {
      this.isChecked = true;
    }
    if (this.isChecked == null) {
      switch (value) {
        case ReIssueProductsConfigEnum.UNCHEKED_DEFAULT_EDITABLE:
          this.isChecked = false;
          break;
        case ReIssueProductsConfigEnum.CHECKED_NOT_EDITABLE:
        case ReIssueProductsConfigEnum.CHEKED_DEFAULT_EDITABLE:
          this.isChecked = true;
          break;
      }
    }
    this.emitUpdatePropertiesForm();
  }
  @Output() searchPolicy: EventEmitter<PolicyGenericDto> = new EventEmitter<PolicyGenericDto>();
  @Output() deletePolicy: EventEmitter<number> = new EventEmitter<number>();
  @Output() updatePropertiesForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteAllPolicies: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(RgiRxTableComponent) table: RgiRxTableComponent<any>;

  displayedColumns: string[] = [ 'company', 'policyNumber', 'expireDate', 'actions'];

  constructor(private modalService: ModalService) {
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableData && this.table) {
      this.tableData$ = changes.tableData.currentValue;
      this.table.renderRows();
    }
  }
  openAddPolicyModal(row: PolicyGenericDto) {
    const data: any = {
      companies : this.companies,
      tableData : this.tableData$,
      row : row || new PolicyGenericDto(),
      type : this.type
    };
    const {modal} = this.modalService.openComponent(ReIssueAddPolicyModalComponent, data);
    modal.onClose.subscribe( (policy: PolicyGenericDto) => {
      if (policy) {
        this.emitAddPolicy(policy);
      }
    });
  }

  private emitAddPolicy(policy: PolicyGenericDto) {
    this.searchPolicy.emit(policy);
    this.tableRenderRows();
  }

  delete(row: PolicyGenericDto) {
    this.deletePolicy.emit(row.objectId);
    this.tableRenderRows();
  }

  emitUpdatePropertiesForm() {
    const data: any = {
      isFormValid : !this.isChecked || (this.isChecked && this.tableData$.length > 0),
      isChecked : this.isChecked
    };
    this.updatePropertiesForm.emit(data);
  }

  isSectionHidden() {
    return this.checkboxConfig$ === ReIssueProductsConfigEnum.HIDDEN;
  }

  isCheckBoxEditable() {
    return this.checkboxConfig$ === ReIssueProductsConfigEnum.CHEKED_DEFAULT_EDITABLE || this.checkboxConfig$ === ReIssueProductsConfigEnum.UNCHEKED_DEFAULT_EDITABLE;
  }

  tableRenderRows() {
    if (this.table) {
      this.table.renderRows();
    }
  }

  deleteAll() {
    if (!this.isChecked) {
      this.deleteAllPolicies.emit();
    }
  }
}

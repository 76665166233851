import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {PostsalesOperationsService} from '../../services/postsales-operations.service';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OperationsListComponent} from '../../modules/lpc-operations-list/operations-list/operations-list.component';
import {AnagService} from '../../services/anag.service';
import { PV_TOKEN } from '../../models/consts/lpc-consts';

/** deprecated poichè è stata rimossa dalla registrazione in quanto card di test */
@Component({
  selector: 'lpc-life-postsales-card',
  templateUrl: './life-postsales-card.component.html',
  styleUrls: ['./life-postsales-card.component.css'],

})
export class LifePostsalesCardComponent implements OnInit {
  policyNumber: string;

  @Output() eventPropagation = new EventEmitter<string>();

  constructor(
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected translate: TranslationWrapperService,
    protected modalService: NgbModal,
    protected anagService: AnagService) {
  }

  ngOnInit() {
  }

  onSubmit() {
    const modalRef = this.modalService.open(OperationsListComponent, {
      centered: true,
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });

    modalRef.componentInstance.policyNumber = this.policyNumber;
    modalRef.componentInstance.loadData();
  }
}

export enum ErrorLevel {
  warning,
  error
}

export class ErrorMessageFromService {
  level: ErrorLevel;
  description: string;
  otherInfo: any;
}

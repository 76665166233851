import {DiscountFundPeriod} from './discount-fund-period';

export class DiscountFund {
  constructor(
    public discountFundPeriods: Array<DiscountFundPeriod>,
    public discountType: string,
    public objectId: number,
    public shareDiscountFund: boolean,
    public companyId: number,
    public companyCode: string,
    public companyDesc: string,
    public discountFundBranchId: number,
    public discountFundBranchCode: string,
    public discountFundBranchDesc: string,
    public pvId: number,
    public pvDesc: string
  ) {
  }
}

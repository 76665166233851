<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit()" translate>
    RGI_SHARED_COMPONENTS.DOCUMENTS_MANAGER
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button rgi-rx-button color="secondary" (click)="modalClose.emit()">
      <span translate>RGI_SHARED_COMPONENTS.CLOSE</span>
    </button>
  </rgi-rx-panel-footer>
  <rgi-outbound-documents
    [policyNumber]="data.policyNumber"
    [proposalNumber]="data.proposalNumber"
    [movementDesc]="data.movementDesc"
    [movementId]="data.movementId">
  </rgi-outbound-documents>
</rgi-rx-panel>

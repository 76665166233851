export class ApiSetup {

  constructor(
    public id?: string,
    public code?: string,
    public description?: string,
    public regYear?: string,
    public regMounth?: string,
    public progNum?: string
  ) {
  }

}

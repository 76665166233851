import { ActiveRoute } from '@rgi/rx/router';
import { AbstractStateManager, StateStoreService } from '@rgi/rx/state';
import { Observable, of } from 'rxjs';
import { adaptStateFromForm } from '../../adapters/group-policy-utils';
import { DataForSteps } from '../../group-policy-models/group-policy-issue-home';
import { ErrorCode, PaymentsPayload } from '../../group-policy-models/group-policy-issue-policy-data';
import { GroupPolicyStateCluster, GroupPolicyStateSummary } from '../group-policy-state';
import { GPDetailClusterSubStateManager } from '../group-policy-sub-statemanagers/group-policy-cluster-sub-statemanager';
import {
  GroupPolicyStatelessOpClusterService
} from '../group-policy-sub-statemanagers/group-policy-stateless-op-cluster.service';
import { GroupPolicyStatelessOpSummaryService } from './group-policy-stateless-op-summary.service';


/**
 * @author: dmasone
 * @description: Component used for summary state manager
 */
export class GroupPolicyStatemanagerSummary extends AbstractStateManager<GroupPolicyStateSummary> implements GPDetailClusterSubStateManager<GroupPolicyStateSummary> {


  constructor(
    activeRoute: ActiveRoute,
    stateStoreService: StateStoreService,
    protected statelesOps: GroupPolicyStatelessOpSummaryService,
    protected clusterStatelesOps: GroupPolicyStatelessOpClusterService) {
    super(activeRoute, stateStoreService);
    const stateSummaryData = this.stateStoreService.has(this.activeRoute.key) ?
      this.stateStoreService.get<GroupPolicyStateSummary>(this.activeRoute.key) :
      new GroupPolicyStateSummary(this.activeRoute.key);
    const initial$ = this.statelesOps.initSummaryData$(of(stateSummaryData), this.activeRoute);
    this.updateState$(initial$);
  }

  public actionBack(previousStepData: DataForSteps, activeRoute: ActiveRoute, targetRoute: string) {
    const st = this.getCurrentState();
    adaptStateFromForm(st);
    this.updateState$(this.statelesOps.actionBack$(of(st), previousStepData, activeRoute, targetRoute));
  }

  public actionSave(previousStepData: DataForSteps) {
    const st = this.getCurrentState();
    st.errors = [];
    st.type = ErrorCode.BLOCKING;
    adaptStateFromForm(st);
    this.updateState$(this.statelesOps.actionSave$(of(st), previousStepData.resId, previousStepData.node, this.activeRoute));
  }

  public actionProposalSave(previousStepData: DataForSteps, operationCode?: string) {
    const st = this.getCurrentState();
    adaptStateFromForm(st);
    this.updateState$(
      this.statelesOps.actionProposalSave$(
        of(st),
        previousStepData.resId,
        previousStepData,
        this.activeRoute,
        operationCode
      )
    );
  }

  public actionSaveDraftsDocuments(previousStepData: DataForSteps, payload) {
    const st = this.getCurrentState();
    adaptStateFromForm(st);
    this.updateState$(this.statelesOps.actionSaveDraftsDocuments$(of(st), previousStepData.resId, previousStepData.node, payload, this.activeRoute));
  }

  setPaymentMethod(paymentMethod: PaymentsPayload, previousStepData: DataForSteps) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.setPaymentMethod$(of(st), paymentMethod, previousStepData));
  }

  actionSavePolicy(previousStepData: DataForSteps, activeRoute: ActiveRoute) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.savePolicy$(of(st), previousStepData, activeRoute));
  }

  getClusterDetail$(stateCluster: GroupPolicyStateCluster, clusterCode: string): Observable<GroupPolicyStateCluster> {
    return this.clusterStatelesOps.getClusterDetail$(of(stateCluster), clusterCode);
  }

  initStateCluster(codeAsset: string, routeData: DataForSteps): GroupPolicyStateCluster {
    const st = this.getCurrentState();
    const clusters = st.summary.summaryAssets.find(asset => asset.codeAsset === codeAsset).clusters;
    const groupPolicyStateCluster = new GroupPolicyStateCluster(
      this.activeRoute.key, routeData.resId, routeData.node, routeData.idParentSession, codeAsset, clusters);
    groupPolicyStateCluster.proposalNumber = st.summary.summaryAdministrativeData.proposalNumber;
    return groupPolicyStateCluster;
  }

  actionGetAuthorizationData(previousStepData: DataForSteps, activeRoute: ActiveRoute) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.getAuthorization$(of(st), previousStepData, activeRoute));
  }

  actionAlignApplications(previousStepData: DataForSteps, activeRoute: ActiveRoute) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.alignApplications(of(st), previousStepData, activeRoute));
  }

  actionRecoverApplications(previousStepData: DataForSteps, activeRoute: ActiveRoute) {
    const st = this.getCurrentState();
    this.updateState$(this.statelesOps.recoverApplications$(of(st), previousStepData, activeRoute));
  }
}

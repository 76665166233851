import { Component, forwardRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ComponentWithAnagModal } from '../../../interfaces/component-with-anag-modal';
import { EMPTY_STR } from '../../../models/consts/lpc-consts';
import {
  BeneficiaryDefinition,
  Definition,
  InputFieldDefinition,
  PaymentTypeDefinition,
  Role,
  RoleDefinition,
  TextValue
} from '../../../models/postsales-operations-response.model';
import { AnagSubject, PARTY_COMPLETE_KO } from '../../../models/subject.model';
import { AnagService } from '../../../services/anag.service';
import { PlcObjectUtils } from '../../../utils/plc-object-utils';
import { Beneficiary } from '../model/beneficiary';
import {BeneficiaryCathegory, BeneficiaryRole, BeneficiaryType} from '../model/lpc-beneficiary-roles.enum';
import { BeneficiaryChoiceType } from './../model/lpc-beneficiary-roles.enum';
import { Roles, RoleType } from '../../../models/enum/lpc-subjects.enum';
import { TranslationWrapperService } from '../../../i18n/translation-wrapper.service';


@Component({
  selector: 'lpc-beneficiary',
  templateUrl: './lpc-beneficiary.component.html',
  styleUrls: ['./lpc-beneficiary.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpcBeneficiaryComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LpcBeneficiaryComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class LpcBeneficiaryComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator, ComponentWithAnagModal {

  // per raccogliere i valori dei beneficiari dal form utilizzare --> PlcObjectUtils.getBeneficiariesFromForm(definitions, formGroup)
  // da aggiungere nel metodo getTransformedOperationData delle operazioni che contengono i beneficiari
  // controlla la formattazione della percentuale (dopo aver aggiunto la direttiva)
  // guardare getTransformedOperationData di dynamic-operation e change-beneficiaries

  @Input() beneficiaryDefinition: BeneficiaryDefinition;
  @Input() beneficiaryRoles: BeneficiaryRole[];
  @Input() disableBeneficiary: boolean;
  @Input() isREG41Enabled = false;
  @Input() isBENDGEnabled = false;
  @Input() paymentTypes: PaymentTypeDefinition[] = [];
  @Input() benefPositionNumberList: Definition[] = [];

  public visibleBeneficiaryCodes: string[] = ['_ALTRO', '_TSLIB'];
  public beneficiaryListVisible = false;
  public showFreeText = false;
  public irrevocableBeneficiaryAllowed = false;
  private subscriptions: Subscription[] = [];

  public beneficiaryTypeOptions: InputFieldDefinition[] = [];
  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    code: new UntypedFormControl(),
    subjects: new UntypedFormArray([]),
    irrevocable: new UntypedFormControl()
  });

  public value: { [key: string]: BeneficiaryDefinition } = {};
  public roleCodeToAdd: RoleType = Roles.BENEFICIARY;
  partyCompleteError: {subjIdPlusrole: string, messages: string[]}[] = [];

  get irrevocable() {
    return this.formGroupValue.irrevocable;
  }

  get beneficiaries(): UntypedFormArray {
    return this.formGroup.controls.subjects as UntypedFormArray;
  }

  get admittedBeneficiary(): RoleDefinition | undefined {
    const code = this.formGroupValue.code;
    if (!!code) {
      return this.beneficiaryDefinition.admittedBeneficiaries.find(el =>
        code.includes(el.code)
      );
    }
    return undefined;
  }

  get minCardinality(): number | undefined {
    if (!!this.admittedBeneficiary) {
      return Number(this.admittedBeneficiary.minCardinality);
    }
    return undefined;
  }

  get maxCardinality(): number | undefined {
    if (!!this.admittedBeneficiary) {
      return Number(this.admittedBeneficiary.maxCardinality);
    }
    return undefined;
  }

  get REG41CheckBoxVisibility(): boolean {
    return this.isREG41Enabled && this.beneficiaryDefinition.code === BeneficiaryCathegory.VITA;
  }

  get BENDGCheckBoxVisibility(): boolean {
    return this.isBENDGEnabled && this.beneficiaryDefinition.code === BeneficiaryCathegory.MORTE && this.isBeneficiaryPhysicalSubject;
  }

  get isBenefLifeAndFromAnag(): boolean {
    return this.beneficiaryDefinition.code === BeneficiaryCathegory.VITA &&
    this.formGroupValue.code === BeneficiaryChoiceType.ENUM_ETIPOSCELTA_SOGGETTO_ANAGRAFICO;
  }

  get isBeneficiaryPhysicalSubject(): boolean {
    return this.beneficiaryDefinition &&
      (this.beneficiaryDefinition.isPhysicalSubject || this.beneficiaryDefinition.severeDisability !== null);
  }

  public get formGroupValue(): { [key: string]: any } {
    return this.formGroup.getRawValue();
  }

  constructor(protected anagService: AnagService, protected translate: TranslationWrapperService) {}


  /* ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.disableBeneficiary.currentValue);
    if (changes.disableBeneficiary.currentValue === true) {
      this.formGroup.get('code').disable();
    }
  } */

  ngOnInit(): void {
    this.irrevocableBeneficiaryAllowed = this.beneficiaryDefinition.showIrrevocable;
    this.beneficiaryTypeOptions = this.beneficiaryDefinition.admittedBeneficiaries;

    if (this.beneficiaryTypeOptions.length === 1) {
      this.formGroup.get('code').disable();
    }
    this.subscriptions.push(
      this.formGroup.get('code').valueChanges.subscribe(value => {
        this.onChangeSelectedBenef(value);
      }),
      this.formGroup.valueChanges.subscribe(val => {
        this.onChange(val);
      })
    );
    this.subscriptions.push(
      this.formGroup.get('irrevocable').valueChanges.subscribe(value => {
        const beneficiaries: Beneficiary[] = PlcObjectUtils.clone(this.formGroupValue.subjects);
        beneficiaries.forEach(ben => ben.irrevocable = value);
        this.formGroup.get('subjects').patchValue(beneficiaries, {eventEmit: false});
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public onChangeSelectedBenef(value) {
    (this.formGroup.get('subjects') as UntypedFormArray).clear();
    if (this.visibleBeneficiaryCodes.includes(value)) {
      this.beneficiaryListVisible = true;
      if (value === '_TSLIB') {
        (this.formGroup.get('subjects') as UntypedFormArray).push(
          this.getSubjectFormControl(value, {text: EMPTY_STR})
        );
      }
    } else {
      this.beneficiaryListVisible = false;
      (this.formGroup.get('subjects') as UntypedFormArray).push(
        this.getSubjectFormControl(value)
      );
    }
    this.partyCompleteError = [];
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {};
    const subjects: UntypedFormArray = this.formGroup.get('subjects') as UntypedFormArray;
    if (this.formGroup.get('code').value == null) {
      Object.assign(errors, {codeMissing: true});
    }

    if (this.formGroup.get('code').value === '_ALTRO') {
      if (subjects.length < this.minCardinality) {
        const minSubjectsMessage = this.translate.getImmediate(
          'lpc_beneficiary_from_party_req',
          {benefRole: this.translate.getImmediate('lpc_life_detail_beneficiary'), benefCat: this.beneficiaryDefinition.label}
        );
        Object.assign(errors, {minSubjects: true, minSubjectsMessage});
      }

      Object.keys(subjects.controls).forEach(subject => {
        let alreadyHasError = false;
        if (subjects.get(subject).value.value) {
          const subjectWrapper = subjects.get(subject).value;
          // IIAB-462 start
          const countSameIds = Object.keys(subjects.controls)
          .map(item => subjects.get(item).value.value)
          .filter(s => s.id === subjectWrapper.value.id).length;
          if (countSameIds > 1) {
            const benefPresenteMessage =
              this.translate.getImmediate('lpc_benefPresente', {benefRole: this.translate.getImmediate('lpc_life_detail_beneficiary'),
            benefCat: this.beneficiaryDefinition.label, benefName: subjectWrapper.value.name});
            Object.assign(errors, {benefPresente: true, benefPresenteMessage});
            alreadyHasError = true;
          }
          // IIAB-462 end
          if (!alreadyHasError) {
            const isDeathBeneficiary = this.anagService.getLayeredRuleService().isDeathBeneficiary(subjectWrapper.type);
            if (isDeathBeneficiary && subjectWrapper.idAssicurato === subjectWrapper.value.id) {
                const benMorteNoAssicMessage = this.translate.getImmediate('lpc_benMorteNoAssic',
                                              {benefRole: this.translate.getImmediate('lpc_life_detail_beneficiary'),
                benefCat: this.beneficiaryDefinition.label, benefName: subjectWrapper.value.name});
                Object.assign(errors, {benMorteNoAssic: true, benMorteNoAssicMessage});
                alreadyHasError = true;
            }
          }

          if (!alreadyHasError) {
            if (subjectWrapper.value.percentage == null || subjectWrapper.value.percentage === '') {
              const percentageMissingMessage = this.translate.getImmediate('lpc_beneficiary_perc_req',
                { benefRole: this.translate.getImmediate('lpc_life_detail_beneficiary'), benefName: subjectWrapper.value.name,
                  benefCat: this.beneficiaryDefinition.label});
              Object.assign(errors, {percentageMissing: true, percentageMissingMessage});
              alreadyHasError = true;
            }
          }

          if (!alreadyHasError) {
            const percentage = Number(subjectWrapper.value.percentage);
            if (!(percentage > 0 && percentage <= 100))  {
              const percentageOutOfRangeMessage = this.translate.getImmediate('lpc_beneficiary_perc_req_range',
                { benefRole: this.translate.getImmediate('lpc_life_detail_beneficiary'), benefName: subjectWrapper.value.name,
                  benefCat: this.beneficiaryDefinition.label});
              Object.assign(errors, {percentageOutOfRange: true, percentageOutOfRangeMessage});
            }
          }
        }
      });
      if (!!subjects.controls.length) {
        Object.keys(subjects.controls).map(sbj => {
          const subjectErrors = subjects.get(sbj).errors;
          if (!!subjectErrors) {
            if (subjects.get(sbj).errors.underAgeLinkedSubjectRoles) {
              const linkedName = subjects.get(sbj).errors.underAgeLinkedSubjectRoles;
              linkedName.benefCat = this.beneficiaryDefinition.label;
              linkedName.benefRole = this.translate.getImmediate('lpc_life_detail_beneficiary');
              Object.assign(errors, {underAgeLinkedSubjectRoles: linkedName});
            }
            if (subjects.get(sbj).errors.minLinkedSubjectRoles) {
              Object.assign(errors, {minLinkedSubjectRoles: true});
            }
            if (subjects.get(sbj).errors.giuridicalLinkedSubjectRoles) {
              const linkedName = subjects.get(sbj).errors.giuridicalLinkedSubjectRoles;
              linkedName.benefCat = this.beneficiaryDefinition.label;
              linkedName.benefRole = this.translate.getImmediate('lpc_life_detail_beneficiary');
              Object.assign(errors, {giuridicalLinkedSubjectRoles: linkedName});
            }
            if (subjects.get(sbj).errors.linkedSubjectRolesPercentageAmount) {
              Object.assign(errors, {linkedSubjectRolesPercentageAmount: true});
            }
            if (subjects.get(sbj).errors.missingPercentageLinkedSubjectRoles) {
              const linkedName = subjects.get(sbj).errors.missingPercentageLinkedSubjectRoles;
              linkedName.benefCat = this.beneficiaryDefinition.label;
              linkedName.benefRole = this.translate.getImmediate('lpc_beneficial_owner_of_beneficiary');
              Object.assign(errors, {missingPercentageLinkedSubjectRoles: linkedName});
            }
            if (subjects.get(sbj).errors.duplicatesLinkedSubjectRoles) {
              const linkedName = subjects.get(sbj).errors.duplicatesLinkedSubjectRoles;
              Object.assign(errors, {duplicatesLinkedSubjectRoles: linkedName});
            }
            if (subjects.get(sbj).errors.errorMinPercentageLinkedSubjectRoles) {
              const minPercLinked = subjects.get(sbj).errors.errorMinPercentageLinkedSubjectRoles;
              Object.assign(errors, {errorMinPercentageLinkedSubjectRoles: minPercLinked});
            }
            if (subjects.get(sbj).errors.partycomplete) {
              Object.assign(errors, {partycomplete: subjects.get(sbj).errors.partycomplete});
            }
          }

        });
        if (!this.beneficiaryDefinition.showPositionNumber) {
            const percentageAmount: number = Object.keys(subjects.controls)
              .map(subject => {
                if (subjects.get(subject).value.value) {
                  return Number(subjects.get(subject).value.value.percentage);
                }
              })
              .reduce((previous, current) => previous + current);
            if (!!percentageAmount && percentageAmount !== 100.0) {
              Object.assign(errors, {percentageAmount: true});
            }
        } else {
          this.positionNumberValidation(subjects, errors);
        }
      }
    }

    if (this.formGroup.get('code').value === '_TSLIB') {
      Object.keys(subjects.controls).forEach(subject => {
        if (subjects.get(subject).value.value.text === EMPTY_STR || !subjects.get(subject).value.value.text ) {
          Object.assign(errors, {freeTextMissing: true});
        }
      });
    }

    if (!!this.partyCompleteError.length) {
      Object.assign(errors, {partycomplete: this.partyCompleteError});
    }

    if (!!Object.keys(errors).length) {
      return errors;
    }
    return null;
  }

  private positionNumberValidation(subjects: UntypedFormArray, errors: ValidationErrors) {
    const groupedBeneficiaries = this.groupByPositionNumberType(subjects);
    Object.keys(groupedBeneficiaries).forEach(groupType => {
      if (groupType === 'undefined' || groupType === 'null') {
        groupedBeneficiaries[groupType].forEach(benefControl => {
          const message = this.translate.getImmediate('lpc_beneficiary_position_mandatory', {
            benefCat: this.beneficiaryDefinition.label,
            benefName: benefControl.value.value.name
          });
          Object.assign(errors, { groupingMissing: message });
        });
        return;
      }

      const totalPerGroup = this.getTotalPerGroup(groupedBeneficiaries, groupType);
      if (totalPerGroup !== 100.0) {
        const message = this.translate.getImmediate('lpc_invalid_total_position_percent', {
          cat: this.beneficiaryDefinition.label,
          groupType: this.benefPositionNumberList.find(e => e && e.code === groupType).label
        });
        Object.assign(errors, { totalPerGroupError: message });
      }
    });
  }

  private getTotalPerGroup(groupedBeneficiaries: { [key: string]: UntypedFormControl[]; }, groupType: string) {
    return groupedBeneficiaries[groupType]
      .map((benef: UntypedFormControl) => {
        let counter = 0;
        if (benef.value && benef.value.value && benef.value.value.percentage) {
          counter += Number(benef.value.value.percentage);
        }
        return counter;
      }).reduce((accumulator: number, currentValue: number) => {
        return +accumulator + +currentValue;
      });
  }

  public isSelectable(): boolean {
    return this.maxCardinality > this.formGroupValue.subjects.length;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable({emitEvent: false});
    } else {
      this.formGroup.enable({emitEvent: false});
    }
  }

  public writeValue(obj: { [key: string]: any }): void {
    /* if (!!obj.subjects) {
        obj.subjects.forEach(element => {
        delete element.creditPayment;
      });
    } */
    if (obj != null) {
      this.formGroup.patchValue(obj, {emitEvent: false});
      if (!!this.formGroup.get('subjects')) {
        (obj.subjects as []).forEach(subject => {
          (this.formGroup.get('subjects') as UntypedFormArray).push(
            new UntypedFormControl(subject)
          );
        });
      }

      if (this.visibleBeneficiaryCodes.includes(obj.code)) {
        this.beneficiaryListVisible = true;
      } else {
        this.beneficiaryListVisible = false;
      }
      this.value = obj;
    }
  }

  public onChange(obj: Beneficiary) {}

  public trackFn(index: any, item: Beneficiary) {
    if (item && item.value) {
      if ((item.value as Role).id) {
        return (item.value as Role).id;
      } else {
        return index;
      }
    }

    return undefined;
  }

  public getShowPercentage(): boolean {
    const admittedBeneficiary = this.beneficiaryDefinition.admittedBeneficiaries.find(
      el => el.code === '_ALTRO'
    );
    return !!admittedBeneficiary ? admittedBeneficiary.showPercentage : false;
  }

  public openAnagSubjectModal() {
    this.anagService.openSubjectModal(this);
  }

  public receiveAnagSubjectFromModal(subject: AnagSubject) {
    const role: Role = AnagService.subjectToRole(subject, this.roleCodeToAdd);
    this.beneficiaryDefinition.isPhysicalSubject = subject.personType &&
                              subject.personType.codice === BeneficiaryType.PHISICAL;

    if (this.beneficiaryDefinition.code === BeneficiaryCathegory.CEDOLE) {
      role.percentage = '100';
    }
    const severeDIS = this.beneficiaryDefinition.isPhysicalSubject && this.isBENDGEnabled ? false : null;

    this.subscriptions.push(
      this.anagService.checkPartyCompleted(
        Number(subject.objectId),
        Number(subject.idLatestPhotos),
        role.role,
        Number(this.anagService.managementNode)
      ).subscribe(res => {
        if (res.result === PARTY_COMPLETE_KO) {
          const roleDescr = this.beneficiaryDefinition.label;
          this.partyCompleteError.push({
            subjIdPlusrole: `${subject.objectId}-${role.role}`,
            messages: res.outcome.map(m => `${this.translate.getImmediate('lpc_life_detail_beneficiary')} ${roleDescr} ${subject.nominative}: ${m}`)
          });
        } else {
          this.removeMessagesByRole(subject.objectId, role.role);
        }
        this.pushNewBenefToForm(role, severeDIS);
        this.formGroup.updateValueAndValidity();
      })
    );
  }

  private removeMessagesByRole(subjId: string, role: RoleType) {
    this.partyCompleteError = this.partyCompleteError.filter(m => m.subjIdPlusrole !== `${subjId}-${role}`);
  }

  private pushNewBenefToForm(role: Role, severeDIS: boolean) {
    (this.formGroup.get('subjects') as UntypedFormArray).push(
      new UntypedFormControl({
        value: role,
        type: this.beneficiaryDefinition.code,
        code: this.formGroupValue.code,
        idAssicurato: this.beneficiaryDefinition.idAssicurato,
        irrevocable: this.inheritIrrFromTypeBenef(this.beneficiaryDefinition.code),
        expiryCommunication: this.evalExpiryCommCondition(),
        severeDisability: severeDIS,
        creditPayment: this.getCreditPaymentForCedola(),
        positionNumberCode: null
      })
    );
  }

  private getCreditPaymentForCedola() {
    if (this.beneficiaryDefinition.code === BeneficiaryCathegory.CEDOLE) {
      return {
        cchecknumber: null,
        cholder: null,
        ciban: null,
        creditCardExpiredDate: null,
        creditCardNumber: null,
        idPaymentType: null
      };
    }
    return null;
  }

  protected evalExpiryCommCondition(): boolean | null {
    if (this.isREG41Enabled && this.isBenefLifeAndFromAnag) {
      return true;
    }
    if (!this.isREG41Enabled && this.isBenefLifeAndFromAnag) {
      return false;
    }
    return null;
  }

  protected evalBenDg(): boolean | null {
    if (this.isBENDGEnabled && this.isBenefLifeAndFromAnag) {
      return true;
    }
    if (!this.isBENDGEnabled && this.isBenefLifeAndFromAnag) {
      return false;
    }
    return null;
  }

  /**
   * Function to obtain irrevocability value from an existing benef with the same type
   * @param typeBenef code of beneficiary type: Vita(1)/Morte(2)
   */
  public inheritIrrFromTypeBenef(typeBenef: string): boolean {
    if (!!this.beneficiaries && !!this.beneficiaries.value) {
      const firstBenefType = this.beneficiaries.value.filter(benef => benef.type === typeBenef)[0];
      if (!!firstBenefType) {
        return firstBenefType.irrevocable;
      }
    }
    return false;
  }

  public isMatchingBeneficiary(ben: Beneficiary, event: Beneficiary): boolean {
    if (ben.value.hasOwnProperty("id") && event.value.hasOwnProperty("id")) {
        return (ben.value as Role).id === (event.value as Role).id;
    }
    if (ben.value.hasOwnProperty("text") && event.value.hasOwnProperty("text")) {
        return (ben.value as TextValue).text === (event.value as TextValue).text;
    }
    return false;
  }

  public deleteBen(event: Beneficiary) {
    let indexToRemove = this.beneficiaries.value.findIndex(ben => this.isMatchingBeneficiary(ben,event))
    if(indexToRemove !== -1){
      this.beneficiaries.removeAt(indexToRemove);
    }
    if(event.hasOwnProperty("id")){
      this.removeMessagesByRole((event.value as Role).id, (event.value as Role).role);
    }
  }

  private getSubjectFormControl(code: string, value: any = null): UntypedFormControl {
    return new UntypedFormControl({
      value: !!value ? value : null,
      type: this.beneficiaryDefinition.code,
      code,
      idAssicurato: this.beneficiaryDefinition.idAssicurato,
      irrevocable: this.formGroupValue.irrevocable,
      expiryCommunication: null,
      severeDisability: null,
      creditPayment: null,
      positionNumberCode: null
    });
  }

  private groupByPositionNumberType(arr: UntypedFormArray): { [key: string]: UntypedFormControl[] } {
    const groupedBeneficiariesByPositionType = arr.controls.reduce((grouped, obj) => {
      const key = obj.value && obj.value.positionNumberCode;

      // Se la chiave non esiste ancora nell'oggetto raggruppato, creala
      if (!grouped[key]) {
        grouped[key] = [];
      }

      // Aggiungi l'oggetto corrente al gruppo corrispondente
      grouped[key].push(obj);

      return grouped;
    }, {});
    return groupedBeneficiariesByPositionType;
  }
}

import {Component, EventEmitter, Inject} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {PremiumItem} from '../../../../models/details/instalment';
import {Currency} from '../../../../models/details/contract-details';

export class ConsultationPremiumDetailModalData {
  dataTable: Array<PremiumItem>;
  currency: Currency;
  total: number;
  title: string;
  constructor(dataTable: Array<PremiumItem>,
              currency: Currency,
              total: number,
              title: string) {
    this.dataTable = dataTable;
    this.currency = currency;
    this.total = total;
    this.title = title;
  }
}

@Component({
  selector: 'consultation-premium-detail-modal',
  templateUrl: './consultation-premium-detail-modal.component.html',
  styleUrls: ['./consultation-premium-detail-modal.component.scss']
})
export class ConsultationPremiumDetailModalComponent  implements OnModalClose {

  protected displayedColumns = ['description','net', 'accessories','fractioningInterests', 'taxable', 'taxes', 'gross'];
  protected data: ConsultationPremiumDetailModalData;

  modalClose: EventEmitter<void> = new EventEmitter<void>() ;

  constructor(@Inject(DIALOG_DATA) data: ConsultationPremiumDetailModalData) {
    this.data = data;
  }

  close(){
    this.modalClose.emit();
  }

  isTableEmpty(): boolean{
    return this.data?.dataTable?.length <= 0;
  }
}

import { ChangeDetectorRef, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';
import { RequestFactor } from '../../models/factor.model';
import {
  BeneficiaryDefinition, Definition,
  FactorDefinition, PaymentTypeDefinition,
  PostsalesError, Role
} from '../../models/postsales-operations-response.model';
import { LpcClaimBeneficiariesComponent } from '../../modules/lpc-beneficiary/lpc-claim-beneficiaries/lpc-claim-beneficiaries.component';
import { LpcBeneficiaryUtils } from '../../modules/lpc-beneficiary/util/lpc-beneficiary-utils';
import { LpcFactorAdapterComponent } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';

@Component({
  selector: 'lpc-constitution-annuities-operations',
  templateUrl: './constitution-annuities-operations.component.html',
  styleUrls: ['./constitution-annuities-operations.component.scss']
})
export class ConstitutionAnnuitiesOperationsComponent extends AbsOperationComponent implements OnInit  {

  protected operationDataKey = 'confirmation-chosen-rent';
  public listRentFactors: FactorDefinition[] = [];
  public requestFactor: RequestFactor[] = [];
  public insuredSettlements: {[key: string]: string}[] = [];
  public tableDefinitions: Definition[] = [];
  public quotationTableDefinitions: {[key: string]: string} = {};
  public beneficiariesDefinitions: BeneficiaryDefinition[] = [];
  protected anagSubj = null;
  public feErrorsBenef: PostsalesError[] = [];

  @ViewChild('factorAdapter') factorAdapter: LpcFactorAdapterComponent;
  @ViewChild('beneficiaries') beneficiaries: LpcClaimBeneficiariesComponent;

  public get hasRentFactors(): boolean {
    return !!this.listRentFactors.length;
  }

  private $paymentTypes: PaymentTypeDefinition[] = [];

  public get paymentTypes(): PaymentTypeDefinition[] {
    return this.$paymentTypes;
  }

  public get feErrors(): PostsalesError[] {
    return LpcBeneficiaryUtils.getSettlementBeneficiariesErrors(this.formGroup, this.translate);
  }

  private _beneficiaryData: any[];

  get idAssicurato(): string {
    if (this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].idAssicurato;
    } else if (!!this._beneficiaryData && this._beneficiaryData.length) {
      return this._beneficiaryData[0].beneficiary && this._beneficiaryData[0].beneficiary.idAssicurato;
    } else {
      return EMPTY_STR;
    }
  }

  get beneficiaryType(): string {
    if (!!this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].code;
    }
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService, plcQuestService, authService, anag);
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe()
    );
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      factors: new UntypedFormGroup({}),
      quotation: new UntypedFormGroup({}),
      beneficiaries: new UntypedFormControl([])
    });
  }


  protected getTransformedOperationData(): any {
    return {
      listRentFactors: this.requestFactor,
      rentBeneficiary: this.formGroup.getRawValue().beneficiaries
        .map(claimBeneficiary => {
          return LpcBeneficiaryUtils.convertToBeneficiaryRequest(claimBeneficiary);
        })
    };
  }

  protected updateDraft(step: string, reload: boolean = false, opDataType?: string): Observable<any> {
    return super.updateDraft(step, reload, opDataType).pipe(
      switchMap(result => {
        const insuredSettlements = result.data.operationData.data.confirmationChosenRentDetail.insuredSettlements;
        this.insuredSettlements = insuredSettlements;
        this.tableDefinitions = result.definitions.settlement as Definition[];
        this.quotationTableDefinitions = result.data.operationData.data.confirmationChosenRentDetail;
        this.listRentFactors = result.definitions.rentFactorsDefinition as FactorDefinition[];
        if (result.definitions.beneficiaries != null) {
          this.beneficiariesDefinitions = (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
        }
        let benefRoles = result.data.operationData.data.rentBeneficiary;
        benefRoles = benefRoles ? benefRoles : [];
        if (!benefRoles.length && !!this.beneficiariesDefinitions && this.beneficiariesDefinitions.length) {
          benefRoles = this.beneficiariesDefinitions;
        }
        this.formGroup.get('beneficiaries').setValue(
          (benefRoles as any[]).map(beneficiary => {
            return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
          }),
          { emitEvent: false }
        );
        this.$paymentTypes = result.definitions.paymentTypes as PaymentTypeDefinition[];
        this.detectChanges();
        return Promise.resolve(result);
      })
    );
  }


  public updateFactors(factors: RequestFactor[]) {
    this.requestFactor = factors;
    this.$subscriptions.push(this.onReload('rentReload').subscribe((result) => {
      this.listRentFactors = result.definitions.rentFactorsDefinition as FactorDefinition[];
    }));
  }

  public onNext(step: string = null, publish: boolean = false, context = step, isConfirmAndAccept = false): void {
    if (step === 'rent') {
      this.requestFactor = this.factorAdapter.getRequestFactor();
    }
    super.onNext(step, publish, context, isConfirmAndAccept);
  }

  public onAddedSubject($event: Role) {
    if ($event === null) {
      this.openModal('lpc_found_duplicate', 'lpc_duplicate_subject_message', true);
    }
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToastComponent} from './toast/toast.component';
import {ApiNotificationInterceptor} from "./api-notification.interceptor";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {PanelModule} from "../panel/panel.module"
import {NotificationService} from "./notification.service";
import {EnvironmentModule} from "../environment/environment.module";
import {RgiRxI18nModule} from "@rgi/rx/i18n";

@NgModule({
  declarations: [ToastComponent],
  exports: [
    ToastComponent
  ],
  imports: [
    CommonModule,
    PanelModule,
    EnvironmentModule,
    RgiRxI18nModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ApiNotificationInterceptor, multi: true},
    NotificationService
  ]
})
export class NotificationModule {
}

import {Component, Input} from '@angular/core';
import {Asset, Factor} from "../../../../models/details/contract-details";

@Component({
  selector: 'lib-consultation-warranties-assets',
  templateUrl: './consultation-assets.component.html',
  styleUrls: ['./consultation-assets.component.css']
})
export class ConsultationWarrantiesAssetsComponent {
  @Input() assets:Asset[];
}

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CodeMsg, OutputOccurrenceData } from './state-service/occurrence-config.model';
import { OccurrenceDataModifyState } from './state-service/occurrence-data-modify.state.manager';
import { Observable } from 'rxjs';
import { AdditionalData } from '../shared/dsc-additional-data/dsc-additional-data.model';
import { IncidentData } from '../shared/dsc-incident-data/dsc-incident-data.model';
import { AlertService } from '@rgi/digital-claims-common-angular';
import { CircumstanceIncident } from '../shared/dsc-circumstances-data/dsc-circumstances-model/dsc-circumstances.config';
import { OpenTypeSharedComponent } from '../shared/dsc-shared-model/dsc-model-enums';
import { AddressEntityData } from '../shared/dsc-shared-model/dsc-model-address';
import { InjuryCode } from '../shared/dsc-shared-model/dsc-model-injury';
import { Outcome } from '../shared/dsc-shared-model/dsc-model-utility';


@Component({
    selector: 'claims-occurrence-data-modify',
    templateUrl: './occurrence-data-modify.component.html',
    styleUrls: ['./occurrence-data-modify.component.scss']
})

export class OccurrenceDataModifyComponent implements OnInit {
    claim: any;
    session: any;
    detailClaim: Observable<OutputOccurrenceData>;
    sessionInfo: any;
    viewOccurrenceData: boolean;
    enumTypeOpenAccordion: OpenTypeSharedComponent = OpenTypeSharedComponent.isAccordion;
    enumTypeOpenTitleComponent: OpenTypeSharedComponent = OpenTypeSharedComponent.isTitleComponent;
    enumIsPot: OpenTypeSharedComponent = OpenTypeSharedComponent.isPot;
    enumIsClaim: OpenTypeSharedComponent = OpenTypeSharedComponent.isClaim;
    enumTypeIsNewClaim: OpenTypeSharedComponent = OpenTypeSharedComponent.isNewClaim;

    constructor(
        public occurrenceService: OccurrenceDataModifyState,
        public formBuilder: UntypedFormBuilder,
        public alertService: AlertService,
        @Inject('sessionService') session: any,
        @Inject('coreResult') private coreResult: any,
        @Inject('eventService') private eventService: any,
    ) {
        this.session = session;
    }

    ngOnInit() {

    }

    outputFormAddress(event: AddressEntityData) {
        this.occurrenceService.updateAddressState(event);
    }
    outputFormAdditionalData(event: AdditionalData) {
        this.occurrenceService.updateAdditionalData(event);
    }
    outputFormIncidentData(event: IncidentData) {
        this.occurrenceService.updateIncidentState(event);
    }
    outputFormInjuryCode(event: InjuryCode) {
        this.occurrenceService.updateInjuryCode(event);
    }
    outputFormCircumstanceIncident(event: CircumstanceIncident) {
        this.occurrenceService.updateCircumstanceIncident(event);
    }

    save() {
        //const currentState = this.occurrenceService.getCurrentState();
        this.occurrenceService.saveIncidentDataClaim();
        // this.occurrenceService.saveIncidentDataClaim().subscribe(
        //     (resp: Outcome) => {
        //         if (resp.code === '1') {
        //             if (currentState.parameterInputData.claimNumber) {
        //                 this.alertService.translatedMessage(CodeMsg.CLAIM + resp.detail);
        //             } else {
        //                 this.alertService.translatedMessage(CodeMsg.COMPLAINT + resp.detail);
        //             }
        //         } else {
        //             this.alertService.translatedMessage(resp.detail);
        //         }
        //         this.occurrenceService.findClaim()
        //     }, error => {
        //         this.alertService.translatedMessage(error.message);
        //     });
    }

    getCurrentSession() {
        return this.session.list().find(el => el.isActive);
    }


    exit() {
        const session = this.getCurrentSession();
        this.session.remove(session.idSession);
    }

    alignPolicy(){
        this.occurrenceService.alignPolicy().subscribe(
            (resp: Outcome) => {
                this.exit();
                if(resp.code === '1'){
                    this.alertService.translatedMessage(resp.descr);
                } else {
                    this.alertService.translatedMessage(resp.detail);
                }
            }, error => {
                this.alertService.translatedMessage(error.message);
        });
    }

}

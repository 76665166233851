<div class="modal-header">
  <h4 class="modal-title">Error</h4>
</div>
<div class="modal-body">
  <p *ngFor="let message of messages; let i = index" [attr.data-qa]="'modal-message-list-item-' + i">{{ message }}</p>
</div>
<div class="modal-footer">
  <div class="app-modal-footer">
    <div class="btn-group btn-group-justified">
      <div class="btn-group">
        <button (click)="onClose()" class="btn btn-primary" data-qa="close-button-action">OK</button>
      </div>
    </div>
  </div>
</div>

import {Injectable, Injector, Type} from '@angular/core';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {
  RgiRxHttpError,
  RgiRxHttpErrorFilter,
  RgiRxHttpErrorFilterType,
  RgiRxHttpErrorScope,
  RgiRxHttpErrorService,
  RgiRxHttpErrorSeverity
} from './http-api';
import {filter, map, mergeMap, shareReplay} from 'rxjs/operators';

const DEFAULT_SCOPES = ['global', 'feature'];
const DEFAULT_SEVERITY = RgiRxHttpErrorSeverity.ERROR;

@Injectable({
  providedIn: 'root'
})
export class RgiRxHttpErrorStream extends RgiRxHttpErrorService {
  private _error = new Subject<RgiRxHttpError>();


  constructor(private injector: Injector) {
    super();
  }

  push(httpError: RgiRxHttpError) {
    this._error.next(httpError);
  }

  error$(
    opts?: RgiRxHttpErrorFilterType): Observable<RgiRxHttpError> {
    if (!opts) {
      return this.observableFromOpts(DEFAULT_SEVERITY, DEFAULT_SCOPES);
    } else if (this.isErrorFilter(opts)) {
      const rgiRxHttpErrorFilter = this.injector.get<RgiRxHttpErrorFilter>(opts);
      return this._error.asObservable().pipe(
        mergeMap(value => {
          const when = rgiRxHttpErrorFilter.when(value);
          if (typeof when === 'boolean') {
            return combineLatest([of(when), of(value)]);
          }
          return combineLatest([when, of(value)]);
        }),
        filter(([shouldEmit, error]) => !!shouldEmit),
        map(([shouldEmit, error]) => error)
      );
    }
    const scopes = opts.scopes ? opts.scopes : DEFAULT_SCOPES;
    const severity = opts.severity !== undefined ? opts.severity : DEFAULT_SEVERITY;
    return this.observableFromOpts(severity, scopes);
  }

  private observableFromOpts(severity: RgiRxHttpErrorSeverity, scopes: RgiRxHttpErrorScope[]) {
    return this._error.asObservable().pipe(
      filter(e => {
        return e.severity <= severity && scopes.indexOf(e.scope) > -1;
      }),
      shareReplay()
    );
  }
  private isErrorFilter(opts: RgiRxHttpErrorFilterType): opts is Type<RgiRxHttpErrorFilter> {
    const matcher = opts as Type<RgiRxHttpErrorFilter>;
    return matcher.prototype !== undefined && matcher.prototype.when !== undefined;
  }

  dispose() {
    this._error.complete();
  }
}

<div>
  <label [for]="label" class="label-core">{{label}}</label>
  <pnc-required [required]="required"></pnc-required>
  <p class="input-group input-core-date" [formGroup]="customFormGroup">
    <input
      [id]="id"
      [name]="label"
      type="text"
      class="form-control"
      #d="ngbDatepicker"
      (dateSelect)="onDateSelect($event)"
      ngbDatepicker
      [formControlName]="customFormControlName"
      [placeholder]="placeholder"
      pncDateFormatter
      [minDate]="minDate"
      [maxDate]="maxDate"
      [disabled]="disabled"
    />
    <span class="input-group-btn">
        <button type="button" class="btn btn-default ui-calendar-background" (click)="d.toggle()" [disabled]="disabled">
            <i class="rgifont pnc-calendar"></i>
        </button>
    </span>
  </p>
</div>

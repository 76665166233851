
<rgi-rx-expansion-panel *ngIf="isAccordion" [expanded]="true" #first color="info">
    <div rgi-rx-expansion-panel-header (click)="!expanded ? setAccordion() : null">
        <div class="row rgi-claims-title-section-shared" style="padding:5px;">
            <div class="col-xs-12 col-md-12">
                <label ng-bind="label">
                    {{ '_CLAIMS_._BODYWORK' | translate }}
                </label>
            </div>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="bodyworkTemplate"></ng-container>
</rgi-rx-expansion-panel>

<div *ngIf="!isAccordion">
    <div class="row rgi-claims-sub-title-section-shared">
        <div class="col-xs-12 col-md-12">
        <label ng-bind="label">
            {{ '_CLAIMS_._BODYWORK' | translate }}
        </label>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="bodyworkTemplate"></ng-container>
</div>


<ng-template #bodyworkTemplate> 
    <div class="row">
        <div class="col-sm-6 col-xs-12" *ngIf="(carBodywork.objectId!=null) else noCarbodywork">
            <label style="display: flex; align-items: center; justify-content: space-between; border: 1px dashed rgba(85, 85, 85, 0.5); padding: 5px; width: 100%; box-sizing: border-box; height: 35px; border-radius: 3px;">
                <span style="color: #4981b4;">{{  (carBodywork.givenName === null ? '' : carBodywork.givenName) + ' ' + (carBodywork.surname === null ? '' : carBodywork.surname) }}</span>
                <button rgi-rx-button variant="square" class="delete-button" (click)="deleteBodywork()" style=" border: 1px solid #ccc; margin-left: auto; box-sizing: border-box; padding: 1px; height: 26px; width: 28px;">
                    <span class="glyphicon glyphicon-trash" style="color: #4981b4; font-size: 12px;"></span>
                </button>
            </label>
        </div>
        <ng-template #noCarbodywork>
            <div class="col-sm-6 col-xs-12">
                <button rgi-rx-button color="secondary" (click)="openModalNewCarbodywork()">
                    {{ '_CLAIMS_._SELECT' | translate
                    }} </button>
            </div>
        </ng-template>
    </div>
<div class="container-fluid table-white">
    <div class="row">
        <div class="col-xs-12 col-sm-6">{{'_CLAIMS_._FISCAL_CODE__VAT_CODE' | translate}}:
            <span>{{ carBodywork?.fiscalCode || carBodywork?.vatCode ||
                '-'}}</span>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-6">{{'_CLAIMS_._ADDRESS' | translate}}:
            <span *ngIf="carBodywork?.claimsAddress">
                {{ carBodywork?.claimsAddress.toponymStr }}&nbsp;
                {{ carBodywork?.claimsAddress.placeAddress }}&nbsp;
                {{ carBodywork?.claimsAddress.number }},&nbsp;
                {{ carBodywork?.claimsAddress.postCode }},&nbsp;
                {{ carBodywork?.claimsAddress.city }}&nbsp;
                {{ carBodywork?.claimsAddress.province }}&nbsp;&minus;&nbsp;
                {{ carBodywork?.claimsAddress.contryCode}}
            </span>
            <span *ngIf="!carBodywork?.claimsAddress">&minus;</span>
        </div>
        <div class="col-xs-12 col-sm-6" *ngIf="carBodywork?.claimsAddress">{{'Telephone' | translate}}:
            <span>{{ carBodywork?.claimsAddress.phoneNumber || '-'}}</span>
        </div>
    </div>

    <div class="row" *ngIf="carBodywork?.claimsAddress">
        <div class="col-xs-12 col-sm-6">{{'_CLAIMS_._EMAIL' | translate}}:
            <span>{{ carBodywork?.claimsAddress.emailAddress || '-'}}</span>
        </div>
    </div>
</div>

</ng-template>

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StartService {
  constructor() {}

  // this method is extended by a Project that needs a different logic for node selection
  getNode(availableAgencies: any[]) {
    return null;
  }

  // this method can be extended by a Project that doesn't want the hyperlink for Alternative Plates
  isAlternativePlateLinkVisible() {
    return true;
  }
}

import {GenericEntity} from '../domain-models/generic-entity';
import {LicensePlate} from '../domain-models/license-plate';
import {ApiVehicleStaticData} from './api-vehicle-static-data';

export class ApiVehicle extends GenericEntity {
  constructor(
    public id: string,
    public code: string,
    public description: string,
    public plate?: LicensePlate,
    public vehicleType?: string,
    public categoryUseCode?: string,
    public assetId?: string,
    public magazineType?: string,
    public holderFiscalCode?: string,
    public vehicleStaticData?: ApiVehicleStaticData
  ) {
    super(id, code, description);
  }
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {GenericEntity} from './generic-entity';
import {Company} from './company';
import {Agency} from './agency';
import {OperatorService} from '@rgi/portal-ng-core';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private baseApiUrl: string;
  private baseApiUrlV2: string;

  private availableCompaniesEndpoint: string;
  private availableAgenciesEndpoint: string;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any,
    private operatorService: OperatorService) {

    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;

    const newMotorFlowServicesPath = environment.api.portal.nmf;
    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
    ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
    : environment.api.portal.host + environment.api.portal.path + '/v2';

    this.availableCompaniesEndpoint = this.baseApiUrl + '/admin/companies';
    this.availableAgenciesEndpoint = this.baseApiUrl + '/admin/agencies';
  }

  getAvailableCompanies(): Observable<Array<Company>> {
    return this.httpClient.get<Array<Company>>(this.availableCompaniesEndpoint).pipe(
      map((data: any) => data.companies));
  }

  getAvailableAgencies(): Observable<Array<Agency>> {
    let httpParams: HttpParams = new HttpParams();

    const salePointLogin = this.operatorService.getSalePointDefault();
    if (salePointLogin) {
      httpParams = httpParams.set('loginNodeId', salePointLogin.objectId);
    }
    return this.httpClient.get<Agency>(this.availableAgenciesEndpoint, {params: httpParams}).pipe(
      map((data: any) => data.agencies)
    );
  }


}

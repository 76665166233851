import {Component, EventEmitter, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {ReIssueMsgData} from './re-issue-msg-model/re-issue-msg-data';

@Component({
  selector: 're-issue-msg-modal',
  templateUrl: './re-issue-msg-modal.component.html',
  styleUrls: ['./re-issue-msg-modal.component.css']
})
export class ReIssueMsgModalComponent implements OnInit, OnModalClose, OnDestroy {
  modalClose: EventEmitter<any> = new EventEmitter();
  data: ReIssueMsgData;

  constructor(
    @Optional() @Inject(DIALOG_DATA) data: ReIssueMsgData
  ) {
    if (data) {
      this.data = data;
      // alert(this.data.msg);
    }
  }

  ngOnInit() {
    // alert(this.data.msg);
  }

  ngOnDestroy(): void {
  }

  actionClose() {
    this.modalClose.emit();
  }

  actionConfirm() {
    this.modalClose.emit('confirm');
  }

}

<ng-template #dynamicHost></ng-template>

<ng-container *ngIf="dynamic === 'template'">
  <ng-container  *ngTemplateOutlet="dynamicAnswer.template; context: {$implicit: getDynamicAnswer()}"></ng-container>
</ng-container>

<ng-container *ngIf="!dynamic">
<div [ngSwitch]="answer.answer.type" class="ppevo-quest-answer">

    <div *ngSwitchCase="answerType.LIST" class="ppevo-input-group">

        <select (ngModelChange)="onInputChange($event)" *ngIf="!answer.multivalue" [(ngModel)]="answer.value"
              class="ppevo-form-control" #modelSelect="ngModel" [required]="isRequired"
              [disabled]="disabled" [ngClass]="{'ppevo-invalid': !isValid}">
            <option></option>
            <option *ngFor="let option of sortAnswerValues(answer.answer.answerValues)" [ngValue]="option"
                [selected]="option === answer.value"
                >{{ option.value }}</option>
        </select>
        <ppevo-multivalue (ngModelChange)="onInputChange($event)"
            *ngIf="answer.multivalue"
            [(ngModel)]="answer.value"
            [answerValues]="answer.answer.answerValues"
            name="multivalue"
            [ngClass]="{'ppevo-invalid': !isValid}"
            [disabled]="disabled"
            [values] = "answer.value"
            ngDefaultControl></ppevo-multivalue>
    </div>

    <div *ngSwitchCase="answerType.STRING" class="ppevo-input-group">
        <input (blur)="onInputChange($event.target.value)" [(ngModel)]="answer.value" class="ppevo-form-control"
               type="text" #modelString="ngModel" [required]="isRequired" [disabled]="disabled"
               [ngClass]="{'ppevo-invalid': !isValid}"/>
    </div>

    <div *ngSwitchCase="answerType.NUMERIC" class="ppevo-input-group">
      <input
          class="ppevo-form-control"
          (ngModelChange)="onInputChange($event)"
          (blur)="onInputChange($event.target.value)"
          type="text"
          (keypress)="onKeyPressNumeric($event)"
          [disabled]="disabled"
          [required]="isRequired"
          [ngClass]="{'ppevo-invalid':!isValid}"
          [(ngModel)]="answer.value"
      />
    </div>

    <div *ngSwitchCase="answerType.BOOLEAN" class="ppevo-input-group" [ngClass]="{'ppevo-invalid': !isValid}" >
      <ppevo-yesno (ngModelChange)="onInputChange($event)" [required]="isRequired" [(ngModel)]="answer.value"
                 name="booleanValue"
                 ngDefaultControl [disabled]="disabled"></ppevo-yesno>
    </div>

    <div *ngSwitchCase="answerType.DATE" class="ppevo-input-group" [ngClass]="{'ppevo-invalid': !isValid}">
      <input (blur)="onBlurDate(modelDate)" (dateSelect)="onBlurDate(modelDate)" [required]="isRequired"
             [(ngModel)]="answer.value" class="ppevo-form-control"
             (ngModelChange)="onInputChange($event)"
             name="dp"
             ngbDatepicker
             [attr.placeholder]="datePlaceholder"
             [minDate]="minDate"
             [maxDate]="maxDate"
             placement="top-right bottom-right" #datePickerInput="ngbDatepicker" #modelDate="ngModel"
             [disabled]="disabled"
             (keyup)="onKeyDate($event)"
        >
        <div class="ppevo-input-group-append" >
          <!-- fixme d.focus era toggle -->
              <button
                [disabled]="disabled"
                (click)="datePickerInput.open()"
                class="ppevo-btn-calendar"></button>
        </div>
    </div>
</div>
</ng-container>

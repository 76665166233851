import { RestErrorType } from '../../../models/enums/vita.enum';
import { RestErrorMessage } from './../../../models/postsales-operations-response.model';

export class ModalMessage {
  messageType: ModalMessageType;
  message: string;

  constructor(message: RestErrorMessage = {} as RestErrorMessage) {
    this.message = message.errorDescription;
    if (message.errorType === RestErrorType.BLOCKING) {
      this.messageType = ModalMessageType.ERROR;
    }
    if (message.errorType === RestErrorType.WARNING) {
      this.messageType = ModalMessageType.WARNING;
    }
  }

}

/**
 * the view of the messages is driven with the message type that you'll set
 * @example
 * ERROR = red
 * @example
 * PRIMARY = rgi blue
 * @example
 * WARNING = yellow
 * @example
 * INFO = light grey
 * @example
 * SUCCESS = green
 */
export enum ModalMessageType {
  ERROR = 'danger',
  PRIMARY = 'primary',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success'
}

import {TemplateRef} from '@angular/core';


/**
 * Abstract class for directive that are used as content wrappers for ng-template.
 */
export abstract class RgiRxTemplateContentDirective {
  protected constructor(private _template: TemplateRef<any>) {
  }


  get template(): TemplateRef<any> {
    return this._template;
  }
}


export interface RgiRxGlobalOverlayPositionStrategy {
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  width?: string;
  height?: string;
  centerHorizontally?: boolean | string;
  centerVertically?: boolean | string;
}





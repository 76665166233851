import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PolicyOperationPayload} from '../../../models/policy-operations';


@Component({
  selector: 'consultation-start-card-action-modal',
  templateUrl: './consultation-start-card-action-modal.component.html',
  styleUrls: ['./consultation-start-card-action-modal.component.css']
})
export class ConsultationStartCardActionModalComponent implements OnInit {
  constructor() {
  }

  @Input() operationTypes:Array<string> = [];
  @Input() operations: Array<PolicyOperationPayload> = [];
  @Output() modalClose = new EventEmitter();
  map:Map<String,PolicyOperationPayload[]> = new Map();
  ngOnInit() {
    this.operationTypes.forEach(type=>{
      this.map.set(type,this.operations.filter(operation=>{return operation.type == type}))
    })
  }

  closeModal() {
    this.modalClose.emit();
  }

}

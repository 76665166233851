import {AnagApiAddress} from './anag-api-party';
import {AnagEntityIta} from '../anag-api/anag-subject-api';
import {AnagExtensionSubject} from './anag-extension-subject';
import {AnagTaxClassification, AnagtaxClassificationData} from './anag-tax-classification';


export class AnagDocument {
  authoritiesRelease: AnagEntityIta;
  documentAddress: AnagApiAddress;
  documentNumber: string;
  documentType: AnagEntityIta;
  expirationDate: Date;
  extensionSubject: AnagExtensionSubject;
  locationsRelease: string;
  objectId: string;
  releaseDate: Date;
  drivingLicense: Array<AnagDrivingLicense>;
}

export class AnagDrivingLicense {
  idPartyDoc: string;
  code: string;
  descr: string;
  idTypeDrivingLicense: number;
  bdriverQualifCard: boolean;
  dstartDrivingLicense: Date;
  dendDrivingLicense: Date;
}

export class AnagDrivingLicenseTypeList {
  abbreviation: string;
  codCountry: string;
  code: string;
  description: string;
  dqcManagement: boolean;
  endDate: Date;
  idDrivingLicenseType: number;
  minimumAgeWithDQC: number;
  minimumAgeWithoutDQC: number;
  startDate: Date;
}

export class AnagDrivingLicenseRequest {
  idTypeDrivingLicense: string;
  dqcManagement: boolean;
  dstartDrivingLicense: Date;
  dendDrivingLicense: Date;
}

export  class AnagCheckDrivingLicenseRequest {
  birthDate: Date;
  licenseExpirationDate: Date;
  drivingLicenses: Array<AnagDrivingLicenseRequest>;
}
export class AnagCheckDrivingLicenseResponse {
  outcomeCode: string;
  outcomeDescr: string;
  message: string;
}

<ng-container [formGroup]="form">
  <div class="rgi-nmf-flex-row">
    <div class="rgi-nmf-field-container" *ngFor="let paymentField of fields">
      <div class="rgi-nmf-field">
        <rgi-rx-form-field [attr.data-qa]="paymentField.name"
                           [displayErrors]="paymentField.name.toUpperCase() !== 'CIBAN'">

          <label rgiRxLabel><span>{{paymentField?.label}}</span></label>
          <div *ngIf="paymentField.type === 'L';then select else input"></div>

          <ng-template #input>
            <ng-container [ngSwitch]="paymentField.name.toUpperCase()">

              <ng-container *ngSwitchCase="'DCARDEXPE'" >
                <div class="rgi-nmf-flex">
                  <input type="number" class="rgi-nmf-w-50" rgiRxInput formControlName="{{paymentField.name + '_MONTH'}}"
                         placeholder="mm">
                  <input type="number" class="rgi-nmf-w-50" rgiRxInput formControlName="{{paymentField.name + '_YEAR'}}"
                         placeholder="yyyy">
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'CIBAN'" >
                <input rgiRxInput formControlName="{{paymentField.name}}">
                <span *ngIf="!isFieldEnable(paymentField.name)" (click)="enableIban(paymentField.name)"
                      class="rgi-ui-icon-edit2 nmf-edit-iban"></span>
                <span *ngIf="canRevert(paymentField.name)" (click)="revertIban(paymentField.name)"
                      class="rgi-ui-icon-change nmf-edit-iban"></span>
                <!-- CIBAN FIELD CUSTOM ERROR --->
                <div class="rgi-ui-error-message rgi-rx-form-field-error-message"
                     *ngIf="form.controls[paymentField.name].touched && form.controls[paymentField.name].invalid">
                  <span *ngIf="form.controls[paymentField.name].errors.required; else formatNotAccepted" translate>required</span>
                  <ng-template #formatNotAccepted>
                    {{form.controls[paymentField.name].value}} <span translate>format not accepted</span>
                  </ng-template>
                </div>
              </ng-container>

              <ng-container *ngSwitchDefault >
                <input rgiRxInput formControlName="{{paymentField.name}}">
              </ng-container>
            </ng-container>
          </ng-template>

          <ng-template #select>
            <select rgiRxNativeSelect formControlName="{{paymentField.name}}">
              <option value=""></option>
              <option *ngFor="let value of paymentField.values" [value]="value.id">{{value.description}}</option>
            </select>
          </ng-template>
        </rgi-rx-form-field>
      </div>
    </div>
  </div>
</ng-container>

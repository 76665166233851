import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {RgiRxDragActionDirective} from '../rgi-rx-drag-action.directive';
import {SCALAR_TYPE} from '@rgi/rx';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {RgiRxDropContainerTemplateCtx} from '../rgi-rx-drag-drop.api';

export interface RgiRxOnDragBodyRemove {
  event?: Event;
  drag: RgiRxDragBodyComponent;
  origin: 'drag' | 'drag-remove';
}

@Component({
  selector: 'rgi-rx-drag-body',
  templateUrl: './rgi-rx-drag-body.component.html',
  host: {
    class: 'rgi-ui-drag rgi-rx-drag'
  }
})

export class RgiRxDragBodyComponent{

  private _model: any;
  private _label: string;
  private _viewModel: SCALAR_TYPE;
  private _disabled: boolean;
  private _dragActions: QueryList<RgiRxDragActionDirective>;
  private _removeClass: string;
  private _removeDescription: string | TemplateRef<any> |undefined;

  @Output() onRemove = new EventEmitter<RgiRxOnDragBodyRemove>();
  @ViewChild('removeHandle', {static: true}) removeHandle: ElementRef;


  @Input() get model(): any {
    return this._model;
  }

  set model(value: any) {
    this._model = value;
  }


  @Input() get label(): string {
    return this._label;
  }

  set label(value: string) {
    this._label = value;
  }

  @Input() labelTemplate?: TemplateRef<any>;
  @Input() valueTemplate?: TemplateRef<any>;


  @Input() @HostBinding('class.rgi-ui-disabled') get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Input() get viewModel(): SCALAR_TYPE {
    return this._viewModel;
  }

  set viewModel(value: SCALAR_TYPE) {
    this._viewModel = value;
  }


  @Input() get dragActions(): QueryList<RgiRxDragActionDirective> {
    return this._dragActions;
  }

  set dragActions(value: QueryList<RgiRxDragActionDirective>) {
    this._dragActions = value;
  }


  @Input() get removeClass(): string {
    return this._removeClass;
  }

  set removeClass(value: string) {
    this._removeClass = value;
  }


  @Input() get removeDescription(): string | TemplateRef<any> | undefined {
    return this._removeDescription;
  }

  set removeDescription(value: string | TemplateRef<any> | undefined) {
    this._removeDescription = value;
  }

  constructor() {}


  remove($event?: Event) {
    if (this.disabled) {
      return;
    }

    const isTarget = !!($event && $event.target && $event.target === this.removeHandle.nativeElement);
    this.onRemove.emit({
      event: $event,
      drag: this,
      origin: isTarget ? 'drag' : 'drag-remove'
    });
    if (isTarget) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  emitAction($event: MouseEvent, action: RgiRxDragActionDirective) {
    if (this.disabled) {
      $event.preventDefault();
      $event.stopPropagation();
      return;
    }
    action.emitAction($event, this.model);
  }

  _getDragTemplateCtx(): RgiRxDropContainerTemplateCtx {
    return {
      $implicit: this.model,
      viewModel: this.viewModel,
      label: this.label
    };
  }

  getRemoveClass(): string {
    if (!this.removeClass) {
      return 'rgi-ui-icon-delete';
    }
    return this.removeClass;
  }
}

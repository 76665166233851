import {DatePipe} from '@angular/common';
import {Component, EventEmitter, Inject, OnInit, Output, Type} from '@angular/core';
import {CustomModalService} from '../custom-modal.service';
import {GISService} from '../gis-service';
import {Modal} from '../modal';
import {ProposalService} from '../proposal.service';
import {VariablesService} from '../variables/variables-service';
import {VariablesComponent} from '../variables/variables.component';


@Component({
  selector: 'mic-contract-variables',
  templateUrl: './contract-variables.component.html',
  styleUrls: ['./contract-variables.component.scss']
})
export class ContractVariablesComponent extends VariablesComponent implements OnInit {

  @Output() recalculatePremium: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventPropagation = new EventEmitter<any>();


  constructor(
    protected variablesService: VariablesService,
    protected gisService: GISService,
    protected proposalService: ProposalService,
    protected datePipe: DatePipe,
    protected modalService: CustomModalService,
    @Inject('contractVariablesModalComponent') variablesModalComponent?: Type<Modal>
  ) {
    super(variablesService, gisService, proposalService, datePipe, modalService, variablesModalComponent);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected getCachedVariables() {
    return this.variablesService.getCachedContractVariables();
  }

  protected getVariablesObservable() {
    return this.variablesService.getContractVariablesObserable();
  }

  protected onModalEmit() {
    const cachedVariables = this.variablesService.getCachedContractVariables();
    this.variables = this.filterVariables(cachedVariables);
    this.filteredVariables = this.filterVariables(cachedVariables);
    this.loadVariables();
    if (this.areVariablesCompleted()) {
      this.variablesCompleted.emit(true);
      this.recalculatePremium.emit();
    }
  }
}

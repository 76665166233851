import {SelectionModel} from '@angular/cdk/collections';
import {
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {RgiRxDSL, RgiRxDSLAssertion, RgiRxVirtualDOMError} from '@rgi/rx';
import {cloneDeep} from 'lodash';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {filter, take, tap} from 'rxjs/operators';
import {PaginatorChange, RgiRxDataPaginator} from '../../paginator/paginator-api';
import {RgiRxPaginatorComponent} from '../../paginator/rgi-rx-paginator/rgi-rx-paginator.component';
import {
    RGI_RX_DATA_TABLE_CONFIG,
    RgiRxDataTableCellContextWrapper,
    RgiRxDataTableColumnSortDef,
    RgiRxDataTableConfig,
    RgiRxDataTableDataSource,
    RgiRxDataTableExpansionModel,
    RgiRxDatatableHeaderAction,
    RgiRxDatatableOnSortEnded,
    RgiRxDatatableRowAction,
    RgiRxDatatableSelectionChange,
    RgiRxDataTableSort
} from '../data-table-api';
import {RgiRxDataTableCellDirective} from '../rgi-rx-data-table-cell.directive';
import {RgiRxDatatableInputSortChange} from '../rgi-rx-datatable-input-sort.directive';
import {RgiRxTableComponent} from '../rgi-rx-table/rgi-rx-table.component';
import {
    getDataSourceTypeObjectPropsFromSchema,
    isActionRowSchema,
    RgiRxDatasource,
    RgiRxDataSourceTypeObjectProps,
    RgiRxTableColumnSorted,
    RgiRxTableSortComparatorDef,
    RgiRxTableSource,
    TableActionRowSchema,
    TableHeaderActionSchema,
    TableRowSchema,
    TableRowTypeSchema,
    TableSchema,
    TableTextRowSchema
} from '../table-api';
import {RGI_RX_DATA_TABLE_PIPE_VIEW_PROVIDERS, RgiRxDataTablePipeProvider} from '../table-providers';

let rgiRxDatatableCounter = 0;

export function childrenDatasourceFactory<T>(dataTable: RgiRxDatatableComponent<T>): RgiRxDatasource<T> {
  return dataTable.dataSource;
}

export function childrenTableSortFactory<T>(datasource: RgiRxDatasource<T>): RgiRxDataTableSort<T> {
  return new RgiRxDataTableSort<T>(datasource);
}


@Component({
  selector: 'rgi-rx-datatable',
  templateUrl: './rgi-rx-datatable.component.html',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'rgi-rx-datatable',
    '[id]': 'id'
  },
  styles: [ // todo use style inline
    `
      .rgi-ui-datatable-cell-expansion-header {
        text-align: center;
      }

      .rgi-rx-datatable-paginator-hidden {
        display: none;
      }

      .rgi-rx-datatable-hidden {
        visibility: hidden;
      }
    `
  ],
  viewProviders: [
    RGI_RX_DATA_TABLE_PIPE_VIEW_PROVIDERS
  ],
  providers: [
    {
      provide: RgiRxDatasource,
      useFactory: childrenDatasourceFactory,
      deps: [RgiRxDatatableComponent]
    },
    {
      provide: RgiRxDataTableSort,
      useFactory: childrenTableSortFactory,
      deps: [RgiRxDatasource]
    }
  ]
})
export class RgiRxDatatableComponent<T = any> implements OnInit, OnDestroy, OnChanges {

  @Input('data') data: RgiRxTableSource<T>;
  @Input('schema') schema: TableSchema;
  @Input('pageOptions') pageOption = [10, 20, 50];
  @Input('selectable') selectable = false;
  @Input('expansionRow') expansionRow: TemplateRef<any>;
  @Input('expansionModel') expansionModel: RgiRxDataTableExpansionModel<T>;
  @Input('sortable') sortable = false;
  @Input('sortHeader') sortHeader: boolean | RgiRxDataTableColumnSortDef<T>[] = false;
  @Input() stickyHeader = false;
  @Input() stickyPaginator = false;
  @Input() stickyColumns?: string[];
  @Input() stickySort = false;
  @Input() stickyExpansion = false;
  @Input() stickySelection = false;

  @Input('disableSearch') disableSearch = false;
  @Input('disableSort') disableSort = false;
  @Input('disableSortHeader') disableSortHeader = false;
  @Input('disablePaginator') disablePaginator = false;
  @Input('disableSelection') disableSelection = false;

  @Output('onAction') onAction = new EventEmitter<RgiRxDatatableRowAction<T>>();
  @Output('headerAction') headerAction = new EventEmitter<RgiRxDatatableHeaderAction<T>>();
  @Output('select') selectEvent = new EventEmitter<RgiRxDatatableSelectionChange<T>>();
  @Output('onSorted') onSorted = new EventEmitter<RgiRxDatatableOnSortEnded<T>>();
  @Output('sortedHeader') sortedHeader = new EventEmitter<RgiRxTableColumnSorted>();
  @ViewChild(RgiRxTableComponent, { static: true }) table: RgiRxTableComponent<T>;
  @ViewChild(RgiRxPaginatorComponent, { static: true }) paginatorComponent: RgiRxPaginatorComponent;
  @ViewChild('pageChecker', { static: false }) pageChecker: ElementRef;
  @ViewChild('toolBar', {static: true}) toolbarRef: ElementRef;
  @ViewChild('headerRow', {static: false, read: ElementRef}) headerRow: ElementRef;

  @ContentChildren(RgiRxDataTableCellDirective) tableCells: QueryList<RgiRxDataTableCellDirective<T>>;

  id: string;
  columns: string[];
  headers: string[];
  filter: string;
  showPaginator = true;
  currentPage = 0;
  selections = new SelectionModel(true, [], true);

  private _dataSource: RgiRxDatasource<any>;
  private dataSubscription: Subscription = Subscription.EMPTY;
  private selectionSubscription: Subscription = Subscription.EMPTY;
  private expansionModelChangeSubscription: Subscription = Subscription.EMPTY;
  private offset = 0;

  get paginator(): RgiRxDataPaginator | undefined {
    return this._dataSource.paginator;
  }


  get dataSource(): RgiRxDatasource<any> {
    return this._dataSource;
  }

  constructor(
    private pipeProvider: RgiRxDataTablePipeProvider,
    private _vc: ViewContainerRef,
    private renderer: Renderer2,
    @Inject(RGI_RX_DATA_TABLE_CONFIG) private config: RgiRxDataTableConfig
  ) {
    this.id = `rgi-rx-datatable-${rgiRxDatatableCounter++}`;
  }

  ngOnInit(): void {
    this.validate();
    this.initExpansionModel();
    this.setHeaderRowStickyStyles(this.stickyHeader);

    if (this.data instanceof RgiRxDatasource) {
      this._dataSource = this.data;
      if (this.data.hasPaginator()) {
        this.paginatorComponent.paginator = this.data.paginator;
        this.paginator.pageSize = this.pageOption[0];
      } else {
        this.showPaginator = false;
      }
      if (!this._dataSource.filterObjectProps) {
        this._dataSource.filterObjectProps = getDataSourceTypeObjectPropsFromSchema(this.schema);
      }
      this.bindDataSource();
    } else {
      const objectProps: RgiRxDataSourceTypeObjectProps<any> = getDataSourceTypeObjectPropsFromSchema(this.schema);
      this._dataSource = new RgiRxDataTableDataSource([], this.paginatorComponent.paginator, objectProps);
      this.bindDataSource();
      // template binding is not working
      if (this.data instanceof Observable) {
        this.dataSubscription = this.data
          .pipe(
            tap(next => {
              this._dataSource.update(next as any[]);
            })
          )
          .subscribe();
      } else {
        this._dataSource.update(this.data);
      }
    }

    if (this.dataSource.hasPaginator()) {
      this._dataSource.paginator.changes.subscribe(
        (change: PaginatorChange) => {
          this.currentPage = change.current;
        }
      );
    }
    this.initSchema();

    this.selectionSubscription = this.selections.changed.subscribe(
      next => {
        this.selectEvent.emit(next);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.offset = 0;
    if (changes.sortable && !changes.sortable.isFirstChange()) {
      if (!changes.sortable.currentValue && !!changes.sortable.previousValue) {
        this.columns = this.columns.filter(c => c !== 'rgiRxDataTableSort');
        this.headers = this.headers.filter(c => c !== 'rgiRxDataTableSort');
      } else {
        this.offset = 2;
        this.initSortable();
      }
    }
    if (changes.selectable && !changes.selectable.isFirstChange()) {
      if (!changes.selectable.currentValue && !!changes.selectable.previousValue) {
        this.columns = this.columns.filter(c => c !== 'rgiRxDatatableSelect');
        this.headers = this.headers.filter(c => c !== 'rgiRxDatatableSelect');
      } else {
        this.offset = 1;
        this.initSelectable();
      }
    }
    if (changes.expansionRow && !changes.expansionRow.isFirstChange()) {
      if (!changes.expansionRow.currentValue && !!changes.expansionRow.previousValue) {
        this.columns = this.columns.filter(c => c !== 'rgiRxDataTableExpanded');
        this.headers = this.headers.filter(c => c !== 'rgiRxDataTableExpanded');
      } else {
        this.offset = 0;
        this.initExpansionRow();
      }
    }

    if (changes.schema && !changes.schema.isFirstChange()) {
      this._dataSource.filterObjectProps = getDataSourceTypeObjectPropsFromSchema(this.schema);
      this.initSchema();
    }
    if (changes.stickyHeader && !changes.stickyHeader.isFirstChange()) {
      this.setHeaderRowStickyStyles(changes.stickyHeader.currentValue);
    }

  }

  onFilterChange($event: any) {
    this._dataSource.filter = this.filter;
  }

  isTextRow(row: TableRowTypeSchema) {
    return !this.isEventRow(row);
  }

  isEventRow(row: TableRowTypeSchema): row is TableActionRowSchema {
    return isActionRowSchema(row);
  }

  evalAssertionOnRow(assertion: RgiRxDSLAssertion, row: T, schema: TableRowSchema) {
    return !assertion ? false : RgiRxDSL.assert(assertion, this.transformRow(row, schema));
  }

  getStylesFromSchema(schema: TableRowSchema, row: T) {
    const classes = !!schema.styleClass ? [schema.styleClass] : [];
    if (!!schema.styles) {
      for (const stylesKey in schema.styles) {
        if (schema.styles.hasOwnProperty(stylesKey)) {
          if (RgiRxDSL.assert(schema.styles[stylesKey], this.transformRow(row, schema))) {
            classes.push(stylesKey);
          }
        }
      }
    }
    return classes;
  }

  onActionClick(row: T, action: string, $event: MouseEvent) {
    this.onAction.emit({
      row,
      name: action,
      event: $event
    });
  }

  onHeaderActionClick($event: MouseEvent, headerAction: TableHeaderActionSchema) {
    this.headerAction.emit({
      name: headerAction.name,
      event: $event,
      selections: this.selections.selected
    });
  }

  calculatePageIndex(index: number): string {
    const lineNumber = this.paginator.getElementAbsoluteIndex(index) + 1;
    return `${lineNumber}`;
  }

  onSortChange(event: RgiRxDatatableInputSortChange, row: T) {
    combineLatest([of(event), this.dataSource.changed])
      .pipe(
        filter(([evt, change]) => change === 'sorted'),
        take(1),
        tap(([evt]) => {
          this.onSorted.emit({
            change: evt,
            row
          });
        })
      ).subscribe();
    this._dataSource.moveElement(event.startIndex, event.targetIndex);
  }

  transform(rowElement: T, rowSchema: TableTextRowSchema): any {
    const value = rowElement[this.getNodePath(rowSchema)];
    if (rowSchema.format) {
      return this.pipeProvider.transform(value, rowSchema.format);
    }
    return value;
  }

  transformRow(rowElement: T, rowSchema: TableTextRowSchema): any {
    const clone = cloneDeep(rowElement);
    if (rowSchema.format) {
      clone[this.getNodePath(rowSchema)] = this.pipeProvider.transform(clone[this.getNodePath(rowSchema)], rowSchema.format);
    }
    return clone;
  }

  onSelectAll($event: Event) {
    const target = $event.target as HTMLInputElement;
    switch (target.checked) {
      case true: {
        this.selections.select(...this._dataSource.getCurrentPageRows());
        break;
      }
      default: {
        this.selections.clear();
      }
    }
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
    this.selectionSubscription.unsubscribe();
    this.expansionModelChangeSubscription.unsubscribe();
  }

  onRowCheckBoxChange($event: Event, row: T) {
    this.pageChecker.nativeElement.checked = false;
    const target = $event.target as HTMLInputElement;
    switch (target.checked) {
      case true: {
        this.selections.select(row);
        break;
      }
      default: {
        this.selections.deselect(row);
      }
    }

  }

  isSelected(row: T): boolean {
    return this.selections.isSelected(row);
  }

  getColumnLength() {
    return this.selectable ? this.columns.length + 1 : this.columns.length;
  }

  shouldShowHeaderAction(headerAction: TableHeaderActionSchema): boolean {
    switch (headerAction.when) {
      case 'single': {
        return !this.selectable;
      }
      case 'multi': {
        return this.selectable;
      }
    }
    return true;
  }

  hasSortHeader() {
    if (typeof this.sortHeader === 'boolean') {
      return this.sortHeader;
    } else {
      return this.sortHeader.length > 0;
    }
  }

  getSortComparator(rowSchema: TableRowTypeSchema): RgiRxTableSortComparatorDef<any> {
    if (typeof this.sortHeader !== 'boolean') {
      const rgiRxDataTableColumnSortDef = this.sortHeader.find(s => s.name === rowSchema.name);
      return rgiRxDataTableColumnSortDef ? rgiRxDataTableColumnSortDef.comparator : undefined;
    }
    return undefined;
  }

  hasTableCell(rowSchema: TableRowSchema | TableTextRowSchema | TableActionRowSchema) {
    return !!this.tableCells.find(item => item.rgiRxDataTableCell === rowSchema.name);
  }

  getDataTableCellContext(row: T, index: number, schema: TableRowTypeSchema): RgiRxDataTableCellContextWrapper<T> {
    return {
      $implicit: {
        isSelected: () => this.isSelected(row),
        assert: assertion => this.evalAssertionOnRow(assertion, row, schema),
        styles: () => this.getStylesFromSchema(schema, row),
        transform: () => this.transform(row, schema),
        emitAction: (action, event) => this.onActionClick(row, action, event),
        row,
        index,
        schema
      }
    };
  }

  isSticky(schema: TableRowTypeSchema) {
    return !!(this.stickyColumns && this.stickyColumns.find(col => col === schema.name));
  }

  canBeSorted(rowSchema: TableRowTypeSchema) {
    return !isActionRowSchema(rowSchema); /* && !this.hasTableCell(rowSchema);*/
  }

  getTableCell(rowSchema: TableRowTypeSchema) {
    const rgiRxDataTableCellDirective = this.tableCells.find(t => t.rgiRxDataTableCell === rowSchema.name);
    if (!rgiRxDataTableCellDirective) {
      throw new RgiRxVirtualDOMError(`No RgiRxDataTableCellDirective has been found for ${rowSchema.name}, available names are: ${this.schema.rows.map(r => r.name).join(', ')}`);
    }
    return rgiRxDataTableCellDirective.template;
  }

  private getNodePath(schema: TableTextRowSchema) {
    return !!schema.path ? schema.path : schema.name;
  }

  private initSchema() {
    this.offset = 0;
    this.columns = this.schema.rows.map(f => f.name);
    this.headers = this.schema.header.filter(f => this.columns.indexOf(f) > -1);
    this.initExpansionRow();
    this.initSelectable();
    this.initSortable();
  }

  isStickyEnd(rowSchema: TableRowTypeSchema) {
    if (!this.stickyColumns || this.stickyColumns.length === 0) {
      return false;
    }
    return this.stickyColumns
      .sort((a, b) => this.schema.header.indexOf(a) - this.schema.header.indexOf(b))
      .indexOf(rowSchema.name) === this.stickyColumns.length - 1;
  }

  getStickyRootStyle(orientation: 'top' | 'bottom', zIndex: number = 2) {
    return `position: sticky; left: 0; ${orientation}: 0; z-index:${zIndex}`;
  }

  private setHeaderRowStickyStyles(isSticky: boolean) {
    setTimeout( // force to reflow after CDK sticky styler applied its changes
      () => {
        if (isSticky) {
          this.renderer.setStyle(this.headerRow.nativeElement, 'top', `${this.toolbarRef.nativeElement.offsetHeight}px`);
          this.renderer.setStyle(this.headerRow.nativeElement, 'position', 'sticky');
          this.renderer.setStyle(this.headerRow.nativeElement, 'left', 0);
          this.renderer.setStyle(this.headerRow.nativeElement, 'z-index', 3);
        }
        else {
          this.renderer.removeStyle(this.headerRow.nativeElement, 'top');
          this.renderer.removeStyle(this.headerRow.nativeElement, 'position');
          this.renderer.removeStyle(this.headerRow.nativeElement, 'left');
          this.renderer.removeStyle(this.headerRow.nativeElement, 'z-index');
        }
      }
    );
  }

  private initExpansionRow() {
    if (!!this.expansionRow) {
      this.columns.splice(this.offset, 0, 'rgiRxDataTableExpanded');
      this.headers.splice(this.offset, 0, 'rgiRxDataTableExpanded');
      this.offset++;
    }
  }

  private initSortable() {
    if (!!this.sortable && !!this.paginator) {
      this.columns.splice(this.offset, 0, 'rgiRxDataTableSort');
      this.headers.splice(this.offset, 0, 'rgiRxDataTableSort');
      this.offset++;
    }
  }

  private initSelectable() {
    if (!!this.selectable) {
      this.columns.splice(this.offset, 0, 'rgiRxDatatableSelect');
      this.headers.splice(this.offset, 0, 'rgiRxDatatableSelect');
      this.offset++;
    }
  }

  private validate() {
    if (!this.schema) {
      throw new RgiRxVirtualDOMError('[schema] must be defined in order to use rgi-rx-datatable');
    }
    if (!this.pageOption || this.pageOption.length === 0) {
      throw new RgiRxVirtualDOMError('[pageOption] must be defined and contains at least one element');
    }
  }

  private initExpansionModel() {
    if (this.expansionRow) {
      if (!this.expansionModel) {
        this.expansionModel = new RgiRxDataTableExpansionModel<any>([], this.config.expansion);
      }
    }
  }

  private bindDataSource() {
    this.table.dataSource = this._dataSource;
  }

}

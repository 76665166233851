import { State } from '@rgi/rx/state';
import { AdditionalData } from '../../shared/dsc-additional-data/dsc-additional-data.model';
import { GeneralClaimsData } from '../../shared/dsc-general-detail/dsc-general-detail.config';
import { IncidentData } from '../../shared/dsc-incident-data/dsc-incident-data.model';
import { CircumstanceIncident } from '../../shared/dsc-circumstances-data/dsc-circumstances-model/dsc-circumstances.config';
import { AddressEntityData } from '../../shared/dsc-shared-model/dsc-model-address';
import { InjuryCode } from '../../shared/dsc-shared-model/dsc-model-injury';

export class UpdatePolicy{
    potentialClaimNumber: string;
    claimNumber: string;
    occurenceDate: string;
    certificatedOccurenceDate: string;
}

export enum CodeMsg {
    CLAIM = 'SI1151 - ',
    COMPLAINT = 'SI0236 - '
}

export class ParameterInputData {
    potentialClaimNumber: string = '';
    claimNumber: string = '';
}

export class OutputOccurrenceData extends State {
    parameterInputData: ParameterInputData  = new ParameterInputData();
    generalData: GeneralClaimsData = new GeneralClaimsData();
    incidentData: IncidentData = new IncidentData();
    addressEntity: AddressEntityData = new AddressEntityData();
    circumstanceIncident: CircumstanceIncident = new CircumstanceIncident();
    injuryCode: InjuryCode = new InjuryCode();
    additionalData: AdditionalData = new AdditionalData();
    buttonNormalizeEnable: boolean = false;
    buttonSaveVisibile: boolean = false;
    buttonAlignPolicy: boolean = false;
    claimSession: any;
    ministerialBranchcode: string;
    isEnabledButtonSave: boolean;
    lastAuditDateValue: any;
    openedClaimDuetoSummonsIssuedValue: boolean;
    notIdentifiedWitnessValue: boolean;
    toBeSettledValue: boolean;
    parentSession: any;
    newClaim: any;
}

import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SalesNode } from '../models/policy.model';
import { EnviromentsService } from '../services/enviroments.service';
import { RGI_RX_DROP_SELECTION_HANDLER } from '@rgi/rx/ui';
import { NodeSelectionModalComponent } from './node-selection-modal/node-selection-modal.component';

@Component({
  selector: 'lic-life-proposal-card',
  templateUrl: './life-proposal-card.component.html',
  styleUrls: ['./life-proposal-card.component.scss'],
  providers: [
    {
      provide: RGI_RX_DROP_SELECTION_HANDLER,
      useValue: {
        name: 'NodeSelectionModal',
        handler: NodeSelectionModalComponent
      },
      multi: true
    }
  ]
})


export class LifeProposalCardComponent implements OnInit {

  @Output() eventPropagation = new EventEmitter<any>();

  companyList: any[];
  key: number;
  withBasket = false;
  selected = false;
  submitted = false;
  selectedOption: { value: string; name: string };
  node = '';
  newProposalControl: UntypedFormGroup;
  availableNode: any;
  descriptions: SalesNode[] = [];
  products: any;
  availableCompany: any;
  baseApiUrl: string;
  idpv;
  operator;

  constructor(
    @Inject('$injector') private injector: any,
    private http: HttpClient,
    protected environment: EnviromentsService) {
      this.baseApiUrl = environment.baseApiURL;
      const authService = this.injector.get('authService');
      this.idpv = authService.getSalePointId();
      this.operator = authService.getOperator();
      this.getAvailableNodes().subscribe(response => {
        const nonFiltered =  response.products.filter(prod => prod.policyCategory.codice === '7');
        this.products = nonFiltered.filter(
          (thing, i, arr) => arr.findIndex(t => t.productType.identification === thing.productType.identification) === i
        );
      });


      this.availableCompany = this.operator.visibleCompanies;
  }

  public getProducts(): Observable<any> {
    const request = {
      productsFilter: {
        filterProductsCategory : '1',
        filterSalableProduct: true,
        filterSalableProductTypes: true,
        idSp: this.idpv
      }
    };
    return this.http.post(this.baseApiUrl + '/admin/products', request);
  }

  ngOnInit() {
    this.initForm();
    this.node = null;
  }

  selectChangeHandlerNode(event: any) {
    this.node = event;
    this.withBasket = true;
  }

  initForm() {
    this.newProposalControl = new UntypedFormGroup({
      company: new UntypedFormControl(null, Validators.required),
      node: new UntypedFormControl('', Validators.required),
      productType: new UntypedFormControl('', Validators.required)
    });
  }

  get f() { return this.newProposalControl.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.newProposalControl.valid) {
      // apertura sessione
      const codeAndIdSp = this.newProposalControl.get('node').value.split('|');
      this.eventPropagation.emit({
        eventName: 'openLifeProposalFlow',
        card: 'ptflifeProposal',
        route: 'anag',
        data: {
          productType: this.newProposalControl.get('productType').value,
          nodeCode: codeAndIdSp[0],
          idpv: codeAndIdSp[1]
        }
      });
    }
  }

  getCompanyCode(service: any): any {
    const label = this.newProposalControl.get('company').value.$ngOptionLabel;
    this.companyList = service.getOperator().visibleCompanies;

    const filteredCompany = this.companyList.filter(
      company => ((company.description === label))
    );

    if (!!filteredCompany) {
      return filteredCompany[0].code;
    }
  }

  getAvailableNodes(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + '/admin/agencies?loginNodeId=' + this.idpv).pipe(
      switchMap(resp => {
        this.availableNode = resp.agencies;
        this.descriptions = this.availableNode.map((y) => {
          const node = new SalesNode();
          node.displayValue = y.code + '|' + y.idSp;
          node.description = y.description;
          return node;
        });
        return this.getProducts();
      }),
    );
  }

  clean() {
    this.newProposalControl.reset();
    this.withBasket = false;
    this.submitted = false;
  }

  // bottone sul campo nodo (cestino)
  cleanNode() {
    this.newProposalControl.patchValue({ node: null });
    this.withBasket = false;
  }

  cleanCompany() {
    this.newProposalControl.patchValue({ company: null });
  }

}


export const REST_API_CONF_LOCAL = {
  endpoint: 'http://localhost:3000',
  endpointPI: 'http://localhost:3000'
};

export const REST_API_TECHDATA_CONF = {
  path: '/qqcontract/contract/',
  technicalDataPath: '/policytechnicaldata'
};

export const REST_API_SUBJECT_CONF = {
  path: '/anag/subject/',
};

export const REST_API_VERIFY_CONF = {
  verificaProdottoBanca: '/verificaProdottoBanca',
  v2: '/v2',
  banca: '/banca'
};

export const REST_API_ANAG_VARIABLES_CONF = {
  path: '/qqcontract/contract/',
  variablesPath: '/variables'
};

export const REST_API_CONTACT_CONF = {
  path: '/qqcontract/contract/',
  contactPath: '/contacts',
  partiesPath: '/parties'
};

export const REST_API_POLICY_DATA_CONF = {
  path: '/qqcontract/contract/',
  variablesPath: '/policyData',
  pathV2: '/v2'
};

export const REST_API_ANAG_WARNINGS_CONF = {
  path: '/qqcontract/contract/',
  warningsPath: '/warnings'
};

export const REST_API_ANAG_PRINTS_CONF = {
  path: '/stampe',
  stampaJform: '/stampaJform',
  v2: '/v2'
};

export const REST_API_BANCA_PTF_DANNI_CONF = {
  path: '/ptfdanni/',
  contractPath: 'contract'
};

export const REST_API_PORTFOLIO_PC_CONF = {
  path: '/portfolio/pc/',
  v2: '/v2',
  configurations: 'configuration'
};

export const REST_API_PORTFOLIO_CONF = {
  path: '/portfolio',
  movimenti: '/movimenti',
  movimentiRistampa: '/movimenti/ristampa'
};

export const REST_API_PRINT_EXT_CONF = {
  path: '/print/jform'
};


import {
  Component, OnInit, Input, Output, EventEmitter, forwardRef,
  ViewEncapsulation, ChangeDetectionStrategy, ViewChild, ElementRef, ChangeDetectorRef, HostBinding
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
/** Change event object emitted. */
export class ToggleSwitchChange {
  constructor(
    /** The source of the event. */
    public source: ToggleSwitchComponent,
    /** The new `checked` value. */
    public checked: boolean) { }
}

// Increasing integer for generating unique ids for slide-toggle components.
let nextUniqueId = 0;

@Component({
  selector: 'pnc-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ToggleSwitchComponent),
    multi: true
  }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class ToggleSwitchComponent implements OnInit, ControlValueAccessor {

  private uniqueId = `toggle-switch-${++nextUniqueId}`;

  private isReadOnly = false;

  private isDisabled = false;

  private isChecked = false;

  private isRequired = false;

  @HostBinding('class') cls = 'pnc-toggle-switch';

  @Input() qa: string | null = null;

  @Input() name: string | null = null;

  @HostBinding('id') @Input() id: string = this.uniqueId;

  @ViewChild('input', { static: true }) inputElement: ElementRef;

  @Output() readonly changed: EventEmitter<ToggleSwitchChange> = new EventEmitter<ToggleSwitchChange>();

  private onChange: (_: any) => {};

  private onTouched: () => void = () => { };

  @Input()
  get readonly() {
    return this.isReadOnly;
  }
  set readonly(value) {
    this.isReadOnly = this._coerceBooleanProperty(value);
  }

  @Input()
  get disabled() {
    return this.isDisabled;
  }
  set disabled(value) {
    this.isDisabled = this._coerceBooleanProperty(value);
  }

  @Input()
  get required() {
    return this.isRequired;
  }
  set required(value) {
    this.isRequired = this._coerceBooleanProperty(value);
  }

  @Input()
  get checked() {
    return this.isChecked;
  }
  set checked(value) {
    this.isChecked = this._coerceBooleanProperty(value);
    this.changeDetectorRef.markForCheck();
  }

  get inputId(): string {
    return `${this.id || this.uniqueId}-input`;
  }

  get inputName(): string {
    return !!this.name ? `${this.name}-input` : null;
  }

  onChangeEvent(event: Event) {
    // Stop propagation on the change event.
    event.stopPropagation();
    // Sync the value from the underlying input element with the component instance.
    this.checked = this.inputElement.nativeElement.checked;
    // Emit my custom change event only if the underlying input emitted one. This ensures that
    // there is no change event, when the checked state changes programmatically.
    this._emitChangeEvent();
  }

  onInputClick(event: Event): boolean {
    // We have to stop propagation for click events on the visual hidden input element.
    // Otherwise we may have multiple click events.
    event.stopPropagation();
    return !this.isReadOnly;
  }

  /**
   * Emits a change event on the `change` output. Also notifies the FormControl about the change.
   */
  private _emitChangeEvent() {
    this.onChange(this.checked);
    this.changed.emit(new ToggleSwitchChange(this, this.checked));
  }


  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {

  }

  /** Implemented as part of ControlValueAccessor. */
  writeValue(value: any): void {
    this.checked = !!value;
    console.log('Written ' + this.checked);
  }

  /** Implemented as part of ControlValueAccessor. */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** Implemented as part of ControlValueAccessor. */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Implemented as a part of ControlValueAccessor. */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  /** Toggles the checked state of the slide-toggle. */
  toggle(): void {
    this.checked = !this.checked;
    this.onChange(this.checked);
  }

  private _coerceBooleanProperty(value: any): boolean {
    return value != null && `${value}` !== 'false';
  }
}


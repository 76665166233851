import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxSnackbarComponent} from './rgi-rx-snackbar/rgi-rx-snackbar.component';
import {PortalModule} from '@angular/cdk/portal';
import {RgiRxSdkObserverModule} from '@rgi/rx/sdk';
import {RgiRxQualityAssuranceModule, RgiRxReactionModule} from '@rgi/rx';
import {RgiRxSnackbarBodyComponent} from './rgi-rx-snackbar-body/rgi-rx-snackbar-body.component';
import {RgiRxSnackbarOverlay} from './rgi-rx-snackbar-overlay.service';
import {RgiRxSnackbarHostComponent} from './rgi-rx-snackbar-host/rgi-rx-snackbar-host.component';
import {RGI_RX_SNACKBAR_OVERLAY_CONFIG} from './rgi-rx-snackbar-api';
import {RGI_RX_SNACKBAR_DEFAULT_OVERLAY_CONFIG} from './overlay.config';
import {RgiRxSnackbarHeaderComponent} from './rgi-rx-snackbar-header/rgi-rx-snackbar-header.component';
import {
  RgiRxSnackbarNotificationComponent
} from './rgi-rx-snackbar-notification/rgi-rx-snackbar-notification.component';
import {RgiRxSdkExpandModule} from '@rgi/rx/sdk';
import {RgiRxI18nModule} from '@rgi/rx/i18n';
import {OverlayModule} from '@angular/cdk/overlay';
import {RGI_RX_SNACKBAR_TRANSLATIONS} from './i18n/localization';

@NgModule({
  declarations: [RgiRxSnackbarComponent, RgiRxSnackbarBodyComponent, RgiRxSnackbarHostComponent, RgiRxSnackbarHeaderComponent, RgiRxSnackbarNotificationComponent],
  imports: [
    CommonModule,
    PortalModule,
    RgiRxSdkObserverModule,
    RgiRxReactionModule,
    RgiRxQualityAssuranceModule,
    RgiRxSdkExpandModule,
    RgiRxI18nModule,
    RgiRxI18nModule.forRoot(RGI_RX_SNACKBAR_TRANSLATIONS),
    OverlayModule
  ],
  providers: [
    RgiRxSnackbarOverlay,
    {
      provide: RGI_RX_SNACKBAR_OVERLAY_CONFIG,
      useValue: RGI_RX_SNACKBAR_DEFAULT_OVERLAY_CONFIG
    }
  ],
  exports: [
    RgiRxSnackbarComponent,
    RgiRxSnackbarNotificationComponent
  ]
})
export class RgiRxSnackbarModule {
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'ppevo-panel',
  templateUrl: './panel.component.html'
})
export class PPEVOPanelComponent implements OnInit{

  @Input() class?:string;
  @Input() ngClass?:any;
  @Input() style?:any;
  @Input() ngStyle:any;
  @Input() closable:boolean
  @Input() showFooter?:boolean = true;
  @Input() showHeader?:boolean = true;
  @Input() status?: 'warning' | 'danger' | 'success' | 'default' = "default";
  @Input() autohide?:number = 0;
  @Input() small?:boolean = false;
  @Input() notifyCloseOnly: boolean = false; 

  @Output('close') close = new EventEmitter;

  visible: boolean = true;
  classStatus:string;

  private classByStatus = {
    'warning' : "ppevo-panel-warning",
    'danger' : "ppevo-panel-danger",
    'success' : "ppevo-panel-success",
  }

  constructor() {

  }

  ngOnInit(): void {


    if(this.status && this.status!="default" && this.classByStatus[this.status]){
      this.ngClass = this.classByStatus[this.status];
    }

    if(this.small){
      this.class += " "+"ppevo-panel-small"
    }

    if(this.autohide){
      setTimeout(
        ()=>{
          this.onClickClose()
        },
        this.autohide
      );
    }
  }


  onClickClose(){
    if(!this.notifyCloseOnly){
      this.hide();
    }
    
    this.close.emit();
  }

  show(){
    this.visible = true;
  }

  hide(){
    this.visible = false;
  }

}

import { DamageDescription, DocumentAddress, Residence } from './dsc-model-claims';
import { InjuryAsset } from './dsc-model-injury';
import { CodiceDescrizione } from './dsc-model-utility';

export class Party {
    role: any;
    insured: any;
    city: any;
    vehicleDamagingPlate: any;
    subject: Subject;
    totalReservedExcesses: any;
    totalCashedExcesses: any;
    damageDescriptions: DamageDescription[];
    totalReservedCompensation: any;
    vehicleRegistrationPlate: any;
    recoveries: any;
    amountIndemnity: any;
    province: any;
    excesses: any;
    surname: any;
    fiscalCode: any;
    reserve: any;
    email: any;
    birthProvince: any;
    identifier: any;
    compensations: any;
    amount: any;
    address: any;
    birthpostCode: any;
    technicalState: any;
    connectedClaimParties: any;
    createdByFlow: any;
    compensable: any;
    birthCity: any;
    birthDate: any;
    amountFees: any;
    serviceCompany: any;
    isMinor: boolean;
    totalOffers: any;
    minorGuardianSubject: Subject;
    listMinorGuardianSubject: Guardian[];
    phoneNumber: any;
    vehicleReferencePlate: any;
    name: any;
    totalCashedCompensation: any;
    operationsCardOutput: any;
    enumGuardianRole: any;
    postCode: any;
    operationsOutput: any;
    residenceProvince: any;
    policyHolder: any;
    partyType: string;
    partyRole: CodiceDescrizione;
}


export class AnagSubject {
    objectId: string = '';
    privacyConsensusType: CodiceDescrizione = new CodiceDescrizione();
    citizenshipPermit: string = null;
    language: string = null;
    gruppoAE: string = null;
    subjectType: CodiceDescrizione = new CodiceDescrizione();
    birthName: string = null;
    denomination: string = null;
    originalName: string = null;
    dynamicObjects: [];
    countryOfBirth: string = null;
    links: [];
    residence: Residence = new Residence();
    privacyConsensus: [];
    idLatestPhotos: string = null;
    sottogruppoAE: string = null;
    professionType:CodiceDescrizione = new CodiceDescrizione();
    uniqueKey: string = '';
    vat: string = null;
    counterTerrorismStatus: CodiceDescrizione = new CodiceDescrizione();
    codAteco: string = null;
    node: string = null;
    corporateSector: string = null;
    mobilePhone: [];
    corporateForm: string = null;
    name: string = '';
    salutation: string = null;
    partyNumber: string = null;
    privacyConsensusDate: string = null;
    maritalStatus: string = null;
    nominative: string = "";
    limitation: false;
    citizenshipExpire: string = null;
    taxClassifications: [];
    dateOfDeath: string = null;
    politicallyExp: string = null;
    payments: [];
    document: string = null;
    counterTerrorismDate: string = null;
    professionDetail: CodiceDescrizione = new CodiceDescrizione();
    landlineNumber: string = null;
    emails: [];
    mainContact: string = null;
    genderIdentity: CodiceDescrizione = new CodiceDescrizione();
    cityOfBirth: string = null;
    provinceOfBirth: string = null;
    surname: string = '';
    subsysReferences: string = null;
    fiscalCode: string = '';
    otherAddress: [];
    robinsonList: false;
    company: string = null;
    iddQuestionnaires: [];
    mainSubject: string = null;
    personType:  CodiceDescrizione = new CodiceDescrizione();
    outcome: string = null;
    sonsNumber: string = null;
    sex: CodiceDescrizione = new CodiceDescrizione();
    citizenship: CodiceDescrizione = new CodiceDescrizione();
    originalSurname: string = null;
    groups: [];
    dateOfBirth: string = null;
    clientNumber: string = null;
    eavObject: {
        SUBJECT_EXTENSION_ROOT: {}
    };
    subjectNodes: [];
    extensionSubject: {
        properties: []
    };
    educationalQualification: string = null;
    partyKey: [];
    birthAddress: string = null;
    bondingCode: string = null;
    phoneFax: string = null;
    regCountry: string = null
}

export class PartyRoleDetail {
    codice: string;
    descrizione: string;
}


export class Subject {
    anagSubject: AnagSubject = new AnagSubject();
    objectId: string = '';
    idLatestPhotos: string = '';
    chkInjury: boolean;
    chkMaterial: boolean;
    claimsAddress: any = null;
    givenName: string = '';
    birthDistrict: any = null;
    birthDate: any = null;
    extensionData: any = null;
    materialDescription: string | null;
    externalKey: any = null;
    fatalityDate: any = null;
    surname: string;
    nominative: string;
    fiscalCode: string;
    gender: CodiceDescrizione = new CodiceDescrizione();
    vatCode: any = null;
    partyType: string = '';
    subjectInjuryAsset: InjuryAsset = new InjuryAsset();
    heirsList: string[] = [];
    heir: boolean = false;
    tipoSoggetto: CodiceDescrizione = new CodiceDescrizione()
}


export class SubjectReduced {
    objectId: string = '';
    givenName: string = '';
    surname: string = '';
    nominative: string = '';
    fiscalCode: string = '';
    vatCode: string = '';
    formatAddress: string = '';
    number: string = '';
    email: string = '';
    dateOfBirth?: Date = null;
    chkMaterial?: boolean = false;
    materialDescription?: string = '';
    chkInjury?: boolean = false;
    isValidForm?: boolean = false;
    subject?: Subject = new Subject();
    subjectDataFormInjury: InjuryAsset = new InjuryAsset();
    isPassenger?: boolean;
    isDriver?: boolean;
    isOwner?: boolean = true;
    partyRole?: PartyRoleDetail = new PartyRoleDetail();
    enumSubjectType?: CodiceDescrizione = null;
    isIncapacitated?: boolean;
    isMinor?: boolean;
    listMinorGuardianSubject?: Guardian[] = [];
}

export class SubjectData {
    subject: SubjectReduced = new SubjectReduced();
    dataForm?: InjuryAsset;
    roles?: any;
}

export class Guardian {
    minorGuardianSubject: Subject; // to be defined
    enumGuardianRole: string;
}

export class Heir {
    notes: any;
    linkSubjectNominative: string;
    linkType: CodiceDescrizione;
    linkedPartyUniqueKey: string;
    linkedPartyObjectId: string;
    idSubjectLinkType: string;
    checked: boolean;
}

export class HeirIdNominative {
    objectId: string;
    nominative: string
}


export interface Key2 {
    label: any;
    state: string;
    type: CodiceDescrizione;
    value: any;
}

export interface Key3Type {
    label: any;
    state: any;
    type: any;
    value: any;
}

export interface Key3 {
    label: any;
    state: any;
    type: any;
    value: any;
}

export interface Key1Type {
    codice: string;
    descrizione: string;
}

export interface Key1 {
    label: string;
    state: string;
    type: Key1Type;
    value: string;
}

export interface Key {
    objectId: string;
    key1: Key1;
    country: string;
    key2: Key2;
    extensionSubject: any;
    key3: Key3;
    main: boolean;
}

export interface PartyKey {
    objectId: string;
    key1: Key1;
    country: string;
    key2: Key2;
    extensionSubject: any;
    key3: Key3;
    main: boolean;
}

export interface Document {
    objectId: any;
    authoritiesRelease: CodiceDescrizione;
    documentType: DocumentType;
    releaseDate: string;
    extensionSubject: any;
    documentNumber: string;
    documentAddress: DocumentAddress;
    locationsRelease: string;
    expirationDate: string;
}

export interface BirthAddress {
    objectId: string;
    country: CodiceDescrizione;
    city: string;
    cab: any;
    normalized: boolean;
    latitude: string;
    placeAddress: any;
    number: any;
    toponym: any;
    cap: any;
    province: CodiceDescrizione;
    addressAddition: any;
    specialMention: any;
    countryCode: string;
    adminLevel3Short: any;
    longitude: string;
    formatAddress: string;
    adminLevel3: string;
    adminLevel1: any;
    subLocality: any;
    adminLevel2: string;
    locality: any;
    detailCode: any;
    adminLevel2Short: string;
    fraction: any;
    at: any;
    postalAddress: any;
    extensionSubject: any;
    adminLevel1Short: any;
}

export interface FiscalCode {
    objectId: string;
    label: string;
    state: string;
    type: Key1Type;
    value: string;
}

export class HeirsListSubjLink {
    objectId: string = '';
    nodeId: string = '';
    potentialHeirs: Heir[] = [];
    heirs: Heir[];
  }
export class HeirsListContainer {
    driver: HeirsListSubjLink = new HeirsListSubjLink();
    policyHolder: HeirsListSubjLink = new HeirsListSubjLink();
    owner: HeirsListSubjLink = new HeirsListSubjLink();
  }
  
  
  export class AuthoritiesRelease {
    codice: string;
    descrizione: string;
  }
  
  export class Document {
    objectId: any;
    authoritiesRelease: AuthoritiesRelease;
    documentType: DocumentType;
    releaseDate: string;
    extensionSubject: any;
    documentNumber: string;
    drivingLicense: any[];
    documentAddress: DocumentAddress;
    locationsRelease: string;
    expirationDate: string;
  }

  export class ProfessionDetail {
    codice: string;
    descrizione: string;
  }
  
  export class GenderIdentity {
    codice: string;
    descrizione: string;
  }
  
  export class Citizenship {
    codice: string;
    descrizione: string;
  }
  
  export class PartyKey {
    objectId: string;
    key1: Key1;
    country: string;
    key2: Key2;
    extensionSubject: any;
    key3: Key3;
    main: boolean;
  }
  
  export class Key1 {
    label: string;
    state: string;
    type: Type;
    value: string;
  }
  
  export class Type {
    codice: string;
    descrizione: string;
  }
  
  export class Key2 {
    label: any;
    state: string;
    type: Type;
    value: any;
  }
  
  export class Key3 {
    label: any;
    state: any;
    type: any;
    value: any;
  }
  
  export class BondingCode {
  }
  
  export class RoleType {
    codice: string;
    descrizione: string;
  }
  
  
  export class ProfessionType {
    codice: string;
    descrizione: string;
  }
  
  export class CounterTerrorismStatus {
    codice: string;
    descrizione: string;
  }
  
  export class PersonType {
    codice: string;
    descrizione: string;
  }
  
  export class EavObject {
    SUBJECT_EXTENSION_ROOT: {};
  }
  
  export class ExtensionSubject {
    properties: Property[];
  }
  
  export class Property {
    chiave: string;
    valore: string;
  }
  



import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {GPExtensionDataSection, GPPolicyFieldUpdate} from '../../group-policy-models/group-policy-issue-policy-data';

@Component({
  selector: 'rgi-gp-group-policy-extension-data',
  templateUrl: './group-policy-extension-data.component.html'
})
export class GroupPolicyExtensionDataComponent {

  /*da rinominare */
  @Input() extensionData: GPExtensionDataSection[];
  @Input() parentForm: UntypedFormGroup;
  @Output() fieldUpdate: EventEmitter<GPPolicyFieldUpdate> = new EventEmitter<GPPolicyFieldUpdate>();
  @Output() customAction = new EventEmitter();


  onPolicyDataExtensionUpdate($event: GPPolicyFieldUpdate) {
    if ($event) {
      this.fieldUpdate.emit($event);
    }
  }

  onFieldAction($event) {
    if ($event) {
      this.customAction.emit($event);
    }
  }

}

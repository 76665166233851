import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PV_TOKEN } from '../../models/consts/lpc-consts';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';

@Component({
  selector: 'lpc-installment-cancellation',
  templateUrl: './installment-cancellation.component.html',
  styleUrls: ['./installment-cancellation.component.css']
})
export class InstallmentCancellationComponent extends AbsOperationComponent implements OnInit {

  protected operationDataKey: string;
  public installments: any[];

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected anagService: AnagService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService, plcQuestService, authService, anagService);
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      cancelInstallment: new UntypedFormControl(null, [Validators.required] )
    });
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(this.createDraft().subscribe());
  }

  protected updateDraft(step: string, reload?: boolean): Observable<any> {
    return super.updateDraft(step, reload).pipe(
      tap(result => {
        this.installments = result.definitions.installmentToCancel;
      })
    );
  }

  protected getTransformedOperationData(): any {
    return {
      idMovement: this.formGroup.getRawValue().cancelInstallment
    };
  }


}

import {Component, ComponentRef, EventEmitter, OnInit, Output} from '@angular/core';

import {Modal} from '../../modal';
import {CommissionsElement} from './commissions-element';
import {CommissionsService} from './commissions.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {CommissionTable} from './commission-table';
import {ProposalService} from '../../proposal.service';
import {DecimalPipe} from '@angular/common';

@Component({
  selector: 'mic-commissions',
  templateUrl: './commissions-modal.component.html',
  styleUrls: ['./commissions-modal.component.scss'],
  providers: [DecimalPipe]
})
export class CommissionsModalComponent implements Modal, OnInit {

  componentRef: ComponentRef<any>;
  nextInstalmentCommissions: Array<CommissionsElement>;
  subscriptionInstalmentCommissions: Array<CommissionsElement>;
  nextInstalmentTotal: CommissionsElement;
  subscriptionInstalmentTotal: CommissionsElement;
  derogate = false;
  editAmount = false;
  status;
  commissionsForm: UntypedFormGroup;
  commissionsDerogationForm: UntypedFormGroup;
  @Output() eventPropagation: EventEmitter<any> = new EventEmitter<any>();
  isProposal = false;

  constructor(
    protected commissionsService: CommissionsService,
    protected proposalService: ProposalService,
    protected formBuilder: UntypedFormBuilder,
    protected decimalPipe: DecimalPipe
  ) {
  }

  ngOnInit() {
    this.initCommissions();
    this.isProposal = this.proposalService.isProposal();
  }

  getFormattedValue(num: number) {
    return this.decimalPipe.transform(num, '1.2-2');
  }

  currencyFormatDE(num: number) {
    return Number(num.toLocaleString('de-DE', {minimumFractionDigits: 2}));
  }

  applyDerogations() {
    if (!this.derogate) {
      return;
    }
    const purchasePercDerogation = this.commissionsDerogationForm.get('purchasePercDerogation').value;
    const purchaseAdditionalPercDerogation = this.commissionsDerogationForm.get('purchaseAdditionalPercDerogation').value;
    const collectionPercDerogation = this.commissionsDerogationForm.get('collectionPercDerogation').value;
    const collectionAdditionalPercDerogation = this.commissionsDerogationForm.get('collectionAdditionalPercDerogation').value;

    this.subscriptionInstalmentCommissions.forEach(
      (commissionElement) => {
        commissionElement.derogate = true;
        if (!commissionElement.fees && !commissionElement.total && commissionElement.derogate) {
          if (purchasePercDerogation !== null && purchasePercDerogation !== undefined) {
            this.commissionsForm.get('purchasePerc-' + commissionElement.key).setValue(purchasePercDerogation);
          }
          if (purchaseAdditionalPercDerogation !== null && purchaseAdditionalPercDerogation !== undefined) {
            this.commissionsForm.get('purchaseAdditionalPerc-'
              + commissionElement.key).setValue(purchaseAdditionalPercDerogation);
          }
          if (collectionPercDerogation !== null && collectionPercDerogation !== undefined) {
            this.commissionsForm.get('collectionPerc-' + commissionElement.key).setValue(collectionPercDerogation);
          }
          if (collectionAdditionalPercDerogation !== null && collectionAdditionalPercDerogation !== undefined) {
            this.commissionsForm.get('collectionAdditionalPerc-'
              + commissionElement.key).setValue(collectionAdditionalPercDerogation);
          }
        }
      }
    );
  }

  save() {
    if (!this.derogate) {
      return;
    }
    this.subscriptionInstalmentCommissions.forEach(
      (commission) => {
        if (commission.derogate) {
          commission.purchasePercentage =
            this.commissionsForm.get('purchasePerc-' + commission.key).value;
          commission.purchaseAmount =
            this.commissionsForm.get('purchaseAmount-' + commission.key).value;
          commission.purchaseAdditionalPercentage =
            this.commissionsForm.get('purchaseAdditionalPerc-' + commission.key).value;
          commission.purchaseAdditionalAmount =
            this.commissionsForm.get('purchaseAdditionalAmount-' + commission.key).value;
          commission.collectionPercentage =
            this.commissionsForm.get('collectionPerc-' + commission.key).value;
          commission.collectionAmount =
            this.commissionsForm.get('collectionAmount-' + commission.key).value;
          commission.collectionAdditionalPercentage =
            this.commissionsForm.get('collectionAdditionalPerc-' + commission.key).value;
          commission.collectionAdditionalAmount =
            this.commissionsForm.get('collectionAdditionalAmount-' + commission.key).value;
        }
      }
    );
    const commissions = new CommissionTable(this.subscriptionInstalmentCommissions,
      this.nextInstalmentCommissions, this.status, this.editAmount, this.derogate);
    this.commissionsService.updateCommissions(commissions).subscribe(
      (data) => {
        this.loadCommissions(data);
      }
    );
  }

  close() {
    this.componentRef.destroy();
  }

  private initCommissions() {
    this.commissionsService.getCommissions().subscribe(
      (commissions) => {
        this.loadCommissions(commissions);
      }
    );

    this.commissionsDerogationForm = this.formBuilder.group({
      purchasePercDerogation: [],
      purchaseAdditionalPercDerogation: [],
      collectionPercDerogation: [],
      collectionAdditionalPercDerogation: []
    });
  }

  private loadCommissions(commissions: CommissionTable) {
    this.nextInstalmentCommissions = commissions.nextInstallment;
    this.subscriptionInstalmentCommissions = commissions.subscription;
    this.commissionsForm = this.commissionsService.toFormGroup(this.subscriptionInstalmentCommissions);
    this.derogate = commissions.derogate;
    this.status = commissions.status;
    this.editAmount = commissions.updateAmount;

    this.nextInstalmentTotal = this.nextInstalmentCommissions.find(
      (element) => {
        return element.total;
      }
    );

    this.subscriptionInstalmentTotal = this.subscriptionInstalmentCommissions.find(
      (element) => {
        return element.total;
      }
    );
  }
}

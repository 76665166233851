import {Component, Inject} from '@angular/core';
import {RoutableComponent} from '@rgi/rx/router';
import {PNC_PSALES_OP_CONTEXT} from '../rgi-pnc-postsales.api';
import {RgiPncPsalesStateManager} from '../rgi-pnc-postsales-state.manager';
import {PncPsalesBaseState} from '../../resources/states/pnc-postsales-state';
import {PNC_PSALES_ACTIONS} from '../../resources/constants/actions';

@Component({
  selector: 'rgi-pnc-psales-route-wrapper',
  templateUrl: './pnc-psales-route-wrapper.component.html',
})
export class PncPsalesRouteWrapperComponent extends RoutableComponent {

  steps = [];
  constructor(
    public stateMgr: RgiPncPsalesStateManager<PncPsalesBaseState>,
    @Inject(PNC_PSALES_OP_CONTEXT) public ctx: any
  ) {
    super();
    this.steps = ctx.stepLabels.filter((s: string) => s !== '');
    // console.log(ctx);
  }

  get activeRoute() {
    return this.stateMgr.activeRoute;
  }

  onAction(action: PNC_PSALES_ACTIONS | string) {
    if (action === PNC_PSALES_ACTIONS.CONTINUE) {
      const state = this.stateMgr.getCurrentState();
      const formStatus = state.formStatus;
      if (formStatus !== 'INVALID') {
        this.stateMgr.onAction(action);
      }
    } else {
      this.stateMgr.onAction(action);
    }
  }
}

import { EnumType } from '@rgi/digital-claims-common-angular';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ApiClaimsParameters } from './api-claims-parameters';

export class ApiNewRequestFilter {
    codeAction: string;
    claimsConnectorActionsParameterEntity: ApiClaimsParameters[];
    requestType: string;
    elaborationRequestDate: number;
    externalKey: string;
    codeChain: string;

}



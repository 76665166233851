import {Inject, Injectable, Optional} from '@angular/core';
import {Decorator, RGI_RX_DECORATOR} from './extension.api';
import {LoggerFactory} from '../logging/logging-providers';

@Injectable()
/**
 * @description provide the decorators to be consumed by a decorator directive.
 * Root decorators are resolved by the RgiRxDecoratorConfig, and view decorators are resolved by the RGI_RX_DECORATOR token.
 * Notifies if the view decorator overrides a root decorator using the logger.
 * @see RgiRxDecoratorConfig
 */
export class RgiRxDecoratorService {

  private readonly decorators: Map<string, Decorator>;
  private readonly logger = LoggerFactory();

  constructor(config: RgiRxDecoratorConfig, @Optional() @Inject(RGI_RX_DECORATOR) decorators: Decorator[]) {
    this.decorators = config.decorators;
    if (decorators) {
      decorators.forEach(
        decorator => {
          if (this.decorators.has(decorator.selector) && this.decorators.get(decorator.selector).decorator !== decorator.decorator){
              this.logger.debug(`RgiRxDecorator::init root decorator with selector ${decorator.selector} has been overridden by another view decorator.`,  {
                old: this.decorators.get(decorator.selector),
                new: decorator
              });
          }
          this.decorators.set(decorator.selector, decorator)
        }
      )
    }
  }


  getDecorator(selector: string): Decorator | undefined {
    return this.decorators.get(selector);
  }
}

@Injectable({
  providedIn: 'root'
})
  /**
   * @description provide the root decorator configuration as a cache, preventing to be computed multiple times.
   */
class RgiRxDecoratorConfig {
  private _decorators = new Map<string, Decorator>();
  private readonly logger = LoggerFactory();

  constructor(@Optional() @Inject(RGI_RX_DECORATOR) decorators?: Decorator[]) {
    if (!decorators) {
      return;
    }
    decorators.forEach(
      decorator => {
        if (this._decorators.has(decorator.selector)) {
          this.logger.debug(`RgiRxDecorator::init root decorator with selector ${decorator.selector} has been overridden by another root decorator.`, {
            old: this._decorators.get(decorator.selector),
            new: decorator
          });
        }
        this._decorators.set(decorator.selector, decorator)
      }
    )
  }

  get decorators(): Map<string, Decorator> {
    return this._decorators;
  }
}

import {PortalCard, PortalCards} from '@rgi/rx/portal';

export const ADAPTERPRINT_CARD: PortalCards = [

  {

    card: {

      name: 'newCardAdapterPrint',

      type: 'main',

      category: 'Cross',

      title: '_START_ADAPTERPRINT_',

      navigationInSession: true,

      subCards: [],


    } as PortalCard,
    routes: [
      {
        type: 'home',
        route: 'home',
        label: '_START_ADAPTERPRINT_',
        destination: 'card.adapterprint.home'
      },
      {
        route: 'result',
        label: '_RESULT_ADAPTERPRINT_',
        destination: 'card.adapterprint.result'
      }]

  }

];

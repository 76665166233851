import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * @author: dmasone
 * @description: Modal component used for show issue step errors
 */
@Component({
    selector: 'lic-issue-modal-error',
    templateUrl: './issue-modal-error.component.html',
    styleUrls: ['./issue-modal-error.component.scss']
})
export class LicIssueModalErrorComponent {

    public title: string;
    public message: string;

    constructor(
        protected activeModal: NgbActiveModal
    ) { }

    onClose() {
        this.activeModal.close();
    }

}

import {ComponentRef, EventEmitter, Injectable, InjectionToken, StaticProvider, Type} from '@angular/core';
import {RgiRxRoutingHostDirective} from './directives/rgi-rx-routing-host.directive';
import {Observable, Subject} from 'rxjs';
import {RgiRxRouteLocationOptions} from './location/router.location.api';
import {RgiRxRouterTitleResolver, RgiRxRouterTitleStrategy} from './router.title-strategy';

export const ROUTE_DEF_WITH_WILDCARDS_PATTERN = /(^[$()*,;~!_+.\-\/\w\s ]*$)|{\s?([^{}]{1,})\s?}/;

export type RgiRxRouteData<T> = {
  readonly [P in keyof T]: T[P];
};

export interface RgiRxQueryData {
  readonly [key: string]: string;
}


export abstract class RgiRxRouterOutlet {
  /**
   * @deprecated The outlet will no longer accept route as input.
   */
  route?: string;
  id?: string;
  currentRoute?: string;
  options?: any;
  routeChangeOpts?: RouteChangeOptions;
  host?: RgiRxRoutingHostDirective;
  routableComponent?: RoutableComponent;
  /**
   * Deprecated, use activated instead
   */
  onChange: EventEmitter<ActiveRoute>;
  activated: EventEmitter<ActiveRoute>;
  ready: EventEmitter<void>
}


export interface Route {
  /**
   * The route path identifier
   * @example my-page
   */
  route: string;
  /**
   * The component to be rendered as route
   */
  component: Type<any>;

  title?: string | Type<RgiRxRouterTitleResolver>;
  /**
   * Static providers to be created for that route.
   * Those providers lifespan will end when the route become inactive or has been destroyed
   */
  providers?: StaticProvider[];
  /**
   * Hooks to be computed when the route is getting activated.
   * If they resolve false, the transition will be cancelled
   */
  canActivate?: Type<RgiRxCanActivate>[];
  /**
   * Hooks to be computed when the route children routes are getting activated.
   * If they resolve false, the transition will be cancelled
   */
  canActivateChild?: Type<RgiRxCanActivateChild>[];
  /**
   * Hooks to be computed when a transition from this route has been requested.
   * If they resolve false, the transition will be cancelled
   */
  canDeActivate?: Type<RgiRxCanDeactivate<any>>[];
  /**
   * Children routes
   */
  children?: Route[];

  context?: any;
}


export interface RgiRxRouterOptions {
  /**
   * Whether a route override should be merged with existing Routes
   */
  mergeExisting?: boolean;
  originTitle?: string;
}

export interface RoutesWithOptions {
  routes: Routes;
  options: RgiRxRouterOptions;
}

/**
 * A route snapshot is a route fragment that has been scheduled for transitions,
 * it may never become an ActiveRoute if all the guards and checks fails.
 */
export interface RgiRxRouteSnapshot extends RgiRxRouteFragment {
  /**
   * A componentRef, if the current snapshot it's activated
   */
  componentRef?: ComponentRef<any>;
}

export type RgiRxRouteOptionType = RgiRxRouteOptions | RgiRxRouteLocationOptions | any;


export interface RgiRxRouteFragment<DATA = any, CTX = any> {
  /**
   * The target route
   */
  route: string;
  /**
   * The route id
   */
  id?: any;
  /**
   * Any Route data of the fragment
   */
  routeData?: RgiRxRouteData<DATA>;
  /**
   * Any route option of the route
   */
  options?: RgiRxRouteOptionType;

  context?: CTX;

  title?: string;

  uriDef?: RgiRxURIDef;
}


export type RgiRxActiveRouteKeyFn = (activeRoute: RgiRxActiveRoute) => string;

export class RgiRxActiveRoute<COMPONENT = any, DATA = any, CTX = any, STATE = any> {

  destroy = new Subject<RgiRxActiveRoute>();
  #uriDef?: RgiRxURIDef;
  private _title?: string;

  constructor(
    private component: Type<COMPONENT>,
    private readonly _route: string,
    private readonly routeData?: RgiRxRouteData<DATA>,
    private readonly _id?: string,
    private readonly _routeOptions?: RgiRxRouteOptionType,
    private _componentRef?: ComponentRef<any>,
    private readonly _context?: CTX,
    private readonly opts?: RgiRxRouterConfig,
  ) {
    this.routeData = routeData;
  }

  getRouteData(): RgiRxRouteData<DATA> {
    return this.routeData;
  }

  getComponent(): Type<COMPONENT> {
    return this.component;
  }


  get componentRef(): ComponentRef<COMPONENT> {
    return this._componentRef;
  }


  set componentRef(value: ComponentRef<COMPONENT>) {
    this._componentRef = value;
    if (this.componentRef) {
      this.componentRef.onDestroy(() => {
        this.destroy.next(this);
        this.destroy.complete();
      });
    }
  }

  get route(): string {
    return this._route;
  }

  get id(): string {
    return this._id;
  }

  get key(): string {
    if (this.opts && this.opts.activeRouteKeyStrategy) {
      return this.opts.activeRouteKeyStrategy(this);
    }
    if (this.uriDef) {
      return `${this.uriDef.path}`;
    }
    return this.id ? `${this.route}_${this.id}` : this.route;
  }

  get routeOptions(): RgiRxRouteOptionType {
    return this._routeOptions;
  }


  get context(): CTX {
    return this._context;
  }


  get uriDef(): RgiRxURIDef {
    return this.#uriDef;
  }

  set uriDef(value: RgiRxURIDef) {
    this.#uriDef = value;
  }


  get title(): string | undefined {
    return this._title;
  }

  set title(value: string | undefined) {
    this._title = value;
  }


  get state(): STATE | undefined {
    return this.routeOptions?.state;
  }

  /**
   * Create a snapshot of this active route
   */
  snapshot(): RgiRxRouteSnapshot {
    return {
      route: this.route,
      routeData: this.getRouteData(),
      id: this.id,
      componentRef: this._componentRef,
      context: this.context,
      options: this.routeOptions,
      title: this._title,
      uriDef: this.uriDef
    };
  }

  destroy$(): Observable<ActiveRoute> {
    return this.destroy.asObservable();
  }
}


/**
 * The actual ActiveRoute instance.
 * This type is a wrapper of RgiRxActiveRoute that is fully typed.
 * For type safety use RgiRxActiveRoute instead
 * @see RgiRxActiveRoute
 */
export class ActiveRoute extends RgiRxActiveRoute {
  getRouteData<T>(): RgiRxRouteData<T> {
    return super.getRouteData();
  }

  getComponent<T>(): Type<T> {
    return super.getComponent();
  }
}


export type Routes = Route[];
export type RgiRxRoutes = Routes | RoutesWithOptions;
export const ROUTES = new InjectionToken<RgiRxRoutes>('ROUTES');


/**
 * Abstract class for a Routable component.
 * Extending this class for a route component allows the component to access directly via this the route and the id without
 * injecting the ActiveRoute.
 * @see ActiveRoute
 */
export abstract class RoutableComponent {
  private _id: string;
  private _route: string;
  private _tickHostChangeCallback = () => {
  }

  get route(): string {
    return this._route;
  }

  set route(value: string) {
    this._route = value;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  tickHostChange() {
    this._tickHostChangeCallback();
  }

  set tickHostChangeCallback(value: () => void) {
    this._tickHostChangeCallback = value;
  }
}


export abstract class RouteComponentLoader {
  abstract load<T>(event: ActiveRoute, host: RgiRxRoutingHostDirective, providers?: StaticProvider[]): ComponentRef<T>;
}


/**
 * @deprecated these options are no longer needed
 */
export interface RouteChangeOptions {

  /**
   * @deprecated the destruction of the origin route is now handled by the ActiveRoute listeners.
   * Once was used to trigger the removal of the ActiveRoute from the @rgi/portal legacy binding
   */
  destroyOrigin?: boolean;
}


export type RgiRxGuardResult = Observable<boolean | RgiRxRouteFragment> | boolean | RgiRxRouteFragment;

export function isGuardResultFragment(result: RgiRxGuardResult): result is RgiRxRouteFragment {
  return (result as RgiRxRouteFragment).route !== undefined;
}


export interface RgiRxCanActivate {
  canActivate(routeSnapshot: RgiRxRouteSnapshot): RgiRxGuardResult;
}

export interface RgiRxCanActivateChild {
  canActivateChild(routeSnapshot: RgiRxRouteSnapshot): RgiRxGuardResult;
}

export interface RgiRxCanDeactivate<T> {
  canDeactivate(component: T, routeSnapshot: RgiRxRouteSnapshot): RgiRxGuardResult;
}


export class RgiRxRouteNotFoundError extends Error {
  constructor(route: string, message: string = '') {
    super(`RgiRxRouter:: no route matching ${route}! ${message}`);
  }
}

export interface RgiRxRouteOptions {
  /**
   * @description relative target when using relative routing
   */
  relativeTo?: ActiveRoute | RgiRxRouteSnapshot | RgiRxActiveRoute;
  /**
   * @description skip all guards during transition
   */
  skipGuards?: boolean;
  title?: {
    key: string,
    params: {[key: string]: any}
  };
  /**
   * @description any state object that is passed to the navigation ActiveRoute
   * When used with the RgiRxRouterLocationModule the state is preserved on the history browser state,
   * without the Location module the state will be available only within the ActiveRoute lifecycle.
   * Use the state to add state properties to a navigation that you don't want to be part of the ActiveRoute data,
   * so they are not serialized in the URI.
   * The State can accommodate any data structure but take note that this might become serialized in the browser
   * history stack as a file, depending on the Browser implementation.
   * @link https://developer.mozilla.org/en-US/docs/Web/API/History/state
   * @see ActiveRoute
   * @see RgiRxRouterLocationModule
   */
  state?: any;
}

export interface RgiRxURIDef {
  path: string;
  query?: string;
  url: string;
}

export interface RgiRxRouterConfig {
  activeRouteKeyStrategy?: RgiRxActiveRouteKeyFn;
}

export const RGI_RX_ROUTER_CONFIG = new InjectionToken<RgiRxRouterConfig>('RGI_RX_ROUTER_CONFIG');


export abstract class RgiRxRouterUrlHandlingStrategy {
  abstract shouldProcess(url: string): boolean;
}


@Injectable({
  providedIn: 'root'
})
export class RgiRxRouterDefaultUrlHandlingStrategy extends RgiRxRouterUrlHandlingStrategy {
  shouldProcess(url: string): boolean {
    return true;
  }
}


import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LicStepComponent} from './component/lic-step/lic-step.component';
import {LicStepperComponent} from './component/lic-stepper/lic-stepper.component';
import {LicNavigationButtonsComponent} from './component/lic-navigation-buttons/lic-navigation-buttons.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PortalNgCoreModule} from '@rgi/portal-ng-core';
import {LicInvestmentsComponent} from './component/lic-investments/lic-investments.component';
import {FormatterUtils} from './utils/FormatterUtils';
import {LicCheckboxAccordionComponent} from './component/lic-checkbox-accordion/lic-checkbox-accordion.component';
import {LicInvestmentContractModule} from '../lic-investment-contract/lic-investment-contract.module';
import {LicFundModule} from '../lic-fund/lic-fund.module';
import {LicCheckboxAccordionModule} from './component/lic-checkbox-accordion/lic-checkbox-accordion.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustomNumberModule } from '../custom-number/custom-number.module';
import {
  LicFinantialParamCalculatorPipe
} from '../lic-fund/component/lic-finantial-param-calculator/pipe/lic-finantial-param-calculator.pipe';
import { LicKarmaFundModule } from '../lic-karma-funds/lic-karma-fund.module';
import { LicToolsModule } from '../lic-tools/lic-tools.module';
import { RgiCountryLayerModule } from '@rgi/country-layer';
import { ScheduledPremiumComponent } from './component/lic-scheduled-premium/lic-scheduled-premium.component';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LicInvestmentsModalErrorComponent } from './component/lic-investments-modal-error/lic-investments-modal-error.component';
import { LicRequiredModule } from '../utils/lic-required/lic-required.module';
import { RgiRxModalModule, RgiRxPanelModule, RgiRxSnackbarModule } from '@rgi/rx/ui';

@NgModule({
    declarations: [
        LicStepComponent,
        LicStepperComponent,
        LicNavigationButtonsComponent,
        LicInvestmentsComponent,
        LicFinantialParamCalculatorPipe,
        ScheduledPremiumComponent,
        LicInvestmentsModalErrorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        LicInvestmentContractModule,
        LicCheckboxAccordionModule,
        LicFundModule,
        CustomNumberModule,
        LicToolsModule,
        LicKarmaFundModule,
        NgxPaginationModule,
        RgiCountryLayerModule,
        RgiRxI18nModule,
        LicRequiredModule,
        RgiRxModalModule,
        RgiRxPanelModule,
        RgiRxSnackbarModule
    ],
    providers: [
        FormatterUtils
    ],
    exports: [
        LicStepComponent,
        ScheduledPremiumComponent,
        LicStepperComponent,
        LicNavigationButtonsComponent,
        LicCheckboxAccordionComponent,
        LicFinantialParamCalculatorPipe
    ]
})
export class InvestStandaloneSessionModule {
}

import {RGI_RX_LOCALE} from '@rgi/rx/i18n';

/**
 * @description Parse and Reverse Int.NumberFormat strings again into numbers.
 * Derived from Mike Bostock implementation with changes to support floating numbers.
 * @link https://observablehq.com/@mbostock/localized-number-parsing
 */
export class RgiCountryLayerNumberParser {
  private readonly group: RegExp;
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private readonly _decimal: RegExp;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private readonly numeral: RegExp;
  private readonly index: (d: any) => any;
  constructor(locale: RGI_RX_LOCALE) {
    const format = new Intl.NumberFormat(locale);
    const parts = format.formatToParts(12345.6);
    const numerals = [...new Intl.NumberFormat(locale, {useGrouping: false}).format(9876543210)].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this.group = new RegExp(`[${parts.find(d => d.type === 'group').value}]`, 'g');
    this._decimal = new RegExp(`[${parts.find(d => d.type === 'decimal').value}]`);
    this.numeral = new RegExp(`[${numerals.join('')}]`, 'g');
    this.index = d => index.get(d);
  }
  parse(value: string): number {
    const replaced = value.trim()
      .replace(this.group, '')
      .replace(this._decimal, '.')
      .replace(this.numeral, this.index);
    return replaced ? this.parseNumber(replaced) : NaN;
  }


  get decimal(): RegExp {
    return this._decimal;
  }

  private parseNumber(replaced: string): number {
    const split = replaced.split('.');
    if (split.length === 2) {
      return parseFloat(replaced);
    }
    return parseInt(replaced, 10);
  }
}

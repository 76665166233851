import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {
  GPAsset,
  GpAssetUnitsClauses,
  GpUnitClause,
  QuotationGuarantees,
  Units
} from '../../group-policy-models/group-policy-issue-guarantees';
import {UpdateUnitEvent} from '../group-policy-units/group-policy-unit.component';
import {UntypedFormGroup} from '@angular/forms';
import {BaseEntity, Factor, GPClause} from '../../group-policy-models/group-policy-issue-policy-data';
import {GPVariableService} from '../../group-policy-services/gpvariable.service';
import {GroupPolicyDateAdapter} from '../../adapters/ngbDateCustomParserFormatter ';
import {
  GroupPolicyStatemanagerGuarantees
} from '../../group-policy-state/group-policy-statemanager-guarantees/group-policy-statemanager-guarantees';

@Component({
  selector: 'rgi-gp-assets',
  templateUrl: './group-policy-assets.component.html'
})
export class GroupPolicyAssetsComponent implements OnChanges {

  @Input() assets: GPAsset[];
  @Input() assetsClauses: GpAssetUnitsClauses[];
  @Input() quotations: QuotationGuarantees[];
  @Input() accordionMap: Map<string, boolean>;
  @Input() parentForm: UntypedFormGroup;
  @Input() unitInitialStatus: BaseEntity[];

  @Output() updateAsset = new EventEmitter<GPAsset>();
  @Output() deleteAsset = new EventEmitter<string>();
  @Output() update = new EventEmitter<string>();
  @Output() updateClauses = new EventEmitter<GpAssetUnitsClauses>();
  @Output() reloadAssets = new EventEmitter<boolean>();

  assetVariablesFGMap = new Map<string, UntypedFormGroup>();

  constructor(
    public variableService: GPVariableService,
    public dateAdapter: GroupPolicyDateAdapter,
    public stateMng: GroupPolicyStatemanagerGuarantees
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assets.isFirstChange() || JSON.stringify(changes.assets.currentValue) !== JSON.stringify(changes.assets.previousValue)) {
      this.assets.forEach(asset => this.createAssetVariablesFormGroup(asset.variables, asset.codeAsset));
    }
  }

  updateUnit($event: UpdateUnitEvent, asset: GPAsset) {
    if ($event) {
      this.updateAsset.emit(asset);
    }
  }

  isAssetExpanded(asset: GPAsset): boolean {
    return this.accordionMap.get(asset.codeAsset);
  }

  updateAccordionMap(expanded: boolean, asset: GPAsset) {
    this.accordionMap.set(asset.codeAsset, expanded);
  }

  onUpdateAssetClauses($event: GPClause[], asset: GPAsset) {
    const clauses: GpAssetUnitsClauses = {
      codeAsset: asset.codeAsset,
      assetClauses: $event
    };
    this.updateClauses.emit(clauses);
  }

  onUpdateUnitClauses($event: GpUnitClause, asset: GPAsset) {
    const clauses: GpAssetUnitsClauses = {
      codeAsset: asset.codeAsset,
      unitsClauses: [$event]
    };
    this.updateClauses.emit(clauses);
  }

  protected createAssetVariablesFormGroup(variables: Factor[], assetCode: string) {
    if (this.parentForm.get(assetCode + '_VARIABLES')) {
      this.variableService.updateVariablesFormGroup(variables, this.parentForm.get(assetCode + '_VARIABLES') as UntypedFormGroup);
    } else {
      const formGroup = this.variableService.createVariablesFormGroup(variables);
      this.parentForm.addControl(assetCode + '_VARIABLES', formGroup);
      this.assetVariablesFGMap.set(assetCode, formGroup);
    }
  }

  onVariableUpdate(variableToUpdate: Factor, assetCode: string) {
    const asset = this.assets.find(a => a.codeAsset === assetCode);
    const variableControl = this.assetVariablesFGMap.get(assetCode).get(variableToUpdate.code);
    this.variableService.updateVariableInList(variableToUpdate, variableControl.value, asset.variables);
    this.updateAsset.emit(asset);
  }

  trackAsset(_index: number, asset: GPAsset) {
    return asset.codeAsset;
  }

  trackUnit(_index: number, unitWrapper: Units) {
    return unitWrapper.unit.code;
  }

  reloadAsset($event: boolean) {
    if (!!$event) {
      this.reloadAssets.emit($event);
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {ActiveRoute, RoutableComponent} from '@rgi/rx/router';
import {
  GroupPolicyStatemanagerEdit
} from '../group-policy-state/group-policy-statemanager-edit/group-policy-statemanager-edit';
import {GroupPolicyStateEdit} from '../group-policy-state/group-policy-state';

@Component({
  selector: 'rgi-gp-group-policy-edit',
  templateUrl: './group-policy-edit.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyEditComponent extends RoutableComponent implements OnInit {

  public state: GroupPolicyStateEdit;

  constructor(protected activeRoute: ActiveRoute,
    protected stateManager: GroupPolicyStatemanagerEdit) {
    super();
  }

  ngOnInit() {
    this.stateManager.getState$().subscribe((nextState: GroupPolicyStateEdit) => {
      this.state = nextState;
    }
    );
  }

}

<div class="grid-item card card-height-normal card-main">
    <div class="modal-content">
        <div class="modal-header">
            <h3 class="modal-title">{{ '_CLAIMS_._NEW_MANUAL_DEADLINE' | translate }}
                <span class="rgifont rgi-close_fine pull-right" (click)="closeModal($event)"></span>
            </h3>
        </div>
        <form [formGroup]="formGroup">
            <div class="modal-body" style="padding-top: 5px">
                <h4 class="text-info bold">{{ '_CLAIMS_._DEADLINE_DATA' | translate }}</h4>
                <div class="form-group row">
                    <div class="col-sm-6 col-xs-12">
                        <pnc-datepicker label="{{ '_CLAIMS_._CREATION_DATE' | translate }}" 
                            customFormControlName="insDate"
                            [customFormGroup]="formGroup"
                            [disabled]="true"
                            attr.data-qa="manual-deadline-creation-date">
                        </pnc-datepicker>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <pnc-datepicker label="{{ '_CLAIMS_._EXPIRATION_DATE' | translate }}" 
                            customFormControlName="expirationDate"
                            [customFormGroup]="formGroup"
                            [disabled]="false" 
                            attr.data-qa="manual-deadline-expiration-date">
                            <pnc-required required="true"></pnc-required>
                        </pnc-datepicker>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6 col-xs-12">
                        <label ng-bind="label">{{ '_CLAIMS_._CATEGORY' | translate }} <pnc-required required="true"></pnc-required></label>
                        <select class="core-select form-control" formControlName="idActDict" id="category" name="category" 
                        (change)="onCategorySelected($event)"  attr.data-qa="manual-deadline-category-input">
                        <option></option>
                        <option *ngFor="let category of categories; " value={{category.idActDict}}>
                            {{category.dictionaryDescription}}</option>
                        </select>
                    <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <label ng-bind="label">{{ '_CLAIMS_._DAMAGE_POSITION' | translate }} <pnc-required required="true">
                            </pnc-required></label>
                        <select class="core-select form-control" formControlName="involvedParty" id="damagePosition" name="damagePosition"
                            attr.data-qa="manual-deadline-damagePosition-input" (change)="onDamagePositionOptionSelected($event)">
                            <option></option>
                            <option *ngFor="let damagePosition of damagePositions; " value={{damagePosition.identifier}}>
                                {{damagePosition.subject.anagSubject.nominative}}</option>
                        </select>
                        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6 col-xs-12">
                        <label ng-bind="label">{{ '_CLAIMS_._TYPE_OF_LOSS' | translate }} <pnc-required required="true">
                            </pnc-required></label>
                        <select class="core-select form-control" formControlName="typeOfLoss" id="typeOfLoss" name="typeOfLoss"
                            attr.data-qa="manual-deadline-typeOfLoss-input">
                            <option></option>
                            <option *ngFor="let typeOfLoss of filteredTypeOfLosses; " value={{typeOfLoss.damagesDescriptionId}}>
                                {{typeOfLoss.causative}}</option>
                        </select>
                        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <label ng-bind="label">{{ '_CLAIMS_._DESCRIPTION' | translate }} <pnc-required required="true"></pnc-required></label>
                        <textarea class="col-sm-16 form-control" formControlName="message" id="description"
                            rows="3" type="text" name="description" 
                            attr.data-qa="manual-deadline-description-input"></textarea>
                    </div>
                </div>
                <h4 class="text-info bold">{{ '_CLAIMS_._USER_DATA' | translate }}</h4>
                <!--div class="form-group row">
                    <div class="col-sm-6 col-xs-12 form-check form-check-inline" style="vertical-align: middle;">
                        <input type="checkbox" class="btn-checkbox checkbox-unit"
                            id="assignedToGroup" formControlName="assignedToGroup" (change)="onGroupCheckboxChange($event)" [disabled]="!enabled"
                            attr.data-qa="manual-deadline-chekbox-input-assign-to-group">
                            <label class="form-check-label checkbox-label">{{ '_CLAIMS_._ASSIGNED_TO_A_GROUP' | translate }}</label>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <label ng-bind="label">{{ '_CLAIMS_._GROUP' | translate }} <pnc-required required="true"></pnc-required></label>
                        <select class="core-select form-control" formControlName="assigneeGroup" id="group" name="group" 
                                    (change)='onGroupOptionSelected($event)' attr.data-qa="manual-deadline-group-input">
                        <option></option>
                        <option *ngFor="let group of groups;" value={{group.code}}>
                            {{group.description}}</option>
                        </select>
                    <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                    </div>
                </div-->
                <div class="form-group row">
                    <div class="col-sm-6 col-xs-12">
                        <label ng-bind="label">{{ '_CLAIMS_._GROUP' | translate }} <pnc-required required="true"></pnc-required></label>
                        <select class="core-select form-control" formControlName="assigneeGroup" id="group" name="group" 
                                    (change)='onGroupOptionSelected($event)' attr.data-qa="manual-deadline-group-input">
                        <option></option>
                        <option *ngFor="let group of groups;" value={{group.code}}>
                            {{group.description}}</option>
                        </select>
                    <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <label ng-bind="label">{{ '_CLAIMS_._USER' | translate }}({{ '_CLAIMS_._BY_WORKLOAD' | translate }}) <pnc-required *ngIf="setDefaultUser" required="true">
                            </pnc-required></label>
                        <select class="core-select form-control" formControlName="assigneeUser" id="user" name="user"
                            attr.data-qa="manual-deadline-user-input">
                            <option *ngIf="!setDefaultUser"></option>
                            <option *ngFor="let user of filteredActivityUsers;  let i = index" value={{user.code}}>
                                <span *ngIf="user.userRealName">{{i+1}} - {{user.userRealName}}</span>
                                <span *ngIf="!user.userRealName">{{i+1}} - {{user.code}}</span>
                            </option>
                        </select>
                        <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
                    </div>
                </div>
                <!--<div id="error-container" style="margin-top: 2em" *ngIf="validationMessages && validationMessages.length > 0">
                    <pnc-form-message type="error" detail="validationError" [messages]="validationMessages"
                        data-qa="manual-deadline-action-error-messages" objectType="complex"></pnc-form-message>
                </div>-->
            </div>

            <div class="modal-footer" *ngIf="enabled">
                <div class="btn-group btn-group-justified">
                    <div class="btn-group">
                        <button class="btn btn-warning text-uppercase" (click)="onConfirm()" data-qa="manual-deadline-confirm-button"
                            >{{ '_CLAIMS_._BUTTONS_._CONFIRM' | translate }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
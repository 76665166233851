import { TypeEnum } from './payment-constants';

export const INIT_TIPO_PAG_FATTURA: TypeEnum[] = [
    {
        eTypeCode: 'FATT', eTypeDescription: 'Invoice'
    },
    {
        eTypeCode: 'NOFATT', eTypeDescription: 'No Invoice'
    }
];

export const INIT_SUCCESS_SERVICE: TypeEnum[] = [
    { eTypeCode: 'OK', eTypeDescription: 'Success' },
    { eTypeCode: 'KO', eTypeDescription: 'Failure' }
];

export const INIT_ROLE_LEGALE_AVVERSARIO: TypeEnum[] = [
    { eTypeCode: '18', eTypeDescription: '18' }
];

export const INIT_TIPO_COASS: TypeEnum[] = [
    { eTypeCode: '1', eTypeDescription: 'No Co-Insurance' },
    { eTypeCode: '2', eTypeDescription: 'Delega liv polizza' },
    { eTypeCode: '4', eTypeDescription: 'Delega altrui' }
];

export const enum ENUMSLISTPAYMENT {
    ETIPOMOVLIQUIDAZQUIETANZA = '1',
    ETIPOMOVLIQUIDAZLIQUIDAZIONE = '2',
    SUCCESS_SERVICE = 'OK',
    FAILURE_SERVICE = 'KO',
    TIPO_PAG_FATTURA = 'FATT',
    TIPO_PAG_NO_FATTURA = 'NOFATT',
    ETIPOQUIETANZA_STANDARD = '1',
    ETIPOQUIETANZA_ONORARI_SEPARATI = '2',
    ETIPOQUIETANZA_OMNIA_COMPRESI = '3',
    ETIPOQUIETANZA_PROPOSTA_DI_TRANSAZIONE = '4',
    ETIPOPAGAMENTO_RISARCIMENTO = '1',
    ETIPOPAGAMENTO_INDENNIZZO = '2',
    ETIPOPAGAMENTO_SPESE_LEGALI = '3',
    ETIPOPAGAMENTO_PROVVISIONALE = '4',
    ETIPOPAGAMENTO_SPESE_CTU = '69',
    ETIPOPAGAMENTO_SPESE_CTP = '70',
    ETIPOPAGAMENTO_IMPOSTE_DI_REGISTRO = '71',
    ETIPOPAGAMENTO_RIMBORSO = '73',
    ETIPOPAGAMENTO_INTERVENTO_COMMERCIALE = '74',
    ETIPOPAGAMENTO_SPESE_DI_COMPAGNIA = '75',
    ETIPOPAGAMENTO_OFFERTA_REALE = '49',
    EROLE_LEGALE_AVVERSARIO = '18',
    ETIPOCOASS_NO_COASS = '1',
    ETIPOCOASS_DELEGA_NOSTRA = '2',
    ETIPOCOASS_DELEGA_ALTRUI = '4',
    ETIPODEFINIZCONT_TOTALE = '1',
    ETIPODEFINIZCONT_PARZIALE = '2',
    ETIPOBENEF_DANNEGGIATO = '1',
    ETIPOBENEF_ALTRO = '2',
    ETIPOBENEF_ASSICURATORE = '3',
    ETIPOBENEF_CARROZZERIA = '4',
    ETIPOBENEF_DATORE_LAVORO = '5',
    ETIPOBENEF_LEGALE = '6',
    ETIPOBENEF_FIDUCIARIO = '7',
    ETIPOBENEF_COMPAGNIA_DELEGATARIA = '8',
    ETIPOBENEF_BENEFICIARIO = '9',
    ETIPOBENEF_CONPAGNIA = '10',
    ETIPOBENEF_COMPAGNIA_CTP = '11',
    ETIPOBENEF_CTU = '12',
    ETIPOBENEF_CTP = '13',
    EGESTIONEPAGAMENTO_DIRETTA = '1',
    EGESTIONEPAGAMENTO_INDIRETTA = '2',
    EMEZZOPAGAMENTO_ASSEGNO_TRAENZA = '1',
    EMEZZOPAGAMENTO_BONIFICO = '2',
    EMEZZOPAGAMENTO_BONIFICO_ESTERO_EUR = '3',
    EMEZZOPAGAMENTO_ASSEGNO = '4',
    EMEZZOPAGAMENTO_DAFLUSSO = '5',
    EMEZZOPAGAMENTO_BONIFICO_ESTERO = '6',
    EMEZZOPAGAMENTO_F23 = '7',
    EMEZZOPAGAMENTO_F24 = '8',
    EADDRESSETYPE_ATTORE = '1',
    EADDRESSETYPE_LEGALE = '2',
    EADDRESSETYPE_ASSICURATORE = '3',
    EADDRESSETYPE_DATORE = '4',
    EADDRESSETYPE_AGENZIA = '5',
    EADDRESSETYPE_BENEFICIARIO = '6',
    EADDRESSETYPE_ASSICURATO = '7',
    ESHIPPINGCHANNEL_POSTA = '2',
    ESHIPPINGCHANNEL_RR = '3',
    ESHIPPINGCHANNEL_MAIL = '4',
    ESHIPPINGCHANNEL_POSTAMAIL = '5',
    ESHIPPINGCHANNEL_RRMAIL = '6',
    ESHIPPINGCHANNEL_PEC = '7',
    ESHIPPINGCHANNEL_FAX = '8',
    ECOERENZADANNOBENE_DANNO_COERENTE = '1',
    ECOERENZADANNOBENE_DANNO_DA_RIVALUTARE = '2',
    ECOERENZADANNOBENE_DANNO_DA_VALUTARE = '3',
    ECOERENZADANNOBENE_DANNO_NON_COERENTE = '4',
}

<form name="reinvestForm" [formGroup]="rinvestForm" (ngSubmit)="confirm()" novalidate>
    <div class="modal-header">
        <div class="modal-title" translate>
            Settlements to reinvest
        </div>
    </div>
    <div class="modal-body">
        <table class="table-responsive table coreDT">
            <thead>
                <tr class="head-result thDataTable">
                    <th class="select-row"><input type="checkbox" formControlName="selectAll" /></th>
                    <th translate>lic_Policynumber</th>
                    <th translate>lic_Type</th>
                    <th translate>lic_Beneficiary</th>
                    <th translate>lic_State</th>
                    <th translate>lic_Netavailable</th>
                    <th translate>lic_Nettoreinvest</th>
                </tr>
            </thead>
            <tbody formArrayName="settlementControls">
                <tr class="table-row" *ngFor="let settl of settlements | paginate:
              { id: 'reinvestment-table',
                itemsPerPage: pageSize,
                currentPage: page,
                totalItems: settlements.length }; let i = index" formGroupName="{{pageSize * (page - 1) + i}}">
                    <td><input type="checkbox" formControlName="select" /></td>
                    <td>{{settl.policyNumber}}</td>
                    <td>{{settl.type}}</td>
                    <td>{{settl.beneficiary}}</td>
                    <td>{{settl.state}}</td>
                    <td>{{settl.import | rgiCountryLayerNumberFormat : locale: optionCurrency}}</td>
                    <td><input type="text" licReinvestmentNumberFormatDirective formControlName="reinvest" /></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="100">TOTALE: {{total | rgiCountryLayerNumberFormat : locale: optionCurrency}}</td>
                </tr>
            </tfoot>
        </table>
        <pagination-controls id="reinvestment-table" previousLabel="" nextLabel=""
            (pageChange)="handlePageChange($event)" responsive="true"></pagination-controls>

        <div *ngIf="showErrors && (!enableConfirm || !rinvestForm.valid)">
            <div class="error-card-home">
                <div class="content-error validate-error">
                    <ul class="messages">
                        <li class="message" *ngIf="!enableConfirm">
                            <span class="rgifont message-icon rgi-exclamation-triangle"></span>
                            <span translate>lic_Mustselectatleastonesettlementtoreinvest</span>
                        </li>
                        <li class="message" *ngIf="hasMaxErrors()">
                            <span class="rgifont message-icon rgi-exclamation-triangle"></span>
                            <span translate>lic_Nettoreinvestmustbeequalorlessthannet</span>
                        </li>
                        <li class="message" *ngIf="hasRequiredErrors()">
                            <span class="rgifont message-icon rgi-exclamation-triangle"></span>
                            <span translate>lic_Missingvalueonnettoreinvest</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="btn-group btn-group-justified">
            <div class="btn-group">
                <button class="btn btn-warning btn-secondary" data-qa="close-button" (click)="close()" translate>lic_close
                </button>
            </div>
            <div class="btn-group">
                <button type="submit" class="btn btn-warning" data-qa="confirm-button" translate>lic_confirm_button
                </button>
            </div>
        </div>
    </div>
</form>

import { RgiRxi18nModuleLoadType, RgiRxTranslations } from '@rgi/rx/i18n';
import { CLAIMS_EN } from './en';
import { CLAIMS_IT } from './it';
import { CLAIMS_ES } from './es';
import { CLAIMS_DE } from './de';
import { CLAIMS_FR } from './fr';
import { CLAIMS_EU_ES } from './eu-ES';
import { CLAIMS_GL_ES } from './gl-ES';
import { CLAIMS_CA_ES } from './ca-ES';

export function loadIT(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(CLAIMS_IT);
  });
}

export function loadEN(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(CLAIMS_EN);
  });
}

export function loadES(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(CLAIMS_ES);
  });
}

export function loadDE(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(CLAIMS_DE);
  });
}

export function loadFR(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(CLAIMS_FR);
  });
}
export function loadEuEs(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(CLAIMS_EU_ES);
  });
}
export function loadGlEs(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(CLAIMS_GL_ES);
  });
}
export function loadCaEs(): RgiRxi18nModuleLoadType {
  return new Promise(resolve => {
    resolve(CLAIMS_CA_ES);
  });
}

export const CLAIMS_TRANSLATIONS: RgiRxTranslations = [
  {
    load: loadIT,
    locale: 'it'
  },
  {
    load: loadEN,
    locale: 'en'
  },
  {
    load: loadES,
    locale: 'es'
  },
  {
    load: loadDE,
    locale: 'de'
  },
  {
    load: loadFR,
    locale: 'fr'
  },
  {
    load: loadEuEs,
    locale: 'eu-ES'
  },
  {
    load: loadGlEs,
    locale: 'gl-ES'
  },
  {
    load: loadCaEs,
    locale: 'ca-ES'
  }
];

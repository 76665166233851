import { Component, OnInit, Input, Optional, EventEmitter } from '@angular/core';
import { Policy } from '@rgi/digital-claims-common-angular';
import { LOCALE_ID, Inject } from '@angular/core';
import { DIALOG_DATA, OnModalClose } from '@rgi/rx/ui';

@Component({
  selector: 'claims-dsc-policy-detail',
  templateUrl: './dsc-policy-detail.component.html',
  styleUrls: ['./dsc-policy-detail.component.scss']
})

export class DscPolicyDetailComponent implements OnInit, OnModalClose {
  @Input()
  policy: Policy;
  @Input() contract: any;
  primaryKey: number;
  modalClose = new EventEmitter<any>();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Optional() @Inject(DIALOG_DATA) data: any) {
    this.primaryKey = 0;
    this.policy = data.policy;
    this.contract = data.contract;
  }

  ngOnInit() {

  }

  closeModal() {
    this.modalClose.emit(true);
  }

}

<div class="core-header-icon re-issue-card-styles" *ngIf="packetContract">
  <span class="rgi-re-header-icon rgifont rgi-package nfm_font_size"></span>
  <span class="header-text" translate>Package</span>&nbsp;
  <span class="pull-right header-text"> </span>
</div>

<form [formGroup]="packetForm">
  <div class="packet-list">
    <div *ngFor="let packet of quotePackets" attr.data-qa="{{packet.description}}"
         class="packet ng-pristine ng-untouched ng-valid ng-not-empty">
      <div class="container-fluid packet-toggle" [ngClass]="{'active': packet.selected}">
        <input type="checkbox" rgiRxInput formControlName="{{packet.code}}"
               class="btn btn-link btn-block btn-packet ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-required">
        <div class="rgi-ui-grid">
          <div class="rgi-ui-col">
            <span class="packet-title">{{packet.description}}</span>
            <div class="packet-unit-description" *ngFor="let unit of packet.units">
              <span>{{unit.description}} ({{unit.section.description}})</span>
            </div>
          </div>
        </div>
        <div class="row nmf-packet-premium">
          {{packet?.totalPremium |currency: currency.code : currency.symbol :'1.2-2':'it' }}
        </div>
      </div>
      <div class="packet-arrow"></div>
    </div>
  </div>
</form>

<rgi-rx-panel>

  <rgi-rx-panel-header [closeable]="true" (onClose)="onCancel()">
    {{ getTitle() | translate }}
  </rgi-rx-panel-header>

  <rgi-rx-panel-footer>
    <button class="rgi-ui-btn rgi-ui-btn-primary" (click)="onCancel()" data-qa="rgi-gp-btn-cancel" translate>
      _GP_._BTN_._UNDO_
    </button>
    <button class="rgi-ui-btn rgi-ui-btn-primary" [disabled]="editPaymentForm.invalid || paymentFieldsForm.invalid"
            data-qa="rgi-gp-btn-confirm" (click)="onConfirm()" translate>
      _GP_._BTN_._CONFIRM_
    </button>
  </rgi-rx-panel-footer>

  <ng-container [formGroup]="editPaymentForm" *ngIf="payments">
    <rgi-rx-form-field>
      <label rgiRxLabel>
        <span translate>_GP_._LABEL_._PAYMENT_TYPE_</span>
      </label>
      <rgi-rx-button-toggle color="info" formControlName="paymentMethod">
        <button rgi-rx-button rgiRxValueOption rg value="debit" data-qa="rgi-gp-btn-debit"
                *ngIf="!isDebitBtnDisabled" translate>
          _GP_._BTN_._DEBIT_
        </button>
        <button rgi-rx-button rgiRxValueOption value="credit" data-qa="rgi-gp-btn-credit"
                *ngIf="!isCreditBtnDisabled" translate>
          _GP_._BTN_._CREDIT_
        </button>
      </rgi-rx-button-toggle>
    </rgi-rx-form-field>

    <div class="rgi-gp-flex-row">
      <div class="rgi-gp-field-container">
        <div class="rgi-gp-field">
          <rgi-rx-form-field *ngIf="editPaymentForm.get('paymentMethod').value">
            <label rgiRxLabel data-qa="rgi-gp-paymentMethodLabel">{{ selectedMethodLabel }}</label>
            <select rgiRxNativeSelect formControlName="paymentTypeCode" data-qa="rgi-gp-paymentMethod">
              <option value=""></option>
              <option *ngFor="let mean of selectedPayments" [value]="mean?.paymentConfig?.meanOfPayment?.code">
                {{mean?.paymentConfig?.meanOfPayment?.description}}
              </option>
            </select>
          </rgi-rx-form-field>
        </div>
      </div>
    </div>
    <rgi-gp-payment-fields *ngIf="paymentFields && paymentFields.length"
                           [fields]="paymentFields"
                           [form]="paymentFieldsForm">
    </rgi-gp-payment-fields>
  </ng-container>


</rgi-rx-panel>

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GPClause} from '../../group-policy-models/group-policy-issue-policy-data';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {take} from 'rxjs/operators';
import {GroupPolicyCrossService} from '../../group-policy-services/group-policy-cross.service';
import {GroupPolicyResourceService} from '../../group-policy-resources/group-policy-resource.service';
import {GpClausesService} from "../../group-policy-services/gp-clauses.service";

@Component({
  selector: 'rgi-gp-group-policy-clause',
  templateUrl: './group-policy-clause.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupPolicyClauseComponent implements OnInit {

  @Input() clauses: GPClause[];
  @Input() parentForm: UntypedFormGroup;
  @Output() formChange = new EventEmitter<UntypedFormGroup>();
  @Output() updateClauses = new EventEmitter<GPClause[]>();

  constructor(
    protected crossService: GroupPolicyCrossService,
    protected resourceApi: GroupPolicyResourceService,
    protected clausesService: GpClausesService
  ) { }

  clausesForm = new UntypedFormGroup({});

  ngOnInit() {
    this.setClausesValuesOnForm();
  }

  setClausesValuesOnForm() {
    this.clauses.forEach((clause, i) => {
      const formCtrlName = clause.code + '_' + i;
      this.clausesForm.addControl(formCtrlName, new UntypedFormControl(clause.selected));
      if (!clause.editable) {
        this.clausesForm.get(formCtrlName).disable();
      }
    });
  }

  public openModalText(clause: GPClause) {
    this.crossService.openTextBoxModal(clause)
      .pipe(take(1))
      .subscribe(resp => {
          if (resp && 'confirm' === resp.event) {
            clause.text = resp.text;
            if (resp.initialState) {
              clause.initialState.value.code = resp.initialState;
              clause.initialState.value = clause.initialState.allowedValues.find(allowedVal => allowedVal.code === resp.initialState);
              clause.hiddenOnApplication = resp.hiddenOnApplication;
            }
            this.updateClauses.emit([clause]);
          }
        }
      );
  }

  public onClauseSelection(clause: GPClause) {
    clause.selected = !clause.selected;
    this.updateClauses.emit([clause]);
  }

  showText(clause: GPClause): boolean {
    return this.clausesService.showText(clause);
  }

  showPreviewText(clause: GPClause): boolean {
    return this.clausesService.showText(clause);
  }
}

export class MeansOfPaymentResponse {
  public serviceInfo: ServiceInfo;
  public meansOfPayment: MeansOfPayment;

  constructor() {
    this.serviceInfo = new ServiceInfo();
    this.meansOfPayment = new MeansOfPayment();
  }
}

export class ServiceInfo {
  public executionId: string;
  public logs: string;
  public codiceErroreOccorso: string;
  public tipoErroreOccorso: string;
  public descrizioneErroreOccorso: string;
  public dettagliSullErroreOccorso: string;
  public eccezioneOccorsaSerializzata: string;
}

export class MeansOfPayment {
  public objectId: string;
  public debMeansOfPayment: DataMeansOfPayment[];
  public credMeansOfPayment: DataMeansOfPayment[];
}

export class DataMeansOfPayment {
  public paymentFields: DebPaymentFields[];
  public creditCards: any[];
  public meanOfPayment: DebMeanOfPayment;
  public paymentType: DebPaymentType;
}

export class DebPaymentFields {
  public objectId: any;
  public name: string;
  public readOnly: boolean;
  public label: string;
  public type: string;
  public value: any;
  public mandatory: boolean;
}

export class DebMeanOfPayment {
  public identification: string;
  public code: string;
  public description: string;
}

export class DebPaymentType {
  public codice: string;
  public description: string;
}

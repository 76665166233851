<div class="rgi-ui-drop-container rgi-rx-drop-container"
     [id]="id"
     cdkDropList
     cdkDropListSortingDisabled
     [cdkDropListData]="_dropListData"
     (cdkDropListDropped)="drop($event)"
     (cdkDropListEntered)="onDragEntered($event)"
     (cdkDropListExited)="onDragExited($event)"
     [cdkDropListDisabled]="disabled"
     [rgi-rx-qa]="id" qa-type="drop-container"
     #container
>
  <rgi-rx-drag-body
    cdkDrag
    [labelTemplate]="dragLabelDirective ? dragLabelDirective.template : undefined"
    [valueTemplate]="dragValueDirective ? dragValueDirective.template : undefined"
    *ngFor="let item of _dropListData; let i = index"
    [cdkDragData]="model"
    (onRemove)="remove($event)"
    [model]="model"
    [disabled]="disabled"
    [label]="label"
    [viewModel]="getViewModelValue(model)"
    [dragActions]="dragActions"
    [removeClass]="dragRemove ? dragRemove.class : undefined"
    [removeDescription]="dragRemove ? dragRemove.description : undefined"
    [rgi-rx-qa]="id +'-drag-body-'+(i+1)" [qa-value]="getViewModelValue(model)" qa-type="drag-body"
  >
    <div class="rgi-ui-drag-preview rgi-rx-drag-preview" *cdkDragPreview>
      <div [style.width]="container.offsetWidth + 'px'">
        <ng-container
          *ngTemplateOutlet="dragValueDirective ? dragValueDirective.template : defaultPreview; context: _getTemplateCtx(model)"></ng-container>
      </div>
    </div>
  </rgi-rx-drag-body>

  <rgi-rx-drop-body
    [hidden]="!!model"
    [disabled]="disabled">
  </rgi-rx-drop-body>
</div>


<ng-template #defaultPreview let-ctx="viewModel">
  {{ctx}}
</ng-template>

import {APP_INITIALIZER, Inject, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxTranslateDirective} from './rgi-rx-translate.directive';
import {RgiRxTranslationService} from './rgi-rx-translation.service';
import {
  RGI_RX_I18N_CONFIG,
  RGI_RX_I18N_DEFAULT_CONFIG,
  RGI_RX_TRANSLATIONS,
  RgiRxI18nConfig,
  RgiRxTranslations
} from './i18n-api';
import {RgiRxTranslatePipe} from './rgi-rx-translate.pipe';
import {RgiRxLocaleDescriptionPipe} from './rgi-rx-locale-description.pipe';
import {take} from 'rxjs/operators';
import {rgiRxi18nProvideTranslations} from './standalone/rgi-rx-i18n.providers';

export function rootCatalogLoader(translationService: RgiRxTranslationService, config: RgiRxI18nConfig): () => void {
  return () => {
    return translationService.getLanguageChange$().pipe(take(1)).toPromise();
  };
}

function rootLoaderTrigger(translationService: RgiRxTranslationService, config: RgiRxI18nConfig) {
  return () => {
    translationService.setCurrentLanguage(config.defaultLocale);
  };
}

/*export function childCatalogLoader(translations: RgiRxTranslations, childTranslations: RgiRxTranslations): RgiRxTranslationCatalogService {

}*/
@NgModule({
  declarations: [RgiRxTranslateDirective, RgiRxTranslatePipe, RgiRxLocaleDescriptionPipe],
  imports: [
    CommonModule
  ],
  exports: [RgiRxTranslateDirective, RgiRxTranslatePipe, RgiRxLocaleDescriptionPipe],
  providers: [
    {provide: RGI_RX_I18N_CONFIG, useValue: RGI_RX_I18N_DEFAULT_CONFIG},
    {provide: APP_INITIALIZER, useFactory: rootLoaderTrigger, multi: true, deps: [RgiRxTranslationService, RGI_RX_I18N_CONFIG ]},
    {provide: APP_INITIALIZER, useFactory: rootCatalogLoader, multi: true, deps: [RgiRxTranslationService, RGI_RX_I18N_CONFIG ]}
  ]
})
export class RgiRxI18nModule {


  constructor() {}

  static forRoot(translations: RgiRxTranslations): ModuleWithProviders<RgiRxI18nModule> {
    return {
      ngModule: RgiRxI18nModule,
      providers: [
        rgiRxi18nProvideTranslations(translations)
      ]
    };
  }

  /*    static forChild(translations?: RgiRxTranslations): ModuleWithProviders<RgiRxI18nModule> {
          return {
              ngModule: RgiRxI18nModule,
              providers: [
                  {provide: RGI_RX_CHILDREN_TRANSLATIONS, useValue: translations},
                  RgiRxTranslationService,
                  RgiRxTranslationCatalogService,
                  {provide: RgiRxTranslationCatalogService, useFactory: rootCatalogLoader, deps: [RGI_RX_TRANSLATIONS,RGI_RX_CHILDREN_TRANSLATIONS]}
              ]
          }
      }*/
}

import {Component, EventEmitter, Inject, Optional} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {DIALOG_DATA, OnModalClose} from '@rgi/rx/ui';
import {GpConfirmationEvent} from "../group-policy-modal-confirmation/gp-confirmation-modal.component";

@Component({
  selector: 'rgi-gp-group-policy-manual-policy-number',
  templateUrl: './group-policy-modal-manual-policy-number.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyModalManualPolicyNumberComponent implements OnModalClose {

  modalClose: EventEmitter<any> = new EventEmitter<string[] | void>();
  manualPolicyNumForm: UntypedFormGroup;
  confirmationEvent = GpConfirmationEvent;

  constructor(
    @Optional() @Inject(DIALOG_DATA) data: string,
  ) {
    this.manualPolicyNumForm = new UntypedFormGroup({ policyNumber : new UntypedFormControl(undefined) });
    const policyNumField: AbstractControl = this.manualPolicyNumForm.get('policyNumber');
    policyNumField.setValue(data);
  }

  onConfirm() {
    const policyNumField: AbstractControl = this.manualPolicyNumForm.get('policyNumber');
    this.modalClose.emit(policyNumField.value);
  }

}

<form [formGroup]="homeForm" class="rgi-ui-form-group">
  <!-- NEW NODE MANAGEMENT -->
  <div class="rgi-ui-grid-1">
    <div class="rgi-ui-col">
      <label rgiRxLabel for="node" translate>_ADAPTERPRINT_._LABEL_._MANAGEMENT_NODE_</label>
      <rgi-rx-drop-container formControlName="selectedNode" viewField="description"
                             select="nodeSelection" [selectData]="nodeList"></rgi-rx-drop-container>
    </div>
    <!-- DATE FROM -->
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel translate>_ADAPTERPRINT_._LABEL_._START_DATE_</label>

        <rgi-rx-date-picker>
          <input [rgiRxDateTimeInput]="dateFromPicker"
                 formControlName="dateFrom"
                 [max]="getMaxDate()"
                 selectMode="single"
                 [dateFilter]="dateFilter"
                 rangeSeparator="-"
                 (dateTimeChange)="onDateTimeChange($event)"
                 (dateTimeInput)="onDateTimeInput($event)"
                 placeholder="gg-mm-yyyy">
        </rgi-rx-date-picker>
        <rgi-rx-date-time #dateFromPicker
                          pickerType="calendar"
                          [hour12Timer]="true"
        ></rgi-rx-date-time>
      </rgi-rx-form-field>
    </div>
    <!-- DATE TO -->
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel translate>_ADAPTERPRINT_._LABEL_._DATE_TO_</label>
        <rgi-rx-date-picker>
          <input [rgiRxDateTimeInput]="dateToPicker"
                 formControlName="dateTo"
                 [min]="getMinDate()"
                 selectMode="single"
                 [dateFilter]="dateFilter"
                 rangeSeparator="-"
                 (dateTimeChange)="onDateTimeChange($event)"
                 (dateTimeInput)="onDateTimeInput($event)"
                 placeholder="gg-mm-yyyy">
        </rgi-rx-date-picker>
        <rgi-rx-date-time #dateToPicker
                          pickerType="calendar"
                          [hour12Timer]="true"
        ></rgi-rx-date-time>
      </rgi-rx-form-field>
    </div>
    <!-- SUBJECT -->
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel translate>_ADAPTERPRINT_._LABEL_._ADDRESSED_</label>
        <input type="text" max="" formControlName="adressed" rgiRxInput>
      </rgi-rx-form-field>
    </div>
    <br>
    <!-- STATE -->
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel translate>_ADAPTERPRINT_._LABEL_._STATUS_</label>
        <select rgiRxNativeSelect formControlName="state">
          <option value=""></option>
          <option *ngFor="let state of states" [value]="state.code">{{state.description}}</option>
        </select>
      </rgi-rx-form-field>
    </div>
    <br>
    <!-- POLICY NUMBER -->
    <div class="rgi-ui-col">
      <rgi-rx-form-field>
        <label rgiRxLabel translate>_ADAPTERPRINT_._LABEL_._POLICY_NUMBER_</label>
        <input type="text" max="" formControlName="policyNumber" rgiRxInput>
      </rgi-rx-form-field>
    </div>
  </div>
</form>

<!-- ERROR MESSAGES -->
  <rgi-rx-snackbar *ngIf="!homeForm.valid && homeForm.touched" tag="errors"></rgi-rx-snackbar>
<!-- WARNING MESSAGES -->
  <rgi-rx-snackbar *ngIf="emptyResult" tag="warnings"></rgi-rx-snackbar>
  <!-- BUTTONS GROUP -->
  <div class="btn-group btn-group-justified">
    <div class="btn-group">
      <!-- CLEAR -->
      <button class="btn btn-warning btn-secondary pull-right" (click)="clear()" translate>_ADAPTERPRINT_._BTN_._EMPTY_</button>
    </div>
    <div class="btn-group">
      <!-- FIND -->
      <span><button class="btn btn-warning pull-right" (click)="search()" color="primary" translate>_ADAPTERPRINT_._BTN_._FIND_</button></span>
    </div>
  </div>



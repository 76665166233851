import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Factor } from '../../../models/policy.model';



@Component({
  selector: 'lic-datepicker-factor',
  templateUrl: './datepicker-factor.component.html',
  styleUrls: ['./datepicker-factor.component.css']
})
export class DatepickerFactorComponent implements OnInit {
  value: string;
  label: string;
  config: Factor;
  group: UntypedFormGroup;
  prevVal: string;
  regex = new RegExp('^[0-9]{2}/[0-9]{2}/[0-9]{4}$');
  modifiable: string;
  mandatory: string;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.prevVal = this.config.value;
    this.initDate();

    this.regex.test('12/05/2019');

  }

  convertYMDIntoDate(dataYMD: string): Date {
    // console.log(dataYMD);
    return new Date(dataYMD);
  }

  convertDateIntoYMD(date: Date): string {
    if (!!date) {
      const year: string = date.getFullYear().toString();
      const month: string = '0' + (date.getMonth() + 1);
      const day: string = '0' + date.getDate();
      return year + '/' + month.substr(month.length - 2, 2) + '/' + day.substr(day.length - 2, 2);
    }
    return null;
  }

  initDate() {
    const validatori: ValidatorFn[] = [this.validatoreData];

    if (!this.group.contains(this.config.code)) {
      this.group.addControl(this.config.code, new UntypedFormControl());
    }
    if (this.config.value !== null && this.config.value !== '' && this.config.value !== '-1') {
      this.group.controls[this.config.code].setValue(this.convertYMDIntoDate(this.config.value));
    } else {
      this.group.controls[this.config.code].setValue(null);
    }
    if (this.config.modifiable) {
      this.modifiable = 'Modificabile';
      this.group.controls[this.config.code].enable();
    } else {
      this.modifiable = 'Non modificabile';
      this.group.controls[this.config.code].disable();
    }
    if (this.config.compulsory) {
      this.mandatory = 'Obbligatorio';
      validatori.push(Validators.required);
    } else {
      this.mandatory = 'Non obbligatorio';
    }
    this.group.controls[this.config.code].setValidators(validatori);
  }

  setFactor(factor: Factor, form: UntypedFormGroup) {
    this.config = factor;
    this.label = this.config.description;
    this.group = form;
  }

  onChange() {
    const newVal = this.convertDateIntoYMD(this.group.controls[this.config.code].value);
    if (this.prevVal !== newVal) {
      this.el.nativeElement.dispatchEvent(new CustomEvent('changeVal', {bubbles: true, detail: this.config.code}));
      // this.prevVal = this.group.controls[this.config.code].value;
    }
  }

  validatoreData: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    if ( control.value || this.config.compulsory ) {
      if (control.value instanceof Date && control.value.getFullYear() > 1900) {
        return null;
      }
      return {'Data non valida': true};
    }
    return null;
  }
}

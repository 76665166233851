import {State} from '@rgi/rx/state';
import {PolicyRow} from '../../models/policy';

export interface ConsultationListNavigationData {
  policies: PolicyRow;
  userCode:string;
}

export class ConsultationListState extends State {
  policies: PolicyRow;
  userCode:string;
}

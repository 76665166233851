<ng-container *ngFor="let section of extensionData; index as i">
  <div *ngIf="section?.visible">
    <div class="rgi-gp-section-title">
      <span class="rgi-gp-icon {{section?.icon}}"></span>
      <b translate>{{section?.label}}</b>
    </div>
    <rgi-gp-group-policy-fields [policyDataFields]="section?.fields" [parentForm]="parentForm"
                                [subformGroupName]="'formGroupExtData_'+i"
                                (fieldUpdate)="onPolicyDataExtensionUpdate($event)"
                                (customAction)="onFieldAction($event)">
    </rgi-gp-group-policy-fields>
  </div>
</ng-container>

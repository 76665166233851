import {Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, map, mergeMap, take} from 'rxjs/operators';
import {ActiveRoute, RgiRxRouteData, RoutingService} from '@rgi/rx/router';
import {ROUTES_DETAIL, ROUTES_MODAL_HOME, ROUTES_RESULT} from '../../anag-constants/routes-constants';
import {AnagRouteData} from '../../anag-model/anag-domain/anag-route-data';
import {HttpErrorResponse} from '@angular/common/http';
import {AnagResourceService} from '../../anag-resources/anag-resource.service';
import {OperatorLight} from '../../services/external-service';
import {RgiRxUserService} from '@rgi/rx/auth';
import {AnagApiSubject} from '../../anag-model/anag-api/anag-api-subject';
import {AnagIntegrationService} from '../../services/anag-integration.service';
import {KEY_DATA_UPDATE, KeyDataUpdateEvent, NewPartyEvent, NewPartyEventInstance} from '../../anag-events/anag-event';
import {RgiRxEventService} from '@rgi/rx';
import {KeyDataModalComponent} from '../../anag-components/party-create-key-data/key-data-modal/key-data-modal.component';
import {AnagStateManagerKeyData} from '../key-data/anag-state-manager-key-data';
import {ModalService} from '@rgi/rx/ui';
import {AnagStateResultHomonymy} from './anag-state-manager-result-homonymy';

@Injectable({
  providedIn: 'root'
})
export class AnagStatelessOpResultHomonymyService {

  constructor(
    protected routingService: RoutingService,
    protected userService: RgiRxUserService,
    protected anagResources: AnagResourceService,
    protected anagIntegration: AnagIntegrationService,
    protected eventService: RgiRxEventService,
    protected modalService: ModalService) {
  }

  initResultsHomonymy$(stateResultHomonymy$: Observable<AnagStateResultHomonymy>, routeData: RgiRxRouteData<AnagRouteData>): Observable<AnagStateResultHomonymy> {
    return stateResultHomonymy$.pipe(
      map(st => {
        st.parties = routeData.parties;
        st.party = routeData.party;
        st.isModal = routeData.isModalFlow === true;
        st.canCreateParty = !routeData.isSearchFlow;
        st.isUmaPartyHomonymyEnable = routeData.isUmaPartyHomonymyEnable;
        st.isUmaPartyCFDuplicate = routeData.isUmaPartyCFDuplicate;
        st.isUmaPartyGroupCompanies = routeData.isUmaPartyGroupCompanies;
        st.isBackFromHomonymy = routeData.isBackFromHomonymy;
        st.totalCounterParties = routeData.totalCounterParties;
        st.redirectToMainFlow = routeData.redirectToMainFlow;
        st.flowData = routeData.flowData;
        return st;
      })
    );
  }

  goToResultHomonymy(activeRoute: ActiveRoute) {
    let route = ROUTES_RESULT;
    const routeData = {
      ...activeRoute.getRouteData<AnagRouteData>(),
      oldParties: activeRoute.getRouteData<AnagRouteData>() ? activeRoute.getRouteData<AnagRouteData>().oldParties : null,
      isBackFromHomonymy: true
    };
    if (activeRoute.getRouteData<AnagRouteData>() && activeRoute.getRouteData<AnagRouteData>().isModalFlow) {
      route = ROUTES_MODAL_HOME;
    }
    this.anagIntegration.navigate(this.routingService, route, routeData, activeRoute);
  }

  // tslint:disable-next-line:max-line-length
  findPartyAndGoTo$(stateResultHomonymy$: Observable<AnagStateResultHomonymy>, activeRoute: ActiveRoute, row: any, goTo: 'EDIT' | 'DETAIL' | 'EDIT_HOMONYMY', stateMgrKeyData: AnagStateManagerKeyData) {
    let nodeId = null;
    if (row && row.nodeId && row.nodeId) {
      nodeId = row.nodeId;
    } else if (this.userService.isLoggedIn()) {
      nodeId = this.userService.getUser<OperatorLight>().salePoint.objectId;
    }
    return stateResultHomonymy$.pipe(
      mergeMap(
        (st) => {
          return combineLatest([
            of(st), this.anagResources.getSubjectDetail$(row.objectId, nodeId, null).pipe(catchError((err) => {
              return of(err);
            }))
          ]).pipe(
            map(
              ([state, resp]: [AnagStateResultHomonymy, AnagApiSubject]) => {
                if (resp && resp.subject) {
                  const routeData = {
                    ...activeRoute.getRouteData<AnagRouteData>(),
                    party: resp.subject,
                    redirectToMainFlow: state.redirectToMainFlow
                  };
                  switch (goTo) {
                    case 'DETAIL':
                      this.anagIntegration.setPartyInContainerCache(routeData.party, activeRoute.id);
                      this.anagIntegration.navigate(this.routingService, ROUTES_DETAIL, routeData, activeRoute);
                      break;
                    case 'EDIT_HOMONYMY':
                      if (state.isModal) {
                        this.eventService.emit<NewPartyEvent>(new NewPartyEventInstance({
                          action: 'keyData',
                          party: resp.subject,
                          lastAction: 'resultFlow',
                          isModalFlow: true,
                          flowData: state.flowData,
                          redirectToMainFlow: state.redirectToMainFlow,
                          parties: state.parties
                        }));
                      } else {
                        const keyDataModal = this.modalService.openComponent(KeyDataModalComponent, resp.subject, [
                          {
                            provide: AnagStateManagerKeyData,
                            useValue: stateMgrKeyData
                          }
                        ]);
                        keyDataModal.modal.enableClickBackground = false;
                        keyDataModal.modal.onClose.asObservable().pipe(take(1));
                        this.eventService.listen<KeyDataUpdateEvent>(KEY_DATA_UPDATE).pipe(take(1)).subscribe(eventInstance => {
                          this.anagIntegration.openEditSubject(eventInstance.event);
                        });
                      }
                      break;
                  }

                }
                if (resp instanceof HttpErrorResponse) {
                  state.serviceError = resp.message;
                }
                return state;
              }
            )
          );
        }
      )
    );
  }
}

import {Injectable} from '@angular/core';
import {ReIssuePartyResourceService} from '../re-issue-resources/re-issue-party-resource.service';
import {Observable} from 'rxjs';
import {PartyAsset} from '../../models/domain-models/party-asset';
import {RgiRxLogger} from '@rgi/rx';
import {ApiPutPartiesRequest} from '../../models/api-models/api-put-parties-request';

@Injectable({
  providedIn: 'root'
})
export class ReIssuePartiesBusinessService {

  constructor(
    public partyResourceService: ReIssuePartyResourceService,
    protected logger: RgiRxLogger
  ) {
  }

  getParties(contractId: string): Observable<Array<PartyAsset>> {
    return this.partyResourceService.get(contractId);
  }

  putParties(contractId: string, parties: Array<PartyAsset>): Observable<ApiPutPartiesRequest> {
    return this.partyResourceService.put(contractId,
      {parties}
    );
  }

  updateLastPhotoId(
    contractId: string,
    partyId: string,
    lastPhotoId: string
  ) {
    this.getParties(contractId).subscribe(
      parties => {
        this.logger.debug('ReIssuePartiesBusinessService::updateLastPhotoId() - parties', parties);
        let updated = false;
        for (const party of parties) {
          if (party.party.partyId === partyId) {
            if (party.party.idLatestPhotos !== lastPhotoId) {
              updated = true;
              party.party.idLatestPhotos = lastPhotoId;
            }
            break;
          }
        }
        if (updated) {
          this.putParties(contractId, parties).subscribe(
            parties1 => {
              this.logger.debug('ReIssuePartiesBusinessService::updateLastPhotoId() - parties1', parties1);
            }
          );
        }
      }
    );
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Activity } from '@rgi/digital-claims-common-angular';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  private baseApiUrlV2: string;
  private baseApiUrl: string;
  private claimsActivityService: any;
  private PROPERTY_URL_REST_WF: string = 'ClaimsUrlRestWorkFlow';

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any,
    @Inject('claimsActivityService') claimsActivityService: any,
    @Inject('getSystemProperty') private getSystemProperty: any
  ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/v2';
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
    this.claimsActivityService = claimsActivityService;
    this.PROPERTY_URL_REST_WF = 'ClaimsUrlRestWorkFlow';
  }

  public assegnaTaskBPM(idTaskBPM: string, assegnatario: string, gruppo: string) {
    const url = this.baseApiUrl + '/jworkflowapi/task/assign/' + idTaskBPM.toLowerCase() + '/' + assegnatario + '?groupId=' + gruppo;
    return this.httpClient.post(url, {});
  }


  public getGruppi(cNumeroDenuncia: string, cnumeroSinistro: string) {
    return this.httpClient.get(this.getGroupWFUri() + '/' + cNumeroDenuncia + '/' + cnumeroSinistro);
  }

  public getGruppiAll() {
    return this.httpClient.get(this.getGroupWFAllUri());
  }

  public getUsers(codGroup: string) {
    return this.httpClient.get(this.getUsersWFUri() + '/' + codGroup);
  }

  public getDictionary(key: string) {
    return this.httpClient.get(this.getDictionaryUri() + '/' + key);
  }

  private getGroupWFUri() {
   return this.baseApiUrlV2 + '/claims/activities/groupsWorkflow';
  }

  private getGroupWFAllUri() {
    return this.baseApiUrlV2 + '/claims/activities/groups';
  }

  private getDictionaryUri() {
    return this.baseApiUrlV2 + '/claims/activities/dictionaries';
  }

  private getUsersWFUri() {
    return this.baseApiUrlV2 + '/claims/activities/usersWorkflow';
   }

   private getAssignActivityUri() {
    return this.baseApiUrlV2 + '/claims/activities/assignActivity';
   }


   private getIdCamundaUri() {
     return this.baseApiUrl + '/jworkflowapi/tasks/select';
   }

  public riassegnaTask(idTaskBPM: string, actTaskId: string, idAct: string, user: string, group: string, codice: string, callWF: string) {


    if (idTaskBPM != null && idTaskBPM.toUpperCase() === 'ACTIVITY_ID_IN_BPM') {


    const COMANDO_PERSONALI = '000001';
    const COMANDO_ASSEGNATI = '000003';

    let idCamunda = null;

    this.getIdCamunda(COMANDO_ASSEGNATI, idAct).subscribe(
      (response: any) => {
        if ( response.tasks[0] != null ) {
          idCamunda = response.tasks[0].id;


          if (idCamunda != null) {
            this.assegnaTaskBPM(idCamunda, user, group).subscribe(
              (resp: any) => {
                console.log(resp);
              }
            );
      }

        }

      }
    );

    if (idCamunda == null) {
      this.getIdCamunda(COMANDO_PERSONALI, idAct).subscribe(
        (response: any) => {
          if (response.tasks[0] != null ) {
            idCamunda = response.tasks[0].id;
          }

          if (idCamunda != null) {
            this.assegnaTaskBPM(idCamunda, user, group).subscribe(
              (resp: any) => {
                console.log(resp);
              }
            );
          }

        }
      );
    }




    } else if (idTaskBPM != null && idTaskBPM.toUpperCase() !== 'NO_BPM_ONLY_ACTIVITY') {

      this.assegnaTaskBPM(idTaskBPM, user, group).subscribe(
        (response: any) => {
          console.log(response);
    }
      );

    }

    const updateActivityInput = {
      actTaskId,
      user,
      group,
      callWF,
      updateOperationEntity: {
        codice
      }
    };

    return this.httpClient.post(this.getAssignActivityUri(), updateActivityInput);
  }


   public getIdCamunda(user: string, idAct: string) {
    const inputListComunicationCriteria = this.preparaJSONworkflowTaskRiassegna(user, idAct);

    return this.httpClient.post(this.getIdCamundaUri(), inputListComunicationCriteria);

   }



   public preparaJSONworkflowTaskRiassegna(commando: string, id: string ) {

    const filterSelect = {
            filter: {
                filter: {
                    command: commando,
                    customFilter: {
                        columns: [{
                             columnWrapper: {
                                columnBusiness: {
                                    columnCode: 'IDACT',
                                    columnType: 1
                                }
                            },
                            conditionType: 0,
                            stringValue: id
                        }]
                    }
                }
            }
        };
    return filterSelect;
}



}

export enum Actions {
    BACK = '_AUTH_._BACK',
    MODIFY_PROPOSAL = 'modify_proposal',
    CANCEL = 'cancel_request',
    SAVE_POLICY = 'issue_policy',
    SAVE_PROPOSAL = 'issue_proposal',
    CONFIRM_VARIATION = 'confirm_variation',
    MODIFY_VARIATION = 'modify_variation',
    BUY = 'buy_policy',
    VARIATION_SUMMARY = 'variation_summary'
}

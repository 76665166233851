import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AnagStorageService} from '../../anag-resources/anag-storage.service';
import {AnagIdentificationEntity} from '../../anag-model/anag-domain/anag-identification-entity';
import {RgiRxUserAuthorizationService} from '@rgi/rx/auth';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AnagApiOtherAddress} from '../../anag-model/anag-domain/anag-api-party';

@Component({
  selector: 'rgi-anag-contact-address',
  templateUrl: './contact-address.component.html',
  host: {
    class: 'rgi-anag-style'
  }
})
export class ContactAddressComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();

  internationalPrefixes: Array<AnagIdentificationEntity>;
  residency: boolean;
  @Input() inputOtherAddress;
  @Input() parentForm: UntypedFormGroup;

  numberValidator = Validators.pattern('^[0-9]*$');
  faxNumberIntPrefix = new UntypedFormControl('');
  faxNumberPrefix = new UntypedFormControl(undefined, this.numberValidator);
  faxNumber = new UntypedFormControl(undefined, this.numberValidator);
  phoneNumberIntPrefix = new UntypedFormControl('');
  phoneNumberPrefix = new UntypedFormControl(undefined, this.numberValidator);
  phoneNumber = new UntypedFormControl(undefined, this.numberValidator);

  contactsAddressForm: UntypedFormGroup = new UntypedFormGroup({
    faxNumberIntPrefix: this.faxNumberIntPrefix,
    faxNumberPrefix: this.faxNumberPrefix,
    faxNumber: this.faxNumber,
    phoneNumberIntPrefix: this.phoneNumberIntPrefix,
    phoneNumberPrefix: this.phoneNumberPrefix,
    phoneNumber: this.phoneNumber
  });

  constructor(public anagStorage: AnagStorageService,
              public authorizationService: RgiRxUserAuthorizationService) { }

  ngOnInit() {
    if (this.parentForm) {
      this.parentForm.setControl('contactsAddressForm', this.contactsAddressForm);
    }
    if (this.inputOtherAddress) {
      this.adaptInputOtherAddressToForm(this.inputOtherAddress);
    } else {
      this.inputOtherAddress = new AnagApiOtherAddress();
    }
    this.manageSubscriptions();
    this.anagStorage.getInternationalPrefixes().subscribe(prefixes => {
      this.internationalPrefixes = prefixes;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private adaptInputOtherAddressToForm(inputAddress: AnagApiOtherAddress) {
    this.updateFormField(this.contactsAddressForm.get('faxNumberIntPrefix'), inputAddress.fax.internationalPrefix);
    this.updateFormField(this.contactsAddressForm.get('faxNumberPrefix'), inputAddress.fax.localPrefix);
    this.updateFormField(this.contactsAddressForm.get('faxNumber'), inputAddress.fax.number);
    this.updateFormField(this.contactsAddressForm.get('phoneNumberIntPrefix'), inputAddress.phone.internationalPrefix);
    this.updateFormField(this.contactsAddressForm.get('phoneNumberPrefix'), inputAddress.phone.localPrefix);
    this.updateFormField(this.contactsAddressForm.get('phoneNumber'), inputAddress.phone.number);
  }

  protected updateFormField(control: AbstractControl, value: string) {
    if (control && value !== control.value) {
      control.setValue(value, {emitEvent: false});
    }
  }

  manageSubscriptions() {
    this.subscriptions.add(this.contactsAddressForm.valueChanges.subscribe(formData => {
      this.adaptFormToApiOtherAddressContacts(formData);
    }));
  }

  adaptFormToApiOtherAddressContacts(formData: any): any {
    this.inputOtherAddress.fax.internationalPrefix = formData.faxNumberIntPrefix;
    this.inputOtherAddress.fax.localPrefix = formData.faxNumberPrefix;
    this.inputOtherAddress.fax.number = formData.faxNumber;
    this.inputOtherAddress.phone.internationalPrefix = formData.phoneNumberIntPrefix;
    this.inputOtherAddress.phone.localPrefix = formData.phoneNumberPrefix;
    this.inputOtherAddress.phone.number = formData.phoneNumber;
    return this.inputOtherAddress;
  }
}

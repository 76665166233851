import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EventNotificator } from '../../../event-notificator';
import { LifeRoleService } from '../../../services/life-role.service';
import { Subject } from './../../../../life-card/models/subject.model';


@Component({
  selector: 'lic-select-subject-list',
  templateUrl: './select-subject-list.component.html',
  styleUrls: ['./select-subject-list.component.css']
})
export class SelectSubjectListComponent implements OnInit, EventNotificator {

  @Input() parentForm: UntypedFormGroup;
  @Input() val: any;
  @Input() role: string;
  @Input() label: string;
  @Input() disableDelete = false;
  @Output() eventPropagation = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() subjetAnagEditedEvent = new EventEmitter<{request: { opened: boolean, subject: Subject, role: string}, index: number}>();

  public showPercentage = false;
  public counter: number;

  constructor(private lifeRoleService: LifeRoleService) {
    this.counter = 0;
  }

  ngOnInit(): void {
    if (Array.isArray(this.lifeRoleService.policyRoles) && this.lifeRoleService.policyRoles.length > 0) {
      const role = this.lifeRoleService.policyRoles.find(pr => pr.partyRole === this.role);
      if (!!role) {
        this.showPercentage = role.showPercentage;
      }
    }
    if (this.val !== null && this.val !== undefined) {
      this.counter = this.val.length;
    } else {
      this.counter = 0;
    }
  }

  public trackByFn(index, item) {
    return index;
  }

  public getSubjectGroup(i: number): UntypedFormGroup {
    const roleList = this.parentForm.get('roleList') as UntypedFormArray;
    return roleList.get(i.toString()) as UntypedFormGroup;
  }

  public getMaxInstances() {
    return this.parentForm.get('maxInstances').value;
  }

  public getRoleList() {
    return this.parentForm.get('roleList') as UntypedFormArray;
  }

  public isEmpty() {
    return this.val == null;
  }

  public addSubj() {
    if (this.counter <= (this.getMaxInstances() - 1) ) {
      this.counter++;
      const subject = new UntypedFormGroup({
      val: new UntypedFormControl(null),
      perc: new UntypedFormControl(null, [Validators.max(100), Validators.min(1)])
      });
      this.getRoleList().push(subject);
    }
  }

  public resetMethod(j: number, event) {
      this.getRoleList().get(j.toString()).get('val').setValue(null);
      const ev = {
      request: event,
      index: j
    };
      this.delete.emit(ev);
  }

  public getAnag(j, event) {
    const ev = {
      request: event,
      index: j
    };
    this.eventPropagation.emit(ev);
  }

  public editAnag(j, event) {
    this.getRoleList().get(j.toString()).get('val').setValue(null);
    const ev = {
      request: event,
      index: j
    };
    this.subjetAnagEditedEvent.emit(ev);
  }

  public changePerc() { }

  public addMethod() { }

}

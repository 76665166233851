import {Component, Inject, OnInit} from '@angular/core';
import {of} from 'rxjs';
import {take} from 'rxjs/operators';
import {DashboardNavigationData} from '../state-manager/dashboard-data-state';
import {DashboardCardState, DashboardManagerService} from '../state-manager/dashboard-manager.service';
import {ActiveRoute} from '@rgi/rx/router';

@Component({
  selector: 'dashboard-summary',
  templateUrl: './dashboard-summary.component.html',
  styleUrls: ['./dashboard-summary.component.scss']
})
export class DashboardSummaryComponent implements OnInit {

  state: DashboardCardState;
  companyName: string;
  routingData: DashboardNavigationData;

  displayedColumns: string[] = ['description', 'movements', 'commissions'];

  constructor(
    public stateManager: DashboardManagerService<DashboardCardState>,
    public activeRoute: ActiveRoute,
    @Inject('sessionService') private portalSession: any
  ) { }

  ngOnInit() {
    this.routingData = this.stateManager.activeRoute.getRouteData<DashboardNavigationData>();
    this.stateManager.getState$().pipe(take(1)).subscribe(state => {
      this.state = state;
      this.stateManager.getSummaryData(state).subscribe(state1 => {
        this.stateManager.updateState$(of(state1));
      });
      this.stateManager.updateState$(of(state));
      window.scroll({
        top: 0,
        left: 0
      });
    });
  }

  close() {
    this.portalSession.remove(this.activeRoute.id);
  }

}

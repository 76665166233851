<rgi-rx-panel class="lg-panel">
  <rgi-rx-panel-header [closeable]="true" (onClose)="close()">
    <span translate>RE_ISSUE.POLICY_QUOTAS</span>
  </rgi-rx-panel-header>

  <div class="modal-body large-modal">
    <div class="tbld_row tbld_row_even">
      <span class="tbld_col_value" translate="">RE_ISSUE.OUR_PERCENTAGE</span>
      <span class="tbld_col_value">: {{coInsuranceDTO.ourPerc | number : DIGITS_INFO:LOCALE_ID}}%</span>
    </div>
    <br>
    <table rgi-rx-table [dataSource]="tableData">

      <ng-container rgiRxColumnDef="quota">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef>% <span translate>RE_ISSUE.QUOTA</span></th>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          {{row.quotaPerc | number : DIGITS_INFO:LOCALE_ID}}
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="commission">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef>% <span translate>RE_ISSUE.COMMISSIONS</span></th>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          {{row.commissionsPerc | number : DIGITS_INFO:LOCALE_ID}}
        </td>
      </ng-container>

      <ng-container rgiRxColumnDef="company">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef translate>RE_ISSUE.COMPANY</th>
        <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.company.description}}</td>
      </ng-container>

      <ng-container rgiRxColumnDef="intermediary">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef translate> RE_ISSUE.INTERMEDIARY</th>
        <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.intermediary.description}}</td>
      </ng-container>

      <ng-container rgiRxColumnDef="policynumber">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef translate> RE_ISSUE.POLICY_NUMBER</th>
        <td rgi-rx-cell *rgiRxCellDef="let row"> {{row.policyNumber}}</td>
      </ng-container>

      <ng-container rgiRxColumnDef="actions">
        <th rgi-rx-header-cell *rgiRxHeaderCellDef></th>
        <td rgi-rx-cell *rgiRxCellDef="let row">
          <button (click)="deleteQuota(row)" rgi-rx-button color="info" class="action-button" data-qa="delete">
            <span class="rgi-re-header-icon rgifont rgi-ui-icon-delete nfm_font_size"></span>
          </button>
          <button (click)="openModal(row)" rgi-rx-button color="info" data-qa="edit">
            <span class="rgi-re-header-icon rgifont rgi-ui-icon-edit nfm_font_size"></span>
          </button>
        </td>
      </ng-container>

      <tr rgi-rx-header-row *rgiRxHeaderRowDef="displayedColumns"></tr>
      <tr rgi-rx-row *rgiRxRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <br>

    <div class="row tbld_row_odd">
      <div class="tbld_col tbld_col_value">
        <span translate> RE_ISSUE.PERCENTAGE_TOTAL</span>
        <span>: {{percentageTotal | number : DIGITS_INFO:LOCALE_ID}}%</span>
      </div>
    </div>

  </div>
  <!-- FOOTER -->
  <rgi-rx-panel-footer>
    <button data-qa="close" class="rgi-ui-btn rgi-ui-btn-primary" (click)="close()" translate>RE_ISSUE.CLOSE</button>
    <button data-qa="new" class="rgi-ui-btn rgi-ui-btn-primary text-uppercase" [disabled]="percentageTotal===100"
            (click)="openModal()" translate> RE_ISSUE.NEW
    </button>
  </rgi-rx-panel-footer>
</rgi-rx-panel>

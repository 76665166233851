import { ChangeDetectorRef, Component, Inject, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { ComponentWithQuotation } from '../../interfaces/component-with-quotation';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';
import { RequestFactor } from '../../models/factor.model';
import {
  FactorDefinition,
  Instalment,
  OperationProperty,
  PaymentTypeDefinition, PostsalesOperationObject, QuotationDefinition,
  Risk
} from '../../models/postsales-operations-response.model';
import { RisksFactors } from '../../models/risks-factors.model';
import { LpcCommissionDetailModalComponent } from '../../modules/lpc-commission-detail-modal/lpc-commission-detail-modal.component';
import { LpcFactorAdapterComponent } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import { KarmaFundService } from '../../modules/lpc-karma-funds/service/karma-fund.service';
import { Premium } from '../../modules/lpc-premium/model/premium.model';
import { LpcQuotationDetailModalComponent } from '../../modules/lpc-quotation-detail-modal/lpc-quotation-detail-modal.component';
import { LpcWarrantyDetailModalComponent } from '../../modules/lpc-warranty-detail-modal/lpc-warranty-detail-modal.component';
import { Instalments } from '../../modules/lpc-warranty-detail-modal/lpc-warranty-detail/lpc-instalments.model';
import { AnagService } from '../../services/anag.service';
import { AngularCommunicationService } from '../../services/angular-communication.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { PlcQuotationUtils } from '../../utils/plc-quotation-utils';
import { InstalmentType } from '../dynamic-operation/dynamic-operation.model';
import { OperationWithFinancialStepComponent } from '../../interfaces/impl/operation-with-financial-step.component';
import { LpcKarmaFundUtils } from '../../modules/lpc-karma-funds/utils/lpc-karma-fund-utils';
import { InvestmentMode, OperationPropertyCode } from '../../models/operation-property-code.enum';
import { PlcObjectUtils } from '../../utils/plc-object-utils';
import { InvestmentAdapterConfig } from '../../interfaces/operation-with-financial-step';


@Component({
  selector: 'lpc-premium-payment-reactivation',
  templateUrl: './premium-payment-reactivation.component.html',
  styleUrls: ['./premium-payment-reactivation.component.scss']
})
export class PremiumPaymentReactivationComponent extends OperationWithFinancialStepComponent  implements ComponentWithQuotation {

  protected operationDataKey = 'premium-payment-reactivation';

  @ViewChild('factorAdapter') child: LpcFactorAdapterComponent;

  public quotationData: any;
  public damageQuotationData: any;
  public lifeWarrantiesData: any[];
  public damageWarrantiesData: any[];
  public paymentTypes: PaymentTypeDefinition[] = [];

  public listProductFactor: FactorDefinition[] = [];
  protected requestProductFactor: RequestFactor[] = [];

  public quotationDefinition: QuotationDefinition;
  public volatility: number;

  public paymentFrequency: FactorDefinition;

  // QUOTATION DETAIL MODAL
  public annualPremium: Premium;
  public firstInstalment: Premium;
  public nextInstalment: Premium;

  // INVESTMENTS
  protected canEditInvestmentFunds = true; // indica se i fondi degli investimenti sono modificabili (scende dalle definitions)

  // WARRANTIES DETAIL MODAL
  public instalments: Instalments;

  // QUOTATION CONTROLS BUTTONS
  public disableQuote = true;
  public disableDetails = true;
  public disableNext: boolean;
  public enableProv: boolean;

  public risksForUnits: Risk[] = [];

  private reqUnitFactors: RisksFactors[] = [];

  get hasProductFactor(): boolean {
    return !!this.listProductFactor && !!this.listProductFactor.length;
  }

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected translate: TranslationWrapperService,
    protected cd: ChangeDetectorRef,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected karmaService: KarmaFundService,
    protected angularCommunicationService: AngularCommunicationService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService,
      plcQuestService, authService, anag, karmaService, angularCommunicationService);
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      operationRoles: new UntypedFormControl(),
      factors: new UntypedFormGroup({}),
      paymentFrequency: new UntypedFormControl(),
      investmentProfiles: new UntypedFormGroup({}),
      investmentFunds: new UntypedFormGroup({}),
      quotation: new UntypedFormGroup({}),
      risks: new UntypedFormControl(),
      notes: new UntypedFormControl() // Text-area note
    });
  }

  ngOnInit(): void {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe(result => {
        this.paymentFrequency = result.definitions.paymentFrequency as FactorDefinition;
        if (!!this.paymentFrequency) {
          this.formGroup.get('paymentFrequency').setValue(this.paymentFrequency.defaultValue);
        }
      })
    );
  }

  public onNext(step: string = null, publish: boolean = false, context = step, isConfirmAndAccept = false): void {
    if (step === 'factors') {
      this.requestProductFactor = this.child.getRequestFactor();
    }
    if (this.isFormValid(this.formGroup.get(step))) {
      super.onNext(step, publish, context, isConfirmAndAccept);
    } else {
      this.setFeErrors(step);
    }
  }

  updateDraftHandleResponse(result: PostsalesOperationObject, step: string, reload?: boolean, opDataType?: string) {
    super.updateDraftHandleResponse(result, step, reload, opDataType);
    const tmpRisk = result.data.operationData.data.listRisksFactor;
    this.disableDetails = false;
    this.disableQuote = true;
    this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
    this.quotationDefinition = this.createQuotationDefinition(result.data.operationData.data);
    if (tmpRisk && tmpRisk.length > 0) {
      this.risksForUnits = PlcQuotationUtils.mapRisksForUnits(tmpRisk, this.quotationDefinition.risks);
    } else {
      this.risksForUnits = this.quotationDefinition.risks;
    }
    this.enableProv = this.checkProvVisibility(this.risksForUnits);
    this.instalments = {
      totAnnualPremium: null,
      totFirstInstalment: this.quotationDefinition.firstInstalment,
      totNextInstalment: null,
      annualPremiumSection: null,
      firstInstalmentSection: null,
      nextInstalmentSection: null
    };
    this.updateModals(this.quotationDefinition);
    this.updateProfilesAndFunds(step, result.definitions);
    this.detectChanges();
  }

  public jumpFactorsStep(): boolean {
    return ((!this.listProductFactor.length) || (!!this.listProductFactor && !!this.listProductFactor.length
              && !this.listProductFactor.some(factor => factor.visible && factor.editable)
           )) && !!this.paymentFrequency && this.paymentFrequency.listValue.length === 1;
  }


  public createQuotationDefinition(q): QuotationDefinition {
    return {
      annualPremium: q.annualQuotation,
      firstInstalment: null,
      nextInstalment: q.nextInstalmentQuotation,
      recoveredCommission: null,
      reimbursementAmount: null,
      risks: q.risksInfo,
      beneficiaries: null
    };
  }

  public isFormValid(form): boolean {
    if (!!form) {
      return form.disabled ? true : form.valid;
    }
    return true;
  }

  public updateProductFactors(factors: RequestFactor[]) {
    this.requestProductFactor = factors;
    this.$subscriptions.push(this.onReload('factors').subscribe());
  }

  public recalculate(disable: boolean) {
    if (this.formGroup.get('risks').valid) {
      this.updateOnQuotation(disable);
    } else {
      this.setFeErrors('quotation');
    }
  }

  public updateOnQuotation(disable: boolean) {
    this.$subscriptions.push(
      this.updateDraft('recalculateQuote', true).subscribe(result => {
        this.disableNext = disable;
      })
    );
  }

  public openQuotationModal(event) {
    const modalRef = this.modalService.open(LpcQuotationDetailModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });

    modalRef.componentInstance.firstInstalment = this.firstInstalment;
    modalRef.componentInstance.nextInstalment = this.nextInstalment;
    modalRef.componentInstance.annualPremium = this.annualPremium;
    modalRef.componentInstance.quotationData = this.quotationData;
  }

  public openWarrantiesModal(event) {
    const modalRef = this.modalService.open(LpcWarrantyDetailModalComponent, {
      centered: true,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      size: <any> 'xl',
      windowClass: 'in lpc-modal',
      backdropClass: 'light-blue-backdrop in'
    });
    modalRef.componentInstance.instalments = this.instalments;
  }

  public openProvModal(event) {
    const modalRef = this.modalService.open(LpcCommissionDetailModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'in',
      backdropClass: 'light-blue-backdrop in'
    });

    modalRef.componentInstance.risks = this.risksForUnits;
  }

  public updatePremiumModal(quotationDefinition: QuotationDefinition) {
    this.annualPremium = PlcQuotationUtils.instalmentToPremium(quotationDefinition.annualPremium);
    if (this.annualPremium) {
      this.annualPremium.typeDescription = this.translate.getImmediate(InstalmentType.ANNUAL_PREMIUM);
    }
    this.firstInstalment = PlcQuotationUtils.instalmentToPremium(quotationDefinition.firstInstalment);
    if (!!this.firstInstalment) {
      this.firstInstalment.typeDescription = this.translate.getImmediate(InstalmentType.FIRST_INSTALMENT);
    }
    this.nextInstalment = PlcQuotationUtils.instalmentToPremium(quotationDefinition.nextInstalment);
    if (!!this.nextInstalment) {
      this.nextInstalment.typeDescription = this.translate.getImmediate(InstalmentType.NEXT_INSTALMENT);
    }
    this.quotationData = PlcQuotationUtils.quotationDefToQuotationData(quotationDefinition);
  }

  public updateWarrantiesModal(quotationDefinition: QuotationDefinition) {
    this.instalments.firstInstalmentSection = this.createInstalmentSectionWarranties(
      quotationDefinition.risks,
      InstalmentType.FIRST_INSTALMENT
    );
    this.instalments.nextInstalmentSection = this.createInstalmentSectionWarranties(
      quotationDefinition.risks,
      InstalmentType.NEXT_INSTALMENT
    );
    this.instalments.annualPremiumSection = this.createInstalmentSectionWarranties(
      quotationDefinition.risks,
      InstalmentType.ANNUAL_PREMIUM
    );
    if (!!quotationDefinition.annualPremium) {
      quotationDefinition.annualPremium.description = this.translate.getImmediate(InstalmentType.ANNUAL_PREMIUM);
    }
    if (!!quotationDefinition.firstInstalment) {
      quotationDefinition.firstInstalment.description = this.translate.getImmediate(InstalmentType.FIRST_INSTALMENT);
    }
    if (!!quotationDefinition.nextInstalment) {
      quotationDefinition.nextInstalment.description = this.translate.getImmediate(InstalmentType.NEXT_INSTALMENT);
    }

    this.instalments.totFirstInstalment = quotationDefinition.firstInstalment;
    this.instalments.totAnnualPremium = quotationDefinition.annualPremium;
    this.instalments.totNextInstalment = quotationDefinition.nextInstalment;
  }

  public updateModals(quotationDefinition: QuotationDefinition) {
    this.updatePremiumModal(quotationDefinition);
    this.updateWarrantiesModal(quotationDefinition);
  }

  // creating sections for warranties detail modal
  public createInstalmentSectionWarranties(risks: Risk[], instalmentType: string): Instalment[] {
    return PlcQuotationUtils.createInstalmentSectionWarranties(risks, instalmentType);
  }


  public checkProvVisibility(risks: Risk[]): boolean {
    return PlcQuotationUtils.checkProvVisibility(risks);
  }

  public updateUnit(riskFactors: Map<string, RequestFactor[]>) {
    this.reqUnitFactors = [];
    riskFactors.forEach((value: RequestFactor[], key: string) => {
      this.reqUnitFactors.push(PlcQuotationUtils.mapRisk(key, value));
    });
    this.$subscriptions.push(this.onReload('quotation').subscribe((result: PostsalesOperationObject) => {
      this.quotationDefinition = this.createQuotationDefinition(result.data.operationData.data);
      this.risksForUnits = PlcQuotationUtils.mapRisksForUnits(
        result.data.operationData.data.listRisksFactor, this.quotationDefinition.risks
      );
      if (!!this.quotationDefinition.firstInstalment) {
        this.quotationDefinition.firstInstalment.gross = 0.00;
      }
      this.disableDetails = true;
      this.disableQuote = false;
      this.disableNext = true;
    }));
  }

  protected getTransformedOperationData(): any {
    let investmentProfiles = [];
    if (!!this.formGroup.getRawValue().investmentProfiles && !!this.formGroup.getRawValue().investmentFunds && !!this.investmentProfiles) {
      investmentProfiles = LpcKarmaFundUtils.getBackendStructureOfProfiles(
        this.investmentProfiles,
        this.formGroup.getRawValue().investmentProfiles,
        this.formGroup.getRawValue().investmentFunds
      );
    }
    return  {
      listProductFactor: this.requestProductFactor.length > 0 ? this.requestProductFactor : [],
      listRisksFactor: this.reqUnitFactors,
      investmentProfiles,
      paymentFrequency: !!this.paymentFrequency ? this.extractProductFactorValuePaymFreq(this.paymentFrequency) : null
    };
  }


  extractProductFactorValuePaymFreq(paymentFrequency: FactorDefinition) {
    return {
      code: paymentFrequency.code,
      label: paymentFrequency.label,
      value: this.formGroup.get('paymentFrequency').value,
      typeCode: paymentFrequency.typeCode,
      userInput: null
    };
  }





  public getFrequency(): string {
    if (!this.paymentFrequency) { return; }
    const paymfreq = this.paymentFrequency.listValue.find(freq => freq.code === this.formGroup.get('paymentFrequency').value);
    return !!paymfreq ? paymfreq.description : EMPTY_STR;
  }

   // ---------------------------------------- INVESTIMENTI ----------------------------------------

  // FLAG PER SALTARE GLI STEP DI INVESTIMENTI
  public get skipInvestmentProfiles(): boolean {
    return !this.canEditInvestmentFunds || this.profileFixedPercentage;
  }
  public get skipInvestmentFunds(): boolean {
    return !this.canEditInvestmentFunds || this.fundFixedPercentage;
  }

  // configurazioni da passare al componente degli investimenti per gestire le varie customizzazioni
  get getInvestmentProfileConfigurations(): InvestmentAdapterConfig {
    return {
      profileFormGroupName: 'investmentProfiles',
      investmentMode: this.getInvestmentMode(),
      enableVolatility: false,
      prevalIfLengthEqual1: true,
      percentageSumEqual100: true,
      enableSingleElementSelection: false,
      showSliderInput: true,
      showPercentageInput: true,
      showAmountColumnOnViewOnly: true,
      enableOpenListDefault: true
    };
  }
  get getInvestmentFundConfigurations(): InvestmentAdapterConfig {
    return {
      profileFormGroupName: 'investmentProfiles',
      fundFormGroupName: 'investmentFunds',
      investmentMode: this.getInvestmentMode(),
      enableVolatility: this.isWithVolatility(),
      prevalIfLengthEqual1: true,
      percentageSumEqual100: true,
      enableSingleElementSelection: false,
      showSliderInput: true,
      showPercentageInput: true,
      showAmountColumnOnViewOnly: true,
      enableOpenListDefault: true
    };
  }

    // metodo che mantine aggiornati gli investimenti ad ogni avanti
    public updateProfilesAndFunds(step: string, definitions) {
      this.totalAmountToInvest = this.getTotalAmount(this.quotationDefinition);
      this.canEditInvestmentFunds = JSON.parse(!!definitions.modifiableFunds ? definitions.modifiableFunds.value.toLowerCase() : true);

      // INVESTIMENTI - VISIBILITà STEP & GESTIONE UPDATE
      super.updateProfilesAndFunds(step, definitions);
    }

    public getTotalAmount(quotationDef: QuotationDefinition): number {
      if (!!quotationDef && !!quotationDef.risks) {
        return quotationDef.risks
        .map(risk => Number(!!risk.nextInstalment && !!risk.nextInstalment.purePremium ?
          risk.nextInstalment.purePremium : 0).valueOf())
        .reduce((acc, premium: number) => acc + premium, 0);
      }
      return null;
    }

    public isPaymentVisibile(): boolean {
      if (this.hasOperationPropertyByCode(OperationPropertyCode.POST_SALES_PAYMENT_TYPES)) {
        return  PlcObjectUtils.getBooleanString(
          this.getOperationPropertyByCode(OperationPropertyCode.POST_SALES_PAYMENT_TYPES).booleanValue
        );
      }
      return true;
    }

    public getInvestmentMode(): InvestmentMode | undefined {
      const prop: OperationProperty = this.getOperationPropertyByCode(OperationPropertyCode.INVESTMENT_MODE);
      return !!prop ? (prop.stringValue as InvestmentMode) : undefined;
    }
}

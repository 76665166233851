import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {REST_API_TECHDATA_CONF} from './re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssueSaveQuoteResourceService {
  private qqApiConf: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any,
  ) {
    this.qqApiConf = REST_API_TECHDATA_CONF;
  }

  postContract(proposalNumber: string): Observable<any> {
    const body = {proposalNumberEncrypted: proposalNumber, createCopy: false};
    return this.http.post<any>(this.postContractUri(), body).pipe(
      share()
    );
  }

  protected postContractUri(): string {
    return this.apiConf.api.portal.path + this.qqApiConf.path;
  }
}

import {Component, EventEmitter, Inject, Optional} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DIALOG_DATA, OnModalClose, RgiRxDataTableDataSource} from '@rgi/rx/ui';
import {Commission} from '../../../group-policy-models/group-policy-issue-guarantees';
export class CommissionForm {
  title?: string = '_GP_._BTN_._COMMISSIONS_';
  commissions: Commission[];
}
/**
 * @author: dmasone
 * @description: Modal used for render commissions
 */
@Component({
  selector: 'rgi-gp-group-policy-modal-commissions',
  templateUrl: './group-policy-modal-commissions.component.html',
  host: {
    class: 'rgi-gp-style'
  }
})
export class GroupPolicyModalCommissionsComponent implements OnModalClose {

  public modalClose = new EventEmitter<any>();
  public commissions: Commission[];
  public commissionsForm = new UntypedFormGroup({});
  public data: CommissionForm;
  percCollNet = 'percCollNet';
  percPurchaseNet = 'percPurchaseNet';
  percFlex = 'percFlex';

  commissionColumns: string[] = ['blank', this.percFlex, 'assign'];
  commisionRange = [Validators.min(0), Validators.max(100)];
  discountRange = [Validators.min(-100), Validators.max(100)];
  public assignValuesForm = new UntypedFormGroup({
    assignFirstYear: new UntypedFormControl(null, this.commisionRange),
    assignNextYear: new UntypedFormControl(null, this.commisionRange),
    assignDiscount: new UntypedFormControl(null, this.discountRange),
  });
  dataSource = new RgiRxDataTableDataSource<any>([]);
  showComm1stYear: boolean;
  showCommNextYears: boolean;

  constructor(@Optional() @Inject(DIALOG_DATA) data: CommissionForm) {
    this.data = data;
    this.commissions = data.commissions;
    this.showComm1stYear = !!data.commissions.find((r) => !!r.percPurchaseNet);
    this.showCommNextYears = !!data.commissions.find((r) => !!r.percCollNet);
    if (this.showCommNextYears) {
      this.commissionColumns.splice(1, 0, this.percCollNet);
    }
    if (this.showComm1stYear) {
      this.commissionColumns.splice(1, 0, this.percPurchaseNet);
    }
    this.fillForm();
  }

  public saveCommissions() {
    this.updateValues();
    this.modalClose.emit(this.commissions);
  }

  protected fillForm() {
    const data = [];
    this.commissions.filter(c => !!c).forEach(risk => {
      data.push({
        id: risk.idRisk,
        description: risk.riskDescr,
        percPurchaseNet: risk.percPurchaseNet,
        percCollNet: risk.percCollNet,
        percFlex: risk.percFlex,
      });
      if (risk.percPurchaseNet) {
        this.commissionsForm.addControl(
          'risk_' + risk.idRisk + '_comm_1st_year', new UntypedFormControl(
            {value: risk.percPurchaseNet.value, disabled: !risk.percPurchaseNet.editable},
            [...this.commisionRange, Validators.required])
        );
      }
      if (risk.percCollNet) {
        this.commissionsForm.addControl(
          'risk_' + risk.idRisk + '_comm_next_year', new UntypedFormControl(
            {value: risk.percCollNet.value, disabled: !risk.percCollNet.editable},
            [...this.commisionRange, Validators.required])
        );
      }
      if (risk.percFlex) {
        this.commissionsForm.addControl(
          'risk_' + risk.idRisk + '_comm_discount', new UntypedFormControl(
            {value: risk.percFlex.value, disabled: !risk.percFlex.editable},
            [...this.discountRange, Validators.required])
        );
      }
    });
    this.dataSource.update(data);
  }

  protected updateValues() {
    const values = this.commissionsForm.getRawValue();
    this.commissions.filter(c => !!c).forEach((commission, index) => {
      Object.keys(commission).forEach((key: string) => {
        let id = 'risk_' + commission.idRisk;
        switch (key) {
          case this.percPurchaseNet:
            id += '_comm_1st_year';
            break;
          case this.percCollNet:
            id += '_comm_next_year';
            break;
          case this.percFlex:
            id += '_comm_discount';
            break;
        }
        if (key && commission[key] != null && key !== 'idRisk' && key !== 'riskDescr') {
          this.commissions[index][key].value = values[id] !== null ? values[id] : this.commissions[index][key].value;
        }
      });
    });
  }

  public assignFormValues() {
    const assignFirstYear = this.assignValuesForm.get('assignFirstYear');
    const assignNextYear = this.assignValuesForm.get('assignNextYear');
    const assignDiscount = this.assignValuesForm.get('assignDiscount');
    this.commissions.filter(c => !!c).forEach(risk => {
      if (risk.percPurchaseNet && risk.percPurchaseNet.editable && assignFirstYear.valid && assignFirstYear.value != null) {
        this.commissionsForm.get('risk_' + risk.idRisk + '_comm_1st_year').setValue(assignFirstYear.value);
      }
      if (risk.percCollNet && risk.percCollNet.editable && assignNextYear.valid && assignNextYear.value != null) {
        this.commissionsForm.get('risk_' + risk.idRisk + '_comm_next_year').setValue(assignNextYear.value);
      }
      if (risk.percFlex && risk.percFlex.editable && assignDiscount.valid && assignDiscount.value != null) {
        this.commissionsForm.get('risk_' + risk.idRisk + '_comm_discount').setValue(assignDiscount.value);
      }
    });
  }

  public cancel() {
    this.modalClose.emit();
  }

}

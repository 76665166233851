import {MotorPostsalesTranslationsKeys} from './motor-psales-translations-keys';

/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */

export const MOTOR_PSALES_EN: MotorPostsalesTranslationsKeys = {
  _MOTORPSALES_: {
    _BTN_: {

    },
    _LABEL_: {

    },
    _MSG_: {

    },
    _TITLE_: {

    },
    _STEPS_: {
      _EXTENSION_LIST_: 'Extension list',
    },
    _SESSION_TITLE_: {
      _EXTENSION_GREEN_CARD_: 'Appendix Green Card Extension policy n. {{policyNumber}}',
      _ISSUE_GREEN_CARD_: 'Issue Green Card for policy n. {{policyNumber}}'
    }
  },

  _VOID_: '',
};

import {Component} from '@angular/core';
import {GpInquiryComponentDetail} from '../gp-inquiry-component-detail';
import {
  GroupPolicyStateManagerInquiry
} from '../../../group-policy-state/group-policy-statemanager-inquiry/group-policy-statemanager-inquiry';
import {GroupPolicyInquiryComponent} from '../../group-policy-inquiry.component';
import {GROUP_POLICY_MODE_FUNCTIONS} from '../../../group-policy-constants/general';
import {GroupPolicyIntegrationService} from "../../../group-policy-services/group-policy-integration.service";

@Component({
  selector: 'rgi-gp-group-policy-contract-detail',
  templateUrl: './group-policy-contract-detail.component.html'
})
export class GroupPolicyContractDetailComponent extends GpInquiryComponentDetail {

  public expandOtherData = false;
  isBackVisible: boolean;
  isBackActive: boolean;

  constructor(
    protected stateMngr: GroupPolicyStateManagerInquiry,
    protected parent: GroupPolicyInquiryComponent,
    protected integrationService: GroupPolicyIntegrationService) {
    super(stateMngr);
    stateMngr.initActionsButtonProperties();
    this.isBackActive = !this.stateMngr.isFunctionEnabled(GROUP_POLICY_MODE_FUNCTIONS.CONTRACT_DETAILS.BACK_BUTTON_DISABLE);
    this.isBackVisible = !this.stateMngr.isFunctionEnabled(GROUP_POLICY_MODE_FUNCTIONS.CONTRACT_DETAILS.BACK_BUTTON_HIDE);
  }

  public backToPrevPage() {
    this.stateMngr.actionBackToPrevPage(this.parent.id);
  }

  public doButtonAction(code: string, isCached = false, route = 'anag') {
    this.stateMngr.handleContractDetailButtonAction(code, isCached, route);
  }

  closeSession() {
    this.integrationService.closeSession(this.parent.id)
  }
}

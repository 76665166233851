import {OnModalClose, RgiRxDateTimeChange} from '@rgi/rx/ui';
import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {DocumentCategoryEntity, DocumentSubcategoryEntity} from '../dto/document-category-entity';
import {DocumentEntity} from '../dto/document-entity';
import {ClaimDocumentsService} from '../claim-documents.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {RgiRxFileReaderService} from '@rgi/rx';
import {ApiParty} from '@rgi/digital-claims-common-angular';

@Component({
  selector: 'claims-ui-modal-dialog-edit-document',
  templateUrl: './edit-document-dialog.component.html',
})
export class UiModalDialogEditDocumentComponent implements OnModalClose, OnInit, OnDestroy {
  // @Output() public modalEditDocumentEmitter: EventEmitter<any> = new EventEmitter<any>();
  party: ApiParty;
  category: DocumentCategoryEntity;
  subcategory: DocumentSubcategoryEntity;
  receptionDate: string;
  modalClose: EventEmitter<any>;
  editForm: FormGroup;
  document: DocumentEntity;
  claimParties: ApiParty[];
  documentCategories: DocumentCategoryEntity[];
  isClaimDocument: boolean;
  isReceptionDateForLegal: boolean = false;
  isReceptionDateValid: boolean = false;

  receiptDateForm: any;


  constructor(
    @Inject('authService') private auth,
    @Inject('eventService') private eventService: any,
    public utilityService: ClaimDocumentsService,
    public formBuilder: FormBuilder,
    private fileReader: RgiRxFileReaderService,
  ) {
  }

  @Output() public modalEditEmitter: EventEmitter<any> = new EventEmitter<any>();

  onCloseModalEditDocument() {
    this.modalEditEmitter.emit({modalMsg: 'MODAL_CLOSED'});
  }

  ngOnInit(): void {
    if (this.utilityService.getSysPropShowParties()) {
      this.party = this.utilityService.getEditParty();
      this.claimParties = this.utilityService.getParties()
        .filter((value, index, array) => value.identifier !== this.party.identifier);
    }
    this.category = this.utilityService.getEditCategory();
    if (this.category.subcategories.length > 0) {
      this.subcategory = this.utilityService.getEditSubcategory();
    } else {
      this.subcategory = this.category;
    }
    if (this.utilityService.getIsEditClaimDocument()) {
      this.documentCategories = this.utilityService.getDocumentCategories()
        .filter((value, index, array) => value.elementId !== this.category.elementId);
    } else {
      this.documentCategories = this.utilityService.getPotClaimDocumentCategories()
        .filter((value, index, array) => value.elementId !== this.category.elementId);
    }


    // remove deprecated categories if the documents WAS NOT previously in a deprecated category
    if (!this.category.isDeprecated) {
      this.documentCategories = this.documentCategories.filter((v, i, arr) => !v.isDeprecated);
    }
    this.document = this.utilityService.getEditDocument();
    // filter out the already selected party

    if (this.document.receptionDate) {
      const splitted = this.document.receptionDate.split('/');
      const formattedDate = splitted[1] + '/' + splitted[0] + '/' + splitted[2];
      this.receiptDateForm = new FormGroup({
        receiptDatePickerFormField: new FormControl(new Date(formattedDate)),
      });
      this.isReceptionDateValid = true;
    } else {
      this.receiptDateForm = new FormGroup({
        receiptDatePickerFormField: new FormControl()
      });
      this.isReceptionDateValid = false;
    }


    // change form depending on system property
    this.utilityService.getSysPropShowParties() ? (
        this.editForm = this.formBuilder.group({
          party: this.utilityService.getEditParty().identifier,
          category: this.category.elementId,
          subcategory: this.subcategory.elementId,
          uploadNote: this.utilityService.getEditDocument().uploadNote,
          subtitle: this.utilityService.getEditDocument().subtitle,
          numPages: this.utilityService.getEditDocument().numPages,
          assetType: this.utilityService.getEditDocument().assetType,
          toSendEBDS: this.utilityService.getEditDocument().toSendEBDS,
          externalVisibility: false,
        })) :
      (
        this.editForm = this.formBuilder.group({
          category: this.category.elementId,
          subcategory: this.subcategory.elementId,
          uploadNote: this.utilityService.getEditDocument().uploadNote,
          subtitle: this.utilityService.getEditDocument().subtitle,
          numPages: this.utilityService.getEditDocument().numPages,
          assetType: this.utilityService.getEditDocument().assetType,
          toSendEBDS: this.utilityService.getEditDocument().toSendEBDS,
          externalVisibility: false
        })
      );

    if (this.isReceptionDateForLegal) {
      // this.editForm.setControl('receiptDatePickerFormField', new FormControl(this.document.receptionDate));
      this.editForm.setControl('receiptDate', new FormControl([]));
      this.editForm.setControl('receiptDateFormatted', new FormControl([]));
    }
  }

  handleCategorySelectionChange($event: Event) {
    console.log($event);
    // @ts-ignore
    const catCode = $event.target.value;
    this.category = this.documentCategories.find((value, index, arr) => value.elementId === catCode);

    if (!this.category.subcategories.length) {
      this.subcategory = this.category;
      this.editForm.controls.category.setValue(this.category.elementId);
      this.editForm.controls.subcategory.setValue(this.subcategory.elementId);
    } else {
      this.subcategory = null;
      this.editForm.controls.subcategory.setValue('');
    }

  }

  onConfirmEditDocument(id: string) {
    // console.log(this.editForm);
    this.eventService.broadcastEvent('start-loader');

    // additional form for receptionDate, couldnt set a date value programmatically otherwise
    if (
      this.editForm.controls.receiptDateFormatted &&
      this.editForm.controls.receiptDateFormatted.value &&
      this.editForm.controls.receiptDateFormatted.value.length === 0 &&
      this.receiptDateForm.controls.receiptDatePickerFormField.value) {
      const val = this.receiptDateForm.controls.receiptDatePickerFormField.value;
      if (val instanceof Date) {
        const day = val.getDate().toString().padStart(2, '0');
        const month = (val.getMonth() + 1).toString().padStart(2, '0');
        const year = val.getFullYear().toString();
        const res = day + '/' + month + '/' + year;
        this.editForm.setControl('receiptDateFormatted', new FormControl(res));
        this.isReceptionDateValid = true;
      }
    }

    this.utilityService.modifyDocument(this.editForm, id).subscribe(
      (res) => {
        console.log(res);
        this.modalEditEmitter.emit({modalMsg: 'MODAL_EDIT_FINISHED'});

        this.eventService.broadcastEvent('stop-loader');
      },
      (error) => {
        this.eventService.broadcastEvent('stop-loader');
      }
    );

  }

  getMaxDay(): Date {
    return new Date();
  }

  ngOnDestroy(): void {
    this.utilityService.setEditSubcategory(null);
    if (this.utilityService.getSysPropShowParties()) {
      this.utilityService.setEditParty(null);
    }
    this.utilityService.setEditDocument(null);
    this.utilityService.setEditCategory(null);
  }

  handleDateTimeChange($event: RgiRxDateTimeChange<Date>) {
    console.log($event);
    const val: Date | Date[] = ($event.value);
    if (val instanceof Date) {
      const day = val.getDate().toString().padStart(2, '0');
      const month = (val.getMonth() + 1).toString().padStart(2, '0');
      const year = val.getFullYear().toString();
      const res = day + '/' + month + '/' + year;
      this.editForm.setControl('receiptDateFormatted', new FormControl(res));
      // this.uploadForm.setControl('receiptDate', new FormControl(res));
    }

  }

  onDateTimeChange($event: any) {
    const val: Date | Date[] = ($event.value);
    if (val instanceof Date) {
      if ($event.source.value > $event.source.max) {
        this.isReceptionDateValid = false;
        return;
      }

      const day = val.getDate().toString().padStart(2, '0');
      const month = (val.getMonth() + 1).toString().padStart(2, '0');
      const year = val.getFullYear().toString();
      const res = day + '/' + month + '/' + year;
      this.editForm.setControl('receiptDateFormatted', new FormControl(res));
      this.isReceptionDateValid = true;
    }

  }

}

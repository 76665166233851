<rgi-rx-panel class="lg-panel">
  <rgi-rx-panel-header [closeable]="true" (onClose)="close()"><span translate>RE_ISSUE.WARRANTIES_SUMMARY</span>
  </rgi-rx-panel-header>

  <div class="modal-body modal-guarantees passqq large large-modal">
    <rgi-rx-expansion-panel *ngFor="let instance of instances; let i = index" [expanded]="true"
                            attr.data-qa="asset_{{i}}">
      <rgi-rx-expansion-panel-header class="panel-heading">
        <div class="rgi-ui-col">
          <span class="panel-title">{{ instance.instanceDescription }}&nbsp;&nbsp;</span>
          <span class="label asset-accordion-label pull-right">
                {{ instance.total | currency: currency.code : currency.symbol:'1.2-2':'it' }}&nbsp;&nbsp;</span>
        </div>
      </rgi-rx-expansion-panel-header>

      <div class="panel-body" *ngFor="let warranty of warranties">
        <b class="unit-name"
           attr.data-qa="{{warranty.sectionTitle }} - ({{ warranty.unitTitle }})">{{warranty.sectionTitle }} -
          ({{ warranty.unitTitle }})</b>
        <b class="pull-right">{{warranty.installment | currency: currency.code : currency.symbol:'1.2-2':'it' }}</b>
        <div *ngFor="let variable of warranty.variablesRows">
          <span>{{variable.extendedDescription ? variable.extendedDescription : variable.description}}</span>
          <span *ngIf="variable.type === 'LIST'" class="pull-right">
              {{getElementFromList(variable.value, variable.values).description}}</span>
          <span *ngIf="variable.type === 'NUM'" class="pull-right">
              {{variable.value | currency: currency.code : currency.symbol:'1.2-2':'it' }}</span>
        </div>
      </div>
    </rgi-rx-expansion-panel>
  </div>
</rgi-rx-panel>

import {ActiveRoute} from '@rgi/rx/router';
import {StateStoreService} from './state-store.service';
import {RgiRxStateManager, RgiRxStateManagerDestroyOpts, State} from './state-manager-api';


/**
 * @deprecated use RgiRxStateManagerDestroyOpts
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RgiRxAbstractStateManagerDestroyOpts extends RgiRxStateManagerDestroyOpts{}

/**
 * Abstract State manager class for implementing Single Observable pattern state a managers
 * @since 1.0.0
 */
export abstract class RgiRxLocalStateManager<T extends State> extends RgiRxStateManager<T> {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  protected constructor(private __activeRoute: ActiveRoute, private __stateStoreService: StateStoreService) {
    super(__stateStoreService);
  }

  /**
   * Get the current ActiveRoute
   */
  get activeRoute(): ActiveRoute {
    return this.__activeRoute;
  }

  /**
   * Get the stateStore service instance
   */
  get stateStoreService(): StateStoreService {
    return this.__stateStoreService;
  }

  /**
   * Get the store key in use.
   * By default, RgiRxLocalState manager uses activeRoute.key
   * @see StateStoreService
   * @see ActiveRoute
   * @default ActiveRoute.key
   * @since 0.3.1
   */
  get storeKey(): string {
    return this.activeRoute.key;
  }
}

/**
 * @deprecated use RgiRxLocalStateManager
 * @see RgiRxLocalStateManager
 */
export class AbstractStateManager<T extends State> extends RgiRxLocalStateManager<T> {}

import { Component, OnInit, Input, ChangeDetectorRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'pnc-radio-group',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RadioGroupComponent),
    multi: true
  }],
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss']
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class RadioGroupComponent implements OnInit, ControlValueAccessor {
  @Input() dataQa: string;
  @Input() name: string;
  @Input() options: { label: string; value: string; }[];
  @Input() value: string;
  @Input() customFormControlName;
  @Input() customFormGroup;

  disabled: boolean;
  required: boolean;



  private onChange: (_: any) => {};

  private onTouched: () => void = () => { };

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  selectOption(index: number) {
    if (!this.disabled) {
      this.value = this.options[index].value;
      this.customFormGroup.get(this.customFormControlName).setValue(this.value);
      this.changeDetectorRef.markForCheck();
      if (!!this.onChange) {
        this.onChange(this.value);
      }
      if (!!this.onTouched) {
        this.onTouched();
      }
    }
  }

  ngOnInit() {
    const formControl = this.customFormGroup.controls[this.customFormControlName];
    const validator: any = formControl.validator && formControl.validator(new UntypedFormControl());
    this.value = formControl.value;

    if (!this.required) {
      this.required = validator && validator.required && !formControl.pristine;
    }
    if (!this.disabled) {
      this.disabled = validator && validator.disabled;
    }
  }

  /** Implemented as part of ControlValueAccessor. */
  writeValue(value: any): void {
    this.value = value;
    console.log('Written ' + this.value);
  }

  /** Implemented as part of ControlValueAccessor. */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** Implemented as part of ControlValueAccessor. */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Implemented as a part of ControlValueAccessor. */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

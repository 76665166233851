import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ac-authorization-error-message',
  templateUrl: './authorization-error-message.component.html',
  host: {
    class: 'rgi-authorizations-style'
  }
})
export class AuthorizationErrorMessageComponent implements OnInit{

  @Input() type: string;
  @Input() messages: string[] = [];

  ngOnInit(): void {
    console.log(this.type);
    console.log(this.messages);
  }




}

import {Injectable} from '@angular/core';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {mergeMap, take, tap} from 'rxjs/operators';
import {
  GroupPolicyModalComponent
} from '../group-policy-components/group-policy-modals/group-policy-modal-component/group-policy-modal.component';
import {ModalService} from '@rgi/rx/ui';
import {Observable, of} from 'rxjs';
import {
  GroupPolicyModalTextBoxComponent,
  GroupPolicyTextClauseModalResp
} from '../group-policy-components/group-policy-modals/group-policy-modal-textbox/group-policy-modal-text-box.component';
import {GPClause, SaveResp} from '../group-policy-models/group-policy-issue-policy-data';
import {RgiRxInterpolationParamsType} from '@rgi/rx';
import {
  GpConfirmationEvent,
  GpConfirmationModalComponent
} from '../group-policy-components/group-policy-modals/group-policy-modal-confirmation/gp-confirmation-modal.component';
import {ACTION_BTN_ENUM, GO_TO_INQUIRY_BTN, GpActionButton, GpGenericModalData} from '../group-policy-models/gp-modals';
import {GroupPolicyIntegrationService} from './group-policy-integration.service';
import {DataForSteps} from '../group-policy-models/group-policy-issue-home';
import {CONTRACT_STATUS} from '../group-policy-constants/general';
import {
  GroupPolicyModalShowClauseComponent
} from "../group-policy-components/group-policy-modals/group-policy-modal-show-clause/group-policy-modal-show-clause.component";

@Injectable()
export class GroupPolicyCrossService {

  constructor(
    protected translationService: RgiRxTranslationService,
    protected modalService: ModalService,
    protected integrationService: GroupPolicyIntegrationService
  ) {
  }

  openBaseModal(firstPartPeriod: string, secondPartPeriod?: string,
                dinamicValue?: string, buttons?: GpActionButton[]): Observable<any> {
    return this.translationService.translateAll(firstPartPeriod, secondPartPeriod)
      .pipe(
        mergeMap(translations => {
          const period = this.generatePeriodForModal(dinamicValue, translations);
          return this.manageGenericModal$(period, buttons);
        })
      );
  }

  protected manageGenericModal$(period: string, buttons: GpActionButton[]) {
    const modalData = new GpGenericModalData(undefined, period);
    modalData.text = period;
    modalData.buttons = buttons;
    const open = this.modalService.open(GroupPolicyModalComponent, modalData);
    open.enableClickBackground = false;
    return open.onClose.asObservable();
  }

  openBaseModalWithParams(translationKey: string, params: RgiRxInterpolationParamsType, buttons?: GpActionButton[]): Observable<any> {
    return this.translationService.translate(translationKey, params).pipe(take(1), mergeMap(period => {
      return this.manageGenericModal$(period, buttons);
    }));
  }

  openTextBoxModal(clause: GPClause): Observable<GroupPolicyTextClauseModalResp> {
    const open = this.modalService.open(GroupPolicyModalTextBoxComponent, clause);
    open.enableClickBackground = false;
    return open.onClose.asObservable();
  }

  showClauseModal(clause: GPClause): Observable<GroupPolicyTextClauseModalResp> {
    const open = this.modalService.open(GroupPolicyModalShowClauseComponent, clause);
    open.enableClickBackground = false;
    return open.onClose.asObservable();
  }

  showModalContractNumber(saveResp: SaveResp, currentSessionId: string) {
    let secondPartPeriod = '_GP_._MODAL_._SAVED_';
    if (saveResp.containsApplications) {
      secondPartPeriod = '_GP_._MODAL_._SAVED_NO_APP_UPDATE_';
    }
    const buttons = [GO_TO_INQUIRY_BTN];
    return this.openBaseModal('_GP_._COMMON_._PROPOSAL_', secondPartPeriod, saveResp.proposalNumber, buttons).pipe(
      tap((onCloseAction: string | null) => {
        this.manageGenericModalOnClose(onCloseAction, saveResp.proposalNumber, currentSessionId);
      })
    );
  }

  manageGenericModalOnClose(onCloseAction: string, proposalNumber?: string, currentSessionId?: string) {
    switch (onCloseAction) {
      case ACTION_BTN_ENUM.INQUIRY:
        this.integrationService.goToInquiry({proposalNumber} as DataForSteps, currentSessionId);
        break;
    }
  }

  openConfirmationModal(firstPartPeriod: string, title?: string, secondPartPeriod?: string,
                        dinamicValue?: string, enableClickBackground?: false): Observable<GpConfirmationEvent> {
    return this.translationService.translateAll(firstPartPeriod, secondPartPeriod, title)
      .pipe(
        mergeMap(translations => {
          const period = this.generatePeriodForModal(dinamicValue, translations);
          const title = translations[2] ? translations[2] : null;
          const modalData = new GpGenericModalData(title, period);
          const confirmationModal = this.modalService.open(GpConfirmationModalComponent, modalData);
          confirmationModal.enableClickBackground = enableClickBackground;
          return confirmationModal.onClose.asObservable();
        })
      );
  }

  protected generatePeriodForModal(dinamicValue: string, translations: string[]): string {
    if (dinamicValue && translations[0] && translations[1]) {
      return `${translations[0]} ${dinamicValue} ${translations[1]}`;
    } else if (translations[0] && dinamicValue) {
      return `${translations[0]} ${dinamicValue}`;
    } else {
      return `${translations[0]}`;
    }
  }

  evaluateOpenModalCheckApplications(applicationsToAuthorize: number) {
    if (applicationsToAuthorize > 0) {
      return this.openConfirmationModal(
        '_GP_._MSG_._CONFIRM_CHECK_APPLICATION_',
        null, null, null, false);
    }
    return of(GpConfirmationEvent.CONFIRM);
  }

  isContractStatusUndefined(stepData: DataForSteps) {
    return !stepData.contractStatus || (stepData.contractStatus && CONTRACT_STATUS.UNDEFINED === stepData.contractStatus.code);
  }

}

import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiFileBean} from '../models/api-models/api-file-bean';
import {HelpService} from './help.service';


@Component({
  selector: 'mic-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpComponent implements OnInit {

  @Input() helpFile: string;

  fileBean: ApiFileBean;
  myHelp: any;

  constructor(
    protected helpService: HelpService
  ) {
  }

  ngOnInit() {
    if (this.helpFile && this.helpFile !== '0') {
      this.fileBean = this.helpService.getHelpFileByName(this.helpFile);
      if (this.fileBean) {
        this.showHtmlFile(this.fileBean);
      } else {
        this.helpService.getHelpFile(this.helpFile).subscribe(data => {
          if (data && data.base64) {
            this.helpService.setHelpFile(data);
            this.showHtmlFile(data);
          }
        });
      }
    }
  }

  showHtmlFile(fileBean: ApiFileBean) {
    const binaryString = window.atob(fileBean.base64);
    this.myHelp = binaryString;
  }

}

import {Directive, TemplateRef} from '@angular/core';
import {RgiRxTemplateContentDirective} from '../rgi-ui-api';

@Directive({
  selector: 'ng-template[rgiRxExpansionPanelContent]'
})
export class RgiRxExpansionPanelContentDirective extends RgiRxTemplateContentDirective {

  constructor(template: TemplateRef<any>) {
    super(template);
  }
}

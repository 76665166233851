import {EventEmitter, Inject, Injectable} from '@angular/core';
import {CompensationEntity} from '../new-payment/domain/compensation-entity';
import {HttpClient} from '@angular/common/http';
import {FormBuilder} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {CompensationDiaryNoteEntity} from './dto/compensation-diary-note-entity';

@Injectable({
  providedIn: 'root'
})
export class CompensationUtilityService {

  private compensations: CompensationEntity[];
  private selectedCompensation: CompensationEntity;
  private selectedCompensationDiaryNotes: CompensationDiaryNoteEntity[];
  private baseApiUrl: string;
  private baseApiUrlRs: string;
  private urlservice: string = '/claims';
  private party: any;
  private claim: any;
  private modal: NgbActiveModal;
  private card: any;
  private user: any;
  private objTable: any;
  private eventEmitter: EventEmitter<any>;


  constructor(private httpClient: HttpClient,
              private formBuilder: FormBuilder,
              @Inject('environment') environment: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path + '/v2';
    this.baseApiUrlRs = environment.api.portal.host + environment.api.portal.path;

  }

  getCompensationDiaryNotes(): Observable<any> {

    return this.httpClient.get(this.baseApiUrl + this.urlservice +
      '/compensations/diaryNotes?idAttore=' + this.party.identifier +
      '&numRivalsa=' + this.selectedCompensation.number);

  }

  addCompensationDiaryNote(text: string) {
    const body = {
      claimNumber: this.claim.claimNumber,
      user: this.user,
      idRivalsa: this.selectedCompensation.idCompensation,
      text
    };

    return this.httpClient.post(this.baseApiUrl + this.urlservice + '/addCompensationDiaryNote',
      body);
  }


  setSelectedCompensationDiaryNotes(notes: CompensationDiaryNoteEntity[]) {
    this.selectedCompensationDiaryNotes = notes;
  }

  getSelectedCompensationDiaryNotes() {
    return this.selectedCompensationDiaryNotes;
  }

  getCompensations(): CompensationEntity[] {
    return this.compensations;
  }

  setCompensations(comp: CompensationEntity[]) {
    this.compensations = comp;
  }

  getClaim() {
    return this.claim;
  }

  setClaim(cl: any) {
    this.claim = cl;
  }

  getParty() {
    return this.party;
  }

  setParty(p: any) {
    this.party = p;
  }

  getSelectedCompensation() {
    return this.selectedCompensation;
  }

  setSelectedCompensation(comp: CompensationEntity) {
    this.selectedCompensation = comp;
  }

  getModal() {
    return this.modal;
  }

  setModal(mod: NgbActiveModal) {
    this.modal = mod;
  }

  setCard(c: any) {
    this.card = c;
  }

  getCard() {
    return this.card;
  }

  setUser(u: any) {
    this.user = u;
  }

  getUser() {
    return this.user;
  }

  setObjTable(table: any) {
    this.objTable = table;
  }

  getObjTable() {
    return this.objTable;
  }

  setEventEmitter(evtEmitter: EventEmitter<any>) {
    this.eventEmitter = evtEmitter;
  }

  getEventEmitter() {
    return this.eventEmitter;
  }
}

<div class="rgi-re-section-title" data-qa="proposal_premium_title">
  <span class="rgi-re-header-icon rgi-re-icon-wallet"></span>
  <span class="rgi-ui-title-1 rgi-re-title" translate>RE_ISSUE.PREMIUM</span>&nbsp;
</div>

<div class="rgi-re-section-title" data-qa="proposal_premium_instalment">
  <span class="rgi-ui-title-2 rgi-ui-text-4 rgi-ui-info" translate>RE_ISSUE.INST_SIGN</span>&nbsp;
  <span
    class="rgi-ui-title-2 rgi-ui-text-4 rgi-ui-info"> {{premiumSummary.gross | currency: currency.code : currency.symbol:'1.2-2':'it' }}</span>
</div>

<table class="rgi-ui-table" data-qa="proposal_premium_field">
  <tbody>
  <tr class="rgi-ui-row">
    <td class="rgi-ui-cell" ><span translate>RE_ISSUE.GROSS</span></td>
    <td class="rgi-ui-cell" >{{premiumSummary.gross | currency: currency.code : currency.symbol:'1.2-2':'it' }}</td>
    <td class="rgi-ui-cell" ><span translate>RE_ISSUE.NET</span></td>
    <td class="rgi-ui-cell" >{{premiumSummary.net | currency: currency.code : currency.symbol:'1.2-2':'it' }}</td>
    <td class="rgi-ui-cell" ><span translate>RE_ISSUE.INST_INTEREST</span></td>
    <td class="rgi-ui-cell" >{{premiumSummary.fractioningInterest | currency: currency.code : currency.symbol:'1.2-2':'it' }}</td>
  </tr>
  <tr class="rgi-ui-row">
    <td class="rgi-ui-cell" ><span translate>RE_ISSUE.FEES</span></td>
    <td class="rgi-ui-cell" >{{premiumSummary.fees| currency: currency.code : currency.symbol:'1.2-2':'it' }}</td>
    <td class="rgi-ui-cell" ><span translate>RE_ISSUE.TAXES</span></td>
    <td class="rgi-ui-cell" >{{premiumSummary.taxes | currency: currency.code : currency.symbol:'1.2-2':'it' }} </td>
  </tr>
  </tbody>
</table>

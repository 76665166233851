<div>
    <rgi-rx-panel style="margin-top:20%;">
      <rgi-rx-panel-header [closeable]="true"
                           (onClose)="onCloseModalDeleteLink(); ">
      </rgi-rx-panel-header>
      <div>
        {{'_CLAIMS_._ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TRUSTEE_ASSOCIATION' | translate}}
      </div>
      <rgi-rx-panel-footer>
        <button rgi-rx-button color="danger" (click)="onDeleteLinkedExpert()">{{'_CLAIMS_._BUTTONS_._DELETE' | translate}}</button>
      </rgi-rx-panel-footer>
      <!--DELETE BUTTON-->
    </rgi-rx-panel>
  </div>
import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {EvaluatorProductI} from '../../../../evaluation/models';
import {EvalProductCfg} from '../../../../state/models/eval-product-cfg';
import {
  EvalValidationProduct,
  SurveyEvaluateState,
  SurveyEvaluateStateManagerService
} from '../../../../state/state-manager-survey.module';
import {filter, map, pluck} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Document, DocumentResource} from '@rgi/ng-passpropro/documents';
import {ModalService} from '@rgi/rx/ui';
import {SurveyEvalDocumentModalComponent} from '../survey-eval-document-modal/survey-eval-document-modal.component';


@Component({
  selector: "[survey-eval-product-bar]",
  templateUrl: "./survey-eval-product-bar.component.html",
  encapsulation: ViewEncapsulation.None
})
export class SurveyEvalProductBarComponent implements OnInit {
  documents$: Observable<Document[]>;
  @Input() validation: EvalValidationProduct;
  @Input('product') product: EvaluatorProductI;
  @Input('editable') isEdit: boolean = false;
  @Input() productCfg: EvalProductCfg;

  @Output('select') onSelectEmitter = new EventEmitter<EvaluatorProductI>();
  @Output('clickInfoProduct') onClickInfoProductEmitter = new EventEmitter<{ product: EvaluatorProductI }>();

  constructor(
    private _stMng: SurveyEvaluateStateManagerService<SurveyEvaluateState>,
    private documentResource: DocumentResource,
    private modal: ModalService
  ) {
  }

  ngOnInit() {
    this.documents$ = this._stMng.getState$().pipe(
      pluck("documents"),
      map(doc => {
        return doc[this.product.cuuid];
      }),
      filter(doc => !!doc)
    );
  }


  toggleContent() {
    this._stMng.actionToggleProductContent(this.product);
  }


  onClickSelect($event) {
    $event.stopPropagation();
    this.product.selected = $event.target.checked;
    this.onSelectEmitter.emit(this.product);
  }


  onClickInfoProduct() {
    const eventData = {
      product: this.product
    }
    this.onClickInfoProductEmitter.emit(eventData);
  }


  toggleSelectAll(toggleValue: boolean) {
    this._stMng.actionToggleSelectionForCoveragesOrPackages(this.product, toggleValue);
  }


  isProductSelectable() {
    return this.product.sellable && this.product.selectable;
  }

  downloadDocument(doc: Document[]) {
    this.modal.openComponent(
      SurveyEvalDocumentModalComponent,
      {documentList: doc}
    )
  }
}

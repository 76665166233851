import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';
import {ChangeDetectorRef, Optional, ViewChild} from '@angular/core';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  BeneficiaryDefinition, BeneficiaryRole, FormuleQuotationTable,
  PaymentTypeDefinition, PostsalesError, PostsalesOperationObject, Risk, Role } from '../../models/postsales-operations-response.model';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import {Factor, RequestFactor} from '../../models/factor.model';
import {LpcFactorAdapterComponent} from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import {RisksFactors} from '../../models/risks-factors.model';
import { LpcBeneficiaryUtils } from '../../modules/lpc-beneficiary/util/lpc-beneficiary-utils';
import { LpcClaimBeneficiariesComponent } from '../../modules/lpc-beneficiary/lpc-claim-beneficiaries/lpc-claim-beneficiaries.component';
import { LoanQuotationTableDefinitions } from '../../models/loan.model';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';
import { CurrencyCacheService, LpcCurrencyCache } from '../../services/currency-cache.service';
import { LpcBeneficiaryService } from '../../services/beneficiary.service';

@Component({
  selector: 'lpc-loan-grant',
  templateUrl: './loan-grant.component.html',
  styleUrls: ['./loan-grant.component.scss'],
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class LoanGrantComponent extends AbsOperationComponent implements OnInit  {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };

  protected operationDataKey = 'loan-grant';
  private riskFactorsDefinitions: Risk[];
  private requestFactor: RequestFactor[];
  private $maxLoanAmount: number;
  public quotationTableDefinitions: { [key: string]: {value: string, type: string}; } = {};
  public beneficiariesDefinitions: BeneficiaryDefinition[] = [];
  private _beneficiaryData: any[];

  protected anagSubj = null;
  private $paymentTypes: PaymentTypeDefinition[] = [];

  public get paymentTypes(): PaymentTypeDefinition[] {
    return this.$paymentTypes;
  }

  get beneficiaryType(): string {
    if (!!this.beneficiariesDefinitions && !!this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].code;
    }
  }

  get idAssicurato(): string {
    if (!!this.beneficiariesDefinitions && !!this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].idAssicurato;
    } else if (!!this._beneficiaryData && this._beneficiaryData.length) {
      return this._beneficiaryData[0].beneficiary && this._beneficiaryData[0].beneficiary.idAssicurato;
    } else {
      return EMPTY_STR;
    }
  }

  public get feErrors(): PostsalesError[] {
    return LpcBeneficiaryUtils.getSettlementBeneficiariesErrors(this.formGroup, this.translate);
  }

  get maxLoanAmount(): string {
    return !isNaN(this.$maxLoanAmount) ?
    this.rgiNumberFormatter.transform(this.$maxLoanAmount, '', this.currencyFormatterOptions) :
    this.translate.getImmediate('lpc_maxLoanAmountKO');
  }

  @ViewChild('factorAdapter') factorAdapter: LpcFactorAdapterComponent;
  @ViewChild('beneficiaries') beneficiaries: LpcClaimBeneficiariesComponent;

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService,
    protected rgiNumberFormatter: RgiCountryLayerNumberFormatPipe,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService,
    protected beneficiaryService: LpcBeneficiaryService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService, plcQuestService, authService, anag);
    this.currencyFormatterOptions.currency = currencyService.currency;
  }

  ngOnInit() {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe(res => this.createDraftHandleResponse(res))
    );
  }

  createDraftHandleResponse(createDraftResponse: PostsalesOperationObject) {
    LpcBeneficiaryUtils.setBeneficiaryRoles(createDraftResponse.definitions.BenefiacyRoles as BeneficiaryRole[]);
    this.beneficiariesDefinitions = createDraftResponse.definitions.beneficiaries &&
                                    (createDraftResponse.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
    this._beneficiaryData = createDraftResponse.data.operationData.data.beneficiaries;
    if (!!this._beneficiaryData) {
      this.formGroup.get('beneficiaries').setValue(
        (this._beneficiaryData as any[]).map(beneficiary => {
          return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
        }),
        { emitEvent: false }
      );
    }
  }

  protected getTransformedOperationData(): any {
    let listRisksFactor: RisksFactors[] = [];
    if (!!this.loanGrantRisk) {
      const codeRisk: string = this.loanGrantRisk.code;
      const factors: RequestFactor[] = this.requestFactor;
      listRisksFactor = [{codeRisk, factors}];
    }
    const obj: any = {
      beneficiaries: this.formGroup.getRawValue().beneficiaries
        .map(claimBeneficiary => {
          return LpcBeneficiaryUtils.convertToBeneficiaryRequest(claimBeneficiary);
        }),
        listRisksFactor
    };
    return obj;
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dates: new UntypedFormControl(),
      factors: new UntypedFormGroup({}),
      beneficiaries: new UntypedFormControl([])
    });
  }

  protected updateDraft(step: string, reload: boolean = false, opDataType?: string): Observable<any> {
    if (this.beneficiaryService.checkAllQuestionnaires(this.stepper.activeStep.id, s => this.setQuestionaryError(s))) {
        return this.beneficiaryService.persistQuestionnaires(this.stepper.activeStep.id).pipe(
          switchMap(_ => super.updateDraft(step, reload, opDataType)),
          switchMap(result => {
            this.riskFactorsDefinitions = result.definitions.riskFactors as Risk[];
            const quotationLoanGrant = result.definitions.quotationLoanGrant as LoanQuotationTableDefinitions[];
            this.quotationTableDefinitions = this.adaptToQuotationTableObject(quotationLoanGrant);
            if (!!result.definitions.maxLoanAmount) {
              this.$maxLoanAmount = (result.definitions.maxLoanAmount as any).value;
            }
            this.$paymentTypes = result.definitions.paymentTypes as PaymentTypeDefinition[];
            if (result.definitions.beneficiaries != null) {
              this.beneficiariesDefinitions = result.definitions.beneficiaries &&
                                            (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
            }

            this.detectChanges();
            return of(result);
          })
        );
    }
  }

  adaptToQuotationTableObject(quotation: LoanQuotationTableDefinitions[]): FormuleQuotationTable {
    return quotation.reduce((group, formula) => {
      const category = formula.description;
      group[category] = !!group[category] ? group[category] : [];
      group[category] = { value: formula.value, type: formula.type };
      return group;
    }, {});
  }

  get loanGrantFactors(): Factor[] {
    return this.loanGrantRisk.factors;
  }

  get loanGrantRisk(): Risk {
    // the risk on which a loan can be granted is the only one having factors
    if (this.hasRiskFactors()) {
      return this.riskFactorsDefinitions.find(r => !!r.factors && !!r.factors.length);
    }
  }

  hasRiskFactors() {
    return !!this.riskFactorsDefinitions && !!this.riskFactorsDefinitions.length;
  }

  public updateFactors(factors: RequestFactor[]) {
    this.requestFactor = factors;
    this.$subscriptions.push(this.onReload('loanGrant').subscribe((result) => {
      this.riskFactorsDefinitions = result.definitions.riskFactors;
    }));
  }

  public onNext(step: string = null, publish: boolean = false, context = step, isConfirmAndAccept = false): void {
    if (step === 'loanGrant') {
      this.requestFactor = this.factorAdapter.getRequestFactor();
    }
    super.onNext(step, publish, context, isConfirmAndAccept);
  }

  public onAddedSubject($event: Role) {
    if ($event === null) {
      this.openModal('lpc_found_duplicate', 'lpc_duplicate_subject_message', true);
    }
  }

  public onTriggerQuestPreval($event: any) {
    if (!!$event) {
      this.anagSubj = $event;
      this.$subscriptions.push(
        this.plcQuestService.prevalQuest(this.operationDataKey, EMPTY_STR, this).subscribe(result => {
          this.questFactorsArray = result;
          this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, EMPTY_STR, this);
        })
      );
    }
  }

}

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Questionnaire} from './questionnaire';
import {ProposalService} from '../proposal.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {QuestionnaireElement} from './questionnaire-element';
import {Subject} from 'rxjs';
import {MicHttpService} from '../http/mic-http.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {

  private baseApiUrlV2;
  private questionnaires: Array<Questionnaire>;
  private currentQuestionnaire: Questionnaire;
  private newAnswerSignalChannel: Subject<QuestionnaireElement> = new Subject<QuestionnaireElement>();
  private confirmQuestionnaireSignalChannel: Subject<any> = new Subject<any>();
  private initQuestionnaireSignalChannel: Subject<any> = new Subject<any>();
  private refreshQuestionnaireSignalChannel: Subject<any> = new Subject<any>();
  private questionnairesListChannel: Subject<Array<Questionnaire>> = new Subject<Array<Questionnaire>>();

  protected httpClient: HttpClient;

  constructor(
    protected micHttpClient: MicHttpService,
    @Inject('environment') environment: any,
    private proposalService: ProposalService
  ) {
    this.httpClient = this.micHttpClient.getHttpClient();

    const newMotorFlowServicesPath = environment.api.portal.nmf;

    this.baseApiUrlV2 = (newMotorFlowServicesPath !== null && newMotorFlowServicesPath !== undefined)
      ? environment.api.portal.host + environment.api.portal.path + newMotorFlowServicesPath
      : environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  retrieveQuestionnaires() {
    const contractId = this.proposalService.getContractId();
    return this.httpClient.post<Array<Questionnaire>>(this.baseApiUrlV2 + '/portfolio/contract/' + contractId + '/questionnaire', {});
  }

  getQuestionnaires(): Array<Questionnaire> {
    return this.questionnaires;
  }

  setQuestionnaires(questionnaires: Array<Questionnaire>) {
    this.questionnaires = questionnaires;
  }

  updateQuestionnaires(questCode: string, questionnaire: Questionnaire) {
    const contractId = this.proposalService.getContractId();
    return this.httpClient.put<Questionnaire>(this.baseApiUrlV2 + '/portfolio/contract/'
      + contractId + '/questionnaire/' + questCode, questionnaire);
  }

  getCurrentQuestionnaire() {
    return this.currentQuestionnaire;
  }

  setCurrentQuestionnaire(questionnaire: Questionnaire) {
    this.currentQuestionnaire = questionnaire;
  }

  toFormGroup(questions: QuestionnaireElement[]) {
    const group: any = {};

    questions.forEach(question => {
      if (question.questionType !== 3) {
        let value;
        if (question.questionType === 4 && question.selectedAnswer) {
          value = new Date(question.selectedAnswer);
        } else if (question.questionType === 5 && !question.selectedAnswer) {
          value = '';
        } else {
          value = question.selectedAnswer;
        }
        const formControl = new UntypedFormControl(value, question.required ? [Validators.required] : null);
        group[question.id] = formControl;
      }
    });
    return new UntypedFormGroup(group);
  }

  getNewAnswerSignal() {
    return this.newAnswerSignalChannel.asObservable();
  }

  sendNewAnswerSignal(questionnaireElement: QuestionnaireElement) {
    this.newAnswerSignalChannel.next(questionnaireElement);
  }

  getConfirmQuestionnaireSignal() {
    return this.confirmQuestionnaireSignalChannel.asObservable();
  }

  sendConfirmQuestionnaireSignal() {
    this.confirmQuestionnaireSignalChannel.next();
  }

  getInitQuestionnaireSignal() {
    return this.initQuestionnaireSignalChannel.asObservable();
  }

  sendInitQuestionnaireSignal() {
    this.initQuestionnaireSignalChannel.next();
  }

  getRefreshQuestionnaireSignal() {
    return this.refreshQuestionnaireSignalChannel.asObservable();
  }

  sendRefreshQuestionnaireSignal() {
    this.refreshQuestionnaireSignalChannel.next();
  }

  getQuestionnairesListObservable() {
    return this.questionnairesListChannel.asObservable();
  }

  setQuestionnairesList(data: Array<Questionnaire>) {
    this.questionnairesListChannel.next(data);
  }

}

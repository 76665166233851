import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ElaborationRequest } from './dto/elaboration-request';
import { ApiElaborationRequestFilter } from './api/api-elaboration-request-filter';
import { ElaborationRequestDetails } from './dto/elaboration-request-details';
import { ApiNewRequestFilter } from './api/api-new-request-filter';

@Injectable({
  providedIn: 'root'
})

export class ElaborationRequestService {

  private sessionElaborationRequests: ElaborationRequest[][];
  private filterElaborationRequest: ApiElaborationRequestFilter[];
  private totalPages: number[];
  private baseApiUrlV2: string;
  private details: ElaborationRequestDetails;
  private urlElaborationRequestList = '/claims/claimsConnectorSearch';
  private urlElaborationRequestRerun = '/claims/claimsConnectorRerun';
  private urlElaborationNewRequest = '/claims/claimsConnectorActions';
  private urlElaborationRequestStop = '/claims/claimsConnectorStop';
  private urlElaborationRequestOutcome = '/claims/claimsConnectorOutcome';
  private urlActionsList = '/claims/claimsConnectorActionsList';


  constructor(
    private httpClient: HttpClient,
    @Inject('environment') environment: any,
  ) {
    this.baseApiUrlV2 = environment.api.portal.host + environment.api.portal.path + '/v2';
  }

  setSessionElaborationRequests(sessionId: string, elaborationRequestList: ElaborationRequest[]) {
    if (!this.sessionElaborationRequests) {
      this.sessionElaborationRequests = [];
    }
    this.sessionElaborationRequests[sessionId] = elaborationRequestList;
  }

  getSessionElaborationRequests(sessionId: string): ElaborationRequest[] {
    if (this.sessionElaborationRequests) {
      return this.sessionElaborationRequests[sessionId];
    }
  }

  setSessionElaborationRequestFilter(sessionId: string, elaborationRequestFilter: ApiElaborationRequestFilter) {
    if (!this.filterElaborationRequest) {
      this.filterElaborationRequest = [];
    }
    this.filterElaborationRequest[sessionId] = elaborationRequestFilter;
  }

  getSessionElaborationRequestFilter(sessionId: string): ApiElaborationRequestFilter {
    if (this.filterElaborationRequest) {
      return this.filterElaborationRequest[sessionId];
    }
  }

  getSessionElaborationRequestTotalPages(sessionId: string): number {
    if (this.totalPages) {
      return this.totalPages[sessionId];
    }
  }

  getElaborationRequestsList(res: ApiElaborationRequestFilter) {
    let queryString = '';

    if (res.codeChain) { queryString += '&codeChain=' + res.codeChain; }
    if (res.externalKey) { queryString += '&externalKey=' + res.externalKey; }
    if (res.codeAction) { queryString += '&codeAction=' + res.codeAction; }
    if (res.creationDateStart) { queryString += '&dateFrom=' + res.creationDateStart; }
    if (res.creationDateEnd) { queryString += '&dateTo=' + res.creationDateEnd; }
    if (res.outcome) { queryString += '&queueOutcomeState=' + res.outcome.eTypeCode; }

    if (queryString.length > 1) {
      queryString = '?' + queryString.slice(1);
    }

    return this.httpClient.get(this.baseApiUrlV2 + this.urlElaborationRequestList + queryString);
  }

  validateDate(controlName: string) {

    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];

      const min: Date = new Date(1990, 1, 1);

      if (control.value) {
        if (control.value instanceof Date) {

          const inputDate = control.value;
          inputDate.setHours(0, 0, 0, 0);
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
          if (inputDate > currentDate) {
            control.setErrors({ futureDate: true });
          } else if (inputDate < min) {
            control.setErrors({ tooOld: true });
          } else {
            control.setErrors(null);
          }
        } else {
          control.setErrors({ invalidDate: true });
        }
      } else {
        const validator: any = control.validator && control.validator(new UntypedFormControl());

        if (validator && validator.required) {
          control.setErrors({ required: true });
        } else {
          control.setErrors(null);
        }
      }
    };
  }

  round(value: number, precision: number) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  formatNumber(field: string) {
    return parseFloat(String(field).replace(',', '.'));
  }


  getElaborationRequestOutcome(code: number) {
    return this.httpClient.get(this.baseApiUrlV2 + this.urlElaborationRequestOutcome + '?elaborationId=' + code);
  }

  setElaborationRequestDetailList(details: ElaborationRequestDetails) {
    this.details = details;
  }

  getElaborationRequestDetailList() {
    return this.details;
  }

  callStop(elabId: string, jsonId?: number) {
    const entity = {
      elaborationId: elabId,
      idParam: jsonId,
      reason: null
    };

    return this.httpClient.put(this.baseApiUrlV2 + this.urlElaborationRequestStop, entity);
  }

  rerunRequest(elabId: string, reType: string, reData?: number) {
    const claimsConnectorRerunEntity = {
      elaborationId: elabId,
      rerunType: reType,
      rerunDate: reData
    };

    return this.httpClient.post(this.baseApiUrlV2 + this.urlElaborationRequestRerun, claimsConnectorRerunEntity);
  }

  newRequest(entity: ApiNewRequestFilter) {
    return this.httpClient.post(this.baseApiUrlV2 + this.urlElaborationNewRequest, entity);
  }

  getActionsList() {
    return this.httpClient.get(this.baseApiUrlV2 + this.urlActionsList);
  }

}

import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { NoteComponent } from '../../objects/note.component';

@Component({
  selector: 'claims-access-note',
  templateUrl: './access-note.component.html',
  styleUrls: ['./access-note.component.scss']
})
export class AccessNoteComponent implements NoteComponent, OnInit {

  @Input() data: any;
  @Input() rechargeEventEmitter: EventEmitter<boolean>;
  rightColumnStyle = '';

  constructor() { }

  ngOnInit() {
    this.rightColumnStyle = this.getRightColumnStyle();
  }

  private getRightColumnStyle() {
    return !this.data.hasLeftColumn ? 'hasOnlyRight' : '';
  }
}

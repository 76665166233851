<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit(null)" translate>
    _GP_._TITLE_._SUBSTITUTION_DATA_
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button rgi-rx-button color="primary" (click)="modalClose.emit(null)" data-qa="rgi-gp-modal-close" translate>
      _GP_._BTN_._CLOSE_
    </button>
    <button rgi-rx-button color="primary" (click)="confirm()" data-qa="rgi-gp-modal-confirm" translate>
      _GP_._BTN_._CONFIRM_
    </button>
  </rgi-rx-panel-footer>
  <div [formGroup]="recoverAppsForm" class="rgi-ui-grid-1">
    <rgi-rx-form-field data-qa="rgi-gp-substitution-type" class="rgi-ui-col-6 rgi-gp-mb-0 rgi-gp-pb-0">
      <label rgiRxLabel translate class="rgi-gp-flex-gr-1">_GP_._LABEL_._SUBSTITUTION_TYPE_</label>
      <rgi-rx-button-toggle formControlName="substitutionType">
        <button rgi-rx-button rgiRxValueOption [value]="substitutionType.FULL" translate>
          _GP_._LABEL_._SUBST_TYPE_FULL_
        </button>
        <button rgi-rx-button rgiRxValueOption [value]="substitutionType.INCREMENTAL" translate>
          _GP_._LABEL_._SUBST_TYPE_INCREMENTAL_
        </button>
      </rgi-rx-button-toggle>
    </rgi-rx-form-field>
    <rgi-rx-form-field data-qa="rgi-gp-renumber-apps" class="rgi-ui-col-6 rgi-gp-mb-0 rgi-gp-pb-0">
      <label rgiRxLabel translate class="rgi-gp-flex-gr-1">_GP_._LABEL_._RENUMBER_APPS_</label>
      <rgi-rx-button-toggle formControlName="renumberApps">
        <button rgi-rx-button rgiRxValueOption [value]="true" translate>_GP_._BTN_._YES_</button>
        <button rgi-rx-button rgiRxValueOption [value]="false" translate>_GP_._BTN_._NO_</button>
      </rgi-rx-button-toggle>
    </rgi-rx-form-field>
  </div>
</rgi-rx-panel>

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AnswerType, QuestionnaireComponent, QuestionnaireFlatI} from '@rgi/ng-passpro';
import {RgiRxTranslationService} from '@rgi/rx/i18n';
import {Subscription} from 'rxjs';
import {RgiRxKeyInterpolationRef} from '@rgi/rx/i18n/src/i18n-api';
import { Questionnaire } from '../model/questionnaire.model';

@Component({
  selector: 'lib-questionnaire-edit',
  templateUrl: './questionnaire-edit.component.html',
  styleUrls: ['./questionnaire-edit.component.css']
})
export class QuestionnaireEditComponent implements OnInit, OnDestroy {
  showErrors = false;
  successMessage: string;
  errormessages = [];
  @ViewChild(QuestionnaireComponent) questionnaireComp: QuestionnaireComponent;
  @Input() quest: Questionnaire;
  @Input() questFlat: QuestionnaireFlatI;
  @Input() disabledQuest: { code: string; }[];
  @Input() readOnly: boolean;
  @Output() saving = new EventEmitter<{ quest: Questionnaire, version: QuestionnaireFlatI }>();
  private validationTranslateSub = Subscription.EMPTY;

  constructor(
    private activeModal: NgbActiveModal,
    protected translate: RgiRxTranslationService
  ) {
  }

  ngOnInit() {
    if (!this.quest || !this.questFlat) {
      throw new Error('Attribute \'quest\' or \'questFlat\' is required');
    }
  }

  ngOnDestroy(): void {
    this.validationTranslateSub.unsubscribe();
  }


  close(): void {
    this.activeModal.dismiss();
  }

  save(): void {
    this.showErrors = !this.questionnaireComp.validate();
    const mess = 'Questionario ' + this.quest.id + ' salvato correttamente';
    if (!this.showErrors) {
      if (this.successMessage) {
        this.successMessage += '<br/>' + mess;
      } else {
        this.successMessage = mess;
      }
      this.saving.emit({quest: this.quest, version: this.questionnaireComp.questionnaireFlat});
      this.close();
    } else {
      const invalidQuestArray = this.questionnaireComp.questionnaire.sections
        .reduce((list, section) => [...list, ...section.questions], []).filter(quest => !quest.isValid && !!quest.visible);
      this.addValidation(invalidQuestArray);
    }
  }

  addValidation(invalidQuestArray: any[]): void {
    const keysRefs: RgiRxKeyInterpolationRef[] = [
      {
        key: 'lpc_error_mandatory_fields'
      }
    ];
    invalidQuestArray.forEach((q) => {
      if (q.answers[0].answer.type !== AnswerType.NUMERIC) {
        keysRefs.push({
          key: 'questionSTR_invalid',
          params: {question: q.text}
        });
      } else {
        keysRefs.push({
          key: 'questionNUM_invalid',
          params: {question: q.text}
        });
      }
    });
    this.validationTranslateSub = this.translate.translateAll(
      ...keysRefs
    ).subscribe(
      next => this.errormessages = next
    );
  }
}

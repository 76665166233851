import { Injectable } from '@angular/core';
import {EnvironmentService, ResponseResult} from "@rgi/ng-passpropro-core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/internal/operators";
import {Product} from "../model/product";

@Injectable()
export class ProductService {

  constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) { }

  public getPassProProductTemplate(code:string):Observable<any> {
    return this.httpClient.get(`${this.environmentService.environmentUrl}/v2/passpro/product/template/${code}`)
      .pipe(
        map((resp: ResponseResult<any>) => {
          return resp.result;
        })
      )
  }

  public getProducts(nameOrCode:string):Observable<Product[]> {
    return this.httpClient.put(`${this.environmentService.environmentUrl}/v2/product/filter`, {
      nameOrCode: nameOrCode
    }).pipe(
      map((value: ResponseResult<Product[]>) => {
        return value.result;
      })
    )
  }

}

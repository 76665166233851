import { Injectable } from '@angular/core';
import { ActiveRoute, RoutingService } from '@rgi/rx/router';
import {
  ROUTES_GP_HOME,
  ROUTES_GP_INQUIRY_DETAIL, ROUTES_GP_ISSUE_CONFIRM, ROUTES_GP_POLDATA
} from '../group-policy-constants/routes-constants';
import { GPMotorAppPayload } from '../group-policy-models/group-policy-flow-integrations';
import { DataForSteps } from '../group-policy-models/group-policy-issue-home';
import { GROUP_POLICY_MODE_PROPERTIES } from '../group-policy-constants/general';
import { Mode } from '../group-policy-models/group-policy-mode';

@Injectable()
export class GroupPolicyIntegrationService {

  constructor(protected routingService: RoutingService) {
  }

  closeSession(id) {
    this.routingService.destroy(id);
  }

  backToPrevPage(_id, _idParentSession) {
    this.routingService.destroy(_id);
  }

  startLifeFlow(_id, _data, _isCached = false, _route = 'anag') {
  }

  startMotorFlow(_payload: GPMotorAppPayload, _idSession: string) {
  }

  openApplicationDetail(_row: any, _idSession: string, _mode: Mode) {

  }

  openSubjectDetail(_subject: any, _idSession: string) {
    /* on portal is opened the card of subject detail the in angular8 native doesn't exist */
  }

  addCssClass(_id) {

  }

  removeCssClass(_id) {

  }

  backToInquiry(_inquiryProposalNumber: string, _idSession: string, _idParentSession: string) {
    this.navigate(this.routingService, ROUTES_GP_INQUIRY_DETAIL, {proposalNumber: _inquiryProposalNumber} as DataForSteps);
  }

  navigate(routingService: RoutingService, destinationRoute: string, routeData: any, activeRoute?: ActiveRoute) {
    routingService.navigate(destinationRoute, routeData, activeRoute ? activeRoute.id : null);
  }

  backToHome(activeRoute: ActiveRoute) {
    this.routingService.navigate(ROUTES_GP_HOME, {}, activeRoute.id);
  }

  goToInquiry(prevStepData: DataForSteps, _id: string = null) {
    this.navigate(this.routingService, ROUTES_GP_INQUIRY_DETAIL, prevStepData);
  }

  goToIssuePolicyPage(prevStepData: DataForSteps, _id: string = null) {
    this.navigate(this.routingService, ROUTES_GP_ISSUE_CONFIRM, prevStepData);
  }

  getRequestedInquiryMode(previousStepData: DataForSteps): string {
    return previousStepData.idMovement ? GROUP_POLICY_MODE_PROPERTIES.MODES.READ_ONLY : GROUP_POLICY_MODE_PROPERTIES.MODES.DEFAULT_MODE;
  }

  goToEdit(prevStepData: DataForSteps) {
    this.navigate(this.routingService, ROUTES_GP_POLDATA, prevStepData);
  }
}

import {TemplateRef, Type} from '@angular/core';
import {RgiRxPushMessage, RgiRxPushOptions, RgiRxPushOptionsRef} from './reaction-api';

/**
 * Create a push notification
 * @param content the content to render
 * @param status the status of the content
 * @param defaults the default settings for the push
 * @param options any option for the message
 */
export function rgiRxPushMessageFactory(content: string | TemplateRef<any> | Type<any>, status: string, defaults: RgiRxPushOptions, options?: RgiRxPushOptionsRef): RgiRxPushMessage {
  const computedOpts = mergeOpts(defaults, options);
  return {
    content,
    status,
    delay: computedOpts.delay,
    tag: computedOpts.tag,
    dismissible: computedOpts.dismissible,
    detail: computedOpts.detail,
    options: {
      icon: computedOpts.icon,
      expanded: computedOpts.expanded
    },
    context: computedOpts.context
  };
}

/**
 * Merge options passed by with default PushOptions
 * @param refOptions the options of a ref
 * @param options the default options
 */
function mergeOpts(options: RgiRxPushOptions, refOptions: RgiRxPushOptionsRef): RgiRxPushOptionsRef {
  if (!refOptions) {
    return options;
  }
  return {
    tag: refOptions.tag,
    delay: refOptions.delay !== undefined ? refOptions.delay : options.delay,
    dismissible: refOptions.dismissible !== undefined ? refOptions.dismissible : options.dismissible,
    icon: refOptions.icon,
    context: refOptions.context,
    detail: refOptions.detail,
    expanded: refOptions.expanded
  };
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'lic-checkbox-accordion',
  templateUrl: './lic-checkbox-accordion.component.html',
  styleUrls: ['./lic-checkbox-accordion.component.scss']
})
export class LicCheckboxAccordionComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('toolBodyContent', {static: true, read: ElementRef}) toolBodyContent: ElementRef;
  @Output() toolCheck: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() accordionColor = null;
  @Input() toolName: string;
  @Input() active = false;
  @Input() public disabled = false;
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    active: new UntypedFormControl()
  });

  private $subscriptions: Subscription[] = [];

  constructor() { }

  public isToolBodyOpen(): boolean {
    return this.formGroup.get('active').value;
  }

  ngOnInit() {
    // inizializzo a true la checkBox se [active] in input al componente è settata a true
    if (!!this.active) {
      this.formGroup.get('active').setValue(true);
    }

    this.$subscriptions.push(
      this.formGroup.get('active').valueChanges.pipe(
        distinctUntilChanged()
      ).subscribe(value => {
        this.toolCheck.emit(value);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    /*if (changes.initiallyActive && !!changes.initiallyActive.currentValue) {
      this.formGroup.get('active').setValue(changes.active.currentValue);
    }*/
    if (changes.disabled) {
      const disabled = changes.disabled.currentValue;
      if (disabled) {
        this.formGroup.get('active').disable();
      } else {
        this.formGroup.get('active').enable();
      }
    }
  }

  ngOnDestroy() {
    this.$subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  activate(emitEvent: boolean = true): void {
    this.formGroup.get('active').setValue(true, { emitEvent });
  }

  deactivate(emitEvent: boolean = true): void {
    this.formGroup.get('active').setValue(false, { emitEvent });
  }

}

import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, PipeTransform, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CardComponent, CardComponentService, SessionService } from '@rgi/digital-claims-common-angular';
import { RoutableComponent } from '@rgi/portal-ng-core';
import { RgiRxDataTablePipe, RgiRxDatatableRowAction, RGI_RX_DATA_TABLE_PIPE_VIEW, TableSchema } from '@rgi/rx/ui';
import { NewPaymentService } from '../new-payment.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'claims-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss'],
  viewProviders: [
    {provide: RGI_RX_DATA_TABLE_PIPE_VIEW, multi: true, useFactory: blankFieldPipeFactory}
  ]
})
export class PaymentListComponent extends CardComponent implements OnInit {
  public isAbilitatoClaimsPaymentUrgency: boolean = false;
  paymentlistResult: any[];
  filter: any;
  public jump: boolean = false;
  @ViewChild('tableEl') dt: any; // RgiRxDatatableComponent

 @Input() card: any;
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();

  schema: TableSchema;

  constructor(
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    @Inject('getSystemProperty') private getSystemProperty: any,
    @Inject('coreResult') private coreResult: any,
    @Inject('claimsFinderIntegrationPassService') private claimsFinderIntegrationPassService: any,
    @Inject('sessionService') private session: any,
    @Inject('eventService') private eventService: any,
    private sessionServiceComm: SessionService,
    private newPaymentService: NewPaymentService,
    public translateService: RgiRxTranslationService
    ) {
      super(cardComponentService, sessionService);

      const obsClaim$ = this.translateService.translate('_CLAIMS_._CLAIM_NUMBER'); 
      const obsMovement$ = this.translateService.translate('_CLAIMS_._MOVEMENT_TYPE'); 
      const obsNumber$ = this.translateService.translate('_CLAIMS_._NUMBER');
      const obsType$ = this.translateService.translate('_CLAIMS_._PAYMENT_TYPE');
      const obsDefinition$ = this.translateService.translate('_CLAIMS_._PAYMENT_DEFINITION');
      const obsDate$ = this.translateService.translate('_CLAIMS_._PAYMENT_DATE');
      const obsState$ = this.translateService.translate('_CLAIMS_._STATE');
      const obsAmount$ = this.translateService.translate('_CLAIMS_._AMOUNT');
      const obsActions$ = this.translateService.translate('_CLAIMS_._ACTIONS');
      combineLatest([
        obsClaim$,
        obsMovement$,
        obsNumber$,
        obsType$,
        obsDefinition$,
        obsDate$,
        obsState$,
        obsAmount$,
        obsActions$
      ]).subscribe(
          ([claim, movement, number, type, definition, date, state, amount, actions]) => {
            this.schema  = {
              rows: [
                {
                  name: 'numClaim',
                  title: claim,
                },
                {
                  name: 'movementTypeDescr',
                  title: movement,
                },
                {
                  name: 'numPayment',
                  title: number,
                },
                {
                  name: 'paymentTypeDescr',
                  title: type,
                },
                {
                  name: 'definitionPayDescr',
                  title: definition,
                  format: {
                    pipe: 'blankField',
                  }
                },
                {
                  name: 'datePay',
                  title: date,
                  format: {
                    pipes: [
                      {
                        pipe: 'date',
                        args: ['shortDate']
                      },
                      {
                        pipe: 'blankField',
                      }
                    ]
                  }
                },
                {
                  name: 'statusPayDescr',
                  title: state,
                },
                {
                  name: 'amountPay',
                  title: amount,
                },
                {
                  name: 'actions',
                  title: actions,
                  actions: [
                    {
                      name: 'modify',
                      label: '',
                      styleClass: 'rgi-ui-icon-edit',
                      hidden: {
                        assert: {
                          path: 'enabledPaymentIssue',
                          value: false,
                          condition: '='
                        },
                      }
                    },
                    {
                      name: 'detail',
                      label: '',
                      styleClass: 'rgi-ui-icon-search',
                      hidden: {
                        assert: {
                          path: 'enabledPaymentDetail',
                          value: false,
                          condition: '='
                        },
                     }
                    },
                  ]
                }
              ],
            header: ['numClaim', 'movementTypeDescr', 'numPayment', 'paymentTypeDescr',
            'definitionPayDescr', 'datePay', 'statusPayDescr', 'amountPay', 'actions' ]
            };
          }
        )
   }

  ngOnInit() {

    this.paymentlistResult = this.coreResult.getResult(this.id, 'paymentlistResult').resultPay;
    this.filter = this.coreResult.getResult(this.id, 'paymentlistResult').filter;
    this.isAbilitatoClaimsPaymentUrgency = this.coreResult.getResult(this.id, 'paymentlistResult').isAbilitatoClaimsPaymentUrgency;

    if (this.isAbilitatoClaimsPaymentUrgency) {
      const index = this.schema.header.indexOf('amountPay');

      const obsUrgency$ = this.translateService.translate('_CLAIMS_._URGENCY'); 
      const obsPaybility$ = this.translateService.translate('_CLAIMS_._PAYBILITY'); 
      combineLatest([
        obsUrgency$,
        obsPaybility$,
      ]).subscribe(
          ([urgency, paybility]) => {
      this.schema.rows.splice(index, 0, {
        name: 'urgencyTypeDescr',
        title: urgency,
      },
      {
        name: 'dueData',
        title: paybility,
        format: {
          pipes: [
            {
              pipe: 'date',
              args: ['shortDate']
            },
            {
              pipe: 'blankField',
            }
          ]
        }
      });
      this.schema.header.splice(index, 0, 'urgencyTypeDescr', 'dueData');
    })
    }

  }

  onActionClick(event: RgiRxDatatableRowAction<any>) {

    if (event.name === 'modify') {
      this.settlement(event.row.idPayment, event.row.idClaim);
    }

    if (event.name === 'detail') {

      const idSession = this.session.list().find(el => el.isActive).idSession;
      const claim: any = {};
      claim.idClaim = event.row.idClaim;
      claim.idPotencialClaim = event.row.idPotencialClaim;
      claim.claimNumber = event.row.numClaim;

      const party: any = {};
      party.identifier = event.row.idParty;

      const filter: any = {};
      filter.claimId = claim.idClaim;
      filter.idPotencialClaim = claim.idPotencialClaim;
      filter.section = event.row.idMovementType === '1' ? 'receipts' : 'settlement';
      filter.partyId = event.row.idParty;

      const cardName = 'claimsNewPayment';
      const cardView = 'home';
      const idResult = this.session.open(cardName, cardView, '', true, null, null, idSession);
      this.coreResult.setResult(idResult, 'claim', claim);
      this.coreResult.setResult(idResult, 'lastSession', idSession);
      this.coreResult.setResult(idResult, 'party', party);
      this.coreResult.setResult(idResult, 'filter', filter);
      this.coreResult.setResult(idResult, 'idsettlement', event.row.idPayment);
      this.coreResult.setResult(idResult, 'onlySettlementDetails', true);
      this.coreResult.setResult(idResult, 'fromDashboardPayment', true);
    }

  }


  settlement(idPayment, idClaim) {
    this.jump = true;

    const filter = {
      claimId: idClaim,
      partyId: null,
      section: 'receipts',
      settlementId: idPayment,
      jumpFunction: 'funcGestioneQuietanza'
    };

    const act = {
      extension: null,
      jumpCommand: 'newSettlementClaim',
      menu: null,
      operation: { identification: '35', code: '000035', description: 'Liquidazione Quietanza' }
    };

    filter.settlementId = idPayment;

    filter.jumpFunction = this.claimsFinderIntegrationPassService.getFunctionByJump(act, filter);
    const objIntegrationPass = this.claimsFinderIntegrationPassService.getObjIntegrationClaimActions(act, filter);
    this._createJump(this.card, objIntegrationPass, 'Liquidazione Quietanza', 'action', this.card.name);
  }

  _createJump(card, objIntegrationPass, title, route, parent) {

    objIntegrationPass.objNavigation.idCardParent = card.id; // add idParent
    card.integrationPass = objIntegrationPass;

    if (objIntegrationPass.objNavigation.newSession) {
        const idNavigation = this.session.open(parent, route, title, true, card.integrationPass, null, card.idSession);

        const idSession = this.sessionServiceComm.idSessionFromId(idNavigation);

        this.sessionServiceComm.registerSessionDeathCallback(idSession, () => {
          this.refresh();
        });

        if (idNavigation) {
          // this.eventService.broadcastEvent(CONTAINER_EVENT.START_LOADER_EVENT, null);
        }
    }
}

refresh() {

  console.log('refresh');
  this.eventService.broadcastEvent('start-loader');
  this.newPaymentService.getListPayment(this.filter).subscribe(
    (result: any) => {
      if (result.outcome.code === '1') {
        this.dt.dataSource.update(result.listPayment);
      }
      this.eventService.broadcastEvent('stop-loader');

    },
    (error: any) => {
      this.eventService.broadcastEvent('stop-loader');
    });
}

return() {
  const session = this.getCurrentSession();
  this.session.remove(session.idSession);
}
  getCurrentSession() {
    return this.session.list().find(el => el.isActive);
  }

}

export class BlankField implements PipeTransform {

  transform(value: any): any {

    if (!value) {

      return '-';
    }
    return value;
  }

}

export function blankFieldPipeFactory(): RgiRxDataTablePipe<BlankField> {
  return {
    pipe: new BlankField(),
    name: 'blankField'
  };
}


import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClaimListData, ClaimReportDetail, NewClaim } from '../models/claim-report.model';
import { PV_TOKEN } from '../models/consts/lpc-consts';
import { AnagSubject } from '../models/subject.model';

@Injectable({
  providedIn: 'root'
})
export class ClaimReportService {

  private readonly baseApiUrl: string;
  private idSubject: string;
  private anagSubject: AnagSubject;
  private idNode: string;
  private sessionId: string;

  constructor(
    private httpClient: HttpClient,
    @Inject(PV_TOKEN.ENV) environment: any,
    @Inject(PV_TOKEN.CORE_INJECTOR) private injector: any,
    @Inject(PV_TOKEN.CORE_AUTH_SERVICE) private authService: any
  ) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
  }

  public getAvailableNodes(): Observable<any> {
    return this.httpClient.get(this.baseApiUrl + '/admin/agencies?loginNodeId=' + this.getIdpv());
  }

  public getIdpv(): string {
    return this.authService.getSalePointId();
  }

  public storeSubjectAndNode(idSbj: string, nodeId: string, sessionId: string) {
    this.idSubject = idSbj;
    this.idNode = nodeId;
    this.sessionId = sessionId;
  }

  public getSubjectId(): string {
    return this.idSubject;
  }

  public getNodeId(): string {
    return this.idNode;
  }

  public getAvailablesClaimReports(idSbj: string, idNode: string): Observable<ClaimListData> {
    const username = this.authService.getOperator().username;
    return this.httpClient
    .get(this.baseApiUrl + '/v2/life/claim?idSubject='
    + idSbj + '&idNode=' + idNode + '&userName=' + username) as Observable<ClaimListData>;
  }

  public getClaimDetail(claimId: string, request: any): Observable<ClaimReportDetail> {
    return this.httpClient.get(this.baseApiUrl +
      '/v2/life/claim/' + claimId + '?idSubject=' + request.idSubject +
      '&idNode=' + request.idNode + '&userName=' + request.userName) as Observable<ClaimReportDetail>;
  }

  public changeStatus(claimId: string, request: any): Observable<any> {
    return this.httpClient.put(this.baseApiUrl + '/v2/life/claim/' + claimId, request) as Observable<any>;
  }

  public getNewClaim(idSubject: string, idNode: string): Observable<NewClaim> {
    return this.httpClient
    .get(this.baseApiUrl + '/v2/life/claim/' + idSubject + '/defaultvalues?idNode=' + idNode, {}) as Observable<NewClaim>;

  }

  public saveClaim(request: any): Observable<any> {
    return this.httpClient
    .post(this.baseApiUrl + '/v2/life/claim', request) as Observable<any>;
  }

  public updateClaim(id: string, request: any): Observable<any> {
    return this.httpClient
    .post(this.baseApiUrl + '/v2/life/claim/' + id, request) as Observable<any>;
  }

  public closeCard() {
    const sessionService = this.injector.get(PV_TOKEN.CORE_SESSION_SERVICE);
    const s = sessionService.list().find(ss => ss.id === this.sessionId);
    if (s) {
      sessionService.remove(s.idSession);
    } else {
      throw new Error('Unable to find session ' + this.sessionId + ' with card');
    }
  }

  public storeSubject(subject: AnagSubject) {
    this.anagSubject = subject;
  }

  public getSubject() {
    return this.anagSubject;
  }
}

export class PartyAsset {
  public party: Party;

  public main: boolean;
  public roleId: string;

  constructor() {
    this.party = new Party();
  }
}

class Party {
  partyId: string | undefined;
  idLatestPhotos: string | null | undefined;
  name: string | null | undefined;
  surname: string | null | undefined;
  denomination: string | null | undefined;
  nominative: string | null | undefined;
}

export class PartyRequest {
  public parties: Array<PartyAsset>;
}


import { ChangeDetectorRef, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWrapperService } from '../../i18n/translation-wrapper.service';
import { NotifierService } from '@rgi/portal-ng-core';
import { QuestionnaireCacheService } from '@rgi/questionnaires-manager';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EMPTY_STR, PV_TOKEN } from '../../models/consts/lpc-consts';
import { RequestFactor } from '../../models/factor.model';
import {
    BeneficiaryDefinition, BeneficiaryRole,
    Definition,
  FactorDefinition,
  FormFieldsDefinition,
  PaymentTypeDefinition, PostsalesError, PostsalesOperationObject
} from '../../models/postsales-operations-response.model';

import { LpcFactorAdapterComponent } from '../../modules/lpc-factor-adapter/lpc-factor-adapter.component';
import { AnagService } from '../../services/anag.service';
import { AuthService } from '../../services/auth.service';
import { PlcQuestService } from '../../services/plc-quest.service';
import { TransfeToOtherFundDataStepperConfig, TRANSFER_TO_OTHER_FUND } from './config/transfer-to-other-fund-configuration';
import { Beneficiary } from '../../modules/lpc-beneficiary/model/beneficiary';
import { LpcFormFieldHandlerUtil } from '../../modules/lpc-form-field-handler/utils/lpc-form-field-handler.utils';
import { AbsOperationComponent } from '../abs-operation-component/abs-operation.component';
import { LpcClaimBeneficiariesComponent } from '../../modules/lpc-beneficiary/lpc-claim-beneficiaries/lpc-claim-beneficiaries.component';
import { LpcBeneficiaryUtils } from '../../modules/lpc-beneficiary/util/lpc-beneficiary-utils';
import { PostsalesOperationsService } from '../../services/postsales-operations.service';
import { LpcBeneficiaryService } from '../../services/beneficiary.service';


@Component({
  selector: 'lpc-transfer-to-other-fund',
  templateUrl: './transfer-to-other-fund.component.html',
  styleUrls: ['./transfer-to-other-fund.component.scss']
})
export class TransferToOtherFundComponent extends AbsOperationComponent implements OnInit {
  public readonly TRANSFER_TO_OTHER_FUND_STEP: TransfeToOtherFundDataStepperConfig = TRANSFER_TO_OTHER_FUND;

  protected operationDataKey = 'reserve-transfer';

  @ViewChild('factorAdapter') child: LpcFactorAdapterComponent;
  @ViewChild('assetFactorAdapter') assetChild: LpcFactorAdapterComponent;
  @ViewChild('beneficiaries') beneficiaries: LpcClaimBeneficiariesComponent;

  public get feErrorsBenef(): PostsalesError[] {
    return LpcBeneficiaryUtils.getSettlementBeneficiariesErrors(this.formGroup, this.translate);
  }

  public paymentTypes: PaymentTypeDefinition[] = [];
  public draftDataTransfer: FormFieldsDefinition[] = [];
  protected requestDataTransfer: RequestFactor[] = [];
  public beneficiariesDefinitions: BeneficiaryDefinition[] = [];
  public beneficiaryData: Beneficiary[] = [];
  public beneficiaryRoles: BeneficiaryRole[] = [];
  private _beneficiaryData: any[];
  protected anagSubj = null;

  public listProductFactor: FactorDefinition[] = [];
  protected requestProductFactor: RequestFactor[] = [];
  get hasProductFactor(): boolean {
    return !!this.listProductFactor && !!this.listProductFactor.length;
  }

  public listAssetFactor: FactorDefinition[] = [];
  protected requestAssetFactor: RequestFactor[] = [];
  get hasAssetFactor(): boolean {
    return !!this.listAssetFactor && !!this.listAssetFactor.length;
  }

  get idAssicurato(): string {
    if (this.beneficiariesDefinitions && this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].idAssicurato;
    } else if (!!this._beneficiaryData && this._beneficiaryData.length) {
      return this._beneficiaryData[0].beneficiary && this._beneficiaryData[0].beneficiary.idAssicurato;
    } else {
      return EMPTY_STR;
    }
  }


  get beneficiaryType(): string {
    if (this.beneficiariesDefinitions && this.beneficiariesDefinitions.length) {
      return this.beneficiariesDefinitions[0].code;
    }
  }

  // QUOTATION CONTROLS BUTTONS
  public tableDefinitions: Definition[] = [];
  public insuredSettlements: any[] = [];

  constructor(
    @Inject(PV_TOKEN.POSTSALES_SERVICE) protected operations: PostsalesOperationsService,
    protected cd: ChangeDetectorRef,
    protected translate: TranslationWrapperService,
    @Inject(PV_TOKEN.CORE_INJECTOR) protected injector: any,
    @Optional() protected questCacheService: QuestionnaireCacheService,
    protected modalService: NgbModal,
    protected notifierService: NotifierService,
    protected plcQuestService: PlcQuestService,
    protected authService: AuthService,
    protected anag: AnagService,
    protected beneficiaryService: LpcBeneficiaryService
  ) {
    super(operations, cd, translate, injector, questCacheService, modalService, notifierService, plcQuestService, authService, anag);
  }

  protected getFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      [TRANSFER_TO_OTHER_FUND.DATE.formName]: new UntypedFormControl(),
      [TRANSFER_TO_OTHER_FUND.POLICY_FACTORS.formName]: new UntypedFormGroup({}),
      [TRANSFER_TO_OTHER_FUND.ASSET_FACTORS.formName]: new UntypedFormGroup({}),
      [TRANSFER_TO_OTHER_FUND.DATA_TRANSFER.formName]: new UntypedFormControl(),
      [TRANSFER_TO_OTHER_FUND.QUOTATION.formName]: new UntypedFormGroup({}),
      [TRANSFER_TO_OTHER_FUND.BENEFICIARIES.formName]: new UntypedFormControl([])
    });
  }

  ngOnInit(): void {
    this.initializeSession();
    this.$subscriptions.push(
      this.createDraft().subscribe(result => {
            LpcBeneficiaryUtils.setBeneficiaryRoles(result.definitions.BenefiacyRoles as BeneficiaryRole[]);
            this.draftDataTransfer = result.definitions.reserveTransfer as FormFieldsDefinition[];
            this.formGroup.get(TRANSFER_TO_OTHER_FUND.DATA_TRANSFER.formName).patchValue({
            definitionsFields: this.draftDataTransfer.map(x => LpcFormFieldHandlerUtil.mapFormFieldData(x))});
            this._beneficiaryData = result.data.operationData.data.beneficiaries;
            if (!!this._beneficiaryData) {
                this.formGroup.get(TRANSFER_TO_OTHER_FUND.BENEFICIARIES.formName).setValue(
                    (this._beneficiaryData as any[]).map(beneficiary => {
                    return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
                    }),
                    {emitEvent: false}
                );
            }
      })
    );
  }

  public onNext(step: string = null, publish: boolean = false, context = step, isConfirmAndAccept = false): void {
    if (step === TRANSFER_TO_OTHER_FUND.POLICY_FACTORS.id) {
      this.requestProductFactor = this.child.getRequestFactor();
    } else if (step === TRANSFER_TO_OTHER_FUND.ASSET_FACTORS.id) {
      this.requestAssetFactor = this.assetChild.getRequestFactor();
    }
    if (this.isFormValid(this.formGroup.get(step))) {
      super.onNext(step, publish, context, isConfirmAndAccept);
    } else {
      this.setFeErrors(step);
    }
  }

  protected updateDraft(step: string, reload?: boolean, opDataType?: string): Observable<any> {
    if (this.beneficiaryService.checkAllQuestionnaires(this.stepper.activeStep.id, s => this.setQuestionaryError(s))) {
      return this.beneficiaryService.persistQuestionnaires(this.stepper.activeStep.id)
        .pipe(switchMap(_ => this.defaultUpdateDraft(step, reload, opDataType)));
    } else {
      return this.defaultUpdateDraft(step, reload, opDataType);
    }
  }

  private defaultUpdateDraft(step: string, reload: boolean, opDataType: string): Observable<PostsalesOperationObject> {
    return super.updateDraft(step, reload, opDataType).pipe(
      switchMap(result => {
        this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
        LpcBeneficiaryUtils.setBeneficiaryRoles(result.definitions.BenefiacyRoles as BeneficiaryRole[]);
        this.beneficiariesDefinitions = result.definitions.beneficiaries &&
          (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
        this.tableDefinitions = result.definitions.settlement as Definition[];
        this.insuredSettlements = result.data.operationData.data.insuredSettlements;
        return of(result);
      })
    );
  }


  updateDraftHandleResponse(result: PostsalesOperationObject, step: string, reload?: boolean, opDataType?: string) {
    super.updateDraftHandleResponse(result, step, reload, opDataType);
    this.listProductFactor = result.definitions.productFactors as FactorDefinition[];
    this.listAssetFactor = result.definitions.goodsFactors as FactorDefinition[];
    this.paymentTypes = result.definitions.paymentTypes as PaymentTypeDefinition[];
    // eslint-disable-next-line max-len
    this.beneficiariesDefinitions = result.definitions.beneficiaries && (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
    this.formGroup.get(TRANSFER_TO_OTHER_FUND.BENEFICIARIES.formName).setValue(
      this.getClaimBeneficiaries(result),
      { emitEvent: false }
    );

    this.detectChanges();
  }

  public isFormValid(form): boolean {
    if (!!form) {
      return form.disabled ? true : form.valid;
    }
    return true;
  }

  public updateProductFactors(factors: RequestFactor[]) {
    this.requestProductFactor = factors;
    this.$subscriptions.push(this.onReload(TRANSFER_TO_OTHER_FUND.POLICY_FACTORS.formName).subscribe());
  }

  public updateAssetFactors(factors: RequestFactor[]) {
    this.requestAssetFactor = factors;
    this.$subscriptions.push(this.onReload(TRANSFER_TO_OTHER_FUND.ASSET_FACTORS.formName).subscribe());
  }

  getClaimBeneficiaries(result: any) {
    if (result.data.operationData.data.beneficiaries) {
      return (result.data.operationData.data.beneficiaries as any[]).map(beneficiary => {
        return LpcBeneficiaryUtils.toClaimBeneficiary(beneficiary);
      });
    }
    return [];
  }

  public createDraftHandleResponse(result) {
    this.beneficiaryData = result.data.operationData.data as Beneficiary[];
    this.beneficiariesDefinitions = (result.definitions.beneficiaries as BeneficiaryDefinition[]).reverse();
    this.beneficiaryRoles = result.definitions.BenefiacyRoles  as BeneficiaryRole[];
  }
  protected getTransformedOperationData(): any {
    return {
      listGoodFactor: this.requestAssetFactor.length > 0 ? this.requestAssetFactor : [],
      listProductFactor: this.requestProductFactor.length > 0 ? this.requestProductFactor : [],
      dataTransfer: this.formGroupValue.dataTransfer.definitionsFields.length > 0 ?
      LpcFormFieldHandlerUtil.extractFormFieldData(this.formGroupValue.dataTransfer.definitionsFields) : [],
      insuredSettlements: null,
      beneficiaries: this.formGroup.getRawValue().beneficiaries
      .map(claimBeneficiary => {
        return LpcBeneficiaryUtils.convertToBeneficiaryRequest(claimBeneficiary);
      })
    };
  }

  public confirmButtonVisibility() {
    return !this.hasBlockingErrorsOnSteps(TRANSFER_TO_OTHER_FUND.QUOTATION.id)
    && !!this.insuredSettlements && !!this.insuredSettlements.length;
  }

  onTriggerQuestPreval($event: any) {
    if (!!$event) {
      this.anagSubj = $event;
      this.$subscriptions.push(
        this.plcQuestService.prevalQuest(this.operationDataKey, '', this).subscribe(result => {
          this.questFactorsArray = result;
          this.disabledQuestionArray = this.plcQuestService.disableQuest(this.operationDataKey, '', this);
        })
      );
    }
  }
}

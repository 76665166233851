import {ReportElement} from './report-element';

export class ReportDetail {

  constructor(id: number,
              description: string,
              areaId: number,
              idQlikApp: string,
              idQlikStream: string,
              enableSelections: number,
              enableInteractions: number,
              elements: ReportElement[],
              qlikTheme: string,
              maxRows: number,
              maxCols: number) {
    this.id = id;
    this.description = description;
    this.areaId = areaId;
    this.idQlikApp = idQlikApp;
    this.idQlikStream = idQlikStream;
    this.enableSelections = enableSelections;
    this.enableInteractions = enableInteractions;
    this.elements = elements;
    this.qlikTheme = qlikTheme;
    this.maxRows = maxRows;
    this.maxCols = maxCols;
  }

  id: number;
  code: string;
  description: string;
  areaId: number;
  idQlikApp: string;
  idQlikStream: string;
  enableSelections: number;
  enableInteractions: number;
  elements: ReportElement[];
  qlikTheme: string;
  maxRows: number;
  maxCols: number;
}

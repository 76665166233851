import {Component, ComponentRef, OnInit} from '@angular/core';
import {Modal} from '../../modal';
import {QuotationService} from '../quotation.service';
import {ApiLPSTaxDetail} from '../../models/api-models/api-lps-tax-detail';

@Component({
  selector: 'mic-lps-taxes-modal',
  templateUrl: './lps-taxes-modal.component.html',
  styleUrls: ['./lps-taxes-modal.component.scss']
})
export class LpsTaxesModalComponent implements Modal, OnInit {

  componentRef: ComponentRef<any>;

  lpsTaxDetailInstallment: ApiLPSTaxDetail;
  lpsTaxDetailSubscriptionInstallment: ApiLPSTaxDetail;

  constructor(
    protected quotationService: QuotationService
  ) {
  }

  ngOnInit() {
    this.lpsTaxDetailInstallment = this.quotationService.getPremium().productPremium.lpsTaxDetailInstallment;
    this.lpsTaxDetailSubscriptionInstallment = this.quotationService.getPremium().productPremium.lpsTaxDetailSubscriptionInstallment;
  }

  close() {
    this.componentRef.destroy();
  }

}

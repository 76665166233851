export class Event {
    public id: string;
    public description: string;
    public user: any;
    public date: Date;
    public competentUser: any;
    public state: any;
    public note: string;
    public level: any;

    constructor(id: string, description: string, date: Date, state: any, level: any) {
        this.id = id;
        this.description = description;
        this.date = date;
        this.state = state;
        this.level = level;
     }
}

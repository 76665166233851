import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RgiRxTokenProvider, TokenProviderService} from './token-provider.service';
import {AuthService, RgiRxAuthService} from './rgi-rx-auth.service';
import {AuthHttpService} from './auth-http.service';
import {UserModule} from './user/user.module';
import {RGI_RX_AUTH_CONFIG, RgiRxAuthConfig} from './auth-api';
import {RGI_RX_HTTP_CLIENT_INTERCEPTOR} from '@rgi/rx/http';
import {TokenAuthorizationInterceptorService} from './token-authorization-interceptor.service';
import {rgiRxAuthProvideAuthConfig} from './standalone/rgi-rx-auth.providers';


@NgModule({
  declarations: [],
  imports: [
    UserModule,
    CommonModule
  ],
  exports: [UserModule],
  providers: [
    {
      provide: TokenProviderService,
      useExisting: RgiRxTokenProvider
    },
    RgiRxAuthService,
    {
      provide: AuthService,
      useExisting: RgiRxAuthService
    },
    AuthHttpService,
    {
      provide: RGI_RX_HTTP_CLIENT_INTERCEPTOR,
      useClass: TokenAuthorizationInterceptorService,
      multi: true
    }
  ]
})
export class RgiRxAuthModule {
  static forRoot(config: RgiRxAuthConfig): ModuleWithProviders<RgiRxAuthModule> {
    return {
      ngModule: RgiRxAuthModule,
      providers: [
        rgiRxAuthProvideAuthConfig(config)
      ]
    };
  }
}

import {Directive, EventEmitter, Input, Output, TemplateRef} from '@angular/core';

export interface RgiRxDragAction {
  event: Event;
  data: any;
}

@Directive({
  selector: '[rgiRxDragAction], rgi-rx-drag-action',
})
export class RgiRxDragActionDirective {
  private _class;
  private _description: string | TemplateRef<any>;
  @Output() onAction = new EventEmitter<RgiRxDragAction>();
  @Input() get class() {
    return this._class;
  }
  set class(value) {
    this._class = value;
  }

  @Input() get description() {
    return this._description;
  }

  set description(value) {
    this._description = value;
  }

  emitAction(event: Event, data: any) {
    this.onAction.next({
      event, data
    });
  }

}

<div class="container-fluid table-white">
    <div class="row">
        <div class="col-md-12  py-4">
            <claims-elaboration-request-search></claims-elaboration-request-search>
        </div>
    </div>
</div>

<br>
<h3>{{ '_CLAIMS_._RESULTS' | translate }}</h3>
<br>

<div>
    <div *ngIf="hasResult()" class="table-white">
        <div class="head-result thDataTable row col-md-12">
            <!-- <div class="col-md-1" >
            <span class="bold"></span>
        </div> -->

            <div align="left" class="col-md-1">
                <span class="bold">{{ '_CLAIMS_._ID' | translate }}</span>
            </div>
            <div align="left" class="col-md-1">
                <span class="bold">{{ '_CLAIMS_._CODE' | translate }}</span>
            </div>
            <div align="left" class="col-md-2">
                <span class="bold">{{'_CLAIMS_._TITLE' | translate}}</span>
            </div>
            <div align="left" class="col-md-2">
                <span class="bold">{{'_CLAIMS_._OUTCOME' | translate}}</span>
            </div>
            <div align="left" class="col-md-1">
                <span class="bold">{{'_CLAIMS_._RERUN' | translate}}</span>
            </div>
            <div align="left" class="col-md-1">
                <span class="bold">{{'_CLAIMS_._INSERTION' | translate}}</span>
            </div>
            <div align="left" class="col-md-1">
                <span class="bold">{{'_CLAIMS_._REQUEST' | translate}}</span>
            </div>
            <div align="left" class="col-md-1">
                <span class="bold">{{'_CLAIMS_._ELABORATION' | translate}}</span>
            </div>
            <div align="left" class="col-md-1">
                <span class="bold">{{'_CLAIMS_._CANCELLATION' | translate}}</span>
            </div>
            <div align="left" class="col-md-1">
                <span class="bold"></span>
            </div>
        </div>
    </div>


    <span
        *ngFor="let elaborationRequest of elaborationRequestsList; let even = even; let odd = odd; let elaborationRequestIndex = index">
        <div class="container-fluid table-white col-md-12">
            <div [ngClass]="{ 'row-result-odd': odd, 'row-result-even': even }" class="row">
                <div class="col-md-1">
                    <span>{{elaborationRequest.elabId}}</span>
                </div>
                <div class="col-md-1">
                    <span>{{elaborationRequest.actionCode}}</span>
                </div>
                <div class="col-md-2">
                    <span>{{elaborationRequest.actionTitle}}</span>
                </div>
                <div class="col-md-2 ">
                    <span>{{elaborationRequest.queueOutcomeState?.codice}}-{{elaborationRequest.queueOutcomeState?.descrizione}}</span>
                </div>
                <div class="col-md-1">
                    <span>{{(elaborationRequest.queueRerunRequest)?('_CLAIMS_._YES' | translate):('_CLAIMS_._NO' | translate)}}</span>
                </div>
                <div class="col-md-1">
                    <span>{{elaborationRequest.queueTIns | date:'dd/MM/yyyy' }}</span>
                </div>
                <div class="col-md-1">
                    <span>{{elaborationRequest.queueTElaborationRequestDate | date:'dd/MM/yyyy' }}</span>
                </div>
                <div class="col-md-1">
                    <span>{{elaborationRequest.queueTElaborationDate | date:'dd/MM/yyyy' }}</span>
                </div>
                <div class="col-md-1">
                    <span>{{elaborationRequest.queueTCancellationDate | date:'dd/MM/yyyy' }}</span>
                </div>
                <div class="col-md-1">
                    <div class="btn-group btn-group-justified " style="margin-top: 1px;">
                        <div class="btn-group">
                            <button rgi-rx-button variant="icon" type="button" color="info" (click)="modal.open()"
                                attr.data-qa="elaboration-request-list-description-btn"><span
                                    class="rgi-ui-icon-info"></span></button>

                            <rgi-rx-modal #modal>
                                <rgi-rx-panel>
                                    <rgi-rx-panel-header [closeable]="true"
                                        (onClose)="modal.close()"></rgi-rx-panel-header>
                                    <rgi-rx-panel-footer>
                                        <button class="rgi-ui-btn rgi-ui-btn-primary"
                                            (click)="modal.close()">{{'_CLAIMS_._BUTTONS_._CLOSE' | translate}}</button>
                                    </rgi-rx-panel-footer>
                                    <div>
                                        {{elaborationRequest.actionDescr}}
                                    </div>
                                </rgi-rx-panel>
                            </rgi-rx-modal>
                        </div>
                        <div class="btn-group" style="padding-right: 20px;">
                            <button rgi-rx-button variant="icon" type="submit" color="info"
                                (click)="elaborationrequestDetail(elaborationRequest.elabId)"
                                attr.data-qa="elaboration-request-list-detail-btn"
                                id="elaboration-request-list-submit"><span
                                    class="glyphicon glyphicon-chevron-right"></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </span>


    <span *ngIf="errorMessage" class="col-md-12 content-error validate-error"
        attr.data-qa="elaboration-request-search-error">
        <span class="rgifont rgi-exclamation-triangle"></span>
        <span>{{errorMessage | translate}}</span>
    </span>

    <div class="btn-group btn-group-justified btn-group-justified-claim">

        <div class="btn-group">
            <button (click)="back()" class="btn btn-warning pull-right text-uppercase" >{{'_CLAIMS_._BUTTONS_._BACK' | translate}}</button>
        </div>

    </div>
</div>
import { Factor } from '../models/policy.model';

export class LicPipUtils {

    public static readonly VFPIP = '_VFPIP';

    public static isPipFactor(factor: Factor) {
        return this.getFactorPropertiesValue(factor, LicPipUtils.VFPIP) === 'true';
      }

    public static getFactorPropertiesValue(factor: Factor, propCode: string) {
        const prop = factor.customProperties.filter((p) => p.code === propCode);
        if (prop && prop.length > 0) {
          return prop[0].value;
        }
      }
}

import {AnagApiResponse} from './anag-api-response';
import {AnagApiParty} from '../anag-domain/anag-api-party';


export class AnagApiCreateSubject extends AnagApiResponse {
  public subject: AnagApiParty;

  constructor(party: AnagApiParty) {
    super();
    this.subject = party;
  }
}

export class AnagApiSaveSubjectResponse extends AnagApiCreateSubject {
  idSubject: string;
  outcome: Array<any>;
}

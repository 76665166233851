<div>
    <form [formGroup]="searchElaborationRequestForm" data-qa="elaboration-request-search-form">

        <div class="col-sm-12 container-elaboration-request-search">

            <div class="col-sm-12">
                <label class="label-core" attr.data-qa="elaboration-request-search-code-chain-label">{{ '_CLAIMS_._CODE_CHAIN'
                    | translate }}</label>
            </div>
            <input class="col-sm-12 form-control" formControlName="codeChain" id="codeChain" type="text"
                name="codeChain" attr.data-qa="elaboration-request-search-codeChain-input">

            <div class="col-sm-12">
                <label class="label-core" attr.data-qa="elaboration-request-search-external-key-label">{{ '_CLAIMS_._EXTERNAL_KEY'
                 | translate   }}</label>
            </div>
            <input class="col-sm-12 form-control" formControlName="externalKey" id="externalKey" type="text"
                name="externalKey" attr.data-qa="elaboration-request-search-external-key-input">

            <div class="col-sm-12">
                <label class="label-core" attr.data-qa="elaboration-request-search-code-action-label">{{ '_CLAIMS_._CODE_ACTION'
                    | translate }}</label>
                     <select class="core-select form-control" formControlName="codeAction" id="codeAction" name="codeAction"
                     attr.data-qa="codeAction-select">
                     <option></option>
                     <option *ngFor="let action of actionsList" [value]="action.actionCode">
                        {{action.actionCode}} - {{action.actionTitle}}</option>
                 </select>
                 <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>
            <!-- <input class="col-sm-12 form-control" formControlName="codeAction" id="codeAction" type="text"
                name="codeAction" attr.data-qa="elaboration-request-search-code-action-input"> -->

            <div class="col-sm-6">
                <pnc-datepicker label="{{ ('_CLAIMS_._DATA_ENTRY' | translate) + ' ' + ('_CLAIMS_._FROM' | translate) }}" customFormControlName="creationDateStart"
                    [customFormGroup]="searchElaborationRequestForm" [maxDate]="today"
                    attr.data-qa="elaboration-request-search-creation-date-start-input">
                </pnc-datepicker>
            </div>

            <div class="col-sm-6">
                <pnc-datepicker label="{{ '_CLAIMS_._TO' | translate }}" customFormControlName="creationDateEnd"
                    [customFormGroup]="searchElaborationRequestForm" [maxDate]="today"
                    attr.data-qa="elaboration-request-search-creation-date-end-input">
                </pnc-datepicker>
            </div>

            <div class="col-sm-12">
                <label class="label-core" ng-bind="label">{{ '_CLAIMS_._OUTCOME' | translate }}</label>
                <select class="core-select form-control" formControlName="outcome" id="outcome" name="outcome"
                    attr.data-qa="outcome-select">
                    <option></option>
                    <option *ngFor="let outcome of outcomeList; let i = index " [value]="i">
                        {{outcome.eTypeCode}} - {{outcome.eTypeDescription}}</option>
                </select>
                <span class="pull-right arrow-select-native rgifont pnc-select pnc-select-chevron-down"></span>
            </div>



        </div>
    </form>

    <span *ngIf="errorMessage" class="col-sm-12 content-error validate-error"
        attr.data-qa="elaboration-request-search-error">
        <span class="rgifont rgi-exclamation-triangle"></span>
        <span>{{errorMessage | translate}}</span>
    </span>

    <div class="btn-group btn-group-justified">
        <div class="btn-group">
            <button type="button" class="btn btn-warning pull-right" (click)="emptyFields()"
                attr.data-qa="elaboration-request-search-empty-btn">{{ '_CLAIMS_._BUTTONS_._EMPTY' | translate }}</button>
        </div>
        <div class="btn-group">
            <button type="submit" class="btn btn-warning pull-right" attr.data-qa="elaboration-request-search-find-btn"
                id="elaboration-request-search-submit" (click)="goToSearchResults()">{{ '_CLAIMS_._BUTTONS_._FIND' | translate }}</button>
        </div>
        <div class="btn-group">
            <button type="button" class="btn btn-warning pull-right" (click)="openNewModel()"
                attr.data-qa="elaboration-request-search-find-btn">{{ '_CLAIMS_._BUTTONS_._NEW_REQUEST' | translate }}</button>
        </div>
    </div>
</div>
<template #claimModal></template>
<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
                    <span>{{ clause?.extendedDescription ? clause?.extendedDescription : clause?.description }}
                    </span>
          <span (click)="cancel()" class="glyphicon glyphicon-remove pull-right cursorPointer"></span>
        </h3>
      </div>
      <div class="modal-body">
        <div [formGroup]="clauseTextForm">
          <div *ngIf="clause?.textFormat === 1 && clause?.textType === '0'">
            <p>{{ clauseTextForm.getRawValue().text }}</p>
          </div>
          <div *ngIf="clause?.textFormat === 1 && clause?.textType === '2'">
            <textarea [cols]="clause?.numCols" [rows]="clause?.numRows"
                      formControlName="text">{{ clauseTextForm.getRawValue().text }}</textarea>
          </div>
          <div *ngIf="clause?.textFormat === 2 && clause?.textType === '0'">
            <p [innerHTML]="clauseTextForm.getRawValue().text" ></p>
          </div>
          <div *ngIf="clause?.textFormat === 2 && clause?.textType === '2'">
            <editor [init]="{plugins: 'link'}" formControlName="text">
            </editor>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div *ngIf="clause?.textFormat === 1 && clause?.textType === '0'" class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="cancel()" class="btn btn-warning" data-qa="btn-clause-modal-close" translate>CLOSE</button>
          </div>
        </div>
        <div *ngIf="!(clause?.textFormat === 1 && clause?.textType === '0')"
             class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="cancel()" class="btn btn-warning" data-qa="btn-clause-modal-cancel" translate>CANCEL
            </button>
          </div>
          <div class="btn-group">
            <button (click)="onSubmit()" class="btn btn-warning" data-qa="btn-clause-modal-confirm" translate>CONFIRM
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

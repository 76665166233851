import {AdditionalLabel} from './additional-label';

export class ProposalDataRequest {
  additionalLabels?: AdditionalLabel[];
  productCode: string;
  companyCode: string;
  managementNodeCode: string;
  issuingDate?: string;
  effectDate?: string;
  from?: From;

  constructor() {
  }
}

export interface From {
  copy: string;
  resume: string;
  load: string;
}

import { Component, Input, OnInit, StaticProvider } from '@angular/core';
import { AnagStatelessService } from '../../../anag-resources/anag-stateless.service';
import { RgiRxTranslationService } from '@rgi/rx/i18n';
import { ModalService } from '@rgi/rx/ui';
import { AnagStateManagerPartyEditor} from '../../../anag-states/party-editor/anag-state-manager-party-editor';
import { DatePipe } from '@angular/common';
import { AnagStateManagerKeyData } from '../../../anag-states/key-data/anag-state-manager-key-data';
import { AnagRating } from '../../../anag-model/anag-domain/anag-rating';
import { AnagDisplayLabel } from '../../../anag-model/anag-domain/anag-display-label';
import { AnagRatingHistoryModalComponent } from '../anag-rating-history-modal/anag-rating-history-modal.component';
import { AnagRatingComponent } from '../anag-rating/anag-rating.component';
import { AnagApiParty } from '../../../anag-model/anag-domain/anag-api-party';
import { RgiRxUserAuthorizationService } from '@rgi/rx/auth';
import { ANAG_FUNCTIONS } from '../../../anag-constants/anag-constants';
import { AnagStorageService } from '../../../anag-resources/anag-storage.service';

@Component({
  selector: 'rgi-anag-rating-section',
  templateUrl: './anag-rating-section.component.html',
  styleUrls: ['./anag-rating-section.component.css']
})
export class AnagRatingSectionComponent implements OnInit {

  @Input()
  inputParty: AnagApiParty;

  @Input()
  private stateMgrProvider: StaticProvider[];

  label: string;
  partyRating: AnagRating;

  canEditRating = false;

  constructor(
    protected modalService: ModalService,
    protected translateService: RgiRxTranslationService,
    public datePipe: DatePipe,
    public statelessService: AnagStatelessService,
    public stateMgr: AnagStateManagerPartyEditor,
    public stateMgrKD: AnagStateManagerKeyData,
    public authorizationService: RgiRxUserAuthorizationService,
    public anagStorage: AnagStorageService
    ) {}

  ngOnInit() {
    this.canEditRating = this.stateMgr.getCurrentState().editorModeEnable && this.authorizationService.isAuthorizedFor(ANAG_FUNCTIONS.editRating);
    this.translation();
  }

  getLastRating() {
    return this.inputParty.ratings && this.inputParty.ratings.length ? this.inputParty.ratings[this.inputParty.ratings.length-1] :  new AnagRating();
  }

  get ratingLabels(): Array<AnagDisplayLabel> {
    const labels: Array<AnagDisplayLabel> = [];
    let partyRating = this.getLastRating();

    if (this.isFieldVisible('rating')) {
      labels.push(new AnagDisplayLabel('_ANAG_._LABEL_._RATING_', partyRating.rating ? partyRating.rating.descrizione : '-', 'rating'));
    }
    if (this.isFieldVisible('ratingPeriod')) {
      labels.push(new AnagDisplayLabel('_ANAG_._LABEL_._RATING_PERIOD_', partyRating.ratingPeriod ? this.statelessService.formatDateToStringPeriod(partyRating.ratingPeriod) : '-', 'ratingPeriod'));
    }
    if (this.isFieldVisible('user')) {
      labels.push(new AnagDisplayLabel('_ANAG_._LABEL_._RATING_USER_', partyRating.user ? partyRating.user : '-', 'user'));
    }
    if (this.isFieldVisible('origin')) {
      labels.push(new AnagDisplayLabel('_ANAG_._LABEL_._RATING_ORIGIN_', partyRating.origin ? partyRating.origin.descrizione : '-', 'origin'));
    }

    return labels;
  }

  translation() {
  }

  isFieldVisible(fieldName: string): boolean {
    return this.stateMgr.isRatingFieldVisible(fieldName);
  }

  editRating() {
    const ratingModal = this.modalService.openComponent(
      AnagRatingComponent, this.getLastRating(), [
        {
          provide: AnagStateManagerPartyEditor,
          useValue: this.stateMgr
        }
      ]
    );
    ratingModal.modal.enableClickBackground = false;
    ratingModal.modal.onClose.subscribe(editedRating => {
      if (editedRating) {
        this.stateMgr.updateRating(editedRating);
      }
    });
  }

  openModalRatingHistory() {
    /*const mock = new AnagRating();
    mock.rating = new AnagEntityIta('1', 'Gold');
    mock.origin = new AnagEntityIta('1', 'manual');
    mock.ratingPeriod = new Date();
    mock.user = 'ADMIN';
    const ratingHistory = [mock];*/
    const ratingHistory = this.inputParty.ratings;
    this.modalService.openComponent(AnagRatingHistoryModalComponent, ratingHistory, [this.stateMgrProvider]);
  }

}

import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
  OnDestroy,
  StaticProvider,
  ViewContainerRef
} from '@angular/core';
import {ActiveRoute, RgiRxActiveRoute, RoutableComponent, RouteComponentLoader} from './router.api';
import {RgiRxRoutingHostDirective} from './directives/rgi-rx-routing-host.directive';
import {Subject} from 'rxjs';
import {LoggerFactory} from '@rgi/rx';

@Injectable({
  providedIn: 'root'
})
export class DynamicComponentLoader extends RouteComponentLoader {

  private readonly logger = LoggerFactory();
  constructor() {
    super();
  }

  load(activeRoute: ActiveRoute, host: RgiRxRoutingHostDirective, providers: StaticProvider[] = []): ComponentRef<any> {
    try {
      const viewContainerRef: ViewContainerRef = host.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(activeRoute.getComponent(), {
        injector: Injector.create({
          parent: viewContainerRef.injector,
          providers: providers.concat([
            {provide: RgiRxActiveRoute, useValue: activeRoute},
            {provide: ActiveRoute, useExisting: RgiRxActiveRoute},
          ])
        })
      });
      if (componentRef.instance instanceof RoutableComponent) {
        const routableComponent = componentRef.instance as RoutableComponent;
        routableComponent.id = activeRoute.id;
        routableComponent.route = activeRoute.route;
        routableComponent.tickHostChangeCallback = () => {
          host._onTickChange.next();
        };
      }
      return componentRef;
    } catch (e) {
      this.logger.error(`Error loading component for route ${activeRoute.route}`, e);
      throw e;
    }
  }
}

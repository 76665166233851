import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable} from "rxjs";
import { CacheService } from "../../common/service/cache.service";
import { PartyNiceActimizeCheckResponses } from "../model/generated/models";
import { CommonErrorResponse } from "../../common/model/generated/models";
import { PathUtil } from "../../common/util/path-util";
import { MessageUtil } from "../../common/util/message-util";
import { EnviromentsService } from "@rgi/life-issue-card";
import { RgiRxTranslationService } from '@rgi/rx/i18n';

@Injectable()

export class PartiesService {

  pathUtil: PathUtil;
  messageUtil: MessageUtil;

  constructor(
    @Inject('environment') apiConf: any,
    protected environment: EnviromentsService,
    protected translate: RgiRxTranslationService,
    private cacheService: CacheService,
    private httpClient: HttpClient,
  ) {
    this.pathUtil = new PathUtil(apiConf,environment);
    this.messageUtil = new MessageUtil(translate);
  }

  public handleCommonOperationResponse(response :any, throwError = true) {

    if (!response || (response as any).error) {
      this.messageUtil.handleCommonErrorResponse(response, throwError);
    } else {
      console.log(response);
    }
  }

  public getMessageUtil() : MessageUtil {
    return this.messageUtil;
  }
  public getPartyNiceActimizeCheck(idAuth: string, useCache = false): Observable<PartyNiceActimizeCheckResponses | CommonErrorResponse> {

    if (useCache) {
      return this.cacheService.getResource(this.getPath(idAuth),this.getCacheContext());
    }

    return this.httpClient.get<PartyNiceActimizeCheckResponses | CommonErrorResponse>(this.getPath(idAuth));
  }

  getPath(idAuth: string): string {
    return this.pathUtil.getPathNiceActimizeCheck(idAuth);
  }

  private getCacheContext() {
    return 'PartyService';
  }
}

import { SurveyVersionI } from "../../models/survey-version";
import { EventInstance, EventType } from "@rgi/rx";
import { QuestionnaireI } from "../../models/questionnaire";
import { QuestionnaireFlatI } from "@rgi/ng-passpro";
import { EvaluatorProductI } from "../../evaluation/models";


export interface SurveyBackEvent extends EventType {
    routeId: string
}

export const SURVEY_BACK: EventType = { eventName: "PPPRO_SurveyBack" };
export class SurveyBackEventInstance implements EventInstance<SurveyBackEvent> {
    event: SurveyBackEvent;
    constructor(routeId: string) {
        this.event = {
            routeId,
            eventName: SURVEY_BACK.eventName
        }
    }
}


export interface SurveyEvaluationBack extends EventType {
    routeId: string;
    askConfirm: boolean;

}

export const SURVEY_EVALUATION_BACK: EventType = { eventName: 'PPPRO_SurveyEvaluationBack' };

export class SurveyEvaluationBackInstance implements EventInstance<SurveyEvaluationBack>{
    event: SurveyEvaluationBack;

    constructor(routeId: string, askConfirm: boolean) {
        this.event = {
            routeId,
            askConfirm,
            eventName: SURVEY_EVALUATION_BACK.eventName
        }
    }
}


export interface SurveySaveEvent extends EventType {
    surveyVersion: SurveyVersionI;
    subject: any;
    idSalePoint: number;
    questCode: string;
    userCode: string;
    readonly eventName: string;
    routeId: string,
    routeOptions: any
}
export const SURVEY_SAVED: EventType = { eventName: "PPPRO_SurveySaved" };

export class SurveySaveEventInstance implements EventInstance<SurveySaveEvent>{
    event: SurveySaveEvent;
    constructor(routeId: string, routeOptions, surveyVersion: SurveyVersionI, subject: any, questionnaireCode: string, salePointId: number, userCode: string) {
        this.event = {
            routeId,
            routeOptions,
            subject: subject,
            surveyVersion: surveyVersion,
            idSalePoint: salePointId,
            questCode: questionnaireCode,
            userCode: userCode,
            eventName: SURVEY_SAVED.eventName
        }
    }
}


export interface SurveySaveErrorEvent extends EventType {
    surveyVersion: SurveyVersionI;
    subject: any;
    idSalePoint: number;
    questCode: string;
    userCode: string;
    readonly eventName: string;
    error: string[] | string;
}
export const SURVEY_SAVE_ERROR: EventType = { eventName: "PPPRO_SurveySaveError" };
export class SurveySaveErrorEventInstance implements EventInstance<SurveySaveErrorEvent> {
    event: SurveySaveErrorEvent;
    constructor(surveyVersion: SurveyVersionI, subject: any, questionnaireCode: string, salePointId: number, userCode: string, error: string[] | string) {
        this.event = {
            subject: subject,
            surveyVersion: surveyVersion,
            idSalePoint: salePointId,
            questCode: questionnaireCode,
            userCode: userCode,
            eventName: SURVEY_SAVE_ERROR.eventName,
            error
        }
    }
}


export interface SurveyChangeQuestionnaireSelection extends EventType {
    questionnaire: QuestionnaireI;
    questionnaireFlat: QuestionnaireFlatI
}
export const SURVEY_CHANGE_QUESTIONNAIRE_SELECTION: EventType = { eventName: "PPPRO_ChangeQuestSelection" };

export class SurveyChangeQuestionnaireSelectionInstance implements EventInstance<SurveyChangeQuestionnaireSelection>{
    event: SurveyChangeQuestionnaireSelection;


    constructor(questionnaire: QuestionnaireI, questionnaireFlat: QuestionnaireFlatI) {
        this.event = {
            eventName: SURVEY_CHANGE_QUESTIONNAIRE_SELECTION.eventName,
            questionnaire: questionnaire,
            questionnaireFlat: questionnaireFlat
        }
    }
}

export interface SurveySelectProductEvaluated extends EventType {
    products: EvaluatorProductI[];
    surveyVersion: SurveyVersionI;
    id: string;
    subject: any;
    forwardData?: any;
}
export const SURVEY_SELECT_PRODUCT_EVALUATED: EventType = { eventName: "PPPRO_SurveySelectProductEvaluated" };

export class SurveySelectProductEvaluatedInstance implements EventInstance<SurveySelectProductEvaluated>{
    event: SurveySelectProductEvaluated;


    constructor(id: string, products: EvaluatorProductI[], surveyVersion: SurveyVersionI, subject: any, forwardData?: any) {
        this.event = {
            eventName: SURVEY_SELECT_PRODUCT_EVALUATED.eventName,
            id: id,
            products: products,
            surveyVersion: surveyVersion,
            subject: subject,
            forwardData
        }
    }
}


export interface SurveyEvaluationErrorEvent extends EventType {
    surveyVersion: SurveyVersionI;
    routeId: string;
    subject: any;
    ephemeral: boolean;
    error: string[] | string;
}
export const SURVEY_EVALUATION_ERROR: EventType = { eventName: "PPPRO_SurveyEvaluationError" };
export class SurveyEvaluationErrorEventInstance implements EventInstance<SurveyEvaluationErrorEvent>{
    event: SurveyEvaluationErrorEvent
    constructor(surveyVersion: SurveyVersionI, routeId: string, subject: any, ephemeral: boolean, error: string[] | string) {
        this.event = {
            eventName: SURVEY_EVALUATION_ERROR.eventName,
            surveyVersion,
            routeId,
            subject,
            ephemeral,
            error
        }
    }
}

import { Component, OnInit, Input, EventEmitter, Output, Inject, OnDestroy } from '@angular/core';
import { ApiEnumType, CardComponentService, Outcome } from '@rgi/digital-claims-common-angular';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoutableComponent } from '@rgi/portal-ng-core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { UtilityService } from '../../external-portfolio/utility.service';
import { AutosoftInfoEntity } from '../dto/autosoft-info-entity';
import { RgiRxTranslationService } from '@rgi/rx/i18n';


@Component({
  selector: 'claims-autosoft-calls',
  templateUrl: './autosoft-calls.component.html',
  styleUrls: ['./autosoft-calls.component.scss']
})
export class AutosoftCallsComponent implements OnInit, OnDestroy, RoutableComponent {
  @Input() card: any;
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();
  public listAutosoftInfo: AutosoftInfoEntity[] = new Array();
  public visDetail = false;
  public detail: string[];
  public detailTitle = '';
  public searchForm: UntypedFormGroup;
  public errorMessage: string = '';
  public errore: boolean;
  public today: NgbDate;
  public criteriaOn: boolean;
  public class: string = 'alert alert-danger';
  public rows: any[];
  public classMsg: string = 'alert alert-danger';
  public selectedEvent = '';


  constructor(
    cardComponentService: CardComponentService,
    @Inject('sessionService') private session: any,
    @Inject('coreResult') private coreResult,
    @Inject('authService') private authService,
    @Inject('eventService') private eventService: any,
    @Inject('enumService') private enumService: any,
    public formBuilder: UntypedFormBuilder,
    private utilytyService: UtilityService,
    private modalService: NgbModal,
    public translateService: RgiRxTranslationService) {
    const today = new Date();
    this.today = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
  }

  ngOnDestroy(): void {

  }

  ngOnInit() {
    this.classMsg = 'alert alert-danger';
    this.criteriaOn = true;
    this.rows = new Array<any>();
    this.listAutosoftInfo = this.utilytyService.getAutosoftResult();
    this.searchForm = this.utilytyService.getSerachForm();
    this.utilytyService.setAutosoftResult(null, null);

    this.listAutosoftInfo.forEach(aEl => {
      const aRow = {
        info: aEl,
        class: ''
      };
      this.rows.push(aRow);
      this.selectedEvent = aEl.eventPossible;
    });

  }

  ctrCriteria() {
    this.criteriaOn = !this.criteriaOn;
  }

  limitXml(str: string) {
    if (str && str.length > 300) {
      return str.substr(0, 300) + '...';
    }
    return str;
  }

  detailResponseXml(record: any) {
    // alert('RESPONSE\n'+record.payLoadResponse.split('><').join('>\n<'));
    this.rows.forEach(element => {
      element.class = '';
    });
    this.visDetail = true;
    this.detailTitle = 'RESPONSE: ' + record.info.idResponse;
    this.detail = record.info.payLoadResponse.split('><').join('>*<').split('*');
    record.class = 'alert alert-danger';
  }

  detailRequestXml(record: any) {
    // alert(record.payLoadRequest);
    this.rows.forEach(element => {
      element.class = '';
    });
    this.visDetail = true;
    this.detailTitle = 'REQUEST: ' + record.info.idRequest;
    this.detail = record.info.payLoadRequest.split('><').join('>*<').split('*');
    record.class = 'alert alert-danger';
  }

  send(aEl: AutosoftInfoEntity) {
    this.classMsg = 'alert alert-danger';
    this.errore = false;
    this.errorMessage = '';
    this.visDetail = false;
    this.detail = null;
    this.detailTitle = '';

    this.eventService.broadcastEvent('start-loader');
    this.utilytyService.sendAutosoft(
      aEl.claimNumber,
      aEl.numberIncarico
    ).subscribe(
      (res: any) => {
        console.log('Send Autosoft --> ', res);
        // console.log(res);
        this.eventService.broadcastEvent('stop-loader');
        this.errore = true;
        this.errorMessage = res.code + ' ' + res.descr;
        if (res.code === 'OK') {
          this.classMsg = 'alert alert-success';
        }
      },
      (error) => {
        this.eventService.broadcastEvent('stop-loader');
        this.errore = true;
        this.translateService.translate('_CLAIMS_._MESSAGE_._ERROR').subscribe(res => {
          this.errorMessage = res;
        }
        )
      }
    );
  }

  retreiveEvent(aEl: AutosoftInfoEntity) {
    this.classMsg = 'alert alert-danger';
    this.errore = false;
    this.errorMessage = '';
    this.visDetail = false;
    this.detail = null;
    this.detailTitle = '';

    this.eventService.broadcastEvent('start-loader');
    this.utilytyService.retreiveEvent(
      aEl.claimNumber,
      aEl.cidExpertManager
    ).subscribe(
      (res: any) => {
        console.log('Retreive event Autosoft --> ', res);
        // console.log(res);
        this.eventService.broadcastEvent('stop-loader');
        this.errore = true;
        this.errorMessage = res.code + ' ' + res.descr;
        if (res.code === 'OK') {
          this.classMsg = 'alert alert-success';
        }
      },
      (error) => {
        this.eventService.broadcastEvent('stop-loader');
        this.errore = true;
        this.translateService.translate('_CLAIMS_._MESSAGE_._ERROR').subscribe(res=> {
          this.errorMessage = res;
        }
        )
      }
    );
  }

  searchEvents() {
    this.selectedEvent = this.searchForm.controls.event.value;
    this.classMsg = 'alert alert-danger';
    this.errore = false;
    this.errorMessage = '';
    this.visDetail = false;
    this.detail = null;
    this.detailTitle = '';
    this.searchForm.controls.typeSearch.setValue('3');
    this.rows = new Array<any>();

    if (!this.searchForm.controls.event.value) {
      this.errore = true;
      this.translateService.translate('_CLAIMS_._MESSAGE_._SPECIFY_EVENT_ASSIGNMENT_NUMBER_DATE_FROM_TO').subscribe(res=> {
        this.errorMessage = res;
      }
      )
    } else {
      this.eventService.broadcastEvent('start-loader');
      this.utilytyService.getAutosoftEvents(
        this.searchForm.controls.event.value,
        this.searchForm.controls.assignmentNumber.value,
        JSON.stringify(this.searchForm.controls.dateFrom.value),
        JSON.stringify(this.searchForm.controls.dateTo.value)
      ).subscribe(
        (res: AutosoftInfoEntity[]) => {
          console.log('getAutosoftEvents --> ', res);
          // console.log(res);
          this.eventService.broadcastEvent('stop-loader');
          if (res.length > 0) {
            this.listAutosoftInfo = res;
            this.listAutosoftInfo.forEach(aEl => {
              const aRow = {
                info: aEl,
                class: ''
              };
              this.rows.push(aRow);
            });
          } else {
            this.errore = true;
            this.translateService.translate('_CLAIMS_._MESSAGE_._NO_RESULT').subscribe(res => {
              this.errorMessage = res;
            }
            )
          }
        },
        (error) => {
          this.eventService.broadcastEvent('stop-loader');
          this.errore = true;
          this.translateService.translate('_CLAIMS_._MESSAGE_._ERROR').subscribe(res => {
            this.errorMessage = res;
          }
          )
        }
      );
    }
  }

  isInvoiceEvent() {
    if (this.selectedEvent === 'R-FAT') {
      return true;
    }
    return false;
  }

  searchTransmission() {
    this.selectedEvent = '';
    this.classMsg = 'alert alert-danger';
    this.listAutosoftInfo = null;
    this.searchForm.controls.typeSearch.setValue('1');
    this.errore = false;
    this.errorMessage = '';
    this.visDetail = false;
    this.detail = null;
    this.detailTitle = '';
    this.rows = new Array<any>();

    if (!this.searchForm.controls.claimNumber.value &&
      !this.searchForm.controls.assignmentNumber.value &&
      !this.searchForm.controls.dateFrom.value &&
      !this.searchForm.controls.dateTo.value) {
      this.errore = true;
      this.translateService.translate('_CLAIMS_._MESSAGE_._SPECIFY_EVENT_ASSIGNMENT_NUMBER_DATE_FROM_TO').subscribe(res=> {
        this.errorMessage = res;
      }
      )
    } else {
      this.eventService.broadcastEvent('start-loader');
      this.utilytyService.getAutosoftTransmission(
        this.searchForm.controls.claimNumber.value,
        this.searchForm.controls.assignmentNumber.value,
        JSON.stringify(this.searchForm.controls.dateFrom.value),
        JSON.stringify(this.searchForm.controls.dateTo.value),
        this.searchForm.controls.inError.value
      ).subscribe(
        (res: AutosoftInfoEntity[]) => {
          console.log('getAutosoftTransmission --> ', res);
          // console.log(res);
          this.eventService.broadcastEvent('stop-loader');
          if (res.length > 0) {
            this.listAutosoftInfo = res;
            this.listAutosoftInfo.forEach(aEl => {
              const aRow = {
                info: aEl,
                class: ''
              };
              this.rows.push(aRow);
            });
          } else {
            this.errore = true;
            this.translateService.translate('_CLAIMS_._MESSAGE_._NO_RESULT').subscribe(res=> {
              this.errorMessage = res;
            }
            )
          }
        },
        (error) => {
          this.eventService.broadcastEvent('stop-loader');
          this.errore = true;
          this.translateService.translate('_CLAIMS_._MESSAGE_._ERROR').subscribe(res=> {
            this.errorMessage = res;
          }
          )
        }
      );
    }
  }

  clearFilter() {
    this.selectedEvent = '';
    this.classMsg = 'alert alert-danger';
    this.searchForm.controls.typeSearch.setValue('0');
    this.listAutosoftInfo = null;
    this.errore = false;
    this.errorMessage = '';
    this.visDetail = false;
    this.detail = null;
    this.detailTitle = '';
    this.searchForm = this.formBuilder.group({
      claimNumber: [],
      assignmentNumber: [],
      event: [],
      dateFrom: [],
      dateTo: [],
      inError: [],
      typeSearch: []
    });
  }

  searchNoTransmission() {
    this.selectedEvent = '';
    this.classMsg = 'alert alert-danger';
    this.listAutosoftInfo = null;
    this.searchForm.controls.typeSearch.setValue('2');
    this.errore = false;
    this.errorMessage = '';
    this.visDetail = false;
    this.detail = null;
    this.detailTitle = '';
    this.rows = new Array<any>();
    if (!this.searchForm.controls.claimNumber.value) {
      this.errore = true;
      this.translateService.translate('_CLAIMS_._MESSAGE_._SPECIFY_ONLY_CLAIM_NUMBER').subscribe(res=> {
        this.errorMessage = res;
      }
      )
    } else {
      this.eventService.broadcastEvent('start-loader');
      this.utilytyService.getAutosoftNoTransmission(
        this.searchForm.controls.claimNumber.value,
        this.searchForm.controls.assignmentNumber.value,
        JSON.stringify(this.searchForm.controls.dateFrom.value),
        JSON.stringify(this.searchForm.controls.dateTo.value)
      ).subscribe(
        (res: AutosoftInfoEntity[]) => {
          console.log('getAutosoftNoTransmission --> ', res);
          // console.log(res);
          this.eventService.broadcastEvent('stop-loader');
          if (res.length > 0) {
            this.listAutosoftInfo = res;
            this.listAutosoftInfo.forEach(aEl => {
              const aRow = {
                info: aEl,
                class: ''
              };
              this.rows.push(aRow);
            });
          } else {
            this.errore = true;
            this.translateService.translate('_CLAIMS_._MESSAGE_._NO_RESULT').subscribe(res => {
              this.errorMessage = res;
            }
            )
          }
        },
        (error) => {
          this.eventService.broadcastEvent('stop-loader');
          this.errore = true;
          this.translateService.translate('_CLAIMS_._MESSAGE_._ERROR').subscribe(res => {
            this.errorMessage = res;
          }
          )
        }
      );
    }
  }
}

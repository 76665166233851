// @ts-ignore
declare var angular: angular.IAngularStatic;

export function AjsPassDamageCardConstantsExt() {

  const constantsModuleExt = angular.module('ptfdamage-nmf-integration-constant', []);

  constantsModuleExt.value('CONTRACT_ORIGIN', {
    PORTALSTANDARDFLOW: 'PortalStandardFlow',
    PORTALNEWMOTORFLOW: 'NewMotorFlow'
  });

}

import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {RoutingHostDirective, RoutingService} from '@rgi/portal-ng-core';
import {CardComponent, CardComponentService, SessionService} from '@rgi/digital-claims-common-angular';
import {LetterTemplatesListComponent} from './letter-templates-list/letter-templates-list.component';
import {LetterTemplateMainClass, LetterTemplatesService} from '../letter-templates.service';

@Component({
  selector: 'claims-letter-templates-single-page-card',
  templateUrl: './letter-templates-single-page-card.component.html',
  styleUrls: ['./letter-templates-single-page-card.component.scss'],
  providers: [RoutingService]
})
export class LetterTemplatesSinglePageCardComponent extends CardComponent implements AfterViewInit {
  @ViewChild(RoutingHostDirective, {static: true}) hostViewRef: RoutingHostDirective;
  routes: { letterTemplatesList: any; newLetterTemplates: any; };

  constructor(
    private letterTemplatesService: LetterTemplatesService,
    private routingService: RoutingService,
    cardComponentService: CardComponentService,
    sessionService: SessionService,
    @Inject('letterTemplatesList') letterTemplatesList,
    @Inject('newLetterTemplates') newLetterTemplates,
  ) {
    super(cardComponentService, sessionService);
    this.routes = {
      letterTemplatesList,
      newLetterTemplates,
    };
  }

  ngAfterViewInit(): void {
    this.routingService.routes = this.routes;
    this.routingService.host = this.hostViewRef.viewContainerRef;

    switch (this.letterTemplatesService.getMainClass()) {
      case LetterTemplateMainClass.NEW_LETTER_TEMPLATE:
        this.routingService.loadComponent(this.routes.newLetterTemplates);
        (this.routingService.componentRef.instance as LetterTemplatesListComponent).card = this.card;
        break;
      case LetterTemplateMainClass.LETTER_TEMPLATE_LIST:
        this.routingService.loadComponent(this.routes.letterTemplatesList);
        (this.routingService.componentRef.instance as LetterTemplatesListComponent).card = this.card;
        break;
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PV_TOKEN } from '../models/consts/lpc-consts';
import { SystemProperties } from '../models/enums/vita.enum';

@Injectable({
  providedIn: 'root'
})
export class SystemPropertiesService {

  protected baseApiUrl: string;
  protected storedSystemProperties = new Map();
  private keys: string[] = [
    SystemProperties.PREVAL_DELEGATE,
    SystemProperties.PREVAL_LEGALGUARDIAN,
    SystemProperties.PREVAL_THIRDPAYER
  ];

  constructor(protected httpClient: HttpClient, @Inject(PV_TOKEN.ENV) environment: any) {
    this.baseApiUrl = environment.api.portal.host + environment.api.portal.path;
   }

   loadAllProperties(): Observable<any> {
     const request: any = {
       filterKey:
        {
          keys: this.getKeys()
        }
     };

     return this.getSystemProp(request).pipe(
       tap((resp: {systemProperties: {key: string, value: string} []}) => {
         this.storedSystemProperties = new Map();
         this.setPropertiesIntoMap(resp);
       })
     );
   }

   getKeys() {
     return this.keys;
   }

   setKeys(extraKeys: string[]) {
    this.keys = this.keys.concat(extraKeys);
   }

   setPropertiesIntoMap(resp: {systemProperties: {key: string, value: string} []}) {
    this.getKeys().forEach(searchProp => {
      const foundProp = resp.systemProperties.find(val => val.key === searchProp);
      if (!!foundProp) {
        this.storedSystemProperties.set(searchProp, this.extratPropValue(foundProp));
      }
    });
   }

  private extratPropValue(prop: { key: string; value: string; }): any {
    if ((prop.value).toUpperCase() === 'SI') {
      return true;
    } else if ((prop.value).toUpperCase() === 'NO') {
      return false;
    }
    return prop.value;
  }

   getSystemProp(request: any): Observable<{systemProperties: {key: string, value: string} []}> {
    return this.httpClient.post<any>(this.baseApiUrl + '/system/systemproperty', request);
   }

   getStoredSystemProp(propCode): any {
     return this.storedSystemProperties.get(propCode);
   }

}

import { Injectable } from '@angular/core';
import { Subject, PartialObserver } from 'rxjs';
import { PanelStatus } from './dto/panel-status';
import { PanelSignal } from './dto/panel-signal';

@Injectable({
  providedIn: 'root'
})
export class PanelService {
  private panelStatuses: Subject<PanelStatus>[];
  private panelSignals: Subject<PanelSignal>[];

  constructor() { }

  subscribeToPanelStatuses(panelId: string, observer?: PartialObserver<PanelStatus>) {
    return this.panelStatusSubject(panelId).subscribe(observer);
  }

  updatePanelStatus(panelId: string, panelStatus: PanelStatus): void {
    this.panelStatusSubject(panelId).next(panelStatus);
  }

  subscribeToPanelSignals(panelId: string, observer?: PartialObserver<PanelSignal>) {
    return this.panelSignalSubject(panelId).subscribe(observer);
  }

  signalToPanel(panelId: string, panelSignal: PanelSignal): void {
    this.panelSignalSubject(panelId).next(panelSignal);
  }

  private panelStatusSubject(panelId): Subject<PanelStatus> {
    if (!this.panelStatuses) {
      this.panelStatuses = [];
    }
    if (!this.panelStatuses[panelId]) {
      this.panelStatuses[panelId] = new Subject<PanelStatus>();
    }
    return this.panelStatuses[panelId];
  }

  private panelSignalSubject(panelId): Subject<PanelSignal> {
    if (!this.panelSignals) {
      this.panelSignals = [];
    }
    if (!this.panelSignals[panelId]) {
      this.panelSignals[panelId] = new Subject<PanelSignal>();
    }
    return this.panelSignals[panelId];
  }

}

export class GenericEntity {
  constructor(
    public id: string,
    public code: string,
    public description: string) {
  }

  public isNull(): boolean {
    if (!this.id && !this.code && !this.description) {
      return true;
    } else if (this.id === '' && this.code === '' && this.description === '') {
      return true;
    } else {
      return false;
    }
  }
}


export class SubReason extends GenericEntity {
  [x: string]: any;

  constructor(
    public id: string,
    public code: string,
    public description: string,
    public effectiveDate: Date,
    public effectiveDateEnabled: boolean
  ) {
    super(id, code, description);
  }
}

export class CompatibleProducts {
  constructor(
    public compatibleProducts: Array<GenericEntity>,
    public messages?: Array<string>) {
  }
}

export class PolicySubstitution {
  constructor(
    public policyNumber: string,
    public authenticationNodeCode: string,
    public productionNodeCode: string,
    public userCode: string,
    public effectiveDate: Date,
    public substitutedPoliciesNumbers: Array<string>,
    public productCode: string,
    public substitutionReasonId: string) {
  }
}

export class PolicySubstitutionRequest {
  constructor(
    public operationTimestamp: number,
    public idSubstitutionReason: string,
    public idProduct: string,
    public policiesList:string[]) {
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'customNumberPipe'
})
export class CustomNumberPipe implements PipeTransform {
  constructor() {
  }

    transform(value: string|number, type: string = 'number', fractionSize: number = 2, output: string = '-'): string {
      if (value != null) {
        value = value.toString();
        const decimalSeparator = ',';
        const thousandSeparator = '.';
        let prefix = '';
        let suffix = '';

        if (type === 'currency') {
          prefix = '';
          suffix = ' €';
        } else if (type === 'percentage') {
          prefix = '';
          suffix = ' %';
        }
        let numericValue;
        if (type !== 'number' && fractionSize < 3) {
          numericValue = (Math.round(parseFloat(value) * 100) / 100).toString();
        } else {
          numericValue = value.toString();
        }

        let [ integer, fraction = ''] = (parseFloat(numericValue).toString() || '').toString().split('.');

        fraction = fractionSize > 0 ? decimalSeparator + (fraction).substring(0, fractionSize) : '';
        while (fraction.length < (decimalSeparator.length + fractionSize)) {
          fraction = fraction.concat('0');
        }

        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
        if (isNaN(parseFloat(integer))) {
          integer = '0';
        }

        return prefix + integer + fraction + suffix;

      }
      return output;
    }
  }


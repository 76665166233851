<ng-container *ngIf="displayType === 'ROWS'; else keyValues">
  <div *ngFor="let row of details; index as i">
    <div class="rgiportal-flex">
      <div *ngFor="let item of row; index as j">
        <span class="rgi-ui-text-5">
          {{(item?.label | translate) + ':'}}
        </span>
        <span class="rgi-ui-text-5 rgi-ui-title-3">
          <b>{{isDate(item.description) ? (item.description | date:'dd-MM-yyyy') : (item.description)}}</b>
        </span>
        <span *ngIf="j !== row.length -1">&nbsp;-&nbsp;</span>
      </div>
    </div>
    <hr class="rgiportal-separator" *ngIf="i !== details.length -1">
  </div>
</ng-container>
<ng-template #keyValues>
  <div class="rgi-ui-grid-no-gutter-4-lg-3-md-2-sm-1">
    <div *ngFor="let item of details; index as i" class="rgi-ui-col-3-lg-4-md-6-sm-12">
      <span class="rgi-ui-text-5">
        {{(item?.label | translate) + ':'}}
      </span>
      <span class="rgi-ui-text-5 rgi-ui-title-3">
        <b>{{isDate(item.description) ? (item.description | date:'dd-MM-yyyy') : (item.description)}}</b>
      </span>
      <hr class="rgiportal-separator" *ngIf="showSeparator(i)">
    </div>
  </div>
</ng-template>

<table class="table table-striped">
  <thead class="head-result">
  <tr>
    <th scope="col">
      <span></span>
    </th>
    <ng-container>
      <th *ngFor="let header of tableHeader" scope="col">
        <span> {{header.description}} </span>
      </th>
    </ng-container>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngFor="let colElement of arrayOfElements; let i = index">
    <tr *ngIf="i % 2 == 0">
      <div *ngIf="viewAccordion" style="vertical-align: middle">
        <button (click)="toggleAccordion(i)" class="btn btn-start-node" type="button">
          <i *ngIf="!isNestedTableOpen(i)" class="glyphicon glyphicon-plus"></i>
          <i *ngIf="isNestedTableOpen(i)" class="glyphicon glyphicon-minus"></i>
        </button>
      </div>
      <td *ngFor="let element of arrayOfElements[i]" [ngSwitch]="element.type" scope="row"
          style="vertical-align: middle">
        <span *ngSwitchCase="'span'" class="tbld_col_value">{{element.description}}</span>
      </td>
    </tr>
    <tr *ngIf="i % 2 > 0 && isNestedTableOpen(i)">
      <td colspan="5">
        <table class="table table-striped">
          <thead class="head-result">
          <tr>
            <ng-container>
              <th *ngFor="let header of nestedTableHeader" scope="col">
                <span> {{header.description}} </span>
              </th>
            </ng-container>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let residualDiscounts of arrayOfElements[i]">
            <td *ngFor="let element of residualDiscounts" [ngSwitch]="element.type" scope="row"
                style="vertical-align: middle">
              <span *ngSwitchCase="'span'" class="tbld_col_value">{{element.description}}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </ng-container>
  </tbody>
</table>

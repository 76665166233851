import {Inject, OnDestroy, Optional, Pipe, PipeTransform} from '@angular/core';
import {RGI_RX_LOCALE} from '@rgi/rx/i18n';
import {RgiCountryLayerContext} from '../rgi-country-layer.api';
import {Subscription} from 'rxjs';
import {RgiCountryLayerNumberFormat} from './rgi-country-layer-number-format.service';

@Pipe({
  pure: false,
  name: 'rgiCountryLayerNumberFormat' // rgiCtryLNumFormatter
})
export class RgiCountryLayerNumberFormatPipe implements PipeTransform, OnDestroy {

  private currentValue: any;
  private currentOptions: Intl.NumberFormatOptions;
  private contextChangeSubscription = Subscription.EMPTY;
  constructor(private context: RgiCountryLayerContext, private format: RgiCountryLayerNumberFormat) {

    this.contextChangeSubscription = context.context$.subscribe(
        next => {
          if (this.currentValue) {
            this.transform(this.currentValue, next.locale, this.currentOptions);
          }
        }
    );
  }

  transform(value: any, locale?: RGI_RX_LOCALE, options?: Intl.NumberFormatOptions): string { // TODO replace NumberFormatOptions with a custom Option Type
    if (this.currentValue !== value) {
      this.currentValue = value;
    }
    this.currentOptions = options;
    const localeToUse = locale ? locale : this.context.locale;
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      if (value.toString().length > 20){
        return this.format.formatBigNumberString(value, localeToUse);
      }
      return this.format.convertNumberByLocale(value, localeToUse, options);
    }
    return value;
  }

  ngOnDestroy(): void {
    this.contextChangeSubscription.unsubscribe();
  }

}

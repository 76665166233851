import {InitContract} from './../../models/api-models/initContract';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map, share} from 'rxjs/operators';
import {ReIssueStartCardState} from '../re-issue-state-manager/re-issue-start-card-state-manager.service';
import {StartCardNode} from '../../models/domain-models/start-card-node';
import {Company} from '../../models/check-pin-response';
import {ProductsObject} from '../../models/pc-portfolio-models/genericEntityPcPortfolio';

@Injectable({
  providedIn: 'root'
})
export class ReIssueStartCardResourceService {

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any
  ) {
  }

  getInitContract(companyId: string, nodeId: string, productCode: string): Observable<InitContract> {
    return this.http.get<InitContract>(this.getInitContractUri(companyId, nodeId, productCode)).pipe(
      share()
    );
  }


  getInitContractUri(companyId: string, nodeId: string, productCode: string): string {
    // TODO: use the config tokens and constants
    return this.apiConf.api.portal.path + '/qqcontract/contract?companyId=' + companyId + '&nodeId=' + nodeId + '&productCode=' + productCode;
  }

  getCompaniesUri() {
    return this.apiConf.api.portal.path + '/admin/companies';
  }

  getInternalCompaniesUri() {
    return this.apiConf.api.portal.path + '/v2/common/companies/internal';
  }


  getCompanies$(st: ReIssueStartCardState): Observable<Array<Company>> {
    return this.http.get<any>(this.getInternalCompaniesUri()).pipe(share(), map(val => {
        return val;
      })
    );
  }


  getProducts$(st: ReIssueStartCardState, node: string): Observable<ProductsObject> {
    const body = {
      productsFilter: {
        idSp: node,
        filterProductsCategory: '1', // questo sarebbe in base a individuale,collettivo (non avendo collettivo è statico)
        filterOnlyDamagesProducts: true,
        filterSalableProduct: true,
        filterSalableProductTypes: true,
        effectiveDate: new Date()
      }
    };
    return this.http.post<any>(this.getProductsUri(), body).pipe(share(), map(val => {
      const objeProd: ProductsObject = new ProductsObject();
      val.products.forEach(element => {
          if (element.policyCategory.codice === '2') {
            objeProd.products.push(element.product);
            objeProd.productsType.push(element.productType);
          }
        });
      return objeProd;
      })
    );
  }

  getProductsUri() {
    return this.apiConf.api.portal.path + '/admin/products';
  }


  getNode$(st: ReIssueStartCardState, node: string): Observable<Array<StartCardNode>> {
    return this.http.get<any>(this.getNodeUri(node)).pipe(share(), map(val => {
      const starCardNode: Array<StartCardNode> = [];
      val.agencies.forEach((element => {
        starCardNode.push(element);
      }));
      return starCardNode;
    }));
  }

  getNodeUri(nodo: string) {
    return this.apiConf.api.portal.path + '/admin/agencies?loginNodeId=' + nodo;
  }


}

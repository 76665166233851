<rgi-rx-panel>
  <rgi-rx-panel-header [closeable]="true" (onClose)="modalClose.emit(confirmationEvent.CLOSE)">
    <span translate>_GP_._TITLE_._INS_POL_NUM_</span>
  </rgi-rx-panel-header>
  <rgi-rx-panel-footer>
    <button rgi-rx-button color="secondary" (click)="modalClose.emit(confirmationEvent.CLOSE)" data-qa="rgi-gp-btn-back" translate>
      _GP_._BTN_._UNDO_
    </button>
    <button rgi-rx-button color="primary" [disabled]="manualPolicyNumForm.invalid"
            (click)="onConfirm()" data-qa="rgi-gp-btn-confirm" translate>
      _GP_._BTN_._CONFIRM_
    </button>
  </rgi-rx-panel-footer>
  <ng-container [formGroup]="manualPolicyNumForm">
    <div class="rgi-ui-grid-2">
      <div class="rgi-ui-col">
        <rgi-rx-form-field data-qa="rgi-gp-policyNumber" class="rgi-gp-mb-0 rgi-gp-w-33">
          <input type="text" rgiRxInput formControlName="policyNumber">
          <label rgiRxLabel><span translate>_GP_._LABEL_._POLICY_NUMBER_</span></label>
        </rgi-rx-form-field>
      </div>
    </div>
  </ng-container>
</rgi-rx-panel>

import { LpcSubRolesControlComponent } from './lpc-subroles-control/lpc-subroles-control.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LpcRolesControlComponent} from './lpc-roles-control/lpc-roles-control.component';
import {ReactiveFormsModule} from '@angular/forms';
import { PortalNgCoreModule } from '@rgi/portal-ng-core';
import { MessageModalComponentModule } from '../lpc-message-modal/lpc-message-modal.module';
import { RgiRxI18nModule } from '@rgi/rx/i18n';
import { LpcRequiredModule } from '../../utils/lpc-required/lpc-required.module';



@NgModule({
  declarations: [LpcRolesControlComponent, LpcSubRolesControlComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PortalNgCoreModule,
        MessageModalComponentModule,
        RgiRxI18nModule,
        LpcRequiredModule
    ],
  exports: [LpcRolesControlComponent, LpcSubRolesControlComponent]
})
export class LpcRolesControlModule { }

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PrintDocumentsRequest} from '../../models/api-models/print-documents/print-documents-request';
import {PrintDocumentsResponse} from '../../models/api-models/print-documents/print-documents-response';
import {REST_API_PRINT_EXT_CONF} from './re-issue-resources-path';

@Injectable({
  providedIn: 'root'
})
export class ReIssueProposalPrintResourceService {
  private apiConfPrint: any;

  constructor(
    private http: HttpClient,
    @Inject('environment') private apiConf: any) {
    this.apiConfPrint = REST_API_PRINT_EXT_CONF;
  }

  printDocuments(request: PrintDocumentsRequest): Observable<PrintDocumentsResponse> {
    return this.http.post<PrintDocumentsResponse>(this.printDocumentsUri(), request);
  }

  protected printDocumentsUri(): string {
    return this.apiConf.api.portal.path + '/v2'
      + this.apiConfPrint.path;
  }

}

import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild
} from '@angular/core';
import { PassproQuestionnaireService, QuestionnaireValuesService } from '@rgi/ng-passpro';
import { questionnaireCacheServiceInjectionToken } from '@rgi/questionnaires-manager';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { CardsNavigationDirective } from './cards-navigation/cards-navigation.directive';
import { CardsNavigationService } from './cards-navigation/cards-navigation.service';
import { SaveActions, OperationCode } from './enum/life-issue.enum';
import { EventNotificator } from './event-notificator';
import { LifeCardHostDirective } from './life-card-host.directive';
import { RoutableComponent } from './routable-component';
import { ActionsService } from './services/actions.service';
import { AuthService } from './services/auth.service';
import { BeneficiaryService } from './services/beneficiary.service';
import { EnviromentsService } from './services/enviroments.service';
import { FromJsService } from './services/fromJs.service';
import { LifeRoleService } from './services/life-role.service';
import { LifeSessionService } from './services/life-session-service';
import { NavigationSummaryService } from './services/navigation-summary.service';
import { PassProLifeService } from './services/passpro-life.service';
import { MasterPolicyService } from './services/policy-master.service';
import { PolicyService } from './services/policy-service';
import { ReinvestementService } from './services/reinvestment.service';
import { SystemPropertiesService } from './services/system-properties.service';
import { ValidationSubjectsService } from './services/validation-subjects.service';
import { AnagFlowData, AnagSearchOrCreateResolver } from '@rgi/anag';
import { TranslationWrapperService } from '../i18n/translation-wrapper.service';

@Component({
    selector: 'lic-life-card',
    templateUrl: './life-card.component.html',
    styleUrls: [],
    providers: [
      PolicyService,
      LifeSessionService,
      SystemPropertiesService,
      ActionsService,
      QuestionnaireValuesService,
      LifeRoleService,
      FromJsService,
      NavigationSummaryService,
      PassProLifeService,
      CardsNavigationService,
      PassproQuestionnaireService,
      AuthService,
      MasterPolicyService,
      ValidationSubjectsService,
      BeneficiaryService,
      ReinvestementService,
      TranslationWrapperService,
      questionnaireCacheServiceInjectionToken
    ]
  })
  export class LifeCardComponent implements OnInit, OnDestroy {

    protected subject;
    routes = null;
    baseApiUrl: string;
    flowId: string = null;

    // serve per evitare di ricaricare una route gi� visualizzata
    lastRouteLoaded: string = null;

    @Input() data: {data: any, idParentSession: string};
    @Output() eventPropagation = new EventEmitter<any>();

    navigationRef: ComponentRef<Component>;
    navigationComponent = null;
    componentRef: ComponentRef<Component>;

    @ViewChild(LifeCardHostDirective, { static: true }) micHost: LifeCardHostDirective;
    @ViewChild(CardsNavigationDirective, { static: true }) navigation: CardsNavigationDirective;

    @Input() set selectedSubject(inputSubject) {
      console.log(inputSubject);
      if (!!inputSubject) {
        if (inputSubject === 'noSubj') {
          this.subject = null;
          this.lifeRoleService.setSubjectFromAnag(null);
        } else if (inputSubject.objectId != null) {
          this.handleSelectedSubject(inputSubject);
        }
      }
    }
    get selectedSubject() {
      return this.subject;
    }

    handleSelectedSubject(inputSubject) {
      this.subject = inputSubject;
      this.policyService.haveToResetQuest = true;
      this.lifeRoleService.storeSubject(inputSubject);
      this.lifeRoleService.setSubjectFromAnag(this.selectedSubject);
    }


    @Input() set errorMessageFromJs(inputMsg) {
      if (inputMsg === 'noMsg') {
        this.fromJsService.setMessageFromJs(null);
        this.eventPropagation.emit('resetMessageFromJs');
      } else if (inputMsg != null) {
        this.fromJsService.setMessageFromJs(inputMsg);
        this.eventPropagation.emit('resetMessageFromJs');
      }
    }

    // TODO: EXT
    @Input() set proposalFromJs(inputProposal) {
      if (inputProposal === 'noProp') {
        this.fromJsService.setProposalFromJs(null);
      } else if (inputProposal != null) {
        this.fromJsService.setProposalFromJs(inputProposal);
      }
    }
    // TODO: EXT


    constructor(protected componentFactoryResolver: ComponentFactoryResolver,
                protected lifeRoleService: LifeRoleService,
                protected policyService: PolicyService,
                protected validationSubjectService: ValidationSubjectsService,
                protected beneficiaryService: BeneficiaryService,
                protected cardsNavigationService: CardsNavigationService,
                protected fromJsService: FromJsService,
                protected lifeSessionService: LifeSessionService,
                protected systemPropertiesService: SystemPropertiesService,
                protected navigationSummaryService: NavigationSummaryService,
                protected changeDetector: ChangeDetectorRef,
                protected http: HttpClient,
                @Inject('issueComponent') issueComponent,
                @Inject('lifeRolePageComponent') lifeRolePageComponent,
                @Inject('datiAmmComponent') datiAmmComponent,
                @Inject('pipDataComponent') pipDataComponent,
                @Inject('goodsInfoComponent') goodsInfoComponent,
                @Inject('licQuotationComponent') quotationComponent,
                @Inject('investmentsComponent') investmentsComponent,
                @Inject('summaryComponent') summaryComponent,
                @Inject('cardsNavigationComponent') cardsNavigationComponent,
                @Inject('$scope') scope,
                protected environment: EnviromentsService,
                @Inject('RGI_ANAG_PORTAL_AJS_FLOW') protected anagInJs: boolean,
                protected anagSearchorCreateResolver: AnagSearchOrCreateResolver) {

      this.routes = {
        [this.cardsNavigationService.STEP.SOGGETTI.route]: lifeRolePageComponent,
        [this.cardsNavigationService.STEP.DATI_AMMINISTRATIVI.route]: datiAmmComponent,
        [this.cardsNavigationService.STEP.DATI_DI_BENE.route]: goodsInfoComponent,
        [this.cardsNavigationService.STEP.PIP.route]: pipDataComponent,
        [this.cardsNavigationService.STEP.QUOTAZIONE.route]: quotationComponent,
        [this.cardsNavigationService.STEP.INVESTIMENTI.route]: investmentsComponent,
        [this.cardsNavigationService.STEP.SOMMARIO.route]: summaryComponent,
        [this.cardsNavigationService.STEP.ISSUE.route]: issueComponent
      };
      this.navigationComponent = cardsNavigationComponent;
      this.setFlowId(scope.id);
      this.baseApiUrl = environment.baseApiURL;
    }

    setFlowId(id: string) {
      this.flowId = id;
      this.navigationSummaryService.id = id;
      this.policyService.mainProposalResourceId = id;
    }

    initNavigation(cnc) {
      this.cardsNavigationService.resetNavigationSteps();
      if (!this.navigation) {
        return;
      }
      this.navigation.viewContainerRef.clear();
      if (this.navigationRef != null) {
        this.navigationRef.destroy();
        this.navigationRef = null;
      }
      this.navigationRef = this.navigation.viewContainerRef.createComponent(
        this.componentFactoryResolver.resolveComponentFactory(cnc)
      );
      if ((this.navigationRef.instance as any).navigation !== undefined) {
        (this.navigationRef.instance as any).navigation.subscribe((event) => {
          this.setActiveRoute(event); // istanzia il componente per la rotta inseritas
        });
      }
    }

    ngOnInit() {
      // RECUPERO I DATI DALLA SESSIONE PRECEDENTE
      this.policyService.coreResultData = this.data.data;
      this.lifeSessionService.idParentSession = this.data.idParentSession;
      console.log('CORE RESULT DATA', this.data.data);
      this.policyService.updateCoreResultData();
      this.initNavigation(this.navigationComponent);
      this.loadAllIssueConfigurations().subscribe(_ => {
        this.startFlow();
      });
    }

    startFlow() {
      if (this.policyService.isSaveFromAuth) {
        this.handleIssueSaveFromAuth();
      } else if (this.policyService.isFromAuth) {
        this.handleIssueFromAuth();
      } else if (!!this.policyService.isFromQuoteModification) {
        this.handleIssueFromQuoteModification();
      } else if (!!this.policyService.isFromBuyQuote) {
        this.handleIssueFromBuyQuote();
      } else if (!!this.policyService.isFromPreventive && !!this.policyService.isAnonymous) {
        this.initProposal(this.cardsNavigationService.STEP.DATI_AMMINISTRATIVI.route);
      } else if (!!this.policyService.isFromProposalModification) {
        this.handleIssueFromProposalMod();
      } else {
        this.initProposal(this.cardsNavigationService.STEP.SOGGETTI.route);
      }
    }

    handleIssueFromProposalMod() {
      this.reloadSubjectsFromAuth().subscribe(_ => {
        this.setActiveRoute(this.cardsNavigationService.STEP.SOGGETTI.route);
      });
    }

    handleIssueFromAuth() {
      this.reloadSubjectsFromAuth().subscribe(_ => {
        this.setActiveRoute(this.cardsNavigationService.STEP.SOGGETTI.route);
      });
    }

    handleIssueSaveFromAuth() {
      this.reloadSubjectsFromAuth().subscribe(_ => {
        // CARICO IL FLUSSO DI EMISSIONE CON LA PAGINA ISSUE FINALE
        this.setActiveRoute(this.cardsNavigationService.STEP.ISSUE.route);
        if (this.policyService.isFromConsultation || this.policyService.isSaveFromAuth) {
          // ASMC-9310 - da consultazione devo distinguere se ho emesso una proposta o una polizza
          if (!!this.policyService.mainProposal.proposal.contractData[0].policyNumber) {
            this.policyService.flowTypeOnConfirm = SaveActions.SAVE_CONTEXTUAL;
          } else {
            this.policyService.flowTypeOnConfirm = SaveActions.SAVE_PROPOSAL;
          }
        }
        this.cardsNavigationService.goStraigthToIssue();
      });
    }

    handleIssueFromQuoteModification() {
      // controllare se serve fare anche il reload benef
      if (!!this.policyService.isAnonymous) {
        this.policyService.mainProposal = this.policyService.coreResultData.quoteFromDetail;
        this.setActiveRoute(this.cardsNavigationService.STEP.DATI_AMMINISTRATIVI.route);
      } else {
        this.reloadSubjectsFromAuth().subscribe(_ => {
          this.setActiveRoute(this.cardsNavigationService.STEP.SOGGETTI.route);
        });
      }
    }

    handleIssueFromBuyQuote() {
      this.eventPropagation.emit('startLoader');
      this.policyService.createInitProposalFromBuyQuote().pipe(
        concatMap((respCreation: any) => {
          if (respCreation.resourceId) {
            this.setFlowId(respCreation.resourceId);
          }
          return this.policyService.getProposalFromCache();
        }),
        concatMap((respGetProp: any) => {
          let reqForModProposal;
          if (respGetProp.output) {
            this.policyService.mainProposal = respGetProp.output;
            reqForModProposal = this.policyService.createPutProposalReq();
            reqForModProposal = this.changeRequestData(Object.assign({}, reqForModProposal));
          }
          return this.policyService.putProposalFromBuyQuote(reqForModProposal);
        }),
        catchError(error => {
          console.error(error);
          return throwError(error);
        })
      ).subscribe((_respPropUdated: any) => {
        this.policyService.handleSaveEstimateResponse(_respPropUdated);
        this.lifeRoleService.reloadFromAuth(this.policyService.getAllSubjectCodeFromAuth());
        this.eventPropagation.emit('stopLoader');
        this.setActiveRoute(this.cardsNavigationService.STEP.SOGGETTI.route);
      });
    }

    changeRequestData(updateReq) {
      /* When i'm buying a quote i need to use only some data of the old quote */
      const req = Object.assign({}, updateReq);
      req.quote.product = Object.assign({}, this.policyService.oldQuote.quote.product);
      req.quote.product.operationCode = OperationCode.EMISSIONE_POLIZZA;
      req.proposal = Object.assign({}, this.policyService.oldQuote.proposal);
      req.proposal.contractData = null;
      req.proposal.extensionData = updateReq.proposal.extensionData === null ?
                                   req.proposal.extensionData = {properties: []} : Object.assign({}, updateReq.proposal.extensionData);
      return req;
    }

    protected reloadSubjectsFromAuth(): Observable<any> {
      // TODO: EXT
      this.eventPropagation.emit('startLoader');
      // Riscarico i ruoli dal servizio di anagrafe e li assegno nelle varie variabili del flusso
      // (soggetti della pagina dei ruoli + beneficiari da anagrafe)
      // tutti i ruoli che scendono vengono salvati nella mappa 'storedPolicyRoles' in 'LifeRoleService'
      this.lifeRoleService.reloadFromAuth(this.policyService.getAllSubjectCodeFromAuth());
      return this.lifeRoleService.waitReloadEnd().pipe(
        switchMap(message => {
          this.beneficiaryService.reloadFromAuth(); // carica la gestione dei beneficiari se sono presenti
          this.eventPropagation.emit('stopLoader');
          return of(message);
        })
      );
    }

    loadAllIssueConfigurations(): Observable<any> {
      // � possibile estendere questo metodo nel policyService per poter aggiungere catene di chiamate da fare
      // prima che il flusso inizi (c'� sempre il problema che apre la card due volte)
      return forkJoin([
        this.policyService.loadAllIssueConfigurations(),
        this.lifeSessionService.initService()
      ]);
    }

    setActiveRoute(route) {
      // issue can load multiple times, when confirming proposal and when confirming policy
      if (this.lastRouteLoaded !== route || route === this.cardsNavigationService.STEP.ISSUE.route) {
        this.lastRouteLoaded = route;
        this.loadComponent(this.routes[route]);
      } else {
        console.log('----- RELOAD ROUTE EVITATO -----');
      }
    }

    initProposal(startRoute: string) {
      this.eventPropagation.emit('startLoader');
      this.policyService.initProposal().subscribe(id => {
        this.eventPropagation.emit('stopLoader');
        this.setActiveRoute(startRoute);
      });
    }

    loadComponent(component: Type<any>) {
      if (!this.micHost) {
        return;
      }
      this.micHost.viewContainerRef.clear();
      if (this.componentRef != null) {
        this.componentRef.destroy();
        this.componentRef = null;
      }
      this.componentRef = this.micHost.viewContainerRef.createComponent(
        this.componentFactoryResolver.resolveComponentFactory(component)
      );

      (this.componentRef.instance as RoutableComponent).navigation.subscribe((nextRoute) => {
        this.setActiveRoute(nextRoute);
      });
      if ((this.componentRef.instance as EventNotificator).eventPropagation !== undefined) {
        (this.componentRef.instance as EventNotificator).eventPropagation.subscribe((event) => {
          if (event.eventName !== 'requireSubject') {
            this.eventPropagation.emit(event);
          } else {
            this.handleAnagEvents(event);
          }
        });
      }
    }

  private handleAnagEvents(event: any) {
    if (event.eventName === 'requireSubject' && this.anagInJs) {
      this.eventPropagation.emit(event);
    } else {
      this.handleAnagEventInNewFlow(event.roleCode);
    }
  }

  protected handleAnagEventInNewFlow(role: string) {
    const anagFlowData = new AnagFlowData();
    anagFlowData.partyRole = role;
    anagFlowData.nodeCode = this.lifeSessionService.codNodeManagement;
    // anagFlowData.partyType = ;
    // anagFlowData.operation = ;
    anagFlowData.idParentSession = this.data.idParentSession;
    this.anagSearchorCreateResolver.searchOrCreateSubject(anagFlowData).pipe(
      switchMap((subject) => {
        return this.lifeRoleService.getSubjectFromAnag(subject.objectId);
      }),
      map(fullSubject => fullSubject.subject)
    ).subscribe(subject => {
      if (!!subject && subject.objectId != null) {
        this.handleSelectedSubject(subject);
      }
    });
  }

    ngOnDestroy() {
      this.lifeRoleService.resetService();
      this.lifeSessionService.resetService();
      this.policyService.resetProposal();
      this.policyService.resetPipContribution();
      this.validationSubjectService.resetService();
      this.beneficiaryService.resetService();
      this.policyService.setInsuredPresent(false);
      if (this.componentRef != null) {
        this.componentRef.destroy();
      }
    }
  }


export class ApiAgency {

  constructor(
    public idSp: string,
    public extension: string,
    public code: string,
    public description: string
  ) {
  }

}


export interface FIELD {
  name: string;
  label: string;
  visible: number;
  functions: string;
  disabled: number;
  parent: string;
  type: string;
  fields: Array<FIELD>;
}

export const FIELD_AUTH_REQUEST_CODE = {
  name : 'requestCode',
  label : 'Authorization request code',
  visible : 0,
  type : 'text'
} as FIELD;

export const FIELD_AUTH_REQUEST_TYPE = {
  name : 'requestType',
  label : 'Request Type',
  visible : 1,
  type : 'select'
} as FIELD;

export const FIELD_AUTH_PRIORITY = {
  name : 'priority',
  label : 'Priority',
  visible : 1,
  type : 'select'
} as FIELD;

export const FIELD_AUTH_POLICY_TYPE = {
  name : 'policyType',
  label : 'Contract type',
  visible : 1,
  type : 'select'
} as FIELD;

export const FIELD_AUTH_USER_TYPE = {
  name : 'userType',
  label : 'User Type',
  visible : 1,
  type : 'select'
} as FIELD;

export const FIELD_AUTH_COMPETENT_USER = {
  name : 'competentUser',
  label : 'Competent user',
  visible : 0,
  type : 'text'
} as FIELD;

export const FIELD_AUTH_REQUEST_STATUS = {
  name : 'requestStatus',
  label : 'Request Status',
  visible : 1,
  type : 'select'
} as FIELD;

export const FIELD_AUTH_WORKFLOW_STATUS = {
  name : 'workflowStatus',
  label : 'Workflow Status',
  visible : 0,
  type : 'select'
} as FIELD;

export const FIELD_AUTH_REQUEST_DATE_FROM = {
  name : 'requestDateFrom',
  label : 'Request date from',
  visible : 0,
  type : 'date'
} as FIELD;

export const FIELD_AUTH_REQUEST_DATE_TO = {
  name : 'requestDateTo',
  label : 'Request date to',
  visible : 0,
  type : 'date'
} as FIELD;

export const FIELD_AUTH_PRODUCT_TYPE = {
  name : 'productType',
  label : 'Product type',
  visible : 1,
  type : 'select'
} as FIELD;

export const FIELD_AUTH_POSTSELL_CODE = {
  name : 'postSellCode',
  label : 'Post-sell code ',
  visible : 0,
  type : 'text'
} as FIELD;

export const FIELD_AUTH_DEROGATION_LEVEL = {
  name : 'derogationLevel',
  label : 'Possible clearence level',
  visible : 0
} as FIELD;

export const FIELD_AUTH_DEROGATION_LEVEL_NUMBER = {
  name : 'derogationLevelNumber',
  label : 'Clearance level',
  visible : 0,
  type : 'select'
} as FIELD;

export const FIELD_AUTH_LAST_ACTION = {
  name : 'lastAction',
  label : 'Last executed action',
  visible : 0,
  type : 'select'
} as FIELD;


import {Injectable, Injector} from '@angular/core';
import {HttpBackend, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MIC_HTTP_INTERCEPTOR} from './mic-http-constants';

export class MicHttpInterceptorHandler implements HttpHandler {
  constructor(private next: HttpHandler, private interceptor: HttpInterceptor) {}

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    return this.interceptor.intercept(req, this.next);
  }
}

@Injectable({
  providedIn: 'root'
})
export class MicHttpHandlerService implements HttpHandler {

  private chain: HttpHandler|null = null;

  constructor(private backend: HttpBackend, private injector: Injector) {}

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (this.chain === null) {
      const interceptors = this.injector.get(MIC_HTTP_INTERCEPTOR, []);

      this.chain = interceptors.reduceRight(
        (next, interceptor) => new MicHttpInterceptorHandler(next, interceptor), this.backend);
    }
    return this.chain.handle(req);
  }
}


/* upgrade parallelRouting */
export function pRouterFactory(i: any) {
    return i.get('parallelRouting');
}

export const pRouterProvider = {
    provide: 'parallelRouting',
    useFactory: pRouterFactory,
    deps: ['$injector']
};

/* upgrade coreAuthService */
export function coreAuthServiceFactory(i: any) {
    return i.get('coreAuthService');
}

export const coreAuthServiceProvider = {
    provide: 'coreAuthService',
    useFactory: coreAuthServiceFactory,
    deps: ['$injector']
};

/* upgrade authService */
export function authServiceFactory(i: any) {
    return i.get('authService');
}

export const authServiceProvider = {
    provide: 'authService',
    useFactory: authServiceFactory,
    deps: ['$injector']
};

/* upgrade angularGridInstance */
export function angularGridInstanceFactory(i: any) {
    return i.get('angularGridInstance');
}

export const angularGridInstanceProvider = {
    provide: 'angularGridInstance',
    useFactory: angularGridInstanceFactory,
    deps: ['$injector']
};

/* upgrade sessionService */
export function sessionServiceFactory(i: any) {
    return i.get('sessionService');
}

export const sessionServiceProvider = {
    provide: 'sessionService',
    useFactory: sessionServiceFactory,
    deps: ['$injector']
};

/* upgrade coreResult */
export function coreResultFactory(i: any) {
    return i.get('coreResult');
}

export const coreResultProvider = {
    provide: 'coreResult',
    useFactory: coreResultFactory,
    deps: ['$injector']
};

/* upgrade cardNavigationWFServiceFactory */
export function cardNavigationWFServiceFactory(i: any) {
    return i.get('cardNavigationWFService');
}

export const cardNavigationWFServiceProvider = {
    provide: 'cardNavigationWFService',
    useFactory: cardNavigationWFServiceFactory,
    deps: ['$injector']
};

/* upgrade eventServiceServiceFactory */
export function eventServiceFactory(i: any) {
    return i.get('eventService');
}

export const eventServiceProvider = {
    provide: 'eventService',
    useFactory: eventServiceFactory,
    deps: ['$injector']
};


export function containerCacheServiceFactory(i: any) {
    return i.get('containerCacheService');
}

export const containerCacheServiceProvider = {
    provide: 'containerCacheService',
    useFactory: containerCacheServiceFactory,
    deps: ['$injector']
};

export function claimsCardServiceFactory(i: any) {
    return i.get('ClaimsCardService');
}

export const claimsCardServiceProvider = {
    provide: 'claimsCardService',
    useFactory: claimsCardServiceFactory,
    deps: ['$injector']
};

export function claimsLoaderServiceFactory(i: any) {
    return i.get('claimsLoaderService');
}

export const claimsLoaderServiceProvider = {
    provide: 'claimsLoaderService',
    useFactory: claimsLoaderServiceFactory,
    deps: ['$injector']
};

export function claimsActivityServiceFactory(i: any) {
    return i.get('claimsActivityService');
}

export const claimsActivityServiceProvider = {
    provide: 'claimsActivityService',
    useFactory: claimsActivityServiceFactory,
    deps: ['$injector']
};

export function claimsActivityCommonServiceFactory(i: any) {
    return i.get('claimsActivityCommonService');
}

export const claimsActivityCommonServiceProvider = {
    provide: 'claimsActivityCommonService',
    useFactory: claimsActivityCommonServiceFactory,
    deps: ['$injector']
};

export function constActivityWorkflowFactory(i: any) {
    return i.get('CONST_ACTIVITY_WORKFLOW');
}

export const constActivityWorkflowProvider = {
    provide: 'CONST_ACTIVITY_WORKFLOW',
    useFactory: constActivityWorkflowFactory,
    deps: ['$injector']
};

export function constCardManagementServiceFactory(i: any) {
    return i.get('cardManagementService');
}

export const constCardManagementServiceProvider = {
    provide: 'cardManagementService',
    useFactory: constCardManagementServiceFactory,
    deps: ['$injector']
};

export function constBoardBaseServiceFactory(i: any) {
    return i.get('boardBaseService ');
}

export const constBoardBaseServiceProvider = {
    provide: 'boardBaseService ',
    useFactory: constBoardBaseServiceFactory,
    deps: ['$injector']
};

export function constFunctionListServiceFactory(i: any) {
    return i.get('functionListService');
}

export const constFunctionListServiceProvider = {
    provide: 'functionListService',
    useFactory: constFunctionListServiceFactory,
    deps: ['$injector']
};

export function claimsSessionStatusFactory(i: any) {
    return i.get('claimsSessionStatus');
}

export const claimsSessionStatusProvider = {
    provide: 'claimsSessionStatus',
    useFactory: claimsSessionStatusFactory,
    deps: ['$injector']
};

export function cardPassServiceFactory(i: any) {
    return i.get('cardPassService');
}

export const cardPassServiceProvider = {
    provide: 'cardPassService',
    useFactory: cardPassServiceFactory,
    deps: ['$injector']
};

export function claimsFinderIntegrationPassServiceFactory(i: any) {
    return i.get('claimsFinderIntegrationPassService');
}

export const claimsFinderIntegrationPassServiceProvider = {
    provide: 'claimsFinderIntegrationPassService',
    useFactory: claimsFinderIntegrationPassServiceFactory,
    deps: ['$injector']
};

export function anagManageSubjectValidationServiceFactory(i: any) {
  return i.get('anagManageSubjectValidationService');
}

export const anagManageSubjectValidationServiceProvider = {
  provide: 'anagManageSubjectValidationService',
  useFactory: anagManageSubjectValidationServiceFactory,
  deps: ['$injector']
};

export function claimsBaseObjectServiceFactory(i: any) {
    return i.get('claimsBaseObjectService');
}

export const claimsBaseObjectServiceProvider = {
    provide: 'claimsBaseObjectService',
    useFactory: claimsBaseObjectServiceFactory,
    deps: ['$injector']
};

export function claimsBodyworkServiceFactory(i: any) {
    return i.get('claimsBodyworkService');
}

export const claimsBodyworkServiceProvider = {
    provide: 'claimsBodyworkService',
    useFactory: claimsBodyworkServiceFactory,
    deps: ['$injector']
};

export function anagFinderSubjectFactory(i: any) {
    return i.get('AnagFinderSubject');
}

export const anagFinderSubjectProvider = {
    provide: 'AnagFinderSubject',
    useFactory: anagFinderSubjectFactory,
    deps: ['$injector']
};

export function constClaimsFeaturesFactory(i: any) {
    return i.get('CLAIMS_FEATURES');
}

export const constClaimsFeaturesProvider = {
    provide: 'CLAIMS_FEATURES',
    useFactory: constClaimsFeaturesFactory,
    deps: ['$injector']
};

export function claimsAlertServiceFactory(i: any) {
    return i.get('claimsAlertService');
}

export const claimsAlertServiceProvider = {
    provide: 'claimsAlertService',
    useFactory: claimsAlertServiceFactory,
    deps: ['$injector']
};

export function claimsServiceFactory(i: any) {
    return i.get('claimsService');
}

export const claimsServiceProvider = {
    provide: 'claimsService',
    useFactory: claimsServiceFactory,
    deps: ['$injector']
};

export function gettextCatalogFactory(i: any) {
    return i.get('gettextCatalog');
}

export const gettextCatalogProvider = {
    provide: 'gettextCatalog',
    useFactory: gettextCatalogFactory,
    deps: ['$injector']
};

export function getSystemPropertyFactory(i: any) {
    return i.get('getSystemProperty');
}

export const getSystemPropertyProvider = {
    provide: 'getSystemProperty',
    useFactory: getSystemPropertyFactory,
    deps: ['$injector']
};

export function enumServiceFactory(i: any) {
    return i.get('core.enumService');
}

export const enumServiceProvider = {
    provide: 'enumService',
    useFactory: enumServiceFactory,
    deps: ['$injector']
};

export function workflowTaskServiceFactory(i: any) {
    return i.get('workflowTaskService');
}

export const workflowTaskServiceProvider = {
    provide: 'workflowTaskService',
    useFactory: workflowTaskServiceFactory,
    deps: ['$injector']
};

export function loadingHistoricalErosionExcessFactory(i: any) {
    return i.get('LoadingHistoricalErosionExcess');
  }

export const loadingHistoricalErosionExcessProvider = {
    provide: 'LoadingHistoricalErosionExcess',
    useFactory: loadingHistoricalErosionExcessFactory,
    deps: ['$injector']
};

export function claimsDocumentServiceFactory(i: any) {
    return i.get('claimsDocumentService');
  }

export const claimsDocumentServiceProvider = {
    provide: 'claimsDocumentService',
    useFactory: claimsDocumentServiceFactory,
    deps: ['$injector']
};

<!-- <div class="re-issue-modal"> -->
<div class="issue-card-modal-content">
  <div class="issue-card-modal-header">
    <h3 class="modal-title">
      <span translate>Document Preview</span>
    </h3>
  </div>
  <div class="issue-card-modal-body re-issue-card-modal-print my-4">
    <re-issue-print-pdf [document]="document"></re-issue-print-pdf>
  </div>
</div>
<div class="btn-group btn-group-justified re-issue-card-modal-print buttons-close my-2">
  <div class="btn-group">
    <button class="btn btn-primary pull-right" (click)="close()">{{'Close'|translate}}</button>
  </div>
</div>
<!-- </div> -->

import {ApiContractNumber} from './api-contract-number';

export class ApiPolicyInfo {

  public contractNumber: ApiContractNumber;
  public movementNumber: string;
  public titleNumbers: Array<string>;
  public features: Array<string>;
  public messages: string[];

  constructor() {
  }
}

import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Factor } from './../../models/policy.model';



@Component({
  selector: 'lic-factors',
  templateUrl: './factors.component.html',
  styleUrls: ['./factors.component.css']
})

export class FactorsComponent implements OnInit {
  @Input() fattori: Factor[];
  @Input() parentForm: UntypedFormGroup;

  @Output() changeValEmitter = new EventEmitter<string>();

  @HostListener('changeVal', ['$event'])
  onChangeVal(event) {
    this.changeValEmitter.emit(event);
  }

  constructor(protected fb: UntypedFormBuilder)  {

  }

  ngOnInit() {
    this.setFactors(this.fattori);
  }

  setFactors(fattori) {
    fattori.map((x: Factor) => {
      this.parentForm.addControl(
      x.code, new UntypedFormControl(x.value)
      );
    });
  }

}

<div class="modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          <span translate>Quotation Details</span>
        </h3>
      </div>
      <div class="modal-body">
        <div class="modal-body modal-premium">
          <div class="row">
            <div *ngFor="let premiumDetail of premiumDetailSections"
                 class="col-xs-12 col-sm-4 col-md-4 tbld_col_summary tbld_annual_premium">
              <div class="tbld">
                <div class="tbld_row premium-title">
                  <div class="tbld_col tbld_col_title"><span
                    class="tbld_title_premi" translate>{{ premiumDetail.sectionTitle }}</span></div>
                  <div class="tbld_col tbld_col_value">
                      <span
                        [ngClass]="{'tbld_premium_gross_small': (premiumDetail.grossAmount).toString().length > 5}"
                        class="pull-right tbld_premium_gross">{{ premiumDetail.grossAmount | currency:'EUR':'symbol':'1.2-2':'it' }}&nbsp;<span
                        class="tbld_title_value_premi"></span></span>
                  </div>
                </div>
                <div class="arrow-down"></div>
                <div class="tbld_row premium_tab">
                  <div class="tbld_col tbld_col_title tbld_col_title_premi" translate>Net</div>
                  <div class="tbld_col tbld_col_value tbld_col_value_premi">
                    <b class="pull-right">
                                            <span><span>
                                                </span>{{ premiumDetail.netAmount | currency:'EUR':'symbol':'1.2-2':'it' }}&nbsp;</span>
                    </b>
                  </div>
                </div>
                <div class="tbld_row  premium_tab">
                  <div class="tbld_col tbld_col_title tbld_col_title_premi"
                       translate>Accessories
                  </div>
                  <div class="tbld_col tbld_col_value tbld_col_value_premi">
                    <b class="pull-right">
                                            <span><span>
                                                </span>{{ premiumDetail.accessories | currency:'EUR':'symbol':'1.2-2':'it' }}&nbsp;</span>
                    </b>
                  </div>
                </div>
                <div class="tbld_row premium_tab">
                  <div class="tbld_col tbld_col_title tbld_col_title_premi " translate>Inst.
                    Interest
                  </div>
                  <div class="tbld_col tbld_col_value tbld_col_value_premi">
                    <b class="pull-right">
                                            <span><span>
                                                </span>{{ premiumDetail.instalmentInterest | currency:'EUR':'symbol':'1.2-2':'it' }}&nbsp;</span>
                    </b>
                  </div>
                </div>
                <div class="tbld_row premium_tab">
                  <div class="tbld_col tbld_col_title tbld_col_title_premi "
                       translate>Taxable
                  </div>
                  <div class="tbld_col tbld_col_value tbld_col_value_premi">
                    <b class="pull-right">
                                            <span><span>
                                                </span>{{ premiumDetail.taxableAmount | currency:'EUR':'symbol':'1.2-2':'it' }}&nbsp;</span>
                    </b>
                  </div>
                </div>
                <div class="tbld_row premium_tab">
                  <div class="tbld_col tbld_col_title tbld_col_title_premi" translate>Taxes</div>
                  <div class="tbld_col tbld_col_value tbld_col_value_premi">
                    <b class="pull-right">
                                            <span><span>
                                                </span>{{ premiumDetail.taxes | currency:'EUR':'symbol':'1.2-2':'it' }}&nbsp;</span>
                    </b>
                  </div>
                </div>
                <div *ngIf="!isTaxComponentsAvailable()" class="tbld_row premium_tab">
                  <div class="tbld_col tbld_col_title tbld_col_title_premi" translate>SSN</div>
                  <div class="tbld_col tbld_col_value tbld_col_value_premi">
                    <b class="pull-right">
                      <span><span></span>{{ premiumDetail.SSN | currency:'EUR':'symbol':'1.2-2':'it' }}&nbsp;</span>
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="btn-group btn-group-justified">
          <div class="btn-group">
            <button (click)="close()" class="btn btn-warning" data-qa="btn-quotation-details-modal-close" translate>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { DomainField } from '@rgi/digital-claims-common-angular';

export class DamageCategoryEntity {
    constructor(
        public coverturecode: string,
        public coverturetypecode: string,
        public riskcode: string,
        public branchclaimcode: string,
        public fields: DomainField[],
        public description: string
    ) { }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pnc-required',
  templateUrl: './required.component.html',
  styleUrls: ['./required.component.scss']
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class RequiredComponent implements OnInit {

  @Input() required: boolean;

  constructor() { }

  ngOnInit() {
  }

}

// @ts-ignore
declare var angular: angular.IAngularStatic;

export function AjsPassDamageCardResourcesExt() {

  const ptfDamageServicesExt = angular.module('ptfdamage-nmmf-integration-resources', []);

  ptfDamageServicesExt.factory('PtfDamageContractOrigin', PtfDamageContractOrigin);
  PtfDamageContractOrigin.$inject = ['$resource', 'API_CONF'];
  function PtfDamageContractOrigin($resource, apiConf) {
    const endpoint = apiConf.server + apiConf.base_url + '/v2/portfolio/contract/origin';
    const ContractOrigin = $resource(endpoint, {}, {
      getContractOrigin: { method: 'POST' }
    });
    ContractOrigin.getInput = () => ({filter: {}});
    return ContractOrigin;
  }
}

import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { ModalService } from '../modal.service';
import { ModalOverlayComponent } from '../modal-overlay/modal-overlay.component';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
  selector: 'pnc-modal',
  template: `
<template #modaloverlay></template>
`
})
/**
 * @deprecated The portal-ng-core library is no longer maintained. Please use @rgi/rx.
 */
export class ModalComponent implements OnInit {

  componentRef: any;

  @ViewChild('modaloverlay', { read: ViewContainerRef, static: true }) entry: ViewContainerRef;

  constructor(private resolver: ComponentFactoryResolver, private modalService: ModalService) {
    modalService.observeMessagesSent().subscribe(
      content => {
        this.openOverlay(content.messages, content.title, content.onClose, content.onConfirm);
      });
    modalService.observeMessagesRead().subscribe(
      () => {
        this.closeOverlay();
      });
  }

  ngOnInit() {
  }

  openOverlay(messages: Array<string>, title?: string, onClose?: () => void, onConfirm?: () => void) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalOverlayComponent);
    this.componentRef = this.entry.createComponent(factory);
    this.componentRef.instance.ref = this.componentRef;
    this.componentRef.instance.messages = messages;
    this.componentRef.instance.title = title;
    this.componentRef.instance.onClose = onClose;
    this.componentRef.instance.onConfirm = onConfirm;
  }

  closeOverlay() {
    this.componentRef.destroy();
  }

}



import { EventEmitter, Injectable, Optional, Output } from '@angular/core';
import {AnswerFlatI, AnswerType, QuestionnaireFlatI, ValueFlatI} from '@rgi/ng-passpro';
import {EnvironmentService, PassproproAuthService} from '@rgi/ng-passpropro-core';
import {
  QuestionnaireSearch, QuestionnaireService,
  QuestionnaireVersion, Questionnaire as QuestionnaireI} from '@rgi/ng-passpropro/questionnaire';
import {SurveyVersionI} from '@rgi/ng-passpropro-survey/lib/models/survey-version';
import {DatePipe} from '@angular/common';
import {SurveyVersionService} from './survey-version.service';
import {combineLatest, forkJoin, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map, mergeMap, switchMap, take, tap} from 'rxjs/internal/operators';
import { SurveyService } from '@rgi/ng-passpropro-survey';
import { Questionnaire } from '../model/questionnaire.model';
import { QuestionnaireCacheService } from './questionnaires-manager-cache.service';

@Injectable()
export class QuestionnairesManagerService {

  productCode = '';
  questType = '';
  key: string;
  date: any;
  productStructure: any;
  factors: any[];
  customQuestLoader: string;
  questionnairesCode: Map<string, boolean>;

  @Output() foundQuestionnaires = new EventEmitter<boolean>();
  @Output() listChange = new EventEmitter<Array<Questionnaire>>();
  @Output() questUpdated = new EventEmitter<Questionnaire>();
  @Output() versionQuest = new EventEmitter<{ quest: Questionnaire, version: QuestionnaireFlatI }>();

  /**
   * An array of defaults answer for a couple (section, question). For boolean answers, the value
   * should be either "0" or "1"; for lists, should be the trascode of the answer or the
   * description.
   *
   * Examples:
   *
   * Setting numeric or text answer
   *
   * { section: 'S1', question: 'Q1', value: '530'}
   * { section: 'S1', question: 'Q1', value: 'My Value'}
   *
   * Setting boolean answer
   *
   * { section: 'S1', question: 'Q1', value: '0'}
   * { section: 'S1', question: 'Q1', value: '0'}
   *
   * Setting list answer
   *
   * { section: 'S1', question: 'Q1', value: 'Answer Description'}
   * { section: 'S1', question: 'Q1', value: 'MY_ANSWER_TRASCODE'}
   *
   * For multivalued answer, the values should be divided by ";".
   */
  private defaultAnswersBySecs: Array<{ section: string, question: string, value: string }> = [];

  constructor(
    protected authService: PassproproAuthService,
    protected questionnaireService: QuestionnaireService,
    @Optional() public cache: QuestionnaireCacheService,
    protected datePipe: DatePipe,
    protected surveyService: SurveyService,
    protected surveyVersionService: SurveyVersionService,
    protected http: HttpClient,
    protected environment: EnvironmentService
  ) {
    console.debug('QuestionnairesManagerServiceImpl - constructor');
  }

  init(uuids?: string[]): Observable<any> {
    console.debug('QuestionnairesManagerServiceImpl - init ');
    if (this.cache && !this.cache.get(this.key)) {
      const updatesToCall = [];
      if (!!uuids && uuids.length > 0) {
        const observer = this.listChange.subscribe(_ => {
          uuids.forEach(uuid => updatesToCall.push(this.updateSurvey(uuid)));
          observer.unsubscribe();
        });
      }
      return this.getLoaderByCustomQuestLoader().pipe(
        switchMap(res => {
          if (!!uuids && uuids.length > 0) {
            return !!updatesToCall.length ? combineLatest(updatesToCall) : of();
          }
          return of(true);
        }),
        switchMap(res => {
          return of(res);
        })
      );
    } else {
      return of({});
    }
  }


  protected getLoaderByCustomQuestLoader(): Observable<any> {
    switch (this.customQuestLoader) {
      case 'BY_CODE':
        return this.loadQuestionnaireByCode();
      default:
        return this.loadQuestionnaires();
    }
  }

  loadQuestionnaires(productCode?: string, questType?: string, date?: any, key?: string): Observable<any> {
    console.debug('QuestionnairesManagerServiceImpl - loadQuestionnaires ', productCode, questType, date, key);
    let clear = false;
    if (!this.cache) {
      console.warn('QuestionnairesManagerServiceImpl - loadQuestionnaires - has no cache');
      return;
    }
    if (productCode || productCode === '') {
      this.productCode = productCode;
      clear = true;
    }
    if (questType || questType === '') {
      this.questType = questType;
      clear = true;
    }
    if (date || date === '') {
      this.date = date;
      clear = true;
    }
    if (key || key === '') {
      this.key = key;
      clear = true;
    }
    if (clear) {
      this.cache.delete(this.key);
    }
    if (date || date === '') {
      this.cache.dateCache = this.datePipe.transform(date, 'yyyy-MM-dd');
    } else {
      this.cache.dateCache = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
    const filter: QuestionnaireSearch = {};
    if (this.productCode || this.productCode !== '') {
      filter.products = [this.productCode];
      filter.enableProductRestriction = true;
    }
    if (this.questType || this.questType !== '') {
      filter.type = this.questType;
    }
    if (this.date) {
      filter.date = this.datePipe.transform(this.date, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSZ');
    }

    return this.getQuestInclusionFormula(filter).pipe(
      switchMap(questionnaires => {
        if (!questionnaires || questionnaires.length === 0) {
          this.foundQuestionnaires.emit(false);
        } else {
          const questionnairesWithVersion = new Map<Questionnaire, QuestionnaireFlatI>();
          questionnaires.forEach(quest => questionnairesWithVersion.set(quest, null));
          this.cache.set(this.key, questionnairesWithVersion);
          this.cache.setMandatory();
          this.foundQuestionnaires.emit(true);
        }
        this.listChange.emit(this.getQuestionnaires());
        return of(true);
      })
    );
  }

  getQuestInclusionFormula(filter: QuestionnaireSearch): Observable<QuestionnaireI[]> {
    if (this.productStructure) {
      const req = {
        questType: filter.type ? filter.type : null,
        date: filter.date ? filter.date : null,
        instance: this.productStructure
      };
      console.log('Calling the formula inclusion service with request: ' + JSON.stringify(req));
      return this.questionnaireService.getQuestInclusionFormula(req);
    } else {
      return this.questionnaireService.getQuestionnaireWith(filter);
    }
  }

  loadQuestionnaireByCode(questCodes?: Map<string, boolean>): Observable<any> {
    const $allQuestResults = [];
    if (questCodes && questCodes.size > 0) {
      this.questionnairesCode = questCodes;
    }

    if (this.questionnairesCode && this.questionnairesCode.size > 0) {

      this.questionnairesCode.forEach((value, code) => $allQuestResults.push(
        this.questionnaireService.getQuestionnaireWith({
          name: code,
          code
        })));

      if ($allQuestResults.length > 0) {
        return forkJoin<QuestionnaireI[]>($allQuestResults)
          .pipe(
            switchMap(results => {
              if (results && results.length > 0) {
                results.forEach(questionnaires => {
                  if (questionnaires && questionnaires.length > 0) {
                    questionnaires.forEach(quest => {
                      const currentValueForCache: Map<Questionnaire, QuestionnaireFlatI> = this.cache.get(this.key);
                      if (!currentValueForCache) {
                        const questionnairesWithVersion = new Map<Questionnaire, QuestionnaireFlatI>();
                        questionnairesWithVersion.set(quest, null);
                        this.cache.set(this.key, questionnairesWithVersion);
                      } else {
                        // rimuove i duplicati !! fix RDDL-2021
                        const foundQuest = [...currentValueForCache.keys()].find(
                          key => key.id === quest.id
                        );
                        if (!foundQuest) {
                          currentValueForCache.set(quest, null);
                        }
                      }
                    });
                  }
                });
                this.cache.setMandatory(this.questionnairesCode);
                this.foundQuestionnaires.emit(true);
                this.listChange.emit(this.getQuestionnaires());
              } else {
                this.foundQuestionnaires.emit(false);
              }
              return of(true);
            })
          );
      } else {
        this.foundQuestionnaires.emit(false);
        return of(true);
      }
    } else {
      this.questionnaireService.getQuestionnaireWith({});
      return of(true);
    }
  }

  getCacheDate(): any {
    if (!this.cache) {
      console.warn('QuestionnairesManagerServiceImpl - getCacheDate - has no cache');
      return undefined;
    }
    return this.cache.dateCache;
  }

  getQuestionnaires(): Array<any> {
    if (this.cache && this.cache.get(this.key)) {
      return Array.from(this.cache.get(this.key).keys());
    }
    return new Array<any>();
  }

  getSelectedQuest(quest: Questionnaire): void {
    console.debug('QuestionnairesManagerServiceImpl - getSelectedQuest ', quest);
    if (!this.cache) {
      console.warn('QuestionnairesManagerServiceImpl - getSelectedQuest - has no cache');
      return;
    }
    const questFlat = this.cache.get(this.key).get(quest); // recupero il questionario dalla cache
    if (questFlat === null) {
      // non ho il questionario in cache e lo creo da nuovo
      this.questionnaireService.getVersions(quest.id).subscribe(d => {
        const currQuest = this.findLastQuestionnaireVersion(d);
        this.questionnaireService
          .getQuestionnaireFromPASSPRO(currQuest.code)
          .subscribe(updateQuest => {
            this.loadQuestWithPreval(quest, updateQuest);
          });
      });
    } else {
      // recupero il questionario dalla cache e lo utilizzo
      this.loadQuestWithPreval(quest, questFlat);
    }
  }

  deleteFromCache(key: string): void {
    console.debug('QuestionnairesManagerServiceImpl - deleteFromCache ', key);
    if (!this.cache) {
      console.warn('QuestionnairesManagerServiceImpl - deleteFromCache - has no cache');
      return;
    }
    this.cache.delete(key);
  }

  clearQuestionnaire(quest: Questionnaire): void {
    if (!this.cache) {
      console.warn('QuestionnairesManagerServiceImpl - deleteFromCache - has no cache');
      return;
    }
    this.cache.clear(quest);
  }

  updateQuestionnaire(quest: Questionnaire, version: QuestionnaireFlatI, compiled: boolean): void {
    console.debug('QuestionnairesManagerServiceImpl - updateQuestionnaire', quest, version, compiled);
    if (this.cache && this.cache.get(this.key).get(quest)) {
      quest.compiled = compiled;
      if (this.questionnairesCode && this.questionnairesCode.size > 0) {
        quest.mandatory = this.questionnairesCode.get(quest.code);
      }
      this.cache.get(this.key).set(quest, version);
      this.questUpdated.emit(quest);
    }
  }

  findLastQuestionnaireVersion(list: QuestionnaireVersion[]): QuestionnaireVersion {
    console.debug('QuestionnairesManagerServiceImpl - findLastQuestionnaireVersion', list);
    return list.reduce(
      (
        max: QuestionnaireVersion,
        v: QuestionnaireVersion
      ): QuestionnaireVersion =>
        !max || v.versionNumber > max.versionNumber ? v : max
    );
  }

  loadQuestWithPreval(quest: Questionnaire, updateQuest: QuestionnaireFlatI) {
    console.debug('QuestionnairesManagerServiceImpl - loadQuestWithPreval', quest, updateQuest);
    if (!this.cache) {
      console.warn('QuestionnairesManagerServiceImpl - loadQuestWithPreval - has no cache');
      return;
    }
    if (this.hasDefaultsByFactors() || this.hasDefaultsBySections()) {
      const survey: SurveyVersionI = this.surveyVersionService.makeSurveyVersion(quest, updateQuest);
      // set default answers by factors
      if (this.hasDefaultsByFactors()) {
        this.surveyService.setInputFactors(survey, this.factors);
      }
      // set default answers by <section, question>
      if (this.hasDefaultsBySections()) {
        this.setInputAnswers(survey, this.defaultAnswersBySecs);
      }
      this.cache.get(this.key).set(quest, survey.lastVersion.questionnaire);
      this.versionQuest.emit({quest, version: updateQuest});
    } else {
      this.cache.get(this.key).set(quest, updateQuest);
      this.versionQuest.emit({quest, version: updateQuest});
    }
  }

  private hasDefaultsByFactors(): boolean {
    return this.factors != null && this.factors.length > 0;
  }

  private hasDefaultsBySections(): boolean {
    return !!this.defaultAnswersBySecs && this.defaultAnswersBySecs.length > 0;
  }

  public setDefaultAnswers(answers: Array<{ section: string, question: string, value: string }>): void {
    if (!!answers) {
      this.defaultAnswersBySecs = answers;
    } else {
      this.defaultAnswersBySecs = [];
    }
  }

  protected setInputAnswers(survey: SurveyVersionI, answers: Array<{ section: string, question: string, value: string }>) {
    // creating a mapping for better performance
    const mapping = {};
    answers.map(input => {
      mapping[input.section + '.' + input.question] = input;
    });
    survey.lastVersion.questionnaire.questions.forEach(question => {
      const input = mapping[question.code];
      if (!!input) {
        if (question.answers.length === 1) {
          const answer = question.answers[0];
          if (answer.paramType.toString() === AnswerType.LIST) {
            if (answer.multivalue) {
              // multivalue lists trascode an array of values
              const values: string[] = input.value.split(';');
              const founds = values.map(str => this.transcodeAnswer(answer, str)).filter(value => value != null).map(value => value.id);
              answer.value = founds.length === 0 ? null : founds.join(';');
            } else {
              // single value lists just trascode the value
              const found = this.transcodeAnswer(answer, input.value);
              if (!!found) {
                answer.value = found.id;
              }
            }
          } else {
            // set the value (can be multivalue divided by ";")
            answer.value = input.value;
          }
        } else {
          console.error('Multi answer questions not spported: ' + input.section + '.' + input.question);
        }
      }
    });
  }

  private transcodeAnswer(answer: AnswerFlatI, inputValue: string): ValueFlatI {
    return answer.values.find(value => inputValue === value.trascode || inputValue === value.description);
  }

  getSurveyVersion(uuid: string): Observable<any> {
    const url = this.environment.environment.URL + '/questionnaires/survey/version/' + uuid;
    return this.http.get<any>(url, {}).pipe(map(response => response.result));
  }

  mergeQuestionnaireFromPASSPRO(questionnaire: SurveyVersionI, needs: any = []): Observable<SurveyVersionI> {
    return of(questionnaire).pipe(
      mergeMap(version => {
        return combineLatest(
          this.http.put<any>(`${this.environment.environment.URL}/questionnaires/fromPassPro`, {
          filter: {
            questionnaire: questionnaire.lastVersion.questionnaire,
            needs
          }
        }), of(version));
      }),
      map(([val, version]) => {
        // aggiorniamo la version
        version.lastVersion.questionnaire = val.questionnaire;
        return version;
      })
    );
  }

  private updateSurvey(uuid: string): Observable<any> {
    return this.getSurveyVersion(uuid).pipe(
      mergeMap(getVersion => {
        return this.mergeQuestionnaireFromPASSPRO(getVersion);
      }),
      tap(result => {
        const version = result.lastVersion.questionnaire;

        this.cache.get(this.key).forEach((_, q, data) => {
          if (q.code === version.code) {
            q.compiled = true;
            if (this.questionnairesCode && this.questionnairesCode.size > 0) {
              q.mandatory = this.questionnairesCode.get(q.code);
            }
            data.set(q, version);
          }
        });
      }),
      take(1)
    );
  }
}

import {Injectable} from '@angular/core';
import {Message} from './models/message';
import {GenericEntity} from './models/domain-models/generic-entity';
import {ErrorMessagesAreas} from './error-messages-areas';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessagesService {

  areas = ErrorMessagesAreas;

  constructor() {
  }

  containsError(errors: Array<GenericEntity>, error: string): boolean {
    let filteredValues: Array<GenericEntity> = [];
    if (errors) {
      filteredValues = errors.filter((val: GenericEntity) => {
        return val.code === error;
      });
    }
    return filteredValues.length > 0;
  }

  addOtherBlockingMessages(blockingMessages: Array<Message>, area: string, errorsList?: Array<GenericEntity>) {
    // For Projects
  }

  addOtherNonBlockingMessages(nonBlockingMessages: Array<Message>, area: string, errorsList?: Array<GenericEntity>) {
    // For Projects
  }

  includesMessage(messages: Array<Message>, message: Message): boolean {
    return messages.some((item) => {
      return message.area.includes(item.area) && message.text.includes(item.text);
    });
  }

}

import {GenericEntity} from '../domain-models/generic-entity';
import {ApiProduct} from './api-product';
import {ApiVehicle} from './api-vehicle';
import {ApiContractNumber} from './api-contract-number';

export class ApiContract {

  constructor(
    public id?: string,
    public products?: Array<ApiProduct>,
    public adjustInsuredValue?: boolean,
    public updatableInsuredValue?: boolean,
    public vehicle?: ApiVehicle,
    public dateOfBirth?: number,
    public completenessErrors?: Array<GenericEntity>,
    public correctnessErrors?: Array<GenericEntity>,
    public isbValueOutsideMagazine?: boolean,
    public contractNumber?: ApiContractNumber,
    public editProposal?: boolean,
    public editSubstitution?: boolean,
    public entitledOnQuote?: boolean,
    public entitledBeforeQuotation?: boolean,
    public checkIbanOnIssue?: boolean,
    public companyId?: string,
    public nodeId?: string,
    public testPlate?: boolean,
    public nodeCode?: string,
    public bindingProposal?: boolean) {
  }

}

import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { TranslationWrapperService } from '../../../../i18n/translation-wrapper.service';
import { KarmaFundError } from '../../model/karma-fund-error';
import { RgiCountryLayerNumberFormatPipe } from '@rgi/country-layer';
import { LpcCurrencyCache, CurrencyCacheService } from '../../../../services/currency-cache.service';

@Component({
  selector: 'lpc-karma-fund-message[error]',
  templateUrl: './lpc-karma-fund-message.component.html',
  styleUrls: ['./lpc-karma-fund-message.component.scss'],
  providers: [RgiCountryLayerNumberFormatPipe]
})
export class LpcKarmaFundMessageComponent implements OnInit {
  // formatter Options
  public currencyFormatterOptions: Intl.NumberFormatOptions = {
    style: 'currency'
  };
  public percentFormatterOptions: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2
  };

  @Input() error: KarmaFundError;
  @Input() fromDisinvestments = false;

  public lower: boolean;
  public total: boolean;
  public type: 'min' | 'max' | 'ok';
  public amount: number;
  public percent: number;
  public limit: number;
  public required: boolean;

  constructor(
    protected translate: TranslationWrapperService,
    protected rgiNumberFormatter: RgiCountryLayerNumberFormatPipe,
    @Optional() @Inject(LpcCurrencyCache) protected currencyService: CurrencyCacheService) {
      this.currencyFormatterOptions.currency = currencyService.currency; }

  ngOnInit() {
    console.log('amount', this.error.amount);
    this.lower = this.error.percent > 0;
    this.total = this.error.description === 'TOTAL_FUNDS';
    this.amount = Math.abs(this.error.amount);
    this.percent = Math.abs(this.error.percent);
    this.limit = this.error.limit;
    this.type = this.error.type;
  }

  getWarningMessage(): string {
    if (this.fromDisinvestments) {
      return this.translate.getImmediate('lpc_disinvestment_msg', {
        amount : this.rgiNumberFormatter.transform(this.amount.toString(), '', this.currencyFormatterOptions),
        percent: this.rgiNumberFormatter.transform((this.percent).toString(), '', this.percentFormatterOptions)});
    } else {
      return this.translate.getImmediate('lpc_investment_msg', {
        amount : this.rgiNumberFormatter.transform(this.amount.toString(), '', this.currencyFormatterOptions),
        percent: this.rgiNumberFormatter.transform((this.percent).toString(), '', this.percentFormatterOptions)});
    }
  }

}
